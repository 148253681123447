/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { TagsContext } from '../../../../../Contexts/Tags';
import { isNotEmpty, tagColors } from '../../../../../shared/Utilities';
import { v2Fetches } from '../../shared';
import EmptyLoading from '../../../../../shared/EmptyLoading';
import Donut from '../../../../../shared/Charts/Donut';
import Legend from '../../../../../shared/Charts/Legend';

import './TagBreakdown.scss';

const TagBreakdown = ( { prefetchedData, settings } ) => {

  const [ loading, setLoading ] = React.useState( false );
  const [ tagTotal, setTagTotal ] = React.useState( 1 );
  const [ tagData, setTagData ] = React.useState( null );
  const [ tags ] = React.useContext( TagsContext );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );

  const setupData = data => {
    if ( isNotEmpty( data ) && isNotEmpty( data.asset_tag ) ) {
      const availableColors = [ ...tagColors.four ];
      availableColors.shift();
      const _tagData = {};

      const total = Object.values( data.asset_tag ).reduce( ( accum, thisCount ) => ( accum + thisCount ), 0 );

      Object.entries( data.asset_tag ).map( ( [ tagID, count ], index ) => {

        if ( isNotEmpty( tags ) && isNotEmpty( tags[tagID] ) ) {
          const tag = tags[tagID];

          _tagData[tagID] = {
            label: tag.label,
            stroke: tag.color || availableColors[index % 8],
            value: ( count / total ) * 100,
            originalCount: count,
            total: count,
            key: tagID,
          };
        }
      } );

      setTagTotal( total );
      setTagData( _tagData );
    }
  };

  const refetchData = async () => {
    setLoading( true );
    const _tallyResults = await v2Fetches.tally_tag();
    setLoading( false );

    if ( isNotEmpty( _tallyResults ) ) {
      setupData( _tallyResults.results );
    }
  };

  React.useEffect( () => {
    if (
      isNotEmpty( prefetchedData )
      && isNotEmpty( prefetchedData.tally_tag )
      && isNotEmpty( prefetchedData.tally_tag.results )
      && isNotEmpty( prefetchedData.tally_tag.results.asset_tag )
    ) {
      setupData( prefetchedData.tally_tag.results );
    } else {
      refetchData();
    }
  }, [ prefetchedData, tags, settings ] );

  return (
    <React.Fragment>
      <EmptyLoading
        payload={ tagData }
        loading={ loading }
        loadingMessage="Loading Tag Data"
        emptyMessage="No Tag Data"
      />
      {
        ( isNotEmpty( tagTotal ) && isNotEmpty( tagData ) ) &&
        <React.Fragment>
          <Donut
            data={tagData}
            total={tagTotal}
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
          <Legend
            legendData={ tagData }
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default TagBreakdown;