/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import InsightDetail from '../../Detail';
import InsightFilters from '../../Filters';
import InsightVisual from '../../Visual';
import InsightTable from '../../Table';

import {
  isNotEmpty,
  decodeURLHash,
  encodeURLHash,
  removeFromURLHash,
  paramsToFilters,
  isInequalityType,
} from '../../../../shared/Utilities';

import './style.scss';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue.js';
import { getRecord, getRecords } from '../../../../shared/RecordCache';
import { getRowNums, pageIterator } from '../../../../shared/Pagination/IndeterminantPagination';
import PageHeader from '../../../../shared/PageHeader';

const Hosts = ( ) => {

  const [ selectedRecord, setSelectedRecord ] = React.useState( null );
  const [ hoverRecord, setHoverRecord ] = React.useState( null );
  const [ visualLoading, setVisualLoading ] = React.useState( true );
  const [ tableLoading, setTableLoading ] = React.useState( true );

  const [ sortBy, setSortBy ] = React.useState( 'risk' );
  const [ sortDirection, setSortDirection ] = React.useState( 'DESC' );

  // controls the top overview panel collapsed state, storing here to pass down to multiple components
  const [ visualcollapsed, setVisualCollapsed ] = React.useState( false );
  // controls the filter drawer at the top level
  const [ filtersCollapsed, setFiltersCollapsed ] = React.useState( true );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  // pagination related state variables
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState( 1 );
  const [ currentPageResults, setCurrentPageResults ] = React.useState( [] );
  const [ nextPageResults, setNextPageResults ] = React.useState( [] );

  const isMounted = true;

  // sorting by clicking the column headers in the table
  React.useEffect( () => {
    const currentHash = decodeURLHash();

    if ( sortBy !== currentHash.sort_by || sortDirection !== currentHash.sort_direction ) {
      // eslint-disable-next-line camelcase
      encodeURLHash( { sort_by: sortBy, sort_direction: sortDirection } );
      onRefresh();
    }
  }, [ sortBy, sortDirection ] );

  const fetchExistingRecord = async( recordID ) => {
    const tagIDs = decodeURLHash()['asset_tag_ids'];

    const _record = (
      await getRecord(
        'scope',
        recordID,
        // eslint-disable-next-line camelcase, max-len
        isNotEmpty( tagIDs ) ? { asset_tag_ids: tagIDs, field_map: { type: 'host' } } : { field_map: { type: 'host' } },
        true,
      )
    );
    return _record;
  };

  const selectRecord = async ( recordID ) => {
    if ( isNotEmpty( recordID ) ) {

      const fetchedRecord = await fetchExistingRecord( recordID );

      if ( isNotEmpty( fetchedRecord ) ) {
        setSelectedRecord( fetchedRecord );
        encodeURLHash( { item: recordID } );
        setHoverRecord( null );
      } else {
        addFlashMessage( {
          type: 'alert',
          body: 'The record you are looking for is no longer part of the risk model',
        } );
        setSelectedRecord( {} );
        removeFromURLHash( 'item' );
        onRefresh();
        setHoverRecord( null );
      }
    } else {
      setSelectedRecord( {} );
      removeFromURLHash( 'item' );
      onRefresh();
      setHoverRecord( null );
    }
  };

  /* eslint-disable camelcase */
  const onRefresh = async ( adjustedFilters=[] ) => {
    const onCorrectPage = decodeURLHash()['report'] === 'hosts';

    if ( onCorrectPage ) {
      setVisualLoading( true );
      setTableLoading( true );

      // if the filter that was just changed is not the current page, set it back to page 1
      if ( isNotEmpty( adjustedFilters ) && !adjustedFilters.some( attr => attr === 'current_page ' ) ) {
        // eslint-disable-next-line camelcase
        encodeURLHash( { current_page: 1 } );
      }

      const filterValues = paramsToFilters();

      const params = {
        // eslint-disable-next-line camelcase
        rownums: [ 0, 100 ],
        // eslint-disable-next-line camelcase
        model: 'base',
        // eslint-disable-next-line camelcase
        group_type: 'host',
        project: 'default',
        filters: { 'num_sensitive_nodes': 'any', 'ii.has_host': true },
      };

      const gt_map = {};
      const lt_map = {};

      const _sortBy = filterValues.sort_by || [ 'scope_analysis.risk' ];
      const _sortDirection = filterValues.sort_direction || 'DESC';

      const _rowNums = getRowNums( filterValues );

      if ( isNotEmpty( filterValues ) ) {
        Object.entries( filterValues ).map( ( [ key, val ] ) => {

          if ( key === 'sensitive_assets' ) {
            params.filters['num_sensitive_nodes'] = val;
          } else if ( key === 'item_count' ) {
            params.rownums = _rowNums;
          } else if ( key === 'keywords' ) {
            params.filters['keywords'] = val;
          } else if ( key === 'asset_tag_ids' ) {
            // eslint-disable-next-line camelcase
            params.filters['asset_tag_ids'] = isNotEmpty( val ) ? val : null;
          } else if ( isInequalityType.includes( key ) ) {
            const [ inequality, unit ] = val;

            if ( isNotEmpty( unit ) ) {
              if ( inequality === 'gt_map' ) {
                gt_map[key] = parseFloat( unit );
              } else if ( inequality === 'lt_map' ) {
                lt_map[key] = parseFloat( unit );
              }
            }
            if ( isNotEmpty( gt_map ) ) {
              params.filters.gt_map = gt_map;
            }
            if ( isNotEmpty( lt_map ) ) {
              params.filters.lt_map = lt_map;
            }
          } else {
            params.filters[key] = val;
          }
        } );
      }

      if (
        isNotEmpty( _sortBy )
        && isNotEmpty( _sortDirection )
        && Array.isArray( _sortBy )
      ) {
        _sortBy.map( s => {
          params.order_by.push( [ s === 'risk' ? 'filtered_risk' : s, _sortDirection ] );
          params.order_by.push( [ 'local_name', 'ASC' ] );
        } );
      } else if ( _sortBy === 'risk' ){
        // eslint-disable-next-line camelcase
        params.order_by = [ [ 'filtered_risk', _sortDirection ] ];
        params.order_by.push( [ 'local_name', 'ASC' ] );
      } else if ( _sortBy === 'label' ){
        // eslint-disable-next-line camelcase
        params.order_by = [ [ 'local_name', _sortDirection ] ];
        params.order_by.push( [ 'filtered_risk', 'ASC' ] );
      } else {
        // eslint-disable-next-line camelcase
        params.order_by = [ [ _sortBy, _sortDirection ] ];
        params.order_by.push( [ 'local_name', 'ASC' ] );
      }

      let recordList = [];
      let pagedResults = [];

      if ( isNotEmpty( filterValues.item ) ) {
        if ( isMounted ) {
          selectRecord( filterValues.item );
        }
      } else {
        recordList = await getRecords( 'HOST', params, true );
        pagedResults = pageIterator( recordList, filterValues );
      }
      if ( isMounted ) {
        setCurrentPageNumber(
          pagedResults.currentPageNumber ? parseInt( pagedResults.currentPageNumber ) : 1,
        );
        setCurrentPageResults( pagedResults.firstPage );
        setNextPageResults( pagedResults.secondPage );
        setVisualLoading( false );
        setTableLoading( false );
      }
    }
  };
  /* eslint-enable camelcase */

  return (
    <React.Fragment>
      {
        isNotEmpty( selectedRecord )
          ? <InsightDetail item={selectedRecord} selectItem={selectRecord} reportType="host" riskType="filtered_risk" />
          : <React.Fragment>
            <PageHeader>
              <InsightFilters
                onRefresh={onRefresh}
                visualCollapsed={visualcollapsed}
                setVisualCollapsed={setVisualCollapsed}
                collapsed={filtersCollapsed}
                setCollapsed={setFiltersCollapsed}
                reportType="host"
                tableLoading={ tableLoading }
                visualLoading={ visualLoading }
              />
              <InsightVisual
                reportType="host"
                loading={visualLoading}
                collapsed={visualcollapsed}
                setCollapsed={setVisualCollapsed}
                selectRecord={selectRecord}
                setHoverRecord={setHoverRecord}
                onRefresh={onRefresh}
                records={currentPageResults}
                riskType="filtered_risk"
              />
            </PageHeader>

            <InsightTable
              records={currentPageResults}
              nextRecords={nextPageResults}
              currentPageNumber={currentPageNumber}
              loading={tableLoading}
              reportType="host"
              onRefresh={onRefresh}
              refreshTable={onRefresh}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
              visualCollapsed={visualcollapsed}
              selectedRecord={selectedRecord}
              selectRecord={selectRecord}
              hoverRecord={hoverRecord}
              setHoverRecord={setHoverRecord}
            />
          </React.Fragment>
      }
    </React.Fragment>
  );
};

export default Hosts;