/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, isNotEmpty } from '../Utilities';
import CVSSBreakdown from '../../components/Reporting/Dashboards/Widgets/v2/CVSSBreakdown';
import './style.scss';

const CVSSCategories = ( { data } ) => {
  return (
    <React.Fragment>
      {
        isNotEmpty( data ) &&
        <div className="cvssCategoriesBreakdownWrapper">
          <h3>
            <label>CVSS Score Distribution</label>
            <strong><span>Total: </span>{ formatNumber( data.results?._total ) } </strong>
          </h3>
          <CVSSBreakdown prefetchedData={ { tally: data } } settings={ { version: 'horizontal' } } />
        </div>
      }
    </React.Fragment>
  );
};

export default CVSSCategories;