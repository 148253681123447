/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

// routes without tabs
import About from '../About';
import HelpDocumentation from '../HelpDocumentation';

// import { ContextualHelpContainer } from '../../components/HelpDocumentation/ContextualHelp/index.js';
import { decodeURLHash, helpIsLoaded, isEmpty, isNotEmpty } from '../../shared/Utilities';
import { ModelContext } from '../../Contexts/Model';
import { hasAccess } from './AccessControl';
import { CurrentUserContext } from '../../Contexts/CurrentUser';
import NotFound from './NotFound';
import FlashMessageQueue from '../../shared/FlashMessageQueue';
import { HelpContext } from '../../Contexts/Help';
import { clearCache } from '../../shared/RecordCache';
import { OnboardingWizardContext } from '../../Contexts/OnboardingWizard';
import { RiskContext } from '../../Contexts/Risk';
import { NavigationContext } from '../../Contexts/Navigation';
import { FullScreenVisualContext } from '../../Contexts/FullScreenVisual';

const Content = ( { currentLocation } ) => {

  // eslint-disable-next-line no-unused-vars
  let isMounted = true;

  let refreshInterval = null;
  let riskInterval = null;

  const [ globalModel, refreshModelData ] = React.useContext( ModelContext );
  const [ lastModelAnalysis, setLastModelAnalysis ] = React.useState( null );
  const [ pageComponent, setPageComponent ] = React.useState( <NotFound /> );

  const [ , , licenseInfo ] = React.useContext( CurrentUserContext );
  const [ , setHelpItems, , , , , , getContextualHelpItemsForPage, , filePathMap ] = React.useContext( HelpContext );
  const [ , , refreshWizard, , , , , , , ] = React.useContext( OnboardingWizardContext );
  const [ , setShowMenuFor, routing ] = React.useContext( NavigationContext );
  const [ , , , , , , , , , ] = React.useContext( FullScreenVisualContext );

  const [ , , , , , refreshGlobalRisk ] = React.useContext( RiskContext );

  // attempts to automatically load the help topics for a given page without needing to call anything on each page
  const loadHelpTopics = async ( ) => {
    const hash = decodeURLHash();
    const section = hash['.'];
    const { page, report } = hash;
    const helpPage = report || page || '';

    const helpItems = await getContextualHelpItemsForPage( section, helpPage, true );

    if ( isNotEmpty( helpItems ) ) {
      setHelpItems( helpItems );
    } else {
      setHelpItems( null );
    }
  };

  const handleGlobalMouseMove = e => {
    if ( isNotEmpty( e ) && isNotEmpty( e.target ) && isNotEmpty( e.target.classList ) ) {
      const classes = [ ...e.target.classList ];
      if ( !classes.includes( 'allowMenuHover' ) && e.target.tagName !== 'path' ) {
        setShowMenuFor( null );
      }
    }
  };

  React.useEffect( () => {
    // bit of a hacky check as a proxy to see if all the paths have loaded
    if (
      helpIsLoaded( filePathMap )
    ) {
      loadHelpTopics( );
      window.addEventListener( 'hashchange', loadHelpTopics );
      window.addEventListener( 'mousemove', handleGlobalMouseMove );
      return () => {
        window.removeEventListener( 'hashchange', loadHelpTopics );
        window.removeEventListener( 'mousemove', handleGlobalMouseMove );
      };
    }
  }, [ filePathMap ] );

  const intialize = async( ) => {
    console.log( 'initializing application with model, global, and project data' );

    // refresh the global vars
    await refreshModelData( );
    await refreshGlobalRisk( );
  };

  // clear cache if analysis is stale
  React.useEffect( () => {
    if ( isNotEmpty( globalModel ) ) {
      console.log( 'Model Refreshed' );
      if ( isEmpty( lastModelAnalysis ) || lastModelAnalysis < globalModel.last_analyzed ) {
        clearCache();
      }
      setLastModelAnalysis( globalModel.last_analyzed );
    }
  }, [ globalModel ] );

  React.useEffect( () => {
    refreshWizard();
    isMounted = true;

    // refresh the global vars
    intialize( );

    refreshInterval = setInterval( () => refreshModelData( [ 'model' ] ), 10_000 );
    // set up a poll to refresh the globals/model every 10 minutes
    riskInterval = setInterval( () => refreshGlobalRisk( ), 60_000 );
    return () => {
      clearInterval( refreshInterval );
      clearInterval( riskInterval );
      isMounted = false;
    };
  }, [] );

  // load the correct component whenever the location changes
  React.useEffect( () => {
    let _component = <NotFound />;

    if ( isNotEmpty( currentLocation ) && isNotEmpty( routing ) ) {

      const { route, page, report } = currentLocation;
      const pageOrReport = isEmpty( report ) ? page : report;
      const currentRoute = routing[route];

      if ( isNotEmpty( currentRoute ) && isNotEmpty( currentRoute.items ) ) {
        for ( const section of Object.values( currentRoute.items ) ) {
          if ( section.slug === pageOrReport && isNotEmpty( section.component ) ) {
            _component = section.component;
            break;
          // seeing if it matches a sub-page of a route section ( ie Reports > Standard > Hosts )
          } else if ( isNotEmpty( section.items ) ) {

            for ( const page of Object.values( section.items ) ) {
              if ( page.slug === pageOrReport && isNotEmpty( page.component ) ) {
                _component = page.component;
                break;
              }
            }
          }
        }
      }
    }
    setPageComponent( _component );
  }, [ currentLocation, routing ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( licenseInfo ) && isNotEmpty( currentLocation ) &&
        <React.Fragment>
          {
            hasAccess( licenseInfo, currentLocation, 'reportOrPage' )
              ? <React.Fragment>
                {
                  ( isNotEmpty( currentLocation ) && isNotEmpty( currentLocation.route ) )
                    ?  <React.Fragment>
                      {
                        isEmpty( currentLocation.page ) && isEmpty( currentLocation.report )
                          ? <React.Fragment>
                            {
                              currentLocation.route === 'about' &&
                              <About />
                            }
                            {
                              currentLocation.route === 'help_documentation' &&
                              <HelpDocumentation />
                            }
                          </React.Fragment>
                          : <React.Fragment>{ pageComponent }</React.Fragment>
                      }
                    </React.Fragment>
                    :  <CustomReports />
                }
              </React.Fragment>
              : <NotFound message={
                <React.Fragment>
                  <p>Your license does not grant you access to this feature</p>
                  <a href="#.=reports&page=reporting_dashboard">Return to Dashboard</a>
                </React.Fragment>
              }
              />
          }
        </React.Fragment>
      }
      <FlashMessageQueue />
    </React.Fragment>
  );
};

export default Content;