/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, isEmpty, isNotEmpty, itemIsNumber } from '../../../../shared/Utilities';
import InlineSVG from '../../../../shared/InlineSVG';
import EmptyLoading from '../../../../shared/EmptyLoading';
import {
  afterFetchAttributeKeys,
  directAttributeKeys,
  directNumAttributeKeys,
  noCountNeeded,
  thirdPartyAttributes,
} from '../shared.js';
import SectionContent from './SectionContent';

import './CollapsibleSection.scss';

const CollapsibleSection = ( {
  sectionKey,
  sectionInfo,
  item,
  instanceData,
  reportType,
  setSelectedSignature,
  setSelectedSegments,
  setSelectedNode,
  setModalContext,
  relatedPaths,
  riskType='risk',
  setHoveredListItemID,
  setHoveredListItemType,
  setHoveredListItemRating,
} ) => {

  const [ collapsed, setCollapsed ] = React.useState( true );
  const [ hasFetched, setHasFetched ] = React.useState( false );
  const [ sectionBodyData, setSectionBodyData ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( false );
  const [ count, setCount ] = React.useState( false );
  const [ superseded, setSuperseded ] = React.useState( 'unsuperseded' );

  // if the section is supposed to be open by default, set that first
  React.useEffect( ( ) => {
    if ( isNotEmpty( sectionInfo ) && sectionInfo.open === true ) {
      setCollapsed( false );
    }
  }, [ sectionInfo ] );

  // depending on the section, we need to get the count differently
  const getCount = ( item, sectionKey, sectionBodyData ) => {
    if ( thirdPartyAttributes.includes( sectionKey ) ) {
      setCount( 0 );
    } else if ( directNumAttributeKeys.includes( sectionKey ) ) {
      if ( sectionKey === 'num_patches' ) {
        if ( superseded === 'unsuperseded' ) {
          if ( isNotEmpty( item.num_unsuperseded_patches ) ) {
            setCount( item.num_unsuperseded_patches );
          } else {
            setCount( 0 );
          }
        } else if ( isNotEmpty( item.num_patches ) ) {
          setCount( item.num_patches );
        } else {
          setCount( 0 );
        }

      } else if ( isNotEmpty( item[sectionKey] ) ) {
        setCount( item[sectionKey] );

      } else {
        setCount( 0 );
      }
    } else if ( directAttributeKeys.includes( sectionKey ) ) {
      if ( isNotEmpty( item[sectionKey] ) ) {
        setCount( item[sectionKey].length );
      } else {
        setCount( 0 );
      }
    } else if ( afterFetchAttributeKeys.includes( sectionKey ) && isNotEmpty( sectionBodyData ) ) {
      setCount( sectionBodyData.length );
    } else if ( afterFetchAttributeKeys.includes( sectionKey ) ) {
      setCount( <span className="expandCount">expand to view</span> );
    } else {
      setCount( 0 );
    }
  };

  // the actual fetch functionality pulled out into an async function
  const getData = async ( options={} ) => {
    let _sectionBodyData;
    // need to wait for the promise to resolve
    if ( sectionInfo.isPromise ) {
      _sectionBodyData = await sectionInfo.getData( item, instanceData, reportType, relatedPaths, riskType, options );
    } else {
      _sectionBodyData = sectionInfo.getData( item, instanceData, reportType, relatedPaths, riskType, options );
    }
    setSectionBodyData( _sectionBodyData );
    setHasFetched( true );
    setLoading( false );
  };

  // when the section is opened, check to see if the data has been loaded yet and if it has not, do that now.
  React.useEffect( ( ) => {
    setLoading( true );
    if ( ( reportType === 'host' || reportType === 'vulnerability' ) && sectionKey === 'num_patches' ) {
      getData( { superseded } );
    } else if ( reportType === 'path' && isNotEmpty( relatedPaths ) ) {
      getData();
    } else if ( !collapsed && isEmpty( sectionBodyData ) && reportType === 'user' && !hasFetched ) {
      getData();
    } else if ( !collapsed && isEmpty( sectionBodyData ) && reportType === 'third_party' && !hasFetched ) {
      getData();
    } else if ( !collapsed && isEmpty( sectionBodyData ) && isNotEmpty( instanceData ) && !hasFetched ) {
      getData();
    }
  }, [ collapsed, relatedPaths, reportType, instanceData, sectionInfo, sectionKey, superseded ] );

  // when the item comes in with the section info, we might be able to set the count on page load before fetching
  // otherwise we can set it after the data comes back
  React.useEffect( ( ) => {
    getCount( item, sectionKey, sectionBodyData );
  }, [ item, sectionKey, sectionBodyData, instanceData ] );

  return (
    <React.Fragment>
      {
        (
          isNotEmpty( item )
          && isNotEmpty( sectionInfo )
        ) &&
        <div className={ `collapsibleSectionWrapper ${collapsed ? 'collapsed' : ''}`}>
          <div
            className="collapsibleSectionHeader"
            onClick={ () => setCollapsed( !collapsed ) }
          >
            <div className="headerLeft">
              { sectionInfo.icon }
              {
                ( sectionInfo.needsAlert && isNotEmpty( sectionBodyData ) ) &&
                <span className="statWarning">
                  <InlineSVG type="warningAlt" />
                </span>
              }
              <h3>{ sectionInfo.label }</h3>
              {
                ( sectionKey === 'num_patches' && ( reportType === 'host' || reportType === 'vulnerability' ) ) &&
                <div className={ `${superseded} toggleWrapper` }>
                  <button
                    onClick={ e => {
                      e.stopPropagation();
                      setSuperseded( 'unsuperseded' );
                    } }
                    className={ `${superseded === 'unsuperseded' ? 'toggled' : ''} toggleButton` }
                  >
                    <span>Show Unsuperseded Patches</span>
                  </button>
                  <button
                    onClick={ e => {
                      e.stopPropagation();
                      setSuperseded( 'any' );
                    } }
                    className={ `${superseded === 'any' ? 'toggled' : ''} toggleButton` }
                  >
                    <span>Show All Patches</span>
                  </button>
                </div>
              }
            </div>
            <div className="headerRight">
              {
                !noCountNeeded.includes( sectionKey ) &&
                <strong className="sectionCount">
                  { itemIsNumber( count ) ? formatNumber( count ) : count }
                </strong>
              }
              <span className="carretWrapper">
                <InlineSVG type="carretUp"/>
              </span>
            </div>
          </div>
          <div className="collapsibleSectionBody">
            <EmptyLoading
              loading={ loading }
              payload={ sectionBodyData }
              emptyMessage={ `No ${sectionInfo.label}`}
            />
            {
              isNotEmpty( sectionBodyData ) &&
              <SectionContent
                sectionCount={count}
                sectionKey={sectionKey}
                sectionData={sectionBodyData}
                reportType={reportType}
                setSelectedSignature={setSelectedSignature}
                setSelectedNode={setSelectedNode}
                setSelectedSegments={setSelectedSegments}
                setModalContext={setModalContext}
                item={item}
                relatedPaths={relatedPaths}
                riskType={riskType}
                setHoveredListItemID={ setHoveredListItemID }
                setHoveredListItemType={ setHoveredListItemType }
                setHoveredListItemRating={ setHoveredListItemRating }
                superseded={superseded}
                setSuperseded={setSuperseded}
              />
            }
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default CollapsibleSection;