/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  isNotEmpty,
  shortenedVulnerabilityScannerNameMap,
  vulnerabilityScannerLogo,
} from '../../../../shared/Utilities';
import RatingBadge from '../../../../shared/RatingBadge';

const SignatureHeader = ( {
  signature,
} ) => {

  return (
    <React.Fragment>
      {
        ( isNotEmpty( signature ) && signature.scanner !== 'internal' ) &&
        <div className="recordCardHeaderRow">
          <h2>
            { vulnerabilityScannerLogo( signature.scanner ) }
            <span className="label">{ shortenedVulnerabilityScannerNameMap[signature.scanner ] } Results</span>
          </h2>
          <RatingBadge rating={ signature.scanner_rating } />
        </div>
      }
    </React.Fragment>
  );
};

export default SignatureHeader;