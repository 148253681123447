/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import { decodeURLHash, encodeURLHash, isNotEmpty, removeFromURLHash } from '../shared/Utilities';

export const ReportCreatorContext = React.createContext();

export const ReportCreatorProvider = ( { children } ) => {

  const [ creatorActive, setCreatorActive ] = React.useState( false );

  // opens the report creator and handles any callbacks
  const openReportCreator = callback => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { creating_report: true } );
    setCreatorActive( true );
    if ( isNotEmpty( callback ) ) {
      callback();
    }
  };

  // closes the report creator and handles any passed in callbacks
  const closeReportCreator = callback => {
    // eslint-disable-next-line camelcase
    removeFromURLHash( 'creating_report' );
    setCreatorActive( false );
    if ( isNotEmpty( callback ) ) {
      callback();
    }
  };

  // used to cancel out and handle any redirects if necessary
  const cancelReportCreator = callback => {
    setCreatorActive( false );

    if ( decodeURLHash()['return_to_reports'] ) {
      window.location.href = '#.=reporting&page=exports';
    } else {
      removeFromURLHash( 'creating_report' );
      removeFromURLHash( 'return_to_reports' );
      removeFromURLHash( 'report_id' );

      if ( isNotEmpty( callback ) ) {
        callback();
      }
    }
  };

  const data = [
    creatorActive,
    setCreatorActive,
    openReportCreator,
    closeReportCreator,
    cancelReportCreator,
  ];

  return (
    <ReportCreatorContext.Provider value={ data }>
      { children }
    </ReportCreatorContext.Provider>
  );
};
