/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';

// import CopyToClipBoard from '../../../shared/CopyToClipBoard';
import EmptyState from '../../../shared/EmptyState';

import {
  isEmpty,
  isNotEmpty,
  generateSecureRandomCode,
} from '../../../shared/Utilities';

import FormModal from '../../../shared/SetupComponents/FormModal';
import { getFieldValues } from '../../../shared/Form/Shared';
import InlineSVG from '../../../shared/InlineSVG';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../legacy/io';
import PageCreateButton from '../../../shared/PageCreateButton';
import CopyToClipBoard from '../../../shared/CopyToClipBoard';

const UserManaged = () => {

  const EMPTY_FIELDS = {
    domain: {
      fields: [
        {
          type:'contentBlock',
          attribute: 'help',
          defaultValue: <p>
            {/* eslint-disable-next-line max-len */}
            <p>Please configure your user managed settings below. For more in-depth documentation and explanations of user managed settings, refer to our documentation <a href="#.=help_documentation&main=user_guide&section=scanning&help_page=user_managed" target="_blank" rel="noopener noreferrer" className="inlineLink inline">here <InlineSVG type="newTabLink" version="primary" /></a></p>
          </p>,
        },
        {
          type: 'text',
          label: 'Registration Code',
          attribute: 'registration_code',
          defaultValue: '',
          disabled: true,
          generateOptions: {
            generate: () => generateNewRegistrationCode(),
            buttonText: 'Generate new code',
          },
        },
        {
          type: 'collection',
          label: 'Domain / IP : Port',
          attribute: 'endpoints',
          required: true,
          defaultValue: [],
          needsPort: true,
          needsIPAddressOrHostname: true,
          defaultPort: 44305,
          internalValidationMessage: 'All Domains / IPs must be valid.',
        },
      ],
    },
  };

  const generateNewRegistrationCode = () => {
    // eslint-disable-next-line max-len
    if ( window.confirm( 'Are you sure you want to generate a new registration code? \n Any previously downloaded registration files will cease to work and the registration URLs will change.  In addition, all settings currently shown on this page will be saved.' ) ) {
      const newCode = generateSecureRandomCode( 40, 'hex' );
      return newCode;
    }
  };

  const [ osVersion, setOsVersion ] = React.useState( 'windows' );
  const [ thumbprint, setThumbprint ] = React.useState( null );

  const [ fields, setFields ] = React.useState( null );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const [ showModal, setShowModal ] = React.useState( false );

  // sections of the record that come from the server
  const [ existingRecord, setExistingRecord ] = React.useState( {} );

  const [ isValid, setIsValid ] = React.useState( true );
  const [ updatedForm, setUpdatedForm ] = React.useState( null );


  const initializeModal = async () => {
    const _fields = { ...EMPTY_FIELDS };
    setFields( _fields );
    onRefresh();
  };

  // On init, grab any settings from the server, setup the help and the form fields
  React.useEffect( () => {
    initializeModal();
  }, [] );

  // register and remove onleave listener
  React.useEffect( () => {
    const parentEl = document.getElementById( 'pageContent' );
    console.log( parentEl );
    if ( isNotEmpty( parentEl ) ) {
      parentEl.onleave = async () => {
        if ( isValid ) {
          const test = await makeRequest( 'FETCH', '/project/default', {} );

          if ( isNotEmpty( test ) ) {
            onSave( false );
            return true;
          }
          window.location.href = `/login.html${window.location.hash}`;
          return false;
        }
      };
    }

    return () => {
      if ( isNotEmpty( parentEl ) ) {
        parentEl.onleave = null;
      }
    };
  }, [ updatedForm, isValid ] );

  // if there is already an existing record setup, grab the thumbprint once
  React.useEffect( ( ) => {
    if ( isNotEmpty( existingRecord ) && isEmpty( thumbprint ) ) {
      makeRequest( 'COMPUTE', '/ca' ).then( response => {
        setThumbprint( response.thumbprint );
      } );
    }
  }, [ existingRecord ] );

  const onRefresh = async( ) => {
    const response = await makeRequest( 'FIND', '/project/default/agent_setting', {} );
    if ( isNotEmpty( response.results ) ) {
      setExistingRecord( response.results[0] );
    }
  };

  const onSave = async( shouldRefresh=true ) => {
    if ( isValid ) {
      if ( isNotEmpty( updatedForm ) && isNotEmpty( updatedForm.fieldStates ) ) {
        const _values = getFieldValues( updatedForm.fieldStates );

        const record = {
          id: existingRecord.id,
          // eslint-disable-next-line camelcase
          registration_code: _values.registration_code,
          endpoints: _values.endpoints,
        };

        // eslint-disable-next-line camelcase
        makeRequest( 'UPSERT', '/project/default/agent_setting', { records: [ record ] } ).then( response => {
          if ( response && response.results && response.results.length ) {
            if ( shouldRefresh ) {
              onRefresh( response.results[0] );
              addFlashMessage( {
                body: 'Successfully saved user managed settings',
                type: 'success',
              } );
            }
          } else if ( shouldRefresh ) {
            addFlashMessage( {
              body: 'Error saving user managed settings',
              type: 'alert',
            } );
          }
        } );
      }
    }
  };

  React.useEffect( ( ) => {
    onRefresh();
    initializeModal();
  }, [ showModal ] );

  return (
    <React.Fragment>
      <FormModal
        recordType="agent"
        showModal={showModal}
        setShowModal={setShowModal}
        isValid={isValid}
        setIsValid={setIsValid}
        selectedRecord={existingRecord}
        onSave={onSave}
        fields={fields}
        formChangeCallBack={setUpdatedForm}
      />
      <div className="setupFormContainer agentsConfigurationsPage">
        <PageCreateButton>
          <button
            id="agentSettingsButton"
            className="agentSettingsButton newItemButton"
            onClick={ () => setShowModal( true ) }
          >
            Edit user managed Configuration
          </button>
        </PageCreateButton>
        {
          isNotEmpty( existingRecord.endpoints )
            ? <React.Fragment>
              <section className="domainsSection">
                {
                  isEmpty( existingRecord ) &&
                  <EmptyState
                    // eslint-disable-next-line camelcase, max-len
                    message="Edit your configuration settings to add valid endpoints for your agents"
                  />
                }
                {
                  isNotEmpty( existingRecord ) &&
                  <React.Fragment>
                    <div className={ `${osVersion} toggleWrapper`}>
                      <button
                        onClick={ () => setOsVersion( 'windows' ) }
                        className={ `${osVersion === 'windows' ? 'toggled' : ''} toggleButton` }
                      >
                        <InlineSVG type="windows" version="light" />
                        <span>Windows</span>
                      </button>
                      <button
                        onClick={ () => setOsVersion( 'linux' ) }
                        className={ `${osVersion === 'linux' ? 'toggled' : ''} toggleButton` }
                      >
                        <InlineSVG type="linux" version="light" />
                        <span>Linux</span>
                      </button>
                      {/* <button
                        onClick={ () => setOsVersion( 'macOS' ) }
                        className={ `${osVersion === 'macOS' ? 'toggled' : ''} toggleButton` }
                      >
                        <InlineSVG type="macos" version="light" />
                        <span>macOS</span>
                      </button> */}
                    </div>
                    <div className="sectionHeader">Instructions</div>
                    {
                      osVersion === 'windows' &&
                      <div className="tabWrapper documentationWrapper">
                        <div className="documentationStep">
                          <h3>Step 1</h3>
                          <p>
                            Download the following script script to the Windows
                            host where you wish to install it:
                          </p>
                          <div className="downloadButtons">
                            {
                              isEmpty( existingRecord.endpoints )
                                ? <div className="disabledButton">
                                  Download
                                </div>
                                : <a
                                  className="downloadButton secondary"
                                  target="_blank"
                                  href="/agent-setup/deepsurface-windows-ephemeral.ps1"
                                  download="deepsurface-windows-ephemeral.ps1"
                                >
                                  <InlineSVG type="downloadAlt" version="risk--green" />
                                  Download
                                </a>
                            }
                          </div>
                        </div>
                        {
                          ( isNotEmpty( thumbprint ) && isNotEmpty( existingRecord.endpoints ) ) &&
                          <div className="documentationStep">
                            <h3>Step 2</h3>
                            <p>
                              {/* eslint-disable-next-line max-len */}
                              <strong>Be sure to execute the following commands in Powershell opened as an Administrator.</strong> Run the downloaded script with the following arguments to run the scanning script:
                            </p>
                            <pre>
                              {/* eslint-disable-next-line max-len */}
                              { `powershell .\\deepsurface-windows-ephemeral.ps1 ${thumbprint} ${existingRecord.registration_code} ${existingRecord.endpoints.join( ' ' )}` }
                              <CopyToClipBoard
                                // eslint-disable-next-line max-len
                                text={ `powershell .\\deepsurface-windows-ephemeral.ps1 ${thumbprint} ${existingRecord.registration_code} ${existingRecord.endpoints.join( ' ' )}` }
                              />
                            </pre>
                          </div>
                        }
                        {
                          ( isNotEmpty( thumbprint ) && isNotEmpty( existingRecord.endpoints ) ) &&
                          <div className="documentationStep">
                            <h3>Note</h3>
                            <p>
                              You may need to relax Powershell Execution Policy in order to run this script.
                              This can be achieved by either adding <code>-ExecutionPolicy bypass</code> to the
                              above command, or by first running:
                            </p>
                            <pre>
                              Set-ExecutionPolicy -Scope Process Bypass -force
                              <CopyToClipBoard
                                text="Set-ExecutionPolicy -Scope Process Bypass -force"
                              />
                            </pre>
                          </div>
                        }
                        <div className="documentationStep">
                          <h3>Integrating with MECM or Intune</h3>
                          <p>
                            The user managed scanning script can be easily managed with Microsoft MECM or Intune. For me
                            detailed information about either of those tools, see the
                            documentation <a
                              href="https://learn.microsoft.com/en-us/mem/configmgr/"
                              target="_blank"
                              rel="noreferrer noopener"
                              className="newTabLink inlineLink"
                            >
                              here (MECM)
                              <InlineSVG type="link" />
                            </a> and <a
                              href="https://learn.microsoft.com/en-us/mem/intune/"
                              target="_blank"
                              rel="noreferrer noopener"
                              className="newTabLink inlineLink"
                            >
                              here (Intune)
                              <InlineSVG type="link" />
                            </a>.
                          </p>
                        </div>
                      </div>
                    }
                    {
                      osVersion === 'linux' &&
                      <div className="tabWrapper documentationWrapper">
                        <div className="documentationStep">
                          <h3>Step 1</h3>
                          <p>
                            Download the following script script to the Linux
                            host where you wish to install it:
                          </p>
                          <div className="downloadButtons">
                            {
                              isEmpty( existingRecord.endpoints )
                                ? <div className="disabledButton">
                                  Download
                                </div>
                                : <a
                                  className="downloadButton secondary"
                                  target="_blank"
                                  href="/agent-setup/deepsurface-linux-ephemeral.sh"
                                  download="deepsurface-linux-ephemeral.sh"
                                >
                                  <InlineSVG type="downloadAlt" version="risk--green" />
                                  Download
                                </a>
                            }
                          </div>
                        </div>
                        {
                          ( isNotEmpty( thumbprint ) && isNotEmpty( existingRecord.endpoints ) ) &&
                          <div className="documentationStep">
                            <h3>Step 2</h3>
                            <p>
                              Run the downloaded script with the following arguments to run the scanning script:
                            </p>
                            <pre>
                              {/* eslint-disable-next-line max-len */}
                              { `sudo sh deepsurface-linux-ephemeral.sh ${thumbprint} ${existingRecord.registration_code} ${existingRecord.endpoints.join( ' ' )}` }
                              <CopyToClipBoard
                                // eslint-disable-next-line max-len
                                text={ `sudo sh deepsurface-linux-ephemeral.sh ${thumbprint} ${existingRecord.registration_code} ${existingRecord.endpoints.join( ' ' )}` }
                              />
                            </pre>
                          </div>
                        }
                        <div className="documentationStep">
                          <h3>Integrating with MECM or Intune</h3>
                          <p>
                            The user managed scanning script can be easily managed with Microsoft MECM or Intune. For me
                            detailed information about either of those tools, see the
                            documentation <a
                              href="https://learn.microsoft.com/en-us/mem/configmgr/"
                              target="_blank"
                              rel="noreferrer noopener"
                              className="newTabLink inlineLink"
                            >
                              here (MECM)
                              <InlineSVG type="link" />
                            </a> and <a
                              href="https://learn.microsoft.com/en-us/mem/intune/"
                              target="_blank"
                              rel="noreferrer noopener"
                              className="newTabLink inlineLink"
                            >
                              here (Intune)
                              <InlineSVG type="link" />
                            </a>.
                          </p>
                        </div>
                      </div>
                    }
                  </React.Fragment>
                }
              </section>
            </React.Fragment>
            : <EmptyState message="User managed settings have not been configured" />
        }
      </div>
    </React.Fragment>
  );
};

export default UserManaged;
