/** *************************************************************
* Copyright (C) 2016-2023 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import FilterForm from '../../../shared/FilterForm';

import { v4 as uuidv4 } from 'uuid';

import './style.scss';
import {
  isNotEmpty,
  paramsToFilters,
  decodeURLHash,
  encodeURLHash,
  recordSorter,
  formatUnixTime,
} from '../../../shared/Utilities';

import IndeterminantPagination, { getRowNums, pageIterator } from '../../../shared/Pagination/IndeterminantPagination';
import { makeRequest } from '../../../../legacy/io';
import PageHeader from '../../../shared/PageHeader';
import Modal from '../../../shared/Modal';
import EmptyLoading from '../../../shared/EmptyLoading';
import ActivityList from '../ActivityList';
import InlineSVG from '../../../shared/InlineSVG';

const FIELDS = [
  {
    type: 'debouncedText',
    attribute: 'identifier',
    label: 'Identifier',
    placeholder: 'Find log by identifier',
  },
  {
    type: 'debouncedText',
    attribute: 'keywords',
    label: 'Keywords',
    placeholder: 'Filter logs by keywords',
  },
  {
    type: 'hidden',
    attribute: 'item',
  },
  {
    type: 'hidden',
    attribute: 'item_count',
    value: 100,
    // shouldTriggerRefresh: true,
  },
];

const LogModalBody = ( { selectedIdentifier, showLogModal } ) => {

  const [ formattedLogContent, setFormattedLogContent ] = React.useState( null );
  const [ logDate, setLogDate ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( true );

  const getLogContent = async ( identifier ) => {
    setLoading( true );
    const fetchedLog = await makeRequest( 'FIND', '/project/default/scan_log', {
      // eslint-disable-next-line camelcase
      field_map: {
        identifier,
      },
    } );

    if ( isNotEmpty( fetchedLog ) && isNotEmpty( fetchedLog.results ) ) {
      setFormattedLogContent( formatLogOutput( fetchedLog.results ) );
      setLoading( false );
    } else {
      setFormattedLogContent( null );
      setLogDate( null );
      setLoading( false );
    }
  };

  const formatLogOutput = logRows => {
    const output = [];

    logRows = logRows.sort( ( a, b ) => recordSorter( 'created', true, a, b ) );

    setLogDate( formatUnixTime( logRows[0].created ) );

    logRows.map( ( log, index ) => {

      // need to cleanup and split the message into parts for formatting and syntax highlighting
      const formattedMessage = log.message.replace( '\n', '\n        ' );
      const split = formattedMessage?.split( '\u2022' ) || [];
      const [ logType, remainingLog ] = split;
      const splitIP = remainingLog?.split( '\u00BB' ) || [];
      const [ ip, message ] = splitIP;

      // const formatType = type => {
      //   if ( type === 'INFO' ) {
      //     return '[\u00A0INFO\u00A0\u00A0]';
      //   }
      //   if ( type === 'DEBUG' ) {
      //     return '[\u00A0DEBUG\u00A0]';
      //   }
      //   if ( type === 'ERROR' ) {
      //     return '[\u00A0ERROR\u00A0]';
      //   }
      //   if ( type === 'WARNING' ) {
      //     return '[WARNING]';
      //   }
      //   return `[\u00A0${type}\u00A0]`;
      // };

      output.push(
        <p key={index} className={ `${logType} logRow` }>
          <span className="lineInfo">
            <span className="timestamp">{ formatUnixTime( log.created, true ) }</span>
            <span className="ipAddress">{ `(${ip || 'N/A'})` }</span>
          </span>
          <span className="lineContent">
            <span className="content">
              <strong>{ `${logType || 'DEBUG' }  \u00BB` }</strong>
              { message || ' --' }
            </span>
          </span>
        </p>,
      );
    } );

    return output;
  };

  React.useEffect( () => {
    if ( isNotEmpty( selectedIdentifier ) && showLogModal ) {
      getLogContent( selectedIdentifier );
    } else {
      setFormattedLogContent( null );
      setLogDate( null );
    }
  }, [ selectedIdentifier, showLogModal ] );


  return (
    <React.Fragment>
      <div className="logOutputWrapper">
        <div className="header">
          <div className="iconWrapper">
            <InlineSVG type="scanning_nav" />
          </div>
          Scan Log
        </div>
        <div className="upper">
          <span>
            <strong>Created:</strong> { logDate || 'N/A' }
          </span>
          <span>
            <strong>Identifier:</strong> { selectedIdentifier || 'N/A' }
          </span>
        </div>
        {
          isNotEmpty( formattedLogContent ) &&
          <div className="logContentWrapper">
            { formattedLogContent }
          </div>
        }
        <EmptyLoading
          loading={loading}
          emptyMessage="This scan log is empty"
          payload={formattedLogContent}
        />
      </div>
    </React.Fragment>
  );
};

const ScanLogs = ( ) => {
  const [ selectedIdentifier, setSelectedIdentifier ] = React.useState( '' );

  // pagination related state variables
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState( 1 );
  const [ currentPageResults, setCurrentPageResults ] = React.useState( [] );
  const [ nextPageResults, setNextPageResults ] = React.useState( [] );

  const [ showLogModal, setShowLogModal ] = React.useState( false );

  const [ loading, setLoading ] = React.useState( false );

  let isMounted = true;

  React.useEffect( () => {
    isMounted = true;
    return () => {
      isMounted = false;
    };
  }, [] );

  const refreshLogItems =  async () => {

    const onCorrectPage = decodeURLHash()['page'] === 'scan_logs';

    if ( onCorrectPage ) {
      setLoading( true );
      const filterValues = paramsToFilters();

      const _rowNums = getRowNums( filterValues );

      const params = {
        // eslint-disable-next-line camelcase
        extra_columns: [ 'identifier', 'created', 'modified' ],
        rownums: _rowNums,
      };

      if ( isNotEmpty( filterValues ) ) {
        Object.entries( filterValues ).map( ( [ key, val ] ) => {
          if ( key === 'keywords' ) {
            params.keywords = isNotEmpty( val ) ? val : '';
          }
          if ( key === 'identifier' ) {
            // eslint-disable-next-line camelcase
            params.field_map = { identifier: val };
          }
        } );
      }

      const logsResponse = await makeRequest( 'SEARCH', '/project/default/scan_log', params );
      const pagedResults = pageIterator( logsResponse?.results, filterValues );
      const currentPageNumber = pagedResults.currentPageNumber ? parseInt( pagedResults.currentPageNumber ) : 1;
      if ( isMounted ) {
        setCurrentPageNumber( currentPageNumber );
        let _currentPageResults = [ ...pagedResults.firstPage ];
        if ( isNotEmpty( filterValues.item ) && isMounted ) {
          setSelectedIdentifier( filterValues.item );
          setShowLogModal( true );
          const currentIdentifiers = _currentPageResults.map( r => r.identifier );
          if ( !currentIdentifiers.includes( filterValues.item ) ) {
            _currentPageResults = [ { identifier: filterValues.item }, ..._currentPageResults ];
          }
        }
        setCurrentPageResults( adjustPageResults( _currentPageResults ) );
        setNextPageResults( adjustPageResults( pagedResults.secondPage ) );
      }
      setLoading( false );
    }
  };

  // whenever the results change, adjust the data for display in the table
  const adjustPageResults = ( results ) => {
    if ( results ) {
      return results.map( transformRowData );
    }
  };

  const transformRowData = ( row ) => {
    return {
      label: row.identifier,
      subLabel: row.label,
      timestamp: formatUnixTime( row.created ),
      id: uuidv4(),
      original: row,
    };
  };

  const goToPage = page => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { current_page: parseInt( page ) } );
    refreshLogItems();
  };

  const showLogFor = async ( log ) => {
    setSelectedIdentifier( log?.original?.identifier );
    setShowLogModal( true );
  };

  return (
    <React.Fragment>
      <Modal
        elementClass="scanLogOutputModal"
        visible={showLogModal}
        setVisible={setShowLogModal}
        body={ <LogModalBody
          selectedIdentifier={selectedIdentifier}
          showLogModal={showLogModal}
        /> }
      />
      <PageHeader elementClass="scanLogsHeaderWrapper">
        <FilterForm
          inputs={FIELDS}
          onRefresh={refreshLogItems}
          reportType="scanning"
        />
        <IndeterminantPagination
          currentPageNumber={currentPageNumber}
          nextPageResults={nextPageResults}
          goToPage={goToPage}
          elementClass="riskInsightPagination"
        />
      </PageHeader>
      <EmptyLoading
        loading={loading}
        payload={currentPageResults}
        emptyMessage="No scan logs matched the current filters"
      />
      {
        isNotEmpty( currentPageResults ) &&
        <ActivityList
          items={ currentPageResults }
          viewItem={ showLogFor }
          itemType="scanLog"
        />
      }
      {/* <div className="logsWrapper">
        <div className="availableLogItems">
          <h3>
            {
              isNotEmpty( recordBounds )
                ? <span>Logs ({recordBounds[0]} - {recordBounds[1]})</span>
                : <span>Logs</span>
            }
          </h3>
          <ul>
            {
              isNotEmpty( currentPageResults ) &&
              <React.Fragment>
                {
                  currentPageResults.map( ( logItem, i ) => {
                    return  <LogItem
                      logItem={logItem}
                      key={i}
                      selectedIdentifier={selectedIdentifier}
                      setSelectedIdentifier={setSelectedIdentifier}
                      getSelectedItem={ getSelectedItem }
                      setRawLogContent={setRawLogContent}
                    />;
                  } )
                }

              </React.Fragment>
            }
          </ul>

        </div>
        <LogOutput
          rawLogContent={rawLogContent}
          selectedIdentifier={selectedIdentifier}
        />
      </div> */}

    </React.Fragment>
  );
};

export default ScanLogs;