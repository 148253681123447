/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import Bar from '../../../shared/Charts/Bar';

import {
  formatRiskReduction,
  getDimensionsAndOffset,
  globalColors,
  isNotEmpty,
  reportTypeDisplayName,
  riskColorMap,
  riskToRating,
} from '../../../shared/Utilities';
import { RiskContext } from '../../../Contexts/Risk';
import Legend from '../../../shared/Charts/Legend';
import { FullScreenVisualContext } from '../../../Contexts/FullScreenVisual';
import InlineSVG from '../../../shared/InlineSVG';

const DefaultVisual = ( {
  records,
  collapsed,
  reportType,
  selectRecord,
  hoverRecord,
  riskType,
} ) => {
  const [ barChartData, setBarChartData ] = React.useState( {} );
  const [ tics, setTics ] = React.useState( null );
  const [ , globalRisk, targetRisk, ,  ] = React.useContext( RiskContext );
  const [ , , showVisual, , showFullScreenVisual, , , ] = React.useContext( FullScreenVisualContext );

  const [ svgContainerWidth, setSVGContainerWidth ] = React.useState( 1 );
  const [ svgContainerHeight, setSVGContainerHeight ] = React.useState( 1 );
  const [ legendData, setLegendData ] = React.useState( null );

  const [ hoveredSeries, setHoveredSeries ] = React.useState( null );

  const svgContainerRef = React.useRef( null );

  let windowWidth = window.innerWidth;

  // fires on all resize events, throttles to changes greater than 100px
  const adjustSVGAspectRatio = ( onPageLoad=false ) => {

    const adjustmentThreshold = 100;

    if ( isNotEmpty( svgContainerRef ) && isNotEmpty( svgContainerRef.current ) ) {

      if ( ( Math.abs( window.innerWidth - windowWidth ) > adjustmentThreshold ) || onPageLoad ) {
        const dimensions = getDimensionsAndOffset( svgContainerRef.current );
        setSVGContainerWidth( dimensions.width );
        setSVGContainerHeight( dimensions.height );

        windowWidth = window.innerWidth;
      }

    }
  };

  // sets up resize aspect ratio event listener
  React.useEffect( ( ) => {
    if ( isNotEmpty( svgContainerRef ) && isNotEmpty( records ) ) {
      windowWidth = window.innerWidth;

      setTimeout( () => {
        adjustSVGAspectRatio( true );
      }, 100 );

      window.addEventListener( 'resize', adjustSVGAspectRatio );
      return () => window.removeEventListener( 'resize', adjustSVGAspectRatio );
    }
  }, [ svgContainerRef, records ] );

  // formats all of the data so that it conforms to what the bar chart and legend expect
  React.useEffect( () => {
    if ( isNotEmpty( records ) && isNotEmpty( reportType ) ) {

      const _tics = [];

      let _highestRisk = 1;

      records.map( record => {
        if ( record[riskType] > _highestRisk ) {
          _highestRisk = record[riskType];
        }
      } );

      _tics.push( formatRiskReduction( _highestRisk, globalRisk ) );
      _tics.push( formatRiskReduction( _highestRisk * ( 2 / 3 ), globalRisk ) );
      _tics.push( formatRiskReduction( _highestRisk * ( 1 / 3 ), globalRisk ) );

      setTics( _tics );

      const _barData = { };

      const _legendData = {
        critical: { key: 'critical', label: 'Critical', total: 0, fill: globalColors.critical },
        high: { key: 'high', label: 'High', total: 0, fill: globalColors.high },
        moderate: { key: 'moderate', label: 'Moderate', total: 0, fill: globalColors.moderate },
        low: { key: 'low', label: 'Low', total: 0, fill: globalColors.low },
        minimal: { key: 'minimal', label: 'Minimal', total: 0, fill: globalColors.minimal },
      };

      if ( reportType === 'host' ) {
        _legendData.unknown = { key: 'unknown', label: 'Unknown', total: 0, fill: globalColors['grey--icon'] };
      }

      records.map( record => {
        if ( isNotEmpty( record ) ) {

          let rating = riskToRating( record[riskType], targetRisk );

          if ( reportType === 'host' && !record.has_host ) {

            rating = 'unknown';

            _barData[record.id] = {
              original: record,
              label: reportTypeDisplayName( record, reportType ),
              value: record[riskType],
              fill: riskColorMap[rating],
              key: rating,
            };
          } else {
            _barData[record.id] = {
              original: record,
              label: reportTypeDisplayName( record, reportType ),
              value: record[riskType],
              fill: riskColorMap[rating],
              key: rating,
            };
          }

          _barData[record.id] = {
            original: record,
            label: reportTypeDisplayName( record, reportType ),
            value: record[riskType],
            fill: riskColorMap[rating],
            key: rating,
          };

          _legendData[rating].total += 1;
        }
      } );

      setLegendData( _legendData );

      setBarChartData( _barData );
    } else {
      setBarChartData( null );
    }
  }, [ records, reportType ] );

  // when the hover record is externally modified ( ie, the user hovers over a row in the table)
  // then we need to show that in the barChart
  React.useEffect( ( ) => {
    const indicators = document.getElementsByClassName( 'hoverIndicator' );
    const bars = document.getElementsByClassName( 'barSegment' );

    [].forEach.call( indicators, ( el ) => {
      el.classList.remove( 'isSelected' );
    } );

    [].forEach.call( bars, ( el ) => {
      el.classList.remove( 'isSelected' );
    } );

    if ( isNotEmpty( hoverRecord ) ) {
      const _id = hoverRecord.id;
      const bar = document.getElementById( `barSegmentID_${_id}` );
      const indicator = document.getElementById( `hoverIndicatorID_${_id}` );
      bar.classList.add( 'isSelected' );
      indicator.classList.add( 'isSelected' );
    }
  }, [ hoverRecord ] );

  const onClick = element => {
    if ( isNotEmpty( element ) ) {
      // eslint-disable-next-line
      const _id = element.id.split( '_' )[1];
      selectRecord( _id );
    }
  };

  return (
    <div className="dashboardWidgetWrapper noWrapper">
      {
        isNotEmpty( barChartData ) &&
        <React.Fragment>
          <div className="widgetContent" ref={svgContainerRef} >
            {
              isNotEmpty( tics ) &&
              <div className="chartAxis yAxis">
                {
                  tics.map( ( t, index ) => {
                    return <div className="tic" key={index} >{ t }</div>;
                  } )
                }
              </div>
            }
            <Bar
              data={barChartData}
              containerHeight={ svgContainerHeight }
              containerWidth={ svgContainerWidth }
              onClick={ onClick }
              insightVersion
              hoveredSeriesIdentifier={ hoveredSeries }
              // setHoveredSeriesIdentifier={ setHoveredSeries }
            />
            <Legend
              legendData={legendData}
              hoveredSeriesIdentifier={ hoveredSeries }
              setHoveredSeriesIdentifier={ setHoveredSeries}
            />

          </div>
          {
            !collapsed &&
            <button
              className="fullScreenVisualToggleButton minimal"
              onClick={ () => showFullScreenVisual(
                <div className="widgetContent" ref={svgContainerRef} >
                  {
                    isNotEmpty( tics ) &&
                    <div className="chartAxis yAxis">
                      {
                        tics.map( ( t, index ) => {
                          return <div className="tic" key={index} >{ t }</div>;
                        } )
                      }
                    </div>
                  }
                  <Bar
                    data={barChartData}
                    containerHeight={ svgContainerHeight }
                    containerWidth={ svgContainerWidth }
                    onClick={ onClick }
                    insightVersion
                    hoveredSeriesIdentifier={ hoveredSeries }
                    // setHoveredSeriesIdentifier={ setHoveredSeries }
                  />
                  <Legend
                    legendData={legendData}
                    hoveredSeriesIdentifier={ hoveredSeries }
                    setHoveredSeriesIdentifier={ setHoveredSeries}
                  />
                </div>,
                'riskInsightIndex',
              ) }
            >
              { showVisual ? <InlineSVG type="exitFullscreen" /> : <InlineSVG type="fullscreen" /> }
            </button>
          }
        </React.Fragment>
      }
    </div>
  );
};

export default DefaultVisual;