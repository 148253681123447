/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, isEmpty, isNotEmpty, reportTypeDisplayName, riskToRating } from '../../../../shared/Utilities';
import InlineSVG from '../../../../shared/InlineSVG';

import './EdgeContent.scss';
import CombinedVulnerabilitySignatureContent from './CombinedVulnerabilitySignatureContent';
import RiskPercentageBar from '../../../../shared/RiskPercentageBar';
import RiskReduction from '../../../../shared/RiskReduction';
import EmptyLoading from '../../../../shared/EmptyLoading';
import { getEscalations } from '../shared';

const EdgeContent = ( {
  edge,
  options,
  selectItem,
  additionalContent,
  setAdditionalContent,
  context,
} ) => {
  const [ loadingVulnerabilities, setLoadingVulnerabilities ] = React.useState( false );
  const [ vulnerabilities, setVulnerabilities ] = React.useState( null );
  const [ highestRisk, setHighestRisk ] = React.useState( null );

  const [ showEdgeVulnerabilities, setShowEdgeVulnerabilities ] = React.useState( true );

  const [ fromNodeLabel, setFromNodeLabel ] = React.useState( null );
  const [ toNodeLabel, setToNodeLabel ] = React.useState( null );

  const [ selectedVulnerabilityID, setSelectedVulnerabilityID ] = React.useState( null );

  const [ showOnPaths, setShowOnPaths ] = React.useState( true );

  const [ onPaths, setOnPaths ] = React.useState( null );

  const [ edgeCount, setEdgeCount ] = React.useState( 1 );

  React.useEffect( () => {
    if ( isNotEmpty( edge ) ) {

      if (
        isNotEmpty( edge.pathIDs )
        && isNotEmpty( options )
        && isNotEmpty( options.relatedPaths )
        && isNotEmpty( options.relatedPaths.results )
        && isNotEmpty( options.relatedPaths.results.paths )
      ) {
        const _onPaths = edge.pathIDs.map( id => options.relatedPaths.results.paths.find( p => p.id === id ) );

        if ( isNotEmpty( _onPaths ) ) {
          setOnPaths( _onPaths );
        }
      }

      if ( isNotEmpty( edge.includedEdges ) ) {
        const [ firstEdge ] = edge.includedEdges;

        const lastEdge = edge.includedEdges[ edge.includedEdges.length - 1 ];

        setFromNodeLabel( firstEdge?.fromNodeFull?.label );
        setToNodeLabel( lastEdge?.toNodeFull?.label );

        setEdgeCount( edge.includedEdges.length );

      } else if ( isNotEmpty( edge.fromNode ) && isNotEmpty( edge.toNode ) ) {
        setFromNodeLabel( edge.fromNode?.label );
        setToNodeLabel( edge.toNode?.label );
      } else {
        setFromNodeLabel( 'N/A' );
        setToNodeLabel( 'N/A' );
      }
    }
  }, [ edge, options ] );

  React.useEffect( () => {
    if ( isEmpty( additionalContent ) ) {
      setSelectedVulnerabilityID( null );
    }
  }, [ additionalContent ] );

  const setupContent = async () => {

    let _edge = edge;

    setLoadingVulnerabilities( true );

    if ( isNotEmpty( edge.includedEdges ) ) {
      if ( edge.includedEdges.length > 1 ) {

        const promises = edge.includedEdges.map( getEscalations );

        const resolvedPromises = await Promise.all( promises );

        let allVulns = {};

        resolvedPromises.map( resolved => {
          const { vulnerabilities } = resolved;

          allVulns = { ...allVulns, ...vulnerabilities };
        } );

        const _vulnerabilities = Object.values( allVulns ).sort( ( a, b ) => b.risk - a.risk );
        const _highestRisk = Math.max( ..._vulnerabilities.map( v => v.risk ) );

        setLoadingVulnerabilities( false );
        setVulnerabilities( _vulnerabilities );
        setHighestRisk( _highestRisk );
      } else {
        [ _edge ] = edge.includedEdges;

        const escalations = await getEscalations( _edge );
        if ( isNotEmpty( escalations ) && isNotEmpty( escalations.vulnerabilities ) ) {
          const _vulnerabilities = Object.values( escalations.vulnerabilities ).sort( ( a, b ) => b.risk - a.risk );
          setLoadingVulnerabilities( false );
          setVulnerabilities( _vulnerabilities );
          const _highestRisk = Math.max( ..._vulnerabilities.map( v => v.risk ) );
          setHighestRisk( _highestRisk );
        } else {
          setLoadingVulnerabilities( false );
          setVulnerabilities( null );
        }
      }
    } else {
      const escalations = await getEscalations( _edge );
      if ( isNotEmpty( escalations ) && isNotEmpty( escalations.vulnerabilities ) ) {
        const _vulnerabilities = Object.values( escalations.vulnerabilities ).sort( ( a, b ) => b.risk - a.risk );
        setLoadingVulnerabilities( false );
        setVulnerabilities( _vulnerabilities );
        const _highestRisk = Math.max( ..._vulnerabilities.map( v => v.risk ) );
        setHighestRisk( _highestRisk );
      } else {
        setLoadingVulnerabilities( false );
        setVulnerabilities( null );
      }
    }

  };

  React.useEffect( () => {
    if ( isNotEmpty( edge ) ) {
      setupContent();
    } else {
      setHighestRisk( null );
      setVulnerabilities( null );
    }
  }, [ edge ] );

  const toggleAdditional = ( vulnerability, edge ) => {
    if ( isNotEmpty( selectedVulnerabilityID ) && selectedVulnerabilityID === vulnerability.id ) {
      setSelectedVulnerabilityID( null );
      setAdditionalContent( null );
    } else if ( isNotEmpty( vulnerability ) && isNotEmpty( edge ) ) {
      setSelectedVulnerabilityID( vulnerability.id );
      setAdditionalContent(
        <CombinedVulnerabilitySignatureContent
          vulnerability={ vulnerability }
          edge={ edge }
          context={ context }
          selectItem={ selectItem }
        />,
      );
    }
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( edge ) &&
        <React.Fragment>
          {
            isNotEmpty( onPaths ) &&
            <div
              // eslint-disable-next-line max-len
              className={ `collapsibleSectionWrapper ${showOnPaths ? '' : 'collapsed' } edgeContentOnPathsContainer` }
            >
              <div
                className="collapsibleSectionHeader"
                onClick={ () => setShowOnPaths( !showOnPaths ) }
              >
                <div className="headerLeft">
                  <InlineSVG
                    elementClass="itemTypeIcon"
                    type="pathsAlt"
                  />
                  <h3>
                    Paths
                  </h3>
                </div>
                <div className="headerRight">
                  <strong className="sectionCount">
                    ({ onPaths.length })
                  </strong>
                  <span className="carretWrapper">
                    <InlineSVG type="carretUp"/>
                  </span>
                </div>
              </div>
              <div className="collapsibleSectionBody">
                <ul>
                  {
                    onPaths.map( ( path, index ) => {
                      return <li
                        key={index}
                      >
                        <a
                          // eslint-disable-next-line max-len
                          href={ `#.=explore&page=explore_paths&item_count=100&sort_by=risk&sort_direction=DESC&item=${path.id}` }
                          target="_blank"
                          rel="noreferrer noopener"
                          className={ `onPathsLink risk-${riskToRating( path.risk ) }` }
                        >
                          {/* eslint-disable-next-line max-len */}
                          <span className="name" >Path to { path.node_labels[ path.node_labels.length - 1][1] } <strong>( { formatNumber( path.edges.length ) } Steps )</strong></span>
                          <div className="actions">
                            <RiskReduction item={path} riskType="risk" />
                            <InlineSVG type="newTabLink" />
                          </div>
                        </a>
                      </li>;
                    } )
                  }
                </ul>
              </div>
            </div>

          }
          {
            edgeCount === 1
              ? <p className="edgeViewerDescription">
                The following vulnerabilities or architectural elements describe how a digital intruder
                with <strong>"{ fromNodeLabel }"</strong> access would be able to escalate
                privileges to <strong>"{ toNodeLabel }."</strong>
              </p>
              : <p className="edgeViewerDescription">
                This "segment" is a combination of <strong>{ edgeCount } segements.</strong> Because the
                vulnerabilities or architectual elements in these segments are similar enough, they were
                combined for simplicity. The following vulnerabilities represent how a digital intruder
                with <strong>{ fromNodeLabel }"</strong> access would be able to escalate
                privileges all the way to <strong>"{ toNodeLabel }"</strong> at the end of the combined
                segments.
              </p>
          }
          <div className="edgeContentvulnerabilitiesSectionWrapper">
            <EmptyLoading
              loading={ loadingVulnerabilities }
              payload={ vulnerabilities }
              emptyMessage="This segment does not have any vulnerabilities"
            />
            {
              isNotEmpty( vulnerabilities ) &&
              <div
                // eslint-disable-next-line max-len
                className={ `collapsibleSectionWrapper ${showEdgeVulnerabilities ? '' : 'collapsed' }` }
              >
                <div
                  className="collapsibleSectionHeader"
                  onClick={ () => setShowEdgeVulnerabilities( !showEdgeVulnerabilities ) }
                >
                  <div className="headerLeft">
                    <InlineSVG
                      elementClass="itemTypeIcon"
                      type="vulnerabilitiesAlt"
                    />
                    <h3>
                      Vulnerabilities
                    </h3>
                  </div>
                  <div className="headerRight">
                    <strong className="sectionCount">
                      ({ Object.values( vulnerabilities ).length })
                    </strong>
                    <span className="carretWrapper">
                      <InlineSVG type="carretUp"/>
                    </span>
                  </div>
                </div>
                <div className="collapsibleSectionBody">
                  <ul>
                    {
                      vulnerabilities.map( ( vulnerability, index ) => {
                        return <li
                          // eslint-disable-next-line max-len
                          className={ `edgeContentvulnerabilityItemWrapper ${ selectedVulnerabilityID === vulnerability.id ? 'selected' : '' }`}
                          key={index}
                          onClick={ () => toggleAdditional( vulnerability, edge ) }
                        >
                          <span
                            className="name vulnerability"
                          >
                            { reportTypeDisplayName( vulnerability, 'vulnerability' ) }
                          </span>
                          {
                            isNotEmpty( highestRisk ) &&
                            <RiskPercentageBar
                              itemRisk={ vulnerability.risk }
                              itemRating={ riskToRating( vulnerability.risk ) }
                              totalRisk={ highestRisk }
                              minimalVariant
                            />
                          }
                          <RiskReduction item={vulnerability} riskType="risk" />
                        </li>;
                      } )
                    }
                  </ul>
                </div>
              </div>
            }
          </div>

        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default EdgeContent;