/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../../shared/Utilities';
import { fetchForWidgetsV2, getWidgetDescription } from '../../shared';

import './WidgetDescription.scss';

const WidgetDescription = ( {
  item,
  prefetchedData,
  settings,
} ) => {

  const [ description, setDescription ] = React.useState( null );

  const setupDescription = async () => {
    if ( isNotEmpty( fetchForWidgetsV2 ) && isNotEmpty( fetchForWidgetsV2[item.key] ) ) {
      const [ dataKey ] = fetchForWidgetsV2[item.key];

      let descriptionData;

      if ( isNotEmpty( prefetchedData ) && isNotEmpty( prefetchedData[dataKey] ) ) {
        descriptionData = prefetchedData[dataKey];

        if ( descriptionData.results ) {
          descriptionData = descriptionData.results;
        }

        if ( item.settings && item.settings.report_type ) {
          descriptionData = descriptionData[item.settings.report_type];
        }
      }

      const _description = getWidgetDescription( item, descriptionData );
      setDescription( _description );
    } else {
      setDescription( null );
    }

  };

  React.useEffect( () => {
    if ( isNotEmpty( item ) ) {
      setupDescription();
    }
  }, [ item, prefetchedData, settings ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( description ) &&
        <div className="widgetDescriptionContainer">
          { description }
        </div>
      }
    </React.Fragment>
  );
};

export default WidgetDescription;