/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './VulnerabilityInstancesTotal.scss';
import { formatNumber, isNotEmpty } from '../../../../../shared/Utilities';
import { v2Fetches } from '../../shared';
import InlineSVG from '../../../../../shared/InlineSVG';

const VulnerabilityInstancesTotal = ( { prefetchedData, settings } ) => {

  const [ total, setTotal ] = React.useState( 0 );

  const refetchData = async () => {
    const _tallyResults = await v2Fetches.tally();

    if ( isNotEmpty( _tallyResults ) ) {
      setTotal( _tallyResults.results_total );
    }
  };

  React.useEffect( () => {
    if (
      isNotEmpty( prefetchedData )
      && isNotEmpty( prefetchedData.tally )
      && isNotEmpty( prefetchedData.tally.results )
      && isNotEmpty( prefetchedData.tally.results._total )
    ) {
      setTotal( prefetchedData.tally.results._total );
    } else {
      refetchData();
    }
  }, [ prefetchedData, settings ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( total ) &&
        <div className="bigNumberWrapper">
          <h3>Vulnerability Instances</h3>
          <h2>
            <InlineSVG type="vulnerabilitiesAlt" />
            { formatNumber( total ) }
          </h2>
          <span>found in your environment</span>
        </div>
      }
    </React.Fragment>
  );
};

export default VulnerabilityInstancesTotal;