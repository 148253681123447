/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import ReportCreator from '../../../shared/ReportCreator';
import { ReportCreatorContext } from '../../../Contexts/ReportCreator';

import './ExportModal.scss';

const ExportModal = ( {
  existingReport,
  setExistingReport,
  show,
  closeCallback,
} ) => {

  const [ creatorActive, , , closeReportCreator, , ] = React.useContext( ReportCreatorContext );
  return (
    <React.Fragment>
      {
        ( show && creatorActive ) &&
        <React.Fragment>
          <div className="dashboardExportModalShade" onClick={ () => closeReportCreator( closeCallback ) }/>
          <div className="dashboardExportModal">
            <ReportCreator
              existingReport={existingReport}
              setExistingReport={setExistingReport}
              advanced
            />
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ExportModal;