/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { globalColors, isEmpty, isNotEmpty, riskToRating } from '../../../shared/Utilities';
import { ADVERSARY_NODE, ICON_SIZE, MENU_WIDTH, NODE_SIZE, SCOPE_PADDING } from './shared';
import { getNodeIcon } from '../../RiskInsight/Detail/shared';
import './GraphNode.scss';

const GraphNode = ( {
  node,
  handleItemMouseUp,
  handleItemMouseDown,
  focusNodes=[],
  setFocusNodes,
  focusEdges=[],
  setFocusEdges,
  externalHoverIDs,
  setSelectedItem,
  setSelectedItemType,
  selectingSecondaryItem,
  setSelectingSecondaryItem,
  ignoreClick,
  setIgnoreClick,
  setSVGScale,
  setSVGPanShift,
  svgDimensions,
  collapsedGraphMenu,
  setIsZoomed,
  setContextMenuItem,
  setContextMenuType,
  setFirstClickedItem,
} ) => {

  const [ iconSize, setIconSize ] = React.useState( ICON_SIZE );

  const handleNodeRightClick = ( e, node ) => {
    if ( isNotEmpty( e ) && e.button === 2 ) {
      e.stopPropagation();
      e.preventDefault();
      const _item = { ...node, clickEvent: e };
      setFirstClickedItem( _item );
      setContextMenuItem( _item );
      setContextMenuType( 'node' );
      return false;
    }
  };

  // when double-clicking a node, we want to zoom into the node
  const handleNodeDoubleClick = ( e, node ) => {

    if ( isNotEmpty( e ) && e.button === 0 ) {
      e.stopPropagation();
      e.preventDefault();

      const additionalX = collapsedGraphMenu ? SCOPE_PADDING * 4 : ( MENU_WIDTH + ( SCOPE_PADDING * 4 ) );
      const additionalY = collapsedGraphMenu ? 5 * 16 : 2 * 16;

      // find the optimal scale, then cut it in half because we don't need to be that zoomed into a node
      // eslint-disable-next-line max-len
      const scale = ( svgDimensions.w - additionalX ) / ( node?.w ) * 0.5;

      const x = ( ( node?.x ) * scale * -1 ) + additionalX;
      const y = ( node?.y ) * scale * -1 + additionalY;

      setSVGScale( scale );
      setSVGPanShift( { x, y } );
      setIsZoomed( true );
    }
  };

  // when clicking on a node it is for 2 reasons:
  // 1: We want to select this node to interact with it in some way
  // 2: We want to select this node as a secondary node for creating an edge, or finding paths
  const handleNodeClick = ( e, node ) => {
    if ( isNotEmpty( e ) && e.button === 0 ) {
      e.stopPropagation();
      e.preventDefault();

      const _item = { ...node, clickEvent: e };

      // DO NOT REMOVE!!! used by devs to debug explore behavior -DMC 2023-07-27
      console.log( _item );

      if ( ignoreClick ) {
        setIgnoreClick( false );
      } else if ( isEmpty( selectingSecondaryItem ) ) {
        const allNodeEdges = [ ..._item.fromEdges, ..._item.toEdges ];
        // deselecting
        if ( isNotEmpty( focusNodes && focusNodes.includes( _item.id ) ) ) {
          let _nodes = [ ...focusNodes ];
          _nodes = _nodes.filter( n => n !== _item.id );
          setFocusNodes( _nodes );
          if ( isNotEmpty( focusEdges ) ) {
            let _edges = [ ...focusEdges ];
            _edges = _edges.filter( e => !allNodeEdges.includes( e ) );
            setFocusEdges( _edges );
          }
          setSelectedItem( null );
          setSelectedItemType( null );
        // selecting
        } else {
          setSelectedItem( _item );
          setSelectedItemType( 'node' );
          setFocusNodes( [ _item.id ] );
          setFocusEdges( allNodeEdges );
        }
      } else {
        setSelectingSecondaryItem( null );
      }
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( node ) && node.id === ADVERSARY_NODE.id ) {
      setIconSize( ICON_SIZE * 1.75 );
    }
  }, [ node ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( node ) &&
        <g
          // eslint-disable-next-line max-len
          className={ `${ externalHoverIDs.includes( node.id ) ? 'externallyHovered' : ''} ${ node.id === ADVERSARY_NODE.id ? 'isAdversary' : ''} graphModelNodeGroup risk-${riskToRating( node.risk ) } ${ focusNodes?.includes( node.id ) ? 'focused' : '' }` }
          onMouseDown={ handleItemMouseDown }
          onMouseUp={ handleItemMouseUp }
        >
          {
            node.id !== ADVERSARY_NODE.id &&
            <circle
              className="edgeMask"
              cx={ node.center.x }
              cy={ node.center.y }
              r={ ( NODE_SIZE / 2 ) * 0.8 }
              fill="#FFF"
            />
          }
          <svg
            width={ iconSize }
            height={ iconSize }
            x={ node.center.x - ( iconSize / 2 ) }
            y={ node.center.y - ( iconSize / 2 ) }
            viewBox="0 0 32 32"
            fill="none"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            className="svgNodeIcon"
          >
            { getNodeIcon( node ) }
          </svg>
          <text
            x={ node.center.x }
            // eslint-disable-next-line max-len
            y={ node.center.y + ( iconSize + ( node.id === ADVERSARY_NODE.id ? -4 : 24 ) ) }
            fill={ globalColors['darkBlue'] }
            // fillOpacity={ 0.7 }
            fontSize={ node.id === ADVERSARY_NODE.id ? iconSize / 2 : iconSize / 1.75 }
            textAnchor="middle"
            fontWeight={ 600 }
          >
            { node.label?.length > 21 ? `${node.label?.slice( 0, 20 )}...` : node.label }
          </text>

          <g>
            <circle
              className="draggingMask"
              cx={ node.center.x }
              cy={ node.center.y }
              r={ ( NODE_SIZE * 0.6 ) }
              fill={ globalColors['darkBlue'] }
              fillOpacity={ node.id === ADVERSARY_NODE.id ? 0 : 0.05 }

            />
            <path
              className="pathDraggingMask"
              // eslint-disable-next-line max-len
              d={ `M ${node.center.x - ( NODE_SIZE * 0.6 )} ${node.center.y - ( NODE_SIZE * 0.6 )} L ${node.center.x + ( NODE_SIZE * 0.6 )} ${node.center.y - ( NODE_SIZE * 0.6 )} L ${node.center.x + ( NODE_SIZE * 0.6 )} ${node.center.y + ( iconSize + ( node.id === ADVERSARY_NODE.id ? -30 : -6 ) )} L ${ node.x + node.w } ${node.center.y + ( iconSize + ( node.id === ADVERSARY_NODE.id ? -30 : -6 ) )} L ${ node.x + node.w } ${node.center.y + ( iconSize + ( node.id === ADVERSARY_NODE.id ? 6 : 30 ) )} L ${node.x} ${node.center.y + ( iconSize + ( node.id === ADVERSARY_NODE.id ? 6 : 30 ) )} L ${node.x} ${node.center.y + ( iconSize + ( node.id === ADVERSARY_NODE.id ? -30 : -6 ) )} L ${node.center.x - ( NODE_SIZE * 0.6 )} ${node.center.y + ( iconSize + ( node.id === ADVERSARY_NODE.id ? -30 : -6 ) )} L ${node.center.x - ( NODE_SIZE * 0.6 )} ${node.center.y - ( NODE_SIZE * 0.6 )}`}
              fill="#FFF"
              fillOpacity={ 0.01 }
              onClick={ e => {
                e.preventDefault();
                e.stopPropagation();
                handleNodeClick( e, node );
                return false;
              } }
              onDoubleClick={ e => {
                e.preventDefault();
                e.stopPropagation();
                handleNodeDoubleClick( e, node );
                return false;
              } }
              // need to prevent here before passed to the handler or it will still trigger the default right-click menu
              onContextMenu={ e => {
                e.preventDefault();
                e.stopPropagation();
                handleNodeRightClick( e, node );
                return false;
              } }
              id={ node.id }
            />
          </g>
        </g>
      }
    </React.Fragment>
  );
};

export default GraphNode;

