/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './ScanningAgeBreakdown.scss';
import { isNotEmpty, globalColors, isEmpty, formatNumber } from '../../../../../shared/Utilities';
import { v2Fetches } from '../../shared';
import Donut from '../../../../../shared/Charts/Donut';
import EmptyLoading from '../../../../../shared/EmptyLoading';
import Legend from '../../../../../shared/Charts/Legend';

const ScanningAgeBreakdown = ( { prefetchedData, settings } ) => {

  const scanningAgeOrder = {
    /* eslint-disable camelcase */
    last_24_hours: { label: '24 Hours', fill: globalColors['status--green'] },
    last_7_days: { label: '7 Days', fill: globalColors['status--green--50'] },
    last_30_days: { label: '30 Days', fill: globalColors['grey--divider'] },
    greater_than_30_days: { label: '>30 Days', fill: globalColors['status--red--40'] },
    never_scanned: { label: 'Never Scanned', fill: globalColors['status--red'] },
    /* eslint-enable camelcase */
  };

  const [ ageTotal, setAgeTotal ] = React.useState( 1 );
  const [ ageData, setAgeData ] = React.useState( null );

  const [ loading, setLoading ] = React.useState( false );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );

  const setupData = ( tallyData ) => {
    const _ageData = {};

    setAgeTotal( tallyData?.all );

    const zeroed = tallyData?.all === 0;

    Object.entries( scanningAgeOrder ).map( ( [ key, data ] ) => {
      _ageData[key] = {
        stroke: data.fill,
        value: zeroed ? zeroed : ( ( tallyData[key] / tallyData.all ) * 100 ),
        total: tallyData[key],
        key,
      };
    } );

    setAgeData( _ageData );
  };

  const refetchData = async ( type='credentialed' ) => {
    setLoading( true );

    let _tallyResults;

    if ( type === 'credentialed' ) {
      _tallyResults = await v2Fetches.scanning_credentialed();
    } else {
      _tallyResults = await v2Fetches.scanning_agent();
    }
    setLoading( false );

    if ( isNotEmpty( _tallyResults ) ) {
      setupData( _tallyResults.category );
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( prefetchedData ) ) {
      if ( isNotEmpty( settings ) && ( 'scanning_type' in settings ) ) {
        if ( settings.scanning_type === 'agent' && isNotEmpty( prefetchedData.scanning_agent ) ) {
          setupData( prefetchedData.scanning_agent.category );
        } else if ( settings.scanning_type === 'credentialed' && isNotEmpty( prefetchedData.scanning_credentialed ) ) {
          setupData( prefetchedData.scanning_credentialed.category );
        } else if ( settings.scanning_type === 'agent' && isEmpty( prefetchedData.scanning_agent ) ) {
          refetchData( 'agent' );
        } else if ( settings.scanning_type === 'agent' && isEmpty( prefetchedData.scanning_credentialed ) ) {
          refetchData( 'credentialed' );
        }
      } else if (
        isNotEmpty( prefetchedData.scanning_credentialed )
        && isNotEmpty( prefetchedData.scanning_credentialed.category )
      ) {
        setupData( prefetchedData.scanning_credentialed.category );
      } else {
        refetchData( 'credentialed' );
      }
    }
  }, [ prefetchedData, settings ] );

  return (
    <React.Fragment>
      <EmptyLoading
        payload={ ageData }
        loading={ loading }
        loadingMessage="Loading Scan Group Data"
        emptyMessage="No Scan Group Data"
      />
      {
        ( isNotEmpty( ageTotal ) && isNotEmpty( ageData ) ) &&
        <React.Fragment>
          {
            settings?.scanning_type === 'credentialed'
              ? <p><strong>{ formatNumber( ageTotal ) }</strong> hosts scanned via credentialed scans</p>
              : <p><strong>{ formatNumber( ageTotal ) }</strong> hosts scanned via user managed/agent scans</p>
          }
          <Donut
            data={ageData}
            total={ageTotal}
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
          <Legend
            legendData={ ageData }
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ScanningAgeBreakdown;