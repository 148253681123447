/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, isNotEmpty } from '../../../../shared/Utilities';
import InlineSVG from '../../../../shared/InlineSVG';
import { getInsightURL } from '../shared';

const SectionDescription = ( {
  sectionKey,
  sectionData,
  reportType,
  sectionCount,
  item,
  riskType,
} ) => {

  const [ description, setDescription ] = React.useState( null );

  const formatDescription = () => {
    const _description = [];

    if ( sectionKey === 'num_vulnerabilities' ) {
      if ( reportType === 'host' ) {
        _description.push(
          <span>The following vulnerabilities were found on this host.</span>,
        );
      } else if ( riskType === 'direct_risk' ) {
        _description.push(
          // eslint-disable-next-line max-len
          <span>The following vulnerabilities (found in your environment) are directly addressed by this security patch.</span>,
        );
      } else {
        _description.push(
          // eslint-disable-next-line max-len
          <span>The following vulnerabilities (found in your environment) are addressed by this security patch and patches that this patch supersedes.</span>,
        );
      }
      if ( sectionCount > 200  ) {
        _description.push(
          // eslint-disable-next-line max-len
          <span>The first <strong>200</strong> are listed below. Click <a target="_blank" rel="noopener noreferrer" href={ getInsightURL( item, 'vulnerability', reportType, riskType ) }>here <InlineSVG type="newTabLink" /></a> to view them all.</span>,
        );
      }
    }

    if ( sectionKey === 'num_hosts' ) {
      if ( reportType === 'patch' ) {
        _description.push(
          <span>This patch applies to</span>,
        );
        _description.push(
          <strong>{ formatNumber( sectionCount ) }</strong>,
        );
        _description.push(
          <span>host(s).</span>,
        );
      } else {
        _description.push(
          <span>This vulnerability affects the following hosts.</span>,
        );
      }
      if ( sectionCount > 200  ) {
        _description.push(
          // eslint-disable-next-line max-len
          <span>The first <strong>200</strong> are listed below. Click <a target="_blank" rel="noopener noreferrer" href={ getInsightURL( item, 'host', reportType, riskType ) }>here <InlineSVG type="newTabLink" /></a> to view them all.</span>,
        );
      }
    }

    if ( sectionKey === 'num_patches' ) {
      if ( sectionCount > 200  ) {
        _description.push(
          // eslint-disable-next-line max-len
          <span>The first <strong>200 patches</strong> are listed below. Click <a target="_blank" rel="noopener noreferrer" href={ getInsightURL( item, 'patch', reportType, riskType ) }>here <InlineSVG type="newTabLink" /></a> to view them all.</span>,
        );
      }
    }

    if ( sectionKey === 'scan_results' ) {
      _description.push(
        <span>Vulnerability scans reported the following related signatures:</span>,
      );
    }

    if ( sectionKey === 'supersedes' ) {
      _description.push(
        // eslint-disable-next-line max-len
        <span>This patch supersedes (or "rolls up") the following older patches for at least some of the applicable software versions:</span>,
      );
    }

    if ( sectionKey === 'superseded_by' ) {
      _description.push(
        <span>This patch is fully or partially superseded by all of the following newer updates:</span>,
      );
    }

    if ( sectionKey === 'unpatchable_vulnerabilities' ) {
      _description.push(
        <span>This host exhibits the following vulnerabilities for which no known patch is available:</span>,
      );
    }

    if ( sectionKey === 'additional_actions' ) {
      _description.push(
        // eslint-disable-next-line max-len
        <span>The following additional actions are needed in order to completely remediate this patch.</span>,
      );
    }

    setDescription( _description );
  };

  React.useEffect( ( ) => {
    if ( isNotEmpty( sectionKey )
      && isNotEmpty( sectionData )
      && isNotEmpty( reportType )
      && isNotEmpty( sectionCount )
    ) {
      formatDescription();
    }
  }, [ sectionKey, sectionData, sectionCount, reportType ] );
  return (
    <React.Fragment>
      {
        ( isNotEmpty( description ) ) &&
        <div className={ `detailSectionDescription ${sectionKey === 'num_patches' ? 'withPatchesToggle' : ''}` }>
          {
            description.map( ( section, i ) => {
              return <React.Fragment key={i}>
                { section }
              </React.Fragment>;
            } )
          }
        </div>
      }
    </React.Fragment>
  );
};

export default SectionDescription;