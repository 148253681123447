/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { makeRequest } from '../../../../legacy/io';
import EmptyState from '../../../shared/EmptyState';
import InlineSVG from '../../../shared/InlineSVG';
import Modal from '../../../shared/Modal';
import {
  isNotEmpty,
  formatTimeDuration,
  formatUnixTime,
  shortenedVulnerabilityScannerNameMap,
} from '../../../shared/Utilities';

const ModalBody = ( { jobHistoryItems, taskType, serverTimeZone, scanGroups } ) => {

  const [ scannerStats, setScannerStats ] = React.useState( {} );

  React.useEffect( () => {
    if ( isNotEmpty( jobHistoryItems ) && taskType === 'import' ) {

      const _scannerStats = {};
      jobHistoryItems.map( item => {
        const _stats = {};
        if (
          isNotEmpty( item )
          && isNotEmpty( item.details )
          && isNotEmpty( item.details.results )
          && isNotEmpty( item.details.results.scanner_stats )
        ) {
          Object.entries( item.details.results.scanner_stats ).map( ( [ scanner, info ] ) => {
            _stats[scanner] = info[0].scanned_hosts.length;
          } );
        }

        _scannerStats[item.id] = _stats;

        setScannerStats( _scannerStats );
      } );
    }
  }, [ jobHistoryItems, taskType ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( jobHistoryItems )
          ? <ul>
            {
              jobHistoryItems.map( ( item, index ) => {
                return  <li
                  key={index}
                >
                  <div className="historyItem">
                    <span className={ `${item.details.status} statusBadge`}>
                      { item.details.status }
                    </span>
                  </div>
                  <div className="historyItem">
                    <strong>Started </strong>
                    <span>{ `${formatUnixTime( item.created )} ${serverTimeZone}` }</span>
                  </div>
                  <div className="historyItem">
                    <strong>Duration </strong>
                    <span>{ formatTimeDuration( item.details.duration ) }</span>
                  </div>
                  {
                    taskType === 'authenticated' &&
                            <div className="historyItem">
                              <strong>Included Scan Groups </strong>
                              <ul>
                                {
                                  item.work_items.map( ( sg, index ) => {
                                    return  <li
                                      key={index}
                                    >
                                      {
                                        ( isNotEmpty( scanGroups ) && scanGroups[sg] )
                                          // eslint-disable-next-line max-len
                                          ? <a href={ `#.=scanning&page=scan_groups&selected_record=${sg}` }>{scanGroups[sg].label}</a>
                                          : <span>[Deleted Scan Group]</span>
                                      }
                                    </li>;
                                  } )
                                }
                              </ul>
                            </div>
                  }
                  {
                    ( taskType === 'import' && isNotEmpty( scannerStats ) ) &&
                            <div className="historyItem">
                              <strong>Scanner Stats</strong>
                              <ul className="scannedHostList">
                                {
                                  isNotEmpty( scannerStats[item.id] ) &&
                                  Object.entries( scannerStats[item.id] ).map( ( [ scanner, stats ], index ) => {
                                    return  <li
                                      key={index}
                                    >
                                      <span>
                                        <strong>
                                          { shortenedVulnerabilityScannerNameMap[scanner] }:
                                        </strong> { stats } Hosts scanned
                                      </span>
                                    </li>;
                                  } )
                                }
                              </ul>
                            </div>
                  }
                </li>;
              } )
            }
          </ul>
          : <EmptyState message="No previous jobs" />
      }
    </React.Fragment>
  );
};

const JobHistoryModal = ( {
  task,
  scanGroups,
  currentTime,
  serverTimeZone,
} ) => {

  const [ showModal, setShowModal ] = React.useState( false );
  const [ jobHistoryItems, setJobHistoryItems ] = React.useState( [] );

  const labelMap = {
    authenticated: 'Credentialed Scan',
    import: 'Vulnerability Scanner Import',
  };

  const viewJobHistory = taskID => {
    setShowModal( true );

    makeRequest( 'HISTORY', '/task', {
      // eslint-disable-next-line camelcase
      field_map: { task: taskID },
      // eslint-disable-next-line camelcase
      extra_columns: [ 'task', 'details', 'work_items', 'created' ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'created', 'DESC' ] ],
      rownums: [ 0, 50 ],
    } ).then( response => {
      if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
        setJobHistoryItems( response.results );
      }
    } );
  };


  return (
    <React.Fragment>
      {
        (
          isNotEmpty( task )
          && isNotEmpty( currentTime )
          && isNotEmpty( serverTimeZone )
        ) &&
        <React.Fragment>
          <Modal
            elementClass="jobHistoryModal"
            visible={showModal}
            setVisible={setShowModal}
            header={ `${labelMap[task.id]} Job History` }
            body={
              <ModalBody
                jobHistoryItems={jobHistoryItems}
                taskType={task.id}
                serverTimeZone={serverTimeZone}
                scanGroups={scanGroups}
              />
            }
          />
          <button
            className="jobHistoryButton"
            onClick={ () => viewJobHistory( task.id ) }
          >
            <InlineSVG type="history" version="primary" />
            View history
          </button>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default JobHistoryModal;