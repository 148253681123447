/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';

import SetupPage from '../../../shared/SetupComponents/SetupPage';

import './style.scss';

import {
  isEmpty,
  isNotEmpty,
  userDisplayName,
} from '../../../shared/Utilities';

import {
  recordData,
} from './data';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../legacy/io';
import { TagsContext } from '../../../Contexts/Tags';

const Tags = () => {

  // ContextualHelp getters and setters
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ users, setUsers ] = React.useState( null );

  const [ tags, setTags ] = React.useState( [] );

  const [ , refreshTags ] = React.useContext( TagsContext );

  React.useEffect( () => {
    const _allUsers = {};
    makeRequest( 'SEARCH', '/user', {
      // eslint-disable-next-line camelcase
      extra_columns: [
        'authentication_provider_id',
        'username',
        'given_name',
        'family_name',
        'api_key',
        'setup_wizard_disabled',
        'email_address',
      ],
      'order_by':[ [ 'username', 'ASC' ] ],
    } ).then( response => {
      if ( response ) {
        if ( response['results'] && response['results'].length ) {
          response.results.map( user => {
            _allUsers[user.id] = userDisplayName( user );
          } );
        }
      }
      setUsers( _allUsers );
    } );
  }, [] );

  const onRefresh = () => {
    makeRequest( 'SEARCH', '/project/default/asset_tag', {
      // eslint-disable-next-line camelcase
      extra_columns: [
        'label',
        'color',
        'included_ranges',
        'excluded_ranges',
        'included_host_patterns',
        'excluded_host_patterns',
        'included_host_ids',
        'excluded_host_ids',
        'remediation_manager',
      ],
      // eslint-disable-next-line camelcase
      order_by:[ [ 'label', 'ASC' ] ],
    } ).then( response => {
      if ( response && response['results'] ) {
        const records = response['results'];
        setTags( records );
      } else {
        setTags( [] );
      }
    } );
  };

  const onSave = async (
    tag,
    isValid,
    values,
    successCallback,
  ) => {
    if ( isValid && isNotEmpty( values ) ) {
      const expectedFilters = [
        'included_ranges',
        'excluded_ranges',

        'included_host_ids',
        'excluded_host_ids',

        'included_host_patterns',
        'excluded_host_patterns',
      ];

      const missingRequired = () => {
        if (
          isNotEmpty( values.included_ranges )
          || isNotEmpty( values.included_host_patterns )
          || isNotEmpty( values.included_host_ids )
        ) {
          return false;
        }
        return true;

      };

      if ( missingRequired() ) {
        addFlashMessage( {
          header: 'Missing Information',
          body: 'Must have at least 1 Include Filter applied (IP Ranges, Specific Hosts, Host Wildcards)',
          type: 'alert',
        } );
      } else {

        const newTag = {};

        Object.keys( values ).map( attr => {
          if ( attr === 'remediation_manager' && values[attr] === 'null' ) {
            newTag[attr] = null;
          } else {
            newTag[attr] = values[attr];
          }
        } );

        if ( tag ) {
          // we are updating, add the id
          newTag.id = tag.id;
        }

        expectedFilters.map( key => {
          if ( isEmpty( newTag[key] ) ) {
            newTag[key] = [];
          }
        } );
        await makeRequest( 'UPSERT', '/project/default/asset_tag', {'records':[ newTag ]} ).then( response => {
          if ( response['errors'] ) {
            addFlashMessage( {
              body: response['errors'],
              type: 'alert',
            } );
          } else {
            addFlashMessage( {
              body: 'Successfully saved tag',
              type: 'success',
            } );
            onRefresh();
            successCallback();
          }
          return response;
        } );

        refreshTags();
      }
    }
  };

  return (
    <React.Fragment>
      <SetupPage
        onRefresh={onRefresh}
        onSave={onSave}
        records={tags}
        setRecords={setTags}
        recordType="tag"
        recordData={recordData}
        users={users}
        useForm
        alternateItemLayout
      />
    </React.Fragment>

  );
};

export default Tags;
