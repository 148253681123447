/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';
import './style.scss';

import { getGlobalSettings, isNotEmpty, updateGlobalSettings } from '../../../shared/Utilities';

import Form from '../../../shared/Form';
import SetupForm from '../../../shared/SetupComponents/SetupForm';
import { getFieldValues } from '../../../shared/Form/Shared';

import { EMPTY_FIELDS } from './data';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';

const AgentlessSettings = () => {
  // refresh control
  let isMounted = true;

  // contexts
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  // state
  const [ fields, setFields ] = React.useState( null );
  const [ record, setRecord ] = React.useState( null );
  const [ isValid, setIsValid ] = React.useState( true );

  // this is set in the form onChange callback
  const [ updatedForm, setUpdatedForm ] = React.useState( null );

  // one time init
  React.useEffect( ( ) => {
    setFields( EMPTY_FIELDS );
    onRefresh( );
  }, [ ] );

  // sets up onleave
  React.useEffect( () => {
    // important functionality that automatically
    // saves the form when navigating away from the page if the form is valid
    const parentEl = document.getElementById( 'pageContent' );
    parentEl.onleave = async () => {
      if ( isValid && isMounted ) {
        onSave( false );
        return true;
      }
    };

    return () => {
      isMounted = false;
      if ( parentEl ) {
        parentEl.onleave = null;
      }
    };
  }, [ isValid, updatedForm ] );

  // refreshes the form whenever one of the following happens:
  // 1. initial page load
  // 2. manual revert button is clicked in the footer
  // 3. a settings is changed and saved
  const onRefresh = async( ) => {

    const _project = await getGlobalSettings( 'project' );

    setRecord( {
      /* eslint-disable camelcase */
      avoid_rescan_period: _project?.scan_defaults?.avoid_rescan_period,
      maximum_duration: _project?.scan_defaults?.maximum_duration,
      mssql_grant_privileges: _project?.scan_defaults?.mssql_grant_privileges,
      scan_domains: _project?.scan_defaults?.scan_domains,
      scan_workers: _project?.scan_defaults?.scan_workers,
      winrm_allow_http: _project?.scan_defaults?.winrm_allow_http,
      enable_nas_scan: _project?.scan_defaults?.enable_nas_scan,
      nas_scan_depth: _project?.scan_defaults?.nas_scan_depth || 1,

      scan_hard_stop_time: _project?.settings?.scan_hard_stop_time,
      scan_max_workers: _project?.settings?.scan_max_workers,
      /* eslint-enable camelcase */
    } );
  };

  // called when clicking save in the footer, or on page leave if the form is valid
  const onSave = async ( shouldRefresh=true ) => {
    // checks for validity and makes sure we have the latest values
    if ( isValid && isNotEmpty( updatedForm ) && isNotEmpty( updatedForm.fieldStates ) ) {
      const _values = getFieldValues( updatedForm.fieldStates, 'agentless_settings' );

      // these attributes are nested in project.scan_defaults
      const isProjectScanDefaults = [
        'avoid_rescan_period',
        'maximum_duration',
        'mssql_grant_privileges',
        'scan_domains',
        'scan_workers',
        'winrm_allow_http',
        'enable_nas_scan',
        'nas_scan_depth',
      ];

      // these attributes are nested in project.settings
      const isProjectSettings = [
        'scan_hard_stop_time',
        'scan_max_workers',
      ];

      // eslint-disable-next-line camelcase
      const _project = { settings: {}, scan_defaults: {} };

      // put the attrs and values into the correct spot
      Object.entries( _values ).map( ( [ attr, val ] ) => {
        if ( isProjectSettings.includes( attr ) ) {
          _project.settings[attr] = val;
        } else if ( isProjectScanDefaults.includes( attr ) ) {
          _project.scan_defaults[attr] = val;
        }
      } );

      const updatedProject = await updateGlobalSettings( 'project', _project );

      // success
      if ( isNotEmpty( updatedProject.results ) ) {
        const _project = updatedProject.results;

        // only refresh if needed, ie, not when navigating away
        if (
          shouldRefresh
          && isMounted
        ) {
          // need to pass these through becuase the update of one was causing the other to get
          // wiped out, not ideal, need to think of a better approach at some point -DMC 2022-04-06
          onRefresh( _project );
          addFlashMessage( {
            type: 'success',
            body: 'Successfully updated settings.',
          } );
        }
      // formatted error
      } else if ( isNotEmpty( updatedProject.errors ) ) {
        const _errors = [];
        updatedProject?.errors.map( e => {
          _errors.push( e );
        } );
        if ( shouldRefresh && isMounted ) {
          _errors.map( e => {
            addFlashMessage( {
              type: 'alert',
              body:  e,
            } );
          } );
        }
      // likely 500
      } else if ( shouldRefresh && isMounted ) {
        addFlashMessage( {
          type: 'alert',
          body: 'There was an error saving settings, please check your connection and try again.',
        } );
      }
    }
  };

  return (
    <SetupForm elementClass="agentlessSettings">
      {
        ( isNotEmpty( fields ) && isNotEmpty( record ) ) &&
          <Form
            fields={fields}
            onChangeCallback={setUpdatedForm}
            existingRecord={record}
            recordType={'agentless_settings'}
            setIsValid={setIsValid}
          />
      }
      <div className="formActions">
        <button
          className="revertButton"
          onClick={() => onRefresh()}
        >
          Revert
        </button>
        <button
          disabled={!isValid}
          onClick={onSave}
        >
          Save
        </button>
      </div>
    </SetupForm>
  );
};

export default AgentlessSettings;
