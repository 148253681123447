/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import EmptyState from '../EmptyState';
import InlineSVG from '../InlineSVG';
import ReportCreator from '../ReportCreator';
import SavedFilters from '../SavedFilters';

import {
  decodeURLHash,
  isNotEmpty,
  paramsToFilters,
  triggerHashRefresh,
  removeFromURLHash,
  isEqual,
} from '../Utilities';

import './AdvancedFilterForm.scss';

import Filter from './Filter';
import { defaultFilterValues } from './shared';
import AppliedFilter from './AppliedFilter';
import { makeRequest } from '../../../legacy/io';
import { ReportCreatorContext } from '../../Contexts/ReportCreator';

const AdvancedFilterForm = ( {
  fieldGroups,
  values,
  disabled,
  onRefresh,
  collapsed,
  visualCollapsed,
  setCollapsed,
  reportType,
} ) => {

  const [ alwaysVisibleGroup, setAlwaysVisibleGroup ] = React.useState( null );
  const [ hasAdvancedGroups, setHasAdvancedGroups ] = React.useState( true );
  const [ flattenedFields, setFlattenedFields ] = React.useState( null );
  const [ appliedFilterValues, setAppliedFilterValues ] = React.useState( [] );

  const [ drawerTab, setDrawerTab ] = React.useState( 'filters' );
  const [ existingReport, setExistingReport ] = React.useState( null );

  const [ filtersOnOpen, setFiltersOnOpen ] = React.useState( null );

  const [
    creatorActive,
    setCreatorActive,
    openReportCreator,
    closeReportCreator,
    ,
  ] = React.useContext( ReportCreatorContext );

  const openCallback = () => {
    setFiltersOnOpen( paramsToFilters() );
    setCollapsed( false );
    setDrawerTab( 'creator' );
  };

  const closeCallback = () => {
    setCollapsed( true );
    setDrawerTab( 'filters' );
  };

  const hideFilterButton = [
    'path',
    'user',
  ];

  // on page load, sets up the report creator and all the needed data if it should be open
  React.useEffect( ( ) => {
    if ( decodeURLHash()['creating_report'] ) {
      const project = 'default';
      const model = 'base';
      const filters = {
        // eslint-disable-next-line camelcase
        extra_columns: [
          'created',
          'email_recipients',
          'format',
          'filters',
          'id',
          'label',
          'last_finished',
          'last_started',
          'schedule',
          'expiration',
          'type',
          'owner',
          'state',
        ],
        // eslint-disable-next-line camelcase
        id_list: [ decodeURLHash()['report_id'] ],
      };

      if ( decodeURLHash()['report_id'] ) {
        makeRequest( 'SEARCH', '/model/base/exported_report', {
          project,
          model,
          filters,
        } ).then( response => {
          if ( response && response.results ) {
            setExistingReport( response.results[0] );
          } else {
            setExistingReport( null );
          }
          openReportCreator( openCallback );
        } );
      } else {
        setExistingReport( null );
        openReportCreator( openCallback );
      }
    }
  }, [] );

  // on page load, store any filters and values, that way on apply or cancel,
  // we can know what changed or needs to be reverted
  React.useEffect( () => {
    setFiltersOnOpen( paramsToFilters() );
  }, [] );

  // some fields will always be visible whether the drawer is open or not
  React.useEffect( ( ) => {
    if ( isNotEmpty( fieldGroups ) ) {
      const _flattenedFields = [];

      const _hasAdvancedGroups = fieldGroups.filter( g => g.name !== 'alwaysVisible' ).length > 0;

      const alwaysVisible = fieldGroups.find( g => g.name === 'alwaysVisible' );

      fieldGroups.map( group => {
        group.fields.map( _field => {
          _flattenedFields.push( _field );
        } );
      } );
      if ( isNotEmpty( alwaysVisible ) ) {
        setAlwaysVisibleGroup( alwaysVisible );
      }
      setHasAdvancedGroups( _hasAdvancedGroups );
      setFlattenedFields( _flattenedFields );
    }
  }, [ fieldGroups ] );

  React.useEffect( ( ) => {
    document.addEventListener( 'keydown', escFunction );
    return () => {
      document.removeEventListener( 'keydown', escFunction );
    };
  }, [] );

  const escFunction = React.useCallback( ( e ) => {
    // esc key
    if ( e.keyCode === 27 ) {
      cancel();
    }
  }, [] );

  // calls whenever the hash changes, to figure out which filters need to be displayed as buttons
  // because they do not match the default values
  const syncDefaultFilters = () => {
    const filterValues = paramsToFilters();

    const _appliedFilterValues = {};

    const alwaysVisibleGroup = fieldGroups.find( g => g.name === 'alwaysVisible' );

    if ( isNotEmpty( alwaysVisibleGroup ) ) {
      alwaysVisibleGroup.fields.map( f => {
        delete filterValues[f.attribute];
      } );
    }

    Object.entries( filterValues ).map( ( [ attr, val ] ) => {
      if ( defaultFilterValues !== null && !isEqual( defaultFilterValues[reportType][attr], val ) ) {
        const field = flattenedFields.find( f => f.attribute === attr );
        if ( isNotEmpty( field ) ) {
          _appliedFilterValues[attr] = { label: field.label, value: val };
        }
      }
    } );
    setAppliedFilterValues( _appliedFilterValues );
  };

  // setup the hashchange watcher to setup all the fields that need to be displayed as a pill
  React.useEffect( ( ) => {
    if ( isNotEmpty( fieldGroups ) && isNotEmpty( flattenedFields ) ) {
      syncDefaultFilters();
      window.addEventListener( 'hashchange', syncDefaultFilters );
    }
    return () => {
      window.removeEventListener( 'hashchange', syncDefaultFilters );
    };

  }, [ fieldGroups, flattenedFields ] );

  // whenever the creator is set to active, toggle the drawer
  React.useEffect( ( ) => {
    if ( creatorActive ) {
      setDrawerTab( 'creator' );
    } else {
      setDrawerTab( 'filters' );
    }
  }, [ creatorActive ] );

  // when clicking the + filter button
  const toggleFiltersDrawer = () => {
    setDrawerTab( 'filters' );
    removeFromURLHash( 'creating_report' );
    setCollapsed( !collapsed );
    setCreatorActive( false );
  };

  // cancel any filter entries in the drawer and revert to previous state
  const cancel = () => {
    setCollapsed( true );
  };

  // apply all filters (trigger onRefresh) and close the drawer
  const apply = async () => {

    const currentFilters = paramsToFilters();

    const changedFilters = [];

    if ( isNotEmpty( filtersOnOpen ) ) {
      Object.entries( filtersOnOpen ).map( ( [ key, value ] ) => {
        if ( currentFilters[key] && currentFilters[key] !== value ) {
          changedFilters.push( key );
        }
      } );
    }

    if ( isNotEmpty( currentFilters ) ) {
      Object.entries( currentFilters ).map( ( [ key, value ] ) => {
        if ( filtersOnOpen[key] && filtersOnOpen[key] !== value && !changedFilters.includes( key ) ) {
          changedFilters.push( key );
        }
      } );
    }
    await onRefresh( changedFilters );
    setFiltersOnOpen( currentFilters );
    triggerHashRefresh();
    setCollapsed( true );
  };

  const clearAllAppliedFilters = async () => {
    const changedFilters = [];
    Object.keys( appliedFilterValues ).map( attr => {
      changedFilters.push( attr );
      removeFromURLHash( attr );
    } );

    await onRefresh( changedFilters );

    setFiltersOnOpen( paramsToFilters() );
    triggerHashRefresh();
  };

  // check for collapsed or reportCreator
  const fullyCollapsed = () => {
    return collapsed && !creatorActive;
  };

  return (
    <div
      // eslint-disable-next-line max-len
      className={ `advancedFilterFormWrapper ${ visualCollapsed ? 'visualCollapsed' : '' } ${ fullyCollapsed() ? 'collapsed' : '' }` }
    >
      {
        !fullyCollapsed() &&
        <div className="advancedFilterDrawerShade" onClick={ () => closeReportCreator( closeCallback ) } />
      }
      {
        ( isNotEmpty( alwaysVisibleGroup ) && isNotEmpty( flattenedFields ) && isNotEmpty( reportType ) ) &&
        <div className={ `alwaysVisible ${ fullyCollapsed() ? 'collapsed' : '' }` }>
          {
            alwaysVisibleGroup.fields.map( ( field, index ) => {
              return <Filter
                key={index}
                value={values[field.attribute] || ''}
                inputs={ flattenedFields }
                values={values}
                input={field}
                disabled={disabled}
                onRefresh={onRefresh}
                reportType={reportType}
                onRrefresh={onRefresh}
              />;
            } )
          }
          {
            !hideFilterButton.includes( reportType ) &&
            <button
              className="advancedFilterToggleButton"
              onClick={ toggleFiltersDrawer }
            >
              <span>Filters</span>
              <InlineSVG type="filterAlt" />
            </button>
          }
        </div>
      }
      {
        ( isNotEmpty( appliedFilterValues ) && isNotEmpty( reportType ) ) &&
        <div className={ `appliedFilters ${ fullyCollapsed() ? 'collapsed' : ''}` }>
          {
            Object.entries( appliedFilterValues ).map( ( [ attr, val ], _index ) => {
              return <AppliedFilter
                key={_index}
                label={val.label}
                value={val.value}
                attribute={attr}
                onRefresh={onRefresh}
                reportType={reportType}
              />;
            } )
          }
          <button
            onClick={ clearAllAppliedFilters }
            className="clearFiltersButton"
          >
            <InlineSVG type="delete" />
          </button>
        </div>
      }
      <div id="riskInsight_filterDrawer" className={ `filterDrawer ${ fullyCollapsed() ? 'collapsed' : '' }`}>
        <div className={ `filtersWrapper ${reportType} ${drawerTab === 'creator' ? 'creator' : ''}` }>
          {
            (
              isNotEmpty( fieldGroups )
              && isNotEmpty( flattenedFields )
              && drawerTab === 'filters'
            )
              ? <React.Fragment>
                {
                  hasAdvancedGroups
                    ? <React.Fragment>
                      <h2>
                        Advanced Filters
                        <div className="savedFiltersWrapper">
                          <SavedFilters showSavedFilters callback={ apply } reportType={reportType} />
                        </div>
                      </h2>
                      {
                        fieldGroups.map( ( group, index ) => {
                          if ( group.name !== 'alwaysVisible' ) {
                            return <div
                              key={index}
                              className={ `advancedFormColumn ${index === fieldGroups.length - 1 ? 'last' : ''}` }
                            >
                              {
                                group.header &&
                                <h2>{ group.header }</h2>
                              }
                              {
                                group.fields.map( ( field, _index ) => {
                                  return <Filter
                                    key={_index}
                                    value={values[field.attribute] || ''}
                                    inputs={ flattenedFields }
                                    values={values}
                                    input={field}
                                    disabled={disabled}
                                    onRefresh={onRefresh}
                                    reportType={reportType}
                                    withinFilterDrawer
                                  />;
                                } )
                              }
                            </div>;
                          }
                        } )
                      }
                    </React.Fragment>
                    : <EmptyState message="No advanced filters" />
                }
              </React.Fragment>
              : <ReportCreator
                existingReport={existingReport}
                setExistingReport={setExistingReport}
                advanced
              />
          }
          {
            drawerTab !== 'creator' &&
            <div
              className="filterDrawerActions"
            >
              <button
                className="cancelButton"
                onClick={ cancel }
              >
                Cancel
              </button>
              <button
                onClick={ apply }
              >
                Apply
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default AdvancedFilterForm;