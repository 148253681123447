/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { makeRequest } from '../../../../../legacy/io';
import InlineSVG from '../../../../shared/InlineSVG';
import Modal from '../../../../shared/Modal';
import NotificationSubscriptions from '../../../../shared/NotificationSubscriptions';
import { isNotEmpty } from '../../../../shared/Utilities';

const ModalBody = ( { user, setShowModal } ) => {

  const [ availableNotifications, setAvailableNotifications ] = React.useState( null );

  // once the user comes in, grab the necessary data
  React.useEffect( ( ) => {
    if ( isNotEmpty( user ) ) {
      makeRequest( 'TYPES', '/notification_settings', {} ).then( response => {
        if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
          setAvailableNotifications( response.results );
        }
      } );
    }
  }, [ user ] );

  const callback = () => {
    setShowModal( false );
  };

  return (
    <React.Fragment>
      <NotificationSubscriptions
        availableNotifications={availableNotifications}
        user={user}
        onCancelCallback={ callback }
        onSaveCallback={ callback }
      />
    </React.Fragment>
  );
};

const UserNotificationSettingsModal = ( {
  user,
} ) => {

  const [ showModal, setShowModal ] = React.useState( false );

  return (
    <React.Fragment>
      {
        isNotEmpty( user ) &&
        <React.Fragment>
          <Modal
            visible={showModal}
            setVisible={setShowModal}
            header="Notification Settings"
            elementClass="UserNotificationSettingsModal"
            needsActions={ false }
            body={<ModalBody
              user={user}
              setShowModal={setShowModal}
            />}
          />
          <button
            title={'Update notification settings'}
            className="recordItemAction"
            onClick={ () => setShowModal( true ) }
          >
            <InlineSVG type="notification" />
          </button>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default UserNotificationSettingsModal;