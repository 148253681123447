/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { ADVERSARY_NODE } from '../../Graph/shared';
import { isNotEmpty } from '../../../../shared/Utilities';

const NodeOptions = ( {
  node,
  selectItem,
  setSelectingSecondaryItem,
  editItem,
  copyItem,
  deleteItem,
  optionCallback=() => {},
} ) => {
  return (
    <React.Fragment>
      {
        isNotEmpty( node ) &&
        <React.Fragment>
          <li
            onClick={ () => selectItem( 'node', node, optionCallback ) }
          >
            Add node fragment to graph
          </li>
          <li
            onClick={ () => {
              setSelectingSecondaryItem( 'paths' );
              optionCallback();
            } }
          >
            Find paths from here
          </li>
          <li
            onClick={ () => {
              setSelectingSecondaryItem( 'segment' );
              optionCallback();
            } }
          >
            Add segment
          </li>
          {
            node.id !== ADVERSARY_NODE.id &&
            <React.Fragment>
              <li
                onClick={ () => editItem( 'node', { ...node, isNode: true }, optionCallback ) }
              >
                Edit node
              </li>
              <li
                onClick={ () => copyItem( 'node', node, optionCallback ) }
              >
                Duplicate node
              </li>
              <li
                className="delete"
                onClick={ () => deleteItem( 'node', node, optionCallback ) }
              >
                Delete node
              </li>
            </React.Fragment>
          }

        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default NodeOptions;