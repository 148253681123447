/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './ScanningTotal.scss';
import { formatNumber, isNotEmpty } from '../../../../../shared/Utilities';
import { v2Fetches } from '../../shared';
import InlineSVG from '../../../../../shared/InlineSVG';

const ScanningTotal = ( { prefetchedData, settings } ) => {

  const [ credentialedTotal, setCredentialedTotal ] = React.useState( null );
  const [ agentTotal, setAgentTotal ] = React.useState( null );

  const refetchData = async () => {
    const _tallyResults = await v2Fetches.scanning_agent();

    if ( isNotEmpty( _tallyResults ) ) {
      setAgentTotal( _tallyResults.category?.all );
      setCredentialedTotal( null );
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( settings ) && 'scanning_type' in settings ) {
      if (
        settings.scanning_type === 'agent'
        && isNotEmpty( prefetchedData )
        && isNotEmpty( prefetchedData.scanning_agent )
        && isNotEmpty( prefetchedData.scanning_agent.category )
      ) {
        setAgentTotal( prefetchedData.scanning_agent.category.all );
        setCredentialedTotal( null );
      } else if (
        settings.scanning_type === 'credentialed'
        && isNotEmpty( prefetchedData )
        && isNotEmpty( prefetchedData.scanning_credentialed )
        && isNotEmpty( prefetchedData.scanning_credentialed.category )
      ) {
        setCredentialedTotal( prefetchedData.scanning_credentialed.category.all );
        setAgentTotal( null );
      } else if (
        settings.scanning_type === 'both'
        && isNotEmpty( prefetchedData )
        && isNotEmpty( prefetchedData.scanning_agent )
        && isNotEmpty( prefetchedData.scanning_agent.category )
        && isNotEmpty( prefetchedData.scanning_credentialed )
        && isNotEmpty( prefetchedData.scanning_credentialed.category )
      ) {
        setAgentTotal( prefetchedData.scanning_agent.category.all );
        setCredentialedTotal( prefetchedData.scanning_credentialed.category.all );
      } else if (
        isNotEmpty( prefetchedData )
        && isNotEmpty( prefetchedData.scanning_agent )
        && isNotEmpty( prefetchedData.scanning_agent.category )
      ) {
        setAgentTotal( prefetchedData.scanning_agent.category.all );
        setCredentialedTotal( null );
      } else {
        refetchData( );
      }
    } else if (
      isNotEmpty( prefetchedData )
      && isNotEmpty( prefetchedData.scanning_agent )
      && isNotEmpty( prefetchedData.scanning_agent.category )
    ) {
      setAgentTotal( prefetchedData.scanning_agent.category.all );
      setCredentialedTotal( null );
    } else {
      refetchData( );
    }
  }, [ prefetchedData, settings ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( credentialedTotal ) || isNotEmpty( agentTotal ) ) &&
        <div
          // eslint-disable-next-line max-len
          className={ `bigNumberWrapper ${ ( isNotEmpty( credentialedTotal ) && isNotEmpty( agentTotal ) ) ? 'twoNumbers' : '' }` }
        >
          {
            isNotEmpty( credentialedTotal ) &&
            <div className="totalWrapper credentialed">
              <h3>Scanned Hosts</h3>
              <h2>
                <InlineSVG type="hostsAlt" />
                { formatNumber( credentialedTotal ) }
              </h2>
              <span>scanned via credentialed scans</span>
            </div>
          }
          {
            isNotEmpty( agentTotal ) &&
            <div className="totalWrapper credentialed">
              <h3>Scanned Hosts</h3>
              <h2>
                <InlineSVG type="hostsAlt" />
                { formatNumber( agentTotal ) }
              </h2>
              <span>scanned via user managed/agent scans</span>
            </div>
          }
        </div>
      }
    </React.Fragment>
  );
};

export default ScanningTotal;