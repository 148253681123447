/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';

import {
  isEmpty,
  isNotEmpty,
  promiseAllSequential,
  riskToRating,
} from '../../../shared/Utilities';

import { makeRequest } from '../../../../legacy/io';

import './style.scss';

import PathsGraph from './Sections/PathsGraph';
import OverviewHeader from './Header/OverviewHeader';
import SensitiveAssetsSection from './Sections/SensitiveAssetsSection';
import { collapsibleSectionDataMap } from './shared';
import CollapsibleSection from './Sections/CollapsibleSection';
import AdditionalInformation from './Sections/AdditionalInformation';
// import SignatureAndVulnerabilityInformation from './Modals/SignatureAndVulnerabilityInformation';
import RemediationInstructions from './Modals/RemediationInstructions';
import AddToPlanMenu from '../../Remediation/AddToPlanMenu/index.js';
import { hasAccess } from '../../App/AccessControl';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';
import EmptyLoading from '../../../shared/EmptyLoading';
import RecordCard from '../../Explore/RecordCard';
import { FullScreenVisualContext } from '../../../Contexts/FullScreenVisual.js';
import InlineSVG from '../../../shared/InlineSVG/index.js';

const needsInstanceInfo = [
  'host',
  'patch',
  'vulnerability',
];

const canAddToRemediation = [
  'host',
  'patch',
  'vulnerability',
];

const InsightDetail = ( {
  reportType,
  item,
  selectItem,
  riskType='risk',
} ) => {

  const [ , , showVisual, , showFullScreenVisual, , , ] = React.useContext( FullScreenVisualContext );

  const project = 'default';
  const model = 'base';

  const [ formattedItem, setFormattedItem ] = React.useState( null );
  const [ instanceData, setInstanceData ] = React.useState( null );
  const [ relatedPaths, setRelatedPaths ] = React.useState( null );
  // eslint-disable-next-line
  const [ modalContext, setModalContext ] = React.useState( 'scan_results' );

  const [ pathCount, setPathCount ] = React.useState( 3 );

  const [ pathsLoading, setPathsLoading ] = React.useState( false );
  // eslint-disable-next-line
  const [ prefetchedLoading, setPrefetchedLoading ] = React.useState( false );

  const [ showRemediateModal, setShowRemediateModal ] = React.useState( false );

  // for all clickable elements (nodes, segments, scanner signatures ) that could show a modal, we are keeping track
  // of the vars at the top level and passing down as needed
  const [ selectedSignature, setSelectedSignature ] = React.useState( null );
  const [ pathEdge, setPathEdge ] = React.useState( null );
  // eslint-disable-next-line
  const [ selectedNode, setSelectedNode ] = React.useState( null );

  const [ , , licenseInfo ] = React.useContext( CurrentUserContext );

  const [ hoveredListItemID, setHoveredListItemID ] = React.useState( null );
  const [ hoveredListItemType, setHoveredListItemType ] = React.useState( null );
  const [ hoveredListItemRating, setHoveredListItemRating ] = React.useState( null );

  const [ pageClass, setPageClass ] = React.useState( null );

  // special actions for different report types
  React.useEffect( () => {
    if ( isNotEmpty( item ) ) {
      const _item = { ...item };

      // additionalActions for patches
      if ( isNotEmpty( reportType ) && reportType === 'patch' ) {

        // checks for additional necessary actions and displays a notification if there are any
        makeRequest(
          'COMPUTE',
          '/analysis/patch_additional_actions',
          {
            project: project,
            model: model,
            // eslint-disable-next-line camelcase
            patch_id: item.id,
          },
        ).then( response => {

          if ( response && isNotEmpty( response.results ) ) {
            // eslint-disable-next-line camelcase
            const additional_actions = [];

            Object.values( response.results ).map( result => {
              const { identifier } = result;

              if ( isNotEmpty( result.additional_actions ) ) {
                Object.entries( result.additional_actions ).map( ( [ label, href ] ) => {
                  // eslint-disable-next-line camelcase
                  additional_actions.push( {
                    identifier,
                    label,
                    href,
                  } );
                } );
              }
            } );
            // eslint-disable-next-line camelcase
            _item.additional_actions = additional_actions;
          }
        } );
      }
      setFormattedItem( _item );
    }
  }, [ item, reportType ] );

  // once the item comes in, figure out what the class of the page should be so that the whole page can be color matched
  React.useEffect( () => {
    if ( isNotEmpty( item ) && isNotEmpty( reportType ) && isNotEmpty( riskType ) ) {
      if ( reportType === 'host' && !item.has_host ) {
        setPageClass( 'unknown' );
      } else {
        setPageClass( riskToRating( item[riskType] ) );
      }
    }
  }, [ item, reportType, riskType ] );

  const topPathReportType = ( reportType, riskType ) => {
    if ( reportType === 'user' ) {
      return 'domain_user';
    }
    if ( reportType === 'patch' ) {
      if ( riskType === 'risk' ) {
        return 'patch';
      }
      return 'direct_patch';
    }
    return reportType;
  };

  // gets the related Paths data here and passes it down to all the components that need it,
  const getPathsInfo = async( item, reportType ) => {

    setPathsLoading( true );

    // params for initial fetch of the related paths
    const params = {
      model: 'base',
      [`${topPathReportType( reportType, riskType )}_id`]: item.id,
      rownums: [ 0, pathCount ],
    };

    // fetches the paths for this item
    const relatedPathsResponse = await makeRequest( 'FETCH', '/analysis/related_paths', params );

    setRelatedPaths( relatedPathsResponse );

    setPathsLoading( false );
  };

  // need to hit the instance endpoint to get additional information for this item, this does a lot of heavy lifting
  // and is different depending on what reportType it is. For each reportType, we are going to have to make 3 group
  // calls and 1 tally call... in order to handle this, the requests will be sent syncronously and the page will render
  // once they are all finished
  // EDIT: group_by endpoints were not working out as expecting, only grabbing the tally
  const getInstanceInfo = async ( item, reportType ) => {
    setPrefetchedLoading( true );
    const promises = [];

    const tallyParams = {
      project: 'default',
      model: 'base',
      filters: {
        [`${reportType}_ids`]: [ item.id ],
        // eslint-disable-next-line camelcase
        risk_type: riskType,
      },
    };

    promises.push( () => makeRequest( 'TALLY', '/analysis/instance', tallyParams ) );

    const resolvedResponses = await promiseAllSequential( promises );

    const _instanceData = {
      tally: resolvedResponses[0],
    };

    setInstanceData( _instanceData );
    setPrefetchedLoading( false );
  };

  React.useEffect( ( ) => {
    if ( isNotEmpty( formattedItem ) && isNotEmpty( reportType ) ) {
      if ( needsInstanceInfo.includes( reportType ) && isEmpty( instanceData ) ) {
        getInstanceInfo( formattedItem, reportType );
      }
      getPathsInfo( formattedItem, reportType );
    }
  }, [ formattedItem, reportType, pathCount ] );

  const edgeOnClickCallback = ( edge, e ) => {
    setPathEdge( { ...edge, clickEvent: e } );
    setSelectedNode( null );
    setModalContext( 'path_edge' );
  };

  const nodeOnClickCallback = ( node, e ) => {
    setSelectedNode( { ...node, clickEvent: e } );
    setPathEdge( null );
    setModalContext( 'path_node' );
  };

  const handleRecordCardClose = () => {
    setSelectedSignature( null );
    setSelectedNode( null );
    setPathEdge( null );
  };

  return (
    <React.Fragment>
      <RemediationInstructions
        item={ formattedItem }
        reportType={ reportType }
        riskType={riskType}
        show={showRemediateModal}
        setShow={setShowRemediateModal}
      />
      {
        isNotEmpty( selectedNode ) &&
        <RecordCard
          record={ selectedNode }
          type="node"
          context="risk_insight"
          show={ isNotEmpty( selectedNode ) }
          setShow={ handleRecordCardClose }
          options={ {
            dismissable: true,
            // includeShade: true,
          } }
        />
      }
      {
        isNotEmpty( pathEdge ) &&
        <RecordCard
          record={ pathEdge }
          type="edge"
          context="risk_insight"
          show={ isNotEmpty( pathEdge ) }
          setShow={ handleRecordCardClose }
          options={ {
            dismissable: true,
            // includeShade: true,
            relatedPaths,
          } }
        />
      }
      {
        isNotEmpty( selectedSignature ) &&
        <RecordCard
          record={ selectedSignature }
          type="signature"
          context="risk_insight"
          show={ isNotEmpty( selectedSignature ) }
          setShow={ handleRecordCardClose }
          options={ {
            dismissable: true,
            // includeShade: true,
          } }
        />
      }
      {
        ( isNotEmpty( formattedItem ) && isNotEmpty( reportType ) && isNotEmpty( pageClass ) ) &&
        <React.Fragment>
          {
            reportType !== 'path' &&
            <OverviewHeader
              item={formattedItem}
              selectItem={selectItem}
              reportType={reportType}
              riskType={riskType}
              instanceData={instanceData}
              loading={prefetchedLoading}
              riskClass={ pageClass }
            />
          }
          <div className={ `riskInsightDetailBody ${reportType}` }>
            <React.Fragment>
              {
                ( reportType === 'vulnerability' && item.nofix )
                  ? <React.Fragment>
                  </React.Fragment>
                  : <div className="detailBodyRow">
                    <div className="detailBodyColumn criticalPaths">
                      <div className="detailColumnHeader">
                        <div className="stepWrapper">
                          <span className="detailStep one">1</span>
                          <h2>{ `Top ${ relatedPaths?.results?.paths?.length || 0 } Critical Paths` }</h2>
                        </div>
                        <div className="selectFieldWrapper">
                          <select
                            value={pathCount}
                            onChange={ e => setPathCount( e.target.value ) }
                          >
                            <option value={ 3 } >View 3 paths</option>
                            <option value={ 5 } >View 5 paths</option>
                          </select>
                        </div>
                      </div>
                      <div className={ `criticalPathsWrapper pathCount_${pathCount}` }>
                        <EmptyLoading
                          payload={ relatedPaths?.results }
                          loading={pathsLoading}
                          loadingMessage="Loading critical paths..."
                          emptyMessage="No critical paths for this item"
                        />
                        <PathsGraph
                          relatedPaths={relatedPaths}
                          item={formattedItem}
                          reportType={reportType}
                          setSelectedSignature={setSelectedSignature}
                          setSelectedNode={setSelectedNode}
                          setPathEdge={setPathEdge}
                          edgeOnClickCallback={edgeOnClickCallback}
                          nodeOnClickCallback={nodeOnClickCallback}
                          hoveredListItemID={ hoveredListItemID }
                          hoveredListItemType={ hoveredListItemType }
                          hoveredListItemRating={ hoveredListItemRating }
                          handleRecordCardClose={ handleRecordCardClose }
                        />
                        {
                          isNotEmpty( relatedPaths?.results ) &&
                          <button
                            className="fullScreenVisualToggleButton"
                            onClick={ () => showFullScreenVisual(
                              <PathsGraph
                                relatedPaths={relatedPaths}
                                item={formattedItem}
                                reportType={reportType}
                                setSelectedSignature={setSelectedSignature}
                                setSelectedNode={setSelectedNode}
                                setPathEdge={setPathEdge}
                                edgeOnClickCallback={edgeOnClickCallback}
                                nodeOnClickCallback={nodeOnClickCallback}
                                hoveredListItemID={ hoveredListItemID }
                                hoveredListItemType={ hoveredListItemType }
                                hoveredListItemRating={ hoveredListItemRating }
                                handleRecordCardClose={ handleRecordCardClose }
                              />,
                              'riskInsightDetails section pathsGraph',
                            ) }
                          >
                            { showVisual ? <InlineSVG type="exitFullscreen" /> : <InlineSVG type="fullscreen" /> }
                          </button>
                        }
                      </div>
                    </div>
                    <div className="detailBodyColumn sensitiveAssets">
                      <div className="detailColumnHeader">
                        <div className="stepWrapper">
                          <span className="detailStep two">2</span>
                          <h2>
                            Vulnerable Sensitive Assets
                          </h2>
                        </div>
                      </div>
                      <SensitiveAssetsSection
                        item={formattedItem}
                        reportType={reportType}
                        relatedPaths={relatedPaths}
                      />
                    </div>
                  </div>
              }
              <div className="detailBodyRow">
                <div className="detailBodyColumn remediation">
                  <div className="detailColumnHeader">
                    <div className="stepWrapper">
                      <span className="detailStep three">
                        {
                          ( reportType === 'vulnerability' && item.nofix )
                            ? '1'
                            : '3'
                        }
                      </span>
                      <h2>
                        Remediation Information
                      </h2>
                      {
                        reportType !== 'user' &&
                        <button
                          className={ `remediationInstructionsButton ${ riskToRating( formattedItem[riskType] ) }` }
                          onClick={ () => setShowRemediateModal( true ) }
                        >
                          View Instructions
                        </button>
                      }
                    </div>
                    {
                      (
                        canAddToRemediation.includes( reportType )
                        && isNotEmpty( licenseInfo )
                        && hasAccess( licenseInfo, {}, 'feature', 'remediationWorkflow' )
                      ) &&
                      <AddToPlanMenu
                        items={ { [item.id]: item } }
                        itemsKey={reportType}
                        withCount={false}
                        elementClass={ pageClass }
                        planOptions={ [ 'remediation', 'acceptedRisk' ] }
                      />
                    }
                  </div>
                  <div className="collapsibleSectionsWrapper">
                    <div className="column">
                      {
                        // eslint-disable-next-line max-len
                        Object.entries( collapsibleSectionDataMap[reportType].column_1 ).map( ( [ sectionKey, sectionInfo ], index ) => {
                          return <CollapsibleSection
                            key={index}
                            sectionKey={sectionKey}
                            sectionInfo={sectionInfo}
                            item={formattedItem}
                            reportType={reportType}
                            instanceData={instanceData}
                            setSelectedSignature={setSelectedSignature}
                            setSelectedNode={setSelectedNode}
                            setPathEdge={setPathEdge}
                            setModalContext={setModalContext}
                            relatedPaths={relatedPaths}
                            riskType={riskType}
                            setHoveredListItemID={ setHoveredListItemID }
                            setHoveredListItemType={ setHoveredListItemType }
                            setHoveredListItemRating={ setHoveredListItemRating }
                          />;
                        } )
                      }
                    </div>
                    <div className="column">
                      {
                        // eslint-disable-next-line max-len
                        Object.entries( collapsibleSectionDataMap[reportType].column_2 ).map( ( [ sectionKey, sectionInfo ], index ) => {
                          return <CollapsibleSection
                            key={index}
                            sectionKey={sectionKey}
                            sectionInfo={sectionInfo}
                            item={formattedItem}
                            reportType={reportType}
                            instanceData={instanceData}
                            setSelectedSignature={setSelectedSignature}
                            setSelectedNode={setSelectedNode}
                            setPathEdge={setPathEdge}
                            setModalContext={setModalContext}
                            relatedPaths={relatedPaths}
                            riskType={riskType}
                            setHoveredListItemID={ setHoveredListItemID }
                            setHoveredListItemType={ setHoveredListItemType }
                            setHoveredListItemRating={ setHoveredListItemRating }
                          />;
                        } )
                      }
                    </div>
                  </div>
                </div>
                <div className="detailBodyColumn additional">
                  <div className="detailColumnHeader">
                    <div className="stepWrapper">
                      <span className="detailStep four">
                        {
                          ( reportType === 'vulnerability' && item.nofix )
                            ? '2'
                            : '4'
                        }
                      </span>
                      <h2>
                        Additional Information
                      </h2>
                    </div>
                  </div>
                  <div className="column">
                    <AdditionalInformation
                      item={formattedItem}
                      reportType={reportType}
                      instanceData={instanceData}
                      loading={prefetchedLoading}
                    />
                    {
                      isNotEmpty( collapsibleSectionDataMap[reportType].column_3 ) &&
                      // eslint-disable-next-line max-len
                      Object.entries( collapsibleSectionDataMap[reportType].column_3 ).map( ( [ sectionKey, sectionInfo ], index ) => {
                        return <CollapsibleSection
                          key={index}
                          sectionKey={sectionKey}
                          sectionInfo={sectionInfo}
                          item={formattedItem}
                          reportType={reportType}
                          instanceData={instanceData}
                          setSelectedSignature={setSelectedSignature}
                          setSelectedNode={setSelectedNode}
                          setPathEdge={setPathEdge}
                          relatedPaths={relatedPaths}
                          riskType={riskType}
                          setHoveredListItemID={ setHoveredListItemID }
                          setHoveredListItemType={ setHoveredListItemType }
                          setHoveredListItemRating={ setHoveredListItemRating }
                        />;
                      } )
                    }
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default InsightDetail;
