/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../Utilities';

const XAxisLabels = ( { labels } ) => {
  return (
    <React.Fragment>
      {
        isNotEmpty( labels ) &&
        <div className={ `axisContainer xAxis ticCountClass--${labels.length}` } >
          {
            labels.map( ( label, i ) => {
              return <div className="axisLabel" key={i}>
                { label }
              </div>;
            } )
          }
        </div>
      }
    </React.Fragment>
  );
};

export default XAxisLabels;