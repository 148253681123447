/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../shared/Utilities';
import InlineSVG from '../../../../shared/InlineSVG';
import ExploreItem from '../../Model/ExploreItem';

const PathsFromHere = ( {
  pathsFromHere,
  displayExploreItemNameFor,
  setExternalHoverIDs,
  selectedPathID,
  setSelectedPathID,
} ) => {

  const [ showPathsFromHere, setShowPathsFromHere ] = React.useState( true );

  return (
    <React.Fragment>
      {
        isNotEmpty( pathsFromHere ) &&
        <React.Fragment>
          <div
          // eslint-disable-next-line max-len
            className={ `collapsibleSectionWrapper ${showPathsFromHere ? '' : 'collapsed' }` }
          >
            <div
              className="collapsibleSectionHeader"
              onClick={ () => setShowPathsFromHere( !showPathsFromHere ) }
            >
              <div className="headerLeft">
                <InlineSVG
                  elementClass="itemTypeIcon"
                  type="pathsAlt"
                />
                <h3>
                  Paths from here
                </h3>
              </div>
              <div className="headerRight">
                <strong className="sectionCount">
                  ({pathsFromHere ? pathsFromHere.length : 0})
                </strong>
                <span className="carretWrapper">
                  <InlineSVG type="carretUp"/>
                </span>
              </div>
            </div>
            <div className="collapsibleSectionBody">
              <ul>
                {
                  pathsFromHere.map( ( path, index ) => {
                    return <ExploreItem
                      key={index}
                      item={path}
                      type="path"
                      displayExploreItemNameFor={displayExploreItemNameFor}
                      setExternalHoverIDs={setExternalHoverIDs}
                      standalone
                      noRisk
                      selectedPathID={ selectedPathID }
                      setSelectedPathID={ setSelectedPathID }
                    />;
                  } )
                }
              </ul>
            </div>
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default PathsFromHere;