/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import Form from '../Form';

import ConfigurationAlert from '../ConfigurationAlert';

import {
  CurrentUserContext,
} from '../../Contexts/CurrentUser';

import {
  reportTypes,
  DOES_NOT_EXPIRE,
  EMPTY_FIELDS,
} from './data';

import {
  decodeURLHash,
  isEmpty,
  isNotEmpty,
  removeFromURLHash,
  userDisplayName,
  capitalize,
  paramsToFilters,
  triggerHashRefresh,
} from '../Utilities';

import './style.scss';
import InlineSVG from '../InlineSVG';
import { getFieldValues } from '../Form/Shared';
import { FlashMessageQueueContext } from '../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../legacy/io';
import { ReportCreatorContext } from '../../Contexts/ReportCreator';

const ReportCreator = ( {
  existingReport,
  setExistingReport,
  advanced,
} ) => {
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const [ isValid, setIsValid ] = React.useState( false );

  const [ fields, setFields ] = React.useState( [] );

  // eslint-disable-next-line no-unused-vars
  const [ allUsers, setAllUsers ] = React.useState( {} );
  // eslint-disable-next-line no-unused-vars
  const [ validUsers, setValidUsers ] = React.useState( {} );

  const [ updatedForm, setUpdatedForm ] = React.useState( null );

  const [ currentUser, , ] = React.useContext( CurrentUserContext );


  const [
    creatorActive,
    setCreatorActive,
    ,
    ,
    cancelReportCreator,
  ] = React.useContext( ReportCreatorContext );

  const cancelCallback = () => {
    setExistingReport( null );
    setCreatorActive( false );
  };

  // sets up the help and options for email recipients and owners
  React.useEffect( () => {
    if ( isNotEmpty( currentUser ) ) {
      makeRequest( 'SEARCH', '/user', {
        // eslint-disable-next-line camelcase
        extra_columns: [
          'authentication_provider_id',
          'username',
          'given_name',
          'family_name',
          'api_key',
          'setup_wizard_disabled',
          'email_address',
        ],
        // eslint-disable-next-line camelcase
        order_by:[ [ 'username', 'ASC' ] ],
      } ).then( response => {
        if ( response ) {
          if ( response['results'] && response['results'].length ) {

            const hash = decodeURLHash();

            const reportType = hash.report ? hash.report : hash.page;

            const _validUsers = {};
            const _allUsers = {};
            response.results.map( user => {

              _allUsers[user.id] = userDisplayName( user );

              if ( isNotEmpty( user.email_address ) ) {
                _validUsers[user.id] = userDisplayName( user );
              }
            } );

            const recipients = EMPTY_FIELDS.find( f => f.attribute === 'email_recipients' );
            recipients.options = _validUsers;

            const owner = EMPTY_FIELDS.find( f => f.attribute === 'owner' );
            owner.options = _allUsers;
            owner.defaultValue = currentUser.id;

            const name = EMPTY_FIELDS.find( f => f.attribute === 'label' );
            // eslint-disable-next-line max-len
            name.defaultValue = `${userDisplayName( currentUser )} (${capitalize( reportTypes[reportType] )} Report)`;

            setAllUsers( _allUsers );
            setValidUsers( _validUsers );
            if ( advanced ) {
              const columnOneAttrs = [
                'label',
                'owner',
                'format',
                'full_xlsx_results',
              ];
              const columnTwoAttrs = [
                'schedule',
                'no_expiration',
                'expiration',
                'email_recipients',
              ];
              const columnOne = [];
              const columnTwo = [];

              EMPTY_FIELDS.map( f => {
                if ( columnOneAttrs.includes( f.attribute ) ) {

                  if ( f.attribute === 'format' ) {
                    if ( reportType === 'reporting_dashboard' ) {
                      f.defaultValue = 'pdf';
                      f.options = { pdf: '.pdf' };
                    } else if ( reportType === 'configuration_alerts' ) {
                      f.defaultValue = 'xlsx';
                      f.options = { xlsx: '.xlsx' };
                    } else {
                      f.options = { xlsx: '.xlsx', pdf: '.pdf' };
                    }
                  }

                  columnOne.push( f );
                }
                if ( columnTwoAttrs.includes( f.attribute ) ) {
                  columnTwo.push( f );
                }
              } );

              setFields( {
                one: {
                  fields: columnOne,
                },
                two: {
                  fields: columnTwo,
                },
              } );
            } else {
              setFields( EMPTY_FIELDS );
            }
          }
        }
      } );
    }
  }, [ currentUser, advanced ] );

  // whenever the creator is opened or closed, need to resize other elements on the page
  React.useEffect( () => {
    setTimeout( function () {
      window.dispatchEvent( new Event( 'resize' ) );
    }, 150 );
  }, [ creatorActive ] );

  const isInequalityType = [
    'age',
    'risk_reduction',
    'num_hosts',
    'cvss_base_score',
  ];

  const shouldReturn = action => decodeURLHash()['report_id']
    || decodeURLHash()['return_to_reports']
    || action === 'UPDATE'
    || isNotEmpty( existingReport );

  const onSave = async() => {

    if ( isValid && isNotEmpty( updatedForm ) && isNotEmpty( updatedForm.fieldStates ) ) {

      let action = 'ADD';

      const hash = decodeURLHash();

      const includedValues = getFieldValues( updatedForm.fieldStates, 'report_creator' );

      const newReport = {};

      Object.keys( includedValues ).map( attr => {
        newReport[attr] = includedValues[attr];
      } );

      if ( isEmpty( includedValues.expiration ) ) {
        newReport.expiration = DOES_NOT_EXPIRE;
      }

      if ( isNotEmpty( existingReport ) ) {
        // we are updating, add the id
        newReport.id = existingReport.id;
        action = 'UPDATE';
      }

      const filters = paramsToFilters();

      delete filters['creating_report'];
      delete filters['return_to_reports'];
      delete filters['report_id'];

      const _filters = { ...filters };

      // eslint-disable-next-line camelcase
      const gt_map = {};
      // eslint-disable-next-line camelcase
      const lt_map = {};

      if ( isNotEmpty( _filters ) ) {
        Object.entries( _filters ).map( ( [ key, val ] ) => {
          if ( isInequalityType.includes( key ) ) {
            delete _filters[key];
            const [ inequality, unit ] = val;

            if ( isNotEmpty( unit ) ) {
              if ( inequality === 'gt_map' ) {
                // eslint-disable-next-line camelcase
                gt_map[key] = parseFloat( unit );
              } else if ( inequality === 'lt_map' ) {
                // eslint-disable-next-line camelcase
                lt_map[key] = parseFloat( unit );
              }
            }
            if ( isNotEmpty( gt_map ) ) {
              // eslint-disable-next-line camelcase
              _filters.gt_map = gt_map;
            }
            if ( isNotEmpty( lt_map ) ) {
              // eslint-disable-next-line camelcase
              _filters.lt_map = lt_map;
            }
          } else {
            _filters[key] = val;
          }
        } );
      }
      // eslint-disable-next-line camelcase
      if (
        isNotEmpty( updatedForm.fieldStates.full_xlsx_results )
        && updatedForm.fieldStates.full_xlsx_results.included
      ) {
        // eslint-disable-next-line camelcase
        _filters.full_xlsx_results = updatedForm.fieldStates.full_xlsx_results.updatedValue;
      }

      newReport.filters = _filters;

      newReport.type = hash.report ? reportTypes[hash.report] : reportTypes[hash.page];

      // for the dashboard, we need to pass along the id of the dashboard that needs to be turned into a pdf
      if ( hash.page === 'reporting_dashboard' ) {

        const dID = localStorage.getItem( 'DSdashboardCurrentLayoutID' );

        // eslint-disable-next-line max-len, camelcase
        const dashboard_id = isNotEmpty( dID ) ? JSON.parse( dID ) : '00000000-0000-0000-0000-000000000001';
        // eslint-disable-next-line camelcase
        newReport.filters = { ...newReport.filters, dashboard_id };
      }

      makeRequest( action, '/model/base/exported_report', { record: newReport } ).then( response => {
        if ( response && response.results ) {
          if ( shouldReturn( action ) ) {
            window.location.href = `#.=reporting&page=exports&report_id=${response.results.id}`;
          } else {
            setCreatorActive( false );
            addFlashMessage( {
              type: 'success',
              // eslint-disable-next-line max-len
              body: <p>Successfully created report. To see progress, edit, or download, visit the <a href={`#.=reporting&page=exports&report_id=${response.results.id}`}>reports page</a>.</p>,
            } );
          }
        }
        removeFromURLHash( 'creating_report' );
        triggerHashRefresh();
      } );
    }
  };

  return (
    <React.Fragment>
      {
        creatorActive &&
        <React.Fragment>
          {
            advanced
              ? <div className="reportCreatorForm advanced">
                <h2>
                  Configure Exported Report Options
                  <a
                    className="linkToReports"
                    href="#.=reporting&page=exports"
                  >
                    View all existing exports
                    <InlineSVG type="link" version="primary" />
                  </a>
                </h2>

                {
                  isNotEmpty( fields ) &&
                  <Form
                    fields={fields}
                    setIsValid={setIsValid}
                    existingRecord={existingReport}
                    recordType={ 'exported_report' }
                    onChangeCallback={setUpdatedForm}
                  />
                }
                <ConfigurationAlert
                  sourceType="email"
                  overrideWith={
                    // eslint-disable-next-line max-len
                    <p>The most recent attempt to send email resulted in an SMTP failure. Please see the <a href="#.=activity&page=configuration_alerts&source=email"> relevant configuration alerts </a> for more information.</p>
                  }
                />
                <div className="reportCreatorActions">
                  <button
                    onClick={ () => cancelReportCreator( cancelCallback ) }
                  >
                    Cancel
                  </button>
                  <button
                    onClick={ onSave }
                    disabled={ !isValid }
                    className="submit"
                  >
                    {
                      isNotEmpty( existingReport )
                        ? 'Save Changes'
                        : 'Create Export'
                    }
                  </button>
                </div>
              </div>
              : <div className="reportCreatorForm">
                <h2>
                  <span className="creatorStep">2</span>
                  Additional Export Options
                </h2>
                {
                  isNotEmpty( fields ) &&
                  <Form
                    fields={fields}
                    setIsValid={setIsValid}
                    existingRecord={existingReport}
                    recordType={ 'exported_report' }
                    onChangeCallback={setUpdatedForm}
                  />
                }
                <ConfigurationAlert
                  sourceType="email"
                  overrideWith={
                    // eslint-disable-next-line max-len
                    <p>The most recent attempt to send email resulted in an SMTP failure. Please see the <a href="#.=activity&page=configuration_alerts&source=email"> relevant configuration alerts </a> for more information.</p>
                  }
                />
                <div className="reportCreatorActions">
                  <button
                    onClick={ () => cancelReportCreator( cancelCallback ) }
                  >
                    Cancel
                  </button>
                  <button
                    onClick={ onSave }
                    disabled={ !isValid }
                    className="submit"
                  >
                    {
                      isNotEmpty( existingReport )
                        ? 'Save Changes'
                        : 'Create Export'
                    }
                  </button>
                </div>
                <div className="linkContainer">
                  <a
                    className="linkToReports"
                    href="#.=reporting&page=exports"
                  >
                    Currently Configured Reports
                    <InlineSVG type="link" version="primary" />
                  </a>
                </div>
              </div>
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ReportCreator;
