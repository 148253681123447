/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import Dropdown from '../../../shared/Dropdown';
import InlineSVG from '../../../shared/InlineSVG';
import { isNotEmpty } from '../../../shared/Utilities';

import './DashboardSelector.scss';

const DashboardSelector = ( {
  setEditMode,
  layouts,
  // setLayouts,
  // currentLayoutID,
  setCurrentLayoutID,
  currentLayout,
  setCurrentLayout,
  isBuiltIn,
} ) => {

  const [ menuItems, setMenuItems ] = React.useState( null );

  const handleExistingLayoutClick = layout => {
    setCurrentLayoutID( layout.id );
    // setCurrentLayout( layout );
    window.location.reload();
  };

  const createNewLayout = () => {
    setCurrentLayout( null );
    setEditMode( true );
  };

  React.useEffect( ( ) => {

    const dsLayouts = [];
    const userLayouts = [];

    layouts.map( layout => {
      if ( isBuiltIn( layout ) ) {
        dsLayouts.push(
          <div
            className={ `existingLayout ${ layout.id === currentLayout?.id ? 'current' : ''}` }
            onClick={ () => handleExistingLayoutClick( layout ) }
          >
            <InlineSVG type="primaryLogoBug" version="bug" size="logoBug" elementClass="dsLogo" />
            { layout.label }
          </div>,
        );
      } else {
        userLayouts.push(
          <div
            className={ `existingLayout ${ layout.id === currentLayout?.id ? 'current' : ''}` }
            onClick={ () => handleExistingLayoutClick( layout ) }
          >
            { layout.label }
          </div>,
        );
      }
    } );

    let _menuItems = [
      <label>DeepSurface Dashboards</label>,
      ...dsLayouts,
    ];

    if ( isNotEmpty( userLayouts ) ) {
      _menuItems.push( <label>User Dashboards</label> );
      _menuItems = [
        ..._menuItems,
        ...userLayouts,
      ];
    }

    _menuItems.push(
      <button
        className="menuCreateNewButton"
        onClick={ createNewLayout }
      >
        Create a new dashboard
      </button>,
    );

    setMenuItems( _menuItems );
  }, [ layouts, currentLayout ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( menuItems ) && isNotEmpty( currentLayout ) ) &&
        <div className="dashboardSelectorWrapper">
          {
            isNotEmpty( currentLayout ) &&
            <strong>{ currentLayout.label }</strong>
          }
          <Dropdown
            trigger={
              <div className="triggerTextWrapper">
                <span>
                  Switch Dashboard
                </span>
                <InlineSVG type="carretDown" version="primary" />
              </div>
            }
            menuItems={ menuItems }
            elementClass="dashboardSelector"
          />
        </div>
      }
    </React.Fragment>
  );
};

export default DashboardSelector;