/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';

import PageTitle from './PageTitle';

import {
  decodeURLHash,
  getGlobalSettings,
  isNotEmpty,
} from '../../shared/Utilities';
import RiskScoreMenu from '../App/RiskScoreMenu';
import PageSelector from './PageSelector';

const TopBar = ( { currentLocation } ) => {

  const [ global, setGlobal ] = React.useState( null );

  React.useEffect( async ( ) => {
    const _global = await getGlobalSettings( 'global' );
    if ( isNotEmpty( _global ) ) {
      setGlobal( _global );
    }
  }, [] );

  return (
    <div id="topBar">
      <div className="innerTopBarWrapper">
        <div id="globalHeaderContainer">
          <div className="titleAndSelectorWrapper">
            <div id="pageCreatebuttonRootLeft" />
            <PageTitle currentLocation={currentLocation} />
            <PageSelector
              currentRoute={ decodeURLHash()['.'] }
              currentPage={ decodeURLHash().report ? decodeURLHash().report : decodeURLHash().page }
            />
            {/* empty divs used as a portals for the create button and any alerts */}
            <div id="pageAlertsRoot" />
            {
              !global?.hide_risk_score &&
              <RiskScoreMenu />
            }
          </div>
          <div className="rightHeaderContent">
            <div id="pageCreatebuttonRoot" />
          </div>
        </div>
        {/* empty div used as a portal when a child page needs to insert anything into the top bar (filters, etc.) */}
        <div id="pageHeaderRoot" />
      </div>
    </div>
  );
};

export default TopBar;