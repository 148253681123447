/* eslint-disable camelcase */
import React from 'react';
import { formatNumber, getGlobalSettings, isNotEmpty } from '../../../shared/Utilities';

import { getRecords } from '../../../shared/RecordCache';
import { makeRequest } from '../../../../legacy/io';
import { getGlobalRisk } from '../../../shared/GlobalRisk';
import SpecificRecord from './Widgets/v2/SpecificRecord';
import RiskBreakdown from './Widgets/v2/RiskBreakdown';
import SpecificCategory from './Widgets/v2/SpecificCategory';
import RiskScore from './Widgets/v2/RiskScore';
import PeerPercentile from './Widgets/v2/PeerPercentile';
import PeerPercentileOverTime from './Widgets/v2/PeerPercentileOverTime';
import Text from './Widgets/v2/Text';
import ExploitStatusBreakdown from './Widgets/v2/ExploitStatusBreakdown';
import VulnerabilityInstancesTable from './Widgets/v2/VulnerabilityInstancesTable';
import RemediationPlans from './Widgets/v2/RemediationPlans';
import Paths from './Widgets/v2/Paths';
import PriorityRecords from './Widgets/v2/PriorityRecords';
import CVSSBreakdown from './Widgets/v2/CVSSBreakdown';
import VulnerabilityInstancesCategories from './Widgets/v2/VulnerabilityInstancesCategories';
import RiskOverTimeV2 from './Widgets/v2/RiskOverTimeV2';
import OSFamilyBreakdown from './Widgets/v2/OSFamilyBreakdown';
import TagBreakdown from './Widgets/v2/TagBreakdown';
import VulnerabilityAgeBreakdown from './Widgets/v2/VulnerabilityAgeBreakdown';
import VulnerabilityInstancesTotal from './Widgets/v2/VulnerabilityInstancesTotal';
import ScanningAgeBreakdown from './Widgets/v2/ScanningAgeBreakdown';
import ScanningScanGroupBreakdown from './Widgets/v2/ScanningScanGroupBreakdown';
import ScanningAgentVersionBreakdown from './Widgets/v2/ScanningAgentVersionBreakdown';
import ScanningTotal from './Widgets/v2/ScanningTotal';
import TopRecordDetails from './Widgets/v2/TopRecordDetails';
import RecordTotal from './Widgets/v2/RecordTotal';
import SpecificRemediationPlan from './Widgets/v2/SpecificRemediationPlan';

export const defaultParams = { project: 'default', model: 'base' };

export const multipleOrderByOptions = [
  'cvss_exploit',
  'cvss_hosts',
  'exploit_cvss',
  'exploit_hosts',
  'hosts_cvss',
  'hosts_exploit',
];

const CURRENT_VERSION = 2;

// some convenience vars so that the widgets fit on a pdf 11*8.5, each row is 60px, 12 rows can fit on a landscape pdf
const pageHeight = {
  full: 12,
  threeQuarters: 9,
  twoThirds: 8,
  half: 6,
  third: 4,
  quarter: 3,
  sixth: 2,
  twelfth: 1,
};

// some convenience vars so that the widgets fit on a pdf 11*8.5, 6 columns
const pageWidth = {
  full: 6,
  half: 3,
  twoThirds: 4,
  third: 2,
};

// different params needed for the different fetches
export const paramsMap = {
  table: {
    hosts: {
      rownums: [ 0, 200 ],
      model: 'base',
      group_type: 'host',
      project: 'default',
      filters: {
        num_sensitive_nodes: 'any',
        'ii.has_host': true,
        sort_by: 'risk',
        sort_direction: 'DESC',
        accepted_risk: 'false',
      },
      order_by: [
        [ 'filtered_risk', 'DESC' ],
        [ 'id', 'DESC' ],
      ],
    },
    patches: {
      model: 'base',
      project: 'default',
      rownums: [ 0, 200 ],
      filters: {
        superseded: 'any',
        risk_type: 'risk',
        sort_by: 'risk',
        sort_direction: 'DESC',
        accepted_risk: 'false',
      },
      order_by: [
        [ 'risk', 'DESC' ],
        [ 'vendor', 'ASC' ],
        [ 'id', 'DESC' ],
      ],
      group_type: 'patch_cumulative',
    },
    vulnerabilities: {
      rownums: [ 0, 200 ],
      model: 'base',
      group_type: 'vulnerability',
      project: 'default',
      filters: {
        patchable: 'any',
        sort_by: 'risk',
        sort_direction: 'DESC',
        accepted_risk: 'false',
        exploit_status: 'null',
      },
      order_by: [
        [ 'filtered_risk', 'DESC' ],
        [ 'id', 'DESC' ],
      ],
    },
    users: {
      rownums: [ 0, 200 ],
      model: 'base',
      group_type: 'host',
      project: 'default',
      filters: {
        rownums: [ 0, 200 ],
        field_map: {
          type: 'domain_user',
        },
        extra_columns: [
          'name',
          'node_id',
          'domain_user_analysis.domain_name',
          'domain_user_analysis.risk',
          'domain_user_analysis.active_hosts',
          'domain_user_analysis.domain_groups',
          'sid',
        ],
        order_by: [
          [ 'domain_user_analysis.risk', 'DESC' ],
          [ 'id', 'DESC' ],
        ],
      },
    },
  },
  metric: {
    category: defaultParams,
    risk: defaultParams,
    target: {},
    hosts: null,
    percentile: null,
  },
  cvss: defaultParams,
  exploit_status: defaultParams,
  vulnerability_instances: defaultParams,
  peer_comparison: null,
  risk_over_time: {},
  top_paths: {
    // eslint-disable-next-line camelcase
    extra_columns: [
      'keywords',
      'risk',
      'modified',
      'edges',
      'node_labels',
    ],
    // eslint-disable-next-line camelcase
    order_by: [
      [ 'risk', 'DESC' ],
    ],
    rownums: [ 0, 5 ],
  },
  remediation_plans: {
    // eslint-disable-next-line camelcase
    extra_columns: [
      'username',
      'given_name',
      'family_name',
      'email_address',
    ],
    'order_by':[ [ 'username', 'ASC' ] ],
  },
  group: {
    project: 'default',
    model: 'base',
    filters: { 'accepted_risk': false },
    rownums: [ 0, 200 ],
    group_type: 'host',
    order_by: [ [ 'filtered_risk', 'DESC' ] ],
  },
  scanning: { filters: {} },
};

export const legacyWidgetKeys = [
  'risk_over_time',
  'top_paths',
  'table',
  'priority',
  'metric',
  'vulnerability_instances',
  'peer_comparison',
  'exploit_status',
  'cvss',
  'remediation_plans',
];

export const getWidgetDescription = ( widget, data ) => {

  const orderByToWords = orderBy => {
    switch ( orderBy ) {
    case 'filtered_risk':
      return 'risk score';
    case 'cvss_base_score':
      return 'CVSS score';
    case 'exploit_status':
      return 'exploit status';
    case 'num_hosts':
      return 'number of affected hosts';
    case 'num_vulnerabilities':
      return 'amount of vulnerabilities';
    case 'cvss_exploit':
      return 'CVSS score and then exploit status';
    case 'cvss_hosts':
      return 'CVSS score and then number of affected hosts';
    case 'exploit_cvss':
      return 'exploit status and then CVSS score';
    case 'exploit_hosts':
      return 'exploit status and then number of hosts';
    case 'hosts_cvss':
      return 'number of hosts and then CVSS score';
    case 'hosts_exploit':
      return 'number of hosts and then exploit status';
    default:
      return 'risk score';
    }
  };

  if ( isNotEmpty( widget ) ) {

    switch  ( widget.key ) {
    case 'hosts_priority':
      // eslint-disable-next-line max-len
      return <p>The top <strong>{ widget?.settings?.item_count } highest priority hosts</strong> in your environment sorted by { orderByToWords( widget?.settings?.order_by ) }</p>;
    case 'patches_priority':
      // eslint-disable-next-line max-len
      return <p>The top <strong>{ widget?.settings?.item_count } highest priority patches</strong> that need to be applied in your environment</p>;
    case 'vulnerabilities_priority':
      // eslint-disable-next-line max-len
      return <p>The top <strong>{ widget?.settings?.item_count } highest priority unpatched vulnerabilities</strong> in your environment sorted by { orderByToWords( widget?.settings?.order_by ) }</p>;
    case 'users_priority':
      // eslint-disable-next-line max-len
      return <p>The top <strong>{ widget?.settings?.item_count } highest priority users</strong> in your environment</p>;

    case 'hosts_global':
      // eslint-disable-next-line max-len
      return <p>A breakdown of all <strong>{ formatNumber( data?.total ) } host(s)</strong> in your environment by risk level</p>;
    case 'patches_global':
      // eslint-disable-next-line max-len
      return <p>A breakdown of all <strong>{ formatNumber( data?.total ) } patch(es)</strong> in your environment by risk level</p>;
    case 'vulnerabilities_global':
      // eslint-disable-next-line max-len
      return <p>A breakdown of all <strong>{ formatNumber( data?.total ) } vulnerabilities</strong> in your environment by risk level</p>;

    case 'paths_global':
      // eslint-disable-next-line max-len
      return <p>Below are the <strong>top { widget?.settings?.item_count }</strong> critical paths in your environment. A critical path represents a way that an external attacker could gain access to a sensitive asset in your environment by exploiting 1 or more unpatched vulnerabilities.</p>;
    case 'paths_specific':
      // eslint-disable-next-line max-len
      return <p>Below are the <strong>top { widget?.settings?.item_count }</strong> critical paths for this record. A critical path represents a way that an external attacker could gain access to a sensitive asset in your environment by exploiting 1 or more unpatched vulnerabilities.</p>;

    case 'vulnerability_instances_cvss_breakdown':
      // eslint-disable-next-line max-len
      return <p>A breakdown of all of the vulnerability instances in your environment broken down by CVSS score.</p>;
    case 'vulnerability_instances_exploit_status_breakdown':
      // eslint-disable-next-line max-len
      return <p>A breakdown of all of the vulnerability instances in your environment broken down by exploit status.</p>;
    case 'vulnerability_instances_os_family_breakdown':
      // eslint-disable-next-line max-len
      return <p>A breakdown of all of the vulnerability instances in your environment broken down by OS family.</p>;
    case 'vulnerability_instances_tag_breakdown':
      // eslint-disable-next-line max-len
      return <p>A breakdown of all of the vulnerability instances in your environment broken down by tag.</p>;
    case 'vulnerability_instances_vulnerability_age_breakdown':
      // eslint-disable-next-line max-len
      return <p>A breakdown of all of the vulnerability instances in your environment broken down by vulnerability age.</p>;
    case 'vulnerability_instances_global':
      // eslint-disable-next-line max-len
      return <p>Before performing any analysis and assigning risk to the various hosts, patches, and vulnerabilities in your environment, DeepSurface first prioritizes all of the vulnerability instances reported by your vulnerability scanner(s). Deepsurface categorizes and prioritizes the vulnerability instances based on various characteristics and is often able to eliminate a large chunk of the instances. Hover over any of the categories below to get a more detailed description of each and click through to see a filtered list of the instances within that category.</p>;

    case 'scanning_agent_version_breakdown':
      return <p>A breakdown of the hosts scanned in your environment by agent version.</p>;
    case 'scanning_scan_group_breakdown':
      return <p>A breakdown of the hosts scanned in your environment by scan group version.</p>;
    case 'scanning_age_breakdown':
      return <p>A breakdown of the hosts scanned in your environment by how long it has been since being scanned.</p>;
    case 'risk_global':
      if ( isNotEmpty( widget?.settings ) && isNotEmpty( widget?.settings?.report_type ) ) {
        if ( widget.settings.report_type === 'hosts' ) {
          // eslint-disable-next-line max-len
          return <p>A breakdown of all <strong>{ formatNumber( data?.total ) } host(s)</strong> in your environment by risk level</p>;
        } else if ( widget.settings.report_type === 'patches' ) {
          // eslint-disable-next-line max-len
          return <p>A breakdown of all <strong>{ formatNumber( data?.total ) } patch(es)</strong> in your environment by risk level</p>;
        } else if ( widget.settings.report_type === 'vulnerabilities' ) {
          // eslint-disable-next-line max-len
          return <p>A breakdown of all <strong>{ formatNumber( data?.total ) } vulnerabilities</strong> in your environment by risk level</p>;
        }
      }
      return '';
    case 'risk_over_time':
      // eslint-disable-next-line max-len
      return <p>DeepSurface tracks the changes in your risk over time. Your overall risk can change depending on several factors, including but not limited to instances and hosts being added or removed from your environment. To see what may have changed in your environment at a given moment, click on that part of the line chart below.</p>;
    case 'risk_peer_percentile_over_time':
      // eslint-disable-next-line max-len
      return <p>DeepSurface tracks the changes in your peer percentile over time, hover over the chart below to see what your percentile was at a given moment.</p>;
    default:
      break;
    }
  }
};

export const v2WidgetsWithLegends = [
  'hosts_global',
  'patches_global',
  'vulnerabilities_global',
  'risk_global',
  'vulnerability_instances_exploit_status_breakdown',
  'vulnerability_instances_cvss_breakdown',
  'vulnerability_instances_os_family_breakdown',
  'vulnerability_instances_vulnerability_age_breakdown',
];

export const fetchForWidgetsV2 = {
  hosts_global: [ 'risk_breakdown' ],
  patches_global: [ 'risk_breakdown' ],
  vulnerabilities_global: [ 'risk_breakdown' ],

  hosts_total: [ 'risk_breakdown' ],
  vulnerabilities_total: [ 'risk_breakdown' ],
  patches_total: [ 'risk_breakdown' ],

  hosts_top_details: [ 'hosts_list' ],
  patches_top_details: [ 'patches_list' ],
  vulnerabilities_top_details: [ 'vulnerabilities_list' ],

  vulnerability_instances_cvss_breakdown: [ 'tally' ],
  vulnerability_instances_exploit_status_breakdown: [ 'tally' ],
  vulnerability_instances_category: [ 'tally' ],
  vulnerability_instances_global: [ 'tally' ],
  vulnerability_instances_total: [ 'tally' ],

  vulnerability_instances_os_family_breakdown: [ 'tally_os' ],
  vulnerability_instances_tag_breakdown: [ 'tally_tag' ],
  vulnerability_instances_vulnerability_age_breakdown: [ 'tally_age' ],

  hosts_priority: [ 'hosts_list' ],
  patches_priority: [ 'patches_list' ],
  vulnerabilities_priority: [ 'vulnerabilities_list' ],
  users_priority: [ 'users_list' ],

  paths_global: [ 'paths' ],

  risk_peer_percentile: [ 'percentile' ],
  risk_peer_percentile_over_time: [ 'percentile' ],
  risk_over_time: [ 'risk', 'project' ],
  risk_target: [ 'model', 'project' ],
  risk_global: [ 'risk_breakdown' ],
  paths_specific: [ 'paths_specific' ],

  scanning_total: [ 'scanning_agent', 'scanning_credentialed' ],
  scanning_scan_group_breakdown: [ 'scanning_credentialed', 'scan_group' ],
  scanning_agent_version_breakdown: [ 'scanning_agent' ],
  scanning_age_breakdown: [ 'scanning_credentialed', 'scanning_agent' ],

  remediation_plans: [ 'remediationPlans', 'remediationUsers' ],
  remediation_specific_plan: [ 'remediationPlans', 'remediationUsers' ],
};

export const v2Fetches = {
  risk_breakdown: () => makeRequest( 'TALLY', '/model/base/insight', {} ),
  tally: () => makeRequest( 'TALLY', '/analysis/instance', defaultParams ),
  // eslint-disable-next-line max-len
  tally_os: () => makeRequest( 'TALLY', '/analysis/instance', { ...defaultParams, types: [ 'category', 'exploit_status', 'cvss', 'os_family' ] } ),
  // eslint-disable-next-line max-len
  tally_tag: () => makeRequest( 'TALLY', '/analysis/instance', { ...defaultParams, types: [ 'category', 'exploit_status', 'cvss', 'asset_tag' ] } ),
  // eslint-disable-next-line max-len
  tally_age: () => makeRequest( 'TALLY', '/analysis/instance', { ...defaultParams, types: [ 'category', 'exploit_status', 'cvss', 'vulnerability_age' ] } ),
  hosts_list: () => getRecords( 'HOST', paramsMap.table.hosts, true ),
  patches_list: () => getRecords( 'PATCH', paramsMap.table.patches, true ),
  vulnerabilities_list: () => getRecords( 'VULNERABILITY', paramsMap.table.vulnerabilities, true ),
  users_list: () => makeRequest( 'SEARCH', '/model/domain_user', paramsMap.table.users ),
  paths: () => getRecords( 'path', paramsMap.top_paths ),
  paths_specific: () => {},
  percentile: () => makeRequest( 'FETCH', '/analysis/industry_percentile_history', defaultParams ),

  scanning_credentialed: () => makeRequest( 'TALLY', '/scan_status/agentless/default', { filters: {} } ),
  scanning_agent: () => makeRequest( 'TALLY', '/scan_status/agent/default', { filters: {} } ),
  scan_group: () => makeRequest( 'FIND', '/project/default/scan_group', {} ),

  remediationPlans: () => makeRequest( 'LIST', '/project/default/model/base/remediation_plan', {} ),
  remediationUsers: () => makeRequest( 'SEARCH', '/user', {
    // eslint-disable-next-line camelcase
    extra_columns: [
      'authentication_provider_id',
      'username',
      'given_name',
      'family_name',
      'api_key',
      'setup_wizard_disabled',
      'email_address',
    ],
    'order_by':[ [ 'username', 'ASC' ] ],
  } ),
  risk: () => getGlobalRisk(),
  project: () => getGlobalSettings( 'project' ),
  model: () => makeRequest( 'FETCH', '/model', defaultParams ),
};

// v2 components
export const v2WidgetComponentMap = {
  hosts_specific: SpecificRecord,
  hosts_priority: PriorityRecords,
  hosts_global: RiskBreakdown,
  hosts_top_details: TopRecordDetails,
  hosts_total: RecordTotal,

  patches_specific: SpecificRecord,
  patches_priority: PriorityRecords,
  patches_global: RiskBreakdown,
  patches_top_details: TopRecordDetails,
  patches_total: RecordTotal,

  vulnerabilities_specific: SpecificRecord,
  vulnerabilities_priority: PriorityRecords,
  vulnerabilities_global: RiskBreakdown,
  vulnerabilities_top_details: TopRecordDetails,
  vulnerabilities_total: RecordTotal,

  users_priority: PriorityRecords,

  paths_specific: Paths,
  paths_global: Paths,

  vulnerability_instances_global: VulnerabilityInstancesCategories,
  vulnerability_instances_exploit_status_breakdown: ExploitStatusBreakdown,
  vulnerability_instances_cvss_breakdown: CVSSBreakdown,
  vulnerability_instances_os_family_breakdown: OSFamilyBreakdown,
  vulnerability_instances_tag_breakdown: TagBreakdown,
  vulnerability_instances_vulnerability_age_breakdown: VulnerabilityAgeBreakdown,
  vulnerability_instances_total: VulnerabilityInstancesTotal,
  vulnerability_instances_table: VulnerabilityInstancesTable,
  vulnerability_instances_category: SpecificCategory,

  risk_score: RiskScore,
  risk_peer_percentile: PeerPercentile,
  risk_global: RiskBreakdown,
  risk_over_time: RiskOverTimeV2,
  risk_peer_percentile_over_time: PeerPercentileOverTime,
  risk_target: RiskScore,

  remediation_plans: RemediationPlans,
  remediation_specific_plan: SpecificRemediationPlan,

  scanning_age_breakdown: ScanningAgeBreakdown,
  scanning_scan_group_breakdown: ScanningScanGroupBreakdown,
  scanning_agent_version_breakdown: ScanningAgentVersionBreakdown,
  scanning_total: ScanningTotal,

  text_free_form: Text,
  // text_heading: Heading,
};
// v2 widgets
export const availableWidgetsV2 = {
  host: {
    label: 'Host',
    icon: 'hostsAlt',
    options: {
      // record card
      specific: {
        version: 2,
        label: 'Specific Host',
        fullLabel: '',
        key: 'hosts_specific',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.half,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.third,
        settings: {
          record_id: '',
          report_type: 'host',
          include_description: false,
        },
      },
      hosts_top_details: {
        version: 2,
        label: 'Top Hosts w/ Details',
        fullLabel: '',
        key: 'hosts_top_details',
        minH: pageHeight.third,
        maxH: pageHeight.full * 4,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.full,
        w: pageWidth.third,
        settings: {
          item_count: 3,
          report_type: 'hosts',
          orientation: 'vertical',
        },
      },
      // table, list, barchart
      priority: {
        version: 2,
        label: 'Highest Priority Hosts',
        fullLabel: 'Hosts: Highest Priority (100)',
        key: 'hosts_priority',
        minH: pageHeight.third,
        maxH: pageHeight.full * 4,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.half,
        settings: {
          version: 'table',
          item_count: 25,
          report_type: 'hosts',
          include_rating: true,
          include_user: false,
          include_os_type: false,
          include_description: true,
        },
      },
      total: {
        version: 2,
        label: 'Total',
        fullLabel: '',
        key: 'hosts_total',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
        settings: {
          report_type: 'hosts',
        },
      },
      // donut of hosts by risk category
      global: {
        version: 2,
        label: 'Host Risk Breakdown',
        fullLabel: 'Hosts: Risk Breakdown',
        key: 'hosts_global',
        settings: {
          report_type: 'hosts',
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
    },
  },
  patch: {
    label: 'Patch',
    icon: 'patchesAlt',
    options: {
      // record card
      specific: {
        version: 2,
        label: 'Specific Patch',
        fullLabel: '',
        key: 'patches_specific',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.half,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.third,
        settings: {
          record_id: '',
          report_type: 'patch',
          include_description: false,
        },
      },
      patches_top_details: {
        version: 2,
        label: 'Top Patches w/ Details',
        fullLabel: '',
        key: 'patches_top_details',
        minH: pageHeight.third,
        maxH: pageHeight.full * 4,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.full,
        w: pageWidth.third,
        settings: {
          item_count: 3,
          report_type: 'patches',
          orientation: 'vertical',
        },
      },
      // table, list, barchart
      priority: {
        version: 2,
        label: 'Highest Priority Patches',
        fullLabel: 'Patches: Highest Priority (100)',
        key: 'patches_priority',
        minH: pageHeight.third,
        maxH: pageHeight.full * 4,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.half,
        settings: {
          version: 'table',
          item_count: 25,
          report_type: 'patches',
          include_rating: true,
          include_description: true,
        },
      },
      total: {
        version: 2,
        label: 'Total',
        fullLabel: '',
        key: 'patches_total',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
        settings: {
          report_type: 'patches',
        },
      },
      // donut of hosts by risk category
      global: {
        version: 2,
        label: 'Patch Risk Breakdown',
        fullLabel: 'Patches: Risk Breakdown',
        key: 'patches_global',
        settings: {
          report_type: 'patches',
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
    },
  },
  vulnerability: {
    label: 'Vulnerability',
    icon: 'vulnerabilitiesAlt',
    options: {
      // record card
      specific: {
        version: 2,
        label: 'Specific Vulnerability',
        fullLabel: '',
        key: 'vulnerabilities_specific',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.half,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.third,
        settings: {
          record_id: '',
          report_type: 'vulnerability',
          include_description: false,
        },
      },
      vulnerabilities_top_details: {
        version: 2,
        label: 'Top Vulnerabilities w/ Details',
        fullLabel: '',
        key: 'vulnerabilities_top_details',
        minH: pageHeight.third,
        maxH: pageHeight.full * 4,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.full,
        w: pageWidth.third,
        settings: {
          item_count: 3,
          report_type: 'vulnerabilities',
          orientation: 'vertical',
        },
      },
      // table, list, barchart
      priority: {
        version: 2,
        label: 'Highest Priority Vulnerabilities',
        fullLabel: 'Vulnerabilities: Highest Priority (100)',
        key: 'vulnerabilities_priority',
        minH: pageHeight.third,
        maxH: pageHeight.full * 4,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.half,
        settings: {
          version: 'table',
          item_count: 25,
          report_type: 'vulnerabilities',
          include_rating: true,
          include_description: true,
        },
      },
      total: {
        version: 2,
        label: 'Total',
        fullLabel: '',
        key: 'vulnerabilities_total',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
        settings: {
          report_type: 'vulnerabilities',
        },
      },
      // donut of hosts by risk category
      global: {
        version: 2,
        label: 'Vulnerability Risk Breakdown',
        fullLabel: 'Vulnerabilities: Risk Breakdown',
        key: 'vulnerabilities_global',
        settings: {
          report_type: 'vulnerabilities',
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,

      },
    },
  },
  user: {
    label: 'User',
    icon: 'domainUser',
    options: {
      // table, list, barchart
      priority: {
        version: 2,
        label: 'Highest Priority Users',
        fullLabel: 'Users: Highest Priority',
        key: 'users_priority',
        minH: pageHeight.third,
        maxH: pageHeight.full * 4,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.half,
        settings: {
          version: 'table',
          item_count: 25,
          report_type: 'users',
          include_rating: true,
          include_description: true,
        },
      },
    },
  },
  path: {
    label: 'Path',
    icon: 'pathsAlt',
    options: {
      // paths by host/patch/vuln
      specific: {
        version: 2,
        label: 'Top Paths (specifc item)',
        fullLabel: '',
        key: 'paths_specific',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.twoThirds,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.full,
        settings: {
          record_id: '',
          report_type: 'host',
          item_count: 3,
          include_description: true,
        },
      },
      // system-wide paths
      global: {
        version: 2,
        label: 'Top Paths (system wide)',
        fullLabel: 'Top 5 Critical Paths',
        key: 'paths_global',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.twoThirds,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.full,
        settings: {
          item_count: 5,
          include_description: true,
        },
      },
    },
  },
  instances: {
    label: 'Vuln. Instance',
    icon: 'vulnerabilitiesAlt',
    options: {
      // full, simplified
      global: {
        version: 2,
        label: 'Categories',
        fullLabel: '',
        key: 'vulnerability_instances_global',
        minH: pageHeight.half,
        maxH: pageHeight.full,
        minW: pageWidth.full,
        maxW: pageWidth.full,
        resizeHandles: [ 's' ],
        h: pageHeight.half,
        w: pageWidth.full,
        settings: {
          version: 'full',
          include_description: true,
        },
      },
      // breakdown by exploit status
      exploit_status: {
        version: 2,
        label: 'Exploit Status Breakdown',
        fullLabel: 'Vulnerability Instances: Exploit Status Breakdown',
        key: 'vulnerability_instances_exploit_status_breakdown',
        settings: {
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.twoThirds,
        minW: pageWidth.third,
        maxW: pageWidth.half,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
      // breakdown by CVSS
      cvss: {
        version: 2,
        label: 'CVSS Breakdown',
        fullLabel: 'Vulnerability Instances: CVSS Breakdown',
        key: 'vulnerability_instances_cvss_breakdown',
        settings: {
          include_description: true,
          version: 'barchart',
        },
        minH: pageHeight.third,
        maxH: pageHeight.twoThirds,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
      // the lower table groupings host/patch/vuln/sig
      // not really necessary considering the priority widget is there, will consider adding back if really necessary
      // table: {
      //   version: 2,
      //   label: 'Table by group',
      //   fullLabel: 'Vulnerability Instances: Groupings',
      //   key: 'vulnerability_instances_table',
      //   minH: pageHeight.half,
      //   maxH: pageHeight.full * 4,
      //   minW: pageWidth.twoThirds,
      //   maxW: pageWidth.full,
      //   h: pageHeight.twoThirds,
      //   w: pageWidth.twoThirds,
      //   settings: {
      //     group_by: 'hosts',
      //     include_description: true,
      //   },
      //   resizeHandles: [ 's' ],
      // },
      os_family: {
        version: 2,
        label: 'OS Family Breakdown',
        fullLabel: 'Vulnerability Instances: OS Family Breakdown',
        key: 'vulnerability_instances_os_family_breakdown',
        settings: {
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.twoThirds,
        minW: pageWidth.third,
        maxW: pageWidth.half,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
      tag: {
        version: 2,
        label: 'Tag Breakdown',
        fullLabel: 'Vulnerability Instances: Tag Breakdown',
        key: 'vulnerability_instances_tag_breakdown',
        settings: {
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.twoThirds,
        minW: pageWidth.third,
        maxW: pageWidth.half,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
      vulnerability_age: {
        version: 2,
        label: 'Vulnerability Age Breakdown',
        fullLabel: 'Vulnerability Instances: Vulnerability Age Breakdown',
        key: 'vulnerability_instances_vulnerability_age_breakdown',
        settings: {
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.twoThirds,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
      // just a donut of a specific category
      specific: {
        version: 2,
        label: 'Specific Instance Category',
        fullLabel: 'Vulnerability Instances: Category',
        key: 'vulnerability_instances_category',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
        settings: {
          category: 'carries_risk',
          include_description: false,
        },
      },
      // just a big number... kind of dumb
      total: {
        version: 2,
        label: 'Total',
        fullLabel: '',
        key: 'vulnerability_instances_total',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
    },
  },
  risk: {
    label: 'Risk',
    icon: 'notificationAlert',
    options: {
      // risk score
      score: {
        version: 2,
        label: 'Risk Score',
        fullLabel: '',
        key: 'risk_score',
        settings: {
          include_description: false,
        },
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
      // peer score
      peer: {
        version: 2,
        label: 'Peer Percentile',
        fullLabel: '',
        key: 'risk_peer_percentile',
        settings: {
          include_description: false,
        },
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
      // donut of host/patch/vuln risk scores
      global: {
        version: 2,
        label: 'Risk Score Breakdown',
        fullLabel: 'Risk: Score Breakdown',
        key: 'risk_global',
        settings: {
          include_description: true,
          report_type: 'hosts',
        },
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
      // risk over time w/ current
      risk_over_time: {
        version: 2,
        label: 'Risk Over Time',
        fullLabel: 'Risk: Over Time',
        key: 'risk_over_time',
        minH: pageHeight.half,
        maxH: pageHeight.full,
        minW: pageWidth.full,
        maxW: pageWidth.full,
        resizeHandles: [ 's' ],
        h: pageHeight.half,
        w: pageWidth.full,
        settings: {
          include_escalations: true,
          include_hosts: true,
          patch_tuesday: false,
          include_description: true,
        },
      },
      // peer over time w/ current
      peer_over_time: {
        version: 2,
        label: 'Peer Percentile Over Time',
        fullLabel: 'Risk: Peer Percentile Over Time',
        key: 'risk_peer_percentile_over_time',
        minH: pageHeight.half,
        maxH: pageHeight.full,
        minW: pageWidth.full,
        maxW: pageWidth.full,
        resizeHandles: [ 's' ],
        h: pageHeight.half,
        w: pageWidth.full,
        settings: {
          include_description: true,
        },
      },
      // target risk (looks similar to risk score)
      target_risk: {
        version: 2,
        label: 'Target Risk',
        fullLabel: '',
        key: 'risk_target',
        settings: {
          include_description: false,
        },
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
    },
  },
  scanning: {
    label: 'Scanning',
    icon: 'scanning_nav',
    options: {
      scanning_age_breakdown: {
        version: 2,
        label: 'Scanning Age Breakdown',
        fullLabel: 'Scanning: Scanning Age Breakdown',
        key: 'scanning_age_breakdown',
        settings: {
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.twoThirds,
        minW: pageWidth.third,
        maxW: pageWidth.half,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
      version_breakdown: {
        version: 2,
        label: 'Agent Version Breakdown',
        fullLabel: 'Scanning: Agent Version Breakdown',
        key: 'scanning_agent_version_breakdown',
        settings: {
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.twoThirds,
        minW: pageWidth.third,
        maxW: pageWidth.half,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
      scan_group_breakdown: {
        version: 2,
        label: 'Scan Group Breakdown',
        fullLabel: 'Scanning: Scan Group Breakdown',
        key: 'scanning_scan_group_breakdown',
        settings: {
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.twoThirds,
        minW: pageWidth.third,
        maxW: pageWidth.half,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
      // just a big number... kind of dumb
      total: {
        version: 2,
        label: 'Total',
        fullLabel: '',
        key: 'scanning_total',
        minH: pageHeight.third,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.twoThirds,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.third,
      },
    },
  },
  remediation: {
    label: 'Remediation',
    icon: 'remediation_nav',
    options: {
      // bar chart of plan completion
      plans: {
        version: 2,
        label: 'All Plans',
        fullLabel: 'Remediation: All Plans',
        key: 'remediation_plans',
        settings: {
          include_description: true,
        },
        minH: pageHeight.third,
        maxH: pageHeight.full * 2,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.half,
      },
      // bar chart of plan completion
      specific_plan: {
        version: 2,
        label: 'Specific Plan',
        fullLabel: '',
        key: 'remediation_specific_plan',
        settings: {
          include_description: true,
          plan_id: '',
        },
        minH: pageHeight.third,
        maxH: pageHeight.full * 2,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.half,
        w: pageWidth.half,
      },
    },
  },
  text: {
    label: 'Text',
    icon: 'text',
    options: {
      free_form: {
        version: 2,
        label: 'Free Form Text',
        fullLabel: '',
        key: 'text_free_form',
        settings: {
          heading: '',
          // eslint-disable-next-line max-len
          content: '',
        },
        minH: pageHeight.sixth,
        maxH: pageHeight.full,
        minW: pageWidth.third,
        maxW: pageWidth.full,
        resizeHandles: [ 's', 'e' ],
        h: pageHeight.third,
        w: pageWidth.full,
      },
    },
  },
};

// no settings for these widgets
export const UneditableWidgets = [
  'risk_score',
  'risk_peer_percentile',
  'risk_target',
];

// instead of storing the minH/maxH and minW/maxW on the widget itself, just grab the settings here to make it easier
// to make adjustments
export const v2WidgetMinMaxDimensions = {
  hosts_specific: {
    minH: pageHeight.third,
    maxH: pageHeight.full,
    minW: pageWidth.third,
    maxW: pageWidth.half,
    resizeHandles: [ 's', 'e' ],
  },
  hosts_priority: {
    minH: pageHeight.third,
    maxH: pageHeight.full * 4,
    minW: pageWidth.third,
    maxW: pageWidth.full,
    resizeHandles: [ 's', 'e' ],
  },
  hosts_global: {
    minH: pageHeight.third,
    maxH: pageHeight.full,
    minW: pageWidth.third,
    maxW: pageWidth.twoThirds,
    resizeHandles: [ 's', 'e' ],
  },
  hosts_top_details: {
    minH: pageHeight.third,
    maxH: pageHeight.full * 4,
    minW: pageWidth.third,
    maxW: pageWidth.full,
    resizeHandles: [ 's', 'e' ],
  },
  hosts_total: {
    minH: pageHeight.third,
    maxH: pageHeight.full,
    minW: pageWidth.third,
    maxW: pageWidth.twoThirds,
    resizeHandles: [ 's', 'e' ],
  },

  patches_specific: {
    minH: pageHeight.third,
    maxH: pageHeight.full,
    minW: pageWidth.third,
    maxW: pageWidth.half,
    resizeHandles: [ 's', 'e' ],
  },
  patches_priority: {
    minH: pageHeight.third,
    maxH: pageHeight.full * 4,
    minW: pageWidth.third,
    maxW: pageWidth.full,
    resizeHandles: [ 's', 'e' ],
  },
  patches_global: {
    minH: pageHeight.third,
    maxH: pageHeight.full,
    minW: pageWidth.third,
    maxW: pageWidth.twoThirds,
    resizeHandles: [ 's', 'e' ],
  },
  patches_top_details: {
    minH: pageHeight.third,
    maxH: pageHeight.full * 4,
    minW: pageWidth.third,
    maxW: pageWidth.full,
    resizeHandles: [ 's', 'e' ],
  },
  patches_total: {
    minH: pageHeight.third,
    maxH: pageHeight.full,
    minW: pageWidth.third,
    maxW: pageWidth.twoThirds,
    resizeHandles: [ 's', 'e' ],
  },

  vulnerabilities_specific: {
    minH: pageHeight.third,
    maxH: pageHeight.full,
    minW: pageWidth.third,
    maxW: pageWidth.half,
    resizeHandles: [ 's', 'e' ],
  },
  vulnerabilities_priority: {
    minH: pageHeight.third,
    maxH: pageHeight.full * 4,
    minW: pageWidth.third,
    maxW: pageWidth.full,
    resizeHandles: [ 's', 'e' ],
  },
  vulnerabilities_global: {
    minH: pageHeight.third,
    maxH: pageHeight.full,
    minW: pageWidth.third,
    maxW: pageWidth.twoThirds,
    resizeHandles: [ 's', 'e' ],
  },
  vulnerabilities_top_details: {
    minH: pageHeight.third,
    maxH: pageHeight.full * 4,
    minW: pageWidth.third,
    maxW: pageWidth.full,
    resizeHandles: [ 's', 'e' ],
  },
  vulnerabilities_total: {
    minH: pageHeight.third,
    maxH: pageHeight.full,
    minW: pageWidth.third,
    maxW: pageWidth.twoThirds,
    resizeHandles: [ 's', 'e' ],
  },

  users_priority: {
    minH: pageHeight.third,
    maxH: pageHeight.full * 4,
    minW: pageWidth.third,
    maxW: pageWidth.full,
    resizeHandles: [ 's', 'e' ],
  },

  paths_specific: {
    minH: pageHeight.third,
    maxH: pageHeight.full,
    minW: pageWidth.twoThirds,
    maxW: pageWidth.full,
    resizeHandles: [ 's', 'e' ],
  },
  paths_global: {
    minH: pageHeight.third,
    maxH: pageHeight.full,
    minW: pageWidth.twoThirds,
    maxW: pageWidth.full,
    resizeHandles: [ 's', 'e' ],
  },

  vulnerability_instances_global: {
    minH: pageHeight.half,
    maxH: pageHeight.full,
    minW: pageWidth.full,
    maxW: pageWidth.full,
    resizeHandles: [ 's' ],
  },
  vulnerability_instances_exploit_status_breakdown: {
    minH: pageHeight.third,
    maxH: pageHeight.twoThirds,
    minW: pageWidth.third,
    maxW: pageWidth.half,
    resizeHandles: [ 's', 'e' ],
  },
  vulnerability_instances_cvss_breakdown: {
    minH: pageHeight.third,
    maxH: pageHeight.twoThirds,
    minW: pageWidth.third,
    maxW: pageWidth.twoThirds,
    resizeHandles: [ 's', 'e' ],
  },
  vulnerability_instances_vulnerability_age_breakdown: {
    minH: pageHeight.third,
    maxH: pageHeight.twoThirds,
    minW: pageWidth.third,
    maxW: pageWidth.full,
    resizeHandles: [ 's', 'e' ],
  },
  vulnerability_instances_tag_breakdown: {
    minH: pageHeight.third,
    maxH: pageHeight.twoThirds,
    minW: pageWidth.third,
    maxW: pageWidth.twoThirds,
    resizeHandles: [ 's', 'e' ],
  },
  vulnerability_instances_os_family_breakdown: {
    minH: pageHeight.third,
    maxH: pageHeight.twoThirds,
    minW: pageWidth.third,
    maxW: pageWidth.twoThirds,
    resizeHandles: [ 's', 'e' ],
  },
  vulnerability_instances_total: {
    minH: pageHeight.third,
    maxH: pageHeight.full,
    minW: pageWidth.third,
    maxW: pageWidth.twoThirds,
    resizeHandles: [ 's', 'e' ],
  },
  vulnerability_instances_table: {
    minH: pageHeight.third,
    maxH: pageHeight.full * 4,
    minW: pageWidth.third,
    maxW: pageWidth.full,
    resizeHandles: [ 's', 'e' ],
  },
  vulnerability_instances_category: {
    minH: pageHeight.third,
    maxH: pageHeight.full,
    minW: pageWidth.third,
    maxW: pageWidth.twoThirds,
    resizeHandles: [ 's', 'e' ],
  },

  risk_score: {
    minH: pageHeight.third,
    maxH: pageHeight.full,
    minW: pageWidth.third,
    maxW: pageWidth.twoThirds,
    resizeHandles: [ 's', 'e' ],
  },
  risk_peer_percentile: {
    minH: pageHeight.third,
    maxH: pageHeight.full,
    minW: pageWidth.third,
    maxW: pageWidth.twoThirds,
    resizeHandles: [ 's', 'e' ],
  },
  risk_global: {
    minH: pageHeight.third,
    maxH: pageHeight.full,
    minW: pageWidth.third,
    maxW: pageWidth.twoThirds,
    resizeHandles: [ 's', 'e' ],
  },
  risk_over_time: {
    minH: pageHeight.half,
    maxH: pageHeight.full,
    minW: pageWidth.full,
    maxW: pageWidth.full,
    resizeHandles: [ 's' ],
  },
  risk_peer_percentile_over_time: {
    minH: pageHeight.half,
    maxH: pageHeight.full,
    minW: pageWidth.full,
    maxW: pageWidth.full,
    resizeHandles: [ 's' ],
  },
  risk_target: {
    minH: pageHeight.third,
    maxH: pageHeight.full,
    minW: pageWidth.third,
    maxW: pageWidth.twoThirds,
    resizeHandles: [ 's', 'e' ],
  },

  scanning_age_breakdown: {
    minH: pageHeight.third,
    maxH: pageHeight.twoThirds,
    minW: pageWidth.third,
    maxW: pageWidth.twoThirds,
    resizeHandles: [ 's', 'e' ],
  },
  scanning_scan_group_breakdown: {
    minH: pageHeight.third,
    maxH: pageHeight.twoThirds,
    minW: pageWidth.third,
    maxW: pageWidth.twoThirds,
    resizeHandles: [ 's', 'e' ],
  },
  scanning_agent_version_breakdown: {
    minH: pageHeight.third,
    maxH: pageHeight.twoThirds,
    minW: pageWidth.third,
    maxW: pageWidth.twoThirds,
    resizeHandles: [ 's', 'e' ],
  },
  scanning_total: {
    minH: pageHeight.third,
    maxH: pageHeight.full,
    minW: pageWidth.third,
    maxW: pageWidth.twoThirds,
    resizeHandles: [ 's', 'e' ],
  },

  remediation_plans: {
    minH: pageHeight.third,
    maxH: pageHeight.full * 2,
    minW: pageWidth.third,
    maxW: pageWidth.full,
    resizeHandles: [ 's', 'e' ],
  },

  remediation_specific_plan: {
    minH: pageHeight.third,
    maxH: pageHeight.full * 2,
    minW: pageWidth.third,
    maxW: pageWidth.full,
    resizeHandles: [ 's', 'e' ],
  },

  text_free_form: {
    minH: pageHeight.sixth,
    maxH: pageHeight.full,
    minW: pageWidth.third,
    maxW: pageWidth.full,
    resizeHandles: [ 's', 'e' ],
  },
};

// called to make sure all legacy widgets are updated and converted to the latest version (V2 for now)
export const getLatestWidgetVersion = widget => {
  if ( widget?.version === CURRENT_VERSION ) {
    return widget;
  }
  return legacyToV2Widget( widget );
};

// converts legacy widgets to v2 version analog
export const legacyToV2Widget = legacyWidget => {
  if (
    isNotEmpty( legacyWidget )
    && legacyWidget.version !== CURRENT_VERSION
    && isNotEmpty( legacyWidget.type )
    && legacyWidgetKeys.includes( legacyWidget.type )
  ) {

    let h, w, v2Version;

    switch ( legacyWidget.type ) {
    case 'risk_over_time':

      v2Version = availableWidgetsV2.risk.options.risk_over_time;
      ( { w } = legacyWidget );

      h = legacyWidget.h * 3;

      return ( {
        ...v2Version,
        settings: {
          ...v2Version.settings,
          version: 'table',
        },
        x: legacyWidget.x,
        y: legacyWidget.y,
        h,
        i: legacyWidget.i,
      } );
    case 'top_paths':
      v2Version = availableWidgetsV2.path.options.global;
      ( { w, h } = legacyWidget );

      if ( legacyWidget.h < v2Version.minH ) {
        h = v2Version.minH;
      } else if ( legacyWidget.h > v2Version.maxH ) {
        h = v2Version.maxH;
      }
      if ( legacyWidget.w < v2Version.minW ) {
        w = v2Version.minW;
      } else if ( legacyWidget.w > v2Version.maxW ) {
        w = v2Version.maxW;
      }
      return ( {
        ...v2Version,
        x: legacyWidget.x,
        y: legacyWidget.y,
        w,
        h,
        i: legacyWidget.i,
      } );
    case 'table':
      switch ( legacyWidget.settings.report_type ) {

      case 'hosts':
        v2Version = availableWidgetsV2.host.options.priority;
        ( { w, h } = legacyWidget );

        if ( legacyWidget.h < v2Version.minH ) {
          h = v2Version.minH;
        } else if ( legacyWidget.h > v2Version.maxH ) {
          h = v2Version.maxH;
        }
        if ( legacyWidget.w < v2Version.minW ) {
          w = v2Version.minW;
        } else if ( legacyWidget.w > v2Version.maxW ) {
          w = v2Version.maxW;
        }
        return ( {
          ...v2Version,
          settings: {
            ...v2Version.settings,
            version: 'table',
            report_type: 'hosts',
            include_rating: legacyWidget.settings.include_rating,
            include_user: legacyWidget.settings.include_user,
            include_os_type: legacyWidget.settings.include_os_type,
          },
          x: legacyWidget.x,
          y: legacyWidget.y,
          h,
          w,
          i: legacyWidget.i,
        } );
      case 'patches':
        v2Version = availableWidgetsV2.patch.options.priority;
        ( { w, h } = legacyWidget );

        if ( legacyWidget.h < v2Version.minH ) {
          h = v2Version.minH;
        } else if ( legacyWidget.h > v2Version.maxH ) {
          h = v2Version.maxH;
        }
        if ( legacyWidget.w < v2Version.minW ) {
          w = v2Version.minW;
        } else if ( legacyWidget.w > v2Version.maxW ) {
          w = v2Version.maxW;
        }
        return ( {
          ...v2Version,
          settings: {
            ...v2Version.settings,
            version: 'table',
            report_type: 'patches',
            include_rating: legacyWidget.settings.include_rating,
          },
          x: legacyWidget.x,
          y: legacyWidget.y,
          h,
          w,
          i: legacyWidget.i,
        } );
      case 'vulnerabilities':
        v2Version = availableWidgetsV2.vulnerability.options.priority;
        ( { w, h } = legacyWidget );

        if ( legacyWidget.h < v2Version.minH ) {
          h = v2Version.minH;
        } else if ( legacyWidget.h > v2Version.maxH ) {
          h = v2Version.maxH;
        }
        if ( legacyWidget.w < v2Version.minW ) {
          w = v2Version.minW;
        } else if ( legacyWidget.w > v2Version.maxW ) {
          w = v2Version.maxW;
        }
        return ( {
          ...v2Version,
          settings: {
            ...v2Version.settings,
            version: 'table',
            report_type: 'vulnerabilities',
            include_rating: legacyWidget.settings.include_rating,
          },
          x: legacyWidget.x,
          y: legacyWidget.y,
          h,
          w,
          i: legacyWidget.i,
        } );
      case 'users':
        v2Version = availableWidgetsV2.user.options.priority;
        ( { w, h } = legacyWidget );

        if ( legacyWidget.h < v2Version.minH ) {
          h = v2Version.minH;
        } else if ( legacyWidget.h > v2Version.maxH ) {
          h = v2Version.maxH;
        }
        if ( legacyWidget.w < v2Version.minW ) {
          w = v2Version.minW;
        } else if ( legacyWidget.w > v2Version.maxW ) {
          w = v2Version.maxW;
        }
        return ( {
          ...v2Version,
          settings: {
            ...v2Version.settings,
            version: 'table',
            report_type: 'users',
            include_rating: legacyWidget.settings.include_rating,
          },
          x: legacyWidget.x,
          y: legacyWidget.y,
          h,
          w,
          i: legacyWidget.i,
        } );
      }
      break;
    case 'priority':
      switch ( legacyWidget.settings.version ) {

      case 'list':
        switch ( legacyWidget.settings.report_type ) {

        case 'hosts':
          v2Version = availableWidgetsV2.host.options.priority;
          ( { w, h } = legacyWidget );

          if ( legacyWidget.h < v2Version.minH ) {
            h = v2Version.minH;
          } else if ( legacyWidget.h > v2Version.maxH ) {
            h = v2Version.maxH;
          }
          if ( legacyWidget.w < v2Version.minW ) {
            w = v2Version.minW;
          } else if ( legacyWidget.w > v2Version.maxW ) {
            w = v2Version.maxW;
          }
          return ( {
            ...v2Version,
            settings: {
              ...v2Version.settings,
              version: 'list',
              report_type: 'hosts',
              include_rating: legacyWidget.settings.include_rating,
              include_user: legacyWidget.settings.include_user,
              include_os_type: legacyWidget.settings.include_os_type,
            },
            x: legacyWidget.x,
            y: legacyWidget.y,
            h,
            w,
            i: legacyWidget.i,
          } );
        case 'patches':
          v2Version = availableWidgetsV2.patch.options.priority;
          ( { w, h } = legacyWidget );

          if ( legacyWidget.h < v2Version.minH ) {
            h = v2Version.minH;
          } else if ( legacyWidget.h > v2Version.maxH ) {
            h = v2Version.maxH;
          }
          if ( legacyWidget.w < v2Version.minW ) {
            w = v2Version.minW;
          } else if ( legacyWidget.w > v2Version.maxW ) {
            w = v2Version.maxW;
          }

          return ( {
            ...v2Version,
            settings: {
              ...v2Version.settings,
              version: 'list',
              report_type: 'patches',
              include_rating: legacyWidget.settings.include_rating,
            },
            x: legacyWidget.x,
            y: legacyWidget.y,
            h,
            w,
            i: legacyWidget.i,
          } );
        case 'vulnerabilities':
          v2Version = availableWidgetsV2.vulnerability.options.priority;
          ( { w, h } = legacyWidget );

          if ( legacyWidget.h < v2Version.minH ) {
            h = v2Version.minH;
          } else if ( legacyWidget.h > v2Version.maxH ) {
            h = v2Version.maxH;
          }
          if ( legacyWidget.w < v2Version.minW ) {
            w = v2Version.minW;
          } else if ( legacyWidget.w > v2Version.maxW ) {
            w = v2Version.maxW;
          }

          return ( {
            ...v2Version,
            settings: {
              ...v2Version.settings,
              version: 'list',
              report_type: 'vulnerabilities',
              include_rating: legacyWidget.settings.include_rating,
            },
            x: legacyWidget.x,
            y: legacyWidget.y,
            h,
            w,
            i: legacyWidget.i,
          } );
        case 'users':
          v2Version = availableWidgetsV2.user.options.priority;
          ( { w, h } = legacyWidget );

          if ( legacyWidget.h < v2Version.minH ) {
            h = v2Version.minH;
          } else if ( legacyWidget.h > v2Version.maxH ) {
            h = v2Version.maxH;
          }
          if ( legacyWidget.w < v2Version.minW ) {
            w = v2Version.minW;
          } else if ( legacyWidget.w > v2Version.maxW ) {
            w = v2Version.maxW;
          }

          return ( {
            ...v2Version,
            settings: {
              ...v2Version.settings,
              version: 'list',
              report_type: 'users',
              include_rating: legacyWidget.settings.include_rating,
            },
            x: legacyWidget.x,
            y: legacyWidget.y,
            h,
            w,
            i: legacyWidget.i,
          } );
        }
        break;

      case 'bar':
        switch ( legacyWidget.settings.report_type ) {

        case 'hosts':
          v2Version = availableWidgetsV2.host.options.priority;
          ( { w, h } = legacyWidget );

          if ( legacyWidget.h < v2Version.minH ) {
            h = v2Version.minH;
          } else if ( legacyWidget.h > v2Version.maxH ) {
            h = v2Version.maxH;
          }
          if ( legacyWidget.w < v2Version.minW ) {
            w = v2Version.minW;
          } else if ( legacyWidget.w > v2Version.maxW ) {
            w = v2Version.maxW;
          }

          return ( {
            ...v2Version,
            settings: {
              ...v2Version.settings,
              version: 'barchart',
              report_type: 'hosts',
              include_rating: legacyWidget.settings.include_rating,
              include_user: legacyWidget.settings.include_user,
              include_os_type: legacyWidget.settings.include_os_type,
            },
            x: legacyWidget.x,
            y: legacyWidget.y,
            h,
            w,
            i: legacyWidget.i,
          } );
        case 'patches':
          v2Version = availableWidgetsV2.patch.options.priority;
          ( { w, h } = legacyWidget );

          if ( legacyWidget.h < v2Version.minH ) {
            h = v2Version.minH;
          } else if ( legacyWidget.h > v2Version.maxH ) {
            h = v2Version.maxH;
          }
          if ( legacyWidget.w < v2Version.minW ) {
            w = v2Version.minW;
          } else if ( legacyWidget.w > v2Version.maxW ) {
            w = v2Version.maxW;
          }

          return ( {
            ...v2Version,
            settings: {
              ...v2Version.settings,
              version: 'barchart',
              report_type: 'patches',
              include_rating: legacyWidget.settings.include_rating,
            },
            x: legacyWidget.x,
            y: legacyWidget.y,
            h,
            w,
            i: legacyWidget.i,
          } );
        case 'vulnerabilities':
          v2Version = availableWidgetsV2.vulnerability.options.priority;
          ( { w, h } = legacyWidget );

          if ( legacyWidget.h < v2Version.minH ) {
            h = v2Version.minH;
          } else if ( legacyWidget.h > v2Version.maxH ) {
            h = v2Version.maxH;
          }
          if ( legacyWidget.w < v2Version.minW ) {
            w = v2Version.minW;
          } else if ( legacyWidget.w > v2Version.maxW ) {
            w = v2Version.maxW;
          }

          return ( {
            ...v2Version,
            settings: {
              ...v2Version.settings,
              version: 'barchart',
              report_type: 'vulnerabilities',
              include_rating: legacyWidget.settings.include_rating,
            },
            x: legacyWidget.x,
            y: legacyWidget.y,
            h,
            w,
            i: legacyWidget.i,
          } );
        case 'users':
          v2Version = availableWidgetsV2.user.options.priority;
          ( { w, h } = legacyWidget );

          if ( legacyWidget.h < v2Version.minH ) {
            h = v2Version.minH;
          } else if ( legacyWidget.h > v2Version.maxH ) {
            h = v2Version.maxH;
          }
          if ( legacyWidget.w < v2Version.minW ) {
            w = v2Version.minW;
          } else if ( legacyWidget.w > v2Version.maxW ) {
            w = v2Version.maxW;
          }

          return ( {
            ...v2Version,
            settings: {
              ...v2Version.settings,
              version: 'barchart',
              report_type: 'users',
              include_rating: legacyWidget.settings.include_rating,
            },
            x: legacyWidget.x,
            y: legacyWidget.y,
            h,
            w,
            i: legacyWidget.i,
          } );
        }
        break;
      }
      break;
    case 'metric':
      switch ( legacyWidget.settings.metric_type ) {
      case 'risk':
        v2Version = availableWidgetsV2.risk.options.score;
        ( { w, h } = legacyWidget );

        if ( legacyWidget.h < v2Version.minH ) {
          h = v2Version.minH;
        } else if ( legacyWidget.h > v2Version.maxH ) {
          h = v2Version.maxH;
        }
        if ( legacyWidget.w < v2Version.minW ) {
          w = v2Version.minW;
        } else if ( legacyWidget.w > v2Version.maxW ) {
          w = v2Version.maxW;
        }

        return ( {
          ...v2Version,
          x: legacyWidget.x,
          y: legacyWidget.y,
          h,
          w,
          i: legacyWidget.i,
        } );
      case 'target':
        v2Version = availableWidgetsV2.risk.options.target_risk;
        ( { w, h } = legacyWidget );

        if ( legacyWidget.h < v2Version.minH ) {
          h = v2Version.minH;
        } else if ( legacyWidget.h > v2Version.maxH ) {
          h = v2Version.maxH;
        }
        if ( legacyWidget.w < v2Version.minW ) {
          w = v2Version.minW;
        } else if ( legacyWidget.w > v2Version.maxW ) {
          w = v2Version.maxW;
        }

        return ( {
          ...v2Version,
          x: legacyWidget.x,
          y: legacyWidget.y,
          h,
          w,
          i: legacyWidget.i,
        } );
      case 'percentile':
        v2Version = availableWidgetsV2.risk.options.peer;
        ( { w, h } = legacyWidget );

        if ( legacyWidget.h < v2Version.minH ) {
          h = v2Version.minH;
        } else if ( legacyWidget.h > v2Version.maxH ) {
          h = v2Version.maxH;
        }
        if ( legacyWidget.w < v2Version.minW ) {
          w = v2Version.minW;
        } else if ( legacyWidget.w > v2Version.maxW ) {
          w = v2Version.maxW;
        }

        return ( {
          ...v2Version,
          x: legacyWidget.x,
          y: legacyWidget.y,
          h,
          w,
          i: legacyWidget.i,
        } );
      case 'hosts':
        v2Version = availableWidgetsV2.host.options.global;
        ( { w, h } = legacyWidget );

        if ( legacyWidget.h < v2Version.minH ) {
          h = v2Version.minH;
        } else if ( legacyWidget.h > v2Version.maxH ) {
          h = v2Version.maxH;
        }
        if ( legacyWidget.w < v2Version.minW ) {
          w = v2Version.minW;
        } else if ( legacyWidget.w > v2Version.maxW ) {
          w = v2Version.maxW;
        }

        return ( {
          ...v2Version,
          x: legacyWidget.x,
          y: legacyWidget.y,
          h,
          w,
          i: legacyWidget.i,
        } );
      default:
        v2Version = availableWidgetsV2.instances.options.specific;
        ( { w, h } = legacyWidget );

        if ( legacyWidget.h < v2Version.minH ) {
          h = v2Version.minH;
        } else if ( legacyWidget.h > v2Version.maxH ) {
          h = v2Version.maxH;
        }
        if ( legacyWidget.w < v2Version.minW ) {
          w = v2Version.minW;
        } else if ( legacyWidget.w > v2Version.maxW ) {
          w = v2Version.maxW;
        }

        return ( {
          ...v2Version,
          settings: {
            category: legacyWidget.settings.metric_type,
          },
          x: legacyWidget.x,
          y: legacyWidget.y,
          h,
          w,
          i: legacyWidget.i,
        } );
      }
    case 'vulnerability_instances':
      v2Version = availableWidgetsV2.instances.options.global;
      ( { w, h } = legacyWidget );

      if ( legacyWidget.h < v2Version.minH ) {
        h = v2Version.minH;
      } else if ( legacyWidget.h > v2Version.maxH ) {
        h = v2Version.maxH;
      }
      if ( legacyWidget.w < v2Version.minW ) {
        w = v2Version.minW;
      } else if ( legacyWidget.w > v2Version.maxW ) {
        w = v2Version.maxW;
      }

      return ( {
        ...v2Version,
        settings: {
          version: 'full',
        },
        x: legacyWidget.x,
        y: legacyWidget.y,
        w,
        h,
        i: legacyWidget.i,
      } );
    case 'peer_comparison':
      v2Version = availableWidgetsV2.risk.options.peer_over_time;
      ( { w, h } = legacyWidget );

      if ( legacyWidget.h < v2Version.minH ) {
        h = v2Version.minH;
      } else if ( legacyWidget.h > v2Version.maxH ) {
        h = v2Version.maxH;
      }
      if ( legacyWidget.w < v2Version.minW ) {
        w = v2Version.minW;
      } else if ( legacyWidget.w > v2Version.maxW ) {
        w = v2Version.maxW;
      }

      return ( {
        ...v2Version,
        x: legacyWidget.x,
        y: legacyWidget.y,
        w,
        h,
        i: legacyWidget.i,
      } );
    case 'exploit_status':
      v2Version = availableWidgetsV2.instances.options.exploit_status;
      ( { w, h } = legacyWidget );

      if ( legacyWidget.h < v2Version.minH ) {
        h = v2Version.minH;
      } else if ( legacyWidget.h > v2Version.maxH ) {
        h = v2Version.maxH;
      }
      if ( legacyWidget.w < v2Version.minW ) {
        w = v2Version.minW;
      } else if ( legacyWidget.w > v2Version.maxW ) {
        w = v2Version.maxW;
      }

      return ( {
        ...v2Version,
        x: legacyWidget.x,
        y: legacyWidget.y,
        w,
        h,
        i: legacyWidget.i,
      } );
    case 'cvss':
      v2Version = availableWidgetsV2.instances.options.cvss;
      ( { w, h } = legacyWidget );

      if ( legacyWidget.h < v2Version.minH ) {
        h = v2Version.minH;
      } else if ( legacyWidget.h > v2Version.maxH ) {
        h = v2Version.maxH;
      }
      if ( legacyWidget.w < v2Version.minW ) {
        w = v2Version.minW;
      } else if ( legacyWidget.w > v2Version.maxW ) {
        w = v2Version.maxW;
      }

      return ( {
        ...v2Version,
        x: legacyWidget.x,
        y: legacyWidget.y,
        w,
        h,
        i: legacyWidget.i,
      } );
    case 'remediation_plans':
      v2Version = availableWidgetsV2.remediation.options.plans;
      ( { w, h } = legacyWidget );

      if ( legacyWidget.h < v2Version.minH ) {
        h = v2Version.minH;
      } else if ( legacyWidget.h > v2Version.maxH ) {
        h = v2Version.maxH;
      }
      if ( legacyWidget.w < v2Version.minW ) {
        w = v2Version.minW;
      } else if ( legacyWidget.w > v2Version.maxW ) {
        w = v2Version.maxW;
      }

      return ( {
        ...v2Version,
        x: legacyWidget.x,
        y: legacyWidget.y,
        w,
        h,
        i: legacyWidget.i,
      } );
    }

    return ( {} );
  }
};

// the available widgets for each part of the application, for now, only supported for the
// reporting dashboard and alltypes are available there
export const availableWidgetOptions = {
  reporting_dashboard: Object.keys( availableWidgetsV2 ),
  custom: Object.keys( availableWidgetsV2 ),
};

export const handleWindowResize = ( type, ref, item, onPageLoad=false, previousWidth, callback ) => {
  const heightForH = {
    risk_over_time: {
      3: 430,
      4: 610,
    },
    risk_over_time_description: {
      3: 290,
      4: 510,
    },
    peer_comparison: {
      3: 480,
    },
    peer_comparison_description: {
      3: 340,
    },
    vulnerability_instances: {
      2: 100,
    },
    priority: {
      1: 160,
      2: 230,
      3: 400,
      4: 560,
      5: 730,
      6: 900,
    },
    cvss: {
      2: 440,
      3: 600,
    },
    remediation_plans: {
      2: 440,
      3: 600,
      4: 760,
    },
  };

  const defaultWidth = {
    risk_over_time: window.innerWidth - 170,
    vulnerability_instances: window.innerWidth - 100,
    peer_comparison: window.innerWidth - 240,
    priority: window.innerWidth - 170,
    cvss: window.innerWidth - 170,
    remediation_plans: window.innerWidth - 170,
  };

  const adjustmentThreshold = 100;

  if ( ( Math.abs( window.innerWidth - previousWidth ) > adjustmentThreshold ) || onPageLoad ) {

    let _type = type;

    if (
      ( type === 'risk_over_time' || type === 'peer_comparison' )
      && item.settings?.include_description
    ) {
      _type = `${_type}_description`;
    }

    // height has to be based on the grid item height, so that it can be relied upon.
    // const height = svgContainerRef?.current?.clientHeight || 1;
    const height = heightForH[_type][item.h];
    const width = ref?.current?.clientWidth || defaultWidth[type];
    const ratio = width / height;

    callback( width, height, ratio, window.innerWidth );
  }
};