/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './ScanningAgentVersionBreakdown.scss';
import { tagColors, isNotEmpty } from '../../../../../shared/Utilities';
import { v2Fetches } from '../../shared';
import Donut from '../../../../../shared/Charts/Donut';
import EmptyLoading from '../../../../../shared/EmptyLoading';
import Legend from '../../../../../shared/Charts/Legend';

const ScanningAgentVersionBreakdown = ( { prefetchedData, settings } ) => {
  const [ versionTotal, setVersionTotal ] = React.useState( 1 );
  const [ versionData, setVersionData ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( false );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );

  const setupData = data => {
    if ( isNotEmpty( data ) ) {
      const availableColors = [ ...tagColors.four ];
      availableColors.shift();

      const _versionData = {};
      const total = Object.values( data ).reduce( ( accum, count ) => ( accum + count ), 0 );

      Object.entries( data ).map( ( [ versionKey, count ], index ) => {
        _versionData[versionKey] = {
          label: versionKey,
          stroke: availableColors[index % 8],
          value: ( count / total ) * 100,
          originalCount: count,
          total: count,
          key: versionKey,
        };
      } );

      setVersionTotal( total );
      setVersionData( _versionData );
    }
  };

  const refetchData = async () => {
    setLoading( true );
    const _tallyResults = await v2Fetches.scanning_agent();
    setLoading( false );

    if ( isNotEmpty( _tallyResults ) ) {
      setupData( _tallyResults.version );
    }
  };

  React.useEffect( () => {
    if (
      isNotEmpty( prefetchedData )
      && isNotEmpty( prefetchedData.scanning_agent )
      && isNotEmpty( prefetchedData.scanning_agent.version )
    ) {
      setupData( prefetchedData.scanning_agent.version );
    } else {
      refetchData();
    }
  }, [ prefetchedData, settings ] );

  return (
    <React.Fragment>
      <EmptyLoading
        payload={ versionData }
        loading={ loading }
        loadingMessage="Loading Version Data"
        emptyMessage="No Version Data"
      />
      {
        ( isNotEmpty( versionTotal ) && isNotEmpty( versionData ) ) &&
        <React.Fragment>
          <Donut
            data={versionData}
            total={versionTotal}
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
          <Legend
            legendData={ versionData }
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ScanningAgentVersionBreakdown;