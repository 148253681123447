/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import EmptyState from '../../../../../shared/EmptyState';
import Accordion from '../../../../../shared/Accordion';
import Form from '../../../../../shared/Form';
import RemediationList from '../RemediationList';
import RemediationItem from '../RemediationItem';

import {
  isNotEmpty,
  pluralizeType,
  recordSorter,
} from '../../../../../shared/Utilities';
import InlineSVG from '../../../../../shared/InlineSVG';
import { getRecords } from '../../../../../shared/RecordCache';

const Step2 = ( {
  planState,
  setPlanState,
  setItemsStale,
  isActive=false,
} ) => {
  // inputs, values, validation for step 1
  const [ step2Fields, setStep2Fields ] = React.useState( [] );

  const [ recordType, setRecordType ] = React.useState( 'host' );

  // show/hide for accordion toggles on step 1
  const [ collapsed, setCollapsed ] = React.useState( { host: true, patch: true, vulnerability: true } );

  const [ remediationItemsLoading, setRemediationItemsLoading ] = React.useState( false );

  const [ remediationItems, setRemediationItems ] = React.useState( [] );

  React.useEffect( () => {

    const _step2Fields = [
      {
        type: 'select',
        attribute: 'type',
        label: 'Type',
        disabled: isActive,
        options: {
          host: 'Host',
          patch: 'Patch',
          vulnerability: 'Vulnerability',
          // path: 'Path',
        },
        defaultValue: 'host',
      },
      {
        type: 'debouncedText',
        attribute: 'keywords',
        label: 'Keywords',
        defaultValue: '',
        disabled: isActive,
      },
    ];
    if ( isActive ) {
      setCollapsed( {
        host: false,
        patch: false,
        vulnerability: false,
      } );
    }
    setStep2Fields( _step2Fields );
  }, [ isActive ] );

  // called whenever user selects a type or keyword search
  const refreshRemediationItems = async ( formState ) => {
    if ( isNotEmpty( formState ) && isNotEmpty( formState.fieldStates ) ) {
      setRemediationItemsLoading( true );
      const _type = formState.fieldStates['type'].updatedValue || 'host';
      const _keywords = formState.fieldStates['keywords'].updatedValue || '';
      setRemediationItems( [] );
      showOnlySection( _type );
      setRecordType( _type );

      let fetchType = 'HOST';

      const params = {
        rownums: [ 0, 100 ],
        model: 'base',
        project: 'default',
        filters: {},
      };

      if ( _type === 'host' ) {
        // eslint-disable-next-line camelcase
        params.group_type = 'host';
        params.filters = {
          // eslint-disable-next-line camelcase
          num_sensitive_nodes: 'any',
          'ii.has_host': true,
          // eslint-disable-next-line camelcase
          sort_by: 'risk',
          // eslint-disable-next-line camelcase
          sort_direction: 'DESC',
          // eslint-disable-next-line camelcase
          accepted_risk: 'false',
          // eslint-disable-next-line camelcase
          risk_type: 'risk',
          keywords: _keywords,
        };
        // eslint-disable-next-line camelcase
        params.order_by = [
          [ 'filtered_risk', 'DESC' ],
          [ 'local_name', 'ASC' ],
        ];
      }

      if ( _type === 'patch' ) {
        fetchType = 'PATCH';
        // eslint-disable-next-line camelcase
        params.group_type = 'patch_cumulative';
        params.filters = {
          superseded: 'unsuperseded',
          // eslint-disable-next-line camelcase
          risk_type: 'risk',
          // eslint-disable-next-line camelcase
          sort_by: 'risk',
          // eslint-disable-next-line camelcase
          sort_direction: 'DESC',
          // eslint-disable-next-line camelcase
          accepted_risk: 'false',
          keywords: _keywords,
        };
        // eslint-disable-next-line camelcase
        params.order_by = [
          [ 'risk', 'DESC' ],
          [ 'vendor', 'ASC' ],
          [ 'identifier', 'DESC' ],
        ];
      }

      if ( _type === 'vulnerability' ) {
        fetchType = 'VULNERABILITY';
        // eslint-disable-next-line camelcase
        params.group_type = 'vulnerability';
        params.filters = {
          patchable: 'any',
          // eslint-disable-next-line camelcase
          sort_by: 'risk',
          // eslint-disable-next-line camelcase
          sort_direction: 'DESC',
          // eslint-disable-next-line camelcase
          accepted_risk: 'false',
          // eslint-disable-next-line camelcase
          exploit_status: 'null',
          // eslint-disable-next-line camelcase
          risk_type: 'risk',
          keywords: _keywords,
        };
        // eslint-disable-next-line camelcase
        params.order_by = [
          [ 'filtered_risk', 'DESC' ],
          [ 'identifier', 'ASC' ],
        ];
      }

      const records = await getRecords( fetchType, params, true );
      setRemediationItems( records );
      setRemediationItemsLoading( false );
    }

  };

  const isCollapsed = type => {
    return collapsed[type];
  };

  const toggleCollapseSection = type => {
    setCollapsed( { ...collapsed, [type]: !collapsed[type] } );
  };

  const showOnlySection = type => {
    const _collapsed = {
      host: true,
      patch: true,
      vulnerability: true,
    };
    _collapsed[type] = false;
    setCollapsed( _collapsed );
  };

  const totalItemCount = () => {
    let count = 0;

    Object.values( planState?.items ).map( subset => {
      subset.map( () => {
        count += 1;
      } );
    } );

    return count;
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( step2Fields ) && !isActive ) &&
        <React.Fragment>
          <div className="panel searchResults remediationPanel">
            <h2>
              { `Available ${pluralizeType( recordType, true ) } (${remediationItems.length})` }
            </h2>
            <Form
              fields={step2Fields}
              trackUpdates={false}
              onChangeCallback={ refreshRemediationItems }
            />
            <RemediationList
              isActive={isActive}
              items={remediationItems}
              planState={planState}
              setPlanState={setPlanState}
              type={recordType}
              remediationItemsLoading={remediationItemsLoading}
              setItemsStale={setItemsStale}
            />
          </div>
        </React.Fragment>
      }
      <div className={`${isActive && 'isActive'} panel selectedItems remediationPanel`}>
        {
          isActive
            ? <h2>{ `Risk Items included (${totalItemCount()})` }</h2>
            : <h2>{ `Risk Items to include (${totalItemCount()})` }</h2>
        }
        {
          isNotEmpty( planState.items )
            ? <Accordion>
              {
                Object.entries( planState.items ).map( ( [ type, items ], index ) => {
                  return  <div
                    key={index}
                    className={`${isCollapsed( type ) ? 'collapsed' : ''} accordionWrapper alternate`}
                  >
                    <div
                      className="accordionHeader"
                      onClick={ () => toggleCollapseSection( type ) }
                    >
                      <h3>
                        <InlineSVG type={ `${pluralizeType( type )}Alt` }/>
                        { `${pluralizeType( type, true )} (${items.length})`}
                      </h3>
                      <button
                        onClick={ () => toggleCollapseSection( type ) }
                      >
                        <InlineSVG type="carretUp" />
                      </button>
                    </div>
                    <div className="accordionBody">
                      <ul>
                        {
                          isNotEmpty( items )
                            // eslint-disable-next-line max-len
                            ? items.sort( ( a, b ) => recordSorter( 'risk', false, a, b ) ).map( ( item, _index ) => {
                              return  <RemediationItem
                                key={_index}
                                item={item}
                                planState={planState}
                                setPlanState={setPlanState}
                                type={type}
                                isSelected
                                isActive={isActive}
                                setItemsStale={setItemsStale}
                              />;
                            } )
                            : <EmptyState message={`No ${pluralizeType( type )} selected`} />

                        }
                      </ul>
                    </div>
                  </div>;
                } )
              }
            </Accordion>
            : <EmptyState message="Selected risk items will appear here" />
        }
      </div>
    </React.Fragment>
  );
};

export default Step2;