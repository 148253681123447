/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import { makeRequest } from '../../legacy/io';

export const CurrentUserContext = React.createContext();

export const CurrentUserProvider = ( { children } ) => {

  const [ currentUser, setCurrentUser ] = React.useState( {} );
  const [ licenseInfo, setLicenseInfo ] = React.useState( {} );

  React.useEffect( () => {
    let isMounted = true;
    makeRequest( 'FETCH', '/profile', {} ).then( response => {
      if ( isMounted ) {
        setCurrentUser( response );
      }
    } );
    makeRequest( 'FETCH', '/license_info' ).then( response => {
      if ( isMounted ) {
        setLicenseInfo( response );
      }
    } );
    return () => {
      isMounted = false;
    };
  }, [] );

  const data = [
    currentUser,
    setCurrentUser,
    licenseInfo,
  ];

  return (
    <CurrentUserContext.Provider value={ data }>
      { children }
    </CurrentUserContext.Provider>
  );
};
