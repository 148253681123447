/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './ScanningScanGroupBreakdown.scss';
import { tagColors, isNotEmpty } from '../../../../../shared/Utilities';
import { v2Fetches } from '../../shared';
import Donut from '../../../../../shared/Charts/Donut';
import EmptyLoading from '../../../../../shared/EmptyLoading';
import Legend from '../../../../../shared/Charts/Legend';

const ScanningScanGroupBreakdown = ( { prefetchedData, settings } ) => {
  const [ scanGroupTotal, setScanGroupTotal ] = React.useState( 1 );
  const [ scanGroupData, setScanGroupData ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( false );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );

  const setupData = ( tallyData, scanGroups ) => {

    if ( isNotEmpty( tallyData && isNotEmpty( scanGroups ) ) ) {

      const availableColors = [ ...tagColors.four ];
      availableColors.shift();

      const _scanGroupData = {};
      const total = Object.values( tallyData ).reduce( ( accum, count ) => ( accum + count ), 0 );

      Object.entries( tallyData ).map( ( [ sgID, count ], index ) => {

        const sg = scanGroups.find( sg => sg.id === sgID );

        if ( isNotEmpty( sg ) ) {
          _scanGroupData[sgID] = {
            label: sg.label,
            stroke: availableColors[index % 8],
            value: ( count / total ) * 100,
            originalCount: count,
            total: count,
            key: sgID,
          };
        }
      } );

      setScanGroupTotal( total );
      setScanGroupData( _scanGroupData );
    }

  };

  const refetchData = async () => {
    setLoading( true );
    const _tallyResults = await v2Fetches.scanning_credentialed();
    const _sgResults = await v2Fetches.scan_group();

    setLoading( false );

    if (
      isNotEmpty( _tallyResults )
      && isNotEmpty( _sgResults )
    ) {
      setupData( _tallyResults.scan_group, _sgResults?.results );
    }
  };

  React.useEffect( () => {
    if (
      isNotEmpty( prefetchedData )
      && isNotEmpty( prefetchedData.scanning_credentialed )
      && isNotEmpty( prefetchedData.scanning_credentialed.scan_group )
      && isNotEmpty( prefetchedData.scan_group )
      && isNotEmpty( prefetchedData.scan_group.results )
    ) {
      setupData( prefetchedData.scanning_credentialed.scan_group, prefetchedData.scan_group.results );
    } else {
      refetchData();
    }
  }, [ prefetchedData, settings ] );

  return (
    <React.Fragment>
      <EmptyLoading
        payload={ scanGroupData }
        loading={ loading }
        loadingMessage="Loading Scan Group Data"
        emptyMessage="No Scan Group Data"
      />
      {
        ( isNotEmpty( scanGroupTotal ) && isNotEmpty( scanGroupData ) ) &&
        <React.Fragment>
          <Donut
            data={scanGroupData}
            total={scanGroupTotal}
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
          <Legend
            legendData={ scanGroupData }
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ScanningScanGroupBreakdown;