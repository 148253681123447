/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import InsightVisual from '../../RiskInsight/Visual';
import InsightTable from '../../RiskInsight/Table';
import InsightFilters from '../../RiskInsight/Filters';

import {
  isNotEmpty,
  decodeURLHash,
  encodeURLHash,
  removeFromURLHash,
  paramsToFilters,
  recordSorter,
} from '../../../shared/Utilities';

import './style.scss';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue.js';
import { getRecord, getRecords } from '../../../shared/RecordCache';
import PageHeader from '../../../shared/PageHeader';
import { getRowNums, pageIterator } from '../../../shared/Pagination/IndeterminantPagination';
import PathAnalysis from './PathAnalysis';

const Paths = ( ) => {

  const [ selectedRecord, setSelectedRecord ] = React.useState( null );
  const [ hoverRecord, setHoverRecord ] = React.useState( null );
  const [ visualLoading, setVisualLoading ] = React.useState( true );
  const [ tableLoading, setTableLoading ] = React.useState( true );

  const [ sortBy, setSortBy ] = React.useState( 'risk' );
  const [ sortDirection, setSortDirection ] = React.useState( 'DESC' );

  // pagination related state variables
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState( 1 );
  const [ currentPageResults, setCurrentPageResults ] = React.useState( [] );
  const [ nextPageResults, setNextPageResults ] = React.useState( [] );


  // controls the top overview panel collapsed state, storing here to pass down to multiple components
  const [ visualcollapsed, setVisualCollapsed ] = React.useState( false );
  // controls the filter drawer at the top level
  const [ filtersCollapsed, setFiltersCollapsed ] = React.useState( true );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const isMounted = true;

  // sorting by clicking the column headers in the table
  React.useEffect( () => {
    const currentHash = decodeURLHash();

    if ( sortBy !== currentHash.sort_by || sortDirection !== currentHash.sort_direction ) {
      // eslint-disable-next-line camelcase
      encodeURLHash( { sort_by: sortBy, sort_direction: sortDirection } );
      onRefresh();
    }
  }, [ sortBy, sortDirection ] );

  const fetchExistingRecord = async( recordID ) => {
    const tagIDs = decodeURLHash()['asset_tag_ids'];
    // eslint-disable-next-line camelcase
    const extra_columns = [
      'keywords',
      'risk',
      'modified',
      'edges',
      'node_labels',
    ];

    let _record = (
      await getRecord(
        'path',
        recordID,
        // eslint-disable-next-line camelcase
        isNotEmpty( tagIDs ) ? { asset_tag_ids: tagIDs, extra_columns } : { extra_columns },
      )
    );

    const withLabel = await getPathScopeLabels( [ _record ] );
    if ( isNotEmpty( withLabel ) ) {
      ( [ _record ] = withLabel );
      return _record;
    }
  };

  const getPathScopeLabels = async ( paths ) => {
    const _paths = [ ...paths ];
    const scopePathsMap = {};

    // get all the scope ids for creating the pathname
    _paths.map( path => {
      // eslint-disable-next-line
      const scopeID = path.node_labels[path.node_labels.length - 1][0];
      if ( scopePathsMap[scopeID] ) {
        scopePathsMap[scopeID].push( path.id );
      } else {
        scopePathsMap[scopeID] = [ path.id ];
      }
    } );
    // eslint-disable-next-line camelcase
    const scopes = await getRecords( 'scope', { id_list: Object.keys( scopePathsMap ) } );

    if ( isNotEmpty( scopes ) ) {
      scopes.map( scope => {
        scopePathsMap[scope.id].map( pathID => {
          const _path = _paths.find( p => p.id === pathID );
          if ( isNotEmpty( _path ) ) {
            // eslint-disable-next-line camelcase, max-len
            _path.full_path_label = `${ scope.ancestor_labels ? scope.ancestor_labels?.join( ' > ' ) : 'N/A' } > ${scope.label}`;
          }
        } );
      } );
    }
    return _paths;
  };

  const selectRecord = async ( recordID ) => {
    if ( isNotEmpty( recordID ) ) {

      const fetchedRecord = await fetchExistingRecord( recordID );

      if ( isNotEmpty( fetchedRecord ) ) {
        setSelectedRecord( fetchedRecord );
        encodeURLHash( { item: recordID } );
        setHoverRecord( null );
      } else {
        addFlashMessage( {
          type: 'alert',
          body: 'The record you are looking for is no longer part of the risk model',
        } );
        setSelectedRecord( {} );
        removeFromURLHash( 'item' );
        onRefresh();
        setHoverRecord( null );
      }
    } else {
      setSelectedRecord( {} );
      removeFromURLHash( 'item' );
      onRefresh();
      setHoverRecord( null );
    }
  };

  const onRefresh = async( adjustedFilters=[] ) => {

    const onCorrectPage = decodeURLHash()['page'] === 'explore_paths';

    if ( onCorrectPage ) {
      setVisualLoading( true );
      setTableLoading( true );

      // if the filter that was just changed is not the current page, set it back to page 1
      if ( isNotEmpty( adjustedFilters ) && !adjustedFilters.some( attr => attr === 'current_page ' ) ) {
        // eslint-disable-next-line camelcase
        encodeURLHash( { current_page: 1 } );
      }

      const filterValues = paramsToFilters();

      let params =  {
        rownums: [ 0, 100 ],
        // eslint-disable-next-line camelcase
        extra_columns: [
          'keywords',
          'risk',
          'modified',
          'edges',
          'node_labels',
        ],
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'risk', 'DESC' ],
        ],
      };

      const _rowNums = getRowNums( filterValues );

      if ( isNotEmpty( filterValues ) ) {
        Object.keys( filterValues ).map( ( key ) => {
          if ( key === 'item_count' ) {
            params.rownums = _rowNums;
          }
        } );
      }

      const _sortBy = filterValues.sort_by || [ 'risk' ];
      const _sortDirection = filterValues.sort_direction || [ 'DESC' ];

      if (
        isNotEmpty( _sortBy )
        && isNotEmpty( _sortDirection )
        && Array.isArray( _sortBy )
      ) {
        _sortBy.map( s => {
          params.order_by.push( [ s === 'risk' ? 'risk' : s, _sortDirection ] );
          params.order_by.push( [ 'label', 'ASC' ] );
        } );
      } else {
        // eslint-disable-next-line camelcase
        params.order_by = [ [ _sortBy === 'risk' ? 'risk' : _sortBy, _sortDirection ] ];
        params.order_by.push( [ 'label', 'ASC' ] );
        // eslint-disable-next-line camelcase, max-len
        params = { ...params, order_by: [ [ _sortBy === 'risk' ? 'risk' : _sortBy, _sortDirection ] ] };
      }

      let recordList = [];
      let pagedResults = [];

      // add a path to the array if we are cross linking and it would not be returned in the search
      // this needs to be handled a bit differently than the other reports because we need to
      // actually get all the paths in order for the detail pain to work
      if ( isNotEmpty( filterValues.item ) ) {
        if ( isMounted ) {
          selectRecord( filterValues.item );
        }
      } else {
        recordList = await getRecords( 'path', params );
        recordList = await getPathScopeLabels( recordList );
        if ( _sortDirection === 'ASC' ) {
          recordList = Object.values( recordList ).sort( ( a, b ) => recordSorter( 'risk', false, b, a ) );
        } else {
          recordList = Object.values( recordList ).sort( ( a, b ) => recordSorter( 'risk', false, a, b ) );
        }

        pagedResults = pageIterator( recordList, filterValues );
      }

      if ( isMounted ) {
        setCurrentPageNumber(
          pagedResults.currentPageNumber ? parseInt( pagedResults.currentPageNumber ) : 1,
        );
        setCurrentPageResults( pagedResults.firstPage );
        setNextPageResults( pagedResults.secondPage );
        setVisualLoading( false );
        setTableLoading( false );
      }
    }
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( selectedRecord )
          ? <PathAnalysis path={selectedRecord} selectItem={selectRecord }/>
          : <React.Fragment>
            <PageHeader>
              <InsightFilters
                onRefresh={onRefresh}
                collapsed={filtersCollapsed}
                visualCollapsed={visualcollapsed}
                setVisualCollapsed={setVisualCollapsed}
                setCollapsed={setFiltersCollapsed}
                reportType="path"
              />
              <InsightVisual
                reportType="path"
                loading={visualLoading}
                collapsed={visualcollapsed}
                setCollapsed={setVisualCollapsed}
                selectRecord={selectRecord}
                setHoverRecord={setHoverRecord}
                onRefresh={onRefresh}
                records={currentPageResults}
              />
            </PageHeader>

            <InsightTable
              records={currentPageResults}
              nextRecords={nextPageResults}
              currentPageNumber={currentPageNumber}
              loading={tableLoading}
              reportType="path"
              onRefresh={onRefresh}
              refreshTable={onRefresh}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
              visualCollapsed={visualcollapsed}
              selectedRecord={selectedRecord}
              selectRecord={selectRecord}
              hoverRecord={hoverRecord}
              setHoverRecord={setHoverRecord}
            />
          </React.Fragment>
      }
    </React.Fragment>
  );
};

export default Paths;