/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  isNotEmpty,
  reportTypeDisplayName,
  riskToRating,
  vulnerabilityScannerLogo,
} from '../../../../shared/Utilities';
import InlineSVG from '../../../../shared/InlineSVG';
import { RiskContext } from '../../../../Contexts/Risk';

import './SectionListItem.scss';
import RiskPercentageBar from '../../../../shared/RiskPercentageBar';
import {
  needsNoFormatting,
  needsVulnerabilityFormatting,
  needsPatchFormatting,
  needsHostFormatting,
  needsActionsFormatting,
  needsSignatureFormatting,
  patchNeedsRollup,
  needsUserFormatting,
} from '../shared';
import RiskReduction from '../../../../shared/RiskReduction';
import PatchRollup from '../Modals/PatchRollup';

const SectionListItem = ( {
  item,
  sectionKey,
  setSelectedSignature=() => {},
  setModalContext=() => {},
  highestRisk,
  riskType='risk',
  setHoveredListItemID=() => {},
  setHoveredListItemType=() => {},
  setHoveredListItemRating=() => {},
  // minimalVariant=false,
} ) => {

  const [ formattedItem, setFormattedItem ] = React.useState( null );
  const [ , globalRisk, , ,  ] = React.useContext( RiskContext );

  const handleSignatureClick = signature => {
    setSelectedSignature( signature );
    setModalContext( 'scan_results' );
  };

  const getItemRating = item => {
    let _type = 'risk';
    if ( item.filtered_risk ) {
      _type = 'filtered_risk';
    }
    if ( item.direct_risk ) {
      _type = 'direct_risk';
    }

    const _risk = item[_type];

    return riskToRating( _risk );
  };

  React.useEffect( ( ) => {
    if ( isNotEmpty( item ) && isNotEmpty( sectionKey ) ) {
      if ( needsNoFormatting.includes( sectionKey ) ) {
        setFormattedItem( <span className="unformatted">{ item }</span> );
      }

      if ( needsVulnerabilityFormatting.includes( sectionKey ) ) {

        let _type = 'risk';
        if ( item.filtered_risk ) {
          _type = 'filtered_risk';
        }
        if ( item.direct_risk ) {
          _type = 'direct_risk';
        }

        const _risk = item[_type];

        const innerContent = <React.Fragment>
          <div className="itemContent">
            <span className="name vulnerability">{ reportTypeDisplayName( item, 'vulnerability' ) }</span>
            {
              isNotEmpty( highestRisk ) &&
              <RiskPercentageBar
                itemRisk={ _risk }
                itemRating={ riskToRating( _risk ) }
                totalRisk={ highestRisk }
                minimalVariant
              />
            }
            <RiskReduction item={item} riskType={ _type } />
          </div>
        </React.Fragment>;

        setFormattedItem(
          <a
            // eslint-disable-next-line max-len
            href={ `#.=risk_insight&report=vulnerabilities&item_count=100&sort_by=risk&sort_direction=DESC&item=${item.id}&current_page=1` }
            target="_blank"
            rel="noreferrer noopener"
            // eslint-disable-next-line max-len
            className={ `fullHover withPercentageBar risk-${riskToRating( _risk ) }` }
          >
            { innerContent }
            <InlineSVG type="newTabLink" />
          </a>,
        );
      }

      if ( needsPatchFormatting.includes( sectionKey ) ) {

        let _type = 'risk';
        if ( item.filtered_risk ) {
          _type = 'filtered_risk';
        }
        if ( item.direct_risk ) {
          _type = 'direct_risk';
        }

        const _risk = item[_type];

        const linkContent = <a
          // eslint-disable-next-line max-len
          href={ `#.=risk_insight&report=patches&risk_type=${_type}&item_count=100&superseded=unsuperseded&sort_by=risk&sort_direction=DESC&item=${item.id}&current_page=1` }
          target="_blank"
          rel="noreferrer noopener"
          className={ `fullHover withPercentageBar risk-${riskToRating( _risk ) }` }
        >
          <div className="itemContent">
            <span className="name">{ reportTypeDisplayName( item, 'patch' ) }</span>
            <RiskPercentageBar
              itemRisk={ _risk }
              itemRating={ riskToRating( _risk ) }
              totalRisk={ highestRisk }
              minimalVariant
            />
            <RiskReduction item={item} riskType={ _type } />
          </div>
          <InlineSVG type="newTabLink" />
        </a>;

        setFormattedItem( <React.Fragment>
          {
            patchNeedsRollup.includes( sectionKey )
              ? <div className="patchItemWithRollupWrapper">
                <PatchRollup item={item} riskType={riskType} itemRisk={ _risk } itemRating={ riskToRating( _risk ) } />
                { linkContent }
              </div>
              : linkContent
          }
        </React.Fragment>,
        );
      }

      if ( needsHostFormatting.includes( sectionKey ) ) {
        let _type = 'risk';
        if ( item.filtered_risk ) {
          _type = 'filtered_risk';
        }
        if ( item.direct_risk ) {
          _type = 'direct_risk';
        }

        const _risk = item[_type];
        let _ratingClass = riskToRating( _risk );

        if ( !item.has_host ) {
          _ratingClass = 'unknown';
        }

        setFormattedItem(
          <a
            // eslint-disable-next-line max-len
            href={ `#.=risk_insight&report=hosts&item_count=100&sort_by=risk&sort_direction=DESC&item=${item.id}&current_page=1` }
            target="_blank"
            rel="noreferrer noopener"
            className={ `fullHover withPercentageBar risk-${_ratingClass}` }
          >
            <div className="itemContent">
              <span className="name">{ reportTypeDisplayName( item, 'host' ) }</span>
              {
                isNotEmpty( highestRisk ) &&
                <RiskPercentageBar
                  itemRisk={ _risk }
                  itemRating={_ratingClass}
                  totalRisk={ highestRisk }
                  minimalVariant
                />
              }
              <RiskReduction item={item} itemType="host" riskType={ _type } />
            </div>
            <InlineSVG type="newTabLink" />
          </a>,
        );
      }

      if ( needsUserFormatting.includes( sectionKey ) ) {
        let _type = 'risk';
        if ( item.filtered_risk ) {
          _type = 'filtered_risk';
        }
        if ( item.direct_risk ) {
          _type = 'direct_risk';
        }

        const _risk = item[_type];

        setFormattedItem(
          <a
            // eslint-disable-next-line max-len
            href={ `#.=risk_insight&report=users&item=${item.id}&sort_by=risk&sort_direction=DESC&item_count=100&current_page=1` }
            target="_blank"
            rel="noreferrer noopener"
            className={ `fullHover withPercentageBar risk-${riskToRating( _risk ) }` }
          >
            <div className="itemContent">
              <span className="name">{ reportTypeDisplayName( item, 'user' ) }</span>
              {
                isNotEmpty( highestRisk ) &&
                <RiskPercentageBar
                  itemRisk={ _risk }
                  itemRating={ riskToRating( _risk ) }
                  totalRisk={ highestRisk }
                  minimalVariant
                />
              }
              <RiskReduction item={item} riskType={ _type } />
            </div>
            <InlineSVG type="newTabLink" />
          </a>,
        );
      }

      if ( needsSignatureFormatting.includes( sectionKey ) ) {
        setFormattedItem(
          <button
            onClick={ () => handleSignatureClick( item ) }
            className={ `relatedSignatureButton ${riskToRating( item.record?.risk ) }`}
          >
            <span className="name">
              { vulnerabilityScannerLogo( item.record?.scanner ) }
              { item.label }
            </span>
          </button>,
        );
      }
      if ( needsActionsFormatting.includes( sectionKey ) ) {
        setFormattedItem(
          <a
            href={ item.href }
            target="_blank"
            rel="noreferrer noopener"
            className="additionalActionLink"
          >
            <span>{ item.identifier}: <strong>{ item.label }</strong></span>
            <InlineSVG type="link" />
          </a>,
        );
      }
    }
  }, [ item, sectionKey, globalRisk, highestRisk ] );

  // this will highlight something in the paths graph above on hover
  // host will highlight a scope box
  // vuln. will highlight a segment that contains that vuln.
  const handleMouseEnter = ( item, sectionKey ) => {
    if ( sectionKey === 'num_hosts' ) {
      setHoveredListItemID( item.id );
      setHoveredListItemType( 'scope' );
      setHoveredListItemRating( getItemRating( item ) );
    }
  };

  const handleMouseLeave = ( ) => {
    setHoveredListItemID( null );
    setHoveredListItemType( null );
    setHoveredListItemRating( null );
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( formattedItem ) &&
        <li
          className="sectionBodyListItem"
          onMouseEnter={ e => handleMouseEnter( item, sectionKey, e ) }
          onMouseLeave={ handleMouseLeave }
        >
          { formattedItem }
        </li>
      }
    </React.Fragment>
  );
};

export default SectionListItem;