/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import {
  isPresent,
  isFQDN,
  isValidPort,
  isIPAddressOrHostname,
} from '../../../Validators';

import {
  isEmpty,
  isNotEmpty,
} from '../../../../Utilities';
import InlineSVG from '../../../../InlineSVG';

const CollectionFieldsWrapper = ( {
  item,
  items,
  setItems,
  uuid,
  field,
  isNew=false,
  formState,
} ) => {

  const [ rootErrorMessages, setRootErrorMessages ] = React.useState( [] );
  const [ portErrorMessages, setPortErrorMessages ] = React.useState( [] );
  // eslint-disable-next-line no-unused-vars
  const [ rootIsFocused, setRootIsFocused ] = React.useState( false );
  const [ portIsFocused, setPortIsFocused ] = React.useState( false );

  // clear out the errors first
  React.useEffect( ( ) => {
    setRootErrorMessages( [] );
    setPortErrorMessages( [] );
  }, [ item ] );

  // removes item from group
  const removeItem = e => {
    e.stopPropagation();
    e.preventDefault();

    const tmpItems = Object.assign( {}, items );
    delete tmpItems[uuid];

    setItems( tmpItems );
  };

  // called whenever either the root or port changes
  // first validate, then add to object
  const handleItemChange = ( value, part, uuid ) => {
    let rootErrors, portErrors;

    if ( part === 'root' ) {
      rootErrors = [];
      portErrors = Array.from( portErrorMessages );
    } else {
      rootErrors = Array.from( rootErrorMessages );
      portErrors = [];
    }

    // if it needs to be a FQDN, domain, or ip
    if ( part === 'root' ) {
      if ( field.needsFQDN ) {
        if ( !isFQDN.check( value ) ) {
          if ( !rootErrors.includes( isFQDN.errorMessage ) ) {
            rootErrors.push( isFQDN.errorMessage );
          } else {
            rootErrors = rootErrors.filter( e => e !== isFQDN.errorMessage );
          }
        } else {
          rootErrors = rootErrors.filter( e => e !== isFQDN.errorMessage );
        }
      }
      if ( field.needsIPAddressOrHostname ) {
        if ( !isIPAddressOrHostname.check( value ) ) {
          if ( !rootErrors.includes( isIPAddressOrHostname.errorMessage ) ) {
            rootErrors.push( isIPAddressOrHostname.errorMessage );
          } else {
            rootErrors = rootErrors.filter( e => e !== isIPAddressOrHostname.errorMessage );
          }
        } else {
          rootErrors = rootErrors.filter( e => e !== isIPAddressOrHostname.errorMessage );
        }
      }
    }

    // if it needs a valid port
    if ( part === 'port' ) {
      portErrors.filter( e => e !== isValidPort.errorMessage );
      if ( !isValidPort.check( parseInt( value ) ) ) {
        portErrors.push( isValidPort.errorMessage );
      }
    }

    // if it is required
    if ( field.required ) {
      if ( !isPresent.check( value ) ) {
        if ( part === 'root' ) {
          rootErrors.push( 'Server/Domain cannot be blank' );
        } else {
          portErrors.push( 'Port cannot be blank' );
        }
      }
    }

    if ( field.needsPort && part === 'root' ) {
      if ( isNew ) {
        if ( isEmpty( item.port ) ) {
          portErrors = [ 'Port cannot be blank' ];
        }
      } else if ( items[uuid] && items[uuid].port ) {
        const errors = [];
        if ( !isPresent.check( items[uuid].port ) ) {
          errors.push( 'Port cannot be blank' );
        }
        if ( !isValidPort.check( items[uuid].port ) ) {
          errors.push( isValidPort.errorMessage );
        }
        portErrors = errors;
      } else {
        portErrors = [ 'Port cannot be blank' ];
      }
    }

    setRootErrorMessages( rootErrors );
    setPortErrorMessages( portErrors );

    const thisItemField = items[uuid];

    thisItemField[part] = value;

    if ( field.needsPort ) {
      thisItemField.isValid = isEmpty( rootErrors ) && isEmpty( portErrors );
    } else {
      thisItemField.isValid = isEmpty( rootErrors );
    }

    setItems(
      {
        ...items,
        [uuid]: thisItemField,
      },
    );
  };

  const hasErrors = () => isNotEmpty( rootErrorMessages ) || isNotEmpty( portErrorMessages );

  return (
    <React.Fragment>
      {/* eslint-disable-next-line max-len */}
      <div className={`specialWrapper ${field.needsPort ? 'needsPort' : ''} ${hasErrors() ? 'invalid' : ''}  ${rootIsFocused ? 'rootFocus' : ''} ${portIsFocused ? 'portFocus' : ''}`}>
        <div className="inputWithIconWrapper" >
          <input
            className={`${isNotEmpty( rootErrorMessages ) ? 'invalid' : ''}`}
            placeholder={ field.placeholder ? field.placeholder : ''}
            disabled={field.disabled || formState.fieldStates[field.attribute].disabled}
            type="text"
            onChange={ e => handleItemChange( e.target.value, 'root', uuid ) }
            value={ isNew ? item.root : item.root }
          />
          <InlineSVG type="edit" elementClass="editIcon" />
        </div>
        {
          field.needsPort &&
          <div className="inputWithIconWrapper" >
            <input
              onFocus={ () => setPortIsFocused( true ) }
              onBlur={ () => setPortIsFocused( false ) }
              className={`${isNotEmpty( portErrorMessages ) ? 'invalid' : ''}`}
              placeholder="Port"
              disabled={field.disabled}
              type="number"
              onChange={ e => handleItemChange( e.target.valueAsNumber || e.target.value, 'port', uuid ) }
              value={ isNew ? item.port : item.port }
            />
          </div>
        }
        <button
          onClick={ removeItem }
        >
          <InlineSVG type="remove" elementClass="removeIcon" />
        </button>
        {
          hasErrors()
            ? <ul className="fieldErrors">
              {
                rootErrorMessages.map( ( error, index ) => {
                  return  <li key={index}>
                    { error }
                  </li>;
                } )
              }
              {
                portErrorMessages.map( ( error, index ) => {
                  return  <li key={index}>
                    { error }
                  </li>;
                } )
              }
            </ul>
            : <React.Fragment></React.Fragment>

        }
      </div>
    </React.Fragment>
  );
};

export default CollectionFieldsWrapper;
