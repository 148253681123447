/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import EmptyState from '../../EmptyState';
import { isEmpty, isNotEmpty, paramsToFilters, uniqueArray } from '../../Utilities';

import './CheckboxGroup.scss';

const CheckboxGroup = ( {
  input,
  value,
  handleChange,
} ) => {

  // the hidden input
  const inputRef = React.useRef( null );

  const [ checkboxValues, setCheckboxValues ] = React.useState( [] );

  const applyInputValue = value => {
    if ( isNotEmpty( inputRef ) && isNotEmpty( inputRef.current ) ) {
      if ( value ) {
        inputRef.current.value = value;
      } else {
        inputRef.current.value = [];
      }
    }
  };

  // copies over the value to the state
  const applyCheckboxValue = ( value, action='add' ) => {
    let newValues = [ ...checkboxValues ];
    // we are adding this value
    if ( action === 'add' ) {
      newValues = [ ...newValues, value ];
    // removing value
    } else {
      newValues = newValues.filter( v => v !== value );
    }
    newValues = uniqueArray( newValues );

    setCheckboxValues( newValues );
    return newValues;
  };

  const handleHashChange = () => {
    const filterValues = paramsToFilters();
    const newValue = filterValues[input.attribute];
    if ( isNotEmpty( inputRef ) && isNotEmpty( inputRef.current ) ) {
      applyInputValue( newValue || [] );
      setCheckboxValues( newValue || [] );
    }
  };

  React.useEffect( () => {
    applyInputValue( value );
    window.addEventListener( 'hashchange', handleHashChange );
    return () => {
      window.removeEventListener( 'hashchange', handleHashChange );
    };
  }, [ value, inputRef ] );

  const handleCheckboxChange = ( e, value ) => {
    const action = e.target.checked ? 'add' : 'remove';
    const newValues = applyCheckboxValue( value, action );
    handleChange( input.attribute, newValues );
  };

  React.useEffect( () => {
    applyInputValue( value || [] );
    setCheckboxValues( value || [] );
  }, [ value ] );

  return (
    <React.Fragment>
      <span className="labelWrapper mainLabel">
        { input.label }
        { input.helpItem }
      </span>
      <input
        type="hidden"
        ref={ inputRef }
      />
      {
        isEmpty( input.options ) &&
        <EmptyState message="No vuln. scanners configured"/>
      }
      {
        ( isNotEmpty( input ) && isNotEmpty( input.options ) ) &&
        Object.entries( input.options ).map( ( [ value, label ], index ) => {
          return <label key={index} >
            <div
              // eslint-disable-next-line max-len
              className={ `checkboxFieldWrapper ${checkboxValues.includes( value ) ? 'checked' : ''} ${input.disabled ? 'disabled' : ''}` }
            >
              <input
                type="checkbox"
                onChange={ e => handleCheckboxChange( e, value ) }
                checked={ checkboxValues.includes( value ) }
              />
            </div>
            <span className="labelWrapper">
              { label }
            </span>
          </label>;
        } )
      }
    </React.Fragment>
  );
};

export default CheckboxGroup;