/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import ACSURL         from './Fields/Advanced/ACSURL';
import ApiCredentials from './Fields/Advanced/ApiCredentials';
import Duration       from './Fields/Advanced/Duration';
import Collection     from './Fields/Advanced/Collection/index';
import ColorSelect    from './Fields/Advanced/ColorSelect';
import Override       from './Fields/Advanced/Override';
import Recipients     from './Fields/Advanced/Recipients';
// import Schedule       from './Fields/Advanced/Schedule/index';
import Schedule       from './Fields/Advanced/ScheduleV2/index.js';
import SearchList     from './Fields/Advanced/SearchList.js/index.js';
import SearchResults  from './Fields/Advanced/SearchResults/index.js';
import SelectList     from './Fields/Advanced/SelectList/index';
import WeeklySchedule from './Fields/Advanced/WeeklySchedule';

import Checkbox       from './Fields/Basic/Checkbox';
import ContentBlock   from './Fields/Basic/ContentBlock';
import Date           from './Fields/Basic/Date';
import DebouncedText  from './Fields/Basic/DebouncedText';
import Email          from './Fields/Basic/Email';
import File           from './Fields/Basic/File';
import Hidden         from './Fields/Basic/Hidden';
import Number         from './Fields/Basic/Number';
import Password       from './Fields/Basic/Password';
import RadioGroup     from './Fields/Basic/RadioGroup';
import Select         from './Fields/Basic/Select';
import Text           from './Fields/Basic/Text';
import TextArea       from './Fields/Basic/TextArea';
import Time           from './Fields/Basic/Time';

import {
  isNotEmpty,
  isEmpty,
  isEqual,
} from '../Utilities';

import {
  needsOwnLabel,
  validateForm,
  checkFormConditionals,
  checkFieldConditional,
} from './Shared.js';

import './Field.scss';

const FieldContent = ( {
  field,
  fields,
  originalValue,
  onChange,
  formState,
  setIsFocused,
  existingRecord,
  fieldRef,
  recordType,
} ) => {

  const fieldContentMap = {
    acsURL: <ACSURL
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    apiCredentials: <ApiCredentials
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    checkbox: <Checkbox
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    collection: <Collection
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    colorSelect: <ColorSelect
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    contentBlock: <ContentBlock
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    date: <Date
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
    />,
    debouncedText: <DebouncedText
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    // deprecated, use 'collection' key instead, here for backwards compatibility
    domain: <Collection
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    duration: <Duration
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    email: <Email
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    file: <File
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    hidden: <Hidden
      field={field}
      existingRecord={existingRecord}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    number: <Number
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    override: <Override
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    password: <Password
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    radioGroup: <RadioGroup
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    recipients: <Recipients
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      allowMultiple={field.allowMultiple}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    schedule: <Schedule
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      allowMultiple={field.allowMultiple}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    select: <Select
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    selectList: <SelectList
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      allowMultiple={field.allowMultiple}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    searchList: <SearchList
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    searchResults: <SearchResults
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    text: <Text
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    textarea: <TextArea
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    time: <Time
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
    weeklySchedule: <WeeklySchedule
      existingRecord={existingRecord}
      setIsFocused={setIsFocused}
      field={field}
      originalValue={originalValue}
      onChange={onChange}
      formState={formState}
      fields={fields}
      fieldRef={fieldRef}
      recordType={recordType}
    />,
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( field ) &&
        fieldContentMap[field.type]
      }
    </React.Fragment>
  );
};

const Field = ( {
  field,
  fields,
  originalValue,
  formState,
  setFormState,
  editMode,
  existingRecord,
  recordType,
  trackUpdates,
  validateOnLoad,
  inline=false,
  asSentence=false,
} ) => {

  const [ isFocused, setIsFocused ] = React.useState( false );

  const fieldRef = React.useRef( null );

  // checker to see if individual field is valid
  const fieldIsValid = field => {
    if ( isEmpty( formState ) ) {
      return true;
    }
    if ( isEmpty( formState.errors ) ) {
      return true;
    }
    if ( isEmpty( formState.errors[field.attribute] ) ) {
      return true;
    }
    return false;
  };

  // checker to see if field has been touched at all
  const fieldIsAltered = field => {
    if ( isEmpty( formState ) ) {
      return false;
    }
    if ( isEmpty( formState.fieldStates ) ) {
      return false;
    }
    if ( isEmpty( formState.fieldStates[field.attribute] ) ) {
      return false;
    }
    if ( isEmpty( formState.fieldStates[field.attribute].altered ) ) {
      return false;
    }
    return true;

  };

  // checker to see if the field is the currently focused field
  const fieldIsFocused = field => isFocused
    || document.activeElement && document.activeElement.id === `${field.attribute}_${field.type}`;

  // checker to see if field has been updated
  const fieldIsUpdated = field => {
    if ( !trackUpdates ) {
      return false;
    }
    if ( isEmpty( formState ) ) {
      return false;
    }
    if ( isEmpty( formState.fieldStates ) ) {
      return false;
    }
    if ( isEmpty( formState.fieldStates[field.attribute] ) ) {
      return false;
    }
    if ( formState.fieldStates[field.attribute].updatedValue === undefined ) {
      return false;
    }
    // explore fields need this override when revisiting previously updated fields
    if (
      field.originalValueOverride
      && !isEqual(
        field.originalValueOverride,
        formState.fieldStates[field.attribute].updatedValue,
      )
    ) {
      return true;
    }
    if ( isEqual(
      formState.fieldStates[field.attribute].originalValue,
      formState.fieldStates[field.attribute].updatedValue,
    ) ) {
      return false;
    }
    return true;
  };

  // checker to see if it should be included or not
  const isIncluded = field => formState.fieldStates[field.attribute]?.included;

  // checker to see if it should be disabled or not
  const isDisabled = field => formState.fieldStates[field.attribute]?.disabled;

  // checker to see if it should be required or not
  const isRequired = field => formState.fieldStates[field.attribute]?.required || field.required;

  // checker to see if a field needs to display a warning... this is different than the valid or required indication
  // in that the form will still submit and all validation has passed, but there could be some strange behavior if the
  // issue is not addressed
  const hasWarning = field => formState.fieldStates[field.attribute]?.hasWarning;

  // main logic that is fired whenever a field value changes
  // updates the state for this field and the form as a whole
  const onChange = ( field, value, internallyValid=true ) => {
    const thisFieldAttr = field.attribute;

    // collects new state values
    let _fieldStates = { ...formState.fieldStates };

    // first set the new field that actually changed, and add it to the collection
    _fieldStates[thisFieldAttr] = {
      ...formState.fieldStates[field.attribute],
      updatedValue: value,
      altered: true,
    };

    // going to check the label for the change that may apply to itself, the conditionalCheck skips the actually changed
    // field for infinite loop reasons as well as weird states it can get itself into
    if ( isNotEmpty( field.conditionalLabel ) ) {
      const conditionalLabelKey = field.conditionalLabel.attribute;
      const allLabels = field.conditionalLabel.labels;

      if ( isNotEmpty( _fieldStates ) && isNotEmpty( _fieldStates[conditionalLabelKey] ) ) {
        const value = _fieldStates[conditionalLabelKey].updatedValue;
        const newLabel = allLabels[value] || field.defaultLabel || '';
        field.label = newLabel;
      }
    }

    // set all conditionals for the entire form
    // this needs to be done first, so that we know which fields are included,
    // and therefore need validating
    _fieldStates = checkFormConditionals(
      _fieldStates,
      fields,
      existingRecord,
      recordType,
      thisFieldAttr,
    );

    // one final check for a warning on this field itself, may need to refactor the checkFieldConditionals to allow
    // self checks, for now, being pragmatic
    _fieldStates[thisFieldAttr].hasWarning = checkFieldConditional( 'warn', field, _fieldStates );

    // validate the entire form
    const _formErrors = validateForm(
      _fieldStates,
      fields,
      editMode,
      recordType,
      { field, value, internallyValid },
    );

    // some fields have their own unique callback, the structure of the callback takes the following args in this order:
    //  field, value, fields
    if ( field.callback ) {
      field.callback( field, value, fields );
    }

    // set the formState
    const newFormState = {
      ...formState,
      fieldStates: { ..._fieldStates },
      errors: { ..._formErrors },
    };

    setFormState( newFormState );
  };

  return (
    <div
      // eslint-disable-next-line max-len
      className={ `${field.elementClass} ${asSentence ? 'asSentence' : ''} ${inline ? 'inline' : ''} ${trackUpdates ? '': 'dontTrack' } ${field.elementClass} fieldWrapper ${isDisabled( field ) ? 'disabled' : ''} ${isIncluded( field ) ? 'included' : ''} ${field.type} ${fieldIsFocused( field ) ? 'focused' : ''} ${( !fieldIsValid( field ) && fieldIsAltered( field ) ) ? 'invalid' : 'valid'} ${fieldIsAltered( field ) ? 'altered' : ''} ${fieldIsUpdated( field ) ? 'updated' : ''} ${hasWarning( field ) ? 'hasWarning' : ''} ${( !fieldIsValid( field ) && validateOnLoad ) ? 'invalid' : 'valid'} ` }
      id={ `${field.attribute}_${field.type}_fieldWrapper`}
    >
      {
        ( needsOwnLabel.includes( field.type ) )
          ? <React.Fragment>
            <FieldContent
              editMode={editMode}
              field={field}
              originalValue={originalValue}
              formState={formState}
              onChange={onChange}
              fields={fields}
              setIsFocused={setIsFocused}
              existingRecord={existingRecord}
              fieldRef={fieldRef}
              recordType={recordType}
            />
            {

              (
                // show if field is invalid and has been altered
                ( !fieldIsValid( field ) && fieldIsAltered( field ) )
                // show if field is invalid and is supposed to show errors on
                || ( !fieldIsValid( field ) && validateOnLoad )
              ) &&
              <ul className="fieldErrors">
                {
                  formState.errors[field.attribute].map( ( error, index ) => {
                    return  <li key={index}>{ error }</li>;
                  } )
                }
              </ul>
            }
          </React.Fragment>
          : <label>
            <span className="labelWrapper">
              { field.label }
              {
                isRequired( field ) &&
                <span className="required">*</span>
              }
              { field.help && field.help }
            </span>
            <FieldContent
              editMode={editMode}
              field={field}
              originalValue={originalValue}
              formState={formState}
              onChange={onChange}
              fields={fields}
              setIsFocused={setIsFocused}
              existingRecord={existingRecord}
              fieldRef={fieldRef}
              recordType={recordType}
            />
            {
              (
                // show if field is invalid and has been altered
                ( !fieldIsValid( field ) && fieldIsAltered( field ) )
                // show if field is invalid and is supposed to show errors on
                || ( !fieldIsValid( field ) && validateOnLoad )
              ) &&
              <ul className="fieldErrors">
                {
                  formState.errors[field.attribute].map( ( error, index ) => {
                    return  <li key={index}>{ error }</li>;
                  } )
                }
              </ul>
            }
            {
              // eslint-disable-next-line max-len
              hasWarning( field ) &&
              <ul className="fieldErrors warning">
                <li>{ field.warningMessage }</li>
              </ul>
            }
            {
              field.postLabel &&
              <span className="labelWrapper post">
                { field.postLabel }
              </span>
            }

          </label>
      }
    </div>

  );
};

export default Field;
