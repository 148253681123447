/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { globalColors, isEmpty, isNotEmpty } from '../Utilities';

import './Bar.scss';

const Bar = ( {
  data,
  onHover=null,
  onClick=null,
  containerHeight=30,
  containerWidth=100,
  multipleSeries=false,
  maxOverride=null,
  insightVersion=false,
  hoveredSeriesIdentifier,
  setHoveredSeriesIdentifier,
} ) => {

  const [ max, setMax ] = React.useState( 1 );
  const [ gap, setGap ] = React.useState( 0 );
  const [ barWidth, setBarWidth ] = React.useState( 0 );
  const [ seriesContainerWidth, setSeriesContainerWidth ] = React.useState( 0 );
  // const [ rx, setRx ] = React.useState( 0 );
  // const [ lineWidth, setLineWidth ] = React.useState( 0 );
  // const [ dashWidth, setDashWidth ] = React.useState( 0 );
  const [ minHeight, setMinHeight ] = React.useState( 0 );
  const [ seriesCount, setSeriesCount ] = React.useState( 1 );

  React.useEffect( ( ) => {
    if ( isNotEmpty( data ) && isNotEmpty( containerWidth ) && isNotEmpty( containerHeight ) ) {
      let _max = 1;

      let _barWidth;

      const _barCount = Object.values( data ).length;
      const _barPlusGap = containerWidth / _barCount;
      const _gap = _barCount >= 50 ? _barPlusGap * 0.15 : _barPlusGap * 0.1;

      // there will be multiple bars per item
      if ( isNotEmpty( multipleSeries ) ) {
        setSeriesCount( multipleSeries );
        if ( maxOverride ) {
          _max = maxOverride;
        } else {
          Object.values( data ).map( barSeries => {
            barSeries.series.map( barData => {
              if ( barData.value > _max ) {
                _max = barData.value;
              }
            } );
          } );
        }

        setSeriesContainerWidth( _barPlusGap - _gap );

        _barWidth = ( ( _barPlusGap - _gap ) / multipleSeries ) - ( _gap / multipleSeries );

      // the default is 1 bar per item
      } else {
        if ( maxOverride ) {
          _max = maxOverride;
        } else {
          Object.values( data ).map( ( barData ) => {
            if ( barData.value > _max ) {
              _max = barData.value;
            }
          } );
        }
        _barWidth = _barPlusGap - _gap;
      }

      let _rx = _barCount < 10 ? _barWidth * 0.05 : _barWidth * 0.2;

      if ( insightVersion && _barCount <= 25 ) {
        _rx = _barWidth * 0.05;
      } else if ( insightVersion ) {
        _rx = _barWidth * 0.1;
      }

      // const _lineWidth = containerHeight < 600 ? containerHeight / 175 : containerHeight / 350;
      // setLineWidth( _lineWidth );
      // setDashWidth( containerWidth / 75 );
      setBarWidth( _barWidth );
      setMax( _max );
      // setRx( _rx );
      setMinHeight( _rx * 1.5 );
      setGap( _gap );
    }
  }, [ data, containerWidth ] );

  const xOffset = 0;
  const yOffset = 0;

  const handleMouseEnter = series => {
    if ( isNotEmpty( setHoveredSeriesIdentifier ) ) {

      setHoveredSeriesIdentifier( series.key );
      if ( isNotEmpty( onHover ) ) {
        onHover( series );
      }
    }
  };

  const handleMouseLeave = () => {
    if ( isNotEmpty( setHoveredSeriesIdentifier ) ) {
      setHoveredSeriesIdentifier( null );
      if ( isNotEmpty( onHover ) ) {
        onHover( null );
      }
    }
  };

  return (
    <div className="svgBarWrapper">
      {
        ( isNotEmpty( data ) && isNotEmpty( max ) ) &&
        <svg
          viewBox={ `0 0 ${containerWidth} ${containerHeight}` }
          xmlns="http://www.w3.org/2000/svg"
          className="barChart"
          preserveAspectRatio="none"
          fill="none"
        >
          {/* eslint-enable max-len */}
          {/* bars and x-axis labels, bars are too long so that they get cut off and only have a top round */}
          {
            isNotEmpty( multipleSeries )
              ? <React.Fragment>
                {
                  Object.values( data ).map( ( barData, wrapperIndex ) => {
                    const _max = max <= 0 ? 1 : max;

                    return <g
                      key={wrapperIndex}
                    >

                      {
                        isNotEmpty( barData?.series ) && barData.series.map( ( bar, barIndex ) => {

                          let height = 0;
                          height = ( containerHeight - yOffset ) * ( bar.value / _max );
                          if ( height < minHeight ) {
                            height = minHeight;
                          }
                          const y = ( ( containerHeight - yOffset ) - height );

                          // basically take the xoffset of where it would be if it was just 1 bar, and then add
                          // a minibar + minigap and multiply it by its series index
                          // eslint-disable-next-line max-len
                          const x = xOffset + ( ( ( seriesContainerWidth + gap ) * wrapperIndex ) + ( barIndex * ( barWidth + ( gap / seriesCount ) ) ) );

                          return <g
                            key={barIndex}
                            // eslint-disable-next-line max-len
                            className={ `fullBarWrapper ${ isNotEmpty( onClick ) ? 'clickable' : '' }` }
                            onClick={ onClick ? e => onClick( e.target, e ) : () => {} }
                          >
                            {/* full bar vertical background */}
                            <rect
                              id={
                                barData?.original?.id
                                  ? `hoverIndicatorID_${barData?.original?.id}`
                                  : `randomIndicator_${Math.random()}`
                              }
                              // eslint-disable-next-line max-len
                              className={ `${ isNotEmpty( setHoveredSeriesIdentifier ) ? 'isHoverable' : '' } ${ hoveredSeriesIdentifier === barData.key ? 'isHovered' : '' } barContainer`}
                              y="0"
                              x={ x }
                              width={barWidth}
                              height={ containerHeight - yOffset }
                              fill={ globalColors['grey--background'] }
                              onMouseEnter={ () => handleMouseEnter( barData ) }
                              onMouseLeave={ handleMouseLeave }
                            />
                            {/* actual bar segment */}
                            <rect
                              key={barIndex}
                              id={
                                bar?.original?.id
                                  ? `barSegmentID_${bar?.original?.id}`
                                  : `randomSegment_${Math.random()}`
                              }
                              // eslint-disable-next-line max-len
                              className={ `${ isNotEmpty( setHoveredSeriesIdentifier ) ? 'isHoverable' : '' } ${ hoveredSeriesIdentifier === barData.key ? 'isHovered' : '' } barSegment`}
                              y={ y }
                              x={ x }
                              width={barWidth}
                              height={ height }
                              fill={ bar.fill }
                              onMouseEnter={ () => handleMouseEnter( barData ) }
                              onMouseLeave={ handleMouseLeave }
                              onClick={ onClick ? e => onClick( e.target, e ) : () => {} }
                            />
                          </g> ;

                        } )
                      }
                    </g>;
                  } )
                }
              </React.Fragment>
              : <React.Fragment>
                {
                  Object.values( data ).map( ( barData, index ) => {
                    const _max = max <= 0 ? 1 : max;

                    let height = 0;
                    height = ( containerHeight - yOffset ) * ( barData.value / _max );
                    if ( height < minHeight || isEmpty( height ) || isNaN( height ) ) {
                      height = minHeight;
                    }
                    const y = ( ( containerHeight - yOffset ) - height );

                    return <g
                      key={index}
                      // eslint-disable-next-line max-len
                      className={ `${ isNotEmpty( setHoveredSeriesIdentifier ) ? 'isHoverable' : '' } ${ hoveredSeriesIdentifier === barData.key ? 'isHovered' : '' } fullBarWrapper ${ isNotEmpty( onClick ) ? 'clickable' : '' }` }
                      onClick={ onClick ? e => onClick( e.target, e ) : () => {} }
                    >
                      {/* full bar vertical background */}
                      <rect
                        id={
                          barData?.original?.id
                            ? `hoverIndicatorID_${barData?.original?.id}`
                            : `randomIndicator_${Math.random()}`
                        }
                        // eslint-disable-next-line max-len
                        className={ `${ isNotEmpty( setHoveredSeriesIdentifier ) ? 'isHoverable' : '' } ${ hoveredSeriesIdentifier === barData.key ? 'isHovered' : '' } barContainer`}
                        y="0"
                        x={ xOffset + ( ( barWidth + gap ) * index ) }
                        width={barWidth}
                        rx="0"
                        height={ containerHeight - yOffset }
                        fill={ globalColors['grey--background'] }
                        onMouseEnter={ () => handleMouseEnter( barData ) }
                        onMouseLeave={ handleMouseLeave }

                      />
                      {/* actual bar segment */}
                      <rect
                        id={
                          barData?.original?.id
                            ? `barSegmentID_${barData?.original?.id}`
                            : `randomSegment_${Math.random()}`
                        }
                        // eslint-disable-next-line max-len
                        className={ `${ isNotEmpty( setHoveredSeriesIdentifier ) ? 'isHoverable' : '' } ${ hoveredSeriesIdentifier === barData.key ? 'isHovered' : '' } barSegment`}
                        y={ y }
                        x={ xOffset + ( ( barWidth + gap ) * index ) }
                        width={barWidth}
                        height={ height }
                        fill={ barData.fill }
                        onMouseEnter={ () => handleMouseEnter( barData ) }
                        onMouseLeave={ handleMouseLeave }
                      />
                    </g>;
                  } )
                }
              </React.Fragment>
          }
        </svg>
      }
    </div>
  );
};

export default Bar;