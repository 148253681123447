/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { globalColors, isNotEmpty } from '../../../../../shared/Utilities';
import { categoryLabelsAndDescription } from './VulnerabilityInstancesCategories';
import Donut from '../../../../../shared/Charts/Donut';

import './SpecificCategory.scss';

const SpecificCategory = ( { prefetchedData, settings } ) => {

  const [ chartData, setChartData ] = React.useState( null );
  const [ total, setTotal ] = React.useState( null );

  const refetchData = async () => {

  };

  const setupData = () => {
    if ( isNotEmpty( prefetchedData ) && isNotEmpty( settings ) && isNotEmpty( settings.category ) ) {

      if ( isNotEmpty( prefetchedData.tally ) ) {
        if (
          isNotEmpty( prefetchedData.tally.results )
          && isNotEmpty( prefetchedData.tally.results.category )
          && isNotEmpty( prefetchedData.tally.results._total )
        ) {
          const { _total } = prefetchedData.tally.results;
          setTotal( _total );

          let categoryInformation;

          if ( settings.category === 'prioritized' ) {
            categoryInformation = {
              label: 'Deprioritized',
              fill: globalColors['status--red'],
            };
          } else {
            categoryInformation = categoryLabelsAndDescription[settings.category];
          }

          const _chartData = {
            [settings.category]: {
              label: categoryInformation.label,
              order: 1,
              stroke: categoryInformation.fill,
              rawValue: prefetchedData.tally.results.category[settings.category],
              value: ( prefetchedData.tally.results.category[settings.category] / _total ) * 100,
            },
          };

          setChartData( _chartData );

        } else {
          refetchData();
        }
      } else {
        refetchData();
      }
    } else {
      refetchData();
    }
  };

  React.useEffect( () => {
    setupData();
  }, [ prefetchedData, settings ] );
  return (
    <React.Fragment>
      {
        ( isNotEmpty( chartData ) && isNotEmpty( total ) && isNotEmpty( settings ) ) &&
        <React.Fragment>
          <Donut
            strokeWidth="thin"
            total={total}
            data={ chartData }
            displayLabel
            // subLabel={ chartData[settings?.category].label }
          />
          <p className="categoryDescription">
            {/* eslint-disable-next-line max-len */}
            <strong>{chartData[settings?.category].label}</strong> instances make up <strong>{ Math.round( chartData[settings?.category].value ) }%</strong> of all vulnerability instances in your environment
          </p>
        </React.Fragment>

      }

    </React.Fragment>
  );
};

export default SpecificCategory;