/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import EmptyState from '../../../../shared/EmptyState';
import Loading from '../../../../shared/Loading';
import { isNotEmpty, pluralizeType } from '../../../../shared/Utilities';
import RemediationItem from './RemediationItem';

const RemediationList = ( {
  isActive,
  items,
  planState,
  setPlanState,
  type,
  remediationItemsLoading,
  setItemsStale,
} ) => {

  return (
    <React.Fragment>
      {
        isNotEmpty( items )
          ? <ul>
            {
              items.map( ( item, index ) => {
                return  <RemediationItem
                  isActive={isActive}
                  item={item}
                  key={index}
                  type={type}
                  planState={planState}
                  setPlanState={setPlanState}
                  setItemsStale={setItemsStale}
                />;
              } )
            }
          </ul>
          : <React.Fragment>
            {
              remediationItemsLoading
                ? <div className="loadingContainerWrapper"><Loading /></div>
                : <EmptyState message={`No ${ pluralizeType( type )} matched your filters`} />
            }
          </React.Fragment>
      }
    </React.Fragment>
  );
};

export default RemediationList;