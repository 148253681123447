/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import FieldGroup from './FieldGroup';

import { v4 as uuidv4 } from 'uuid';

import {
  isNotEmpty,
  isEmpty,
} from '../Utilities';

import {
  getOriginalValue,
  typeNeedsAdjusting,
  adjustValuesForRecordType,
  checkFormConditionals,
  validateForm,
} from './Shared';

import './style.scss';

// if you want to be able to get the formState externally (usually the case, but not always) then
// the parent component that is rendering the form needs to pass in an onChangeCallback, such as...
//
// <Form fields={fields} onChangeCallback={callback} />
//
// then the parent can get the formState by accessing the associated getter
const  Form = ( {
  fields,
  trackUpdates=true,
  onChangeCallback=() => {},
  existingRecord=null,
  recordType=null,
  setIsValid=() => {},
  formAction=null,
  formCancel=null,
  elementClass='',
  validateOnLoad=false,
} ) => {

  const [ fieldGroups, setFieldGroups ] = React.useState( [] );

  const [ allUpdates, setAllUpdates ] = React.useState( [] );
  const [ allErrors, setAllErrors ] = React.useState( [] );
  const [ flattenedFields, setFlattenedFields ] = React.useState( [] );

  // master record of the form
  // stores all the original values, any updated values, any validation errors
  // {
  //   fieldStates: {
  //     [fieldAttr]: {
  //       originalValue: '',
  //       updatedValue: '',
  //       altered: true/false,
  //       included: true/false,
  //       internallyValid: true/false,
  //     }
  //   }
  //   errors: [
  //     {
  //       [fieldAttr]: [ '', '', ],
  //     }
  //   ]
  // }
  const [ formState, setFormState ] = React.useState( {
    fieldStates: {},
    errors: {},
  } );

  // checker to see if form is valid
  const formIsValid = () => isEmpty( allErrors );

  // checker to see if the form has any updates
  const formIsUpdated = () => isEmpty( allUpdates );

  // separates fields into groups, if they are not grouped, makes a default 'all' group
  const mapFieldsToGroups = fields => {
    const _fieldGroups = [];
    // not grouped
    if ( Array.isArray( fields ) ) {
      _fieldGroups.push( {
        name: 'all',
        fields: fields,
      } );
    // fields are grouped
    } else if ( isNotEmpty( fields ) ) {
      Object.entries( fields ).map( ( [ groupKey, groupData ] ) => {
        _fieldGroups.push(
          {
            name: groupKey,
            fields: groupData.fields,
            help: groupData.help,
            header: groupData.header,
            inline: groupData.inline,
            asSentence: groupData.asSentence,
          },
        );
      } );
    }
    return _fieldGroups;
  };

  // anytime the fields change (onload, or if the selector changes the fieldset)
  // or an already existingRecord needs to fill out the field values.
  // MAIN initializer for setting up the formState.
  React.useEffect( () => {
    // there are inputs
    if ( isNotEmpty( fields ) || validateOnLoad ) {

      const _groups = mapFieldsToGroups( fields );

      let _fields = [];

      // fields are not grouped, just a flat array
      if ( Array.isArray( fields ) ) {
        // copy in the original fields
        _fields = Array.from( fields );
      // fields are in groups
      } else if ( isNotEmpty( fields ) ) {
        Object.values( fields ).map( group => {
          group.fields.map( f => {
            _fields.push( f );
          } );
        } );
      }

      setFlattenedFields( _fields );

      // empty collection for the values for each field
      let _fieldStates = { };
      let _adjustedStates = { };

      // need to populate the formState with the existing values, setting updated as well, so we
      // have something to compare against on init for the includeIf and disableIf fields
      if ( isNotEmpty( existingRecord ) ) {
        _fields.map( f => {
          const _value = getOriginalValue( f, existingRecord, recordType );
          _fieldStates[f.attribute] = { originalValue: _value, updatedValue: _value, internallyValid: true };
        } );
      // need to start with a blank form, this is for a new record
      } else {
        _fields.map( f => {

          // authenticationProvider type has some special logic needed for initializing the default
          // value of the id
          if ( recordType === 'authentication_provider' && f.attribute === 'saml2_id' ) {
            const uuid = uuidv4();
            _fieldStates[f.attribute] = { originalValue: uuid, updatedValue: uuid, internallyValid: true };
          } else {
            _fieldStates[f.attribute] = {
              originalValue: f.defaultValue,
              updatedValue: f.defaultValue,
              internallyValid: true,
            };
          }
        } );
      }

      // set all conditionals for the entire form
      // this needs to be done first, so that we know which fields are included,
      // and therefore need validating
      _fieldStates = checkFormConditionals( _fieldStates, _fields, existingRecord, recordType );

      // validate the entire form
      const _formErrors = validateForm(
        _fieldStates,
        _fields,
        isNotEmpty( existingRecord ),
        recordType,
      );

      // if any special logic is needed for different reportTypes
      if ( typeNeedsAdjusting.includes( recordType ) ) {
        _adjustedStates = adjustValuesForRecordType( recordType, existingRecord, _fieldStates );
      } else {
        _adjustedStates = _fieldStates;
      }

      // set the formState with the either the values from the record, or a blank state
      setFormState( {
        fieldStates: _adjustedStates,
        errors: { ..._formErrors },
      } );

      setFlattenedFields( _fields );
      setFieldGroups( _groups );
    // clear the formState
    } else {
      setFormState( {
        fieldStates: {},
        errors: {},
      } );
      setFieldGroups( [] );
    }
  }, [ fields, existingRecord ] );

  // whenever the formState changes, this is called
  // it will get changed when fields are added (init)
  // when values change (user interacts with the form)
  // and subsequently upon validation, for now, just flattening
  // all the errors and setting the valid state
  React.useEffect( () => {
    // get all the errors, and flatten them to include the field label
    if (
      isNotEmpty( formState )
      && isNotEmpty( flattenedFields ) && isNotEmpty( formState.errors )
    ) {
      const _allErrors = [];

      Object.entries( formState.errors ).map( ( [ fieldAttr, errors ] ) => {
        const field = flattenedFields.find( f => f.attribute === fieldAttr );
        let fieldLabel = '';
        if ( isNotEmpty( field ) ) {
          fieldLabel = field.label;
        }

        errors.map( e => {
          _allErrors.push( `${ isNotEmpty( fieldLabel ) && `${fieldLabel}: `} ${e}` );
        } );
      } );
      setAllErrors( _allErrors );
    } else {
      setAllErrors( [] );
    }

    // get all the updates and flatten them for easy retrieval
    if ( isNotEmpty( formState ) && isNotEmpty( formState.fieldStates ) ) {
      const _allUpdates = [];

      Object.entries( formState.fieldStates ).map( ( [ fieldAttr, data ] ) => {
        if ( isNotEmpty( data.updated ) ) {
          _allUpdates.push( { [fieldAttr]: data.updated } );
        }
      } );
      setAllUpdates( _allUpdates );
    } else {
      setAllUpdates( [] );
    }
    onChangeCallback( formState );

  }, [ flattenedFields, formState ] );

  // whenever the allErrors gets updated, set the external validation if there is one
  React.useEffect( () => {
    setIsValid( isEmpty( allErrors ) );
  }, [ allErrors ] );

  return (
    <div className={ `${elementClass} formWrapper` }>
      {
        (
          isNotEmpty( fieldGroups ) && isNotEmpty( formState.fieldStates )
          && isNotEmpty( flattenedFields )
        ) &&
        fieldGroups.map( ( group, index ) => {
          return  <FieldGroup
            fields={flattenedFields}
            group={group}
            key={index}
            formState={formState}
            setFormState={setFormState}
            editMode={ isNotEmpty( existingRecord ) }
            trackUpdates={trackUpdates}
            existingRecord={existingRecord}
            recordType={recordType}
            validateOnLoad={validateOnLoad}
          />;
        } )
      }
      {
        ( isNotEmpty( formAction ) || isNotEmpty( formCancel ) ) &&
        <div
          className={
            `formActions ${formIsValid() ? 'valid' : 'invalid'} ${formIsUpdated() ? 'updated' : ''}`
          }
        >
          {
            isNotEmpty( formCancel ) &&
            <button
              className="formCancelButton"
              onClick={ formCancel }
            >
              Cancel
            </button>
          }
          {
            isNotEmpty( formAction ) &&
            <button
              disabled={ !formIsValid() }
              className={`formActionButton ${formIsValid() ? '' : 'disabled'}`}
              onClick={ () => formAction( formState ) }
            >
              Save
            </button>
          }
        </div>
      }
    </div>
  );
};

export default Form;
