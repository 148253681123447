/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import Modal from '../Modal';

import { v4 as uuidv4 } from 'uuid';

import {
  isNotEmpty,
  useLocalStorage,
  addToLocalStorage,
  removeFromLocalStorage,
  isEmpty,
} from '../Utilities';
import InlineSVG from '../InlineSVG';
import Dropdown from '../Dropdown';
import { getFieldValues } from '../Form/Shared';
import Form from '../Form';

import './style.scss';
import EmptyState from '../EmptyState';

const ModalBody = ( {
  setIsValid,
  fields,
  setUpdatedForm,
  existingRecord,
} ) => {
  return (
    <React.Fragment>
      {
        ( isNotEmpty( fields ) ) &&
        <Form
          fields={fields}
          existingRecord={ existingRecord }
          setIsValid={setIsValid}
          onChangeCallback={setUpdatedForm}
          recordType="saved_filter"
        />
      }
    </React.Fragment>
  );
};

const ModalAction = ( {
  isValid,
  onSave,
} ) => {
  return (
    <React.Fragment>
      <button
        disabled={ !isValid }
        className={'submitButton'}
        onClick={ onSave }
      >
        Save
      </button>
    </React.Fragment>
  );
};

const SavedFilters = ( {
  reportType,
  minimalButton=false,
  showSavedFilters=false,
  callback=() => {},
} ) => {

  const [ savedFilters, setSavedFilters ] = useLocalStorage( `${reportType}SavedFilters`, '' );
  const [ showModal, setShowModal ] = React.useState( false );
  const [ isValid, setIsValid ] = React.useState( false );
  const [ updatedForm, setUpdatedForm ] = React.useState( null );

  const [ existingRecord, setExistingRecord ] = React.useState( { label: '' } );

  const [ formattedSavedFilters, setFormattedSavedFilters ] = React.useState( [] );

  const [ fields, setFields ] = React.useState( null );

  const DEFAULT_FIELDS = [
    { type: 'text', label: 'Filter name', required: true, attribute: 'label', defaultValue: '' },
  ];

  React.useEffect( () => {
    if ( isNotEmpty( reportType ) ) {
      reset();
    }
  }, [ reportType ] );

  const reset = () => {
    setExistingRecord( { label: '' } );
    setShowModal( false );
    setFields( DEFAULT_FIELDS );
  };

  const removeItem = ( e, id ) => {
    e.preventDefault();
    e.stopPropagation();
    removeFromLocalStorage( id, `${reportType}SavedFilters`, setSavedFilters );
  };

  const getLatestFilters = () => {
    const _formattedSavedFilters = [];

    if ( isNotEmpty( window.localStorage.getItem( `${reportType}SavedFilters` ) ) ) {

      const _parsedFilters = JSON.parse(
        window.localStorage.getItem( `${reportType}SavedFilters` ),
      );
      if ( isNotEmpty( _parsedFilters ) ) {
        Object.values( _parsedFilters ).map( ( filter, index ) => {
          _formattedSavedFilters.push( <div
            className="savedFilter"
            onClick={ () => handleSavedFilterSelect( filter.href ) }
          >
            <span key={index} >
              { filter.label }
              <button
                className="removeFilterButton"
                onClick={ e => removeItem( e, filter.id ) }
              >
                <InlineSVG type="delete" />
              </button>
            </span>
          </div> );
        } );
      }
    }
    if ( isEmpty( _formattedSavedFilters ) ) {
      _formattedSavedFilters.push(
        <EmptyState message="No Saved Filters" />,
      );
    }
    _formattedSavedFilters.push(
      <button
        className="menuCreateNewButton"
        onClick={ () => setShowModal( true ) }
      >
        Save Current Filters
      </button>,
    );
    setFormattedSavedFilters( _formattedSavedFilters );
  };

  React.useEffect( () => {
    getLatestFilters();
  }, [ savedFilters ] );

  const onSave = () => {
    if ( isValid && isNotEmpty( updatedForm ) ) {
      const { label } = getFieldValues( updatedForm?.fieldStates, 'saved_filters' );
      const item = {
        id: uuidv4(),
        href: window.location.href,
        label,
      };
      addToLocalStorage( item, `${reportType}SavedFilters`, setSavedFilters );
      reset();
      getLatestFilters();
    }
  };

  const handleSavedFilterSelect = href => {
    window.location.href = href;
    callback();
  };

  return (
    <React.Fragment>
      <Modal
        visible={showModal}
        setVisible={setShowModal}
        header={'Save Current Filters'}
        size="small"
        elementClass="saveFiltersModal"
        body={<ModalBody
          setIsValid={setIsValid}
          fields={fields}
          setUpdatedForm={setUpdatedForm}
          existingRecord={existingRecord}
        />
        }
        action={<ModalAction
          isValid={isValid}
          onSave={onSave}
        />}
      />
      {
        minimalButton &&
        <button
          className={ ` ${minimalButton ? 'asMinimalButton' : ''} saveFiltersButton`}
          onClick={ () => setShowModal( true ) }
        >
          <InlineSVG type="bookmark" />
        </button>
      }
      {
        ( showSavedFilters ) &&
        <React.Fragment>
          <Dropdown
            trigger={
              <React.Fragment>
                <span>
                  Saved Filters
                </span>
                <InlineSVG type="carretDown" version="primary" />
              </React.Fragment>
            }
            menuItems={ formattedSavedFilters }
            elementClass="savedFiltersPicker"
          />
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default SavedFilters;
