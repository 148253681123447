/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { TasksProvider } from '../../../Contexts/Tasks';

import TaskList from './TaskList';
import './style.scss';

const Tasks = ( ) => {
  return (
    <div className="setupPageContainer tasksPage">
      <TasksProvider>
        <TaskList />
      </TasksProvider>
    </div>
  );
};

export default Tasks;