/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';

import {
  isNotEmpty,
  formatUnixDate,
  paramsToFilters,
} from '../../Utilities';

const Date = ( {
  input,
  value,
  handleChange,
} ) => {
  const [ isFocused, setIsFocused ] = React.useState( false );

  const inputRef = React.useRef( null );

  const applyDateValue = _value => {
    if ( input.needsUnixTransform ) {
      if ( isNotEmpty( _value ) ) {
        const newValue = formatUnixDate( _value );
        inputRef.current.value = newValue;
      } else {
        inputRef.current.value = '';
      }
    } else {
      inputRef.current.value = _value;
    }
  };

  const handleHashChange = () => {
    const filterValues = paramsToFilters();
    const newValue = filterValues[input.attribute];

    if ( isNotEmpty( inputRef ) && isNotEmpty( inputRef.current ) ) {
      applyDateValue( newValue || '' );
    }
  };

  React.useEffect( () => {
    if (
      isNotEmpty( input )
      && isNotEmpty( value )
      && isNotEmpty( inputRef )
      && isNotEmpty( inputRef.current )
    ) {
      handleHashChange();
      applyDateValue( value );
      window.addEventListener( 'hashchange', handleHashChange );
    }
    return () => {
      window.removeEventListener( 'hashchange', handleHashChange );
    };
  }, [ input, value, inputRef ] );


  const onChange = e => {
    let newValue = e.target.value;

    if ( input.needsUnixTransform ) {
      if ( Number.isNaN( e.target.valueAsNumber ) ) {
        newValue = '';
      } else {
        newValue = e.target.valueAsNumber / 1000.0;
      }
    }
    applyDateValue( newValue );
    handleChange( input.attribute, newValue );
  };

  return (
    <React.Fragment>
      <label className={`${isFocused ? 'focus' : ''}`}>
        <span className="labelWrapper">
          { input.label }
          {
            input.required &&
            <span className="required">*</span>
          }
          { input.helpItem }
        </span>
        <input
          ref={inputRef}
          onFocus={ () => setIsFocused( true ) }
          onBlur={ () => setIsFocused( false ) }
          placeholder={ input.placeholder ? input.placeholder : ''}
          disabled={input.disabled }
          type={input.type}
          onChange={ onChange }
          { ...input.htmlProps }
        />
      </label>
    </React.Fragment>
  );
};

export default Date;
