/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../shared/Utilities';
import { GLOBAL_SCOPE } from '../../Graph/shared';

const ScopeOptions = ( {
  scope,
  editItem,
  copyItem,
  selectItem,
  optionCallback=() => {},
} ) => {
  return (
    <React.Fragment>
      {
        isNotEmpty( scope ) &&
        <React.Fragment>
          {
            scope.id !== GLOBAL_SCOPE.id &&
            <React.Fragment>
              <li
                onClick={ () => selectItem( 'scope', scope, optionCallback ) }
              >
                Add scope fragment to graph
              </li>
              <li
                onClick={ () => editItem( 'scope', scope, optionCallback ) }
              >
                Edit scope
              </li>
              <li
                onClick={ () => copyItem( 'scope', scope, optionCallback ) }
              >
                Duplicate scope
              </li>
            </React.Fragment>
          }
          <li
            onClick={ () => editItem( 'scope', { parent: scope.id }, optionCallback ) }
          >
            Add scope
          </li>
          <li
            onClick={ () => editItem( 'node', { ...scope, isScope: true }, optionCallback ) }
          >
            Add node
          </li>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ScopeOptions;