/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { makeRequest } from '../../../legacy/io';
import { ModelContext } from '../../Contexts/Model';
import { RiskContext } from '../../Contexts/Risk';
import InlineSVG from '../../shared/InlineSVG';
import Modal from '../../shared/Modal';
import {
  formatNumber,
  getCurrentPercentile,
  getGlobalSettings,
  getPercentileClass,
  getRiskClass,
  getYForX,
  isEmpty,
  isNotEmpty,
  updateGlobalSettings,
} from '../../shared/Utilities';

import './RiskScoreMenu.scss';
// import Metric from '../Reporting/Dashboards/Widgets/Content/Metric';
import { numberToOrdinal } from '../../shared/Utilities';
import RiskAndPercentile from '../../shared/RiskAndPercentile';

const ModalAction = ( { newTargetRisk, newDisplaySetting } ) => {

  const [ , RefreshModelData ] = React.useContext( ModelContext );

  const onSave = async () => {
    // eslint-disable-next-line camelcase
    await updateGlobalSettings( 'project', { settings: { risk_target: newTargetRisk } } );
    // eslint-disable-next-line camelcase
    await updateGlobalSettings( 'global', { risk_score_display_mode: newDisplaySetting } );

    RefreshModelData();
    window.location.reload( true );
  };

  return (
    <button
      onClick={ onSave }
    >
      Save Changes
    </button>
  );
};

const ModalBody = ( {
  newTargetRisk,
  setNewTargetRisk,
  riskEditMode,
  setRiskEditMode,
  newDisplaySetting,
  setNewDisplaySetting,
} ) => {

  const [ , , targetRisk, , , , ] = React.useContext( RiskContext );

  const [ reductionValue, setReductionValue ] = React.useState( 1 );
  const [ peerIndex, setPeerIndex ] = React.useState( '' );
  const [ peerValues, setPeerValues ] = React.useState( null );

  const handleReductionChange = e => {
    if ( riskEditMode === 'percent' ) {
      const { value } = e.target;
      setReductionValue( value );
      setNewTargetRisk( targetRisk * value );
    } else if ( isNotEmpty( peerValues ) ) {
      const { value } = e.target;
      setPeerIndex( value );
      setNewTargetRisk( peerValues[value] );
    }
  };

  React.useEffect( ( ) => {
    if ( isNotEmpty( targetRisk ) ) {
      setNewTargetRisk( targetRisk );
    }
    if ( isEmpty( peerValues ) ) {
      makeRequest( 'FETCH', '/analysis/telemetry' ).then( response => {
        if ( isNotEmpty( response.results ) && isNotEmpty( response.results.industry_model_percentiles ) ) {
          // eslint-disable-next-line
          const mostRecentPointKey = Object.keys( response.results.industry_model_percentiles )[0];
          const mostRecentPoint = response.results.industry_model_percentiles[mostRecentPointKey];
          if ( mostRecentPoint ) {
            /* eslint-disable max-len */
            const peerDataPoints = [
              // 10%
              Math.round( getYForX( response.results.industry_comparison, 2, mostRecentPoint.observed_hosts, 3.333 ) ),
              // 20%
              Math.round( getYForX( response.results.industry_comparison, 2, mostRecentPoint.observed_hosts, 2.857 ) ),
              // 30%
              Math.round( getYForX( response.results.industry_comparison, 2, mostRecentPoint.observed_hosts, 2.5 ) ),
              // 40%
              Math.round( getYForX( response.results.industry_comparison, 2, mostRecentPoint.observed_hosts, 2.2222 ) ),
              // 50%
              Math.round( getYForX( response.results.industry_comparison, 1, mostRecentPoint.observed_hosts, 4 ) ),
              /* eslint-enable max-len */
            ];
            setPeerValues( peerDataPoints );
          }
        }
      } );
    }
  }, [ targetRisk ] );

  return (
    <React.Fragment>
      <div className="selectFieldWrapper">
        <label>Risk Display Mode</label>
        <select
          value={ newDisplaySetting }
          onChange= { e => setNewDisplaySetting( e.target.value ) }
        >
          <option value="risk">Risk score only</option>
          <option value="percentile">Peer percentile only</option>
          <option value="both">Both risk and percentile</option>
        </select>
      </div>
      <RiskAndPercentile externalPreference={ newDisplaySetting } />
      <h3>Target Risk</h3>
      <p>Your target risk (among other things) helps DeepSurface apply ratings to all items in your environment</p>
      <p>Use the form below to change your target</p>
      <div className="formWrapper">
        <div className="selectFieldWrapper">
          <label>Calculate target risk based on</label>
          <select
            value={riskEditMode}
            onChange={ e => setRiskEditMode( e.target.value ) }
          >
            <option value="percent">Percentage of current risk</option>
            <option value="peer">Peer percentile</option>
          </select>
        </div>
        {
          riskEditMode === 'peer' &&
          <div className="selectFieldWrapper">
            <label>Desired Peer Percentile:</label>
            <select
              value={peerIndex}
              onChange={ handleReductionChange }
            >
              <option value="">Select Percentile</option>
              <option value={ 0 }>Top 10%</option>
              <option value={ 1 }>Top 20%</option>
              <option value={ 2 }>Top 30%</option>
              <option value={ 3 }>Top 40%</option>
              <option value={ 4 }>Top 50% (typical peer average)</option>
            </select>
          </div>
        }
        {
          riskEditMode === 'percent' &&
          <div className="selectFieldWrapper">
            <label>Lower Target Risk by:</label>
            <select
              value={reductionValue}
              onChange={ handleReductionChange }
            >
              <option value={ 1 }>0%</option>
              <option value={ 0.9 }>10%</option>
              <option value={ 0.8 }>20%</option>
              <option value={ 0.7 }>30%</option>
              <option value={ 0.6 }>40%</option>
              <option value={ 0.5 }>50%</option>
            </select>
          </div>
        }

        <div className="numberFieldWrapper">
          <label>Target Risk</label>
          <input
            type="number"
            value={newTargetRisk}
            onChange={ e => setNewTargetRisk( Number( e.target.value ) ) }
          />
        </div>
      </div>

    </React.Fragment>
  );
};

const RiskScoreMenu = ( ) => {
  const [ , globalRisk, targetRisk, , , , ] = React.useContext( RiskContext );

  const [ showModal, setShowModal ] = React.useState( false );
  const [ newTargetRisk, setNewTargetRisk ] = React.useState( '' );
  const [ riskClass, setRiskClass ] = React.useState( 'high' );
  const [ percentileClass, setPercentileClass ] = React.useState( 'primary' );
  const [ globalSettings, setGlobalSettings ] = React.useState( null );

  const [ newDisplaySetting, setNewDisplaySetting ] = React.useState( 'risk' );

  const [ currentPercentile, setCurrentPercentile ] = React.useState( null );
  const [ shouldIncludePercentile, setShouldIncludePercentile ] = React.useState( false );
  const [ shouldIncludeRisk, setShouldIncludeRisk ] = React.useState( true );

  const [ riskEditMode, setRiskEditMode ] = React.useState( 'percent' );

  React.useEffect( ( ) => {

    // need the global settings for the trigger display ( options, percentile, global risk, both )
    getGlobalSettings( 'global' ).then( response => {
      setGlobalSettings( response );

      if ( isNotEmpty( response.risk_score_display_mode ) ) {
        setNewDisplaySetting( response.risk_score_display_mode );
        setShouldIncludePercentile(
          response.risk_score_display_mode === 'percentile' || response.risk_score_display_mode === 'both',
        );
        setShouldIncludeRisk(
          response.risk_score_display_mode === 'risk' || response.risk_score_display_mode === 'both',
        );
      } else {
        setNewDisplaySetting( 'risk' );
        setShouldIncludePercentile( false );
        setShouldIncludeRisk( true );
      }
    } );

    if ( isNotEmpty( globalRisk ) ) {
      // percentile for the percentile trigger display, only need to fetch it once
      if ( isEmpty( currentPercentile ) ) {
        getCurrentPercentile().then( _percentile => {
          if ( isNotEmpty( _percentile ) ) {
            setCurrentPercentile( _percentile );
          }
        } );
      }
    }
  }, [ globalRisk ] );

  React.useEffect( ( ) => {
    if ( isNotEmpty( globalRisk ) && isNotEmpty( targetRisk ) ) {

      const _riskClass = getRiskClass( globalRisk, targetRisk );
      setRiskClass( _riskClass );

    }
  }, [ globalRisk, targetRisk ] );

  React.useEffect( ( ) => {
    if ( isNotEmpty( currentPercentile ) && isNotEmpty( currentPercentile.percentile ) ) {
      const { percentile } = currentPercentile;
      const _percentileClass = getPercentileClass( percentile );
      setPercentileClass( _percentileClass );
    }
  }, [ currentPercentile ] );

  const getCurrentRiskClass = () => {
    if ( shouldIncludePercentile && isNotEmpty( currentPercentile ) ) {
      return percentileClass;
    }
    return riskClass;
  };

  return (
    <React.Fragment>
      {
        (
          isNotEmpty( globalRisk )
        ) &&
        <React.Fragment>
          <Modal
            visible={ showModal }
            setVisible={ setShowModal }
            body={ <ModalBody
              newTargetRisk={newTargetRisk}
              setNewTargetRisk={setNewTargetRisk}
              riskEditMode={riskEditMode}
              setRiskEditMode={setRiskEditMode}
              globalSettings={globalSettings}
              currentPercentile={currentPercentile}
              newDisplaySetting={newDisplaySetting}
              setNewDisplaySetting={setNewDisplaySetting}
            /> }
            action={ <ModalAction newTargetRisk={newTargetRisk} newDisplaySetting={newDisplaySetting} />}
            elementClass="topBarRiskScoreModal"
            size="small"
          />
          {
            ( shouldIncludeRisk || ( shouldIncludePercentile && isNotEmpty( currentPercentile ) ) ) &&
            <div
              className={ `globalRiskTriggerWrapper currentRiskButton risk--${getCurrentRiskClass()}` }
              onClick={ () => setShowModal( true )}
            >
              {
                shouldIncludeRisk &&
                <div className={ `riskButtonSection risk ${shouldIncludePercentile ? 'withPercentile' : ''}`} >
                  <span className="iconWrapper">
                    <InlineSVG type="dsLogoMinimal" />
                  </span>
                  <span>{ formatNumber( Math.round( globalRisk || 0 ) ) }</span>
                </div>
              }
              {
                ( shouldIncludePercentile && isNotEmpty( currentPercentile ) ) &&
                <div className={ `riskButtonSection percentile ${shouldIncludeRisk ? 'withRisk' : ''}`}>
                  <span className="iconWrapper">
                    <InlineSVG type="widget_rank" />
                  </span>
                  <span>{ `${ numberToOrdinal( currentPercentile?.percentile ) }` }</span>
                </div>
              }
            </div>
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default RiskScoreMenu;