/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';
import InlineSVG from '../InlineSVG';

import {
  isNotEmpty,
} from '../Utilities';

const ExpandingCell = ( {
  cellContents,
  skipSanitization=false,
} ) => {

  const [ showExpanded, setShowExpanded ] = React.useState( false );
  const [ needsExpanding, setNeedsExpanding ] = React.useState( false );
  const [ sanitizedContent, setSanitizedContent ] = React.useState( null );

  const contentRef = React.useRef( null );
  const wrapperRef = React.useRef( null );

  const handleExpand = () => {
    if ( needsExpanding ) {
      setShowExpanded( !showExpanded );
    }
  };

  // sanitize the content to make sure it is a string before adding
  React.useEffect( () => {
    if ( skipSanitization ) {
      setSanitizedContent( <pre>{ cellContents }</pre> );
    } else {
      const _sanitized = new String( cellContents );
      setSanitizedContent( <pre>{ _sanitized }</pre> );
    }
  }, [ cellContents ] );

  // after sanitization, do all the size logic
  React.useEffect( () => {
    if ( isNotEmpty( sanitizedContent ) && isNotEmpty( contentRef ) && isNotEmpty( wrapperRef ) ) {
      if ( isNotEmpty( contentRef.current ) && isNotEmpty( wrapperRef.current ) ) {
        const hiddenHeight = contentRef.current.clientHeight;
        const wrapperHeight = wrapperRef.current.clientHeight;
        if ( hiddenHeight > wrapperHeight ) {
          setNeedsExpanding( true );
        } else {
          setNeedsExpanding( false );
        }
      } else {
        setNeedsExpanding( false );
      }
    } else {
      setNeedsExpanding( false );
    }
  }, [ sanitizedContent, contentRef, wrapperRef ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( sanitizedContent ) &&
        <React.Fragment>
          <div
            className="expandingCellHiddenContent"
            ref={contentRef}
          >
            { sanitizedContent }
          </div>
          <div
            ref={wrapperRef}
            onClick={ handleExpand }
            // eslint-disable-next-line max-len
            className={ `expandingCellWrapper ${showExpanded ? 'expanded' : ''} ${needsExpanding ? 'needsExpanding' : ''}` }
          >
            {
              needsExpanding &&
              <span
                className="expandingCellIndicator"
              >
                <InlineSVG type="moreHorizontal" elementClass="expandMore" />
                <InlineSVG type="carretDown" elementClass="expandDown" />
                <InlineSVG type="carretUp" elementClass="expandUp" />
              </span>
            }
            { sanitizedContent }
          </div>
        </React.Fragment>
      }

    </React.Fragment>

  );
};

export default ExpandingCell;
