/** **************************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ****************************************************************************/
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  qnormal,
} from '../../../legacy/math-functions';

import {
  globalRiskColor,
} from '../Charts/shared.js';

import cbcLogo from '../../../../images/logos/cbc.png';
import msatpLogo from '../../../../images/logos/msatp.png';
import nozomiLogo from '../../../../images/logos/nozomi.png';
import openvasLogo from '../../../../images/logos/openvas.png';
import wazuhLogo from '../../../../images/logos/wazuh.png';
import qualysLogo from '../../../../images/logos/qualys.png';
import rapid7Logo from '../../../../images/logos/rapid7.png';
import tenableLogo from '../../../../images/logos/tenable.png';
import deepsurfaceLogo from '../../../../images/logos/deepsurface.png';
import crowdstrikeLogo from '../../../../images/logos/crowdstrike.png';
import eclypsiumLogo from '../../../../images/logos/eclypsium.png';
import awsLogo from '../../../../images/logos/aws.png';
import lansweeperLogo from '../../../../images/logos/lansweeper.png';

import cbcLogoFull from '../../../../images/logos/cbc_full.png';
import msatpLogoFull from '../../../../images/logos/msatp_full.png';
import nozomiLogoFull from '../../../../images/logos/nozomi_full.png';
import openvasLogoFull from '../../../../images/logos/openvas_full.png';
import wazuhLogoFull from '../../../../images/logos/wazuh_full.png';
import qualysLogoFull from '../../../../images/logos/qualys_full.png';
import rapid7LogoFull from '../../../../images/logos/rapid7_full.png';
import tenableLogoFull from '../../../../images/logos/tenable_full.png';
import deepsurfaceLogoFull from '../../../../images/logos/deepsurface_full.png';
import crowdstrikeLogoFull from '../../../../images/logos/crowdstrike_full.png';
import eclypsiumLogoFull from '../../../../images/logos/eclypsium_full.png';
import awsLogoFull from '../../../../images/logos/aws_full.png';
import lansweeperLogoFull from '../../../../images/logos/lansweeper_full.png';

import { makeRequest } from '../../../legacy/io';
import InlineSVG from '../InlineSVG';

// SECTION ----------------- helpers and dictionaries ------------------------------- SECTION //
export const stringIsEmpty = testString => testString.trim() === '';
export const stringIsNotEmpty = testString => testString.trim() !== '';

export const arrayIsEmpty = testArray => testArray.length === 0;
export const arrayIsNotEmpty = testArray => testArray.length > 0;

export const objectIsEmpty = testObject => Object.keys( testObject ).length === 0 && testObject.constructor === Object;
// eslint-disable-next-line max-len
export const objectIsNotEmpty = testObject => Object.keys( testObject ).length !== 0 && testObject.constructor === Object;

export const mapIsEmpty = testMap => testMap.size === 0;
export const mapIsNotEmpty = testMap => testMap.size > 0;

// used everywhere. Accepts strings, arrays, objects, maps
export const isEmpty = test => {
  if ( test === null || test === 'undefined' ) {
    return true;
  }
  if ( test ) {
    if ( test.constructor === Array ) {
      return arrayIsEmpty( test );
    }
    if ( test.constructor === Object ) {
      return objectIsEmpty( test );
    }
    if ( typeof test === 'string' || test instanceof String ) {
      return stringIsEmpty( test );
    }
    if ( test instanceof( Map ) ) {
      return mapIsEmpty( test );
    }
    if ( Number.isInteger( test ) ) {
      return Number.isNaN( test );
    }
    return false;
  }
  return true;
};

// used everywhere. Accepts strings, arrays, objects, maps
export const isNotEmpty = test => {
  if ( test === null || test === 'undefined' ) {
    return false;
  }
  if ( ( typeof test === 'number' && test === 0 ) || test ) {
    if ( test.constructor === Array ) {
      return arrayIsNotEmpty( test );
    }
    if ( test.constructor === Object ) {
      return objectIsNotEmpty( test );
    }
    if ( typeof test === 'string' || test instanceof String ) {
      return stringIsNotEmpty( test );
    }
    if ( typeof test === 'number' ) {
      if ( test === 0 || test ) {
        return true;
      }
    }
    if ( test instanceof( Map ) ) {
      return mapIsNotEmpty( test );
    }
    return true;
  }
  if ( typeof test === 'number' ) {
    return true;
  }
  return false;
};

const getDataType = test => {
  if ( test === undefined ) {
    return undefined;
  }

  if ( itemIsObject( test ) ) {
    return 'object';
  } else if ( itemIsArray( test ) ) {
    return 'array';
  } else if ( itemIsString( test ) ) {
    return 'string';
  }  else if ( itemIsFloat( test ) ) {
    return 'float';
  } else if ( itemIsNumber( test ) ) {
    return 'number';
  } else if ( itemIsBoolean( test ) ) {
    return 'boolean';
  } else if ( test === null ) {
    return 'null';
  }
  return undefined;
};

export const itemIsObject = test => {
  return isNotEmpty( test ) && test.constructor === Object;
};

export const itemIsArray = test => {
  return isNotEmpty( test ) && Array.isArray( test );
};

export const itemIsString = test => {
  return typeof test === 'string' || test instanceof String;
};

export const itemIsNumber = test => {
  return typeof test === 'number';
};

export const itemIsFloat = test => {
  return typeof test === 'number' && !Number.isInteger( test );
};

export const itemIsBoolean = test => {
  return typeof test === 'boolean';
};

export const isEqual = ( a, b ) => {
  const aType = getDataType( a );
  const bType = getDataType( b );

  // they have the same type
  if ( aType === bType ) {

    if ( isEmpty( aType ) ) {
      return false;
    }

    // Object need to do some recursive lookups
    if ( aType === 'object' ) {
      const aKeys = Object.keys( a );
      const bKeys = Object.keys( b );

      if ( aKeys.length !== bKeys.length ) {
        return false;
      }

      const equalA = aKeys.every( aKey => isEqual( a[aKey], b[aKey] ) );
      const equalB = bKeys.every( bKey => isEqual( a[bKey], b[bKey] ) );

      return equalA === true && equalB === true;
    }

    // array need to do some recursive lookups
    if ( aType === 'array' ) {
      const aCopy = [ ...a ];
      const bCopy = [ ...b ];
      const sortedA = aCopy.sort();
      const sortedB = bCopy.sort();
      if ( sortedA.length !== sortedB.length ) {
        return false;
      }
      const equal = sortedA.every( ( item, i ) => isEqual( item, sortedB[i] ) );
      return equal;

    }

    // string/number/float
    if ( aType === 'string' || aType === 'number' || aType === 'float' ) {
      return a === b;
    }

    if ( aType === 'boolean' ) {
      return a === b;
    }

    if ( aType === 'null' ) {
      return a === b;
    }
    return false;
  }
  return false;

};

export const isFalsey = test => {

  if ( test === null || test === undefined ) {
    return true;
  }

  if ( itemIsBoolean( test ) ) {
    return test === false;
  }

  if ( itemIsNumber( test ) ) {
    return test === 0;
  }

  if ( itemIsString( test ) ) {
    return test === '0' || test === 'false' || test === 'null';
  }

  return false;
};

export const isTruthy = test => {
  if ( itemIsBoolean( test ) ) {
    return test === true;
  }

  if ( itemIsNumber( test ) ) {
    return test <= 0;
  }

  if ( itemIsString( test ) ) {
    return test === 'true' || test === '1';
  }
};

// if we ever need to isolate functionality only for Safari
export const isSafari = navigator.vendor && navigator.vendor.indexOf( 'Apple' ) > -1 &&
                        navigator.userAgent &&
                        navigator.userAgent.indexOf( 'CriOS' ) === -1 &&
                        navigator.userAgent.indexOf( 'FxiOS' ) === -1;

// if we ever need to isolate functionality only for FF
export const isFirefox =  navigator.userAgent.toLowerCase().indexOf( 'firefox' ) > -1;

// base/default URLs for each of the instance reports
export const DEFAULT_INSIGHT_URL = {
  // eslint-disable-next-line max-len
  host: '#.=risk_insight&report=hosts&item_count=100&sort_by=risk&sort_direction=DESC&sensitive_assets=any&current_page=1',
  // eslint-disable-next-line max-len
  patch: '#.=risk_insight&report=patches&risk_type=risk&item_count=100&sort_by=risk&sort_direction=DESC&superseded=unsuperseded&current_page=1',
  // eslint-disable-next-line max-len
  vulnerability: '#.=risk_insight&report=vulnerabilities&item_count=100&sort_by=risk&sort_direction=DESC&patchable=any&current_page=1',
  // eslint-disable-next-line max-len
  instance: '#.=risk_insight&report=instances&group_type=host&item_count=100&sort_by=filtered_risk&sort_direction=DESC&current_page=1',
  path: '#.=explore&page=explore_paths&item_count=100&sort_by=risk&sort_direction=DESC&current_page=1',
};

// only want certain creds that are of integration creds
export const INTEGRATION_CREDENTIAL_PROTOCOLS = [
  'jira',
];

export const integrationNameMap = {
  jira: 'Atlassian Jira',
  email: 'Ticketing via email',
};

export const integrationInstanceName = ( integration, simplified=false, includeTool=true ) => {
  if ( isNotEmpty( integration ) ) {
    if ( integration.tool === 'jira' ) {
      if ( isNotEmpty( integration.label ) ) {
        if ( simplified ) {
          let label = `${integration.label}`;
          if ( includeTool ) {
            label = label + ` (${ integrationNameMap[integration.tool] })`;
          }
          return label;
        }
        return <span>
          <strong>{ `${integration.label}` }</strong>
          {
            includeTool && <React.Fragment>
              ({ integrationNameMap[integration.tool] })
            </React.Fragment>
          }
        </span>;
      }
      let label = `Integration for ${integration?.project_name || 'unnamed project'}`;
      if ( includeTool ) {
        label = label + ` (${ integrationNameMap[integration.tool] })`;
      }
      return label;
    }
    if ( integration.tool === 'email' ) {
      if ( isNotEmpty( integration.email_label ) ) {
        if ( simplified ) {
          let label = `${integration.email_label}`;
          if ( includeTool ) {
            label = label + ` (${ integrationNameMap[integration.tool] })`;
          }
          return label;
        }
        return <span>
          <strong>{ `${integration.email_label}` }</strong>
          {
            includeTool && <React.Fragment>
              ({ integrationNameMap[integration.tool] })
            </React.Fragment>
          }
        </span>;
      }
      let label = `Integration for ${integration?.destination_email_address}`;
      if ( includeTool ) {
        label = label + ` (${ integrationNameMap[integration.tool] })`;
      }
      return label;
    }
    return `Unnamed ${integrationNameMap[integration?.tool]} integration`;
  }
  return `Unnamed ${integrationNameMap[integration?.tool]} integration`;
};

export const AWSRegions = [
  'eu-south-1', 'ap-northeast-3', 'ap-southeast-1', 'us-east-2', 'eu-north-1', 'ap-northeast-2', 'ca-central-1',
  'eu-west-1', 'ap-east-1', 'eu-west-2', 'af-south-1', 'us-iso-east-1', 'sa-east-1', 'us-gov-east-1', 'ap-southeast-3',
  'eu-central-1', 'us-gov-west-1', 'us-west-1', 'cn-northwest-1', 'us-isob-east-1', 'ap-northeast-1', 'me-south-1',
  'eu-west-3', 'ap-south-1', 'us-iso-west-1', 'cn-north-1', 'us-east-1', 'ap-southeast-2', 'us-west-2',
];

export const AWSRegionsOptions = {
  'eu-south-1': 'eu-south-1',
  'ap-northeast-3': 'ap-northeast-3',
  'ap-southeast-1': 'ap-southeast-1',
  'us-east-2': 'us-east-2',
  'eu-north-1': 'eu-north-1',
  'ap-northeast-2': 'ap-northeast-2',
  'ca-central-1': 'ca-central-1',
  'eu-west-1': 'eu-west-1',
  'ap-east-1': 'ap-east-1',
  'eu-west-2': 'eu-west-2',
  'af-south-1': 'af-south-1',
  'us-iso-east-1': 'us-iso-east-1',
  'sa-east-1': 'sa-east-1',
  'us-gov-east-1': 'us-gov-east-1',
  'ap-southeast-3': 'ap-southeast-3',
  'eu-central-1': 'eu-central-1',
  'us-gov-west-1': 'us-gov-west-1',
  'us-west-1': 'us-west-1',
  'cn-northwest-1': 'cn-northwest-1',
  'us-isob-east-1': 'us-isob-east-1',
  'ap-northeast-1': 'ap-northeast-1',
  'me-south-1': 'me-south-1',
  'eu-west-3': 'eu-west-3',
  'ap-south-1': 'ap-south-1',
  'us-iso-west-1': 'us-iso-west-1',
  'cn-north-1': 'cn-north-1',
  'us-east-1': 'us-east-1',
  'ap-southeast-2': 'ap-southeast-2',
  'us-west-2': 'us-west-2',
};

export const vulnerabilityScannerNameMap = {
  nessusapi:      'Nessus API',
  tenableio:      'Tenable.io API',
  tenablesc:      'SecurityCenter/Tenable.sc API',
  rapid7api:      'Rapid7 InsightVM API',
  qualysapi:      'Qualys API',
  lansweeperapi:  'Lansweeper Cloud',
  lansweeper:     'Lansweeper Cloud',
  wazuh:          'Wazuh',
  wazuhapi:       'Wazuh',
  nozomiapi:      'Nozomi Guardian',
  crowdstrike:    'CrowdStrike Spotlight',
  crowdstrikeapi: 'CrowdStrike Spotlight',
  eclypsium:      'Eclypsium',
  eclypsiumapi:   'Eclypsium',
  awsinspector:   'AWS Inspector',
  awsinspectorapi:'AWS Inspector',
  msatp:          'Microsoft Defender for Endpoint',
  cbc:            'Carbon Black Cloud',
  nessus:         'Nessus/Tenable.io Manual Imports',
  openvas:        'OpenVAS Manual Imports',
  rapid7:         'Rapid7 InsightVM Manual Imports',
  qualys:         'Qualys Manual Imports',
};

export const manualVulnerabilityScanners = [
  'nessus',
  'openvas',
  'rapid7',
  'qualys',
];

export const APIVulnerabilityScanners = [
  'nessusapi',
  'tenableio',
  'tenablesc',
  'rapid7api',
  'qualysapi',
  'lansweeperapi',
  'lansweeper',
  'wazuh',
  'nozomiapi',
  'awsinspectorapi',
  'msatp',
  'cbc',
];

export const shortenedVulnerabilityScannerNameMap = {
  nessus: 'Tenable',
  nessusapi: 'Tenable',
  tenableio: 'Tenable',
  tenablesc: 'Tenable',

  wazuh: 'Wazuh',
  wazuhapi: 'Wazuh',

  lansweeper: 'Lansweeper',
  lansweeperapi: 'Lansweeper',

  nozomi: 'Nozomi',
  nozomiapi: 'Nozomi',

  crowdstrike: 'CrowdStrike',
  crowdstrikeapi: 'CrowdStrike',

  eclypsium:      'Eclypsium',
  eclypsiumapi:   'Eclypsium',

  awsinspector: 'AWS',
  awsinspectorapi: 'AWS',

  rapid7: 'Rapid7',
  rapid7api: 'Rapid7',

  qualys: 'Qualys',
  qualysapi: 'Qualys',

  openvas: 'OpenVAS',
  internal: 'DeepSurface',
  msatp: 'MSDE',
  cbc: 'Carbon Black',

};

export const vulnerabilityScannerInstanceName = record => {
  if ( manualVulnerabilityScanners.includes( record.tool ) ) {
    return 'Manual Upload';
  }
  if ( record.settings ) {
    if ( record.settings.server ) {
      return record.settings.server;
    }
    if ( record.settings.username ) {
      return record.settings.username;
    }
    return 'Manual Upload';
  }
  return 'N/A';
};

export const vulnerabilityScannerLogoMap = {
  nessus: { small: tenableLogo, full: tenableLogoFull },
  tenablesc: { small: tenableLogo, full: tenableLogoFull },
  tenableio: { small: tenableLogo, full: tenableLogoFull },
  nessusapi: { small: tenableLogo, full: tenableLogoFull },

  rapid7: { small: rapid7Logo, full: rapid7LogoFull },
  rapid7api: { small: rapid7Logo, full: rapid7LogoFull },

  qualys: { small: qualysLogo, full: qualysLogoFull },
  qualysapi: { small: qualysLogo, full: qualysLogoFull },

  lansweeper: { small: lansweeperLogo, full: lansweeperLogoFull },
  lansweeperapi: { small: lansweeperLogo, full: lansweeperLogoFull },

  wazuh: { small: wazuhLogo, full: wazuhLogoFull },
  wazuhapi: { small: wazuhLogo, full: wazuhLogoFull },

  nozomi: { small: nozomiLogo, full: nozomiLogoFull },
  nozomiapi: { small: nozomiLogo, full: nozomiLogoFull },

  crowdstrike: { small: crowdstrikeLogo, full: crowdstrikeLogoFull },
  crowdstrikeapi: { small: crowdstrikeLogo, full: crowdstrikeLogoFull },

  eclypsium: { small: eclypsiumLogo, full: eclypsiumLogoFull },
  eclypsiumapi: { small: eclypsiumLogo, full: eclypsiumLogoFull },

  awsinspector: { small: awsLogo, full: awsLogoFull },
  awsinspectorapi: { small: awsLogo, full: awsLogoFull },

  cbc: { small: cbcLogo, full: cbcLogoFull },
  msatp: { small: msatpLogo, full: msatpLogoFull },
  openvas: { small: openvasLogo, full: openvasLogoFull },
  internal: { small: deepsurfaceLogo, full: deepsurfaceLogoFull },
};

export const vulnerabilityScannerLogo = ( scanner, size='small' ) => {
  if ( isNotEmpty( scanner ) && isNotEmpty( vulnerabilityScannerLogoMap[scanner] ) ) {
    return <img
      src={vulnerabilityScannerLogoMap[scanner][size] }
      alt={ `${vulnerabilityScannerNameMap[scanner]} Logo`}
      className={ `recordTypeHeaderIcon ${size}` }
    />;
  }
};

export const cloudScannerVendorNameMap = {
  aws: 'AWS',
};

export const cloudScannerVendorName = scanner => cloudScannerVendorNameMap[scanner.vendor];

export const cloudScannerLogo = scanner => {
  return <InlineSVG type={ `${scanner.vendor}Logo` } version="special" />;
};

// categegories in vuln. instance report
export const translatedCategoryValues = {
  prioritized: 'prioritized',
  unrecognized: 'unrecognized',
  unsupported: 'unsupported',
  // eslint-disable-next-line camelcase
  cannot_model: 'cannot_model',
  // eslint-disable-next-line camelcase
  insufficient_information: 'insufficient_information',
  // eslint-disable-next-line camelcase
  missing_capability: 'missing_capability',
  // eslint-disable-next-line camelcase
  dos_only: 'purely_denial_of_service',
  // eslint-disable-next-line camelcase
  not_exploitable: 'not_exploitable',
  overridden: 'overridden',
  deprioritized: 'deprioritized',
  unreachable: 'unreachable',
  // eslint-disable-next-line camelcase
  carries_risk: 'carries_risk',
  // eslint-disable-next-line camelcase
  for_review: 'for_review',
  // eslint-disable-next-line camelcase
  missing_host: 'missing_host',
};

export const categoryLabelMap = {
  prioritized: 'Prioritized',
  unrecognized: 'Unrecognized',
  unsupported: 'Unsupported',
  // eslint-disable-next-line camelcase
  cannot_model: 'Cannot Model',
  // eslint-disable-next-line camelcase
  insufficient_information: 'Insufficient Information',
  // eslint-disable-next-line camelcase
  missing_capability: 'Missing Capability',
  // eslint-disable-next-line camelcase
  dos_only: 'Purely Denial of Service',
  // eslint-disable-next-line camelcase
  purely_denial_of_service: 'Purely Denial of Service',
  // eslint-disable-next-line camelcase
  not_exploitable: 'Mitigated',
  overridden: 'Overridden',
  deprioritized: 'Deprioritized',
  unreachable: 'No Attack Path',
  // eslint-disable-next-line camelcase
  carries_risk: 'Carries Risk',
  // eslint-disable-next-line camelcase
  for_review: 'For Review',
  // eslint-disable-next-line camelcase
  missing_host: 'Not Scanned by DeepSurface',
};


// mirrors globals.scss
export const globalColors = {

  // primary colors
  'primaryBlue': '#00AAE9',
  'darkBlue': '#334D6E',
  'darkBlue--80': '#5c718b',
  'darkBlue--60': '#8594a8',
  'darkBlue--40': '#adb8c5',
  'darkBlue--20': '#d6dbe2',

  'default': '#1ab3ec',

  // greys
  'grey': '#90A0B7',
  'grey--icon': '#C2CFE0',
  'grey--divider': '#EBEFF2',
  'grey--background': '#F7F7FA',
  'grey--background--light': '#FCFCFD',

  // risk colors and variants
  'critical': '#c33937',
  'high': '#ea5e50',
  'moderate': '#ff961a',
  'low': '#ffdf0d',
  'minimal': '#43d988',
  'primary': '#1ab3ec',
  'unknown': '#90A0B7',

  'critical--75': '#cd5958',
  'high--75': '#ed796d',
  'moderate--75': '#ffa740',
  'low--75': '#ffe640',
  'minimal--75': '#62df9b',
  'primary--75': '#40bfef',

  'critical--50': '#de9190',
  'high--50': '#f3a69e',
  'moderate--50': '#ffc580',
  'low--50': '#ffee80',
  'minimal--50': '#97eabd',
  'primary--50': '#80d5f4',
  'unknown--50': '#C2CFE0',

  'critical--30': '#ebbdbd',
  'high--30': '#f8cac5',
  'moderate--30': '#ffdcb3',
  'low--30': '#fff5b3',
  'minimal--30': '#c1f3d8',
  'primary--30': '#b3e6f9',
  'unknown--30': '#EBEFF2',

  'critical--15': '#f5dede',
  'high--15': '#fce4e2',
  'moderate--15': '#ffeed9',
  'low--15': '#fffad9',
  'minimal--15': '#e0f9eb',
  'primary--15': '#d9f2fc',

  'critical--10': '#f9e9e9',
  'high--10': '#fdeeec',
  'moderate--10': '#fff4e6',
  'low--10': '#fffce6',
  'minimal--10': '#ebfbf2',
  'primary--10': '#e6f7fd',

  // status colors and variants
  'status--blue': '#2D9CDB',
  'status--green': '#6FCF97',
  'status--yellow': '#F9CD49',
  'status--orange': '#F2994A',
  'status--red': '#F7685B',

  'status--green--75': '#93dbb1',
  'status--green--50': '#b7e7cb',
  'status--green--25': '#dbf3e5',

  'status--red--10': '#fff0ef',
  'status--red--20': '#fde1de',
  'status--red--40': '#fcc3bd',
  'status--red--60': '#faa49d',
  'status--red--80': '#f9867c',
};

export const tagColors = {
  one: [
    '#343434',
    '#f94144',
    '#f3722c',
    '#f8961e',
    '#f9844a',
    '#f9c74f',
    '#90be6d',
    '#43aa8b',
    '#4d908e',
    '#577590',
    '#277da1',
  ],
  two: [
    '#5f5f5f',
    '#fb8d8f',
    '#f8aa80',
    '#fbc078',
    '#fbb592',
    '#fbdd95',
    '#bcd8a7',
    '#8eccb9',
    '#94bcbb',
    '#9aacbc',
    '#7db1c7',
  ],
  three: [
    '#8a8a8a',
    '#ffdd00',
    '#ffbe0b',
    '#fb5607',
    '#f94144',
    '#ff006e',
    '#8338ec',
    '#560bad',
    '#3f37c9',
    '#3a86ff',
    '#4cc9f0',
  ],
  four: [
    '#cbcbcb',
    '#ffeb66',
    '#ffd86d',
    '#fd9a6a',
    '#fb8d8f',
    '#ff66a8',
    '#b588f4',
    '#9a6dce',
    '#8c87df',
    '#89b6ff',
    '#94dff6',
  ],
};

export const hexToRGB = hex => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec( hex );
  return result
    ? {
      r: parseInt( result[1], 16 ),
      g: parseInt( result[2], 16 ),
      b: parseInt( result[3], 16 ),
    }
    : null;
};

export const [ defaultTagColor ] = tagColors.four;

export const tagColorDarkLabels = [
  '#f9c74f',

  '#fbc078',
  '#fbb592',
  '#fbdd95',
  '#bcd8a7',
  '#8eccb9',
  '#94bcbb',

  '#ffdd00',

  '#cbcbcb',
  '#ffeb66',
  '#ffd86d',
  '#fd9a6a',
  '#94dff6',
];

// all attributes that are of type array
// so that the inputs know how to decode/encode
export const isArrayType = [
  'asset_tag_ids',
  'host_ids',
  'id_list',
  'patch_ids',
  'ports',
  'vulnerability_ids',
  'signature_ids',
  'item_ids',
  'vulnerability_globs',
  'host_globs',
  'patch_globs',
  'scan_groups',
  'explore_scope',
  'explore_patch',
  'explore_vulnerability',
  'explore_path',
  'explore_node',
  'explore_edge',

  'age',
  'risk_reduction',
  'num_hosts',
  'cvss_base_score',
];

export const isInequalityType = [
  'age',
  'risk_reduction',
  'num_hosts',
  'cvss_base_score',
];

// there are lots of fields that need to send a null back to the server, but need to display something to the
// front end, hence the "Form Null" concept. This just keeps track of the myriad of ways this is being done... uggh
// this concept was ported over from legacy code
export const isFormNull = val => {
  return val === 'undefined'
  || val === 'none'
  || val === 'null'
  || val === ''
  || val === undefined
  || val === null;
};

export const exploitStatusMap = {
  'null': 'Private',
  'private': 'Details published',
  'published_details': 'PoC published',
  'poc': 'Weaponized',
};

export const unshiftedExploitStatusMap = {
  'private': 'Private',
  'published_details': 'Details published',
  'poc': 'PoC published',
  'weaponized': 'Weaponized',
};

export const exploitStatusLabelMap = {
  'null': 'Private',
  'private': 'Private',
  'published_details': 'Details published',
  'poc': 'PoC published',
  'weaponized': 'Weaponized',
};

export const vulnScannerNameMap = {
  nessus:   'Tenable',
  openvas:  'OpenVAS',
  rapid7:   'Rapid7',
  qualys:   'Qualys',
  internal: 'DeepSurface',
  msatp:    'MDE',
  cbc:      'Carbon Black',
};

export const vulnScannerFullNameMap = {
  tenableio:  'Tenable.io API',
  tenablesc:  'SecurityCenter/Tenable.sc API',
  nessusapi:  'Nessus API',
  nessus:     'Nessus/Tenable.io Manual Imports',
  openvas:    'OpenVAS Manual Imports',
  rapid7api:  'Rapid7 InsightVM API',
  rapid7:     'Rapid7 InsightVM Manual Imports',
  qualys:     'Qualys Manual Imports',
  qualysapi:  'Qualys API',
  msatp:      'Microsoft Defender for Endpoint',
  cbc:        'Carbon Black Cloud',
};

export const recordTypeIcon = {
  host:             <svg
    className={'inlineSVG dark'}
    width={32}
    height={32}
    viewBox={ '0 0 32 32'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* eslint-disable-next-line max-len */}
    <path d="M23.7037 15.4074H15.4074V23.7037H23.7037V15.4074ZM20.9383 20.9382H18.1728V18.1728H20.9383V20.9382ZM32 18.1728V15.4074H29.2346V12.6419C29.2346 11.121 27.9901 9.87652 26.4691 9.87652H23.7037V7.11108H20.9383V9.87652H18.1728V7.11108H15.4074V9.87652H12.642C11.121 9.87652 9.87655 11.121 9.87655 12.6419V15.4074H7.11111V18.1728H9.87655V20.9382H7.11111V23.7037H9.87655V26.4691C9.87655 27.9901 11.121 29.2345 12.642 29.2345H15.4074V32H18.1728V29.2345H20.9383V32H23.7037V29.2345H26.4691C27.9901 29.2345 29.2346 27.9901 29.2346 26.4691V23.7037H32V20.9382H29.2346V18.1728H32ZM26.4691 26.4691H12.642V12.6419H26.4691V26.4691Z" fill="black"/>
    {/* eslint-disable-next-line max-len */}
    <path d="M22.2222 0H2.66667C1.14568 0 0 1.14568 0 2.66667V22.2222C0 23.7432 1.14568 24.8889 2.66667 24.8889H4.44444V22.2222H3.66667C3.11438 22.2222 2.66667 21.7745 2.66667 21.2222V3.66667C2.66667 3.11438 3.11438 2.66667 3.66667 2.66667H21.2222C21.7745 2.66667 22.2222 3.11438 22.2222 3.66667V4.44444H24.8889V2.66667C24.8889 1.14568 23.7432 0 22.2222 0Z" fill="black"/>
  </svg>,
  scope:            <svg
    className={'inlineSVG dark'}
    width={32}
    height={32}
    viewBox={ '0 0 32 32'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* eslint-disable-next-line max-len */}
    <path d="M23.7037 15.4074H15.4074V23.7037H23.7037V15.4074ZM20.9383 20.9382H18.1728V18.1728H20.9383V20.9382ZM32 18.1728V15.4074H29.2346V12.6419C29.2346 11.121 27.9901 9.87652 26.4691 9.87652H23.7037V7.11108H20.9383V9.87652H18.1728V7.11108H15.4074V9.87652H12.642C11.121 9.87652 9.87655 11.121 9.87655 12.6419V15.4074H7.11111V18.1728H9.87655V20.9382H7.11111V23.7037H9.87655V26.4691C9.87655 27.9901 11.121 29.2345 12.642 29.2345H15.4074V32H18.1728V29.2345H20.9383V32H23.7037V29.2345H26.4691C27.9901 29.2345 29.2346 27.9901 29.2346 26.4691V23.7037H32V20.9382H29.2346V18.1728H32ZM26.4691 26.4691H12.642V12.6419H26.4691V26.4691Z" fill="black"/>
    {/* eslint-disable-next-line max-len */}
    <path d="M22.2222 0H2.66667C1.14568 0 0 1.14568 0 2.66667V22.2222C0 23.7432 1.14568 24.8889 2.66667 24.8889H4.44444V22.2222H3.66667C3.11438 22.2222 2.66667 21.7745 2.66667 21.2222V3.66667C2.66667 3.11438 3.11438 2.66667 3.66667 2.66667H21.2222C21.7745 2.66667 22.2222 3.11438 22.2222 3.66667V4.44444H24.8889V2.66667C24.8889 1.14568 23.7432 0 22.2222 0Z" fill="black"/>
  </svg>,
  patch:            <svg
    className={'inlineSVG dark'}
    width={32}
    height={32}
    viewBox={ '0 0 32 32'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* eslint-disable-next-line max-len */}
    <path d="M26.6853 19.5555L31.6246 14.6138C32.1086 14.1295 32.1086 13.3473 31.6246 12.863L26.2385 7.47427C25.7545 6.99002 24.9727 6.99002 24.4887 7.47427L19.5494 12.416L14.61 7.47427C14.3618 7.23835 14.0516 7.11419 13.7289 7.11419C13.4187 7.11419 13.096 7.23835 12.8602 7.47427L7.47412 12.863C6.99011 13.3473 6.99011 14.1295 7.47412 14.6138L12.4134 19.5555L7.47412 24.4973C6.99011 24.9815 6.99011 25.7638 7.47412 26.248L12.8602 31.6368C13.3442 32.121 14.126 32.121 14.61 31.6368L19.5494 26.695L24.4887 31.6368C24.7369 31.8851 25.0471 31.9969 25.3698 31.9969C25.6925 31.9969 26.0027 31.8727 26.2509 31.6368L31.637 26.248C32.121 25.7638 32.121 24.9815 31.637 24.4973L26.6853 19.5555V19.5555ZM19.5742 15.8057C20.2567 15.8057 20.8152 16.3645 20.8152 17.0474C20.8152 17.7303 20.2567 18.289 19.5742 18.289C18.8916 18.289 18.3331 17.7303 18.3331 17.0474C18.3331 16.3645 18.8916 15.8057 19.5742 15.8057ZM13.7289 18.2394L9.22397 13.7446L13.7289 9.23741L18.2215 13.7322L13.7289 18.2394ZM17.0921 20.7723C16.4095 20.7723 15.8511 20.2136 15.8511 19.5307C15.8511 18.8478 16.4095 18.289 17.0921 18.289C17.7747 18.289 18.3331 18.8478 18.3331 19.5307C18.3331 20.2136 17.7747 20.7723 17.0921 20.7723ZM19.5742 23.2556C18.8916 23.2556 18.3331 22.6969 18.3331 22.014C18.3331 21.3311 18.8916 20.7723 19.5742 20.7723C20.2567 20.7723 20.8152 21.3311 20.8152 22.014C20.8152 22.6969 20.2567 23.2556 19.5742 23.2556ZM22.0562 18.289C22.7388 18.289 23.2973 18.8478 23.2973 19.5307C23.2973 20.2136 22.7388 20.7723 22.0562 20.7723C21.3737 20.7723 20.8152 20.2136 20.8152 19.5307C20.8152 18.8478 21.3737 18.289 22.0562 18.289ZM25.3574 29.8861L20.8524 25.3913L25.3574 20.8841L29.8499 25.3789L25.3574 29.8861Z" fill="black"/>
    {/* eslint-disable-next-line max-len*/}
    <path d="M22.2222 0H2.66667C1.14568 0 0 1.14568 0 2.66667V22.2222C0 23.7432 1.14568 24.8889 2.66667 24.8889H4.44444V22.2222H3.66667C3.11438 22.2222 2.66667 21.7745 2.66667 21.2222V3.66667C2.66667 3.11438 3.11438 2.66667 3.66667 2.66667H21.2222C21.7745 2.66667 22.2222 3.11438 22.2222 3.66667V4.44444H24.8889V2.66667C24.8889 1.14568 23.7432 0 22.2222 0Z" fill="black"/>
  </svg>,
  vulnerability:    <svg
    className={'inlineSVG dark'}
    width={32}
    height={32}
    viewBox={ '0 0 32 32'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* eslint-disable-next-line max-len */}
    <path d="M30.2346 14.0247H26.3491C25.7269 12.9461 24.8696 12.0197 23.8326 11.3145L26.0864 9.06071L24.1368 7.11108L21.1363 10.1116C20.5003 9.95948 19.8504 9.87652 19.1728 9.87652C18.4953 9.87652 17.8454 9.95948 17.2232 10.1116L14.2089 7.11108L12.2593 9.06071L14.4993 11.3145C13.4761 12.0197 12.6188 12.9461 11.9965 14.0247H8.11111V16.7901H11.001C10.9319 17.2464 10.8765 17.7027 10.8765 18.1728V19.5555H8.11111V22.321H10.8765V23.7037C10.8765 24.1738 10.9319 24.6301 11.001 25.0864H8.11111V27.8518H11.9965C13.4346 30.3269 16.1032 32 19.1728 32C22.2425 32 24.9111 30.3269 26.3491 27.8518H30.2346V25.0864H27.3447C27.4138 24.6301 27.4691 24.1738 27.4691 23.7037V22.321H30.2346V19.5555H27.4691V18.1728C27.4691 17.7027 27.4138 17.2464 27.3447 16.7901H30.2346V14.0247ZM24.7037 19.5555V23.7037C24.7037 24.0079 24.6622 24.3536 24.6069 24.6716L24.4686 25.5703L23.957 26.4691C22.9615 28.1837 21.1363 29.2345 19.1728 29.2345C17.2094 29.2345 15.3842 28.1698 14.3886 26.4691L13.877 25.5842L13.7388 24.6854C13.6835 24.3674 13.642 24.0217 13.642 23.7037V18.1728C13.642 17.8548 13.6835 17.5091 13.7388 17.2049L13.877 16.3061L14.3886 15.4074C14.8035 14.6884 15.3842 14.0661 16.0617 13.596L16.8499 13.0568L17.8731 12.8079C18.3017 12.6973 18.7442 12.6419 19.1728 12.6419C19.6153 12.6419 20.044 12.6973 20.4864 12.8079L21.4267 13.0291L22.2701 13.6098C22.9615 14.08 23.5284 14.6884 23.9432 15.4212L24.4686 16.32L24.6069 17.2187C24.6622 17.5229 24.7037 17.8686 24.7037 18.1728V19.5555ZM16.4074 22.321H21.9383V25.0864H16.4074V22.321ZM16.4074 16.7901H21.9383V19.5555H16.4074V16.7901Z" fill="black"/>
    {/* eslint-disable-next-line max-len */}
    <path d="M23.2222 0H3.66667C2.14568 0 1 1.14568 1 2.66667V22.2222C1 23.7432 2.14568 24.8889 3.66667 24.8889H5.44444V22.2222H4.66667C4.11438 22.2222 3.66667 21.7745 3.66667 21.2222V3.66667C3.66667 3.11438 4.11438 2.66667 4.66667 2.66667H22.2222C22.7745 2.66667 23.2222 3.11438 23.2222 3.66667V4.44444H25.8889V2.66667C25.8889 1.14568 24.7432 0 23.2222 0Z" fill="black"/>
  </svg>,
  path:             <svg
    className={'inlineSVG dark'}
    width={32}
    height={32}
    viewBox={ '0 0 32 32'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* eslint-disable-next-line max-len */}
    <path d="M20.5128 3H2.46154C1.05755 3 0 4.05755 0 5.46154V23.5128C0 24.9168 1.05755 25.9744 2.46154 25.9744H4.10256V23.5128H3.46154C2.90925 23.5128 2.46154 23.0651 2.46154 22.5128V6.46154C2.46154 5.90925 2.90925 5.46154 3.46154 5.46154H19.5128C20.0651 5.46154 20.5128 5.90925 20.5128 6.46154V7.10256H22.9744V5.46154C22.9744 4.05755 21.9168 3 20.5128 3Z" fill="black"/>
    {/* eslint-disable-next-line max-len */}
    <path fillRule="evenodd" clipRule="evenodd" d="M7.3846 9.56404C8.29572 9.56404 9.09122 10.0591 9.51683 10.7948H22.4849C25.022 10.7948 27.0769 12.9979 27.0769 15.7179C27.0769 18.4379 25.022 20.641 22.4849 20.641H14.449C13.1862 20.641 12.153 21.7487 12.153 23.1025C12.153 24.4563 13.1862 25.564 14.449 25.564H27.4062C27.8318 24.8283 28.6273 24.3333 29.5385 24.3333C30.8979 24.3333 32 25.4353 32 26.7948C32 28.1543 30.8979 29.2563 29.5385 29.2563C28.6273 29.2563 27.8318 28.7613 27.4062 28.0256H14.449C11.9119 28.0256 9.85704 25.8225 9.85704 23.1025C9.85704 20.3825 11.9119 18.1794 14.449 18.1794H22.4849C23.7477 18.1794 24.7809 17.0717 24.7809 15.7179C24.7809 14.364 23.7477 13.2563 22.4849 13.2563L9.51683 13.2563C9.09122 13.9921 8.29572 14.4871 7.3846 14.4871C6.02513 14.4871 4.92307 13.3851 4.92307 12.0256C4.92307 10.6661 6.02513 9.56404 7.3846 9.56404Z" fill="black"/>
  </svg>,
};

export const largeModals = [
  'tag',
  'remediation_plan',
  'scan_group',
];

// SECTION ----------------- Promise Helpers -------------------------- SECTION //
// takes an array of promises and returns an array of resolved promises in the same order, handled
// sequentially when Promise.all would put too much of a burst on the server
export const promiseAllSequential = async ( promises, options={} ) => {

  if ( isNotEmpty( options ) && isNotEmpty( options.chunkSize ) ) {
    const chunks = [];

    for ( let i = 0; i < promises.length; i += options.chunkSize ) {
      const chunk = promises.slice( i, i + options.chunkSize );
      chunks.push( chunk );
    }
    let results = [];

    for ( let index = 0; index < chunks.length; index++ ) {

      const chunk = chunks[index];

      const slicedResults = await Promise.all( chunk.map( fn => fn() ) );

      results = [
        ...results,
        ...slicedResults,
      ];
    }
    return results;
  }

  const resolvedPromises = [];
  return promises.reduce( ( p, item, index ) => {
    return p.then( () => {
      return promises[index]( item ).then( response => {
        resolvedPromises.push( response );
        return resolvedPromises;
      } );
    } );
  }, Promise.resolve() );

};

// reusable way of selecting explore items
export const selectExploreItems =  async ( type, items ) => {
  const hash = decodeURLHash();

  const existingSelected = hash[`explore_${type}`];

  let _selectedIDs = [];

  if ( existingSelected ) {
    _selectedIDs = JSON.parse( existingSelected );
    _selectedIDs = [ ..._selectedIDs, ...items.map( i => i.id ) ];
  } else {
    _selectedIDs = items.map( i => i.id );
  }
  encodeURLHash( { [`explore_${type}`]: JSON.stringify( _selectedIDs ) } );
  triggerHashRefresh();
};

// SECTION ----------------- Global Settings -------------------------- SECTION //

// gets either the global or project settings and strips off just the results
export const getGlobalSettings = async( type ) => {
  let response;

  if ( type === 'global' ) {
    response = await makeRequest( 'INDEX', '/global_setting', {} );
  } else {
    response = await makeRequest( 'FETCH', '/project/default', {} );
    window.localStorage.setItem( 'targetRisk', response.results.settings.risk_target );
  }
  return response.results;
};

// takes the type "project" or "global" and the data as an obj. of format: { attr: 'value };
export const updateGlobalSettings = async( type, data ) => {
  if ( type === 'global' ) {
    const records = [];

    // put the attrs and values into the correct spot
    Object.entries( data ).map( ( [ attr, val ] ) => {
      if ( val !== undefined ) {
        records.push( { key: attr, value: val } );
      }
    } );
    await makeRequest( 'UPSERT', '/global_setting', { records, key: [ 'key' ] } );

    // the global_setting endpoint only returns the settings you just updated... not ideal,
    const response = await makeRequest( 'INDEX', '/global_setting', {} );
    return response;
  }

  // a lot of the project setting data is included in a nested 'settings' obj.
  // need to preseve the existing settings, and only update the new values.
  if ( type === 'project' ) {
    const _project = await getGlobalSettings( 'project' );
    Object.entries( data ).map( ( [ attr, val ] ) => {
      const _settings = { ..._project.settings };
      // special logic for nested settings
      if ( attr === 'settings' && ( val !== null && val !== undefined ) ) {
        Object.entries( val ).map( ( [ settingsAttr, settingsVal ] ) => {
          _settings[settingsAttr] = settingsVal;
        } );

        _project.settings =_settings;
      } else if ( val !== null && val !== undefined ) {
        _project[attr] = val;
      }
    } );

    const updated = await makeRequest( 'UPSERT', '/project/default', { records: [ _project ] } );
    return updated;
  }
};

// SECTION ----------------- Integrations -------------------------- SECTION //

// gets active Integrations that are available to use for this interface ( ie: jira, serviceNow, etc. )
export const getActiveIntegrations = async () => {
  /* eslint-disable camelcase */
  const activeIntegrations = { };
  const integrations = [ ];
  const credentialIDs = [ ];
  let credResponse = { results: [] };

  const thirdPartyResponse = await makeRequest( 'SEARCH', '/project/default/third_party_setting', {
    extra_columns: [ 'tool', 'settings', 'category' ],
    order_by: [ [ 'tool', 'ASC' ] ],
  } );

  // look at the thirdPartyResponse and see if any have the category of 'ticketing_software', ignore all the others
  if ( isNotEmpty( thirdPartyResponse ) && isNotEmpty( thirdPartyResponse.results ) ) {
    thirdPartyResponse.results.map( tp => {
      if ( tp.category === 'ticketing_software' ) {
        integrations.push( tp );
        if ( tp.settings && tp.settings.credential_id ) {
          credentialIDs.push( tp.settings.credential_id );
        }
      }
    } );
  }

  // if any of type 'ticketing_software' were found, grab all the corresponding credentials for each
  // for integrations that are email type, we will handle those later
  if ( isNotEmpty( credentialIDs ) ) {
    credResponse = await makeRequest( 'SEARCH', '/project/default/credential', {
      extra_columns: [
        'username',
        'label',
        'domain',
      ],
      id_list: credentialIDs,
      order_by: [ [ 'protocol', 'ASC' ] ],
    } );
  }

  // go through each integration and add all the settings from the credential, etc.
  integrations.map( integration => {

    const settings = { ...integration.settings };

    // this integration has an associated credential that needs to be added (jira for now).
    if ( isNotEmpty( integration.settings?.credential_id ) ) {
      const credential = credResponse?.results?.find( c => c.id === integration.settings.credential_id );

      // spread in the credential settings
      if ( isNotEmpty( credential ) ) {
        settings.label =  credential.label;
        settings.credential_id = credential.id;
        settings.username = credential.username;
        settings.domain = credential.domain;
      }
    }

    if ( isNotEmpty( activeIntegrations[integration.tool] ) ) {
      // eslint-disable-next-line max-len
      activeIntegrations[integration.tool].push( { ...integration, ...settings } );
    } else {
      // eslint-disable-next-line max-len
      activeIntegrations[integration.tool] = [ { ...integration, ...settings } ];
    }
  } );


  return activeIntegrations;
  /* eslint-enable camelcase */
};

// SECTION ----------------- External Users ------------------------- SECTION //

// gets active Integrations that are available to use for this interface ( ie: jira, serviceNow, etc. )
export const getExternalUsers = async ( thirdPartySettingID=null, callback=() => {}, callbackOptions=[] ) => {

  const searchParams = { rownums: [ 0, 100 ] };

  if ( isNotEmpty( thirdPartySettingID ) ) {
    // eslint-disable-next-line camelcase
    searchParams.third_party_setting_id = thirdPartySettingID;
  }

  // first lets see if we already have external users
  const externalUsersResponse = await makeRequest( 'SEARCH', '/model/base/external_users', searchParams );

  // we do not alreay have external users, we will need to poll for them
  if ( isEmpty( externalUsersResponse.results ) ) {
    // check in on the job, see if it is running
    const syncResponse = await makeRequest( 'COMPUTE', '/model/base/sync_external_users_job', {} );

    // successfully kicked off the sync, now we need to poll the endpoint until the job is ready
    if ( syncResponse.status === 'ok' ) {
      // this will run until it finishes
      pollForExternalUsersAndCallback( callback, callbackOptions );
    // the sync failed for some reason, we need to alert the user
    } else {
      console.log( 'error starting job' );
    }
  // we already have external users, no polling for the job is necessary, just need to format them and then
  // pass them along to any callback that may have been passed in
  } else {
    formatExternalUsersAndCallback( callback, callbackOptions, searchParams );
  }
};

// if the job is done, format the external users and then fire the callback
export const formatExternalUsersAndCallback = async ( callback, callbackOptions, searchParams={} ) => {
  const externalUsersResponse = await makeRequest( 'SEARCH', '/model/base/external_users', searchParams );

  const _externalUsers = {};

  externalUsersResponse?.results?.map( user => {
    _externalUsers[user.id] = user;
  } );

  callback( _externalUsers, ...callbackOptions );
};

// recursive poll function for grabbing the externalUsers, when finished, callback is fired
export const pollForExternalUsersAndCallback = async ( callback, callbackOptions ) => {
  // see if the job is done
  const pollRequest = await makeRequest( 'STATUS', '/model/base/sync_external_users_job' );

  // job is done, get the ds users and move onto the next page
  if ( pollRequest.msg && pollRequest.msg === 'Sync job not running' ) {
    formatExternalUsersAndCallback( callback, callbackOptions );
  } else {
    setTimeout( () => {
      pollForExternalUsersAndCallback( callback, callbackOptions );
    }, 2000 );
  }
};

// SECTION ----------------- formatters ------------------------------- SECTION //
export const formatUnixTime = ( t, includeSeconds, tzoffset, tzname, timeSpacer=':' ) => {
  let _offset = tzoffset;

  if( ( tzoffset === null ) || ( tzoffset === undefined ) ) {
    _offset = 0 - ( ( new Date() ).getTimezoneOffset() * 60 );
  }

  const a = new Date( ( t + _offset ) * 1000 );
  const year = a.getUTCFullYear();
  const month = '0' + ( a.getUTCMonth()+1 );
  const day = '0' + a.getUTCDate();
  const hour = '0' + a.getUTCHours();
  const min = '0' + a.getUTCMinutes();
  const sec = '0' + a.getUTCSeconds();

  let computed = year
    + '-'
    + month.substr( month.length-2 )
    + '-'
    + day.substr( day.length-2 )
    + `${timeSpacer === '-' ? '-' : ' '}`
    + hour.substr( hour.length-2 )
    + timeSpacer
    + min.substr( min.length-2 );

  if ( includeSeconds ) {
    computed += ':' + sec.substr( sec.length-2 );
  }

  if( tzname ) {
    computed += ' ('+tzname+')';
  }

  return computed;
};

export const formatTimeDuration = t => {
  let hours = parseInt( t / 3600 );
  let minutes = parseInt( ( t % 3600 ) / 60 );
  let seconds = parseInt( t % 60 );

  hours = '0' + hours;
  minutes = '0' + minutes;
  seconds = '0' + seconds;

  return hours.substr( hours.length-2 )
    + ':'
    + minutes.substr( minutes.length-2 )
    + ':'
    + seconds.substr( seconds.length-2 );
};

export const formatUnixDate = date => {
  const a = new Date( date * 1000.0 );
  const year = a.getUTCFullYear();
  const month = '0' + ( a.getUTCMonth() + 1 );
  const day = '0' + a.getUTCDate();

  return  `${year}-${month.substr( month.length - 2 )}-${day.substr( day.length - 2 )}`;
};

export const formatTZOffset = offset => {
  const _offset = `${ offset / ( 60 * 60 ) }`;

  if ( _offset?.startsWith( '-' ) ) {
    return _offset;
  }
  return `+${_offset}`;
};

export const getCurrentPercentile = async () => {

  const response = await makeRequest(
    'FETCH',
    '/analysis/industry_percentile_history',
    { project: 'default', model: 'base' },
  );

  if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
    const _currentPercentile = response.results[ response.results.length - 1 ];
    return _currentPercentile;
  }
  return null;
};

export const getRiskClass = ( global, target ) => {
  const ratio = global / target;

  if ( ratio < 1 ) {
    return 'minimal';
  } else if ( ratio < 1.5 ) {
    return 'low';
  } else if ( ratio < 1.75 ) {
    return 'moderate';
  } else if ( ratio < 2 ) {
    return 'high';
  }
  return 'critical';
};

export const getPercentileClass = percentile => {
  if ( percentile <= 0.25 ) {
    return 'high';
  } else if ( percentile <= 0.5 ) {
    return 'low';
  } else if ( percentile <= 0.75 ) {
    return 'minimal';
  }
  return 'primary';
};

// takes a risk category and returns the appropriate color (used in charts)
export const riskColorMap = {
  critical: globalColors['critical'], // darkRed
  high: globalColors['high'], // red
  moderate: globalColors['moderate'], // orange
  low: globalColors['low'], // yellow
  minimal: globalColors['minimal'], // green
  unknown: globalColors['grey--icon'],
};

// risk reduction formatted as string, this will almost always read the globalRisk out of localStorage,
// which is populated by the global contex, howerver on print reports, it will need to be passed in
// because the headless browser will not have the localStorage or context to look at
export const formatRiskReduction = ( risk, globalRisk=null ) => {

  let displayValue = 'N/A';

  let _globalRisk;

  // global risk was passed in, set it to that instead (print report)
  if ( isNotEmpty( globalRisk ) ) {
    _globalRisk = globalRisk;
  // should be in the localStorage otherwise
  } else {
    _globalRisk = window.localStorage.getItem( 'globalRisk' );
  }

  // early return if global is somehow 0
  if ( _globalRisk === 0 || !_globalRisk ) {
    console.log( 'Global Risk is 0' );
    return displayValue;
  }

  // early return if risk is 0
  if ( risk === 0 || !risk ) {
    return '0%';
  }

  const riskRatio = risk / _globalRisk;
  const percent = riskRatio * 100;

  if ( percent < 0.005 ) {
    displayValue = '< 0.01%';
  } else if ( percent < 10.0 ) {
    displayValue = `${percent.toFixed( 2 )}%`;
  } else if ( percent > 100 ) {
    displayValue = '100%';
  } else {
    displayValue = `${percent.toFixed( 2 )}%`;
  }
  return displayValue;
};

export const riskToRating = ( risk, targetRisk=null ) => {
  let denominator, rating;

  const getRating = ( risk, denominator ) => {
    const ratio = risk / denominator;
    let rating = 'minimal';


    if ( ratio >= 0.32 ) {
      rating = 'critical';
    }
    if ( ratio < 0.32 ) {
      rating = 'high';
    }
    if ( ratio < 0.16 ) {
      rating = 'moderate';
    }
    if ( ratio < 0.08 ) {
      rating = 'low';
    }
    if ( ratio < 0.04 ) {
      rating = 'minimal';
    }
    return rating;
  };

  if ( targetRisk ) {
    denominator = parseFloat( targetRisk );
    rating = getRating( risk, denominator );
    return rating;
  } else if ( window.localStorage.getItem( 'targetRisk' ) ) {
    denominator = parseFloat( window.localStorage.getItem( 'targetRisk' ) );
    rating = getRating( risk, denominator );
    return rating;
  }
  console.log( 'Target Risk is 0' );
  return 'minimal';
};

// takes a risk and returns the correct color based on target risk
export const svgColorForRisk = ( risk, targetRisk=null ) => {
  let denominator;

  if ( targetRisk ) {
    denominator = parseFloat( targetRisk );
  } else if ( window.localStorage.getItem( 'targetRisk' ) ) {
    denominator = parseFloat( window.localStorage.getItem( 'targetRisk' ) );
  } else {
    denominator = 1;
  }

  const ratio = risk / denominator;

  let color = '#BC2220'; // critical (darkRed)
  if ( ratio < 0.04 ) {
    color = '#2ED47A'; // minimal (green)
  } else if ( ratio < 0.08 ) {
    color = '#FFB800'; // low (yellow)
  } else if ( ratio < 0.16 ) {
    color = '#FF8A00'; // moderate (orange)
  } else if ( ratio < 0.32 ) {
    color = '#EF3535'; // high (red)
  }
  return color;
};

export const cvssToRating = rating => {
  let colorClassName = 'critical';

  const minMax = {
    critical: {
      maximum: 10,
      minimum: 9,
    },
    high: {
      maximum: 9,
      minimum: 7.5,
    },
    moderate: {
      maximum: 7.5,
      minimum: 6,
    },
    low: {
      maximum: 6,
      minimum: 3,
    },
    minimal: {
      maximum: 3,
      minimum: 0,
    },
  };

  if ( rating < minMax.minimal.maximum ) {
    colorClassName = 'minimal'; // minimal (green)
  } else if ( rating < minMax.low.maximum ) {
    colorClassName = 'low'; // low (yellow)
  } else if ( rating < minMax.moderate.maximum ) {
    colorClassName = 'moderate'; // moderate (orange)
  } else if ( rating < minMax.high.maximum ) {
    colorClassName = 'high'; // high (red)
  }

  return colorClassName;
};

export const generatePalette = numberOfColors => {
  // Create an array to store the colors.
  const colors = [];

  // Loop through the number of colors and generate a random color for each one.
  for ( let i = 0; i < numberOfColors; i++ ) {
    const color = '#' + Math.random().toString( 16 ).substring( 2, 8 );
    colors.push( color );
  }

  // Return the array of colors.
  return colors;
};

export const userDisplayName = ( user, underscored=false, externalSource=null ) => {
  if ( isNotEmpty( externalSource ) ) {
    if ( externalSource === 'jira' ) {
      if ( isNotEmpty( user.display_name ) ) {
        return user.display_name;
      }
      return user.identifier;
    }
  }

  if ( isNotEmpty( user.given_name ) && isNotEmpty( user.family_name ) ) {
    if ( underscored ) {
      return `${user.given_name}_${user.family_name}`;
    }
    return `${user.given_name} ${user.family_name}`;

  }
  return user.username;
};

export const reportTypeIcon = {
  host: <InlineSVG elementClass="reportTypeIcon" type="hostsAlt" />,
  hosts: <InlineSVG elementClass="reportTypeIcon" type="hostsAlt" />,
  scope: <InlineSVG elementClass="reportTypeIcon" type="hostsAlt" />,
  scopes: <InlineSVG elementClass="reportTypeIcon" type="hostsAlt" />,
  patch: <InlineSVG elementClass="reportTypeIcon" type="patchesAlt" />,
  patches: <InlineSVG elementClass="reportTypeIcon" type="patchesAlt" />,
  vulnerability: <InlineSVG elementClass="reportTypeIcon" type="vulnerabilitiesAlt" />,
  vulnerabilities: <InlineSVG elementClass="reportTypeIcon" type="vulnerabilitiesAlt" />,
  user: <InlineSVG elementClass="reportTypeIcon" type="domainUser" />,
  users: <InlineSVG elementClass="reportTypeIcon" type="domainUser" />,
};

export const reportTypeDisplayName = ( item, reportType, showSteps=false ) => {
  console.log( item );
  if ( reportType === 'host' || reportType === 'scope' || reportType === 'hosts' ) {
    return item.label || item.local_name;
  }

  if ( reportType === 'patch' || reportType === 'patches' || reportType === 'patch_cumulative' ) {
    return `${item.vendor} ${item.identifier}`;
  }

  if ( reportType === 'vulnerability' || reportType === 'vulnerabilities' ) {
    return item.identifier;
  }

  if ( reportType === 'path' || reportType === 'paths' ) {
    const [ , assetLabel ] = item.node_labels[item.node_labels.length - 1];
    const scopeLabel1 = item.full_path_label ? item.full_path_label.split( ' > ' )[0] : 'N/A';
    const scopeLabel2 = item.full_path_label ? item.full_path_label.split( ' > ' )[1] : 'N/A';
    return <div className="pathLabelWrapper reportTypeDisplayNameWrapper">
      <span>Path to</span>
      <strong>{ assetLabel }</strong>
      <span>-</span>
      <span className="scopeLabel">{scopeLabel2}</span>
      {
        !showSteps &&
        <span className="scopeLabel">({scopeLabel1})</span>
      }
      {
        showSteps &&
        <strong className="stepsLabel">({item.edges.length} steps)</strong>
      }
    </div>;
  }

  if ( reportType === 'user' || reportType === 'users' ) {
    return `${item.domain_name}\\${item.name}`;
  }
  if ( reportType === 'signature' || reportType === 'scanner_signature' || reportType === 'signatures' ) {
    return `${item.scanner} ${item.signature}`;
  }
};

export const credentialDisplayName = credential => {
  let name = '';
  let label = credential.label || '';

  if ( credential.domain ) {
    label += ` ${credential.domain}/${credential.username}`;
  } else {
    label += ` ${credential.username}`;
  }

  name = `${credential.protocol}: ${label}`;

  return name;
};

export const pluralizeItem = type => {
  const plurals = {
    host: 'hosts',
    patch: 'patches',
    vulnerability: 'vulnerabilities',
    user: 'users',
    path: 'paths',

    scanner: 'scanners',
    signature: 'signatures',
    tag: 'tags',
    report: 'reports',

    edge: 'edges',
    node: 'nodes',
    scope: 'scopes',

    hosts: 'hosts',
    patches: 'patches',
    vulnerabilities: 'vulnerabilities',
    users: 'users',
    paths: 'paths',

    scanners: 'scanners',
    signatures: 'signatures',
    tags: 'tags',
    reports: 'reports',

    edges: 'edges',
    nodes: 'nodes',
    scopes: 'scopes',
  };

  if ( isNotEmpty( plurals[type] ) ) {
    return plurals[type];
  }

  return `${type}s`;
};
// eslint-disable-next-line camelcase
export const instancesReportFilterLink = ( type, id, asset_tag_ids ) => {
  // eslint-disable-next-line max-len, camelcase
  return `#.=risk_insight&report=instances&item_count=100&sort_by=filtered_risk&sort_direction=DESC&current_page=1&${type}_ids=${encodeURIComponent( JSON.stringify( [ id ] ) )}&group_type=${type === 'host' ? 'patch' : 'host'}${ asset_tag_ids ? `&asset_tag_ids=${encodeURIComponent( JSON.stringify( asset_tag_ids ) )}` : ''}`;
};

export const formatNumber = num => {
  if ( isNotEmpty( num ) ) {
    return num.toLocaleString( 'en-US' );
    // return num.toString().replace( /\B(?=(\d{3})+(?!\d))/g, ',' );
  }
  return 0;
};

export const numberToOrdinal = float => {
  const asStringArray = `${Math.round( float * 100 )}`.split( '' );
  const lastDigit = asStringArray[ asStringArray.length - 1 ];

  if ( lastDigit === '1' ) {
    asStringArray.push( 'st' );
  } else if ( lastDigit === '2' ) {
    asStringArray.push( 'nd' );
  } else if ( lastDigit === '3' ){
    asStringArray.push( 'rd' );
  } else {
    asStringArray.push( 'th' );
  }

  return asStringArray.join( '' );
};

export const cvssScoreToRating = score => {
  if ( isNotEmpty( score ) ) {

    if ( score >= 9 ) {
      return 'critical';
    }
    if ( score >= 7.5 ) {
      return 'high';
    }
    if ( score >= '6' ) {
      return 'moderate';
    }
    if ( score >= 3 ) {
      return 'low';
    }
    return 'minimal';
  }
  return 'default';
};

// takes a underscored string and breaks it into captialized words
export const capitalize = string => {
  if ( isNotEmpty( string ) ) {
    const words = string.split( '_' );
    const capitalizedParts = [];
    for ( var part of words ) {
      capitalizedParts.push( part.charAt( 0 ).toUpperCase() + part.slice( 1 ) );
    }
    return capitalizedParts.join( ' ' );
  }

  return '';
};

// utility function for handling pluralization of records
export const pluralizeType = ( recordType, shouldCapitalize=false ) => {
  const pluralizationMap = {
    host:           'hosts',
    scope:          'scopes',
    patch:          'patches',
    vulnerability:  'vulnerabilities',
    node:           'nodes',
    edge:           'edges',
    segment:        'segment',
    escalation:     'escalations',
    path:           'paths',
    instance:       'Vulnerability Instances',
    scans:          'Vulnerability Instances',
    // eslint-disable-next-line camelcase
    sensitive_asset_policy: 'Sensitive Asset Policies',
  };

  const plural = pluralizationMap[recordType] || recordType;

  return shouldCapitalize ? capitalize( plural ) : plural;
};

// SECTION ----------------- params / hash / navigation ------------------------------- SECTION //
export const replaceURLHash = url => {
  window.location.href = url;
};

export const decodeURLHash = url => {

  const paramDelimiter = '&';
  const kvDelimiter = '=';

  if ( isEmpty( url ) ) {
    url = document.location.href;
  }

  let hash = '';

  if ( url.search( '#' ) > -1 ) {
    // eslint-disable-next-line
    hash = url.split( '#', 2 )[1];
  }

  const params = hash.split( paramDelimiter );

  var decodedHash = {};

  params.map( param => {
    const kv = param.split( kvDelimiter, 2 );

    const attrKey = decodeURIComponent( kv[0] );
    let attrVal = decodeURIComponent( kv[1] );
    if (
      isNotEmpty( attrKey )
      && isArrayType.includes( attrKey ) || ( attrVal?.startsWith( '[' ) && attrVal?.endsWith( ']' ) )
    ) {
      attrVal = JSON.parse( attrVal );
    }
    decodedHash[attrKey] = attrVal;
  } );

  return decodedHash;
};

export const serializeURLHash = params => {

  const paramDelimiter = '&';
  const kvDelimiter = '=';

  const paramsList = [];

  for ( var key in params ) {
    if ( isNotEmpty( key ) ) {

      let _val = params[key];
      if ( Array.isArray( _val ) ) {
        _val = JSON.stringify( _val );
      }

      // encode obj. values as search params if value is an obj. otherwise encode normally
      const encodedValue = itemIsObject( _val )
        ? encodeURIComponent( JSON.stringify( _val ) )
        : encodeURIComponent( _val );

      paramsList.push( `${encodeURIComponent( key )}${kvDelimiter}${encodedValue}` );
    }
  }
  return paramsList.join( paramDelimiter );
};

export const removeFromURLHash = key => {
  const oldParams = decodeURLHash();

  if ( isNotEmpty( oldParams[key] ) ) {
    delete oldParams[key];
    const serializedHash = serializeURLHash( oldParams );
    window.history.pushState( decodeURLHash(), null, `#${serializedHash}` );
  }
};

export const encodeURLHash = ( updates, replace=false ) => {
  const oldParams = decodeURLHash();

  const newParams = { ...oldParams, ...updates };

  Object.entries( newParams ).map( ( [ key, value ] ) => {
    if ( isEmpty( value ) && value !== 0 ) {
      delete newParams[key];
    }
  } );

  const newHash = `#${serializeURLHash( newParams )}`;

  if ( !isEqual( oldParams, newParams ) ) {
    if ( replace ) {
      window.history.replaceState( newParams, null, newHash );
    } else {
      window.history.pushState( newParams, null, newHash );
    }
  }
};

export const paramsToFilters = params => {

  let toTransform = {};

  if ( isNotEmpty( params ) ) {
    toTransform = params;
  } else {
    toTransform = decodeURLHash();
  }

  delete toTransform['.'];
  delete toTransform['page'];
  delete toTransform['report'];

  const filters = {};

  Object.entries( toTransform ).map( ( [ key, value ] ) => {
    // the risk_type param changed from 'direct' to 'direct_risk' this is a one-off check for that situation
    if ( key === 'risk_type' && value === 'direct' ) {
      filters[key] = 'direct_risk';
    } else {
      filters[key] = value;
    }
  } );

  return filters;
};

// deprecated, no longer used anywhere
export const filtersToParams = filters => {
  const tmpValues = {};

  Object.entries( filters ).map( ( [ key, value ] ) => {
    if ( isArrayType.includes( key ) ) {
      if ( isEmpty( value.value ) ) {
        tmpValues[key] = '';
      } else if ( Array.isArray( value.value ) ) {
        tmpValues[key] = JSON.stringify( value.value );
      } else {
        tmpValues[key] = JSON.stringify( [ value.value ] );
      }
    } else {
      tmpValues[key] = value.value;
    }
  } );
  encodeURLHash( tmpValues );
};

export const triggerHashRefresh = () => window.dispatchEvent( new HashChangeEvent( 'hashchange' ) );

export const parameterizeArray = ( key, arr ) => {
  arr = arr.map( encodeURIComponent );
  return '?'+key+'[]=' + arr.join( '&'+key+'[]=' );
};

// SECTION ----------------- localStorage helpers ------------------------------- SECTION //
export const useLocalStorage = ( key, initialValue ) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [ storedValue, setStoredValue ] = React.useState( () => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem( key );
      // Parse stored json or if none return initialValue
      return isNotEmpty( item ) ? JSON.parse( item ) : initialValue;
    } catch ( error ) {
      // If error also return initialValue
      return initialValue;
    }
  } );

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value( storedValue ) : value;
      // Save state
      setStoredValue( valueToStore );
      // Save to local storage
      window.localStorage.setItem( key, JSON.stringify( valueToStore ) );
    } catch ( error ) {
      // A more advanced implementation would handle the error case
      console.log( error );
    }
  };

  return [ storedValue, setValue ];
};

export const addToLocalStorage = ( newItem, storageKey, setter, limit=10 ) => {

  let _toAdd = {};

  if ( isNotEmpty( window.localStorage.getItem( storageKey ) ) ) {
    const existingItems = JSON.parse( window.localStorage.getItem( storageKey ) );

    // if there are already 20 items, remove the first item before adding the new one
    if ( Object.keys( existingItems ).length >= limit ) {
      delete existingItems[Object.keys( existingItems )[0]];
    }

    // if the item is already in the history, do nothing
    if ( isEmpty( existingItems[newItem.id] ) ) {
      _toAdd = { ...existingItems, [newItem.id]: { ...newItem } };
    } else {
      _toAdd = { ...existingItems };
    }

  } else {
    _toAdd = { [newItem.id]: { ...newItem } };
  }

  setter( _toAdd );
};

export const removeFromLocalStorage = ( itemKey, storageKey, setter ) => {
  if ( isNotEmpty( window.localStorage.getItem( storageKey ) ) ) {
    const existingItems = JSON.parse( window.localStorage.getItem( storageKey ) );

    // remove the item from the exsting ones
    if ( existingItems[itemKey] ) {
      delete existingItems[itemKey];
    }

    if ( isNotEmpty( existingItems ) ) {
      // and then reset the object
      setter( existingItems );
      window.localStorage.setItem( storageKey, JSON.stringify( existingItems ) );
    } else {
      // and then reset the object
      setter( {} );
      window.localStorage.setItem( storageKey, '' );
    }

  } else {
    setter( {} );
    window.localStorage.setItem( storageKey, '' );
  }
};

export const clearLocalStorage = ( storageKey, setter ) => {
  if ( window.confirm( 'Are you sure you want to clear all of your saved items?' ) ) {
    setter( {} );
    window.localStorage.setItem( storageKey, '' );
  }
};

export function sleep( ms ) {
  return new Promise( resolve => setTimeout( resolve, ms ) );
}

// debounces any input, use on any text-like inputs with onChange hooks
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. If there is ever a delay longer than the 'delay' arg, the passed in function will run.
export const debounce = ( callback, delay ) => {

  let timeout;

  // This is the function that is returned and will be executed many times
  return function executedFunction( ...args ) {

    // if there is ever a delay longer than what is passed in, this will finally get called
    const executeCallback = () => {
      // null timeout to indicate the debounce ended
      timeout = null;

      // Execute the callback
      callback( ...args );
    };

    // This will reset the waiting every function execution. This is the step that prevents the function from
    // being executed because it will never reach the inside of the previous setTimeout
    clearTimeout( timeout );

    // Restart the debounce delay period.
    timeout = setTimeout( executeCallback, delay );
  };
};

export const copyToClipBoard = text => {
  navigator.clipboard.writeText( text );
};

export const getKeyByValue = ( object, value ) => {
  const _key = Object.keys( object ).find( key => isEqual( object[key], value ) );
  return _key;
};

export const elementRiskClass = risk => {
  // early return for edge cases
  if ( isEmpty( risk ) || risk === 'nofix' ) {
    return 'default';
  }

  if ( risk < 0.05 ) {
    return 'primary';
  }
  if ( risk < 0.25 ) {
    return 'risk--green';
  }
  if ( risk < 0.75 ) {
    return 'risk--yellow';
  }
  if ( risk < 0.95 ) {
    return 'risk--orange';
  }
  return 'risk--red';
};

export const getDimensionsAndOffset = ( el, container=null ) => {

  if ( el ) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + ( isNotEmpty( container ) ? container.scrollTop : window.scrollY ),
      width: rect.width,
      height: rect.height,
    };
  }
  return {
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  };

};

const normalPercentile = ( percentile, func, stddev, x ) => {
  return qnormal( percentile, func( x ), stddev );
};

// main math function for calculating the regression lines, takes 3 args
//   referenceData is the entire json blob that comes back on init
//   regressionLine is a num 0, 1, or 2 where 0 is the uppermost line, 1 is the middle,
//   and 3 is the lowest
//   x is a num
//   function returns the y value for the line of the given point 'x'
export const getYForX = ( referenceData, regressionLine, x, targetPercentile=4.0 ) => {
  let y = 0;
  if ( isNotEmpty( referenceData ) ) {
    // referenceData = {'regression_params':params, 'normal_stddev':stddev}
    const percentile = 1 / targetPercentile;
    const middle = x => referenceData.regression_params.a * Math.pow( x, 2 ) + referenceData.regression_params.b * x;
    // eslint-disable-next-line camelcase
    const middle_normal = x => Math.log( middle( x ) );
    // eslint-disable-next-line
    const normal_stddev = referenceData.normal_stddev;

    // upper line
    if ( regressionLine === 0 ) {
      // eslint-disable-next-line camelcase, max-len
      const upper_normal = normalPercentile.bind( null, 1 - percentile,  middle_normal, normal_stddev );
      const upper = x => Math.exp( upper_normal( x ) );
      y = upper( x );

    // middle line
    } else if ( regressionLine === 1 ) {
      y = middle( x );

    // lower line
    } else {
      // eslint-disable-next-line camelcase
      const lower_normal = normalPercentile.bind( null, percentile, middle_normal, normal_stddev );
      const lower = x => Math.exp( lower_normal( x ) );
      y = lower( x );
    }
  }
  return y;
};

export const generateAPICredentials = ( skipConfirmation=false ) => {

  let secret = '';
  let secret32 = new Uint32Array( 4 );

  secret32 = window.crypto.getRandomValues( secret32 );

  const generatedKeys = () => {
    for ( let keySegment = 0; keySegment < secret32.length; keySegment++ ) {
      secret += secret32[keySegment].toString( 32 );
    }
    return { key: uuidv4(), secret };
  };

  if ( skipConfirmation ) {
    return generatedKeys();
  }
  if (
    // eslint-disable-next-line max-len
    window.confirm( 'Your existing API Credentials will no longer work once new credentials have been created. Are you sure you want to generate new API Credentials?' )
  ) {
    return generatedKeys();
  }
  return { key: '', secret: '' };


};

export function generateSecureRandomCode( length, charset='default' ) {
  const dictionaries = {
    default:  'abcdefghijkmnopqrstwxyzABCDEFGHJKLMNPQRSTUVWXYZ12345679!@#$%*()/',
    hex:      '0123456789abcdef',
    alphanumeric: 'abcdefghijkmnopqrstwxyzABCDEFGHJKLMNPQRSTUVWXYZ12345679',
  };

  const dictionary = dictionaries[charset];
  const numbers = new Uint8Array( length );
  window.crypto.getRandomValues( numbers ); // secure random

  let build = '';

  for ( var i = 0; i < numbers.length; i++ ) {
    // scale random numbers down
    // don't use % because then there is bias
    build += dictionary[Math.floor( numbers[i] * ( dictionary.length / 256 ) )];
  }

  return build;
}

export async function getBranchVersion() {
  const info = ( await makeRequest( 'FETCH', '/about', {} ) )['results'];
  const branchRegex = /[^-]*/;

  const branchVersion = info ? info['version'].match( branchRegex )[0] : '';
  return branchVersion;
}

export const checkBranchVersion = async () => {
  let _branchVersion = window.localStorage.getItem( 'branchVersion' );

  if ( isEmpty( _branchVersion ) ) {
    const info = ( await makeRequest( 'FETCH', '/about', {} ) )['results'];
    const branchRegex = /[^-]*/;

    _branchVersion = info ? info['version'].match( branchRegex )[0] : '';
  }

  window.localStorage.setItem( 'branchVersion', _branchVersion );
  return _branchVersion;
};

export const checkDocsToken = async () => {
  const now = Math.floor( Date.now() / 1000 );

  let _docsToken = window.localStorage.getItem( 'docsToken' );
  let _docsTokenLast = window.localStorage.getItem( 'docsTokenLast' );

  // any of these reasons would result in needing to generate a new token
  if (
    isEmpty( _docsToken )
    || _docsToken === 'error'
    || isEmpty( _docsTokenLast )
    || ( now > _docsTokenLast + ( 15 * 60 ) )
  ) {
    const response = await makeRequest( 'COMPUTE', '/profile', { type: 'docs_token' } );
    if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
      _docsToken = response.results;
    } else {
      _docsToken = 'error';
    }
    _docsTokenLast = now;
  }

  window.localStorage.setItem( 'docsToken', _docsToken );
  window.localStorage.setItem( 'docsTokenLast', _docsTokenLast );

  return _docsToken;
};

export const focusForOnboarding = element => {
  const currentPageParams = decodeURLHash();
  if ( currentPageParams.onboarding_step ) {
    if ( element ) {
      if ( !element.classList.contains( 'onboardingWizardFocus' ) ) {
        element.classList.add( 'onboardingWizardFocus' );
      }
    }
  }
};

export const unFocusForOnboarding = element => {
  const currentPageParams = decodeURLHash();
  if ( currentPageParams.onboarding_step ) {
    if ( element ) {
      if ( element.classList.contains( 'onboardingWizardFocus' ) ) {
        element.classList.remove( 'onboardingWizardFocus' );
        removeFromURLHash( 'onboarding_step' );
      }
    }
  }
};

// takes the latest 2 risk points and returns the necessary content to display the trend and description
export const getRiskTrend = ( current, all, target ) => {
  let previousPoint;

  const returnObj = {};

  if ( isNotEmpty( all ) && isNotEmpty( current ) && isNotEmpty( target ) ) {

    let previousIndex;

    if ( isNotEmpty( current.originalIndex ) ) {
      previousIndex = current.originalIndex - 1;
    } else if ( isNotEmpty( current.original?.originalIndex ) ) {
      previousIndex = current.original.originalIndex - 1;
    }
    previousPoint = Object.values( all )[ previousIndex ];
  }

  if ( isNotEmpty( previousPoint ) ) {
    const _delta = Math.round( current.original.risk - previousPoint.risk );
    const description = [];
    if ( isNotEmpty( current ) && isNotEmpty( current.original ) ) {
      returnObj.currentRisk = Math.round( current.original.risk );
      returnObj.chartColor = globalRiskColor( Math.round( current.original.risk ), target );
      description.push(
        <span>Your risk score of <strong>{ formatNumber( Math.round( current.original.risk ) ) }</strong></span>,
      );
    }

    if ( _delta === 0 ) {
      returnObj.delta = 0;
      returnObj.deltaDirection = 'flat';
      returnObj.deltaPercent = '0.0%';
      description.push(
        <span key={0} > has been flat and had very little change recently.</span>,
      );
    } else {
      const percent = ( ( Math.abs( _delta ) / current.original.risk ) * 100 ).toFixed( 1 );

      returnObj.delta = Math.abs( _delta );
      returnObj.deltaDirection = _delta < 0 ? 'down' : 'up';
      returnObj.deltaPercent = `${percent}%`;

      if ( _delta < 0 ) {
        description.push(
          // eslint-disable-next-line max-len
          <span key={1} > has dropped <strong>{formatNumber( Math.abs( _delta ) )}</strong> points, representing a {percent}% drop since the last data point.</span>,
        );
      } else {
        description.push(
          // eslint-disable-next-line max-len
          <span key={1} > has increased <strong>{formatNumber( Math.abs( _delta ) )}</strong> points, representing a {percent}% increase since the last data point.</span>,
        );
      }
      returnObj.riskDescription = description;
    }
  } else {
    returnObj.delta = 0;
    returnObj.deltaDirection = 'flat';
    returnObj.deltaPercent = '0.0%';
  }
  return returnObj;
};

// help hack to see if it is loaded or not
export const helpIsLoaded = filePathMap => isNotEmpty( filePathMap )
  && isNotEmpty( filePathMap.user_guide )
  && isNotEmpty( filePathMap.user_guide.setup )
  && isNotEmpty( filePathMap.user_guide.setup.users )
  && isNotEmpty( filePathMap.user_guide.setup.users.items );

// try to only do sorting on the server side, only use if that is not possible
export const recordSorter = ( field, reverse, a, b ) => {
  if ( reverse ) {
    if ( a[field] > b[field] ) {
      return 1;
    }

    if ( a[field] < b[field] ) {
      return -1;
    }

  } else {
    if ( a[field] < b[field] ) {
      return 1;
    }

    if ( a[field] > b[field] ) {
      return -1;
    }

    // Makes sorting order stable even if fields are equal
    if( a.id > b.id ) {
      return -1;
    }

    if( a.id < b.id ) {
      return 1;
    }
  }

  return 0;
};

// helpful function for deduping an array of objects
export const uniqueArray = nonUniqueInput => {

  if ( !Array.isArray( nonUniqueInput ) ) {
    return nonUniqueInput;
  }

  let dedupedArray = [];

  let deduped = new Set();

  if ( isNotEmpty( nonUniqueInput ) ) {
    nonUniqueInput.map( i => {
      if ( isNotEmpty( i ) ) {
        deduped.add( JSON.stringify( i ) );
      }
    } );

    deduped = Array.from( deduped );
    dedupedArray = deduped.map( JSON.parse );
  } else {
    dedupedArray = [];
  }

  return dedupedArray;
};

// helper to send a payload to show up in the server logs for easier client debugging
export const logToServer = payload => {
  makeRequest( 'DEBUG', '/front_end_reports', { debug: payload } );
};
