/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { capitalize, isNotEmpty } from '../../../../../shared/Utilities';
import { multipleOrderByOptions, paramsMap } from '../../shared';
import { getRecord, getRecords } from '../../../../../shared/RecordCache';
import RecordCard from '../../../../Explore/RecordCard';

import './TopRecordDetails.scss';

const TopRecordDetails = ( { prefetchedData, settings } ) => {

  const keyToAttrMap = {
    hosts: 'num_hosts',
    vulnerabilities: 'num_vulnerabilities',
    cvss: 'cvss_base_score',
    exploit: 'exploit_status',
  };

  const reportTypeFetchKeys = {
    hosts: 'HOST',
    patches: 'PATCH',
    vulnerabilities: 'VULNERABILITY',
  };

  const specificReportTypeFetchKeys = {
    hosts: 'host',
    patches: 'patch',
    vulnerabilities: 'vulnerability',
  };

  const reportTypeToCardMap = {
    hosts: 'scope',
    patches: 'patch',
    vulnerabilities: 'vulnerability',
  };

  const [ records, setRecords ] = React.useState( null );

  const fetchRecords = async ( reportType='hosts', orderBy='filtered_risk', itemCount=3 ) => {

    let riskType = 'filtered_risk';
    if ( reportType === 'patches' ) {
      riskType = 'risk';
    }
    // eslint-disable-next-line camelcase
    let order_by = [
      [ riskType, 'DESC' ],
      [ 'id', 'DESC' ],
    ];

    if ( multipleOrderByOptions.includes( orderBy ) ) {

      const [ primaryKey, secondaryKey ] = orderBy.split( '_' );

      // eslint-disable-next-line camelcase
      order_by = [
        [ keyToAttrMap[primaryKey], 'DESC' ],
        [ keyToAttrMap[secondaryKey], 'DESC' ],
        [ 'id', 'DESC' ],
      ];
    } else {
      let _orderBy = orderBy;
      if ( reportType === 'patches' && orderBy === 'filtered_risk' ) {
        _orderBy = 'risk';
      }

      // eslint-disable-next-line camelcase
      order_by = [
        [ _orderBy, 'DESC' ],
        [ 'id', 'DESC' ],
      ];
    }

    const _records = await getRecords(
      reportTypeFetchKeys[reportType],
      {
        ...paramsMap.table[reportType],
        // eslint-disable-next-line camelcase
        order_by,
        // eslint-disable-next-line camelcase
        rownums: [ 0, itemCount ],
      },
      true,
    );

    if ( isNotEmpty( _records ) ) {
      const ids = _records.map( r => r.id );

      if ( isNotEmpty( ids ) ) {

        const promises = [];

        ids.map( id => {
          promises.push( getRecord( specificReportTypeFetchKeys[reportType], id, {}, true ) );
        } );

        if ( isNotEmpty( promises ) ) {
          const _recordsWithDetails = await Promise.all( promises );

          if ( isNotEmpty( _recordsWithDetails ) ) {
            setRecords( _recordsWithDetails );
          } else {
            setRecords( null );
          }
        }
      }
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( settings ) ) {
      // eslint-disable-next-line camelcase
      let report_type = 'hosts';
      // eslint-disable-next-line camelcase
      let order_by = 'filtered_risk';
      // eslint-disable-next-line camelcase
      let item_count = 3;

      if ( 'report_type' in settings ) {
        // eslint-disable-next-line camelcase
        ( { report_type } = settings );
      }
      if ( 'order_by' in settings ) {
        // eslint-disable-next-line camelcase
        ( { order_by } = settings );
      }
      if ( 'item_count' in settings ) {
        // eslint-disable-next-line camelcase
        ( { item_count } = settings );
      }

      fetchRecords( report_type, order_by, item_count );
    } else {
      fetchRecords( 'hosts', 'filtered_risk', 3 );
    }
  }, [ settings, prefetchedData ] );

  const orderByToWords = orderBy => {
    switch ( orderBy ) {
    case 'filtered_risk':
      return 'risk score';
    case 'cvss_base_score':
      return 'CVSS score';
    case 'exploit_status':
      return 'exploit status';
    case 'num_hosts':
      return 'number of affected hosts';
    case 'num_vulnerabilities':
      return 'amount of vulnerabilities';
    case 'cvss_exploit':
      return 'CVSS score and then exploit status';
    case 'cvss_hosts':
      return 'CVSS score and then number of affected hosts';
    case 'exploit_cvss':
      return 'exploit status and then CVSS score';
    case 'exploit_hosts':
      return 'exploit status and then number of hosts';
    case 'hosts_cvss':
      return 'number of hosts and then CVSS score';
    case 'hosts_exploit':
      return 'number of hosts and then exploit status';
    default:
      return 'risk score';
    }
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( records ) &&
        <div
          // eslint-disable-next-line max-len
          className={ `topRecordDetailsWrapper itemCount--${settings?.item_count} ${ settings?.orientation === 'horizontal' ? 'horizontal' : 'vertical'}`}
        >
          {
            isNotEmpty( settings ) &&
            <span className="recordsCountHeader">
              <strong>Top { settings?.item_count } { capitalize( settings?.report_type ) }: </strong>
              <span>sorted by { orderByToWords( settings?.order_by ) }</span>
            </span>
          }
          {
            records.map( ( record, index ) => {
              let _record = { ...record };
              if ( settings?.report_type === 'hosts' ) {
                _record = { ..._record, type: 'host' };
              }
              return <RecordCard
                key={index}
                record={_record}
                type={ reportTypeToCardMap[settings?.report_type] }
                options={ {
                  includeRisk:  settings?.include_risk,
                  includeInstances: settings?.include_instances,
                  includeCVSS: settings?.include_cvss,
                } }
                show={true}
                context="dashboard"
              />;
            } )
          }
        </div>

      }
    </React.Fragment>
  );
};

export default TopRecordDetails;