/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../shared/Utilities';
import InlineSVG from '../../../../shared/InlineSVG';
import RatingBadge from '../../../../shared/RatingBadge';
import { edgeRiskClass } from '../../../RiskInsight/Detail/shared';

const EdgeHeader = ( { edge } ) => {

  const [ fromNodeLabel, setFromNodeLabel ] = React.useState( null );
  const [ toNodeLabel, setToNodeLabel ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( edge ) ) {
      if ( isNotEmpty( edge.includedEdges ) ) {
        const [ firstEdge ] = edge.includedEdges;

        const lastEdge = edge.includedEdges[ edge.includedEdges.length - 1 ];

        setFromNodeLabel( firstEdge?.fromNodeFull?.label );
        setToNodeLabel( lastEdge?.toNodeFull?.label );
      } else if ( isNotEmpty( edge.fromNode ) && isNotEmpty( edge.toNode ) ) {
        setFromNodeLabel( edge.fromNode?.label );
        setToNodeLabel( edge.toNode?.label );
      } else {
        setFromNodeLabel( 'N/A' );
        setToNodeLabel( 'N/A' );
      }
    }
  }, [ edge ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( edge ) &&
        <div className="recordCardHeaderRow">
          <h2>
            <InlineSVG type="segmentsAlt" elementClass="recordTypeHeaderIcon" />
            <span className="label">
              <strong>{ fromNodeLabel }</strong>
              <span>to</span>
              <strong>{ toNodeLabel }</strong>
            </span>
          </h2>
          <RatingBadge rating={ edgeRiskClass( edge ) } />
        </div>

      }
    </React.Fragment>
  );
};

export default EdgeHeader;