/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import { makeRequest } from '../../legacy/io';
import { getGlobalSettings, isNotEmpty } from '../shared/Utilities';
import { v4 as uuidv4 } from 'uuid';

export const RiskContext = React.createContext();

export const RiskProvider = ( { children } ) => {

  let isMounted = true;

  const [ loadingRisk, setLoadingRisk ] = React.useState( false );
  const [ riskData, setRiskData ] = React.useState( null );
  const [ targetRisk, setTargetRisk ] = React.useState( 1 );
  const [ globalRisk, setGlobalRisk ] = React.useState( 1 );

  const refreshGlobalRisk = async () => {
    const response = await makeRequest( 'FETCH', '/model', { project: 'default', model: 'base' } );
    if ( isMounted && response.results?.risk !== undefined ) {
      setGlobalRisk( response.results.risk );
    }
  };

  const refreshRisk = async ( options ) => {
    setLoadingRisk( true );

    const riskHistoryResponse = await (
      makeRequest(
        'FETCH',
        '/project/default/model/base/chart/risk_remediation_history',
        {
          width: window.innerWidth,
          height: 100,
          days: options?.days || '60',
        },
      )
    );

    if ( isNotEmpty( riskHistoryResponse ) ) {
      let max = 0;
      if ( options && isMounted !== false ) {
        setLoadingRisk( false );
        if (
          isNotEmpty( riskHistoryResponse )
          && isNotEmpty( riskHistoryResponse.results ) && isNotEmpty( riskHistoryResponse.results.data_points )
        ) {
          const _data = { };
          riskHistoryResponse.results.data_points.map( ( point, i ) => {

            const id = uuidv4();

            let addedHosts = 0;
            let removedHosts = 0;
            if ( isNotEmpty( point.scan_events ) ) {
              point.scan_events.map( e => {
                if ( e.event === 'identified' ) {
                  addedHosts += 1;
                }
                if ( e.event === 'expired' ) {
                  removedHosts -= 1;
                }
              } );
            }

            if ( point.risk >= max ) {
              max = point.risk;
            }

            _data[id] = {
              id,
              originalIndex: i,
              totalPoints: riskHistoryResponse.results.data_points.length,
              risk: point.risk,
              timestamp: point.timestamp,
              addedEscalations: isNotEmpty( point.import_events )
                ? point.import_events.added_escalations
                : 0,
              removedEscalations: isNotEmpty( point.import_events )
                ? 0 - point.import_events.removed_escalations
                : 0,
              addedHosts,
              removedHosts,
              original: point,
            };
          } );

          setRiskData( {
            original: riskHistoryResponse.results.data_points,
            transformed: _data,
            max,
            yAxis: 'risk',
            xAxis: 'timestamp',
          } );
        }
      }
    }
  };

  const refreshTarget = async () => {
    const projectResponse = await getGlobalSettings( 'project' );

    if ( isNotEmpty( projectResponse ) && isNotEmpty( projectResponse.settings ) ) {
      setTargetRisk( projectResponse.settings.risk_target );
      window.localStorage.setItem( 'targetRisk', projectResponse.settings.risk_target );
    }
  };

  React.useEffect( () => {
    isMounted = true;
    refreshGlobalRisk();
    refreshRisk( { days: '60', isMounted } );
    refreshTarget();
    return () => {
      isMounted = false;
    };
  }, [] );

  const data = [
    riskData,
    globalRisk,
    targetRisk,
    refreshRisk,
    loadingRisk,
    refreshGlobalRisk,
  ];

  return (
    <RiskContext.Provider value={ data }>
      { children }
    </RiskContext.Provider>
  );
};
