/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/
import React from 'react';

import { decodeURLHash, isNotEmpty } from '../../shared/Utilities';

import './style.scss';
import InlineSVG from '../../shared/InlineSVG';

import MenuItem from './MenuItem';
import { ContextualHelpContainer } from '../HelpDocumentation/ContextualHelp';
import { NavigationContext } from '../../Contexts/Navigation';
import { hasAccess } from '../App/AccessControl';
import AddToPlanMenu from '../Remediation/AddToPlanMenu';
import { HelpContext } from '../../Contexts/Help';
import { CurrentUserContext } from '../../Contexts/CurrentUser';

const LeftNav = () => {

  const [ currentRoute, setCurrentRoute ] = React.useState( '' );
  const [ currentPage, setCurrentPage ] = React.useState( '' );
  const [ , , routing ] = React.useContext( NavigationContext );

  const [ helpItems, , , , showHelp, setShowHelp, , , , ] = React.useContext( HelpContext );

  const [ , , licenseInfo ] = React.useContext( CurrentUserContext );

  const refreshRouteData = () => {
    const pageParams = decodeURLHash();

    setCurrentRoute( pageParams['.'] );
    if ( pageParams['page'] || pageParams['report'] ) {
      setCurrentPage( pageParams['page'] || pageParams['report'] );
    } else {
      setCurrentPage( '' );
    }
  };

  React.useEffect( () => {
    refreshRouteData();
    window.addEventListener( 'hashchange', refreshRouteData );
    return () => {
      window.removeEventListener( 'hashchange', refreshRouteData );
    };
  }, [] );

  return (
    <React.Fragment>
      <div
        id="navigationMenu"
      >
        <div className="upperWrapper">
          <div className="logoContainer">
            <a href="#.=reporting&page=reporting_dashboard">
              <InlineSVG type="primaryLogoBug" version="bug" size="logoBug" />
            </a>
          </div>
          <ul id="menuItems">
            {
              Object.values( routing ).map( ( routeInfo, index ) => {
                if ( !routeInfo.lower && !routeInfo.settings ) {
                  return  <MenuItem
                    item={routeInfo}
                    key={index}
                    currentRoute={currentRoute}
                    currentPage={currentPage}
                  />;
                }
              } )
            }
          </ul>
        </div>
        <ul id="lowerItems">
          {
            ( isNotEmpty( licenseInfo ) && hasAccess( licenseInfo, {}, 'feature', 'remediationWorkflow' ) ) &&
            <AddToPlanMenu
              planOptions={ [ 'remediation' ] }
              elementClass="leftNavItem"
              positionOverride={ { bottom: '1em', left: '6em' } }
            />
          }
          {
            <li>
              <div
                className="contextualHelpButton"
                onClick={ () => setShowHelp( !showHelp ) }
              >
                <InlineSVG type="help_center_nav" />
                {
                  isNotEmpty( helpItems ) &&
                  <span className="notificationCount">{ Object.keys( helpItems ).length }</span>
                }
              </div>
            </li>
          }
          {
            Object.values( routing ).map( ( routeInfo, index ) => {
              if ( routeInfo.lower ) {
                return  <MenuItem
                  item={routeInfo}
                  key={index}
                  currentRoute={currentRoute}
                  currentPage={currentPage}
                />;
              }
            } )
          }
        </ul>
      </div>

      <ContextualHelpContainer />
    </React.Fragment>
  );
};

export default LeftNav;