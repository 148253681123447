/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../Utilities';

export const percentileColor = percentile => {
  if ( percentile >= 0.75 ) {
    return 'primaryBlue';
  }
  if ( percentile >= 0.5 ) {
    return 'green';
  }
  if ( percentile >= 0.25 ) {
    return 'yellow';
  }
  return 'red';
};

export const globalRiskColor = ( risk, target ) => {
  const ratio = risk / ( target || 1 );
  if ( ratio < 1 ) {
    return 'green';
  } else if ( ratio < 1.5 ) {
    return 'yellow';
  } else if ( ratio < 1.75 ) {
    return 'orange';
  } else if ( ratio < 2 ) {
    return 'red';
  }
  return 'darkRed';
};

export const chartGradients = {
  darkBlue: <linearGradient id="gradient--darkBlue" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#334D6E" />
    <stop offset="20%" stopColor="#334D6E" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#334D6E" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#334D6E" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#334D6E" stopOpacity="00" />
  </linearGradient>,
  'status--red': <linearGradient id="gradient--status--red" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#F7685B" />
    <stop offset="20%" stopColor="#F7685B" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#F7685B" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#F7685B" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#F7685B" stopOpacity="00" />
  </linearGradient>,
  'status--green': <linearGradient id="gradient--status--green" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#6FCF97" />
    <stop offset="20%" stopColor="#6FCF97" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#6FCF97" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#6FCF97" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#6FCF97" stopOpacity="00" />
  </linearGradient>,
  'primary': <linearGradient id="gradient--primary" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#1ab3ec" />
    <stop offset="20%" stopColor="#1ab3ec" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#1ab3ec" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#1ab3ec" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#1ab3ec" stopOpacity="00" />
  </linearGradient>,
};

export const isCurrent = ( point, currentPoint ) => point?.id === currentPoint?.id;

export const shouldShowTicLabel = ( point, currentPoint, data ) => {
  if ( isCurrent( point, currentPoint ) ) {
    return true;
  }

  if ( isNotEmpty( data ) && isNotEmpty( data.transformed ) ) {
    const allPoints = Object.values( data.transformed );

    const pointsLength = allPoints.length;

    const thisIndex = allPoints.indexOf( point );

    // always show first
    if ( thisIndex === 0 ) {
      return true;
    }
    // // always show 20%
    // if ( thisIndex === Math.floor( pointsLength * 0.2 ) ) {
    //   return true;
    // }
    // // always show 40%
    // if ( thisIndex === Math.floor( pointsLength * 0.4 ) ) {
    //   return true;
    // }
    // // always show 60%
    // if ( thisIndex === Math.floor( pointsLength * 0.6 ) ) {
    //   return true;
    // }
    // // always show 80%
    // if ( thisIndex === Math.floor( pointsLength * 0.8 ) ) {
    //   return true;
    // }
    // always show last
    if ( thisIndex === pointsLength - 1 ) {
      return true;
    }
    return false;
  }
  return false;
};




