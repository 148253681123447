/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';

import Modal from '../../shared/Modal';
import Notification from '../../shared/Notification';

import InlineSVG from '../../shared/InlineSVG';

import {
  isNotEmpty,
  isSafari,
  isFirefox,
  isFalsey,
  formatUnixDate,
} from '../../shared/Utilities';

import './style.scss';

import { makeRequest } from '../../../legacy/io';

const About = () => {

  const [ aboutInfo, setAboutInfo ] = React.useState( {} );
  const [ eula, setEula ] = React.useState( '' );
  const [ licenseInfo, setLicenseInfo ] = React.useState( {} );
  const [ currentModalBody, setCurrentModalBody ] = React.useState( '' );
  const [ currentModalBodyHeader, setCurrentModalHeader ] = React.useState( '' );
  const [ showModal, setShowModal ] = React.useState( false );
  const [ generatingSupport, setGeneratingSupport ] = React.useState( false );

  const supportLink = React.useRef( null );

  React.useEffect( () => {
    makeRequest( 'FETCH', '/about', {} ).then( response => {
      setAboutInfo( response.results );
    } );

    makeRequest( 'GET', '/eula.txt' ).then( response => {
      setEula( response );
    } );

    makeRequest( 'FETCH', '/license_info' ).then( response => {
      setLicenseInfo( response );
    } );
  }, [] );

  const EulaContent = ( { eula } ) => {
    return (
      <React.Fragment>
        <p>Please carefully review the following agreement:</p>
        <pre className="eulaContent">{ eula }</pre>
      </React.Fragment>

    );
  };

  const OpenSourceLicensesContent = ( { info } ) => {
    return (
      <div className="openSourceContent">
        {
          ( info && info.licenses ) &&

          Object.keys( info.licenses ).map( ( license, index ) => {
            return  <div
              key={index}
            >
              <h2>
                {
                  // eslint-disable-next-line
                  `This software may contain portions of the ${license} project, which is released under the following license:`
                }
              </h2>
              <pre>
                { info.licenses[license] }
              </pre>
            </div>;
          } )

        }
      </div>
    );
  };

  const modalContentMap = {
    eula: {
      header: <span>DeepSurface <sup> { '\u00AE' } </sup> End-User License Agreement</span>,
      body: <EulaContent eula={eula} />,
    },
    license: {
      header: 'Open Source Licenses',
      body: <OpenSourceLicensesContent info={aboutInfo} />,
    },
  };

  const showModalFor = type => {
    setCurrentModalHeader( modalContentMap[type].header );
    setCurrentModalBody( modalContentMap[type].body );
    setShowModal( true );
  };

  const getBarPercentage = type => {
    const limit = parseInt( licenseInfo.license_element_limit );
    const elType = type === 'host' ? licenseInfo.observed_hosts : licenseInfo.observed_active_users;
    if ( limit >= 0 ) {
      return Math.round( ( elType / limit ) * 100 );
    }
    return 0;

  };

  const generateSupportInfo = e => {
    e.preventDefault();

    setGeneratingSupport( true );

    const sid = window.localStorage.getItem( 'sid' );
    const oReq = new XMLHttpRequest();
    oReq.open( 'GENERATE', '/support_file', true );
    oReq.setRequestHeader( 'Authorization', 'kanchil ' + sid );
    oReq.responseType = 'arraybuffer';

    oReq.onload = function( ) {
      const arrayBuffer = oReq.response;
      const blob = new Blob( [ arrayBuffer ], {type: 'application/zip'} );
      // eslint-disable-next-line
      const filename = 'logs-' + new Date().toLocaleString().replaceAll( '/', '_' ).replaceAll( ':', '_' ).replaceAll( ' ', '-' ).replaceAll( ',', '' ) + '.zip';

      const hiddenLinkEl = supportLink.current;

      hiddenLinkEl.setAttribute( 'href', window.URL.createObjectURL( blob ) );

      if( isFirefox || isSafari ){
        hiddenLinkEl.setAttribute( 'download', filename );
        hiddenLinkEl.removeAttribute( 'target' );
      }

      hiddenLinkEl.click();

      setGeneratingSupport( false );
    };
    oReq.send();
  };

  const licenseExpired = new Date() > new Date( parseFloat( licenseInfo.license_expiration ) * 1000 );
  const overLicense = licenseInfo.observed_elements > licenseInfo.license_element_limit;
  // eslint-disable-next-line
  const usagePercentage = parseInt( licenseInfo.license_element_limit ) === 0 ? 100 : Math.round( ( licenseInfo.observed_elements / parseInt( licenseInfo.license_element_limit ) ) * 100 );

  return (
    <div className="reactAboutPage" >
      <Modal
        elementClass="reactAboutPageModal"
        visible={showModal}
        setVisible={setShowModal}
        header={currentModalBodyHeader}
        body={currentModalBody}
      />
      <strong className="asHeader">
        DeepSurface <sup>{ '\u00AE' }</sup> is Copyright <span>{ '\u00A9' }</span> 2024
        DeepSurface Security, Inc. All Rights Reserved.
      </strong>
      <p>Patent Pending</p>
      <p>DeepSurface is a registered trademark of DeepSurface Security, Inc.</p>
      <p>
        DeepSurface <sup>{ '\u00AE' }</sup> Version: <span>{ aboutInfo.version }</span>
      </p>
      <strong className="asHeader">
        DeepSurface API
      </strong>
      <p>
        To generate API credentials for users in the system, edit or create an existing
        user <a href="#.=setup&page=users">here</a> and click "Generate API Credentials".
      </p>
      <p>
        For more detailed instructions and documentation about the DeepSurface API, visit the
        documentation
        <a href = "/api/v1/docs"> here<InlineSVG type="newTabLink" version="primary" /></a>
      </p>
      <p>
        Installation ID: <span>{ aboutInfo.installation_id }</span>
      </p>
      <a
        href="#.=help_documentation"
        target="_blank"
        rel="noreferrer noopener"
      >
        Help Documentation
        <InlineSVG type="newTabLink" version="primary" />
      </a>
      {
        isNotEmpty( licenseInfo ) &&
        <div className="usageDataContainer" >
          {
            overLicense &&
            <Notification
              options={{
                message: 'You are over your license element limit',
                type: 'alert',
              }}
            />
          }
          {
            licenseExpired &&
            <Notification
              options={{
                message: 'Your license has expired',
                type: 'alert',
              }}
            />
          }
          <h3>License Usage:</h3>
          <span className="label">
            License Holder: <strong>{licenseInfo.license_owner}</strong>
          </span>
          <span className="label">
            License Issued:
            <strong>
              { formatUnixDate( licenseInfo.license_issued ) }
            </strong>
          </span>
          <span className="label">
            License Expires:
            <strong>
              { formatUnixDate( licenseInfo.license_expiration ) }
            </strong>
          </span>
          <span className="label">
            Remediation Enabled:
            <strong>
              { isFalsey( licenseInfo.license_option_remediation ) ? 'No' : 'Yes' }
            </strong>
          </span>
          <div className="legend">
            <div className="items">
              <div className="legendItem hosts">
                <span className="color" />
                <span className="label">
                  Hosts Count: <strong>{licenseInfo.observed_hosts}</strong>
                </span>
              </div>
              <div className="legendItem users">
                <span className="color" />
                <span className="label">
                  Active Users: <strong>{licenseInfo.observed_active_users}</strong>
                </span>
              </div>
            </div>
            <div className="otherInfo">
              <span className="label">
                Total Elements: <strong>{licenseInfo.observed_elements}</strong>
              </span>
              <span className="label">
                License Element Limit: <strong>{licenseInfo.license_element_limit}</strong>
              </span>
              <span className="label">
                Percentage Used: <strong>{ `${usagePercentage}%` }</strong>
              </span>
            </div>
          </div>

          <div className={`progressWrapper ${overLicense ? 'over' : ''}`}>
            <div
              className="hostUsageBar"
              style={
                { width: `${getBarPercentage( 'host' )}%`}
              }
            />
            <div
              className="userUsageBar"
              style={
                { width: `${getBarPercentage( 'user' )}%`}
              }
            />
          </div>
        </div>
      }
      <div className="actions">
        <div
          className="modalTrigger"
          onClick={ () => showModalFor( 'eula' )}
        >
          End-User License Agreement
        </div>
        <div
          className="modalTrigger"
          onClick={ () => showModalFor( 'license' )}
        >
          Open Source Licenses
        </div>

        <button
          className={ `${ generatingSupport ? 'disabled' : '' } downloadButton` }
          disabled={ generatingSupport }
          onClick={ generateSupportInfo }
        >
          {
            generatingSupport
              ? <React.Fragment>
                <InlineSVG type="cycle" version="light" />
                <span>Preparing, please wait...</span>
              </React.Fragment>
              : <span>Download Support Info</span>
          }
        </button>
        <a
          ref={supportLink}
          className="hiddenLink"
          target="_blank"
        ></a>
      </div>
    </div>
  );
};

export default About;
