/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { RiskContext } from '../../Contexts/Risk';
import { formatRiskReduction, isNotEmpty, riskToRating } from '../Utilities';

import './style.scss';
import { edgeRiskClass } from '../../components/RiskInsight/Detail/shared';

const RiskReduction = ( { item, itemType, riskType='risk', type=null } ) => {

  const [ , globalRisk, , ,  ] = React.useContext( RiskContext );

  const [ reductionAmount, setReductionAmount ] = React.useState( '--' );
  const [ reductionClass, setReductionClass ] = React.useState( 'unknown' );

  React.useEffect( () => {
    if ( isNotEmpty( item ) && isNotEmpty( itemType ) ) {
      if ( itemType === 'host' && !item.has_host ) {
        setReductionClass( 'unknown' );
        setReductionAmount( '\u00A0\u00A0\u00A0\u00A0--\u00A0\u00A0\u00A0\u00A0' );
      } else {
        let _rating = riskToRating( item[riskType] );
        const _amount = formatRiskReduction( item[riskType], globalRisk );

        if ( type ==='edge' ) {
          _rating = edgeRiskClass( item );
        }
        setReductionClass( _rating );
        setReductionAmount( _amount );
      }
    } else {
      let _rating = riskToRating( item[riskType] );
      const _amount = formatRiskReduction( item[riskType], globalRisk );

      if ( type ==='edge' ) {
        _rating = edgeRiskClass( item );
      }
      setReductionClass( _rating );
      setReductionAmount( _amount );
    }
  }, [ item, itemType ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( item ) && isNotEmpty( globalRisk ) ) &&
        <strong
          className={ `riskReduction ${reductionClass}`}
        >
          { reductionAmount }
        </strong>
      }
    </React.Fragment>
  );
};

export default RiskReduction;