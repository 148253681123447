/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import {
  formatNumber,
  isNotEmpty,
  isEmpty,
  formatUnixDate,
} from '../../../../shared/Utilities';

export const scopeTypeOptions = {
  'null': 'Any',
  'mssql_*': 'MS SQL Database',
  'mysqld': 'MySQL Database',
  'sharedfolder_*': 'SMB Shared Folder',
  // 'user': 'Local Users',
  // 'domain_user': 'Domain Users',
  // 'domain_group': 'Domain Groups',
};

export const nodeTypeOptions = {
  'null': 'Any',
  'database': 'Database',
  'shared_folder_permission': 'Shared Folder',
  'user': 'Local User',
  'domain_user': 'Domain User',
  'domain_group': 'Domain Group',
};

export const conditionalExcludeLabels = {
  'mssql_*': 'Include Default Databases',
  'mysqld': 'Include Default Databases',
  'sharedfolder_*': 'Include Default Folders',
};

export const conditionalTagLabels = {
  'null': 'that belong to',

};

export const conditionalScopeNameLabels = {
  'null': 'to',
  'mssql_*': 'to all',
  'mysqld': 'to all',
  'sharedfolder_*': 'to all',
};

export const conditionalNodeTypeLabels = {
  'null': 'and are',
  'database': 'and have the',
  'shared_folder_permission': 'and have the',
  'user': 'and have the',
  'domain_user': 'and have the',
  'domain_group': 'and have the',
};

export const buildPolicyRecord = ( selectedRecord, isValid, updatedRecord ) => {
  /* eslint-disable camelcase */
  if ( isValid && isNotEmpty( updatedRecord ) ) {
    const record = {
      asset_tag_id: updatedRecord.asset_tag_id === 'null' ? null : updatedRecord.asset_tag_id,
      label: updatedRecord.label,
      impact: updatedRecord.impact ? parseFloat( updatedRecord.impact ) : 0,
      criteria: {
        scope_filters: [
          {},
        ],
        node_filter: {},
      },
    };

    // very specific mapping of form values to backend expected structur
    Object.entries( updatedRecord ).map( ( [ attr, val ] ) => {
      if ( attr === 'scope_name' && isNotEmpty( val ) && val !== 'null' ) {
        // need to construct things very differently depending on the type
        if ( val === 'mssql_*' ) {
          record.criteria.scope_filters.push( { name: val } );
        }
        if ( val === 'mysqld' ) {
          record.criteria.scope_filters.push( { name: val } );
        }
        if ( val === 'sharedfolder_*' ) {
          record.criteria.scope_filters.push( { name: val } );
        }
      }

      if ( attr === 'scope_label' && isNotEmpty( val ) ) {
        record.criteria.scope_filters[1].label = val;
      }
      if ( attr === 'scope_builtin' ) {
        // for sharedfolder_* this goes in the scope_filter
        record.criteria.scope_filters[1].flags = { builtin: val };
      }
      if ( attr === 'node_label' && isNotEmpty( val ) ) {
        record.criteria.node_filter.label = val;
      }
      if ( attr === 'node_name' && isNotEmpty( val ) ) {
        record.criteria.node_filter.name = val;
      }
      if ( attr === 'node_builtin' ) {
        // for mssql_* and mysqld this goes in the node_filter
        record.criteria.node_filter.flags = { builtin: val };
      }
      if ( attr === 'node_type' && isNotEmpty( val ) && val !== 'null' ) {
        record.criteria.node_filter.type = val;
      }
    } );

    // add id if updating an existing policy so that we don't accidentally create a new one
    if ( selectedRecord && selectedRecord.id ) {
      record.id = selectedRecord.id;
    }
    return record;
  }
  return {};
  /* eslint-enable camelcase */
};

export const parsePolicyRecord = policy => {
  let flattened = { ...policy };
  if ( isNotEmpty( policy.criteria ) ) {

    const _criteria = {};
    // flatten the scope filters
    if ( isNotEmpty( policy.criteria.scope_filters ) ) {
      policy.criteria.scope_filters.map( f => {
        // there will be an empty base filter, don't need to worry about that
        if ( isNotEmpty( f ) ) {
          // take each and append `scope_` so as not to confuse with node variants
          Object.entries( f ).map( ( [ attr, value ] ) => {
            if ( attr === 'flags' && isNotEmpty( value ) ) {
              Object.entries( value ).map( ( [ _attr, _value ] ) => {
                _criteria[`scope_${_attr}`] = _value;
              } );
            } else {
              _criteria[`scope_${attr}`] = isEmpty( value ) ? 'null' : value;
            }
          } );
        }
      } );
    }

    // flatten the node_filter, there will only be one
    if ( isNotEmpty( policy.criteria.node_filter ) ) {
      // take each and append `scope_` so as not to confuse with node variants
      Object.entries( policy.criteria.node_filter ).map( ( [ attr, value ] ) => {
        if ( attr === 'flags' && isNotEmpty( value ) ) {
          Object.entries( value ).map( ( [ _attr, _value ] ) => {
            _criteria[`node_${_attr}`] = _value;
          } );
        } else {
          _criteria[`node_${attr}`] = isEmpty( value ) ? 'null' : value;
        }
      } );
    }

    flattened = { ...flattened, ..._criteria };
  }
  return flattened;
};

export const policyName = policy => {
  if ( isNotEmpty( policy.label ) ) {
    return policy.label;
  }
  return `Policy from ${formatUnixDate( policy.created )}`;
};

export const userFriendlyNodeTypeMap = {
  'user':'User',
  'group':'Group',
  'domain_user':'Domain User',
  'domain_group':'Domain Group',
  'service_user':'Service User',
  'file':'File(s)',
  'database':'Database',
  'shared_folder_permission':'Shared Folder Permission',
};

export const userFriendlyNameFor = type => userFriendlyNodeTypeMap[type]
  ? userFriendlyNodeTypeMap[type]
  : type;

// actually build the policy sentence, separated from the Form callback so that it can be called onload from an
// existing policy
export const buildPolicySentence = ( policy, assetTags ) => {

  const getScopeNameSection = policy => {
    const allSections = [];
    let scopeBuiltin = null;
    let scopeLabel = null;
    const scopeName = <React.Fragment> <code>{ scopeTypeOptions[policy.scope_name] }s</code></React.Fragment>;

    if ( policy.scope_builtin !== true && policy.scope_name === 'sharedfolder_*' ) {
      scopeBuiltin = <React.Fragment> <code>non-default</code></React.Fragment>;
    }

    if ( isNotEmpty( policy.scope_label ) ) {
      // eslint-disable-next-line max-len
      scopeLabel = <React.Fragment> with a scope label that matches the wildcard <code>{ policy.scope_label }</code></React.Fragment>;
    }

    if ( isNotEmpty( scopeBuiltin ) ) {
      allSections.push( scopeBuiltin );
    }

    if ( isNotEmpty( scopeName ) ) {
      allSections.push( scopeName );
    }

    if ( isNotEmpty( scopeLabel ) ) {
      allSections.push( scopeLabel );
    }
    return allSections;
  };

  const sentenceParts = [];

  // add the impact section of the sentence
  sentenceParts.push(
    <span className="impact">
      Give an impact score of <code>{ formatNumber( policy.impact ) || 0 }</code>
    </span>,
  );

  // build up and add the scope section
  if ( isNotEmpty( policy.scope_name && policy.scope_name !== 'null' ) ) {
    sentenceParts.push(
      // eslint-disable-next-line max-len
      <span className="scope"> to all { getScopeNameSection( policy ).map( ( s, i ) => <React.Fragment key={i}>{ s }</React.Fragment> ) }</span>,
    );
  } else {
    sentenceParts.push(
      // eslint-disable-next-line
      <span className="scope"> to all assets</span>,
    );
  }

  // add the tag if there is one
  if ( isNotEmpty( policy.asset_tag_id ) ) {
    if ( isEmpty( policy.asset_tag_id ) ) {
      sentenceParts.push( <span className="tag"> that belong to <code>Any</code> tag</span> );
    } else {
      sentenceParts.push(
        <span className="tag"> that belong to the <code>{ assetTags[policy.asset_tag_id] }</code> tag</span>,
      );
    }
  }

  const nodeParts = [];

  if ( policy.node_builtin !== true ) {
    if ( isNotEmpty( policy.node_type ) && policy.node_type !== 'null' ) {
      nodeParts.push(
        // eslint-disable-next-line max-len
        <React.Fragment> that are <code>non-default</code></React.Fragment>,
      );
    }
  }

  // node section, name/label/flags
  if ( isNotEmpty( policy.node_name ) ) {
    nodeParts.push(
      <React.Fragment> and have the name <code>{policy.node_name}</code></React.Fragment>,
    );
  }

  if ( isNotEmpty( policy.node_label ) ) {

    if ( isNotEmpty( policy.node_type ) && policy.node_type !== 'null' ) {
      nodeParts.push(
        // eslint-disable-next-line max-len
        <React.Fragment> and have an asset label that matches the wildcard <code>{policy.node_label}</code> and have the type <code>{nodeTypeOptions[policy.node_type] }</code></React.Fragment>,
      );
    } else {
      nodeParts.push(
        // eslint-disable-next-line max-len
        <React.Fragment> and have an asset label that matches the wildcard <code>{policy.node_label}</code></React.Fragment>,
      );
    }
  }

  // just a type
  if (
    isEmpty( policy.node_label )
    && isEmpty( policy.node_name )
    && isNotEmpty( policy.node_type )
  ) {
    if ( policy.node_builtin !== true ) {
      if ( isNotEmpty( policy.node_type ) && policy.node_type !== 'null' ) {
        nodeParts.push(
          // eslint-disable-next-line max-len
          <React.Fragment> and have the type <code>{nodeTypeOptions[policy.node_type] }</code></React.Fragment>,
        );
      }
    } else {
      nodeParts.push(
        // eslint-disable-next-line max-len
        <React.Fragment> that have the type <code>{nodeTypeOptions[policy.node_type] }</code></React.Fragment>,
      );
    }
  }

  if ( isNotEmpty( nodeParts ) ) {
    sentenceParts.push(
      <span className="node">{ nodeParts.map( ( p, i ) => <React.Fragment key={i}>{ p }</React.Fragment> ) }</span>,
    );
  }

  return sentenceParts;
};

export const stubbedSearch = {
  results: [
    {
      'id': '0d7ecd71-b9f1-4014-ad3b-771e06aeeb8a',
      'modified': 1667360099.48633,
      'created': 1667359735.141123,
      'model_id': '4c63610d-b4b3-4690-98d4-eb81e6ec6e8e',
      'label': 'some label',
      'asset_tag_id': 'a12f9585-98fa-4080-9b5c-7e79d1007816',
      'criteria': {
        'scope_filters': [
          {},
          {
            'name': 'mssql_*',
            'label': 'scope_keyword',
            'flags': {
              'builtin': true,
            },
          },
        ],
        'node_filter': {
          'label': 'asset_keyword',
        },
      },
      'impact': 1,
    },
    {
      'asset_tag_id': null,
      'label': 'policy name',
      'impact': 10,
      'criteria': {
        'scope_filters': [
          {},
          {
            'name': 'mysqld',
            'flags': {
              'builtin': false,
            },
          },
        ],
        'node_filter': {
          'label': 'keyword',
        },
      },
    },

    {
      'id': '9ae97565-3e48-4d83-bf65-4bd6c9c6b599',
      'modified': 1667360254.770544,
      'created': 1667360254.770544,
      'model_id': '4c63610d-b4b3-4690-98d4-eb81e6ec6e8e',
      'label': '',
      'asset_tag_id': null,
      'criteria': {
        'scope_filters': [
          {},
          {
            'name': 'sharedfolder_*',
            'label': 'folder_key',
          },
        ],
        'node_filter': {
          'name': 'write',
          'flags': {
            'builtin': false,
          },
        },
      },
      'impact': 1000,
    },

    {
      'id': '36c4f0dd-9979-4142-81ad-5cccdfb7be5c',
      'modified': 1667360320.574658,
      'created': 1667360320.574658,
      'model_id': '4c63610d-b4b3-4690-98d4-eb81e6ec6e8e',
      'label': 'asdfasdfrtyiurtyuityui',
      'asset_tag_id': 'd318b5b0-7658-40d9-b065-b30548056af6',
      'criteria': {
        'scope_filters': [
          {},
        ],
        'node_filter': {
          'type': 'domain_user',
          'label': 'asdfasdfasdfasdfasdf',
        },
      },
      'impact': 145,
    },
  ],
};