/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { decodeURLHash, isEmpty, isNotEmpty } from '../../../shared/Utilities';
import { availableWidgetOptions, availableWidgetsV2 } from './shared';
import { v4 as uuidv4 } from 'uuid';

import './DashboardEditorV2.scss';
import InlineSVG from '../../../shared/InlineSVG';
import Form from '../../../shared/Form';
import { getFieldValues } from '../../../shared/Form/Shared';
import { makeRequest } from '../../../../legacy/io';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';

const DashboardEditorV2 = ( {
  setEditMode,
  layouts,
  currentLayoutID,
  setCurrentLayoutID,
  currentLayout,
  setCurrentLayout,
  updatedLayoutForm,
  setUpdatedLayoutForm,
  editorRef,
  refreshLayouts,
  openWidgetEditorFor,
  currentSettings,
} ) => {

  const [ availableWidgetCategories, setAvailableWidgetCategories ] = React.useState( null );

  const [ reportFields, setReportFields ] = React.useState( null );
  const [ isValid, setIsValid ] = React.useState( true );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  // on init, decide what widgets are available for this page, for now, it is all and this
  // only exists on the "custom reports" page, but in the future, this could be used in many more places
  React.useEffect( ( ) => {
    const hash = decodeURLHash();
    const page = hash.page || hash.report;
    const availableKeys = availableWidgetOptions[page];

    if ( isNotEmpty( availableKeys ) ) {
      const _options = {};
      availableKeys.map( key => {
        _options[key] = availableWidgetsV2[key];
      } );
      setAvailableWidgetCategories( _options );
    }
  }, [] );

  // set up the fields (only 1, the name of the dashboard)
  React.useEffect( ( ) => {
    setReportFields( [
      {
        type: 'text',
        label: 'Dashboard Name',
        attribute: 'label',
        required: true,
        defaultValue: currentLayout?.label || '',
      },
    ] );
  }, [ currentLayout ] );

  const cancelChanges = () => {
    window.location.reload();
  };

  const saveChanges = async () => {
    let values = {};
    if ( isNotEmpty( updatedLayoutForm ) && isNotEmpty( updatedLayoutForm.fieldStates ) ) {
      values = getFieldValues( updatedLayoutForm.fieldStates, 'reporting_dashboard' );
    }

    const _layout = { ...currentLayout, ...values };

    if ( isNotEmpty( _layout ) && isNotEmpty( _layout.widgets ) && isNotEmpty( currentSettings ) ) {
      _layout.widgets.map( w => {
        if ( isNotEmpty( currentSettings[w.i] ) ) {
          w.settings = currentSettings[w.i];
        }
      } );
    }

    if ( isEmpty( _layout.id ) ) {
      _layout.id = uuidv4();
    }

    const upsertRequest = await makeRequest( 'UPSERT', '/dashboard', { records: [ _layout ] } );

    // success
    if ( isNotEmpty( upsertRequest ) && isNotEmpty( upsertRequest.results ) ) {

      setCurrentLayoutID( upsertRequest.results[0].id );

      addFlashMessage( {
        body: 'Successfully saved dashboard changes',
        type: 'success',
      } );
      window.location.reload();
    } else {
      addFlashMessage( {
        body: 'There was an error saving your dashboard',
        type: 'alert',
      } );
    }

    setEditMode( false );
    refreshLayouts( upsertRequest.results[0].id );
  };

  const deleteLayout = async () => {
    if ( confirm( 'Are you sure you want to delete this dashboard?' ) ) {

      if ( isNotEmpty( currentLayout ) && isNotEmpty( currentLayout.id ) ) {
        const { id } = currentLayout;

        const _layouts = layouts.filter( l => l.id !== id );

        // if the id is equal to the current layout... which it probably will be, then we need to choose a different one
        if ( id === currentLayoutID ) {
          setCurrentLayout( null );
        }

        const deleteRequest = await makeRequest( 'DELETE', '/dashboard', { id } );
        // success
        if ( isNotEmpty( deleteRequest ) && isNotEmpty( deleteRequest.results ) ) {
          addFlashMessage( {
            body: 'Successfully deleted dashboard',
            type: 'success',
          } );
        } else {
          addFlashMessage( {
            body: 'There was an error deleting your dashboard',
            type: 'alert',
          } );
        }
        setEditMode( false );
        refreshLayouts( _layouts[0].id );
      }
    }
  };

  return (
    <React.Fragment>
      <div
        className="dashboardEditorV2"
        ref={editorRef}
      >
        {
          isNotEmpty( reportFields ) &&
          <Form
            fields={reportFields}
            onChangeCallback={ setUpdatedLayoutForm }
            existingRecord={ currentLayout }
            recordType="reporting_dashboard"
            setIsValid={setIsValid}
            trackUpdates={false}
          />
        }
        {
          isNotEmpty( availableWidgetCategories ) &&
          <div className="addWidgetsWrapper">
            <h2>Select a widget category:</h2>
            <div className="optionsWrapper">
              {
                Object.values( availableWidgetCategories ).map( ( widgetCategory, index ) => {
                  return <button
                    className="widgetOption"
                    key={index}
                    onClick={ () => openWidgetEditorFor( widgetCategory ) }
                  >
                    <InlineSVG type={widgetCategory.icon} />
                    <label>{ widgetCategory.label }</label>
                  </button>;
                } )
              }
            </div>
          </div>
        }
        <div className="editorActionsWrapper">
          <button
            className="deleteButton"
            onClick={ deleteLayout }
          >
            Delete
          </button>
          <div className="buttonWrapper">
            <button
              className="cancelButton"
              onClick={ cancelChanges }
            >
              Cancel
            </button>
            <button
              className={ `${isValid ? '' : 'disabled'} submitButton`}
              onClick={ saveChanges }
              disabled={ !isValid }
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardEditorV2;