/** **************************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ****************************************************************************/

import React from 'react';

import {
  useLocalStorage,
  isEmpty,
  isNotEmpty,
} from '../shared/Utilities';

export const InsightHistoryContext = React.createContext();

export const InsightHistoryProvider = ( { children } ) => {

  const [ insightHistory, setInsightHistory ] = useLocalStorage( 'insightHistory', '' );

  const addToInsightHistory = newItem => {

    let _toAdd = {};

    if ( isNotEmpty( window.localStorage.getItem( 'insightHistory' ) ) ) {
      const existingItems = JSON.parse( window.localStorage.getItem( 'insightHistory' ) );

      // if there are already 20 items, remove the first item before adding the new one
      if ( Object.keys( existingItems ).length >= 20 ) {
        delete existingItems[Object.keys( existingItems )[0]];
      }

      // if the item is already in the history, do nothing
      if ( isEmpty( existingItems[newItem.id] ) ) {
        _toAdd = { ...existingItems, [newItem.id]: { ...newItem } };
      } else {
        _toAdd = { ...existingItems };
      }

    } else {
      _toAdd = { [newItem.id]: { ...newItem } };
    }

    setInsightHistory( _toAdd );
  };

  const removeFromInsightHistory = itemKey => {
    if ( isNotEmpty( window.localStorage.getItem( 'insightHistory' ) ) ) {
      const existingItems = JSON.parse( window.localStorage.getItem( 'insightHistory' ) );

      // remove the item from the exsting ones
      if ( existingItems[itemKey] ) {
        delete existingItems[itemKey];
      }

      if ( isNotEmpty( existingItems ) ) {
        // and then reset the object
        setInsightHistory( existingItems );
      } else {
        // and then reset the object
        setInsightHistory( {} );
        window.localStorage.setItem( 'insightHistory', '' );
      }

    } else {
      setInsightHistory( {} );
    }
  };

  const clearInsightHistory = () => {
    if ( window.confirm( 'Are you sure you want to clear your entire history?' ) ) {
      setInsightHistory( window.localStorage.setItem( 'insightHistory', {} ) );
    }
  };

  const data = [
    insightHistory,
    setInsightHistory,
    addToInsightHistory,
    removeFromInsightHistory,
    clearInsightHistory,
  ];

  return (
    <InsightHistoryContext.Provider value={ data }>
      { children }
    </InsightHistoryContext.Provider>
  );
};
