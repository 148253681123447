/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  vulnerabilityScannerInstanceName,
  vulnerabilityScannerLogo,
  vulnerabilityScannerNameMap,
} from '../../../shared/Utilities';

const VulnerabilityScannerItem = ( { vulnerabilityScanner } ) => {
  return (
    <React.Fragment>
      <section className="mainDetailsSection">
        { vulnerabilityScannerLogo( vulnerabilityScanner.tool ) }
        <div className="column">
          <h2>{ vulnerabilityScannerNameMap[vulnerabilityScanner.tool] }</h2>
          <span><strong>Instance: </strong>{ vulnerabilityScannerInstanceName( vulnerabilityScanner ) }</span>
        </div>
      </section>
    </React.Fragment>
  );
};

export default VulnerabilityScannerItem;