/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import { ReportCreatorProvider } from '../../Contexts/ReportCreator';
import { InsightHistoryProvider } from '../../Contexts/InsightHistory';
import { ModelProvider } from '../../Contexts/Model';
import { CurrentUserProvider } from '../../Contexts/CurrentUser';
import { HelpProvider } from '../../Contexts/Help';
import { OnboardingWizardProvider } from '../../Contexts/OnboardingWizard';
import { LabelsProvider } from '../../Contexts/Labels';
import { RemediationProvider } from '../../Contexts/Remediation';
import { FlashMessageQueueProvider } from '../../Contexts/FlashMessageQueue';
import { RiskProvider } from '../../Contexts/Risk';

import React from 'react';
import { BreakpointProvider } from '../../Contexts/Breakpoint';
import { NavigationProvider } from '../../Contexts/Navigation';
import { TagsProvider } from '../../Contexts/Tags';
import { FullScreenVisualProvider } from '../../Contexts/FullScreenVisual';

const ContextProviderWrapper = ( { children } ) => {
  return (
    <BreakpointProvider>
      <HelpProvider>
        <RiskProvider>
          <ModelProvider>
            <FlashMessageQueueProvider>
              <CurrentUserProvider>
                <OnboardingWizardProvider>
                  <InsightHistoryProvider>
                    <LabelsProvider>
                      <RemediationProvider>
                        <ReportCreatorProvider>
                          <NavigationProvider>
                            <TagsProvider>
                              <FullScreenVisualProvider>
                                { children }
                              </FullScreenVisualProvider>
                            </TagsProvider>
                          </NavigationProvider>
                        </ReportCreatorProvider>
                      </RemediationProvider>
                    </LabelsProvider>
                  </InsightHistoryProvider>
                </OnboardingWizardProvider>
              </CurrentUserProvider>
            </FlashMessageQueueProvider>
          </ModelProvider>
        </RiskProvider>
      </HelpProvider>
    </BreakpointProvider>
  );
};

export default ContextProviderWrapper;