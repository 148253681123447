/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue';
import {
  HelpTrigger,
} from '../../../HelpDocumentation/ContextualHelp/index.js';
import Form from '../../../../shared/Form';
import { isEmpty, isNotEmpty } from '../../../../shared/Utilities';
import { getFieldValues, normalizedAttribute } from '../../../../shared/Form/Shared';
import './IntegrationForm.scss';
import Loading from '../../../../shared/Loading';
import { makeRequest } from '../../../../../legacy/io';
import { isEmailAddress } from '../../../../shared/Form/Validators';
import ConfigurationAlert from '../../../../shared/ConfigurationAlert';

const IntegrationForm = ( {
  selectedRecord,
  isValid,
  setIsValid,
  updatedForm,
  setUpdatedForm,
  setCachedProjects,
} ) => {

  const projectField = {
    type: 'select',
    label: 'Jira Project',
    attribute: 'jira_project',
    defaultValue: '',
    required: true,
    // needs to be replaced with actual projects if connection was successful
    options: {},
    help: <HelpTrigger helpKey="project" />,
  };

  const EMPTY_FIELDS = {
    selector: {
      fields: [
        {
          type: 'select',
          label: 'Integration Tool',
          attribute: 'tool',
          defaultValue: 'jira',
          options: {
            'jira': 'Atlassian Jira',
            'email': 'Ticketing via email',
          },
          disabled: isNotEmpty( selectedRecord ),
          help: <HelpTrigger helpKey="tool" />,
        },
      ],
    },

    'jira': {
      fields: [
        {
          includeIf: { attribute: 'tool', value: 'jira'},
          type: 'contentBlock',
          attribute: 'jira_help',
          defaultValue: <p>
            Enter  your Jira URL and then create a new Jira user with the
            name of <strong>DeepSurface Reporter</strong> and ensure that this user is part of
            the default <strong>jira-software-users group</strong>. Once this user is created, add the credentials for
            that user below and test the connection in order to retrieve the possible projects
          </p>,
        },
        {
          includeIf: { attribute: 'tool', value: 'jira'},
          disableIf: { attribute: 'jira_project', check: isNotEmpty },
          type: 'text',
          label: 'Label',
          attribute: 'jira_label',
          defaultValue: '',
          help: <HelpTrigger helpKey="label" />,
        },
        {
          includeIf: { attribute: 'tool', value: 'jira'},
          disableIf: { attribute: 'jira_project', check: isNotEmpty },
          type: 'text',
          label: 'Jira URL',
          attribute: 'jira_domain',
          defaultValue: '',
          required: true,
          help: <HelpTrigger helpKey="url" />,
        },
        {
          includeIf: { attribute: 'tool', value: 'jira'},
          disableIf: { attribute: 'jira_project', check: isNotEmpty },
          type: 'text',
          label: 'Jira Username',
          attribute: 'jira_username',
          defaultValue: '',
          required: true,
          help: <HelpTrigger helpKey="username" />,
        },
        {
          includeIf: { attribute: 'tool', value: 'jira'},
          disableIf: { attribute: 'jira_project', check: isNotEmpty },
          type: 'password',
          removeGenerate: true,
          label: 'JIRA API TOKEN',
          defaultValue: '',
          attribute: 'jira_secret',
          required: true,
          help: <HelpTrigger helpKey="password" />,
        },
      ],
    },
    'email': {
      fields: [
        {
          includeIf: { attribute: 'tool', value: 'email' },
          type: 'text',
          label: 'Label',
          attribute: 'email_email_label',
          defaultValue: '',
          help: <HelpTrigger helpKey="label" />,
        },
        {
          includeIf: { attribute: 'tool', value: 'email' },
          type: 'email',
          label: 'Destination Email Address',
          attribute: 'email_destination_email_address',
          defaultValue: '',
          validators: [ isEmailAddress ],
          required: true,
          help: <HelpTrigger helpKey="destination_email_address" />,
        },
        {
          includeIf: { attribute: 'tool', value: 'email' },
          type: 'text',
          label: 'Subject Prefix',
          attribute: 'email_subject_prefix',
          defaultValue: '[DeepSurface]',
          help: <HelpTrigger helpKey="subject_prefix" />,
        },
        // commenting out html option, currently unsupported
        // {
        //   includeIf: { attribute: 'tool', value: 'email' },
        //   type: 'checkbox',
        //   label: 'Format messages as HTML?',
        //   attribute: 'email_format_as_html',
        //   defaultValue: false,
        //   help: <HelpTrigger helpKey="format_as_html" />,
        // },
      ],
    },
  };

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const [ loading, setLoading ] = React.useState( false );
  const [ fields, setFields ] = React.useState( null );

  const [ showEmailAlert, setShowEmailAlert ] = React.useState( false );

  React.useEffect( () => {
    setFields( EMPTY_FIELDS );
  }, [ selectedRecord ] );

  // when the form changes, check to see if the email alert message is needed
  React.useEffect( ( ) => {
    if ( isNotEmpty( updatedForm ) && isNotEmpty( updatedForm.fieldStates ) ) {
      const values = getFieldValues( updatedForm.fieldStates, 'integration' );
      if ( values.tool === 'email' ) {
        setShowEmailAlert( true );
      } else {
        setShowEmailAlert( false );
      }
    }
  }, [ updatedForm ] );

  // called from the test button that conditionally appears at the bottom of the form if all fields are valid
  const getIntegrationProjects = async ( ) => {
    setLoading( true );

    const { fieldStates } = updatedForm;

    if ( isNotEmpty( fieldStates ) ) {
      const values = getFieldValues( fieldStates, 'integration' );

      const _fields = { ...EMPTY_FIELDS };

      // update the default values of each field to the previous default value so that the form is in sync with
      // the value displayed to the user
      _fields.selector.fields.map( f => {
        f.defaultValue = values[f.attribute];
      } );
      _fields.jira.fields.map( f => {
        f.defaultValue = values[ normalizedAttribute( f, 'integration' ) ];
      } );

      // grab current projects
      const projectsResponse = await makeRequest( 'FETCH', '/jira_projects', {
        username: values.username,
        domain: values.domain,
        secret: values.secret,
      } );

      const projectOptions = {};

      if (
        isNotEmpty( projectsResponse )
        && projectsResponse.status === 'ok'
        && isNotEmpty( projectsResponse.results )
      ) {
        setCachedProjects( projectsResponse.results );
        addFlashMessage( {
          type: 'success',
          body: 'Successfully connected to Third Party Integration',
        } );

        projectsResponse.results.map( project => {
          projectOptions[project.project_key] = project.project_name;
        } );

        projectField.options = projectOptions;

        // disable all the fields before adding the project, we don't want the user messing up this integration
        _fields.jira.fields.map( f => {
          f.disabled = true;
        } );

        // add the project field
        _fields.jira.fields = [ ..._fields.jira.fields, projectField ];

        // reset the fields (and form)
        setFields( _fields );
      // there was an error, in the old way, display it to the user
      } else if (
        isNotEmpty( projectsResponse )
        && isNotEmpty( projectsResponse.errors )
      ) {
        projectsResponse.errors.map( ( e ) => {
          addFlashMessage( {
            type: 'alert',
            body: e?.desc || 'there was an error connecting to the api',
          } );
        } );
      // there was an error, in the new way
      } else if (
        isNotEmpty( projectsResponse )
        && projectsResponse.status === 'error'
      ) {
        if ( isNotEmpty( projectsResponse.errors ) ) {
          projectsResponse.errors.map( ( e ) => {
            addFlashMessage( {
              type: 'alert',
              body: e?.desc || 'there was an error connecting to the api',
            } );
          } );
        }
      // catch all
      } else {
        addFlashMessage( {
          type: 'alert',
          // eslint-disable-next-line max-len
          body: 'Your Integration credentials did not return any projects. Please ensure that you have entered the correct credentials and that the Jira integration has projects configured.',
        } );
      }
    }
    setLoading( false );
  };

  const shouldShowFetchButton = () => {
    if ( isNotEmpty( updatedForm ) ) {
      const { fieldStates } = updatedForm;

      const values = getFieldValues( fieldStates, 'integration' );

      if ( isNotEmpty( values ) ) {
        return isEmpty( values.project ) && isValid && values.tool === 'jira';
      }
      return false;
    }
    return false;
  };

  return (
    <React.Fragment>
      { loading && <Loading text="Fetching projects..." /> }
      <Form
        fields={fields}
        onChangeCallback={setUpdatedForm}
        existingRecord={selectedRecord}
        recordType={'integration'}
        setIsValid={setIsValid}
      />
      {
        showEmailAlert &&
        <ConfigurationAlert
          sourceType="email"
          overrideWith={
            // eslint-disable-next-line max-len
            <p>The most recent attempt to send email resulted in an SMTP failure. Please see the <a href="#.=activity&page=configuration_alerts&source=email"> relevant configuration alerts </a> for more information.</p>
          }
        />
      }
      {
        ( isNotEmpty( selectedRecord ) && isNotEmpty( selectedRecord ) && selectedRecord.tool === 'jira' )
          ? <div className="selectedProjectInformation">
            <label>Selected Project</label>
            {
              isNotEmpty( selectedRecord.project_avatar )
                ? <img
                  className="projectAvatar"
                  src={ selectedRecord.project_avatar }
                  alt={ selectedRecord.project_name }
                />
                : <span
                  className="projectAvatar initials"
                >
                  { selectedRecord?.project ? selectedRecord?.project[0] : 'N/A' }
                </span>
            }
            <div className="projectInfo">
              <span className="projectTitle">{ selectedRecord.project_name }</span>
              <strong className="projectID">ID: { selectedRecord.project_id }</strong>
            </div>
          </div>
          : <React.Fragment>
            {
              shouldShowFetchButton() &&
              <button
                onClick={ () => getIntegrationProjects() }
                disabled={ loading }
                className={`${loading ? 'disabled' : ''} fetchProjectsButton`}
              >
                Fetch Projects
              </button>
            }
          </React.Fragment>
      }
    </React.Fragment>
  );
};

export default IntegrationForm;