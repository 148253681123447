/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import InlineSVG from '../../../shared/InlineSVG';
import Draggable from 'react-draggable';
import ExploreItem from './ExploreItem';
import { isNotEmpty } from '../../../shared/Utilities';

import './PathsFromHereCard.scss';

const PathsFromHereCard = ( {
  disabled,
  show,
  collapsed,
  setCollapsed,
  pathsFromHere,
  displayExploreItemNameFor,
  setExternalHoverIDs,
  selectedPathID,
  setSelectedPathID,
} ) => {
  return (
    <React.Fragment>
      {
        ( show() && isNotEmpty( pathsFromHere ) ) &&
        <Draggable
          disabled={disabled}
          handle=".pathsFromHereCardHeader"
        >
          <div className={ `pathsFromHereDraggableCard ${ collapsed ? 'collapsed' : ''}` }>
            <div className="pathsFromHereCardHeader">
              <h2>
                <span>
                  <InlineSVG
                    elementClass="itemTypeIcon"
                    type="pathsAlt"
                  />
                  <span>Paths from here</span>
                  {
                    collapsed &&
                    <span className="selectedCount">({pathsFromHere.length} items)</span>
                  }
                </span>
                <div className="headerActions">
                  <button
                    onClick={ () => setCollapsed( !collapsed ) }
                    className="panelCollapseButton"
                  >
                    {
                      collapsed
                        ? 'Expand'
                        : 'Collapse'
                    }
                  </button>
                  {
                    !collapsed &&
                    <InlineSVG type="draggable" elementClass="draggableIcon" />
                  }
                </div>

              </h2>
            </div>
            <ul>
              {
                pathsFromHere.map( ( path, index ) => {
                  return <ExploreItem
                    key={index}
                    item={path}
                    type="path"
                    displayExploreItemNameFor={displayExploreItemNameFor}
                    setExternalHoverIDs={setExternalHoverIDs}
                    standalone
                    noRisk
                    selectedPathID={ selectedPathID }
                    setSelectedPathID={ setSelectedPathID }
                  />;
                } )
              }
            </ul>
          </div>
        </Draggable>
      }
    </React.Fragment>
  );
};

export default PathsFromHereCard;