/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, isNotEmpty } from '../../Utilities';

import './style.scss';

const YAxisLabels = ( {
  yMax,
  // height,
  ticsCount=3,
  ticFormatter=tic => formatNumber( Math.round( tic ) ),
} ) => {

  const [ tics, setTics ] = React.useState( null );

  React.useEffect( ( ) => {
    if ( isNotEmpty( yMax ) && isNotEmpty( ticsCount ) ) {
      const _tics = [];

      let currentTic = ticsCount;
      let currentValue = yMax;


      while ( currentTic >= 1 ) {

        _tics.push( ticFormatter( currentValue ) );
        currentTic -= 1;
        currentValue = yMax * ( currentTic / ticsCount );
      }

      setTics( _tics );
    }
  }, [ yMax, ticsCount ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( tics ) &&
        <div className={ `axisContainer yAxis ticCountClass--${ticsCount}` } >
          {
            tics.map( ( tic, i ) => {
              return <div className="axisTic" key={i}>
                { tic }
              </div>;
            } )
          }
        </div>
      }
    </React.Fragment>
  );
};

export default YAxisLabels;