/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { defaultTagColor, isEmpty, isNotEmpty, tagColorDarkLabels } from '../../../shared/Utilities';

const TagItem = ( { tag } ) => {

  const colorForBackGround = bg => {
    if ( tagColorDarkLabels.includes( bg ) || isEmpty( bg ) ) {
      return '#000';
    }
    return '#FFF';
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( tag ) &&
        <li
          className="assetTagListItem"
          style={
            {
              background: tag.color || defaultTagColor,
              color: colorForBackGround( tag.color ),
            }
          }
        >
          { !tag.truncated && tag.label }
        </li>
      }
    </React.Fragment>
  );
};

export default TagItem;