/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import SetupPage from '../../../shared/SetupComponents/SetupPage';
import { HelpTrigger } from '../../../components/HelpDocumentation/ContextualHelp/index.js';

import {
  isEmpty,
  isNotEmpty,
  credentialDisplayName,
  isTruthy,
  uniqueArray,
} from '../../../shared/Utilities';

import {
  recordData,
} from './data';

import './style.scss';

import { OnboardingWizardContext } from '../../../Contexts/OnboardingWizard';
import { getFieldValues } from '../../../shared/Form/Shared';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import { isInteger } from '../../../shared/Form/Validators';
import { makeRequest } from '../../../../legacy/io';
import RatingBadge from '../../../shared/RatingBadge';

const ScanGroups = () => {
  // eslint-disable-next-line no-unused-vars
  const [ credentials, setCredentials ] = React.useState( [] );
  // eslint-disable-next-line no-unused-vars
  const [ defaultScanSettings, setDefaultScanSettings ] = React.useState( {} );
  // eslint-disable-next-line no-unused-vars
  const [ subordinates, setSubordinates ] = React.useState( [] );
  const [ scanGroups, setScanGroups ] = React.useState( [] );

  const [ fields, setFields ] = React.useState( null );

  const [ initialized, setInitialized ] = React.useState( false );

  // ContextualHelp getters and setters
  const [ , , refreshWizard, , , , , , , ] = React.useContext( OnboardingWizardContext );
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const validCredentialTypes = [
    'smb',
    'smb-wmi',
    'winrm-psrp',
    'ssh-password',
    'ssh-key',
  ];

  const EMPTY_FIELDS = {
    basic: {
      fields: [
        {
          type: 'text',
          label: 'Name',
          attribute: 'label',
          required: true,
          defaultValue: '',
          help: <HelpTrigger helpKey="label" />,
        },
        {
          type: 'select',
          label: 'Scanner',
          attribute: 'subordinate_scanner',
          defaultValue: 'null',
          help: <HelpTrigger helpKey="subordinate_scanner" />,
        },

        {
          type: 'textarea',
          label: 'Target Ranges',
          attribute: 'included_ranges',
          needsSplit: true,
          required: true,
          defaultValue: [],
          help: <HelpTrigger helpKey="included_ranges" />,
        },
        {
          type: 'textarea',
          label: 'Range Exclusions',
          attribute: 'excluded_ranges',
          needsSplit: true,
          defaultValue: [],
          help: <HelpTrigger helpKey="excluded_ranges" />,
        },
      ],
    },
    advanced: {
      fields: [
        {
          type: 'selectList',
          label: 'Credentials',
          attribute: 'credentials',
          needsDraggable: true,
          vertical: true,
          required: true,
          defaultValue: [],
          emptyMessage: <span>
            You must configure credentials <a href="#.=scanning&page=credentials">here</a> before
            creating a scan group
          </span>,
          help: <HelpTrigger helpKey="credentials" />,
        },
        {
          type: 'schedule',
          label: 'Schedules',
          attribute: 'schedule_settings',
          allowMultiple: true,
          defaultValue: { enabled: true, recurring: [] },
          help: <HelpTrigger helpKey="schedule" />,
        },
      ],
    },
    settings: {
      header: 'Scan Default Overrides',
      help: <HelpTrigger helpKey="scan_default_overrides" />,
      fields: [
        {
          type: 'override',
          defaultValue: { isOverridden: false },
          overrideInputType: 'duration',
          expectedUnit: 'h',
          label: 'Avoid re-scan period',
          attribute: 'avoid_rescan_period',
          htmlProps: { min: 0, step: 1 },
          help: <HelpTrigger helpKey="avoid_rescan_period" />,
        },
        {
          type: 'override',
          defaultValue: { isOverridden: false },
          overrideInputType: 'duration',
          expectedUnit: 'm',
          label: 'Maximum scan duration',
          attribute: 'maximum_duration',
          htmlProps: { min: 0, step: 1 },
          help: <HelpTrigger helpKey="maximum_duration" />,
        },
        {
          type: 'override',
          defaultValue: { isOverridden: false },
          overrideInputType: 'number',
          label: 'Scan parallelism',
          attribute: 'scan_workers',
          htmlProps: { min: 0, step: 1, max: 200 },
          help: <HelpTrigger helpKey="scan_workers" />,
        },

        {
          type: 'override',
          defaultValue: { isOverridden: false },
          overrideInputType: 'checkbox',
          label: 'Grant scanner MSSQL privileges',
          attribute: 'mssql_grant_privileges',
          help: <HelpTrigger helpKey="mssql_grant_privileges" />,
        },
        {
          type: 'override',
          defaultValue: { isOverridden: false },
          overrideInputType: 'checkbox',
          label: 'Collect domain information',
          attribute: 'scan_domains',
          help: <HelpTrigger helpKey="scan_domains" />,
        },
        {
          type: 'override',
          defaultValue: { isOverridden: false },
          overrideInputType: 'checkbox',
          label: 'WinRM: Allow HTTP Scanning',
          attribute: 'winrm_allow_http',
          help: <HelpTrigger helpKey="winrm_allow_http" />,
        },
        {
          type: 'override',
          defaultValue: { isOverridden: false },
          defaultInputValue: false,
          overrideInputType: 'checkbox',
          label: <React.Fragment>
            <span>Enable NAS Scan</span>
            <RatingBadge rating="beta" />
          </React.Fragment>,
          attribute: 'enable_nas_scan',
          help: <HelpTrigger helpKey="enable_nas_scan" />,
        },
        {
          type: 'override',
          defaultValue: { isOverridden: false },
          defaultInputValue: 1,
          overrideInputType: 'number',
          label: <React.Fragment>
            <span>NAS Scanning Depth</span>
            <RatingBadge rating="beta" />
          </React.Fragment>,
          attribute: 'nas_scan_depth',
          help: <HelpTrigger helpKey="nas_scan_depth" />,
          validators: [ isInteger ],
          htmlProps: { min: 1, step: 1, max: 10 },
        },
      ],
    },
  };
  React.useEffect( () => {
    initialize();
  }, [] );

  const initialize = async () => {
    let _credentials = await makeRequest( 'SEARCH', '/project/default/credential', {
      // eslint-disable-next-line camelcase
      extra_columns: [ 'protocol', 'domain', 'username', 'eval_order', 'options', 'label' ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'eval_order', 'ASC' ] ],
    } );
    // eslint-disable-next-line max-len
    _credentials = _credentials.results.filter( c => isNotEmpty( c.protocol ) && validCredentialTypes.includes( c.protocol ) );

    if ( isEmpty( _credentials ) ) {
      recordData.notifications = [ {
        type: 'alert',
        message: <p>
          You do not have any scan credentials configured. In order to
          create a scan group you must first configure at least
          one <a className="scanningLink" href="#.=scanning&page=credentials">here</a>.
        </p>,
      } ];
    } else {
      recordData.notifications = [];
    }

    const credOptions = {};
    _credentials.map( c => {
      return credOptions[c.id] = credentialDisplayName( c );
    } );

    const credInput = EMPTY_FIELDS.advanced.fields.find( i => i.attribute === 'credentials' );
    credInput.options = credOptions;
    credInput.defaultValue = Object.keys( credOptions );

    let _subordinates = await makeRequest( 'FIND', '/subordinate_scanner', {
      // eslint-disable-next-line camelcase
      field_map: { registration_code: null },
      // eslint-disable-next-line camelcase
      not_field_map: { type: 'local' },
    } );

    _subordinates = _subordinates.results;

    const subordinateOptions = { 'null': 'Local Scanner' };

    _subordinates.map( s => {
      subordinateOptions[s.id] = s.label;
    } );

    const subordinateInput = EMPTY_FIELDS.basic.fields.find( i => i.attribute === 'subordinate_scanner' );
    subordinateInput.options = subordinateOptions;

    let _settings = await makeRequest( 'FETCH', '/project/default', {} );
    _settings = _settings.results.scan_defaults;

    if ( isNotEmpty( _settings ) ) {
      Object.entries( _settings ).map( ( [ key, value ] ) => {
        const f = EMPTY_FIELDS.settings.fields.find( f => f.attribute === key );
        if ( isNotEmpty( f ) ) {
          let globalDefault = value;
          if ( f.overrideInputType === 'checkbox' ) {
            globalDefault = isTruthy( value );
          }
          f.defaultValue = { ...f.defaultValue, globalDefault };
        }
      } );
    }

    setDefaultScanSettings( _settings );

    setCredentials( _credentials );
    setSubordinates( _subordinates );

    setFields( EMPTY_FIELDS );
    setInitialized( true );
  };

  const onRefresh = async ( ) => {
    let _scanGroups = await makeRequest( 'FIND', '/project/default/scan_group', {} );

    _scanGroups = _scanGroups.results.sort( ( a, b ) => {
      // eslint-disable-next-line
      return ( a.label > b.label ) ? 1 : ( ( b.label > a.label ) ? -1 : 0 );
    } );

    setScanGroups( _scanGroups );
  };

  const onSave = async (
    scanGroup,
    isValid,
    fieldStates,
    successCallback,
  ) => {
    if ( isValid && isNotEmpty( fieldStates ) ) {

      let _credentials = await makeRequest( 'SEARCH', '/project/default/credential', {
        // eslint-disable-next-line camelcase
        extra_columns: [ 'protocol', 'domain', 'username', 'eval_order', 'options', 'label' ],
        // eslint-disable-next-line camelcase
        order_by: [ [ 'eval_order', 'ASC' ] ],
      } );
      // eslint-disable-next-line max-len
      _credentials = _credentials.results.filter( c => isNotEmpty( c.protocol ) && validCredentialTypes.includes( c.protocol ) );

      _credentials = _credentials.map( cred => cred.id );

      // nested scan groups settings
      const scanGroupSettingsAttrs = [
        'avoid_rescan_period',
        'maximum_duration',
        'scan_workers',
        'mssql_grant_privileges',
        'scan_domains',
        'winrm_allow_http',
        'enable_nas_scan',
        'nas_scan_depth',
      ];

      const needsRounding = [
        'scan_workers',
      ];

      const _scanGroup = {};

      const includedValues = getFieldValues( fieldStates, 'scan_group' );

      const _settings = {};

      Object.keys( includedValues ).map( attr => {
        if ( scanGroupSettingsAttrs.includes( attr ) ) {
          if ( includedValues[attr]?.isOverridden ) {

            // special rounding logic for number values
            if ( needsRounding.includes( attr ) ) {
              _settings[attr] = Math.round( includedValues[attr].overrideValue );
            } else {
              _settings[attr] = includedValues[attr].overrideValue;
            }
          }
        } else if ( attr === 'subordinate_scanner' && includedValues[attr] === 'null' ) {
          _scanGroup[attr] = null;
        } else if ( attr === 'schedule_settings' ) {

          // we don't allow duplicate schedules, uniqueArray is a handy deduping function
          includedValues[attr].recurring = uniqueArray( includedValues[attr].recurring );

          _scanGroup[attr] = includedValues[attr];
          // need to remove any invalid credentials that were since deleted
        } else if ( attr === 'credentials' ) {
          const allowedCreds = [];

          const creds = includedValues.credentials;

          creds.map( cred => {
            if ( _credentials.includes( cred ) ) {
              allowedCreds.push( cred );
            }
          } );
          _scanGroup.credentials = allowedCreds;
        } else if ( attr === 'included_ranges' || attr === 'excluded_ranges' ) {
          // need to make sure an empty value for included/excluded ranges is an array, not a string
          if ( isEmpty( includedValues[attr] ) ) {
            _scanGroup[attr] = [];
          } else {
            _scanGroup[attr] = includedValues[attr];
          }
        } else {
          _scanGroup[attr] = includedValues[attr];
        }
      } );

      _scanGroup.settings = _settings;

      // if secret is not being modified, don't replace it with an empty string
      if ( scanGroup ) {
        // we are updating, add the id
        _scanGroup.id = scanGroup.id;
      }

      makeRequest( 'UPSERT', '/project/default/scan_group', { 'records': [ _scanGroup ] } ).then( response => {
        onRefresh();
        if ( response['errors'] ) {
          addFlashMessage( {
            body: response['errors'],
            type: 'alert',
          } );
        } else {
          refreshWizard();
          addFlashMessage( {
            body: 'Successfully saved scan group settings',
            type: 'success',
          } );
          successCallback();
        }
      } );
    }
  };

  return (
    <React.Fragment>
      {
        initialized &&
        <SetupPage
          onRefresh={onRefresh}
          onSave={onSave}
          records={scanGroups}
          recordType="scan_group"
          recordData={recordData}
          modalClass="scanGroupModal"
          alternateItemLayout
          useForm
          fields={fields}
          setRecords={setScanGroups}
          allowEnterSubmit={false}
        />
      }
    </React.Fragment>
  );
};

export default ScanGroups;
