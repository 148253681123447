/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty, reportTypeDisplayName, riskToRating } from '../../../../shared/Utilities';
import InlineSVG from '../../../../shared/InlineSVG';
import RatingBadge from '../../../../shared/RatingBadge';
import RiskReduction from '../../../../shared/RiskReduction';

const PatchHeader = ( { patch, options } ) => {
  return (
    <React.Fragment>
      {
        isNotEmpty( patch ) &&
        <React.Fragment>
          <div className="recordCardHeaderRow">
            <h2>
              <InlineSVG type="patchesAlt" elementClass="recordTypeHeaderIcon" />
              <span className="label">{ reportTypeDisplayName( patch, 'patch' ) }</span>
            </h2>
            {
              ( isNotEmpty( options ) && 'includeRisk' in options )
                ? <React.Fragment>
                  {
                    options.includeRisk === true &&
                    <RatingBadge rating={ riskToRating( patch.risk ? patch.risk : patch.direct_risk ) } />
                  }
                </React.Fragment>
                : <RatingBadge rating={ riskToRating( patch.risk ? patch.risk : patch.direct_risk ) } />
            }
          </div>
          <div className="recordCardHeaderRow">
            <div className="left">
              <a
                // eslint-disable-next-line max-len
                href={ `#.=risk_insight&report=patches&risk_type=risk&item_count=100&superseded=unsuperseded&sort_by=risk&sort_direction=DESC&accepted_risk=any&item=${patch.id}&current_page=1` }
                target="_blank"
                rel="noreferrer noopener"
              >
                Learn more
                <InlineSVG type="newTabLink" />
              </a>
            </div>
            {
              ( isNotEmpty( options ) && 'includeRisk' in options )
                ? <React.Fragment>
                  {
                    options.includeRisk === true &&
                    <RiskReduction item={patch} riskType={ patch.risk ? 'risk' : 'direct_risk' } />
                  }
                </React.Fragment>
                : <RiskReduction item={patch} riskType={ patch.risk ? 'risk' : 'direct_risk' } />
            }
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default PatchHeader;