/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { globalColors, isNotEmpty } from '../../../../../shared/Utilities';
import { v2Fetches } from '../../shared';
import EmptyLoading from '../../../../../shared/EmptyLoading';
import Donut from '../../../../../shared/Charts/Donut';
import Legend from '../../../../../shared/Charts/Legend';

import './OSFamilyBreakdown.scss';

const OSFamilyBreakdown = ( { prefetchedData, settings } ) => {

  const [ osFamilyTotal, setOSFamilyTotal ] = React.useState( 1 );
  const [ osFamilyData, setOSFamilyData ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( false );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );

  const palette = {
    macos: '#add4f8',
    windows: '#087dea',
    unix: '#5aa8f1',
    unknown: globalColors['grey--divider'],
  };

  const familyNameMap = {
    windows: 'Windows',
    unix: 'Unix',
    macos: 'macOS',
    unknown: 'Unknown',
  };

  const setupData = data => {
    const _osFamilyData = {};
    const total = data._total || 1;

    Object.entries( data.os_family ).map( ( [ familyKey, count ] ) => {
      _osFamilyData[familyKey] = {
        label: familyNameMap[familyKey],
        stroke: palette[familyKey],
        value: ( count / total ) * 100,
        originalCount: count,
        total: count,
        key: familyKey,
      };
    } );

    setOSFamilyTotal( total );
    setOSFamilyData( _osFamilyData );
  };

  const refetchData = async () => {
    setLoading( true );
    const _tallyResults = await v2Fetches.tally_os();
    setLoading( false );

    if ( isNotEmpty( _tallyResults ) ) {
      setupData( _tallyResults.results );
    }
  };

  React.useEffect( () => {
    if (
      isNotEmpty( prefetchedData )
      && isNotEmpty( prefetchedData.tally_os )
      && isNotEmpty( prefetchedData.tally_os.results )
      && isNotEmpty( prefetchedData.tally_os.results.os_family )
    ) {
      setupData( prefetchedData.tally_os.results );
    } else {
      refetchData();
    }
  }, [ prefetchedData, settings ] );

  return (
    <React.Fragment>
      <EmptyLoading
        payload={ osFamilyData }
        loading={ loading }
        loadingMessage="Loading OS Data"
        emptyMessage="No OS Data"
      />
      {
        ( isNotEmpty( osFamilyTotal ) && isNotEmpty( osFamilyData ) ) &&
        <React.Fragment>
          <Donut
            data={osFamilyData}
            total={osFamilyTotal}
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
          <Legend
            legendData={ osFamilyData }
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default OSFamilyBreakdown;