/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, isNotEmpty } from '../../../../shared/Utilities';
import InlineSVG from '../../../../shared/InlineSVG';

import './RecordStat.scss';

const RecordStat = ( { statKey, statValue } ) => {

  const labelForStat = {
    /* eslint-disable camelcase */
    num_unsuperseded_patches: 'Patches',
    num_vulnerabilities: 'Vulnerabilities',
    vulnerabilities: 'Vulnerabilities',

    hosts: 'Affected Hosts',
    patches: 'Patches',
    /* eslint-enable camelcase */
  };

  const iconForStat = {
    /* eslint-disable camelcase */
    num_unsuperseded_patches: <InlineSVG type="patchesAlt" />,
    num_vulnerabilities: <InlineSVG type="vulnerabilitiesAlt" />,

    hosts: <InlineSVG type="hostsAlt" />,
    patches: <InlineSVG type="patchesAlt" />,
    vulnerabilities: <InlineSVG type="vulnerabilitiesAlt" />,
    /* eslint-enable camelcase */
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( statKey ) &&
        <div className="recordStatistic">
          <label>
            { labelForStat[statKey] }
          </label>
          <div className="recordStatisticValue">
            { iconForStat[statKey] }
            { formatNumber( statValue ) }
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default RecordStat;