/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { defaultTagColor, isEmpty, isNotEmpty, tagColorDarkLabels } from '../../../shared/Utilities';

import './TagItem.scss';
import { getRecords } from '../../../shared/RecordCache';

const TagItem = ( { tag, users } ) => {

  const [ owner, setOwner ] = React.useState( null );
  const [ tagString, setTagString ] = React.useState( null );

  const buildTagString = async ( tag ) => {

    const hostIDs = [ ...tag.included_host_ids, ...tag.excluded_host_ids ];
    let hosts;

    if ( isNotEmpty( hostIDs ) ) {
      // eslint-disable-next-line camelcase
      hosts = await getRecords( 'scope', { id_list: hostIDs } );
    }
    const _string = [];
    if ( isNotEmpty( tag.included_ranges ) ) {
      _string.push(
        <span className="included">
          IP Ranges <code>{ tag.included_ranges.join( ', ' ) }</code>
        </span>,
      );
    }
    if ( isNotEmpty( tag.included_host_patterns ) ) {
      _string.push(
        <span className="included">
          Host Wildcards <code>{ tag.included_host_patterns.join( ', ' ) }</code>
        </span>,
      );
    }
    if ( isNotEmpty( tag.included_host_ids ) ) {
      _string.push(
        <span className="included">
          {/* eslint-disable-next-line max-len */}
          Specific Hosts <code>{ tag.included_host_ids.map( id => hosts.find( h => h.id === id ).label ).join( ', ' ) }</code>
        </span>,
      );
    }
    if ( isNotEmpty( tag.excluded_ranges ) ) {
      _string.push(
        <span className="excluded">
          IP Ranges <code>{ tag.excluded_ranges.join( ', ' ) }</code>
        </span>,
      );
    }
    if ( isNotEmpty( tag.excluded_host_patterns ) ) {
      _string.push(
        <span className="excluded">
          Host Wildcards <code>{ tag.excluded_host_patterns.join( ', ' ) }</code>
        </span>,
      );
    }
    if ( isNotEmpty( tag.excluded_host_ids ) ) {
      _string.push(
        <span className="excluded">
          {/* eslint-disable-next-line max-len */}
          Specific Hosts <code>{ tag.excluded_host_ids.map( id => hosts.find( h => h.id === id ).label ).join( ', ' ) }</code>
        </span>,
      );
    }
    setTagString( _string );
  };

  React.useEffect( ( ) => {
    if ( isNotEmpty( tag ) && isNotEmpty( tag.remediation_manager ) && isNotEmpty( users ) ) {
      buildTagString( tag );
      if ( isNotEmpty( users[tag.remediation_manager] ) ) {
        setOwner( users[tag.remediation_manager] );
      }
    }
  }, [ tag, users ] );

  const colorForBackGround = bg => {
    if ( tagColorDarkLabels.includes( bg ) || isEmpty( bg ) ) {
      return '#000';
    }
    return '#FFF';
  };

  return (
    <React.Fragment>
      <section className="mainDetailsSection">
        <div className="column">
          <h2 style={ {
            background: tag.color || defaultTagColor,
            color: colorForBackGround( tag.color ),
          } }
          >
            { tag.label }</h2>
          <span>
            <strong>Owner: </strong>
            { isNotEmpty( owner ) ? owner : 'N/A' }
          </span>
        </div>
        {
          isNotEmpty( tagString ) &&
          <div className="tagStringWrapper">
            {
              tagString.map( ( s, i ) => {
                return <React.Fragment key={i} >{ s }</React.Fragment>;
              } )
            }
          </div>
        }
      </section>
    </React.Fragment>
  );
};

export default TagItem;