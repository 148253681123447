/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, globalColors, isNotEmpty, numberToOrdinal } from '../../../../../shared/Utilities';
import Gauge from '../../../../../shared/Charts/Gauge';
import EmptyLoading from '../../../../../shared/EmptyLoading';

import './PeerPercentile.scss';
import { makeRequest } from '../../../../../../legacy/io';

const PeerPercentile = ( { prefetchedData, settings } ) => {


  const [ chartData, setChartData ] = React.useState( null );
  const [ currentRisk, setCurrentRisk ] = React.useState( null );
  const [ total, setTotal ] = React.useState( null );
  const [ fill, setFill ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( false );

  const getFill = percentile => {
    if ( percentile <= 0.25 ) {
      return globalColors['high'];
    }
    if ( percentile <= 0.5 ) {
      return globalColors['low'];
    }
    if ( percentile <= 0.75 ) {
      return globalColors['minimal'];
    }
    return globalColors['primaryBlue'];
  };

  const getFillClass = percentile => {
    if ( percentile <= 0.25 ) {
      return 'high';
    }
    if ( percentile <= 0.5 ) {
      return 'low';
    }
    if ( percentile <= 0.75 ) {
      return 'minimal';
    }
    return 'primaryBlue';
  };

  const setupData = async () => {
    setLoading( true );
    if (
      isNotEmpty( prefetchedData )
      && isNotEmpty( prefetchedData.percentile )
      && isNotEmpty( prefetchedData.percentile.results )
    ) {
      const current = prefetchedData.percentile.results[prefetchedData.percentile.results.length - 1];
      setChartData( current.percentile );
      setTotal( 1 );
      setCurrentRisk( Math.round( current.risk ) );
      setFill( getFill( current.percentile ) );
    } else {
      // eslint-disable-next-line
      const percentileRequest = await makeRequest( 'FETCH', '/analysis/industry_percentile_history', { project: 'default', model: 'base' } );

      const current = percentileRequest.results[percentileRequest.results.length - 1];
      setChartData( current.percentile );
      setTotal( 1 );
      setCurrentRisk( Math.round( current.risk ) );
      setFill( getFill( current.percentile ) );
    }
    setLoading( false );
  };

  React.useEffect( () => {
    setupData();
  }, [ prefetchedData, settings ] );

  return (
    <React.Fragment>
      <EmptyLoading
        payload={ chartData }
        loading={ loading }
        loadingMessage="Loading data"
        emptyMessage="No risk data"
      />
      {
        ( isNotEmpty( chartData ) && isNotEmpty( total ) && isNotEmpty( currentRisk ) && isNotEmpty( fill ) ) &&
        <div className={ `peerPercentileChartWrapper risk--${getFillClass( chartData ) }` }>
          <Gauge
            total={total}
            displayLabel
            data={
              {
                stroke: getFill( chartData ),
                value: ( chartData / total ) * 100,
                rawValue: numberToOrdinal( chartData ),
                displayLabel: 'Peer Percentile',
              }
            }
          />
          <h3 className="chartLabel">Peer Percentile</h3>
          <p className="chartDescription">
            <span>The risk score of </span>
            <strong style={ { color: fill } }>{ formatNumber( Math.round( currentRisk ) ) } </strong>
            <span>is better than </span>
            <strong style={ { color: fill } }>{ Math.round( chartData * 100 ) }% </strong>
            <span>of your peers.</span>
          </p>
        </div>
      }
    </React.Fragment>
  );
};

export default PeerPercentile;