/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty, itemIsArray, itemIsObject } from '../Utilities';
import RecordListItem from './RecordListItem';

import './style.scss';

const RecordList = ( {
  orderBy,
  records,
  reportType,
  sectionKey='',
  setSelectedSignature=() => {},
  setModalContext=() => {},
  setHoveredListItemID=() => {},
  setHoveredListItemType=() => {},
  setHoveredListItemRating=() => {},
} ) => {

  // const [ riskType, setRiskType ] = React.useState( 'risk' );
  const [ recordListRecords, setRecordListRecords ] = React.useState( [] );
  // const [ highestRisk, setHighestRisk ] = React.useState( 0 );

  const [ maxAttributeValue, setMaxAttributeValue ] = React.useState( 1 );
  const [ orderByAttribute, setOrderByAttribute ] = React.useState( 'risk' );

  React.useEffect( () => {
    if ( isNotEmpty( records ) && isNotEmpty( reportType ) ) {
      let _records;
      if ( itemIsObject( records ) ) {
        _records = [ ...Object.values( records ) ];
      } else if ( itemIsArray( records ) ) {
        _records = [ ...records ];
      }

      if ( isNotEmpty( _records ) ) {

        const [ first ] = _records;

        // going to always assume risk, but also want to allow for other ordering/sorting methods
        // the records will always be passed in pre-ordered... but need to know what attr to highlight and how to fill
        // each bar

        // we are going to support of few at onset and go from there.

        // 1. risk/direct_risk/filtered_risk (default)
        // 2. num_vulnerabilities
        // 3. num_hosts
        // 4. num_patches
        // 5. cvss_base_score
        // 6. exploit_status

        const riskTypes = [
          'risk',
          'filtered_risk',
          'direct_risk',
        ];

        // const numTypes = [
        //   'num_hosts',
        //   'num_vulnerabilities',
        //   'num_patches',
        // ];

        // const otherTypes = [
        //   'cvss_base_score',
        //   'exploit_status',
        // ];


        let _orderByAttribute = 'risk';

        if ( isNotEmpty( orderBy ) ) {
          // it is a risk types
          if ( riskTypes.includes( orderBy ) ) {
            if ( reportType === 'patches' ) {
              if ( first.filtered_risk ) {
                _orderByAttribute = 'filtered_risk';
              } else if ( first.risk ) {
                _orderByAttribute = 'risk';
              } else {
                _orderByAttribute = 'direct_risk';
              }
            } else if ( first.filtered_risk ) {
              _orderByAttribute = 'filtered_risk';
            } else {
              _orderByAttribute = 'risk';
            }
          } else {
            _orderByAttribute = orderBy;
          }
        } else if ( reportType === 'patches' ) {
          if ( first.filtered_risk ) {
            _orderByAttribute = 'filtered_risk';
          } else if ( first.risk ) {
            _orderByAttribute = 'risk';
          } else {
            _orderByAttribute = 'direct_risk';
          }
        } else if ( first.filtered_risk ) {
          _orderByAttribute = 'filtered_risk';
        } else {
          _orderByAttribute = 'risk';
        }

        // const _highestRisk = Math.max( ..._records.map( r => r[_orderBy] ) );
        const _maxAttributeValue = Math.max( ..._records.map( r => r[_orderByAttribute] ) );
        setMaxAttributeValue( _maxAttributeValue );
        setRecordListRecords( _records );
        setOrderByAttribute( _orderByAttribute );
      }
    }
  }, [ records, reportType, orderBy ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( recordListRecords ) && isNotEmpty( reportType ) && isNotEmpty( orderByAttribute ) ) &&
        <ul className={ `recordList ${reportType} ${sectionKey} orderBy-${orderByAttribute}`}>
          {
            recordListRecords.map( ( record, index ) => {
              return <RecordListItem
                key={index}
                record={record}
                reportType={reportType}
                orderByAttribute={orderByAttribute}
                maxAttributeValue={maxAttributeValue}
                setSelectedSignature={setSelectedSignature}
                setModalContext={setModalContext}
                setHoveredListItemID={setHoveredListItemID}
                setHoveredListItemType={setHoveredListItemType}
                setHoveredListItemRating={setHoveredListItemRating}
              />;
            } )
          }
        </ul>
      }
    </React.Fragment>
  );
};

export default RecordList;