/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import InlineSVG from '../../../../InlineSVG';
import { isNotEmpty } from '../../../../Utilities';

const SelectedItemWrapper = ( {
  id,
  order,
  items,
  setItems,
  field,
  index,
  onChange,
} ) => {

  // removes item from group
  const removeItem = e => {
    e.preventDefault();
    e.stopPropagation();

    const _items = { ...items };
    delete _items[id];
    setItems( _items );

    if ( isNotEmpty( _items ) ) {
      onChange( field, Object.keys( _items ) );
    } else {
      onChange( field, [] );
    }
  };

  return (
    <React.Fragment>
      {
        field.needsDraggable
          ? <Draggable draggableId={`record-draggable-${index}`} index={index}>
            {
              ( provided, snapshot ) => (
                <li
                  className={`selectedItemWrapper isDraggable ${snapshot.isDragging ? 'isDragging' : ''}`}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <span>{ order }</span>
                  { items[id] }
                  <button
                    className="removeButton"
                    onClick={ removeItem }
                  >
                    <InlineSVG type="remove" />

                  </button>
                  <button className="draggableHandle">
                    {
                      snapshot.isDragging
                        ? <InlineSVG type="draggable" version="primary" />
                        : <InlineSVG type="draggable" version="dark" />
                    }
                  </button>
                </li>
              )
            }
          </Draggable>
          : <li className="selectedItemWrapper">
            { field.needsDraggable &&
              <span>{ order }</span>
            }
            { items[id] }
            <button
              onClick={ removeItem }
            >
              <InlineSVG type="remove" />
            </button>
          </li>
      }
    </React.Fragment>
  );
};

export default SelectedItemWrapper;
