/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../../shared/Utilities';

import './Text.scss';

const Text = ( { settings, prefetchedData } ) => {

  const [ text, setText ] = React.useState( null );
  const [ heading, setHeading ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( settings ) ) {
      setText( settings.content );
      setHeading( settings.heading );
    }
  }, [ settings, prefetchedData ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( heading ) &&
        <h2 className="widgetHeadingContentWrapper">{ heading }</h2>
      }
      {
        isNotEmpty( text ) &&
        <div className="textWidgetWrapper" >
          <p>{ text }</p>
        </div>
      }
    </React.Fragment>
  );
};

export default Text;