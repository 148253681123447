/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';

import DataTable from '../../../shared/DataTable';

import InlineSVG from '../../../shared/InlineSVG';
import ExpandingCell from '../../../shared/DataTable/ExpandingCell';

import {
  isNotEmpty,
  isEmpty,
  reportTypeDisplayName,
  riskToRating,
  formatUnixTime,
  formatNumber,
  decodeURLHash,
  paramsToFilters,
  removeFromURLHash,
  encodeURLHash,
  triggerHashRefresh,
  vulnScannerNameMap,
  vulnerabilityScannerLogo,
} from '../../../shared/Utilities';

import './style.scss';
import Loading from '../../../shared/Loading';
import ExploitStatus from '../../../shared/ExploitStatus';
import { hasAccess } from '../../App/AccessControl';
import InsightTableActions from './InsightTableActions';
import { defaultRemdiationItems, remediationItemsKey } from './shared';
import { RiskContext } from '../../../Contexts/Risk';
import RatingBadge from '../../../shared/RatingBadge';
import TagList from '../Tags/List';
import { TagsContext } from '../../../Contexts/Tags';
import RiskReduction from '../../../shared/RiskReduction';
import NotScannedBadge from '../../../shared/NotScannedBadge';
import { FullScreenVisualContext } from '../../../Contexts/FullScreenVisual';

const InsightTable = ( {
  records,
  reportType,
  loading=false,
  selectRecord,
  hoverRecord,
  setHoverRecord,
  onRefresh,
  refreshTable=() => {},
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection,
  visualCollapsed,
  riskType='risk',
  // pagination related vars
  nextRecords=[],
  currentPageNumber=1,
  // selectedSignature,
  setSelectedSignature,
} ) => {

  const [ , , showVisual, , showFullScreenVisual, , , ] = React.useContext( FullScreenVisualContext );

  // pagination scroll behavior
  const [ scrollToTableTop, setScrollToTableTop ] = React.useState( false );

  const [ adjustedData, setAdjustedData ] = React.useState( [] );

  const [ , , licenseInfo ] = React.useContext( CurrentUserContext );
  const [ , , targetRisk, ,  ] = React.useContext( RiskContext );

  // for remediation: { [groupType]: [ ...IDs ] }
  const [ remediationItems, setRemediationItems ] = React.useState( defaultRemdiationItems );

  const [ tags ] = React.useContext( TagsContext );

  // source of truth for which columns are sortable by report type (used for all except instances)
  const reportTypeSortableColumns = {
    /* eslint-disable camelcase */
    host: {
      risk: 'risk',
      name: 'label',
      'Last DeepSurface Scan': 'last_scanned',
    },
    vulnerability: {
      risk: 'risk',
      name: 'identifier',
      exploit_status: 'exploit_status',
      'CVSS': 'cvss_base_score',
    },
    path: {
      risk: 'risk',
    },
    patch: {
      risk: 'risk',
      name: 'label',
    },
    user: {
      risk: 'risk',
      name: 'name',
    },
    /* eslint-enable camelcase */
  };

  // source of truth for which columns are sortable by group type (used for instances)
  const instanceGroupingSortableColumns = {
    /* eslint-disable camelcase */
    host: {
      risk: 'filtered_risk',
      name: 'local_name',
      product_name: 'product_name',
      vulns: 'num_vulnerabilities',
      'Last DeepSurface Scan': 'last_scanned',
    },
    patch: {
      risk: 'filtered_risk',
      name: [ 'vendor', 'identifier' ],
      hosts: 'num_hosts',
      vulns: 'num_vulnerabilities',
    },
    vulnerability: {
      risk: 'filtered_risk',
      name: 'identifier',
      hosts: 'num_hosts',
      CVSS: 'cvss_base_score',
      exploit_status: 'exploit_status',
    },
    signature: {
      scanner: [ 'scanner', 'signature' ],
      risk: 'filtered_risk',
      hosts: 'num_hosts',
      vulns: 'num_vulnerabilities',
    },
    /* eslint-enable camelcase */
  };

  const formatIpAddresses = addresses => {
    const formatted = [];
    if ( addresses ) {
      addresses.map( address => {
        return formatted.push( address.split( '/' )[0] );
      } );
    }
    return formatted.join( ', ' );
  };

  const insightGoToRecord = ( row, _groupType ) => {
    let reportType;
    if ( _groupType === 'host' ) {
      reportType = 'hosts';
    } else if ( _groupType === 'patch' || _groupType === 'patch_cumulative' ) {
      reportType = 'patches';
    } else {
      reportType = 'vulnerabilities';
    }

    if ( isNotEmpty( _groupType ) ) {
      // eslint-disable-next-line max-len
      window.location.href = `#.=risk_insight&report=${reportType}&item_count=100&sort_by=risk&sort_direction=DESC&current_page=1&item=${row.id}${ reportType === 'patches' ? '&risk_type=risk' : ''}`;
    } else {
      selectRecord( row.id );
    }
  };

  const reportTypeNameLink = ( row, _groupType ) => {
    if (
      isEmpty( row.id ) ||
      (
        ( ( reportType === 'host' ) && !row.has_host )
        || ( reportType === 'instance' && _groupType === 'host' && !row.has_host )
      )
    ) {
      return <span className="notLink">
        {
          (
            ( ( reportType === 'host' ) && !row.has_host )
            || ( reportType === 'instance' && _groupType === 'host' && !row.has_host )
          ) &&
          <div className="iconWrapper notScanned">
            <InlineSVG type="notScanned" />
          </div>
        }
        { reportTypeDisplayName( row, _groupType ) }
      </span>;
    }
    return <div
      className="nameLink"
      onClick={ () => insightGoToRecord( row, _groupType ) }
    >
      {
        (
          ( ( reportType === 'host' ) && !row.has_host )
          || ( reportType === 'instance' && _groupType === 'host' && !row.has_host )
        ) &&
        <div className="iconWrapper notScanned">
          <InlineSVG type="notScanned" />
        </div>
      }
      { reportTypeDisplayName( row, _groupType || reportType ) }
    </div>;
  };

  const formatLastScanned = ( row, _groupType ) => {
    if ( isEmpty( _groupType ) ) {
      if ( isNotEmpty( row.last_scanned ) && row.last_scanned !== 0 ) {
        return formatUnixTime( row.last_scanned );
      }
    } else if ( _groupType === 'host' && !row.has_host ) {
      return <NotScannedBadge />;
    }
    return formatUnixTime( row.last_scanned );
  };

  // const formatLastScanned = ( row, _groupType ) => {
  //   if ( isEmpty( _groupType ) ) {
  //     if ( isNotEmpty( row.last_scanned ) && row.last_scanned !== 0 ) {
  //       return formatUnixTime( row.last_scanned );
  //     }
  //   } else if ( _groupType === 'host' && !row.has_host ) {
  //     return <NotScannedBadge />;
  //   }
  //   return formatUnixTime( row.last_scanned );
  // };

  // remediation selection functions
  const onRemediationSelect = id => {
    if ( includeRemediationSelect( licenseInfo ) ) {
      const rKey = remediationItemsKey( reportType );
      const selectedIDs = Object.keys( remediationItems[rKey] );
      if ( selectedIDs.includes( id ) ) {

        delete remediationItems[rKey][id];
        setRemediationItems( {
          ...remediationItems,
          [rKey]: remediationItems[rKey],
        } );
      } else {
        const newItem = records.find( r => r.id === id );
        setRemediationItems( {
          ...remediationItems,
          [rKey]: { ...remediationItems[rKey], [id]: newItem },
        } );
      }
    } else {
      return false;
    }
  };

  const setRemediationItemsForType = ids => {
    const rKey = remediationItemsKey( reportType );
    const _items = {};

    if ( isNotEmpty( ids ) ) {
      ids.map( id => {
        _items[id] = records.find( r => r.id === id );
      } );
    }
    setRemediationItems( { ...remediationItems, [rKey]: _items } );
  };

  // The add/remove button for each row in the table (instances)
  const AddRemoveFilterAction = ( { row } ) => {

    const hash = decodeURLHash();

    let _groupType = hash.group_type || 'host';

    if ( _groupType === 'patch_cumulative' ) {
      _groupType = 'patch';
    }

    const existingFilter = hash[`${_groupType}_ids`] && hash[`${_groupType}_ids`].includes( row.id );

    const addOrRemoveFilter = () => {
      if ( existingFilter ) {
        removeFromURLHash( `${_groupType}_ids` );
      } else {
        encodeURLHash( { [`${_groupType}_ids`]: JSON.stringify( [ row.id ] ) } );
      }
      triggerHashRefresh();
      onRefresh();
    };

    return (
      <button
        title={ existingFilter ? 'remove filter' : 'add filter' }
        className={`${ existingFilter ? 'removeFilterButton' : 'addFilterButton'}`}
        onClick={ () => addOrRemoveFilter() }
      >
        {
          existingFilter
            ? <InlineSVG type="removeFilter" elementClass="filterIcon" />
            : <InlineSVG type="addFilter" elementClass="filterIcon" />
        }
      </button>
    );
  };

  // formatted name of signature (instances)
  const ScannerSignature = ( { row, link=true } ) => {
    let scanner;

    if ( row.scanner in vulnScannerNameMap ) {
      scanner = vulnScannerNameMap[row.scanner];
    }

    if ( !link ) {
      return <p>{`${ scanner ?? row.scanner } - ${ row.signature }`}</p>;
    }

    return (
      <button
        title={`${ scanner } - ${ row.signature }`}
        className={ `relatedSignatureButton ${riskToRating( row?.risk ) }`}
        onClick={ () => setSelectedSignature( row ) }
      >
        { vulnerabilityScannerLogo( row?.scanner ) }
        <span className="name">{`${ scanner ?? row.scanner } - ${ row.signature }`}</span>
      </button>
    );
  };

  // The data returned from the server needs to be massaged and adjusted for display
  const transformRowData = row => {
    const riskRating = riskToRating(
      row[( reportType === 'host' || reportType === 'vulnerability' ) ? 'filtered_risk' : riskType], targetRisk,
    );

    // only used for instances
    const _groupType = paramsToFilters()['group_type'] || 'host';

    const _tags = {};

    if (
      isNotEmpty( tags )
      && reportType === 'host'
      && isNotEmpty( row )
      && isNotEmpty( row.asset_tags )
    ) {
      row.asset_tags.map( id => _tags[id] = tags[id] );
    }

    /* eslint-disable camelcase */
    switch ( reportType ) {
    case 'host':
      return {
        risk: <div className={ `rowRiskWrapper ${riskRating}` }>
          <RatingBadge item={ row } itemType={ reportType } altVersion rating={ riskToRating( row.filtered_risk ) } />
          <RiskReduction item={ row } itemType={ reportType } riskType="filtered_risk" />
        </div>,
        name: <React.Fragment>
          { reportTypeNameLink( row ) }
          <TagList tags={_tags} truncation={ 2 } />
        </React.Fragment>,
        'Last DeepSurface Scan': formatLastScanned( row, 'host' ),
        sensitive_assets: <span
          className={ `sensitiveAssetsCount ${ row.num_sensitive_nodes !== 0 ? 'hasAssets' : ''}`}
        >
          { formatNumber( row.num_sensitive_nodes || 0 ) }
        </span>,
        all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
        unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
        vulnerabilities: <span>{ formatNumber( row?.num_vulnerabilities || 0 ) }</span>,
        actions: <button className="selectRecordButton" onClick={ () => selectRecord( row.id ) }>
          <span>View</span>
          <InlineSVG type="carretRight" version="primary"/>
        </button>,
        id: row.id,
        originalRecord: row,
      };
    case 'vulnerability':
      return {
        risk: <div className={ `rowRiskWrapper ${riskRating}` }>
          <RatingBadge item={ row } itemType={ reportType } altVersion rating={ riskToRating( row.filtered_risk ) } />
          <RiskReduction item={ row } itemType={ reportType } riskType="filtered_risk" />
        </div>,
        name: reportTypeNameLink( row ),
        exploit_status: <ExploitStatus status={row.exploit_status} fullVersion={false} />,
        'CVSS': <span>{ row.cvss_base_score }</span>,
        affected_hosts: <span>{ formatNumber( row.num_hosts || row.hosts?.length || 0 ) }</span>,
        all_patches: <span>{ formatNumber( row?.num_patches || 0 ) }</span>,
        unsuperseded_patches: <span>{ formatNumber( row?.num_unsuperseded_patches || 0 ) }</span>,
        actions: <button className="selectRecordButton" onClick={ () => selectRecord( row.id ) }>
          <span>View</span>
          <InlineSVG type="carretRight" version="primary"/>
        </button>,
        id: row.id,
        originalRecord: row,
      };
    case 'patch':
      return {
        risk: <div className={ `rowRiskWrapper ${riskRating}` }>
          <RatingBadge item={ row } itemType={ reportType } altVersion rating={ riskToRating( row[riskType] ) } />
          <RiskReduction item={ row } itemType={ reportType } riskType={riskType} />
        </div>,
        name: reportTypeNameLink( row ),
        superseded_patches: <span>{ formatNumber( row?.num_supersedes || 0 ) }</span>,
        affected_hosts: <span>{ formatNumber( row?.num_hosts || 0 ) }</span>,
        vulnerabilities: <span>{ formatNumber( row?.num_vulnerabilities || 0 ) }</span>,
        actions: <button className="selectRecordButton" onClick={ () => selectRecord( row.id ) }>
          <span>View</span>
          <InlineSVG type="carretRight" version="primary"/>
        </button>,
        id: row.id,
        originalRecord: row,
      };
    case 'path':
      return {
        risk: <div className={ `rowRiskWrapper ${riskRating}` }>
          <RatingBadge item={ row } itemType={ reportType } altVersion rating={ riskToRating( row[riskType] ) } />
          <RiskReduction item={ row } itemType={ reportType } riskType={riskType} />
        </div>,
        name: reportTypeNameLink( row ),
        steps: <span>{ row.edges.length }</span>,
        // eslint-disable-next-line max-len
        actions: <button className="selectRecordButton" onClick={ () => selectRecord( row.id ) }>
          <span>View</span>
          <InlineSVG type="carretRight" version="primary"/>
        </button>,
        id: row.id,
        originalRecord: row,
      };
    case 'user':
      return {
        risk: <div className={ `rowRiskWrapper ${riskRating}` }>
          <RatingBadge item={ row } itemType={ reportType } altVersion rating={ riskToRating( row[riskType] ) } />
          <RiskReduction item={ row } itemType={ reportType } riskType={riskType} />
        </div>,
        name: reportTypeNameLink( row ),
        // eslint-disable-next-line max-len
        recently_accessed_hosts: <span>{ formatNumber( row.active_hosts?.length || 0 ) }</span>,
        domain_groups: <span>{ formatNumber( row.domain_groups?.length || 0 ) }</span>,
        actions: <button className="selectRecordButton" onClick={ () => selectRecord( row.id ) }>
          <span>View</span>
          <InlineSVG type="carretRight" version="primary"/>
        </button>,
        id: row.id,
        originalRecord: row,
      };
    case 'instance':
      switch ( _groupType ) {
      case 'host':
        return {
          risk: <div className={ `rowRiskWrapper ${riskRating}` }>
            <RatingBadge item={ row } itemType={ _groupType } altVersion rating={ riskToRating( row.filtered_risk ) } />
            <RiskReduction item={ row } itemType={ _groupType } riskType="filtered_risk" />
          </div>,
          // eslint-disable-next-line max-len
          name: reportTypeNameLink( row, _groupType ),
          vulns: formatNumber( row.num_vulnerabilities ),
          // eslint-disable-next-line camelcase
          product_name: row.product_name,
          addresses: <ExpandingCell cellContents={ formatIpAddresses( row?.addresses ) } skipSanitization />,
          // eslint-disable-next-line camelcase
          'Last DeepSurface Scan': formatLastScanned( row, _groupType ),
          id: row.id,
          originalRecord: row,
          actions: <React.Fragment>
            {
              (
                ( ( reportType === 'host' ) && !row.has_host )
                || ( reportType === 'instance' && _groupType === 'host' && !row.has_host )
              )
                ? <React.Fragment></React.Fragment>
                : <React.Fragment>
                  <AddRemoveFilterAction row={ row } />
                  {
                    ( isNotEmpty( row.id ) ) &&
                    <button className="selectRecordButton" onClick={ () => insightGoToRecord( row, _groupType ) }>
                      <span>View</span>
                      <InlineSVG type="carretRight" version="primary"/>
                    </button>
                  }
                </React.Fragment>
            }
          </React.Fragment>,
        };
      case 'patch':
        return {
          risk: <div className={ `rowRiskWrapper ${riskRating}` }>
            <RatingBadge item={ row } itemType={ _groupType } altVersion rating={ riskToRating( row.filtered_risk ) } />
            <RiskReduction item={ row } itemType={ _groupType } riskType="filtered_risk" />
          </div>,
          name: reportTypeNameLink( row, _groupType ),
          hosts: formatNumber( row.num_hosts ),
          vulns: formatNumber( row.num_vulnerabilities ),
          description: isNotEmpty( row?.description )
            ? <ExpandingCell cellContents={ row.description } />
            : <span></span>,
          id: row.id,
          originalRecord: row,
          actions: <React.Fragment>
            <AddRemoveFilterAction row={ row } />
            {
              isNotEmpty( row.id ) &&
              <button className="selectRecordButton" onClick={ () => insightGoToRecord( row, _groupType ) }>
                <span>View</span>
                <InlineSVG type="carretRight" version="primary"/>
              </button>
            }
          </React.Fragment>,
        };
      case 'patch_cumulative':
        return {
          risk: <div className={ `rowRiskWrapper ${riskRating}` }>
            <RatingBadge item={ row } itemType={ 'patch' } altVersion rating={ riskToRating( row.filtered_risk ) } />
            <RiskReduction item={ row } itemType={ 'patch' } riskType="filtered_risk" />
          </div>,
          name: reportTypeNameLink( row, _groupType ),
          hosts: formatNumber( row.num_hosts ),
          vulns: formatNumber( row.num_vulnerabilities ),
          description: isNotEmpty( row?.description )
            ? <ExpandingCell cellContents={ row.description } />
            : <span></span>,
          id: row.id,
          originalRecord: row,
          actions: <React.Fragment>
            <AddRemoveFilterAction row={ row } />
            {
              isNotEmpty( row.id ) &&
              <button className="selectRecordButton" onClick={ () => insightGoToRecord( row, _groupType ) }>
                <span>View</span>
                <InlineSVG type="carretRight" version="primary"/>
              </button>
            }
          </React.Fragment>,
        };
      case  'vulnerability':
        return {
          risk: <div className={ `rowRiskWrapper ${riskRating}` }>
            <RatingBadge
              item={ row }
              itemType={ _groupType }
              altVersion
              rating={ riskToRating( row?.filtered_risk ) }
            />
            <RiskReduction item={ row } itemType={ _groupType } riskType="filtered_risk" />
          </div>,
          name: reportTypeNameLink( row, _groupType ),
          exploit_status: <ExploitStatus status={row?.exploit_status} fullVersion={false} />,
          CVSS: row?.cvss_base_score,
          hosts: formatNumber( row?.num_hosts ),
          description: isNotEmpty( row?.description )
            ? <ExpandingCell cellContents={ row.description } />
            : <span></span>,
          // analyst_notes: <ExpandingCell cellContents={row.public_notes} />,
          id: row.id,
          originalRecord: row,
          actions: <React.Fragment>
            <AddRemoveFilterAction row={ row } />
            {
              isNotEmpty( row.id ) &&
              <button className="selectRecordButton" onClick={ () => insightGoToRecord( row, _groupType ) }>
                <span>View</span>
                <InlineSVG type="carretRight" version="primary"/>
              </button>
            }
          </React.Fragment>,
        };
      case 'signature':
        return {
          risk: <div className={ `rowRiskWrapper ${riskRating}` }>
            <RatingBadge
              item={ row }
              itemType={ _groupType }
              altVersion
              rating={ riskToRating( row?.filtered_risk ) }
            />
            <RiskReduction item={ row } itemType={ _groupType } riskType="filtered_risk" />
          </div>,
          scanner_signature: <ScannerSignature row={ row } />,
          name: <p title={ row.title }>{ row.title }</p>,
          hosts: formatNumber( row.num_hosts ),
          vulns: formatNumber( row.num_vulnerabilities ),
          id: row.id,
          originalRecord: row,
          actions: <React.Fragment>
            <AddRemoveFilterAction row={ row } />
          </React.Fragment>,
        };
      }
    }
  /* eslint-enable camelcase */
  };

  // whenever the table data changes (ie filters, etc.)
  // this sets the adjusted data that the table will render
  React.useEffect( () => {
    if ( isNotEmpty( records ) ) {
      const newData = records.map( row => {
        const rowData = transformRowData( row );
        return { ...rowData };
      } );
      setAdjustedData( newData );
    } else {
      setAdjustedData( [] );
    }
  }, [ records, tags ] );

  // only some reports and group types support remediation workflow, this dictates which
  const includeRemediationSelect = licenseInfo => {
    if ( isNotEmpty( licenseInfo ) ) {
      if ( hasAccess( licenseInfo, {}, 'feature', 'remediationWorkflow' ) ) {
        if ( reportType === 'instance' ) {
          return paramsToFilters()['group_type'] !== 'signature';
        }
        if ( reportType === 'path' || reportType === 'user' || reportType === 'vulnerability_data' ) {
          return false;
        }
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( licenseInfo ) &&
        <div className={ `${visualCollapsed ? 'visualCollapsed' : ''} riskInsightTableWrapper` }>
          <InsightTableActions
            refreshTable={refreshTable}
            reportType={reportType}
            records={records}
            remediationItems={remediationItems}
            setRemediationItems={setRemediationItems}
            currentPageNumber={currentPageNumber}
            nextRecords={nextRecords}
            setScrollToTableTop={setScrollToTableTop}
            onRefresh={onRefresh}
          />
          <button
            className="fullScreenVisualToggleButton"
            onClick={ () => showFullScreenVisual(
              <DataTable
                sortableColumns={
                  ( reportType === 'instance' || reportType === 'vulnerability_data' )
                    ? instanceGroupingSortableColumns[paramsToFilters()['group_type'] || 'host']
                    : reportTypeSortableColumns[reportType]
                }
                sortBy= {sortBy }
                setSortBy={ setSortBy }
                sortDirection={ sortDirection }
                setSortDirection={ setSortDirection }
                data={ adjustedData }
                // eslint-disable-next-line max-len
                elementClass={ `${ includeRemediationSelect( licenseInfo ) ? 'hasRemediation' : '' }  ${reportType}_riskInsightDataTable ${paramsToFilters()['group_type'] || 'host'}_groupBy riskInsightDataTable` }
                externalHoverRecord={hoverRecord}
                setExternalHoverRecord={setHoverRecord}
                withSelect={ includeRemediationSelect( licenseInfo ) }
                onSelect={ onRemediationSelect }
                scrollToTableTop={scrollToTableTop}
                // eslint-disable-next-line max-len
                selectedIDs={ includeRemediationSelect( licenseInfo ) ? Object.keys( remediationItems[remediationItemsKey( reportType )] ) : [] }
                setSelectedIDs={ setRemediationItemsForType }
                allowHover
              />,
              `riskInsightTable ${reportType}`,
            ) }
          >
            { showVisual ? <InlineSVG type="exitFullscreen" /> : <InlineSVG type="fullscreen" /> }
          </button>
          { loading && <Loading /> }
          { !loading &&
            <DataTable
              sortableColumns={
                ( reportType === 'instance' || reportType === 'vulnerability_data' )
                  ? instanceGroupingSortableColumns[paramsToFilters()['group_type'] || 'host']
                  : reportTypeSortableColumns[reportType]
              }
              sortBy= {sortBy }
              setSortBy={ setSortBy }
              sortDirection={ sortDirection }
              setSortDirection={ setSortDirection }
              data={ adjustedData }
              // eslint-disable-next-line max-len
              elementClass={ `${ includeRemediationSelect( licenseInfo ) ? 'hasRemediation' : '' }  ${reportType}_riskInsightDataTable ${paramsToFilters()['group_type'] || 'host'}_groupBy riskInsightDataTable` }
              externalHoverRecord={hoverRecord}
              setExternalHoverRecord={setHoverRecord}
              withSelect={ includeRemediationSelect( licenseInfo ) }
              onSelect={ onRemediationSelect }
              scrollToTableTop={scrollToTableTop}
              // eslint-disable-next-line max-len
              selectedIDs={ includeRemediationSelect( licenseInfo ) ? Object.keys( remediationItems[remediationItemsKey( reportType )] ) : [] }
              setSelectedIDs={ setRemediationItemsForType }
              allowHover
            />
          }
        </div>
      }
    </React.Fragment>
  );
};

export default InsightTable;