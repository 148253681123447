/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, isEmpty, isNotEmpty, uniqueArray } from '../../../../shared/Utilities';
import { getRecords } from '../../../../shared/RecordCache';
import EmptyLoading from '../../../../shared/EmptyLoading';

import './SensitiveAssetsSection.scss';
import EmptyState from '../../../../shared/EmptyState';
import SensitiveAssetItem from '../../../Explore/Paths/SensitiveAssetItem';

const SensitiveAssetsSection = ( { item, relatedPaths, reportType } ) => {

  const [ sensitiveAssets, setSensitiveAssets ] = React.useState( null );
  const [ scopes, setScopes ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( false );

  const setupAllSensitiveAssets = async () => {
    setLoading( true );

    const _sensitiveAssetIDs = [];
    let pathAssets = [];
    let itemAssets = [];
    const _scopeIDs = [];

    const { paths, nodes } = relatedPaths.results;

    if ( reportType === 'host' && isNotEmpty( item.sensitive_nodes ) ) {
      // eslint-disable-next-line camelcase
      const fetchedNodes = await getRecords( 'node', { id_list: item.sensitive_nodes } );
      if ( isNotEmpty( fetchedNodes ) ) {
        fetchedNodes.map( n => {
          if ( !_sensitiveAssetIDs.includes( n.id ) ) {
            _sensitiveAssetIDs.push( n.id );
          }
          itemAssets.push( n );
          _scopeIDs.push( n.scope_id );
        } );
      }
    }

    paths.map( path => {
      const lastNodeID = path.nodes[ path.nodes.length - 1 ];
      // if this has not already been included, add it to the list
      if ( !_sensitiveAssetIDs.includes( lastNodeID ) ) {
        const node = nodes[lastNodeID];
        pathAssets.push( node );
        _scopeIDs.push( node.scope_id );
        _sensitiveAssetIDs.push( lastNodeID );
      }
    } );

    // eslint-disable-next-line camelcase
    const fetchedScopes = await getRecords( 'scope', { id_list: uniqueArray( _scopeIDs ) } );

    const _scopes = {};
    if ( isNotEmpty( fetchedScopes ) ) {
      fetchedScopes.map( s => _scopes[s.id] = s );
    }

    itemAssets = itemAssets.sort( ( a, b ) => b.combined_impact - a.combined_impact );
    pathAssets = pathAssets.sort( ( a, b ) => b.impact - a.impact );

    setLoading( false );
    setScopes( _scopes );
    setSensitiveAssets( { itemAssets, pathAssets } );
  };

  // grab any additional sensitive nodes stripped off of the related Paths
  React.useEffect( ( ) => {
    if (
      isNotEmpty( relatedPaths )
      && isNotEmpty( relatedPaths.results )
      && isNotEmpty( item )
      && isNotEmpty( reportType )
    ) {
      setupAllSensitiveAssets();
    }
  }, [ relatedPaths, item, reportType ] );

  const additionalAssetsMessage = ( pathAssets, reportType, itemAssets ) => {
    const message = [];

    if ( isEmpty( itemAssets ) ) {
      message.push( <strong>{ formatNumber( pathAssets.length ) }</strong> );
    } else {
      message.push( <strong>{ formatNumber( pathAssets.length ) } Additional</strong> );
    }
    message.push( <span>sensitive asset(s) can be reached through critical paths </span> );

    if ( reportType === 'host' ) {
      message.push( <span>that pass through this host.</span> );
    }
    if ( reportType === 'patch' ) {
      message.push( <span>that take advantage of this patch not being applied.</span> );
    }
    if ( reportType === 'vulnerability' ) {
      message.push( <span>that exploit this vulnerability.</span> );
    }
    return message;
  };

  return (
    <React.Fragment>
      <EmptyLoading
        loading={ loading }
        loadingMessage="Loading sensitive assets..."
        emptyMessage="This item does not expose any sensitive assets"
        payload={ { scopes, sensitiveAssets } }
      />
      {
        ( isNotEmpty( scopes ) && isNotEmpty( sensitiveAssets ) ) &&
        <div className="senstiveAssetsListWrapper">
          {
            isNotEmpty( sensitiveAssets.itemAssets ) &&
            <React.Fragment>
              <div className="detailSectionDescription">
                {/* eslint-disable-next-line max-len */}
                <strong>{ `${formatNumber( sensitiveAssets.itemAssets.length ) } sensitive asset(s)`}</strong>
                <span>{ sensitiveAssets.itemAssets.length > 1 ? 'are' : 'is' } directly present on this host.</span>
              </div>
              <ul>
                {
                  sensitiveAssets.itemAssets.map( ( asset, index ) => {
                    const scope = scopes[asset.scope_id];
                    return <SensitiveAssetItem asListItem asset={ { node: asset, scope } } key={index} />;
                  } )
                }
              </ul>
            </React.Fragment>
          }
          {
            isNotEmpty( sensitiveAssets.pathAssets ) &&
            <React.Fragment>
              <div className="detailSectionDescription">
                {
                  // eslint-disable-next-line max-len
                  additionalAssetsMessage( sensitiveAssets.pathAssets, reportType, sensitiveAssets.itemAssets ).map( ( part, i ) => {
                    return <React.Fragment key={i} >
                      { part }
                    </React.Fragment>;
                  } )
                }
              </div>
              <ul>
                {
                  sensitiveAssets.pathAssets.map( ( asset, index ) => {
                    const scope = scopes[asset.scope_id];
                    return <SensitiveAssetItem asListItem asset={ { node: asset, scope } } key={index} />;
                  } )
                }
              </ul>
            </React.Fragment>
          }
        </div>
      }
      {
        ( isEmpty( sensitiveAssets?.pathAssets ) && isEmpty( sensitiveAssets?.itemAssets ) ) &&
        <EmptyState message="This item does not expose any sensitive assets" />
      }
    </React.Fragment>
  );
};

export default SensitiveAssetsSection;