/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import InlineSVG from '../InlineSVG';
import {
  categoryLabelMap,
  encodeURLHash,
  isEmpty,
  isNotEmpty,
  itemIsArray,
  itemIsObject,
  removeFromURLHash,
  reportTypeDisplayName,
  triggerHashRefresh,
} from '../Utilities';
import './AppliedFilter.scss';
import { appliedFiltersTransformMap, defaultFilterValues } from './shared';

const AppliedFilter = ( {
  label,
  value,
  attribute,
  onRefresh,
  reportType,
} ) => {

  const [ transformedValue, setTransformedValue ] = React.useState( null );

  const removeFilter = () => {
    // if this is one of the defaultValues that we are keeping track of, replace the param with the
    // default value, otherwise it should be cleared
    if (
      isNotEmpty( defaultFilterValues )
      && isNotEmpty( defaultFilterValues[reportType][attribute] )
    ) {
      encodeURLHash( { [attribute]: defaultFilterValues[reportType][attribute] } );
    } else {
      removeFromURLHash( attribute );
    }
    triggerHashRefresh();
    onRefresh( [ attribute ] );
  };

  // transform UUID into user friendly label
  React.useEffect( ( ) => {
    let _value = value;


    if ( Object.keys( appliedFiltersTransformMap ).includes( attribute ) ) {
      const transformer = appliedFiltersTransformMap[attribute];

      if ( transformer.isPromise ) {
        if ( attribute === 'asset_tag_ids' ) {
          if ( isNotEmpty( _value ) && itemIsArray( _value ) ) {
            [ _value ] = value;
            if ( _value === '{}' ) {
              _value = null;
            }
          } else {
            _value = null;
          }
        }
        if ( isNotEmpty( _value ) ) {
          transformer.formatter( _value ).then( response => {
            if ( itemIsObject( response ) ) {
              if ( attribute === 'asset_tag_ids' && response.results ) {
                if ( isNotEmpty( response.results ) ) {
                  setTransformedValue( response.results[0].label );
                } else {
                  setTransformedValue( '[Deleted]' );
                }
              } else {
                setTransformedValue( reportTypeDisplayName( response, attribute.split( '_' )[0] ) );
              }
            // asset tag that no longer exists, got a 500
            } else if ( attribute === 'asset_tag_ids' ) {
              setTransformedValue( '[Deleted]' );
            } else if ( isEmpty( response ) ) {
              setTransformedValue( 'MISSING' );
            } else {
              setTransformedValue( response );
            }
          } );
        } else {
          setTransformedValue( 'N/A' );
        }
      } else if ( isNotEmpty( _value ) ) {
        setTransformedValue( transformer.formatter( _value ) );
      } else {
        setTransformedValue( 'N/A' );
      }
    } else if( Object.keys( categoryLabelMap ).includes( _value ) ) {
      setTransformedValue( categoryLabelMap[_value] );
    } else if ( isNotEmpty( _value ) ) {
      setTransformedValue( _value );
    } else {
      setTransformedValue( 'N/A' );
    }
  }, [ value, attribute ] );

  return (
    <div
      className="appliedFilterButton"
      onClick={ removeFilter }
    >
      <strong className="filterLabel">{ `${label}: ` }</strong>
      {
        isNotEmpty( transformedValue ) &&
        <span className="filterValue">{ transformedValue }</span>
      }
      <button>
        <InlineSVG type="close" />
      </button>
    </div>
  );
};

export default AppliedFilter;