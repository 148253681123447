/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  capitalize,
  isNotEmpty,
  pluralizeItem,
  reportTypeDisplayName,
  reportTypeIcon,
} from '../../../../shared/Utilities';
import InstancesBreakdown from '../../../../shared/Charts/InstancesBreakdown';
import InlineSVG from '../../../../shared/InlineSVG';

import './OverviewHeader.scss';
import ItemStatistic from './ItemStatistic';
import ExploitStatusBreakdown from '../../../../shared/Charts/ExploitStatusBreakdown';
import Loading from '../../../../shared/Loading';
import PageCreateButton from '../../../../shared/PageCreateButton';
import { TagsContext } from '../../../../Contexts/Tags';
import TagList from '../../Tags/List';
import NotScannedBadge from '../../../../shared/NotScannedBadge';

const OverviewHeader = ( {
  item,
  selectItem,
  instanceData,
  reportType,
  riskType,
  loading,
  riskClass,
} ) => {

  const [ tagMemberships, setTagMemberships ] = React.useState( null );
  const [ tags ] = React.useContext( TagsContext );

  const svgWrapperRef = React.useRef( null );

  const canViewInExplore = [
    'host',
    'patch',
    'vulnerability',
    'path',
  ];

  const statisticsForReportType = {
    host: [
      'risk_reduction',
      'vulnerabilities',
      'patches',
      'risk_rating',
      'sensitive_nodes',
      'unsuperseded_patches',
    ],
    patch: [
      'risk_reduction',
      'hosts',
      'vulnerabilities',
      'risk_rating',
      // 'sensitive_assets',
    ],
    vulnerability: [
      'risk_reduction',
      'hosts',
      'patches',
      'risk_rating',
      'exploit_status',
      'unsuperseded_patches',
      // 'sensitive_assets',
    ],
    user: [
      'risk_rating',
      'risk_reduction',
      'active_hosts',
      'domain_groups',
      // 'sensitive_assets',
    ],
    path: [
      'risk_rating',
      'risk_reduction',
    ],
  };

  const getExploreLink = ( item, reportType ) => {
    // eslint-disable-next-line max-len
    return `#.=explore&page=explore_model&type=scope&explore_${ reportType === 'host' ? 'scope' : reportType }=%5B"${item.id}"%5D`;
  };

  // find out what tags this host is a part of
  React.useEffect( () => {
    if (
      isNotEmpty( tags )
      && isNotEmpty( reportType )
      && reportType === 'host'
      && isNotEmpty( item )
      && isNotEmpty( item.asset_tags )
    ) {
      const _tags = {};

      item.asset_tags.map( id => {
        _tags[id] = tags[id];
      } );

      setTagMemberships( _tags );
    }
  }, [ tags, item, reportType ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( item ) && isNotEmpty( reportType ) && isNotEmpty( riskType ) && isNotEmpty( riskClass ) ) &&
        <div className={ `riskInsightOverviewHeader ${reportType}`}>
          <PageCreateButton placement="left" elementClass={ riskClass } >
            <button className="riskInsightBackButton" onClick={ () => selectItem( null ) }>
              <InlineSVG type="carretLeft" />
              <span>All { capitalize( pluralizeItem( reportType ) ) }</span>
            </button>
          </PageCreateButton>
          {
            isNotEmpty( tagMemberships ) &&
            <TagList tags={tagMemberships} />
          }
          <div className="headerSection instances">
            {
              ( reportType === 'host' && !item.has_host ) &&
              <NotScannedBadge />
            }
            <div className="itemLabelWrapper">
              <div className={ `labelAndbuttonWrapper risk-${riskClass}` }>
                <div className={ `reportTypeIconWrapper risk-${riskClass}` }>
                  { reportTypeIcon[reportType] }
                </div>
                <h2>{ reportTypeDisplayName( item, reportType ) }</h2>
              </div>
              {
                canViewInExplore.includes( reportType ) &&
                <a
                  className="exploreLink"
                  target="_blank"
                  rel="noreferrer noopener"
                  href={ getExploreLink( item, reportType ) }
                >
                  View in explore
                  <InlineSVG type="newTabLink" />
                </a>
              }
            </div>
            <InstancesBreakdown
              reportType={reportType}
              itemID={item.id}
              instanceData={ instanceData?.tally }
              svgRef={ svgWrapperRef }
              riskRating={ riskClass }
              loading={ loading }
            />

          </div>
          <div className={ `headerSection statistics ${reportType}` }>
            {
              statisticsForReportType[reportType].map( ( stat, i ) => {
                return <ItemStatistic
                  key={i}
                  item={item}
                  stat={stat}
                  instanceData={instanceData}
                  riskType={riskType}
                  reportType={reportType}
                  riskClass={ riskClass }
                />;
              } )
            }
          </div>
          {
            reportType !== 'vulnerability' &&
            <div className="headerSection exploitStatus">
              { loading && <Loading minimalVariant /> }
              {
                isNotEmpty( instanceData ) &&
                <ExploitStatusBreakdown instanceData={instanceData} />
              }
            </div>
          }
        </div>
      }
    </React.Fragment>
  );
};

export default OverviewHeader;