/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../shared/Utilities';
import SectionDescription from './SectionDescription';
import { thirdPartyAttributes } from '../shared';
import RecordList from '../../../../shared/RecordList';

const needsDescription = [
  'num_vulnerabilities',
  'num_hosts',
  'num_patches',
  'scan_results',
  'supersedes',
  'superseded_by',
  'unpatchable_vulnerabilities',
  'additional_actions',
];

const SectionContent = ( {
  item,
  sectionKey,
  sectionCount,
  sectionData,
  reportType,
  setSelectedSignature,
  setModalContext,
  relatedPaths,
  riskType,
  setHoveredListItemID,
  setHoveredListItemType,
  setHoveredListItemRating,
} ) => {

  return (
    <React.Fragment>
      {
        (
          isNotEmpty( sectionKey )
          && isNotEmpty( sectionData )
          && isNotEmpty( sectionCount )
          && isNotEmpty( reportType )
        ) &&
        <React.Fragment>
          {
            needsDescription.includes( sectionKey ) &&
            <SectionDescription
              sectionKey={sectionKey}
              sectionCount={sectionCount}
              sectionData={sectionData}
              reportType={reportType}
              item={item}
              relatedPaths={relatedPaths}
              riskType={riskType}
            />
          }
          {
            isNotEmpty( sectionData ) &&
            <React.Fragment>
              {
                thirdPartyAttributes.includes( sectionKey )
                  ? <div className="thirdPartySectionBodyWrapper">
                    { sectionData }
                  </div>
                  : <RecordList
                    records={ sectionData }
                    orderBy={ riskType }
                    sectionKey={ sectionKey }
                    reportType={ sectionKey }
                    setSelectedSignature={ setSelectedSignature }
                    setModalContext={ setModalContext }
                    setHoveredListItemID={ setHoveredListItemID }
                    setHoveredListItemType={ setHoveredListItemType }
                    setHoveredListItemRating={ setHoveredListItemRating }
                  />
              }
            </React.Fragment>
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default SectionContent;