/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  isNotEmpty,
  pluralizeType,
  riskToRating,
} from '../../../../../shared/Utilities';
import InlineSVG from '../../../../../shared/InlineSVG';

import './TaskItem.scss';
import { completionClass, percentComplete } from '../../Shared';
import { makeRequest } from '../../../../../../legacy/io';
import RiskReduction from '../../../../../shared/RiskReduction';
import RatingBadge from '../../../../../shared/RatingBadge';

const ExternalTicketLink = ( { task, flattenedIntegrations } ) => {

  const [ href, setHref ] = React.useState( null );
  const [ integration, setIntegration ] = React.useState( null );

  React.useEffect( ( ) => {
    if (
      isNotEmpty( task )
      && isNotEmpty( task.original )
      && isNotEmpty( task.original.third_party_setting_id )
      && isNotEmpty( task.original.external_ticket_id )
      && isNotEmpty( flattenedIntegrations )
    ) {
      const _integration = flattenedIntegrations[task.original.third_party_setting_id];
      setIntegration( _integration );
      if ( isNotEmpty( _integration ) ) {
        setHref( `${_integration.domain}/browse/${task.original.external_ticket_id}` );
      }
    }
  }, [ task, flattenedIntegrations ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( href ) && isNotEmpty( integration ) ) &&
        <a
          className="externalTicketLink"
          href={ href }
          target="_blank"
          rel="nofollow noopener"
        >
          <InlineSVG type={ `${integration.tool}Logo` } version="special" elementClass="integrationLogo" />
          <strong>
            { task.original.external_ticket_id }
          </strong>
          <InlineSVG type="link" elementClass="externalLinkIcon" />
        </a>
      }
    </React.Fragment>
  );
};

const TaskItem = ( {
  task,
  getTasks,
  planState,
  setPlanState,
  setLoadingStep,
  isSelected=false,
  isExported=false,
  users,
  currentStep=null,
  isActive,
  readOnly=false,
  hideProgress=false,
  activeIntegrations={},
  needsIntegration=false,
  elementClass='',
} ) => {

  const [ flattenedIntegrations, setFlattenedIntegrations ] = React.useState( {} );

  // on init, happens only once, sets up the options for the select
  React.useEffect( ( ) => {
    const flattened= {};
    if ( isNotEmpty( activeIntegrations ) ) {
      Object.values( activeIntegrations ).map( intGroup => {
        intGroup.map( int => {
          flattened[int.id] = int;
        } );
      } );
    }
    setFlattenedIntegrations( flattened );
  }, [ activeIntegrations ] );

  const taskAction = task => {
    let _text = '';
    if ( task.original ) {
      if ( task.original.type === 'host_patches' ) {
        _text = <span>Apply <strong>{task.original.num_items} {task.original.type.split( '_' )[1]}</strong></span>;
      }
      if ( task.original.type === 'patch_hosts' ) {
        _text = <span>Apply to <strong>{task.original.num_items} {task.original.type.split( '_' )[1]}</strong></span>;
      }
      if ( task.original.type === 'host_vulnerabilities' ) {
        _text = <span>Fix <strong>{task.original.num_items} {task.original.type.split( '_' )[1]}</strong></span>;
      }
      if ( task.original.type === 'vulnerability_hosts' ) {
        _text = <span>Fix on <strong>{task.original.num_items} {task.original.type.split( '_' )[1]}</strong></span>;
      }
    }

    return _text;
  };
  // eslint-disable-next-line max-len
  const taskIcon = task => <InlineSVG type={ `${pluralizeType( task.normalizedType )}Alt` } elementClass="taskIcon" />;

  const selectTask = async ( task ) => {
    setLoadingStep( true );

    const { id } = planState;

    await makeRequest( 'ADD', '/project/default/model/base/remediation_task', {
      // eslint-disable-next-line camelcase
      remediation_plan_id: id,
      additions: [ task ],
    } );

    const tasks = await getTasks( planState );

    setPlanState( { ...planState, tasks } );
    setLoadingStep( false );
  };

  const removeTask = async ( task ) => {
    setLoadingStep( true );

    const { id } = planState;

    await makeRequest( 'DELETE', '/project/default/model/base/remediation_task', {
      // eslint-disable-next-line camelcase
      remediation_plan_id: id,
      ids: [ task.id ],
    } );

    const tasks = await getTasks( planState );
    setPlanState( { ...planState, tasks } );
    setLoadingStep( false );
  };

  const updateOwner = async ( task, ownerID ) => {
    const { id } = planState;

    await makeRequest( 'UPDATE', '/project/default/model/base/remediation_task', {
      updates: [ { id: task.original.id, owner: ownerID === '' ? null : ownerID } ],
    } );

    await makeRequest( 'FETCH', '/project/default/model/base/remediation_task', {
      // eslint-disable-next-line camelcase
      remediation_plan_id: id,
    } );

    const tasks = await getTasks( planState );
    setPlanState( { ...planState, tasks } );
  };

  const hasExternalTicket = () => isNotEmpty( task.original.external_ticket_id )
    && isNotEmpty( task.original.third_party_setting_id )
    && isNotEmpty( flattenedIntegrations );

  return (
    <React.Fragment>
      {
        isNotEmpty( task ) &&
        // eslint-disable-next-line max-len
        <li className={`${hasExternalTicket() ? 'hasExternalTicket' : ''} ${isExported ? 'isExported' : ''} ${elementClass} ${needsIntegration ? 'needsIntegration' : ''} taskItem remediationTaskItem ${readOnly ? 'readOnly' : '' } ${isSelected ? 'selected' : ''} ${isActive ? 'active' : ''} ${hideProgress ? 'hideProgress' : ''} ${isNotEmpty( task.externalUser ) ? 'hasExternalUser' : '' }`}>
          {
            ( hasExternalTicket() ) &&
            <ExternalTicketLink task={task} flattenedIntegrations={flattenedIntegrations} />
          }
          <div className="titleWrapper">
            <h3>
              { taskIcon( task ) }
              { task.label }
            </h3>
            <span className="ratingWrapper">
              <RatingBadge altVersion rating={ riskToRating( task.risk ) } />
              <RiskReduction item={task} riskType="risk" />
            </span>
            <span className="action">{ taskAction( task ) }</span>
            {
              readOnly
                ? <div className="lowerStat">
                  <InlineSVG type="user" />
                  <strong>Assigned to: </strong>
                  <span>{ users[task.original.owner] || 'Unassigned' }</span>
                </div>
                : <React.Fragment>
                  {
                    isNotEmpty( users )
                      ? <React.Fragment>
                        {
                          isSelected
                            ? <div className="selectFieldWrapper">
                              <select
                                value={ task.original.owner || '' }
                                onChange={ e => updateOwner( task, e.target.value ) }
                              >
                                <option value="">Unassigned</option>
                                {
                                  Object.entries( users ).map( ( [ uuid, name ], index ) => {
                                    return  <option key={index} value={uuid}>{ name }</option>;
                                  } )
                                }
                              </select>
                            </div>
                            : <div className="lowerStat">
                              <InlineSVG type="user" />
                              <strong>Assigned to: </strong>
                              <span>{ users[task.original.owner] || 'Unassigned' }</span>
                            </div>
                        }
                      </React.Fragment>
                      : <span> Unassigned </span>
                  }
                </React.Fragment>
            }
          </div>
          {
            ( isActive && !hideProgress ) &&
            <div className="remediationPlanVisualization">
              <div className="completionWrapper risk">
                <div className="completionLabel">
                  <strong>Risk</strong>
                  <span>{ percentComplete( task.original.original_risk, task.original.risk ) }%</span>
                </div>
                <div className={
                  `${completionClass( task.original.original_risk, task.original.risk )} completionBarWrapper risk`
                }>
                  <div
                    className={ 'completionBar risk' }
                    style={{
                      width: `${percentComplete( task.original.original_risk, task.original.risk )}%`,
                    }}
                  />
                </div>

              </div>
              <div className="completionWrapper instances">
                <div className="completionLabel">
                  <strong>Instances</strong>
                  <span>{ percentComplete( task.original.original_num_instances, task.original.num_instances ) }%</span>
                </div>
                {/* eslint-disable-next-line max-len */}
                <div className={ `${completionClass( task.original.original_num_instances, task.original.num_instances )} completionBarWrapper instances` }>
                  <div
                    className={ 'completionBar instances' }
                    style={{
                      width: `${percentComplete( task.original.original_num_instances, task.original.num_instances )}%`,
                    }}
                  />
                </div>
              </div>
            </div>
          }
          {
            ( currentStep !== 4 && !readOnly ) &&
            <div className="itemActions">
              {
                isSelected
                  ? <button
                    className="addItemButton"
                    onClick={ () => removeTask( task.original ) }
                  >
                    <InlineSVG type="remove" />
                  </button>
                  : <button
                    className="addItemButton"
                    onClick={ () => selectTask( task.original ) }
                  >
                    <InlineSVG type="add" />
                  </button>
              }
            </div>
          }
        </li>
      }
    </React.Fragment>
  );
};

export default TaskItem;