/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  decodeURLHash,
  getDimensionsAndOffset,
  globalColors,
  isEmpty,
  isNotEmpty,
  logToServer,
  riskToRating,
  uniqueArray,
} from '../../../../shared/Utilities';

import { getRecords } from '../../../../shared/RecordCache';

import { v4 as uuidv4 } from 'uuid';

import './PathsGraph.scss';

import { getNodeIcon, getRiskFill } from '../shared';
import Modal from '../../../../shared/Modal';

// compresses the paths down to a threshold (default 10) and then returns all the compressed paths, edges, and nodes
const combinedData = ( paths, edges, nodes, maxLength=10 ) => {

  const shortenedPaths = [];
  const includedEdges = [];
  const includedNodes = [];

  paths.map( path => {

    const pathEdges = path.edges.map( eID => edges[eID] );

    // already short enough, not doing anything except adding everything to the right places
    if ( path.edges.length <= maxLength ) {

      const _shortenedPathEdges = [];
      const _shortenedPathNodes = [];

      path.edges.map( eID => {

        const edge = edges[eID];

        if ( isNotEmpty( edge ) ) {
          const fromNode = nodes[edge.from_node];
          const toNode = nodes[edge.to_node];

          const _edge = {
            id: edge.id,
            // eslint-disable-next-line camelcase
            from_node: edge.from_node,
            // eslint-disable-next-line camelcase
            to_node: edge.to_node,
            nofix: edge.nofix,
            risk: edge.risk,
            // eslint-disable-next-line camelcase
            risk_percentile: edge.risk_percentile,
            includedEdges: [ edge ],
            includedEdgeIDs: [ edge.id ],
            includedNodeIDs: [ edge.from_node, edge.to_node ],
            isCombined: false,
            pathID: path.id,
            sensitiveAssetID: path.asset_id,
          };
          _shortenedPathEdges.push( _edge );
          _shortenedPathNodes.push( fromNode );
          _shortenedPathNodes.push( toNode );

          includedEdges.push( _edge );
          includedNodes.push( fromNode );
          includedNodes.push( toNode );
        }
      } );

      const _shortenedPath = {
        id: path.id,
        risk: path.risk,
        // eslint-disable-next-line camelcase
        asset_id: path.asset_id,
        edges: _shortenedPathEdges,
        nodes: uniqueArray( _shortenedPathNodes ),
      };

      shortenedPaths.push( _shortenedPath );

    } else {

      const _shortenedPathEdges = [];
      const _shortenedPathNodes = [];
      let shortenedPath = shortenToThreshold( pathEdges, maxLength );

      // it has actually been compressed too much, we need to unwrap a few so that we get back up to the threshold,
      // the thought is, find which of these have some wiggle room to adjust (lots of edges compressed) and split
      // until we reach the threshold
      if ( shortenedPath.length < maxLength ) {

        const delta = maxLength - shortenedPath.length;

        let maxGrouping;

        shortenedPath.map( ( edgeGrouping, index ) => {
          if ( isEmpty( maxGrouping ) || edgeGrouping.length > maxGrouping.count ) {
            maxGrouping = { edges: [ ...edgeGrouping ], originalIndex: index, count: edgeGrouping.length };
          }
        } );

        // we have more segments than the amount we need, so we can split this group apart, arbitrarily
        // otherwise we just have to live with this path being shorter
        if ( maxGrouping.count > delta ) {

          const combined = [ ...shortenedPath ];

          // we are only ever going to split it up a max of twice, don't need to get too crazy.
          if ( delta > 1 ) {
            const firstThird = Math.ceil( maxGrouping.count / 3 );
            const secondThird = Math.ceil( maxGrouping.count * ( 2 / 3 ) );

            const first = [ ...maxGrouping.edges ].slice( 0, firstThird );
            const second = [ ...maxGrouping.edges ].slice( firstThird, secondThird );
            const third = [ ...maxGrouping.edges ].slice( secondThird );

            combined.splice( maxGrouping.originalIndex, 1, first, second, third );
          } else {
            const half = Math.ceil( maxGrouping.count / 2 );

            const first = [ ...maxGrouping.edges ].slice( 0, half );
            const second = [ ...maxGrouping.edges ].slice( half );

            combined.splice( maxGrouping.originalIndex, 1, first, second );
          }

          shortenedPath = combined;
        }
      }

      // shortened path is a 2-dimensional array with each sub array representing a collection of edges that will
      // be compressed down to a single edge in the visual
      // the "fromNode" will be the from_node of the first edge in any given collection.
      // the "toNode" will be the to_node of the last edge in a any given collection.
      // each of those nodes will be added to the collection of nodes that are included
      // a new "edge" will be added to the collection of includedEdges so that it can be rendered just like any other
      shortenedPath.map( edges => {

        const includedNodeIDs = [];

        let fromNode, toNode;

        if ( isNotEmpty( edges[0] ) ) {
          fromNode = nodes[edges[0].from_node];
        }

        if ( isNotEmpty( edges[edges.length - 1] ) ) {
          toNode = nodes[edges[edges.length - 1].to_node];
        }

        edges.map( e => {
          if ( isNotEmpty( e ) ) {
            includedNodeIDs.push( e.to_node );
            includedNodeIDs.push( e.from_node );
          }
        } );

        if ( isNotEmpty( fromNode ) && isNotEmpty( toNode ) ) {
          const _edge = {
            id: uuidv4(),
            // eslint-disable-next-line camelcase
            from_node: fromNode.id,
            // eslint-disable-next-line camelcase
            to_node: toNode.id,
            nofix: edges.some( e => e.nofix ),
            // eslint-disable-next-line camelcase
            risk_percentile: Math.max( ...edges.map( e => e.risk_percentile ) ),
            risk: Math.max( ...edges.map( e => e.risk ) ),
            includedEdges: edges,
            includedEdgeIDs: edges.map( e => e.id ),
            includedNodeIDs: uniqueArray( includedNodeIDs ),
            isCombined: edges.length > 1,
            pathID: path.id,
            sensitiveAssetID: path.asset_id,
          };

          if ( edges.length === 1 ) {
            _edge.id = edges[0].id;
          }

          includedEdges.push( _edge );
          includedNodes.push( fromNode );
          includedNodes.push( toNode );

          // save this "edge" and the from/to nodes back to the path
          _shortenedPathEdges.push( _edge );
          _shortenedPathNodes.push( fromNode );
          _shortenedPathNodes.push( toNode );
        }
      } );

      const _shortenedPath = {
        id: path.id,
        risk: path.risk,
        // eslint-disable-next-line camelcase
        asset_id: path.asset_id,
        edges: _shortenedPathEdges,
        nodes: uniqueArray( _shortenedPathNodes ),
      };

      shortenedPaths.push( _shortenedPath );
    }
  } );

  // map over any edges that are in more than 1 path
  const edgeIDsToPaths = {};

  const edgeAndPathIDs = includedEdges.map( edge => ( { [edge.id]: edge.pathID } ) );

  edgeAndPathIDs.map( mapping => {
    const [ edgeID ] = Object.keys( mapping );
    const [ pathID ] = Object.values( mapping );

    if ( isNotEmpty( edgeIDsToPaths[edgeID] ) ) {
      edgeIDsToPaths[edgeID].push( pathID );
    } else {
      edgeIDsToPaths[edgeID] = [ pathID ];
    }
  } );

  includedEdges.map( edge => {
    edge.pathIDs = edgeIDsToPaths[edge.id];
  } );

  return {
    paths: shortenedPaths,
    edges: uniqueArray( includedEdges ),
    nodes: uniqueArray( includedNodes ),
  };
};

// called if a path is too long for a given threshold
const shortenToThreshold = ( edges, threshold ) => {
  let combined = [];
  let tolerance = 0;

  combined = combineEdges( edges, tolerance );

  while ( combined.length > threshold ) {
    tolerance += 1;

    if ( tolerance <= 10 ) {
      combined = combineEdges( edges, tolerance );
    } else {
      break;
    }
  }

  combined = combined.map( collection => {
    const sorted = collection.sort( ( a, b ) => a.originalIndex - b.originalIndex );
    return sorted;
  } );
  return combined;
};

// called within a loop with ever increasing delta
// tolerance until we get a grouping that is small enough to display
const combineEdges = ( edges, tolerance ) => {

  const combined = [];
  let collected = [];
  const highPriority = 5;

  edges.map( ( edge, index ) => {
    // there are no previous collected segments, start a new collection
    if ( isEmpty( collected ) ) {
      collected.push( edge );
      // if this is the first one, close it
      if ( index === 0 ) {
        combined.push( collected );
        collected = [];
      }
    // this segment is within the tolerance
    } else if (
      isNotEmpty( edge )
      && isNotEmpty( collected[0] )
      && Math.abs( ( edge?.priority || 0 ) - ( collected[0].priority || 0 ) ) <= tolerance ) {
      // if the last one is a high enough priority,
      // lets leave it alone even if it is below the tolerance
      if ( ( collected[0].priority || 0 ) >= highPriority ) {
        combined.push( collected );
        collected = [ edge ];
        // if this is the last one, close it
        if ( index === edges.length - 1 ) {
          combined.push( collected );
        }
      // add it to the collection because it is within the tolerance
      } else {
        collected.push( edge );
        // if this is the last one, close it
        if ( index === edges.length - 1 ) {
          combined.push( collected );
        }
      }
    // it has a priority that is outside of the tolerance, close the collection and start a new one
    } else {
      combined.push( collected );
      collected = [ edge ];
      // if this is the last one, close it
      if ( index === edges.length - 1 ) {
        combined.push( collected );
      }
    }
  } );

  return combined;
};

const SVGContent = ( {
  data,
  nodeContainerHeight,
  nodeContainerWidth,
  revertZoom,
  svgContainerRef,
  svgPanShift,
  svgScale,
  hoveredListItemRating,
  isExternallyHoveringScope,
  onHostDetailPageForScope,
  orderedEdges,
  handleItemDoubleClick,
  item,
  onUserPageForNode,
  isSelected,
  isHovered,
  nodeOnClickCallback,
  handleSVGItemHover,
  handleSVGItemLeave,
  // toggleFullscreen,
  // fullscreen,
  withinModal=false,
} ) => {
  return (
    <React.Fragment>
      { isNotEmpty( data ) &&
        <div className="pathSVGContainer">
          <svg
            // width is the max path width * 48, height is the number of paths * 48, makes a grid of 48 x 48 squares
            // eslint-disable-next-line max-len
            viewBox={ `0 0 ${data.width * nodeContainerWidth} ${data.height * nodeContainerHeight}` }
            xmlns="http://www.w3.org/2000/svg"
            className={ `paths svgHeight-${data.height} ${withinModal ? 'withinModal' : ''}` }
            ref={ svgContainerRef }
            onDoubleClick={ e => {
              e.preventDefault();
              e.stopPropagation();
              revertZoom( e );
              return false;
            } }
          >
            <g
              id="panZoomWrapper"
              // eslint-disable-next-line max-len
              transform={ `translate(${svgPanShift.x} ${svgPanShift.y}) scale(${svgScale})` }
            >
              {/* render the parent scope boxes */}
              {
                isNotEmpty( data.parentScopes ) && Object.values( data.parentScopes ).map( ( scope, sIndex ) => {

                  if (
                    isNotEmpty( scope.x )
                    && isNotEmpty( scope.y )
                    && isNotEmpty( scope.width )
                    && isNotEmpty( scope.height )
                  ) {
                    const hasParent = scope.parentScopeID !== '00000000-0000-0000-0000-000000000000';

                    const multiplier = nodeContainerWidth;
                    const xyOffset = nodeContainerWidth / 24;
                    const widthHeightOffset = nodeContainerWidth / 12;

                    const x = ( scope.x * multiplier ) + xyOffset;
                    const y = ( scope.y * multiplier ) + xyOffset;
                    const width = ( scope.width * multiplier ) - widthHeightOffset;
                    const height = ( scope.height * multiplier ) - widthHeightOffset;

                    let scopeLabel = scope.label;

                    if ( scope.width < 2 ) {
                      scopeLabel = `${scope.label?.length > 13 ? `${scope.label?.slice( 0, 12 )}...` : scope.label }`;
                    }

                    return <g
                      key={ `${scope.id}_${sIndex}`}
                      // eslint-disable-next-line max-len
                      className={ `scopeGroup scopeID_${scope.id} ${hoveredListItemRating ? `hoverRatingOverride-${hoveredListItemRating}` : '' } ${ isExternallyHoveringScope( scope ) ? 'selected' : '' } ${ onHostDetailPageForScope( scope ) ? 'selected' : '' } ${ riskToRating( item.filtered_risk )}` }
                    >
                      <rect
                        className="scopeContainer"
                        x={ x }
                        y={ y }
                        width={ width }
                        height={ height }
                        fill="#fff"
                        stroke={ globalColors.grey }
                        strokeWidth={ 0.5 }
                        rx={ hasParent ? 2 : 4 }
                      />
                      <circle
                        className="riskRatingCircle"
                        r={ 2 }
                        cx={ x + 5 }
                        cy={ y + 5 }
                        fill={ globalColors[scope.riskRating] }
                      />
                      <text
                        className="scopeLabel"
                        x={ x + 9 }
                        y={ y + 6.3 }
                        textAnchor="start"
                        fontSize={ 4 }
                        fontWeight={ 600 }
                        fill={ globalColors.darkBlue }
                      >
                        { scopeLabel }
                      </text>
                    </g>;
                  }

                } )
              }
              {/* render the child scope boxes */}
              {
                isNotEmpty( data.childScopes ) && Object.values( data.childScopes ).map( ( scope, sIndex ) => {
                  if (
                    isNotEmpty( scope.x )
                    && isNotEmpty( scope.y )
                    && isNotEmpty( scope.width )
                    && isNotEmpty( scope.height )
                  ) {
                    const hasParent = scope.parentScopeID !== '00000000-0000-0000-0000-000000000000';

                    const multiplier = nodeContainerWidth;
                    const xyOffset = nodeContainerWidth / 8;
                    const widthHeightOffset = nodeContainerWidth / 4;

                    const x = ( scope.x * multiplier ) + xyOffset;
                    const y = ( scope.y * multiplier ) + xyOffset;
                    const width = ( scope.width * multiplier ) - widthHeightOffset;
                    const height = ( scope.height * multiplier ) - widthHeightOffset;

                    let scopeLabel = scope.label;

                    if ( scope.width < 2 ) {
                      scopeLabel = `${scope.label?.length > 10 ? `${scope.label?.slice( 0, 9 )}...` : scope.label }`;
                    }

                    return <g
                      key={ `${scope.id}_${sIndex}`}
                      // eslint-disable-next-line max-len
                      className={ `scopeGroup scopeID_${scope.id} ${hoveredListItemRating ? `hoverRatingOverride-${hoveredListItemRating}` : ''} ${ isExternallyHoveringScope( scope ) ? 'selected' : '' } ${ onHostDetailPageForScope( scope ) ? 'selected' : '' } ${ riskToRating( item.filtered_risk )}` }
                    >
                      <rect
                        className="scopeContainer"
                        x={ x }
                        y={ y }
                        width={ width }
                        height={ height }
                        fill={ globalColors['grey--background--light'] }
                        stroke={ globalColors.grey }
                        strokeWidth={ 0.5 }
                        rx={ hasParent ? 2 : 4 }
                        onDoubleClick={ e => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleItemDoubleClick( e, scope, 'scope' );
                          return false;
                        }}
                      />
                      <circle
                        className="riskRatingCircle"
                        r={ 2 }
                        cx={ x + 5 }
                        cy={ y + 5 }
                        fill={ globalColors[scope.riskRating] }
                      />
                      <text
                        className="scopeLabel"
                        x={ x + 9 }
                        y={ y + 6.3 }
                        textAnchor="start"
                        fontSize={ 4 }
                        fontWeight={ 600 }
                        fill={ globalColors.darkBlue }
                      >
                        { scopeLabel }
                      </text>
                    </g>;
                  }

                } )
              }
              {/* render the edges, this is handled differently because of draw order */}
              {
                isNotEmpty( orderedEdges ) &&
                <React.Fragment>
                  {
                    isNotEmpty( orderedEdges.deselected ) &&
                    orderedEdges.deselected.map( ( renderedEdge, index ) => {
                      return <React.Fragment key={ `${renderedEdge.id}_${index}` }>
                        { renderedEdge.content }
                      </React.Fragment>;
                    } )
                  }
                  {
                    isNotEmpty( orderedEdges.selected ) &&
                    orderedEdges.selected.map( ( renderedEdge, index ) => {
                      return <React.Fragment key={ `${renderedEdge.id}_${index}` }>
                        { renderedEdge.content }
                      </React.Fragment>;
                    } )
                  }
                </React.Fragment>
              }
              {/* render the nodes */}
              {
                isNotEmpty( data.nodes ) && Object.values( data.nodes ).map( ( node, nodeIndex ) => {

                  const iconWidth = node.type === 'attacker' ? 20 : 13;
                  // eslint-disable-next-line max-len
                  const textOffset =  node.type === 'attacker' ? nodeContainerWidth - 8 : nodeContainerWidth - iconWidth + 3;

                  const { x } = node;
                  return <g
                    key={ `${node.id}_${nodeIndex}` }
                    // eslint-disable-next-line max-len
                    className={ `nodeGroup nodeID_${node.id} ${ ( isNotEmpty( item ) && isNotEmpty( item.risk ) ) ? riskToRating( item.risk ) : '' } ${ onUserPageForNode( node ) ? 'onDetailPage' : '' } ${isSelected( node ) ? 'selected' : ''} ${isHovered( node ) ? 'hovered' : ''}`}
                    onClick={ e => nodeOnClickCallback( node, e ) }
                    onDoubleClick={ e => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleItemDoubleClick( e, node, 'node' );
                      return false;
                    }}
                  >
                    <svg
                      width={ iconWidth }
                      height={ iconWidth }
                      // eslint-disable-next-line max-len
                      x={ `${ ( ( x * nodeContainerWidth ) + ( ( nodeContainerWidth - iconWidth ) / 2 ) ) }` }
                      // eslint-disable-next-line max-len
                      y={ `${ ( ( node.y * nodeContainerHeight ) + ( ( nodeContainerHeight - iconWidth ) / 2 ) ) }` }
                      viewBox="0 0 32 32"
                      fill="none"
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="svgNodeIcon"
                    >
                      { getNodeIcon( node ) }
                    </svg>
                    {/* node label */}
                    <text
                      x={ `${ ( x * nodeContainerWidth ) + ( nodeContainerWidth / 2 ) }` }
                      y={ `${ ( node.y * nodeContainerHeight ) + textOffset }` }
                      fontSize={ node.type === 'attacker' ? 6 : 4 }
                      textAnchor="middle"
                      fontWeight="bold"
                      fill={ globalColors.darkBlue }
                    >
                      { `${node.label?.length > 21 ? `${node.label?.slice( 0, 20 )}...` : node.label }` }
                    </text>
                    {/* this is what actually gets the hover functionality */}
                    <circle
                      cx={ `${ ( ( x * nodeContainerWidth ) + ( ( nodeContainerWidth ) / 2 ) ) }` }
                      // eslint-disable-next-line max-len
                      cy={ `${ ( ( node.y * nodeContainerHeight ) + ( ( nodeContainerHeight ) / 2 ) ) }` }
                      r={ 10 }
                      fill="#fff"
                      opacity={ 0 }
                      className="nodeHoverCircle"
                      onMouseEnter={ () => handleSVGItemHover( node, 'node' ) }
                      onMouseLeave={ handleSVGItemLeave }
                    />
                  </g>;
                } )
              }
            </g>
          </svg>
          <div className="graphActions">
            {
              svgScale !== 1 &&
              <button
                className="revertZoomButton"
                onClick={ revertZoom }
                title="Reset zoom"
              >
                reset zoom
              </button>
            }
          </div>

        </div>
      }
    </React.Fragment>
  );
};

const PathsGraph = ( {
  item={ id: '' },
  reportType='host',
  relatedPaths,
  edgeOnClickCallback=() => {},
  nodeOnClickCallback=() => {},
  handleRecordCardClose=() => {},
  hoveredListItemID,
  // hoveredListItemType,
  hoveredListItemRating,
} ) => {

  const DEFAULT_PANSHIFT = { x: 0, y: 0 };
  const DEFAULT_SCALE = 1;

  const [ data, setData ] = React.useState( null );
  const [ selectedPathIDs, setSelectedPathIDs ] = React.useState( null );
  const [ selectedItemID, setSelectedItemID ] = React.useState( null );
  const [ orderedEdges, setOrderedEdges ] = React.useState( null );

  const [ svgPanShift, setSVGPanShift ] = React.useState( DEFAULT_PANSHIFT );
  const [ svgScale, setSVGScale ] = React.useState( DEFAULT_SCALE );

  const [ fullscreen, setFullscreen ] = React.useState( false );

  const svgContainerRef = React.useRef( null );

  const nodeContainerHeight = 48;
  const nodeContainerWidth = 48;

  const setupPathData = async () => {
    if ( relatedPaths && isNotEmpty( relatedPaths.results ) ) {

      // the following is all just to debug info to the server
      const pathsServerDebug = {
        message: 'truncated related_paths data',
        // eslint-disable-next-line camelcase
        path_count: 0,
        // eslint-disable-next-line camelcase
        edge_count: 0,
        // eslint-disable-next-line camelcase
        node_count: 0,
        // eslint-disable-next-line camelcase
        edge_display_priority: relatedPaths.results.edge_display_priority,
      };

      if ( isNotEmpty( relatedPaths.results.paths ) ) {

        const _paths = [];
        // eslint-disable-next-line camelcase
        pathsServerDebug.path_count = relatedPaths.results.paths.length;

        relatedPaths.results.paths.map( p => {
          const _path = {
            id: p.id,
            // eslint-disable-next-line camelcase
            edge_ids: p.edges,
            // eslint-disable-next-line camelcase
            node_ids: p.nodes,
          };
          _paths.push( _path );
        } );

        if ( isNotEmpty( _paths ) ) {
          pathsServerDebug.paths = _paths;
        }
      }

      if ( isNotEmpty( relatedPaths.results.edges ) ) {
        const _edges = [];
        // eslint-disable-next-line camelcase
        pathsServerDebug.edge_count = Object.values( relatedPaths.results.edges ).length;
        Object.values( relatedPaths.results.edges ).map( e => {
          const _edge = {
            id: e.id,
            // eslint-disable-next-line camelcase
            from_scope_id: e.from_scope,
            // eslint-disable-next-line camelcase
            to_scope_id: e.to_scope,
            // eslint-disable-next-line camelcase
            from_node_id: e.from_node,
            // eslint-disable-next-line camelcase
            to_node_id: e.to_node,
          };
          _edges.push( _edge );
        } );

        if ( isNotEmpty( _edges ) ) {
          pathsServerDebug.edges = _edges;
        }
      }

      if ( isNotEmpty( relatedPaths.results.nodes ) ) {
        const _nodes = [];
        // eslint-disable-next-line camelcase
        pathsServerDebug.node_count = Object.values( relatedPaths.results.nodes ).length;
        Object.values( relatedPaths.results.nodes ).map( n => {
          const _node = {
            id: n.id,
            // eslint-disable-next-line camelcase
            scope_id: n.scope_id,
          };
          _nodes.push( _node );
        } );

        if ( isNotEmpty( _nodes ) ) {
          pathsServerDebug.nodes = _nodes;
        }
      }
      console.log( pathsServerDebug );
      if ( isNotEmpty( pathsServerDebug ) ) {
        logToServer( pathsServerDebug );
      }

      const globalScope = {
        id: '00000000-0000-0000-0000-000000000000',
        label: 'Global',
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      };

      const attackerNode = {
        id: '13371337-1337-1337-1337-133713371337',
        label: 'Adversary',
        type: 'attacker',
        // eslint-disable-next-line camelcase
        scope_id: '00000000-0000-0000-0000-000000000000',
        x: 0,
        y: 0,
        impact: null,
        original: {},
      };

      // shortcut vars for the server values, we will be iterating and mapping over everything ( a few times )
      // in order to build up the data structure in a way we can render the paths
      // eslint-disable-next-line camelcase
      const { paths, nodes, edges, edge_display_priority } = relatedPaths.results;
      const scopes = {};
      const _formattedEdges = {};

      // before going any further, we need to get all the scopes so that we can display the labels correctly
      const scopeIDs = Object.values( nodes ).map( node => node.scope_id );

      // adds any additional scopes that may somehow have been missed
      Object.values( edges ).map( edge => {
        scopeIDs.push( edge.from_scope );
        scopeIDs.push( edge.to_scope );
      } );

      // returns the needed scope labels and parent ids
      const scopesResponse = await getRecords( 'scope', {
        // eslint-disable-next-line camelcase
        extra_columns: [ 'label', 'parent', 'risk' ],
        // eslint-disable-next-line camelcase
        id_list: uniqueArray( scopeIDs ),
      } );

      if ( isNotEmpty( scopesResponse ) ) {
        scopesResponse.map( s => {
          scopes[s.id] = { ...s, riskRating: riskToRating( s.risk ) };
        } );
      }

      // need to get even more scopes because some parents only exist as the parent of a scope and have not yet been
      // fetched
      let parentScopeIDs = uniqueArray( Object.values( scopes ).map( s => s.parent ) );

      parentScopeIDs = parentScopeIDs.filter( id => id !== globalScope.id );

      // returns the needed scope labels and parent ids
      const parentScopesResponse = await getRecords( 'scope', {
        // eslint-disable-next-line camelcase
        extra_columns: [ 'label', 'parent', 'risk' ],
        // eslint-disable-next-line camelcase
        id_list: parentScopeIDs,
      } );

      if ( isNotEmpty( parentScopesResponse ) ) {
        parentScopesResponse.map( s => {
          scopes[s.id] = { ...s, riskRating: riskToRating( s.risk ) };
        } );
      }

      // more server debugs
      const scopesServerDebug = {
        message: 'Related paths scope ids',
      };

      if ( isNotEmpty( scopes ) ) {
        // eslint-disable-next-line camelcase
        scopesServerDebug.scope_ids = Object.keys( scopes );

        logToServer( scopesServerDebug );
      }

      // add more details to the edges so that it is easier to retrieve all the information later
      if ( isNotEmpty( edges ) ) {
        Object.entries( edges ).map( ( [ eID, details ] ) => {
          _formattedEdges[eID] = {
            ...details,
            // eslint-disable-next-line camelcase
            priority: edge_display_priority[eID],
            fromNodeFull: nodes[details.from_node],
            toNodeFull: nodes[details.to_node],
          };
        } );
      }

      // now that we have all the scopes we need, we need to start putting everything in the correct place
      // the basic idea is that we will re-use any existing nodes and scopes by all the paths and connect
      // the nodes together via the provided edges.
      // The structure will go as follows:
      // 1. Global Scope -> only contains the attacker node
      //  \_ 2. Top-Level scopes, can contain nodes or child scopes
      //      \_ 3. Child scopes, contains nodes
      // The layout needs to follow a few rules:
      // 1: Top-Level Scopes can never overlap
      // 2: Top-Level Scopes must contain child scopes
      // 3: Scopes are as tall as the number of nodes + child scopes at the most dense point, in other words, the first
      //    column of a scope might only have 1 node, but the next might have 2, later on it might have 3 nodes, and a
      //    child scope that contains a node itself, therefore, the height of the entire scope across all columns would
      //    be 4
      // 4. A new scope cannot be added to the same row of a previous scope is still present, it must bump down to a
      //    a lower row
      // 5. The basic order of operations for calculating the layout is as follows:
      //
      //    STEP 1 OPERATIONS
      //    1: Find what NODES go into what columns, set the X Position
      //    2: Find where each SCOPE starts, set the X Position
      //    3: Find where each SCOPE ends, set the X2 Position
      //    4: Find the width of all CHILD SCOPES
      //
      //    STEP 2 OPERATIONS
      //    6: Find the Height of all CHILD SCOPES
      //
      //    STEP 3 OPERATIONS
      //    5: Find the width of all PARENT SCOPES
      //
      //    STEP 4 OPERATIONS
      //    7: Find the Height of all PARENT SCOPES
      //
      //    STEP 5 OPERATIONS
      //    8: Calculate the Y position of all PARENT SCOPES ( reversed order from what we have done so far )
      //    9: Calculate the Y Possition of all CHILD SCOPES ( reversed order from what we have done so far )
      //    10: Calculate the Y Position of all NODES
      //
      //    STEP 6 OPERATIONS
      //    11: Calculate the height and width of the container
      //
      //    STEP 7 OPERATIONS
      //    12: Flatten all the nodes for easier lookup when drawing the svg

      const _scopeXs = {};
      const _scopeX2s = {};
      const _nodeXs = {};

      const _scopes = {};
      const _nodes = {};

      const _pathNodes = {};

      const _parentScopesByColumn = [ [ globalScope.id ] ];


      // Before creating everything, need to create the initial scopes object.
      Object.entries( scopes ).map( ( [ sID, scope ], index ) => {
        if ( scope.id !== globalScope.id ) {
          const _scopeObject = {
            id: sID,
            label: scope.label,
            x: null, // blank for now, need more information to fill this in later
            x2: null, // blank for now, need more information to fill this in later
            width: 1,
            height: null, // blank for now, need more information to fill this in later
            y: null, // blank for now, need more information to fill this in later
            itemIDs: [], // since we are later placing nodes in a particular column, it is possible that the
            // same node can be entered into a scope twice, this will prevent that from happening
            parent: scopes[scope.parent], // either global or the parent scope
            parentScopeID: scope.parent,
            childScopes: [], // filled in later once we know this
            // eslint-disable-next-line max-len
            itemsByColumn: { }, // purposely not calling this nodes by column so that it could also contain child scopes
            columnsByItem: { },
            columns: [], // empty for now, but will have a column number for each column this scope appears in
            itemType: 'scope',
            globalOrder: index,
            riskRating: scope.riskRating || 'default',
          };
          _scopes[sID] = _scopeObject;
        }
      } );

      // instead of using the original data from the server, we will be using the truncated data in order to show
      // shorter paths
      const _data = combinedData( paths, _formattedEdges, nodes, reportType === 'path' ? 30 : 11 );

      // setting an arbitrary globalPathOrder to sort everything by later
      const globalPathOrder = _data.paths.map( p => p.id );

      // STEP 1) just need to go through all the nodes and figure out which column they will go in so that nothing
      // ends up going backwards
      _data.paths.map( path => {

        if ( isEmpty( _pathNodes[path.id] ) ) {
          _pathNodes[path.id] = [];
        }

        path.nodes.map( ( node, nIndex ) => {

          let x = nIndex;

          // use the previous x placement the last time this node came up because it is higher
          if ( isNotEmpty( _nodeXs[node.id] ) && _nodeXs[node.id] > x ) {
            x =  _nodeXs[node.id];
          // use this x because it is higher
          } else {
            // record the nodeX
            _nodeXs[node.id] = x;
          }

          _pathNodes[path.id].push( node.id );
        } );
      } );

      Object.values( _pathNodes ).map( nodeIDs => {
        const xs = [];
        nodeIDs.map( id => xs.push( { id, x: _nodeXs[id] } ) );

        let fixFromIndex = 0;
        let diff = 0;

        for ( let index = 0; index < xs.length; index++ ) {

          if ( index > 0 ) {
            const lastX = xs[index - 1];
            const thisX = xs[index];

            if ( lastX.x >= thisX.x ) {
              diff = lastX.x - thisX.x + 1;
              fixFromIndex = index;
              break;
            }
          }
        }

        if ( fixFromIndex > 0 && diff > 0 ) {
          xs.map( ( x, i ) => {
            if ( i >= fixFromIndex ) {
              _nodeXs[x.id] += diff;
            }
          } );
        }

      } );

      // STEP 1.1) After figuring out the order of all the nodes do the  initial setup of the nodes, scopes,
      // starts, and ends.
      _data.paths.map( path => {

        // this orders the scopes and nodes as they appear in the paths so that we can reference their order later on
        path.nodes.map( ( node, nIndex ) => {

          if ( node.id !== attackerNode.id ) {
            // full details for the node, immediate parent, and parent's parent (if not global)
            const immediateParentScope = scopes[node.scope_id];

            let parentParentScope = globalScope;

            const isNested = immediateParentScope.parent !== globalScope.id;

            if ( isNested ) {
              parentParentScope = scopes[immediateParentScope.parent];
            }

            const x = _nodeXs[node.id];

            // this will be the "node" from now on, it is more useful than the structure from the db
            let _nodeObject = {
              original: node,
              id: node.id,
              label: node.label,
              parentScope: immediateParentScope,
              parentScopeID: immediateParentScope.id,
              parentParentScope: parentParentScope, // either global or the parent scope
              parentParentScopeID: parentParentScope.id,
              type: node.type,
              height: 1,
              x,
              y: null, // blank for now, need more information to fill this in later
              impact: node.impact,
              isSensitive: nIndex === path.nodes.length - 1, // not all end of paths have an impact it turns out
              itemType: 'node',
            };

            // this node has already come up in a path and is already part of some paths, otherwise make this the first
            if ( isNotEmpty( _nodes[node.id] ) ) {
              _nodes[node.id].pathIDs.push( path.id );
            } else {
              _nodeObject.pathIDs = [ path.id ];
            }

            // this will be the "scope" from now on, it is more useful than the structure from the db
            const _scopeObject = {
              id: immediateParentScope.id,
              label: immediateParentScope.label,
              x: null, // blank for now, need more information to fill this in later
              x2: null, // blank for now, need more information to fill this in later
              width: 1,
              height: null, // blank for now, need more information to fill this in later
              y: null, // blank for now, need more information to fill this in later
              itemIDs: [ node.id ], // since we are later placing nodes in a particular column, it is possible that the
              // same node can be entered into a scope twice, this will prevent that from happening
              parent: parentParentScope, // either global or the parent scope
              parentScopeID: parentParentScope.id,
              childScopes: [], // filled in later once we know this
              // eslint-disable-next-line max-len
              itemsByColumn: { [x]: [ _nodeObject ] }, // purposely not calling this nodes by column so that it could also contain child scopes
              columnsByItem: { [node.id]: x },
              columns: [], // empty for now, but will have a column number for each column this scope appears in
              pathIDs: [], // empty for now, but will contain all the paths where this scope shows up
              itemType: 'scope',
              riskRating: immediateParentScope.riskRating || 'default',
            };

            // if the scope ( and parent above that ) has not yet been added to the scopeX obj.
            // then this is the start of the scope. Again adding 1
            if ( isEmpty( _scopeXs[immediateParentScope.id] ) ) {
              _scopeXs[immediateParentScope.id] = x;
            }
            if ( isEmpty( _scopeXs[parentParentScope.id] ) && parentParentScope.id !== globalScope.id ) {
              _scopeXs[parentParentScope.id] = x;
            }

            // always update the _scopeX2, the last time it is overwritten will be the end. sometimes the x2 can be less
            // for the same node that is on a shorter path
            if ( isNotEmpty( _scopeX2s[immediateParentScope.id] ) && x > _scopeX2s[immediateParentScope.id] ) {
              _scopeX2s[immediateParentScope.id] = x;
            } else if ( isEmpty( _scopeX2s[immediateParentScope.id] ) ) {
              _scopeX2s[immediateParentScope.id] = x;
            }

            // also always update the parentParentScope x2, that way that scope will always contain this scope and does
            // not need to be updated to incorporate child scope widths
            if ( parentParentScope.id !== globalScope.id ) {
              if ( isNotEmpty( _scopeX2s[parentParentScope.id] ) && x > _scopeX2s[parentParentScope.id] ) {
                _scopeX2s[parentParentScope.id] = x;
              } else if ( isEmpty( _scopeX2s[parentParentScope.id] ) ) {
                _scopeX2s[parentParentScope.id] = x;
              }
            }

            // start building up (or adjusting ) the _scope obj. (if this node is within one)
            // create a new scope
            if ( isEmpty( _scopes[immediateParentScope.id] ) ) {
              _scopes[immediateParentScope.id] = _scopeObject;
            // add to an existing scope itemsByColumn, if the node has not been included in this scope yet
            } else if (
              isEmpty( _scopes[immediateParentScope.id].itemsByColumn[x] )
              && !_scopes[immediateParentScope.id].itemIDs.includes( node.id )
            ) {
              // eslint-disable-next-line max-len
              _scopes[immediateParentScope.id].itemsByColumn = { ..._scopes[immediateParentScope.id].itemsByColumn, [x]: [ _nodeObject ] };
              _scopes[immediateParentScope.id].itemIDs.push( node.id );
              _scopes[immediateParentScope.id].columnsByItem[node.id] = x;
            // create a new column and add this node to it
            } else if ( !_scopes[immediateParentScope.id].itemIDs.includes( node.id ) ) {
              // eslint-disable-next-line max-len
              _scopes[immediateParentScope.id].itemsByColumn[x] = uniqueArray( [ ..._scopes[immediateParentScope.id].itemsByColumn[x], _nodeObject ] );
              _scopes[immediateParentScope.id].itemIDs.push( node.id );
              _scopes[immediateParentScope.id].columnsByItem[node.id] = x;
            // the node already exists on this scope, need to take it out of its previous column, and add it instead to
            // this one
            } else if ( _scopes[immediateParentScope.id].itemIDs.includes( node.id ) ) {

              const oldColumnNumber = _scopes[immediateParentScope.id].columnsByItem[node.id];

              // the actual adjustment
              if (
                isNotEmpty( oldColumnNumber )
                && isNotEmpty( _scopes[immediateParentScope.id].itemsByColumn[oldColumnNumber] )
              ) {
                // remove the item from the previous column
                // eslint-disable-next-line max-len
                _scopes[immediateParentScope.id].itemsByColumn[oldColumnNumber] = _scopes[immediateParentScope.id].itemsByColumn[oldColumnNumber].filter( i => i.id !== node.id );
                // delete the old column if it is now empty
                if ( isEmpty( _scopes[immediateParentScope.id].itemsByColumn[oldColumnNumber] ) ) {
                  delete _scopes[immediateParentScope.id].itemsByColumn[oldColumnNumber];
                }
                // eslint-disable-next-line max-len
                _scopes[immediateParentScope.id].columns = _scopes[immediateParentScope.id].columns.filter( c => c !== oldColumnNumber );

                if ( isNotEmpty( _scopes[immediateParentScope.id].itemsByColumn[x] ) ) {
                  // eslint-disable-next-line max-len
                  _scopes[immediateParentScope.id].itemsByColumn[x] = uniqueArray( [ ..._scopes[immediateParentScope.id].itemsByColumn[x], _nodeObject ] );
                } else {
                  // eslint-disable-next-line max-len
                  _scopes[immediateParentScope.id].itemsByColumn = { ..._scopes[immediateParentScope.id].itemsByColumn, [x]: [ _nodeObject ] };
                }
              }
            }

            // store the node in the collection
            if ( isNotEmpty( _nodes[node.id] ) ) {
              _nodeObject = { ..._nodes[node.id], ..._nodeObject };
            }

            _nodes[node.id] = _nodeObject;
          }
        } );
      } );

      // STEP 1.2) Get all the paths from the items within a scope and bring them up to the top level.
      Object.values( _scopes ).map( scope => {
        let _pathIDs = [];
        if ( isNotEmpty( scope.itemsByColumn ) ) {
          Object.values( scope.itemsByColumn ).map( items => {
            items.map( item => {
              if ( item.itemType === 'node' && isNotEmpty( item.pathIDs ) ) {
                // eslint-disable-next-line max-len
                item.pathIDs = item.pathIDs.sort( ( a, b ) => globalPathOrder.indexOf( a ) - globalPathOrder.indexOf( b ) );
                _pathIDs = [ ..._pathIDs, ...item.pathIDs ];
              }
            } );
          } );
        }
        _pathIDs = uniqueArray( _pathIDs );

        _pathIDs = _pathIDs.sort( ( a, b ) => globalPathOrder.indexOf( a ) - globalPathOrder.indexOf( b ) );

        scope.pathIDs = _pathIDs;
      } );

      // STEP 2) Find the height of all child scopes, and then add them into the parent scope in the correct column
      Object.values( _scopes ).map( scope => {

        // this is a nested scope that is being used
        if (
          scope.parentScopeID !== globalScope.id
          && scope.id !== globalScope.id
          && isNotEmpty( scope.itemsByColumn )
        ) {
          // get the number of nodes at each column, this will represent the "height" in node units
          const nodeCountsPerColumn = Object.values( scope.itemsByColumn ).map( nodes => nodes.length );
          const max = Math.max( ...nodeCountsPerColumn );
          // set this scope's height
          scope.height = max;

          const parent = _scopes[scope.parentScopeID];

          // push this scope into the parent scope's collection of childScopes
          parent.childScopes.push( scope );

          const columnKeys = Object.keys( scope.itemsByColumn );

          const x = parseInt( columnKeys[0] );
          const x2 = parseInt( columnKeys[columnKeys.length - 1] );

          const arrayStart = x;
          const arrayEnd = x2 + 1;

          const columns = [ ...Array( arrayEnd - arrayStart ).keys() ].map( k => k += x );

          // for each column this nested scope exists, add it to the itemsByColumn collection on the parent and make
          // sure the parent's columns are updated accordingly
          columns.map( columnNumber => {
            if ( isNotEmpty( parent.itemsByColumn[columnNumber] ) ) {
              parent.itemsByColumn[columnNumber].push( scope );
            } else {
              parent.itemsByColumn[columnNumber] = [ scope ];
            }
            parent.columnsByItem[scope.id] = columnNumber;
            parent.itemIDs.push( scope.id );

            // adjust the parent columns and dimensions accordingly
            if ( !parent.columns.includes( columnNumber ) ) {
              parent.columns.push( columnNumber );
            }
          } );
        }
      } );

      // STEP 3) Need to make a quick adjustment to the widths and fill in the columns array
      Object.values( _scopes ).map( scope => {
        if ( isNotEmpty( scope.itemsByColumn ) ) {
          const itemColumnKeys = Object.keys( scope.itemsByColumn );
          const x = parseInt( itemColumnKeys[0] );
          const x2 = parseInt( itemColumnKeys[itemColumnKeys.length - 1] );
          const width = ( x2 + 1 ) - x;
          const columns = [ ...Array( ( x2 + 1 ) - x ).keys() ].map( k => k += x );
          scope.x = x;
          scope.x2 = x2;
          scope.width = width;
          scope.columns = columns;
        }
      } );

      // STEP 3.1) Need to account for an edge case where the width of a scope is incorrect due to the node changing
      // position from a later path
      Object.values( _scopes ).map( scope => {
        if (
          scope.itemIDs.length === 1
          && Object.keys( scope.itemsByColumn ).length === 1
          && scope.width !== 1
        ) {
          scope.width = 1;
          scope.x = scope.x2;
        }
      } );

      // STEP 4) Now find the height of all the parent scopes now that the child scopes have been added
      Object.values( _scopes ).map( scope => {

        // this is a scope on the global scope, or a "parent"
        if ( scope.parentScopeID === globalScope.id ) {
          // get the number of nodes at each column, this will represent the "height" in node units
          const itemHeightsByColumn = [];

          Object.values( scope.itemsByColumn ).map( items => {
            let height = 0;
            items.map( item => {
              height += item.height;
            } );
            itemHeightsByColumn.push( height );
          } );
          const max = Math.max( ...itemHeightsByColumn );
          // set this scope's height
          scope.height = max;
        }
      } );

      // STEP 5) Now that we have the height of everything figured out, we can start setting the Y values for all
      //         parent scopes, nested scopes, and finally the nodes, unlike previous steps, we are going to start
      //         on the "outside" and move in

      // STEP 5.1) Create a map of what parent scopes are present in each column so that we can start stacking the boxes
      Object.values( _scopes ).map( scope => {
        // this scope is directly on the global scope, it will effect the stacking, needs to be added to the column
        if ( scope.parentScopeID === globalScope.id ) {
          scope.columns.map( columnNumber => {
            if ( isNotEmpty( _parentScopesByColumn[columnNumber] ) ) {
              _parentScopesByColumn[columnNumber].push( scope.id );
            } else  {
              _parentScopesByColumn[columnNumber] = [ scope.id ];
            }
          } );
        }
      } );

      // sort the columns so that the scope that started furthest to the left is always first
      _parentScopesByColumn.map( ( column, index ) => {
        if ( index !== 0 ) {
          column = column.sort( ( a, b ) => _scopes[a].globalOrder - _scopes[b].globalOrder );
        }
        _parentScopesByColumn[index] = column;
      } );

      const scopesWithYs = [];
      const itemsWithYs = [];

      // STEP 5.2a) initial setting of the  Y values of all the parent scopes, this will be adjusted if it forms any
      // vertical gaps in any column that could be filled
      _parentScopesByColumn.map( scopeIDs => {

        // the scope that started first will always take precedent
        const sortedIDs = scopeIDs.sort( ( a, b ) => _scopes[a].x - _scopes[b].x );

        if ( isNotEmpty( sortedIDs ) ) {
          sortedIDs.map( ( sID, scopeIndex ) => {

            const _scope = _scopes[sID];
            // this is not the global scope, and it has not yet been stacked
            if ( isNotEmpty( _scope ) && _scope.id !== globalScope.id && !scopesWithYs.includes( _scope.id ) ) {

              if ( scopeIndex > 0 ) {

                const previousScope = _scopes[sortedIDs[scopeIndex - 1]];
                _scope.y = previousScope.y + previousScope.height;

              } else {
                _scope.y = 0;
              }
              scopesWithYs.push( _scope.id );
            }
          } );
        }
      } );

      // STEP 5.2b) Need to do another pass on the Y values of the scopes to make sure that no gaps appeared and
      // some scopes were placed lower than they needed to
      _parentScopesByColumn.map( ( scopeIDs, columnIndex ) => {
        // see if there are any gaps in this column based on the current state of the scopes
        // after all the ys are set, go back through and mark all the gaps
        const gaps = [];

        const adjustedScopes = [];

        // sort the scopes based on the new y value that was just set
        const sortedIDs = scopeIDs.sort( ( a, b ) => _scopes[a].y - _scopes[b].y );

        sortedIDs.map( ( sID, sIndex ) => {
          const _scope = _scopes[sID];
          // this is not the global scope
          if ( isNotEmpty( _scope ) ) {

            // if the first scope does not start at the top
            if ( sIndex === 0 && _scope.y !== 0 ) {
              gaps.push( { start: 0, end: _scope.y } );
            // this is not the lowest scope in the stack
            } else if ( sIndex < sortedIDs.length - 1 ) {
              const nextScope = _scopes[sortedIDs[sIndex + 1]];
              // this represents a gap, the height of this scope does not reach the bottom of the next one
              if ( _scope.y + _scope.height < nextScope.y ) {
                gaps.push( { start: _scope.y + _scope.height, end: nextScope.y } );
              }
            }
          }
        } );

        // if this column has any gaps, lets move some scope boxes up if we can
        if ( isNotEmpty( gaps ) ) {
          gaps.map( gap => {
            const { start, end } = gap;

            let currentStart = start;
            let remainingHeight = end - start;

            scopeIDs.map( sID => {
              const _scope = _scopes[sID];

              // if there is a scope (not global) and the scope starts at this column and is not already at the top
              // and it could fit in the remaining height of any gaps that exist, move it up
              if (
                isNotEmpty( _scope )
                && _scope.x === columnIndex
                && _scope.y !== 0
                && _scope.height <= remainingHeight
                && !adjustedScopes.includes( _scope.id )
              ) {
                _scope.y = currentStart;
                currentStart += _scope.height;
                remainingHeight -= _scope.height;
                adjustedScopes.push( _scope.id );
              }
            } );
          } );
        }
      } );

      // STEP 5.3) set the y values of all "items" that exist directly within a parent scope
      //           (this could be nodes or nested scopes) this will be very similar to how we stacked the parent scopes,
      //           column by column, keeping track of what has already been set
      Object.values( _scopes ).map( scope => {
        // this scope is direclty on the global scope, it will effect the stacking, needs to be added to the column
        if ( scope.parentScopeID === globalScope.id ) {

          // go through each item and set its y based on its index within the array of items in this column
          // use the parent's y as the starting point that gets added to
          Object.values( scope.itemsByColumn ).map( items => {

            const itemHeightsInThisColumn = [];

            const sortedItems = items.sort(
              ( a, b ) => ( isNotEmpty( a.pathIDs ) ? globalPathOrder.indexOf( a.pathIDs[0] ) : -1 )
                - ( isNotEmpty( b.pathIDs ) ? globalPathOrder.indexOf( b.pathIDs[0] ) : -1 ),
            );

            sortedItems.map( item => {
              itemHeightsInThisColumn.push( { id: item.id, height: item.height } );
            } );

            items.map( ( item, itemIndex ) => {
              // has not yet been stacked
              if ( !itemsWithYs.includes( item.id ) ) {

                const heightsAboveThisItem = itemHeightsInThisColumn.filter( ( s, index ) => index < itemIndex );

                const totalHeightAboveThisScope = heightsAboveThisItem.reduce( ( a, b ) => a + b.height, 0 );

                item.y = totalHeightAboveThisScope + scope.y;

                itemsWithYs.push( item.id );
              }
            } );
          } );
        }
      } );

      // STEP 5.4) set the y values of all nodes that exist in the nested scopes this will be very similar to how we
      //           stacked the items above, column by column, keeping track of what has already been set
      Object.values( _scopes ).map( scope => {
        // a nested scope
        if ( scope.parentScopeID !== globalScope.id ) {

          // go through each item and set its y based on its index within the array of items in this column
          // use the parent's y as the starting point that gets added to
          Object.values( scope.itemsByColumn ).map( items => {

            const itemHeightsInThisColumn = [];

            items.map( item => {
              itemHeightsInThisColumn.push( { id: item.id, height: item.height } );
            } );

            items.map( ( item, itemIndex ) => {
              // has not yet been stacked
              if ( !itemsWithYs.includes( item.id ) ) {

                const heightsAboveThisItem = itemHeightsInThisColumn.filter( ( s, index ) => index < itemIndex );

                const totalHeightAboveThisScope = heightsAboveThisItem.reduce( ( a, b ) => a + b.height, 0 );

                item.y = totalHeightAboveThisScope + scope.y;

                itemsWithYs.push( item.id );
              }
            } );
          } );
        }
      } );

      // STEP 6) Need to calculate the height and width of the entire SVG, width is easy, just the number of columns,
      //         height needs to add up the height of each parent scope that exists in each column and find the max
      const svgWidth = _parentScopesByColumn.length;

      const columnHeights = [];

      _parentScopesByColumn.map( scopeIDs => {
        const lastScopeID = scopeIDs[scopeIDs.length -1];

        if ( lastScopeID !== globalScope.id ) {
          const lastScope = _scopes[scopeIDs[scopeIDs.length -1]];
          const height = lastScope.y + lastScope.height;
          columnHeights.push( height );
        }
      } );

      // STEP 7) Flatten all the nodes for easier lookup
      Object.values( _scopes ).map( scope => {
        if ( isNotEmpty( scope.itemsByColumn ) ) {
          Object.values( scope.itemsByColumn ).map( items => {
            items.map( item => {
              if ( item.itemType === 'node' ) {

                if ( isNotEmpty( _nodes[item.id] ) ) {
                  _nodes[item.id] = {
                    ..._nodes[item.id],
                    ...item,
                  };
                } else {
                  _nodes[item.id] = item;
                }
              }
            } );
          } );
        }
      } );
      // add the attackerNode
      _nodes[attackerNode.id] = attackerNode;

      // STEP 7.1) Split the scopes into 2 obj. parents and children
      const parentScopes = {};
      const childScopes = {};

      Object.values( _scopes ).map( scope => {
        // this is a scope on the global scope, or a "parent"
        if ( scope.parentScopeID === globalScope.id ) {
          parentScopes[scope.id] = scope;
        } else {
          childScopes[scope.id] = scope;
        }
      } );

      const edgesByPath = {};
      const pathEdgeIDs= {};

      // STEP 7.2) Group the edges by sensitiveAssetId, because of the way I am hovering over the sensitive assets
      //           in the svg, I want to re-render the edges so that the edges associated with the selected sensitive
      //           asset will appear on top always
      Object.values( _data.edges ).map( edge => {

        if ( isEmpty( edgesByPath[edge.pathID] ) ) {
          edgesByPath[edge.pathID] = [ edge ];
          pathEdgeIDs[edge.pathID] = [ edge.id ];
        } else {
          edgesByPath[edge.pathID].push( edge );
          pathEdgeIDs[edge.pathID].push( edge.id );
        }
      } );

      const edgeIDsToPathIDs = {};

      Object.entries( pathEdgeIDs ).map( ( [ pathID, edgeIDs ] ) => {
        edgeIDs.map( eID => {
          if ( isEmpty( edgeIDsToPathIDs[eID] ) ) {
            edgeIDsToPathIDs[eID] = [ pathID ];
          } else {
            edgeIDsToPathIDs[eID].push( pathID );
          }
        } );
      } );

      // set the data so we can render
      setData( {
        edges: _data.edges,
        edgesByPath,
        nodes: _nodes,
        scopes: _scopes,
        parentScopes,
        childScopes,
        height: Math.max( ...columnHeights ) || 5,
        width: svgWidth || 16,
      } );
    }
  };

  // Sets up all the data once the item comes in
  React.useEffect( () => {
    const hash = decodeURLHash();
    if ( isNotEmpty( item ) && isNotEmpty( reportType ) && isNotEmpty( relatedPaths ) ) {
      if ( hash.page === 'reporting_dashboard' && isEmpty( data ) ) {
        setupPathData();
      } else if ( hash.page !== 'reporting_dashboard' ) {
        setupPathData();
      }
    }
  }, [ item, reportType, relatedPaths ] );

  const handleSVGItemHover = item => {
    setSelectedItemID( item.id );
    setSelectedPathIDs( item.pathIDs );
  };

  const handleSVGItemLeave = () => {
    setSelectedPathIDs( null );
    setSelectedItemID( null );
  };

  const getArrowDirection = ( x1, y1, x2, y2 ) => {
    // vertical north or south
    if ( x1 === x2 ) {
      if ( x2 >= x1 ) {
        return 's';
      }
      return 'n';
    }

    // horizontal west or east
    if ( y1 === y2 ) {
      if ( y2 >= y1 ) {
        return 'e';
      }
      return 'w';
    }

    // going from west to east, either ne or se
    if ( x2 >= x1 ) {
      if ( y2 >= y1 ) {
        return 'se';
      }
      return 'ne';

    }
    // going from east to west, either nw or sw
    if ( y2 >= y1 ) {
      return 'sw';
    }
    return 'nw';
  };

  const isHovered = item => {
    if ( isEmpty( selectedItemID ) ) {
      return false;
    }
    return selectedItemID === item.id;
  };

  const isSelected = item => {
    if ( isEmpty( selectedPathIDs ) ) {
      return false;
    }
    return item.pathIDs?.some( i => selectedPathIDs?.includes( i ) );
  };

  const isExternallyHoveringScope = scope => isNotEmpty( hoveredListItemID ) && hoveredListItemID === scope.id;
  const isExternallyHoveringEdge = edge => isNotEmpty( hoveredListItemID ) && hoveredListItemID === edge.id;

  const onHostDetailPageForScope = scope => isNotEmpty( reportType )
    && reportType === 'host'
    && isNotEmpty( item )
    && item.id === scope.id;

  const onUserPageForNode = node => isNotEmpty( reportType )
    && reportType === 'user'
    && isNotEmpty( item )
    && item.node_id === node.id;

  // whenever a sensitive node is hovered, re-order the edges for render
  React.useEffect( ( ) => {
    orderEdgeRendering();
  }, [ selectedPathIDs, data, hoveredListItemID, hoveredListItemRating ] );

  // pulling this functionality out so that I can control which edges are rendered and in what order
  const orderEdgeRendering = () => {

    const renderEdgeGroup = edge => {
      const arrowWidth = 9;
      const iconWidth = 16;

      const fromNode = data.nodes[edge.from_node];
      const toNode = data.nodes[edge.to_node];

      const isVertical = fromNode.x === toNode.x;
      const verticalDown = isVertical && fromNode.y < toNode.y;

      const arrowDirection = getArrowDirection( fromNode.x, fromNode.y, toNode.x, toNode.y );

      const isBackWards = fromNode.x > toNode.x;

      // eslint-disable-next-line max-len
      const x1 = ( fromNode.x * nodeContainerWidth ) + ( nodeContainerWidth / 2 ) + ( isBackWards ? -( iconWidth / 2 ) : ( iconWidth / 2 ) ) + ( isBackWards ? -2 : 2 );
      const y1 = ( fromNode.y * nodeContainerHeight ) + ( nodeContainerHeight / 2 );
      // eslint-disable-next-line max-len
      const x2 = ( toNode.x * nodeContainerWidth ) + ( nodeContainerWidth / 2 ) + ( isBackWards ? ( iconWidth / 2 ) : -( iconWidth / 2 ) ) + ( isBackWards ? 2 : -2 );
      const y2 = ( toNode.y * nodeContainerHeight ) + ( nodeContainerHeight / 2 );

      const width = x2 - x1;
      const height = y2 - y1;

      // verticalLine vars
      const vX1 = ( fromNode.x * nodeContainerWidth ) + ( nodeContainerWidth / 2 );
      const vX2 = vX1;
      // eslint-disable-next-line max-len
      const vY1 = ( fromNode.y * nodeContainerHeight ) + ( nodeContainerHeight / 2 ) + ( verticalDown ? 10 : -10 );
      // eslint-disable-next-line max-len
      const vY2 = ( toNode.y * nodeContainerHeight ) + ( nodeContainerHeight / 2 ) + ( verticalDown ? -10 : 10 );

      const M = `${x1}, ${y1}`;
      const C = `${x1 + ( width * ( 3 / 4 ) )}, ${y1} ${x1 + ( width / 4 )},${y2} ${x2}, ${y2}`;
      return <g
        // eslint-disable-next-line max-len
        className={ `edgeGroup edgeID_${edge.id} pathID_${edge.pathID} ${hoveredListItemRating ? `hoverRatingOverride-${hoveredListItemRating}` : '' } ${ isExternallyHoveringEdge( edge ) ? 'hovered externallyHovered' : '' } sensitiveAssetID_${edge.sensitiveAssetID} ${ isHovered( edge ) ? 'hovered' : '' } ${ isSelected( edge ) ? 'selected' : '' }` }
        onMouseEnter={ () => handleSVGItemHover( edge ) }
        onMouseLeave={ handleSVGItemLeave }
        onClick={ e => edgeOnClickCallback( edge, e ) }
      >
        {
          isVertical
            ? <React.Fragment>
              <line
                className="edgePath"
                x1={ vX1 }
                x2={ vX2 }
                y1={ vY1 }
                y2={ vY2 }
                strokeWidth={ 1.125 }
                stroke={ getRiskFill( edge, isSelected( edge ), isHovered( edge ), isExternallyHoveringEdge( edge ) ) }
                strokeLinecap="round"
                strokeDasharray={
                  ( edge.isCombined === true && edge.includedEdges?.length > 1 ) ? '4 4' : 'none'
                }
              />

            </React.Fragment>
            : <React.Fragment>
              {/* This is what actually gets the hover functionality */}
              <path
                // eslint-disable-next-line max-len
                d={ `M ${M} C ${C}`}
                strokeWidth={ 10 }
                stroke="#fff"
                opacity={ 0 }
                strokeDasharray={
                  ( edge.isCombined === true && edge.includedEdges?.length > 1 ) ? '4 4' : 'none'
                }
                strokeLinecap="round"
                fill="none"
                className="edgeHoverPath"
              />
              <path
                // eslint-disable-next-line max-len
                d={ `M ${M} C ${C}`}
                strokeWidth={ 1.25 }
                stroke={ getRiskFill( edge, isSelected( edge ), isHovered( edge ), isExternallyHoveringEdge( edge ) ) }
                strokeDasharray={
                  ( edge.isCombined === true && edge.includedEdges?.length > 1 ) ? '4 4' : 'none'
                }
                strokeLinecap="round"
                fill="none"
                className="edgePath"
              />
            </React.Fragment>
        }
        {/* draw a little arrow or combined count for each edge */}
        {
          ( edge.isCombined === true && edge.includedEdges?.length > 1 )
            ? <g>
              <circle
                strokeWidth={ 1 }
                fill="#FFF"
                className="edgeCountCircle"
                stroke={ getRiskFill( edge, isSelected( edge ), isHovered( edge ), isExternallyHoveringEdge( edge ) ) }
                r={ arrowWidth / 2 }
                cx={ x1 + ( width / 2 )}
                cy={ y1 + ( height / 2 )}
              />

              <text
                x={ x1 + ( width / 2 ) }
                y={ y1 + ( height / 2 ) + 0.5 }
                fontSize={ 6 }
                textAnchor="middle"
                alignmentBaseline="middle"
                fontWeight={ 600 }
                className="edgeCount"
                fill={ getRiskFill( edge, isSelected( edge ), isHovered( edge ), isExternallyHoveringEdge( edge ) ) }
              >
                { edge.includedEdges.length }
              </text>
            </g>
            : <svg
              x={ x1 + ( width / 2 ) - ( arrowWidth / 2 ) }
              y={ y1 + ( height / 2 ) - ( arrowWidth / 2 )}
              width={ arrowWidth }
              height={ arrowWidth }
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className={ `edgeArrow ${arrowDirection}` }
                // eslint-disable-next-line max-len
                d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7Z"
                fill={ getRiskFill( edge, isSelected( edge ), isHovered( edge ), isExternallyHoveringEdge( edge ) ) }
              />
              <path
                className={ `edgeArrowDirection ${arrowDirection}` }
                // eslint-disable-next-line max-len
                d="M1.4 7C1.4 3.913 3.913 1.4 7 1.4C10.087 1.4 12.6 3.913 12.6 7C12.6 10.087 10.087 12.6 7 12.6C3.913 12.6 1.4 10.087 1.4 7ZM0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0C3.136 0 0 3.136 0 7ZM7 6.3H4.2V7.7H7V9.8L9.8 7L7 4.2V6.3Z"
                fill="#FFF"
              />
            </svg>
        }
      </g>;
    };

    const _orderedEdges = {
      selected: [],
      deselected: [],
    };

    if ( isNotEmpty( data ) && isNotEmpty( data.edgesByPath ) ) {
      // nothing is selected render normally
      if ( isEmpty( selectedPathIDs ) ) {
        Object.values( data.edgesByPath ).map( edges => {
          edges.map( edge => {
            _orderedEdges.deselected.push( { id: edge.id, content: renderEdgeGroup( edge ) } );
          } );
        } );
      // force the selectedPath edges to the bottom
      } else {
        Object.entries( data.edgesByPath ).map( ( [ pathID, edges ] ) => {
          if ( !selectedPathIDs.includes( pathID ) ) {
            edges.map( edge => {
              _orderedEdges.deselected.push( { id: edge.id, content: renderEdgeGroup( edge ) } );
            } );
          }
          if ( selectedPathIDs.includes( pathID ) ) {
            edges.map( edge => {
              _orderedEdges.selected.push( { id: edge.id, content: renderEdgeGroup( edge ) } );
            } );
          }
        } );
      }
    }

    setOrderedEdges( _orderedEdges );
  };

  // EVENT HANDLERS FOR DOUBLE-CLICKING TO ZOOM -------------//
  const handleItemDoubleClick = ( e, item, type ) => {
    handleRecordCardClose();

    const hash = decodeURLHash();

    if ( isNotEmpty( svgContainerRef ) && isNotEmpty( svgContainerRef.current ) && hash.report !== 'paths' ) {
      const svgDimensions = getDimensionsAndOffset( svgContainerRef.current );

      const PADDING = 16;

      if ( type === 'node' ) {
        const iconWidth = item.type === 'attacker' ? 20 : 13;

        const nodeX = ( item.x * nodeContainerWidth ) + ( ( nodeContainerWidth - iconWidth ) / 2 );
        const nodeY = ( item.y * nodeContainerHeight ) + ( ( nodeContainerHeight - iconWidth ) / 2 );

        const scale = ( svgDimensions.height - PADDING ) / ( iconWidth ) * 0.125;

        const x = ( ( nodeX ) * scale * -1 ) + ( ( svgDimensions.width / 2 ) / scale );
        const y = ( nodeY ) * scale * -1 + ( ( svgDimensions.height / 2 ) / scale );

        setSVGScale( scale );
        setSVGPanShift( { x, y } );
      }
      if ( type === 'scope' ) {
        const multiplier = nodeContainerWidth;
        const xyOffset = nodeContainerWidth / 8;
        const widthHeightOffset = nodeContainerWidth / 4;

        const height = ( item.height * multiplier ) - widthHeightOffset;

        const scale = ( svgDimensions.height - PADDING ) / ( height ) * 0.25;

        const scopeX = ( item.x * multiplier ) + xyOffset;
        const scopeY = ( item.y * multiplier ) + xyOffset;

        const x = ( scopeX * scale * -1 ) + ( PADDING * 3 );
        const y = scopeY * scale * -1 + PADDING;

        setSVGScale( scale );
        setSVGPanShift( { x, y } );
      }
    }
  };

  // END EVENT HANDLERS -------------------------------------//

  const revertZoom = () => {
    handleRecordCardClose();
    setSVGPanShift( DEFAULT_PANSHIFT );
    setSVGScale( DEFAULT_SCALE );
  };

  const toggleFullscreen = () => {
    setFullscreen( !fullscreen );
  };

  return (
    <React.Fragment>
      {
        <Modal
          visible={ fullscreen }
          setVisible={ setFullscreen }
          size="fullscreen"
          elementClass="pathsGraphFullscreenModal"
          needsActions={false}
          body={ <SVGContent
            data={data}
            nodeContainerHeight={nodeContainerHeight}
            nodeContainerWidth={nodeContainerWidth}
            revertZoom={revertZoom}
            svgContainerRef={svgContainerRef}
            svgPanShift={svgPanShift}
            svgScale={svgScale}
            hoveredListItemRating={hoveredListItemRating}
            isExternallyHoveringScope={isExternallyHoveringScope}
            onHostDetailPageForScope={onHostDetailPageForScope}
            orderedEdges={orderedEdges}
            handleItemDoubleClick={handleItemDoubleClick}
            item={item}
            onUserPageForNode={onUserPageForNode}
            isSelected={isSelected}
            isHovered={isHovered}
            nodeOnClickCallback={nodeOnClickCallback}
            handleSVGItemHover={handleSVGItemHover}
            handleSVGItemLeave={handleSVGItemLeave}
            toggleFullscreen={toggleFullscreen}
            fullscreen={fullscreen}
            withinModal
          /> }
        />
      }
      <SVGContent
        data={data}
        nodeContainerHeight={nodeContainerHeight}
        nodeContainerWidth={nodeContainerWidth}
        revertZoom={revertZoom}
        svgContainerRef={svgContainerRef}
        svgPanShift={svgPanShift}
        svgScale={svgScale}
        hoveredListItemRating={hoveredListItemRating}
        isExternallyHoveringScope={isExternallyHoveringScope}
        onHostDetailPageForScope={onHostDetailPageForScope}
        orderedEdges={orderedEdges}
        handleItemDoubleClick={handleItemDoubleClick}
        item={item}
        onUserPageForNode={onUserPageForNode}
        isSelected={isSelected}
        isHovered={isHovered}
        nodeOnClickCallback={nodeOnClickCallback}
        handleSVGItemHover={handleSVGItemHover}
        handleSVGItemLeave={handleSVGItemLeave}
        toggleFullscreen={toggleFullscreen}
        fullscreen={fullscreen}
      />
    </React.Fragment>
  );
};

export default PathsGraph;