/* eslint-disable camelcase */
/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import InsightFilters from '../../Filters';
import InsightVisual from '../../Visual';
import InsightTable from '../../Table';

import {
  isNotEmpty,
  paramsToFilters,
  decodeURLHash,
  encodeURLHash,
  isInequalityType,
} from '../../../../shared/Utilities';

import './style.scss';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../../legacy/io';
import InlineSVG from '../../../../shared/InlineSVG';
import Toggle from '../../../../shared/Toggle';
import { getRowNums, pageIterator } from '../../../../shared/Pagination/IndeterminantPagination';
import PageHeader from '../../../../shared/PageHeader';
import RecordCard from '../../../Explore/RecordCard';

const VulnerabilityInstances = () => {
  const [ visualMode, setVisualMode ] = React.useState( 'categories' );
  const [ visualLoading, setVisualLoading ] = React.useState( true );
  const [ tableLoading, setTableLoading ] = React.useState( true );

  const [ visualData, setVisualData ] = React.useState( null );

  const [ sortBy, setSortBy ] = React.useState( 'filtered_risk' );
  const [ sortDirection, setSortDirection ] = React.useState( 'DESC' );

  // pagination related state variables
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState( 1 );
  const [ currentPageResults, setCurrentPageResults ] = React.useState( [] );
  const [ nextPageResults, setNextPageResults ] = React.useState( [] );

  // controls the top overview panel collapsed state, storing here to pass down to multiple components
  const [ visualcollapsed, setVisualCollapsed ] = React.useState( false );
  // controls the filter drawer at the top level
  const [ filtersCollapsed, setFiltersCollapsed ] = React.useState( true );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const [ selectedSignature, setSelectedSignature ] = React.useState( null );

  // eslint-disable-next-line no-unused-vars
  const isMounted = true;


  // main fetch method called whenever the filters change
  const onRefresh = async ( refreshTallies=true ) => {
    const onCorrectPage = decodeURLHash()['report'] === 'instances';

    if ( onCorrectPage ) {
      setVisualLoading( true );
      setTableLoading( true );

      const filterValues = paramsToFilters();

      const params = {
        project: 'default',
        model: 'base',
        filters: {},
      };

      const _groupType = decodeURLHash()['group_type'] || 'host';

      const _rowNums = getRowNums( filterValues );

      if ( isNotEmpty( filterValues ) ) {

        const gt_map = {};
        const lt_map = {};

        Object.entries( filterValues ).map( ( [ key, val ] ) => {
          if ( isInequalityType.includes( key ) ) {
            const [ inequality, unit ] = val;

            if ( isNotEmpty( unit ) && unit !== 'null' && unit !== null && !isNaN( unit ) ) {
              if ( inequality === 'gt_map' ) {
                gt_map[key] = parseFloat( unit );
              } else if ( inequality === 'lt_map' ) {
                lt_map[key] = parseFloat( unit );
              }
            }
            if ( isNotEmpty( gt_map ) ) {
              params.filters.gt_map = gt_map;
            }
            if ( isNotEmpty( lt_map ) ) {
              params.filters.lt_map = lt_map;
            }
          } else {
            params.filters[key] = val;
          }
        } );

        if ( isNotEmpty( params.filters ) ) {
          delete params.filters.sort_by;
          delete params.filters.sort_direction;
          delete params.filters.group_type;
        }

        const tallyParams = { ...params };

        const _groupParams = {
          ...tallyParams,
          // eslint-disable-next-line camelcase
          group_type: _groupType,
          rownums: _rowNums,
        };

        if ( !refreshTallies ) {
          delete tallyParams.filters.exploit_statuses;
        }

        makeRequest( 'TALLY', '/analysis/instance', tallyParams ).then( response => {
          // transforming the server data into the structure necessary for icicle chart
          if ( isNotEmpty( response ) ) {
            if ( isMounted ) {
              const _visualData ={
                tally: response,
              };
              setVisualData( _visualData );
              setVisualLoading( false );
            }
          } else if ( isMounted ) {
            addFlashMessage( {
              type: 'alert',
              body: 'Your filters returned an error',
            } );
            setVisualData( {} );
            setVisualLoading( false );
          }
        } );
        if ( isMounted ) {
          refreshTable( _groupParams );
        }
      }
    }
  };

  const refreshTable = () => {

    if ( decodeURLHash()['report'] === 'instances' ) {

      // clear out existing results
      setCurrentPageResults( [] );
      setNextPageResults( [] );

      const params = {
        project: 'default',
        model: 'base',
        filters: {},
      };

      const filterValues = paramsToFilters();

      const _rowNums = getRowNums( filterValues );

      if ( isNotEmpty( filterValues ) ) {

        const gt_map = {};
        const lt_map = {};

        Object.entries( filterValues ).map( ( [ key, val ] ) => {
          if ( key === 'item_count' ) {
            params.rownums = _rowNums;
          } else if ( isInequalityType.includes( key ) ) {
            const [ inequality, unit ] = val;

            if ( isNotEmpty( unit ) ) {
              if ( inequality === 'gt_map' ) {
                gt_map[key] = parseFloat( unit );
              } else if ( inequality === 'lt_map' ) {
                lt_map[key] = parseFloat( unit );
              }
            }
            if ( isNotEmpty( gt_map ) ) {
              params.filters.gt_map = gt_map;
            }
            if ( isNotEmpty( lt_map ) ) {
              params.filters.lt_map = lt_map;
            }
          } else {
            params.filters[key] = val;
          }
        } );

        const _groupType = filterValues.group_type || 'host';
        // eslint-disable-next-line camelcase
        const _groupParams = { ...params, group_type: _groupType };
        const _sortBy = filterValues.sort_by || [ 'filtered_risk' ];
        const _sortDirection = filterValues.sort_direction || [ 'DESC' ];

        if ( isNotEmpty( params.filters ) ) {
          delete params.filters.sort_by;
          delete params.filters.sort_direction;
          delete params.filters.group_type;
        }

        if ( isNotEmpty( _groupParams ) ) {
          setTableLoading( true );
          // eslint-disable-next-line camelcase
          let params = {
            ..._groupParams,
            // eslint-disable-next-line camelcase
            group_type: _groupType,
            // eslint-disable-next-line camelcase
            order_by: [],
            rownums: _rowNums,
          };
          if (
            isNotEmpty( _sortBy )
            && isNotEmpty( _sortDirection )
            && Array.isArray( _sortBy )
          ) {
            _sortBy.map( s => {
              params.order_by.push( [ s, _sortDirection ] );
            } );
          } else {
            // eslint-disable-next-line camelcase
            params.order_by = [ [ _sortBy, _sortDirection ] ];
            // eslint-disable-next-line camelcase
            params = { ...params, order_by: [ [ _sortBy, _sortDirection ] ] };
          }
          makeRequest( 'GROUP', '/analysis/instance', params ).then( response => {
            if ( isMounted ) {
              const pagedResults = pageIterator( response.results, filterValues );

              if ( isNotEmpty( pagedResults ) ) {
                setCurrentPageNumber(
                  pagedResults.currentPageNumber ? parseInt( pagedResults.currentPageNumber ) : 1,
                );
                setCurrentPageResults( pagedResults.firstPage );
                setNextPageResults( pagedResults.secondPage );
                setTableLoading( false );
              } else {
                setCurrentPageNumber( 1 );
                setCurrentPageResults( [] );
                setNextPageResults( [] );
              }
            }
          } );
        }
      }
    }
  };

  // sorting by clicking the column headers in the table
  React.useEffect( () => {
    const currentHash = decodeURLHash();

    if ( sortBy !== currentHash.sort_by || sortDirection !== currentHash.sort_direction ) {
      // eslint-disable-next-line camelcase
      encodeURLHash( { sort_by: sortBy, sort_direction: sortDirection } );
      refreshTable();
    }
  }, [ sortBy, sortDirection ] );

  // callback closing the signature card
  const handleRecordCardClose = () => {
    setSelectedSignature( null );
  };

  return (
    <React.Fragment>
      <Toggle
        toggled={ visualMode }
        setToggled={ setVisualMode }
        elementClass="instanceVisualModeToggle optionCount--3"
        options= { {
          categories: <React.Fragment>
            <InlineSVG type="categoriesVisual" />
            Categories
          </React.Fragment>,
          widgets: <React.Fragment>
            <InlineSVG type="widgetVisual" />
            Widgets
          </React.Fragment>,
          icicle: <React.Fragment>
            <InlineSVG type="icicleVisual" />
            Icicle
          </React.Fragment>,

        } }
      />
      <PageHeader>
        <InsightFilters
          onRefresh={onRefresh}
          collapsed={filtersCollapsed}
          visualCollapsed={visualcollapsed}
          setVisualCollapsed={setVisualCollapsed}
          setCollapsed={setFiltersCollapsed}
          reportType="instance"
          tableLoading={ tableLoading }
          visualLoading={ visualLoading }
        />
        <InsightVisual
          reportType="instance"
          loading={visualLoading}
          collapsed={visualcollapsed}
          setCollapsed={setVisualCollapsed}
          visualData={visualData}
          onRefresh={onRefresh}
          visualMode={visualMode}
        />
      </PageHeader>
      <InsightTable
        records={currentPageResults}
        nextRecords={nextPageResults}
        currentPageNumber={currentPageNumber}
        loading={tableLoading}
        reportType="instance"
        onRefresh={onRefresh}
        refreshTable={refreshTable}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        visualCollapsed={visualcollapsed}
        selectedSignature={selectedSignature}
        setSelectedSignature={setSelectedSignature}
      />
      {
        isNotEmpty( selectedSignature ) &&
        <RecordCard
          record={ selectedSignature }
          type="signature"
          context="risk_insight_signature_instances"
          show={ isNotEmpty( selectedSignature ) }
          setShow={ handleRecordCardClose }
          options={ {
            dismissable: true,
            includeShade: true,
          } }
        />
      }
    </React.Fragment>
  );
};

export default VulnerabilityInstances;