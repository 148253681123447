/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../../shared/Utilities';
import { getRecord } from '../../../../../shared/RecordCache';
import RecordCard from '../../../../Explore/RecordCard';

import './SpecificRecord.scss';

const SpecificRecord = ( {
  settings,
  prefetchedData,
} ) => {

  const [ record, setRecord ] = React.useState( null );

  const setupData = async () => {

    const recordID = settings[`record_id_${settings.report_type}`];

    let _record = await getRecord( settings.report_type, recordID, {}, true );
    if ( settings?.report_type === 'host' ) {
      _record = { ..._record, type: 'host' };
    }
    setRecord( _record );
  };

  React.useEffect( () => {
    if (
      isNotEmpty( settings )
      && isNotEmpty( settings.report_type )
      && (
        isNotEmpty( settings.record_id_host )
        || isNotEmpty( settings.record_id_patch )
        || isNotEmpty( settings.record_id_vulnerability )
      )
    ) {
      setupData();
    }
  }, [ settings, prefetchedData ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( record ) ) &&
        <RecordCard
          record={record}
          type={ settings?.report_type === 'host' ? 'scope' : settings?.report_type }
          options={ {
            includeRisk:  settings?.include_risk,
            includeInstances: settings?.include_instances,
            includeCVSS: settings?.include_cvss,
          } }
          show={true}
          context="dashboard"
        />
      }
    </React.Fragment>
  );
};

export default SpecificRecord;