/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../../shared/Utilities';
import { completionClass, nameForPlan, percentComplete } from '../../Shared';
import TaskItem from './TaskItem';
import './Step4.scss';

const Step4 = ( {
  setLoadingStep,
  startStep,
  users,
  planState,
  setPlanState,
  getTasks,
  activeIntegrations,
} ) => {

  return (
    <React.Fragment>
      <div className="panel review remediationPanel">
        <div className="activePlanOverView" >
          <div className="column">
            <h2>
              { nameForPlan( planState ) }
            </h2>
            <div className="recordAttribute">
              <strong>Total Risk</strong>
              <span>{ Math.ceil( planState.risk ).toLocaleString( 'en-US' ) || 0 }</span>
            </div>
            <div className="recordAttribute">
              <strong>Total Instances</strong>
              <span>{ planState.num_instances.toLocaleString( 'en-US' ) || 0 }</span>
            </div>
          </div>
          <div className="column">
            <div className="completionWrapper risk">
              <label>Risk Reduced</label>
              {/* eslint-disable-next-line max-len */}
              <div className={`${completionClass( planState.original_risk, planState.risk )} completionBarWrapper risk`}>
                <div className="completionLabel">
                  { percentComplete( planState.original_risk, planState.risk ) || '0%' }
                </div>
                <div
                  className={ 'completionBar risk' }
                  style={{
                    width: percentComplete( planState.original_risk, planState.risk ) || '0%',
                  }}
                ></div>
              </div>
            </div>
            <div className="completionWrapper instances">
              <label>Instances Remediated</label>
              {/* eslint-disable-next-line max-len */}
              <div className={ `${completionClass( planState.original_num_instances, planState.num_instances )} completionBarWrapper instances` }>
                <div
                  className={ 'completionBar instances' }
                  style={{
                    width: percentComplete(
                      planState.original_num_instances, planState.num_instances,
                    ) || '0%',
                  }}
                ></div>
                <div className="completionLabel">
                  { percentComplete( planState.original_num_instances, planState.num_instances ) || '0%' }
                </div>
              </div>
            </div>
          </div>
          <button onClick={ () => startStep( 1, planState ) } className="stepChangeButton">
            Edit Plan Details
          </button>
        </div>
        {
          isNotEmpty( planState?.tasks?.selected ) &&
          <section>
            <h2>{ `Tasks included in plan (${planState?.tasks?.selected.length})` }</h2>

            <ul className="taskList">
              {
                planState?.tasks?.selected.map( ( task, index ) => {
                  return  <TaskItem
                    task={task}
                    key={index}
                    planState={planState}
                    setPlanState={setPlanState}
                    setLoadingStep={setLoadingStep}
                    getTasks={getTasks}
                    users={users}
                    isSelected
                    isActive
                    activeIntegrations={activeIntegrations}
                  />;
                } )
              }
            </ul>
          </section>
        }
      </div>
    </React.Fragment>
  );
};

export default Step4;