/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { makeRequest } from '../../../../legacy/io';
import EmptyState from '../../../shared/EmptyState';
import InlineSVG from '../../../shared/InlineSVG';
import Loading from '../../../shared/Loading';
import { getRecords } from '../../../shared/RecordCache';
import {
  formatUnixDate,
  isNotEmpty,
  recordSorter,
  reportTypeDisplayName,
  riskToRating,
} from '../../../shared/Utilities';
import TaskItem from './EditModal/RemediationSteps/TaskItem';

import './RemediationPlanItem.scss';
import { completionClass, nameForPlan, percentComplete, typeForTask } from './Shared';
import RiskReduction from '../../../shared/RiskReduction';
import RatingBadge from '../../../shared/RatingBadge';

// gets all necessary records for the tasks and formats it correctly
export const fetchAndFormatTaskRecordData = async ( _tasks, users ) => {

  const _formatted = [];

  const hostIDs = [];
  const patchIDs = [];
  const vulnerabilityIDs = [];

  let fetchedHosts, fetchedPatches, fetchedVulnerabilities;

  _tasks.map( c => {
    if ( typeForTask( c ) === 'host' ) {
      hostIDs.push( c.task );
    }
    if ( typeForTask( c ) === 'patch' ) {
      patchIDs.push( c.task );
    }
    if ( typeForTask( c ) === 'vulnerability' ) {
      vulnerabilityIDs.push( c.task );
    }
  } );

  if ( isNotEmpty( hostIDs ) ) {
    // eslint-disable-next-line camelcase
    fetchedHosts = await getRecords( 'scope', { type: 'host', id_list: hostIDs, extra_columns: [ 'label' ] } );
  }
  if ( isNotEmpty( patchIDs ) ) {
    // eslint-disable-next-line max-len, camelcase
    fetchedPatches = await getRecords( 'patch', { id_list: patchIDs, extra_columns: [ 'vendor', 'identifier', 'patch_analysis.risk' ] } );
  }
  if ( isNotEmpty( vulnerabilityIDs ) ) {
    // eslint-disable-next-line max-len, camelcase
    fetchedVulnerabilities = await getRecords( 'vulnerability', { id_list: vulnerabilityIDs, extra_columns: [ 'identifier' ] } );
  }

  _tasks.map( c => {

    const _type = typeForTask( c );

    let _record;

    if ( _type === 'host' ) {
      _record = fetchedHosts.find( r => r.id === c.task );
    }
    if ( _type === 'vulnerability' ) {
      _record = fetchedVulnerabilities.find( r => r.id === c.task );
    }
    if ( _type === 'patch' ) {
      _record = fetchedPatches.find( r => r.id === c.task );
    }
    const _formattedTask = {
      original: c,
      risk: c.risk,
      id: c.id,
      normalizedType: _type,
      owner: isNotEmpty( c.owner ) ? users[c.owner] : 'N/A',
      label: isNotEmpty( _record ) ? reportTypeDisplayName( _record, _type ) : 'N/A',
    };

    _formatted.push( _formattedTask );
  } );

  return _formatted;
};

const RemediationPlanItem = ( { plan, users, activeIntegrations } ) => {

  const [ loadingTasks, setLoadingTasks ] = React.useState( false );
  const [ showTasks, setShowTasks ] = React.useState( false );
  const [ tasks, setTasks ] = React.useState( [] );
  const [ hasFetchedTasks, setHasFetchedTasks ] = React.useState( false );



  const fetchAndFormatTasks = async () => {
    setLoadingTasks( true );

    let _tasks;
    const taskFetch = await makeRequest( 'FETCH', '/project/default/model/base/remediation_task', {
      // eslint-disable-next-line camelcase
      remediation_plan_id: plan.id,
    } );

    if ( isNotEmpty( taskFetch ) && isNotEmpty( taskFetch.results ) ) {
      _tasks = taskFetch.results;
    } else {
      _tasks = [];
    }

    if ( isNotEmpty( _tasks ) ) {
      const _formatted = await fetchAndFormatTaskRecordData( _tasks, users );
      _formatted.sort( ( a, b ) => recordSorter( 'risk', false, a, b ) );
      setTasks( _formatted );
      setHasFetchedTasks( true );
      setLoadingTasks( false );
    } else {
      setHasFetchedTasks( true );
      setLoadingTasks( false );
    }
  };

  React.useEffect( ( ) => {
    if ( showTasks && !hasFetchedTasks && isNotEmpty( plan ) ) {
      fetchAndFormatTasks();
    }
  }, [ showTasks ] );

  return (
    <React.Fragment>
      <div className="planStatInformation" >
        <span>
          <h2>
            <span>
              <InlineSVG type="remediation_nav" />
              { nameForPlan( plan ) }
            </span>
            <span className="ratingWrapper">
              <RatingBadge altVersion rating={ riskToRating( plan.risk ) } />
              <RiskReduction item={plan} riskType="risk" />
            </span>
          </h2>
          <div className="planStatusWrapper">
            <span>Status:</span>
            <strong className={ `planStatus ${plan.status}` } >{ plan.status }</strong>
          </div>
          <div className="subText">
            <div className="lowerStat">
              <InlineSVG type="user" />
              <strong>Owner: </strong>
              <span>{ ( isNotEmpty( plan.owner ) && isNotEmpty( users ) ) ? users[plan.owner] : 'No Owner' }</span>
            </div>
            <div className="lowerStat">
              <InlineSVG type="time" />
              <strong>{ plan.status === 'closed' ? 'Closed: ' : 'Created: ' }</strong>
              <span>
                {
                  plan.status === 'closed'
                    ? formatUnixDate( plan.modified )
                    : formatUnixDate( plan.created )
                }
              </span>
            </div>
          </div>
        </span>
      </div>
      <div className="remediationPlanVisualization">
        <label>Plan Completion</label>
        <div className="completionWrapper risk">
          <div className="completionLabel">
            <strong>Risk</strong>
            <span>{ percentComplete( plan.original_risk, plan.risk ) }%</span>
          </div>
          <div className={`${completionClass( plan.original_risk, plan.risk )} completionBarWrapper risk`}>
            <div
              className={ 'completionBar risk' }
              style={{
                width: `${percentComplete( plan.original_risk, plan.risk )}%`,
              }}
            />
          </div>

        </div>
        <div className="completionWrapper instances">
          <div className="completionLabel">
            <strong>Instances</strong>
            <span>{ percentComplete( plan.original_num_instances, plan.num_instances ) }%</span>
          </div>
          {/* eslint-disable-next-line max-len */}
          <div className={ `${completionClass( plan.original_num_instances, plan.num_instances )} completionBarWrapper instances` }>
            <div
              className={ 'completionBar instances' }
              style={{
                width: `${percentComplete( plan.original_num_instances, plan.num_instances )}%`,
              }}
            />
          </div>
        </div>
      </div>
      <div className={ `expandingTasksContainer ${ showTasks ? 'expanded' : '' }` }>
        <button
          className="showTasksButton"
          onClick={ () => setShowTasks( !showTasks )}
        >
          {
            showTasks
              ? 'Hide Tasks'
              : 'Show Tasks'
          }
          <InlineSVG type="carretDown" />
        </button>

        {
          showTasks &&
          <section className="remediationTaskListSectionWrapper">
            <h2>{ `Tasks (${tasks.length})` }</h2>
            {
              loadingTasks
                ? <Loading />
                : <React.Fragment>
                  {
                    isNotEmpty( tasks )
                      ? <ul className="taskList">
                        {
                          tasks.map( ( task, index ) => {
                            return  <TaskItem
                              task={task}
                              key={index}
                              setLoadingStep={setLoadingTasks}
                              users={users}
                              isSelected
                              isActive
                              readOnly
                              activeIntegrations={activeIntegrations}
                            />;
                          } )
                        }
                      </ul>
                      : <EmptyState message="This plan has no tasks" />
                  }
                </React.Fragment>
            }
          </section>
        }
      </div>
    </React.Fragment>
  );
};

export default RemediationPlanItem;