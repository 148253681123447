/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  isNotEmpty,
  reportTypeDisplayName,
  riskToRating,
} from '../../../../shared/Utilities';

import InlineSVG from '../../../../shared/InlineSVG';

import './RemediationItem.scss';
import { RiskContext } from '../../../../Contexts/Risk';
import RatingBadge from '../../../../shared/RatingBadge';
import RiskReduction from '../../../../shared/RiskReduction';

const RemediationItem = ( {
  isActive,
  item,
  type,
  planState,
  setPlanState,
  setItemsStale,
  isSelected=false,
} ) => {

  const [ formatted, setFormatted ] = React.useState( {} );
  const [ , , targetRisk, ,  ] = React.useContext( RiskContext );

  React.useEffect( () => {
    if ( isNotEmpty( item ) && isNotEmpty( type ) ) {
      setFormatted( {
        label: reportTypeDisplayName( item, type ),
        id: item.id,
        risk: item.risk,
      } );
    }
  }, [ item, type ] );
  // eslint-disable-next-line max-len
  const hasBeenSelected = ( item, type ) => planState?.items[type] && planState?.items[type].map( i => i.id ).includes( item.id );

  const toggleSelection = async ( item, type ) => {
    setItemsStale( true );
    const _planState = { ...planState };
    if ( hasBeenSelected( item, type ) ) {
      const _items = _planState.items[type].filter( i => i.id !== item.id );
      _planState[`${type}_ids`] = _items.map( i => i.id );
      _planState.items[type] = _items;
      setPlanState( _planState );
    } else {
      const _items = [ ..._planState.items[type], item ];
      _planState[`${type}_ids`] = _items.map( i => i.id );
      _planState.items[type] = _items;
      setPlanState( _planState );
    }
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( formatted ) &&
        <li
          // eslint-disable-next-line max-len
          className={ `${hasBeenSelected( item, type ) ? 'selected' : ''} remediationItem ${isSelected ? 'alreadySelected' : ''}`}
        >
          <h3>
            <RatingBadge
              altVersion
              rating={ riskToRating( item[type === 'patch' ? 'risk' : 'filtered_risk'], targetRisk ) }
            />
            { formatted.label }
          </h3>
          <div className="actions">
            <RiskReduction item={item} riskType={ type === 'patch' ? 'risk' : 'filtered_risk' } />
            {
              !isActive &&
              <React.Fragment>
                {
                  ( isSelected || hasBeenSelected( item, type ) )
                    ? <button
                      className="removeItemButton"
                      onClick={ () => toggleSelection( item, type ) }
                    >
                      <InlineSVG type="remove" />
                    </button>
                    : <button
                      className="addItemButton"
                      onClick={ () => toggleSelection( item, type ) }
                    >
                      <InlineSVG type="add" />
                    </button>
                }
              </React.Fragment>
            }
          </div>
        </li>
      }
    </React.Fragment>
  );
};

export default RemediationItem;