/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

const recordData = {
  display: {},
};

import React from 'react';
import { makeRequest } from '../../../../../legacy/io';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue';
import SetupPage from '../../../../shared/SetupComponents/SetupPage';
import { focusForOnboarding, isEmpty, isNotEmpty } from '../../../../shared/Utilities';
import { buildPolicyRecord, parsePolicyRecord } from './shared';

const Policies = ( ) => {

  const [ policies, setPolicies ] = React.useState( null );
  const [ assetTagOptions, setAssetTagOptions ] = React.useState( {} );
  const [ maxEvalOrder, setMaxEvalOrder ] = React.useState( 0 );

  const [ loading, setLoading ] = React.useState( false );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  React.useEffect( ( ) => {
    const elementToFocus = document.getElementById( 'newItemButton' );
    focusForOnboarding( elementToFocus );
  }, [] );

  const onRefresh = async () => {
    const tagOptions = {};

    const _evalOrders = [];

    const assetTagsResponse = await makeRequest( 'SEARCH', '/project/default/asset_tag', {
      // eslint-disable-next-line camelcase
      extra_columns: [ 'label' ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'label', 'ASC' ] ],
    } );


    if ( isNotEmpty( assetTagsResponse ) && isNotEmpty( assetTagsResponse.results ) ) {
      assetTagsResponse.results.map( tag => {
        tagOptions[tag.id] = tag.label;
      } );
    }

    setAssetTagOptions( tagOptions );

    const policiesResponse = await makeRequest( 'SEARCH', '/model/base/sensitive_asset_policy', {
      filters: {
        // eslint-disable-next-line camelcase
        extra_columns: [ 'label', 'impact', 'asset_tag_id', 'criteria', 'created', 'eval_order' ],
        // eslint-disable-next-line camelcase
        order_by: [ [ 'eval_order', 'ASC' ], [ 'label', 'ASC' ] ],
      },
    } );

    // // actual endpoint might still be broken
    // const policiesResponse = stubbedSearch;

    const flattenedResults = [];

    if ( isNotEmpty( policiesResponse ) && isNotEmpty( policiesResponse.results ) ) {
      policiesResponse.results.map( ( policy ) => {
        const flattenedResult = parsePolicyRecord( policy );
        flattenedResults.push( flattenedResult );
        _evalOrders.push( flattenedResult.eval_order || 0 );
      } );

      setMaxEvalOrder( Math.max( ..._evalOrders ) );
      setPolicies( flattenedResults );
    } else {
      setMaxEvalOrder( 0 );
      setPolicies( [] );
    }
  };

  const onSave = async ( selectedRecord, isValid, updatedRecord, callback ) => {

    setLoading( true );

    const record = buildPolicyRecord( selectedRecord, isValid, updatedRecord );

    // only increment on new record
    if ( isEmpty( selectedRecord ) ) {
      // eslint-disable-next-line camelcase
      record.eval_order = maxEvalOrder + 1;
    }

    if ( isNotEmpty( record ) ) {
      // stubbed out for now, but this will be the actual upsert call
      const savedResponse = await makeRequest(
        'UPSERT',
        '/model/base/sensitive_asset_policy',
        { records: [ record ] },
      );

      setLoading( false );
      // success
      if ( isNotEmpty( savedResponse ) && isNotEmpty( savedResponse.results ) ) {
        addFlashMessage( {
          type: 'success',
          body: `Successfully ${ isNotEmpty( selectedRecord ) ? 'updated' : 'created' } policy`,
        } );
        onRefresh();
        callback();
      // error code with errors to print to the front end
      } else if ( isNotEmpty( savedResponse ) && isNotEmpty( savedResponse.errors ) ) {
        savedResponse.errors.map( e => {
          addFlashMessage( {
            type: 'alert',
            body: e,
          } );
        } );
      // likely 500
      } else {
        addFlashMessage( {
          type: 'alert',
          body: 'There was an error saving your policy, please try again',
        } );
      }
    }
  };

  const onReorder = reordered => {
    const updatedCredOrder = [];

    // optimistically reordering in the UI
    setPolicies( reordered );

    reordered.map( ( policy, index ) => {
      // eslint-disable-next-line camelcase
      updatedCredOrder.push( { ...policy, eval_order: index + 1 } );
    } );
    makeRequest( 'UPSERT', '/model/base/sensitive_asset_policy', { records: updatedCredOrder } ).then( response => {
      onRefresh();
      if ( response['errors'] ) {
        addFlashMessage( {
          body: response['errors'],
          type: 'alert',
        } );
      }
    } );
  };

  return (
    <React.Fragment>
      <SetupPage
        onReorder={onReorder}
        isDraggable
        onRefresh={onRefresh}
        onSave={onSave}
        records={policies}
        setRecords={setPolicies}
        recordType="sensitive_asset_policy"
        recordData={recordData}
        alternateItemLayout
        assetTagOptions={assetTagOptions}
        externalLoading={loading}
      />
    </React.Fragment>
  );
};

export default Policies;