/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, isNotEmpty, reportTypeDisplayName, riskToRating } from '../../../shared/Utilities';

import './PathAnalysis.scss';
import InlineSVG from '../../../shared/InlineSVG';
import Loading from '../../../shared/Loading';
import { getNodeIcon, nodeIconMap } from '../../RiskInsight/Detail/shared';
import { makeRequest, search_model_records } from '../../../../legacy/io';
import { getRecords } from '../../../shared/RecordCache';
import PathsGraph from '../../RiskInsight/Detail/Sections/PathsGraph';
import RecordCard from '../RecordCard';
import SensitiveAssetItem from './SensitiveAssetItem';
import RatingBadge from '../../../shared/RatingBadge';
import RiskReduction from '../../../shared/RiskReduction';
import PageCreateButton from '../../../shared/PageCreateButton';
import PageHeader from '../../../shared/PageHeader';
import { FullScreenVisualContext } from '../../../Contexts/FullScreenVisual';


const PathAnalysis = ( {
  path,
  selectItem,
} ) => {
  const [ , , showVisual, , showFullScreenVisual, , , ] = React.useContext( FullScreenVisualContext );

  const [ formattedEdges, setFormattedEdges ] = React.useState( [] );
  const [ sensitiveAsset, setSensitiveAsset ] = React.useState( null );
  const [ topVulnerabilities, setTopVulnerabilities ] = React.useState( 0 );
  const [ loading, setLoading ] = React.useState( true );
  const [ relatedPaths, setRelatedPaths ] = React.useState( null );
  const [ pathEdge, setPathEdge ] = React.useState( null );
  const [ selectedNode, setSelectedNode ] = React.useState( null );

  const [ hoveredListItemID, setHoveredListItemID ] = React.useState( null );
  const [ hoveredListItemRating, setHoveredListItemRating ] = React.useState( null );

  const getEscalations = async ( edge ) => {
    // currently leaving escalations out of the cache becuase it would fill up so quickly,
    // so this function use is fine. -DMC 2022-02-16
    const _escalations = await search_model_records( 'escalation', {
      // eslint-disable-next-line camelcase
      field_map: { edge_id: edge.id},
      rownums: [],
      // eslint-disable-next-line camelcase
      extra_columns: [ 'vulnerability_id', 'escalation_analysis.risk' ],
    } );

    return _escalations;
  };

  const setupAllPathData = async ( path ) => {
    if ( isNotEmpty( path ) ) {
      const params = {
        model: 'base',
        // eslint-disable-next-line camelcase
        path_ids: [ path.id ],
        rownums: [ 0, 1 ],
      };

      // fetches the paths for this item
      const relatedPathsResponse = await makeRequest( 'FETCH', '/analysis/related_paths', params );

      if ( isNotEmpty( relatedPathsResponse ) && isNotEmpty( relatedPathsResponse.results ) ) {

        // STEP 1) get all the scopes
        const scopeIDs = [];

        Object.values( relatedPathsResponse.results.nodes ).map( node => {
          if ( !scopeIDs.includes( node.scope_id ) ) {
            scopeIDs.push( node.scope_id );
          }
        } );

        if ( isNotEmpty( scopeIDs ) ) {
          // returns the needed scope labels and parent ids
          let scopesResponse = await getRecords( 'scope', {
            // eslint-disable-next-line camelcase
            extra_columns: [ 'label', 'risk' ],
            // eslint-disable-next-line camelcase
            id_list: scopeIDs,
          } );

          scopesResponse = scopesResponse.sort( ( a, b ) => b.risk - a.risk );

          const scopes = {};
          const edgeToVulnIDsMap = {};
          const vulnToEdgeIDsMap = {};

          scopesResponse.map( s => {
            scopes[s.id] = s;
          } );

          // STEP 2: Fetch all the edge escalations
          const escalationPromises = [];

          Object.values( relatedPathsResponse.results.edges ).map( ( edge ) => {
            escalationPromises.push( getEscalations( edge ) );
          } );

          const resolvedResponses = await Promise.all( escalationPromises );


          Object.values( relatedPathsResponse.results.edges ).map( ( edge, index ) => {
            const allVulnIDs = [];
            resolvedResponses[index].map( _e => {
              allVulnIDs.push( _e.vulnerability_id );
              if ( isNotEmpty( vulnToEdgeIDsMap[ _e.vulnerability_id ] ) ) {
                vulnToEdgeIDsMap[ _e.vulnerability_id ].push( edge.id );
              } else {
                vulnToEdgeIDsMap[ _e.vulnerability_id ] = [ edge.id ];
              }
            } );
            edgeToVulnIDsMap[edge.id] = allVulnIDs;
          } );

          let allVulnIDs = [];

          if ( isNotEmpty( edgeToVulnIDsMap ) ) {
            Object.values( edgeToVulnIDsMap ).map( vulnIDs => {
              allVulnIDs = [ ...allVulnIDs, ...vulnIDs ];
            } );
          }
          if ( isNotEmpty( allVulnIDs ) ) {
            const vulnParams = {
              // eslint-disable-next-line camelcase
              id_list: allVulnIDs,
              // eslint-disable-next-line camelcase
              extra_columns: [
                'vulnerability_analysis.hosts',
                'vulnerability_analysis.patches',
                'vulnerability_analysis.risk',
                'identifier',
                'modified',
                'identifier',
                'description',
                'urls',
                'cvss_base_score',
                'public_notes',
                'cvssv2',
                'cvssv3',
                'vulnerability_analysis.model_id',
                'vulnerability_analysis.vulnerability_id',
                'vulnerability_analysis.patches',
                'vulnerability_analysis.hosts',
                'vulnerability_analysis.scan_results',
                'vulnerability_analysis.risk',
                'vulnerability_analysis.risk_percentile',
                'vulnerability_analysis.exploit_status',
                'vulnerability_analysis.nofix',
              ],
              rownums: [ 0, allVulnIDs.length + 1 ],
              // eslint-disable-next-line camelcase
              order_by: [ [ 'vulnerability_analysis.risk', 'DESC' ] ],
            };
            // eslint-disable-next-line camelcase
            const vulnerabilityRecords = await getRecords( 'vulnerability', vulnParams );

            if ( isNotEmpty( vulnerabilityRecords ) ) {
              vulnerabilityRecords.map( v => {
                const edgeIDs = vulnToEdgeIDsMap[v.id];

                if ( isNotEmpty( edgeIDs ) ) {
                  edgeIDs.map( edgeID => {
                    const edge = relatedPathsResponse.results.edges[edgeID];
                    if ( isNotEmpty( edge.vulnerabilities ) ) {
                      edge.vulnerabilities.push( v );
                    } else {
                      edge.vulnerabilities = [ v ];
                    }
                  } );
                }
              } );
            }
          }

          // STEP 3: format the edges for display
          const [ path ] = relatedPathsResponse.results.paths;

          const _formattedEdges = [];
          const _topVulnerabilities = [];
          let _allVulnerabilities = [];

          path.edges.map( ( eID, index ) => {

            const edge = relatedPathsResponse.results.edges[eID];

            if ( isNotEmpty( edge ) ) {

              let vulnerabilities = [];

              if ( isNotEmpty( edge.vulnerabilities ) ) {
                vulnerabilities = Object.values( edge.vulnerabilities ).sort( ( a, b ) => b.risk - a.risk );
              }

              _allVulnerabilities = [ ..._allVulnerabilities, ...vulnerabilities ];

              if ( !edge.nofix ) {
                _topVulnerabilities.push( vulnerabilities[0] );
              }

              const _formattedEdge = {
                id: eID,
                risk: edge.risk,
                riskPercentile: edge.risk_percentile,
                nofix: edge.nofix,
                fromNode: relatedPathsResponse.results.nodes[edge.from_node],
                toNode: relatedPathsResponse.results.nodes[edge.to_node],
                fromScope: scopes[edge.from_scope],
                toScope: scopes[edge.to_scope],
                vulnerabilities,
              };

              if ( index === path.edges.length - 1 ) {
                setSensitiveAsset( {
                  node: relatedPathsResponse.results.nodes[edge.to_node],
                  scope: scopes[edge.to_scope],
                } );
              }
              _formattedEdges.push( _formattedEdge );
            }
          } );
          setRelatedPaths( relatedPathsResponse );
          setLoading( false );
          setTopVulnerabilities( _topVulnerabilities );
          setFormattedEdges( _formattedEdges );
        }
      }
    }
  };

  // when the path comes in, need to get all the other necessary pieces of
  // information to display the visual and analysis
  React.useEffect( () => {
    if ( isNotEmpty( path ) ) {
      setupAllPathData( path );
    }
  }, [ path ] );

  const handleMouseEnter = edge => {
    const rating = edge.nofix ? 'default' : riskToRating( edge.risk );

    setHoveredListItemID( edge.id );
    setHoveredListItemRating( rating );
  };

  const handleMouseLeave = () => {
    setHoveredListItemID( null );
    setHoveredListItemRating( null );
  };

  const getEdgeRating = edge => {
    if ( edge.nofix ) {
      return 'nofix';
    }
    if ( edge.riskPercentile < 0.05 ) {
      return 'primary';
    }
    if ( edge.riskPercentile < 0.25 ) {
      return 'minimal';
    }
    if ( edge.riskPercentile < 0.75 ) {
      return 'low';
    }
    if ( edge.riskPercentile < 0.95 ) {
      return 'moderate';
    }
    return 'high';
  };

  const edgeOnClickCallback = ( edge, e ) => {
    setPathEdge( { ...edge, clickEvent: e } );
    setSelectedNode( null );
  };

  const nodeOnClickCallback = ( node, e ) => {
    setSelectedNode( { ...node, clickEvent: e } );
    setPathEdge( null );
  };

  const handleRecordCardClose = () => {
    setSelectedNode( null );
    setPathEdge( null );
  };

  return (
    <React.Fragment>
      { loading && <Loading /> }
      {
        isNotEmpty( selectedNode ) &&
        <RecordCard
          record={ selectedNode }
          type="node"
          context="risk_insight"
          show={ isNotEmpty( selectedNode ) }
          setShow={ handleRecordCardClose }
          options={ {
            dismissable: true,
            // includeShade: true,
          } }
        />
      }
      {
        isNotEmpty( pathEdge ) &&
        <RecordCard
          record={ pathEdge }
          type="edge"
          context="risk_insight"
          show={ isNotEmpty( pathEdge ) }
          setShow={ handleRecordCardClose }
          options={ {
            dismissable: true,
            // includeShade: true,
            relatedPaths,
          } }
        />
      }
      {
        isNotEmpty( relatedPaths ) &&
        <React.Fragment>
          <PageCreateButton placement="left" elementClass={ riskToRating( path.risk ) } >
            <button className="riskInsightBackButton" onClick={ () => selectItem( null ) }>
              <InlineSVG type="carretLeft" />
              <span>All Paths</span>
            </button>
          </PageCreateButton>
          <PageHeader>
            <div className="pathDetailHeader">
              <div className="headingWrapper">
                <div className="upper">
                  <InlineSVG type="pathsAlt" />
                  <h2>
                    {/* eslint-disable-next-line max-len */}
                    <strong>Path Analysis: </strong>
                    { reportTypeDisplayName( path, 'path', true ) }
                  </h2>
                </div>
                <SensitiveAssetItem asset={sensitiveAsset} />
              </div>
              <div className="ratingWrapper">
                <RatingBadge rating={riskToRating( path.risk ) } />
                <RiskReduction item={path} riskType="risk" />
              </div>
            </div>
            <div className="pathDetailVisual">
              <PathsGraph
                relatedPaths={relatedPaths}
                item={path}
                reportType="path"
                setSelectedNode={setSelectedNode}
                setPathEdge={setPathEdge}
                edgeOnClickCallback={edgeOnClickCallback}
                nodeOnClickCallback={nodeOnClickCallback}
                hoveredListItemID={ hoveredListItemID }
                hoveredListItemRating={ hoveredListItemRating }
                handleRecordCardClose={ handleRecordCardClose }
              />
              {
                isNotEmpty( relatedPaths?.results ) &&
                <button
                  className="fullScreenVisualToggleButton"
                  onClick={ () => showFullScreenVisual(
                    <PathsGraph
                      relatedPaths={relatedPaths}
                      item={path}
                      reportType="path"
                      setSelectedNode={setSelectedNode}
                      setPathEdge={setPathEdge}
                      edgeOnClickCallback={edgeOnClickCallback}
                      nodeOnClickCallback={nodeOnClickCallback}
                      hoveredListItemID={ hoveredListItemID }
                      hoveredListItemRating={ hoveredListItemRating }
                      handleRecordCardClose={ handleRecordCardClose }
                    />,
                    'riskInsightDetails section pathsGraph',
                  ) }
                >
                  { showVisual ? <InlineSVG type="exitFullscreen" /> : <InlineSVG type="fullscreen" /> }
                </button>
              }
            </div>
          </PageHeader>
        </React.Fragment>
      }
      {
        isNotEmpty( formattedEdges ) &&
        <div className="pathAnalysisContainer">
          {
            isNotEmpty( sensitiveAsset ) &&
            <p className="description">
              {/* eslint-disable-next-line max-len */}
              An attacker can reach the sensitive asset <strong>{ sensitiveAsset.node?.label }</strong> on { sensitiveAsset.scope?.label } in <strong>{ formatNumber( formattedEdges.length ) } steps</strong> by exploiting <strong>{ formatNumber( topVulnerabilities.length ) } { topVulnerabilities.length === 1 ? 'vulnerability' : 'vulnerabilities' }</strong>.
            </p>
          }
          <ul className="edgeDescriptionList">
            {
              formattedEdges.map( ( edge, index ) => {
                return <li
                  key={ index }
                  // eslint-disable-next-line max-len
                  className={ `${index === formattedEdges.length - 1 ? 'lastItem' : '' } edgeDescriptionItem risk-${ getEdgeRating( edge ) }`}
                  onMouseEnter={ () => handleMouseEnter( edge ) }
                  onMouseLeave={ handleMouseLeave }
                >
                  <div className="timelineColumn">
                    {
                      // this is the sensitiveAssetNode
                      index === formattedEdges.length - 1 &&
                      <React.Fragment>
                        <svg
                          width={ 16 }
                          height={ 16 }
                          viewBox="0 0 32 32"
                          fill="none"
                          preserveAspectRatio="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="nodeTypeIcon"
                        >
                          { getNodeIcon( sensitiveAsset.node ) }
                        </svg>
                      </React.Fragment>
                    }
                    {
                      // this is one in the middle
                      ( index !== formattedEdges.length - 1 ) &&
                      <InlineSVG type="poi" elementClass="nofixIcon"/>
                    }
                  </div>
                  <div className="contentColumn">
                    {
                      // this is the first node, need to add the attacker icon
                      index === 0 &&
                      <svg
                        width={ 16 }
                        height={ 16 }
                        viewBox="0 0 32 32"
                        fill="none"
                        preserveAspectRatio="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="attackerIcon"
                      >
                        { nodeIconMap.attacker }
                      </svg>
                    }
                    {
                      // add an alert for when a vuln is present
                      !edge.nofix &&
                      <InlineSVG type="notificationAlert" elementClass="alertIcon" />
                    }
                    <h2>
                      <strong>{ edge.fromNode?.label }</strong>
                      <span>- {edge.fromScope?.label}</span>
                    </h2>
                    {
                      edge.vulnerabilities.length > 1
                        ? <p>
                          {/* eslint-disable-next-line max-len */}
                          An attacker is able to gain access to <strong>{ edge.toNode?.label }</strong> by exploiting any one of <strong>{ formatNumber( edge.vulnerabilities.length ) } vulnerabilities</strong>, the highest risk vulnerability being <a
                            // eslint-disable-next-line max-len
                            href={ `#.=risk_insight&report=vulnerabilities&item_count=100&patchable=any&sort_by=risk&sort_direction=DESC&accepted_risk=false&item=${edge.vulnerabilities[0].id}&current_page=1` }
                            target="_blank"
                            rel="noreferrer noopener"
                            // eslint-disable-next-line max-len
                            className={ `inlineLink newTabLink risk-${riskToRating( edge.vulnerabilities[0].risk ) }`}
                          >
                            { reportTypeDisplayName( edge.vulnerabilities[0], 'vulnerability' ) }
                            <InlineSVG type="newTabLink" />
                          </a>
                        </p>
                        : <p>
                          {
                            edge.nofix
                              ? <span>
                                {
                                  isNotEmpty( edge.vulnerabilities ) &&
                                  <span>
                                    {/* eslint-disable-next-line max-len */}
                                    An attacker is able to gain access to <strong>{ edge.toNode?.label }</strong> because <strong className="vulnDescription">{ edge.vulnerabilities[0].description }</strong>
                                  </span>
                                }
                              </span>
                              : <span>
                                {
                                  isNotEmpty( edge.vulnerabilities ) &&
                                  <React.Fragment>
                                    {/* eslint-disable-next-line max-len */}
                                    An attacker is able to gain access to <strong>{ edge.toNode?.label }</strong> by exploiting <a
                                      // eslint-disable-next-line max-len
                                      href={ `#.=risk_insight&report=vulnerabilities&item_count=100&patchable=any&sort_by=risk&sort_direction=DESC&accepted_risk=false&item=${edge.vulnerabilities[0].id}&current_page=1` }
                                      target="_blank"
                                      rel="noreferrer noopener"
                                      // eslint-disable-next-line max-len
                                      className={ `inlineLink newTabLink risk-${riskToRating( edge.vulnerabilities[0].risk ) }`}
                                    >
                                      { reportTypeDisplayName( edge.vulnerabilities[0], 'vulnerability' ) }
                                      <InlineSVG type="newTabLink" />
                                    </a>
                                  </React.Fragment>
                                }

                              </span>
                          }
                        </p>
                    }
                  </div>
                </li>;
              } )
            }
            {/* add in the final description for the sensitive asset */}
            <li className="edgeDescriptionItem sensitiveAssetNode">
              <div className="timelineColumn"></div>
              <div className="contentColumn">
                <h2>
                  <strong>{ sensitiveAsset.node?.label }</strong>
                  <span>- { sensitiveAsset.scope?.label }</span>
                </h2>
              </div>
            </li>
          </ul>
        </div>
      }
    </React.Fragment>
  );
};

export default PathAnalysis;