/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { cvssScoreToRating, isNotEmpty, reportTypeDisplayName, riskToRating } from '../../../../shared/Utilities';
import InlineSVG from '../../../../shared/InlineSVG';
import RatingBadge from '../../../../shared/RatingBadge';
import RiskReduction from '../../../../shared/RiskReduction';

import './VulnerabilityHeader.scss';

const VulnerabilityHeader = ( { vulnerability, context, selectItem, options } ) => {
  return (
    <React.Fragment>
      {
        isNotEmpty( vulnerability ) &&
        <React.Fragment>
          <div className="recordCardHeaderRow">
            <h2>
              <InlineSVG type="vulnerabilitiesAlt" elementClass="recordTypeHeaderIcon" />
              <span className="label">{ reportTypeDisplayName( vulnerability, 'vulnerability' ) }</span>
            </h2>
            {
              ( isNotEmpty( options ) && 'includeRisk' in options )
                ? <React.Fragment>
                  {
                    options.includeRisk === true &&
                    <RatingBadge
                      rating={ riskToRating( vulnerability.risk ? vulnerability.risk : vulnerability.filtered_risk ) }
                    />
                  }
                </React.Fragment>
                : <RatingBadge
                  rating={ riskToRating( vulnerability.risk ? vulnerability.risk : vulnerability.filtered_risk ) }
                />
            }
            {
              ( isNotEmpty( options ) && 'includeCVSS' in options ) &&
              <React.Fragment>
                {
                  options.includeCVSS === true &&
                  <RatingBadge
                    rating={ cvssScoreToRating( vulnerability.cvss_base_score ) }
                    // eslint-disable-next-line max-len
                    alternateText={ `CVSS Score: ${vulnerability.cvss_base_score}`}
                    elementClass="cvssRatingBadge"
                  />
                }
              </React.Fragment>
            }
          </div>
          <div className="recordCardHeaderRow">
            <div className="left">
              {
                context === 'explore' &&
                <button
                  onClick={ () => selectItem( 'vulnerability', vulnerability ) }
                >
                  Add to graph
                </button>
              }
              <a
                // eslint-disable-next-line max-len
                href={ `#.=risk_insight&report=vulnerabilities&item_count=100&patchable=any&sort_by=risk&sort_direction=DESC&accepted_risk=any&item=${vulnerability.id}&current_page=1` }
                target="_blank"
                rel="noreferrer noopener"
              >
                Learn more
                <InlineSVG type="newTabLink" />
              </a>
            </div>
            {
              ( isNotEmpty( options ) && 'includeRisk' in options )
                ? <React.Fragment>
                  {
                    options.includeRisk === true &&
                    <RiskReduction item={vulnerability} riskType={ vulnerability.risk ? 'risk' : 'filtered_risk' } />
                  }
                </React.Fragment>
                : <RiskReduction item={vulnerability} riskType={ vulnerability.risk ? 'risk' : 'filtered_risk' } />
            }
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default VulnerabilityHeader;