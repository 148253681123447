/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatUnixDate, globalColors, isNotEmpty } from '../../Utilities';
import { isCurrent } from '../shared';

import './Xaxis.scss';

const Xaxis = ( {
  data,
  dataKey,
  currentPoint,
  containerHeight,
  containerWidth,
} ) => {

  const svgHeight = containerHeight * 0.1;
  const svgWidth = containerWidth - 10;
  const xGutter = ( containerWidth - svgWidth ) / 2;
  const xOffset = 0;
  const yOffset = containerHeight * 0.9;

  const [ tics, setTics ] = React.useState( [] );
  const [ subAxisPoints, setSubAxisPoints ] = React.useState( [] );

  React.useEffect( ( ) => {
    const _tics = [];
    if ( isNotEmpty( data ) && isNotEmpty( data.transformed ) && isNotEmpty( dataKey ) ) {
      const _widthRatio = svgWidth / ( Object.values( data ).length - 1 );

      const transformedData = Object.values( data.transformed );
      const delta = transformedData[transformedData.length - 1][data.xAxis] - transformedData[0][data.xAxis];

      const _subAxisPoints = [
        ticTransForm( transformedData[0][dataKey], dataKey ),
        ticTransForm( ( transformedData[0][dataKey] + ( delta * 0.2 ) ), dataKey ),
        ticTransForm( ( transformedData[0][dataKey] + ( delta * 0.4 ) ), dataKey ),
        ticTransForm( ( transformedData[0][dataKey] + ( delta * 0.6 ) ), dataKey ),
        ticTransForm( ( transformedData[0][dataKey] + ( delta * 0.8 ) ), dataKey ),
        ticTransForm( transformedData[transformedData.length - 1][dataKey], dataKey ),
      ];

      setSubAxisPoints( _subAxisPoints );

      transformedData.map( ( point, index ) => {

        let x;

        // points are not necessarily spaced out evenly, need to find the distance from the left based on
        // the xAxis series, if no xAxis is passed in... need to assume even spacing
        if ( isNotEmpty( data.xAxis ) ) {
          const firstPointValue = transformedData[0][data.xAxis];
          // eslint-disable-next-line max-len
          const lastPointValue = transformedData[ transformedData.length - 1][data.xAxis];
          const delta = lastPointValue - firstPointValue;
          const newWidthRatio = svgWidth / delta;
          x = ( newWidthRatio * ( point[data.xAxis] - firstPointValue ) ) + xGutter;

        // evenly spaced out
        } else {
          x = ( _widthRatio * index ) + xGutter;
        }

        _tics.push( { id: point.id, value: ticTransForm( point[dataKey], dataKey ), original: point, x } );

      } );

      setTics( _tics );
    }
  }, [ data, containerHeight, containerWidth ] );

  const allValuesLoaded = () => isNotEmpty( svgHeight )
    && isNotEmpty( svgWidth )
    && isNotEmpty( xGutter )
    && isNotEmpty( xOffset )
    && isNotEmpty( yOffset )
    && isNotEmpty( containerHeight )
    && isNotEmpty( containerWidth );

  const ticTransForm = ( val, dataKey ) => {
    const dateKeys = [
      'timestamp',
    ];

    if ( dateKeys.includes( dataKey ) && isNotEmpty( val ) ) {
      return formatUnixDate( val );
    }
    return val;
  };

  const getTextAnchor = ( index, length ) => {
    if ( index > 0 && index < length - 1 ) {
      return 'middle';
    }
    if ( index === 0 ) {
      return 'start';
    }
    return 'end';
  };

  const getTicOffset = ( index, length, gutter ) => {
    if ( index > 0 && index < length - 1 ) {
      return 0;
    }
    if ( index === 0 ) {
      return gutter;
    }
    return -gutter;
  };

  const svgContent = () => {
    return <g className="chartAxisGroupxAxis">
      <line
        x1={ xOffset + xGutter }
        x2={ containerWidth - xGutter }
        y1={ yOffset - 5 }
        y2={ yOffset - 5 }
        stroke={ globalColors['grey--divider'] }
        strokeWidth={ 2 }
      />
      {
        isNotEmpty( subAxisPoints ) &&
        subAxisPoints.map( ( value, index ) => {
          return <text
            key={index}
            x={
              ( ( index * ( svgWidth / 5 ) ) + xGutter )
              - getTicOffset( index, subAxisPoints.length, xGutter )
            }
            y={ yOffset + 24 }
            fontSize={14}
            fontWeight="bold"
            // alignmentBaseline="hanging"
            textAnchor={ getTextAnchor( index, subAxisPoints.length ) }
            fill={ globalColors['darkBlue'] }
          >
            { value }
          </text>;
        } )
      }
      {
        tics.map( ( tic, index ) => {
          return <g
            key={index}
            className={ `${isCurrent( tic.original, currentPoint ) ? 'current' : ''} axisTic` }
          >
            <line
              x1={ tic.x }
              x2={ tic.x }
              y1={ 0 }
              y2={ yOffset }
              stroke={ globalColors['grey--divider'] }
              strokeOpacity={ 0.5 }
              strokeWidth={ 1.5 }
              strokeDasharray="8 5"
            />
            <line
              x1={ tic.x }
              x2={ tic.x }
              y1={ yOffset + - 10 }
              y2={ yOffset }
              stroke={ globalColors['grey--icon'] }
              strokeWidth={ 1.5 }
            />
          </g>;
        } )
      }
    </g>;
  };
  return (
    <React.Fragment>
      {
        ( isNotEmpty( data )
          && isNotEmpty( dataKey )
          && allValuesLoaded()
        ) &&
        <React.Fragment>
          { svgContent() }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default Xaxis;