/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import { getFieldValues } from '../../../../shared/Form/Shared';
import {
  generateAPICredentials,
  getActiveIntegrations,
  integrationInstanceName,
  isEmpty,
  isFormNull,
  isNotEmpty,
} from '../../../../shared/Utilities';

import {
  isValidUsername,
  isValidPassword,
  isEmailAddress,
} from '../../../../shared/Form/Validators';
import { makeRequest } from '../../../../../legacy/io';
import InlineSVG from '../../../../shared/InlineSVG';
import Notification from '../../../../shared/Notification';
import { hasAccess } from '../../../App/AccessControl';

const EMPTY_FIELDS = {
  selector: {
    fields: [
      {
        type: 'select',
        label: 'Authentication Provider',
        attribute: 'provider',
        defaultValue: '00000000-0000-0000-0000-000000000000',
        options: {},
        disableOnEdit: true,
      },
    ],
  },

  left: {
    fields: [
      {
        type: 'text',
        label: 'Username',
        attribute: 'username',
        required: true,
        validators: [ isValidUsername ],
        placeholder: 'jsmith',
        defaultValue: '',
      },
      {
        warnIf: [
          { attribute: 'remediation_export_setting', check: isFormNull },
          { attribute: 'email_address', check: isFormNull },
        ],
        // eslint-disable-next-line max-len
        warningMessage: 'Remediation tickets will not be able to be sent to this user without entering a valid email address',
        type: 'text',
        label: 'Email Address',
        attribute: 'email_address',
        validators: [ isEmailAddress ],
        placeholder: 'jsmith@example.com',
        defaultValue: '',
      },
      {
        includeIf: { attribute: 'provider', value: '00000000-0000-0000-0000-000000000000' },
        type: 'password',
        label: 'Password',
        attribute: 'password',
        required: true,
        validators: [ isValidPassword ],
        placeholder: 'Not shown, only edit when changing password',
        defaultValue: '',
      },
      {
        type: 'select',
        label: 'Preferred Ticketing Integration',
        attribute: 'remediation_export_setting',
        defaultValue: '',
        asDropdown: true,
        options: [],
      },
      {
        type: 'searchResults',
        label: 'Mapped User',
        attribute: 'mapped_user_id',
        defaultValue: '',
        recordType: 'external_user',
      },
      {
        attribute: 'remediation_export_email_message',
        type: 'contentBlock',
        defaultValue: <Notification
          options={{
            header: 'Destination Email',
            type: 'info',
            // eslint-disable-next-line max-len
            message: <p>Tickets assigned to you will be emailed to designated email for selected integration</p>,
          }}
        />,
      },
    ],
  },
  right: {
    fields: [
      {
        type: 'text',
        label: 'Given Name',
        attribute: 'given_name',
        placeholder: 'Jane',
        defaultValue: '',
      },
      {
        type: 'text',
        label: 'Family Name',
        attribute: 'family_name',
        placeholder: 'Smith',
        defaultValue: '',
      },
      {
        type: 'apiCredentials',
        label: 'API Credentials',
        attribute: 'api_credentials',
        generateOptions: {
          generate: () => generateAPICredentials(),
          buttonText: 'Generate API Credentials',
        },
        defaultValue: { key: '',  secret: '' },
      },
    ],
  },
};

export const recordData = {
  display: {},
};

export const refresh = async ( ) => {
  const providers = {};
  let users = [];
  const fields = { ...EMPTY_FIELDS };

  const licenseInfo = await makeRequest( 'FETCH', '/license_info' );

  const providersFetch = await makeRequest( 'SEARCH', '/project/default/authentication_provider', {
    // eslint-disable-next-line camelcase
    extra_columns:[ 'type', 'label', 'settings' ],
  } );

  const usersFetch = await makeRequest( 'SEARCH', '/user', {
    // eslint-disable-next-line camelcase
    extra_columns: [
      'authentication_provider_id',
      'username',
      'given_name',
      'family_name',
      'api_key',
      'setup_wizard_disabled',
      'email_address',
      'remediation_export_setting',
    ],
    // eslint-disable-next-line camelcase
    order_by: [ [ 'username', 'ASC' ] ],
  } );

  if ( isNotEmpty( usersFetch ) && isNotEmpty( usersFetch.results ) ) {
    users = usersFetch.results;
  }
  const activeIntegrations = await getActiveIntegrations();

  // initialize with one optGroup "no integration"
  const integrationOptions = [
    {
      label: 'No Integration',
      type: 'none',
      options: { '': <span>Email me directly</span> },
    },
  ];

  if ( isNotEmpty( activeIntegrations ) ) {

    if ( isNotEmpty( activeIntegrations.email ) ) {
      const emailGroup = { label: 'Email', type: 'email', options: {} };
      activeIntegrations.email.map( i => {
        emailGroup.options[i.id] = <span>
          <InlineSVG type="mail" version="primary" />
          { integrationInstanceName( i, true, false ) }
        </span>;
      } );
      integrationOptions.push( emailGroup );
    }

    if ( isNotEmpty( activeIntegrations.jira ) ) {
      const jiraGroup = { label: 'Atlassian Jira', type: 'jira', options: {} };
      activeIntegrations.jira.map( i => {
        jiraGroup.options[i.id] = <span>
          <InlineSVG type="jiraLogo" version="special" />
          { integrationInstanceName( i, true, false ) }
        </span>;
      } );
      integrationOptions.push( jiraGroup );
    }
  }

  const integrationHasUsers = value => {
    if ( isNotEmpty( value ) && isNotEmpty( activeIntegrations ) && isNotEmpty( activeIntegrations.jira ) ) {
      const thisIntegration = activeIntegrations?.jira?.find( i => i.id === value );
      return isNotEmpty( thisIntegration );
    }
    return false;
  };

  const integrationIsEmail = value => {
    if ( isNotEmpty( value ) && isNotEmpty( activeIntegrations ) && isNotEmpty( activeIntegrations.email ) ) {
      const thisIntegration = activeIntegrations?.email?.find( i => i.id === value );
      return isNotEmpty( thisIntegration );
    }
    return false;
  };

  const initializeExistingMessage = ( field, value, fields, values ) => {
    let integration;

    if (
      isNotEmpty( values )
      && isNotEmpty( values.remediation_export_setting )
      && isNotEmpty( activeIntegrations )
      && isNotEmpty( activeIntegrations.email )
    ) {
      integration = activeIntegrations.email.find( i => i.id === values.remediation_export_setting );
      if ( isNotEmpty( integration ) ) {
        field.value = <Notification
          options={{
            header: 'Destination Email',
            type: 'info',
            // eslint-disable-next-line max-len
            message: <p>Tickets assigned to you will be emailed to <strong>{integration?.destination_email_address}</strong></p>,
          }}
        />;
      }
    }
  };

  const remediationChangeCallback = ( field, value, fields ) => {
    setAdditionalSearchParams( field, value, fields );

    const emailMessageField = fields.find( f => f.attribute === 'remediation_export_email_message' );

    let integration;

    if ( isNotEmpty( activeIntegrations.email ) ) {
      integration = activeIntegrations.email.find( i => i.id === value );

      if ( isNotEmpty( integration ) ) {
        // eslint-disable-next-line max-len
        emailMessageField.value = <Notification
          options={{
            header: 'Destination Email',
            type: 'info',
            // eslint-disable-next-line max-len
            message: <p>Tickets assigned to you will be emailed to <strong>{integration?.destination_email_address}</strong></p>,
          }}
        />;
      } else {
        emailMessageField.value = <p></p>;
      }
    }
  };

  const setAdditionalSearchParams = ( field, value, fields ) => {
    if ( isNotEmpty( field ) && isNotEmpty( fields ) ) {
      const userMappingField = fields.find( f => f.attribute === 'mapped_user_id' );
      if ( isNotEmpty( value ) ) {
        // eslint-disable-next-line camelcase
        userMappingField.additionalSearchParams = { third_party_setting_id: value };
      } else {
        userMappingField.additionalSearchParams= null;
      }
    }
  };

  const hasRemediationAccess = () => {
    return hasAccess( licenseInfo, null, 'feature', 'remediationWorkflow' );
  };

  // need to setup a ton of callbacks, inclusion logic, and options
  if ( providersFetch.results && providersFetch.results.length ) {
    providersFetch.results.map( provider => {
      providers[provider.id] = provider.label;
    } );
    // eslint-disable-next-line
    const providerField = fields.selector.fields[0];
    const userMappingField = fields.left.fields.find( f => f.attribute === 'mapped_user_id' );
    const remediationField = fields.left.fields.find( f => f.attribute === 'remediation_export_setting' );
    const emailMessageField = fields.left.fields.find( f => f.attribute === 'remediation_export_email_message' );

    userMappingField.includeIf = [
      { attribute: 'remediation_export_setting', check: integrationHasUsers },
      { attribute: 'username', check: hasRemediationAccess },
    ];
    remediationField.includeIf = { attribute: 'username', check: hasRemediationAccess };
    remediationField.callback = remediationChangeCallback;
    remediationField.options = integrationOptions;
    providerField.options = providers;
    emailMessageField.includeIf = [
      { attribute: 'remediation_export_setting', check: integrationIsEmail },
      { attribute: 'username', check: hasRemediationAccess },
    ];
    emailMessageField.init = initializeExistingMessage;
  }
  return { providers, fields, activeIntegrations, users };
};

export const getUserParams = ( user, fieldStates ) => {

  /* eslint-disable camelcase */

  const providerID = fieldStates.provider.updatedValue;

  const includedValues = getFieldValues( fieldStates, 'user' );

  const sanitizedExportSetting = value => {
    if ( value === 'null' || isEmpty( value ) ) {
      return null;
    }
    return value;
  };

  const userParams = {
    given_name: includedValues.given_name,
    family_name: includedValues.family_name,
    username: includedValues.username,
    email_address: includedValues.email_address,
    authentication_provider_id: providerID,
    api_key: includedValues.api_key.key,
    api_secret: includedValues.api_key.secret,
    // eslint-disable-next-line max-len
    remediation_export_setting: sanitizedExportSetting( includedValues.remediation_export_setting ),
    setup_wizard_disabled: user ? user.setup_wizard_disabled : false,
  };

  // internal store needs a password, ldap and saml do not (and potentially other future ones)
  if ( providerID === '00000000-0000-0000-0000-000000000000' ) {
    userParams.password = includedValues.password || '';
  }

  // we are editing an existing record
  if ( isNotEmpty( user ) ) {
    // need to remove the blank password, if it has not been altered
    if ( isEmpty( includedValues.password ) || includedValues.password === '' ) {
      delete userParams.password;
    }

    // need to remove the blank password, if it has not been altered
    if ( includedValues.api_key && includedValues.api_key.secret === '' ) {
      delete userParams.api_secret;
    }
    // add the id of the existing cred, so that it updates instead of creating a new record
    userParams.id = user.id;
  }

  /* eslint-enable camelcase */
  return userParams;
};