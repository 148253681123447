/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { globalColors, isNotEmpty } from '../../../../../shared/Utilities';
import { makeRequest } from '../../../../../../legacy/io';
import EmptyLoading from '../../../../../shared/EmptyLoading';
import Donut from '../../../../../shared/Charts/Donut';
import Legend from '../../../../../shared/Charts/Legend';

import './ExploitStatusBreakdown.scss';

const ExploitStatusBreakdown = ( { prefetchedData, onClickCallback, settings } ) => {
  const [ chartData, setChartData ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( true );
  const [ total, setTotal ] = React.useState( null );

  const [ selectedSeriesIdentifier, setSelectedSeriesIdentifier ] = React.useState( null );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );

  const setupData = async () => {
    setLoading( true );
    let _talliesResults;
    if (
      isNotEmpty( prefetchedData )
      && isNotEmpty( prefetchedData.tally )
      && isNotEmpty( prefetchedData.tally.results )
    ) {
      _talliesResults = prefetchedData.tally.results;
    } else {
      const tallyRequest = await makeRequest( 'TALLY', '/analysis/instance', { project: 'default', model: 'base' } );
      if ( isNotEmpty( tallyRequest ) && isNotEmpty( tallyRequest.results ) ) {
        _talliesResults = tallyRequest.results;
      }
    }

    const { _total } = _talliesResults;

    setTotal( _total );

    const zeroed = _total === 0;

    const _chartData = {
      private: {
        label: 'Private',
        stroke: globalColors['grey--divider'],
        value: zeroed ? zeroed : ( ( _talliesResults.exploit_status.private / _total ) * 100 ),
        total: _talliesResults.exploit_status.private,
        key: 'private',
      },
      // eslint-disable-next-line camelcase
      published_details: {
        label: 'Details Published',
        stroke: globalColors.low,
        value: zeroed ? zeroed : ( ( _talliesResults.exploit_status.published_details / _total ) * 100 ),
        total: _talliesResults.exploit_status.published_details,
        key: 'published_details',
      },
      poc: {
        label: 'PoC Published',
        stroke: globalColors.moderate,
        value: zeroed ? zeroed : ( ( _talliesResults.exploit_status.poc / _total ) * 100 ),
        total: _talliesResults.exploit_status.poc,
        key: 'poc',
      },
      weaponized: {
        label: 'Weaponized',
        stroke: globalColors.high,
        value: zeroed ? zeroed : ( ( _talliesResults.exploit_status.weaponized / _total ) * 100 ),
        total: _talliesResults.exploit_status.weaponized,
        key: 'weaponized',
      },
    };
    setLoading( false );
    setChartData( _chartData );
  };

  React.useEffect( () => {
    setupData();
  }, [ prefetchedData, settings ] );

  return (
    <React.Fragment>
      <EmptyLoading
        payload={ chartData }
        loading={ loading }
        loadingMessage="Loading data"
        emptyMessage="No risk data"
      />
      {
        isNotEmpty( chartData ) &&
        <React.Fragment>
          <Donut
            strokeWidth="thick"
            data={chartData}
            total={total}
            // eslint-disable-next-line max-len
            onClickCallback={ onClickCallback }
            selectedSeriesIdentifier={ isNotEmpty( onClickCallback ) ? selectedSeriesIdentifier : null }
            setSelectedSeriesIdentifier={ isNotEmpty( onClickCallback ) ? setSelectedSeriesIdentifier : null }
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
          <Legend
            legendData={chartData}
            onClickCallback={ onClickCallback }
            selectedSeriesIdentifier={ isNotEmpty( onClickCallback ) ? selectedSeriesIdentifier : null }
            setSelectedSeriesIdentifier={ isNotEmpty( onClickCallback ) ? setSelectedSeriesIdentifier : null }
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ExploitStatusBreakdown;