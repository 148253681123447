/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { makeRequest } from '../../../../legacy/io';
import { HelpTrigger } from '../../../components/HelpDocumentation/ContextualHelp/index.js';

import FilterForm from '../../../shared/FilterForm';
import {
  isNotEmpty,
  decodeURLHash,
  // exploitStatusMap,
  vulnerabilityScannerInstanceName,
  shortenedVulnerabilityScannerNameMap,
} from '../../../shared/Utilities';

import './style.scss';
import { acceptedRiskFilterOptions } from '../../../shared/FilterForm/shared';
import { BreakpointContext } from '../../../Contexts/Breakpoint';
import InlineSVG from '../../../shared/InlineSVG';
import { TagsContext } from '../../../Contexts/Tags';

const InsightFilters = ( {
  onRefresh,
  collapsed,
  setCollapsed,
  visualCollapsed=false,
  setVisualCollapsed=() => {},
  reportType,
  tableLoading=false,
  visualLoading=false,
} ) => {

  const [ fields, setFields ] = React.useState( null );

  const [ tags ] = React.useContext( TagsContext );
  const [ breakpointClass ] = React.useContext( BreakpointContext );

  let isMounted = true;
  /* eslint-disable camelcase */
  const EMPTY_FILTERS = {
    host: {
      alwaysVisible: {
        fields: [
          {
            type: 'debouncedText',
            label: 'Filter Hosts',
            attribute: 'keywords',
            placeholder: 'Filter Hosts by keyword...',
            shouldTriggerRefresh: true,
            helpItem: <HelpTrigger helpKey="keywords" />,
          },
          {
            type: 'hidden',
            attribute: 'item',
          },
          {
            type: 'hidden',
            attribute: 'item_count',
            value: 100,
            shouldTriggerRefresh: true,
          },
        ],
      },
      advancedLeft: {
        fields: [
          {
            type: 'select',
            label: 'Tags',
            attribute: 'asset_tag_ids',
            multiple: true,
            value: [],
            allowBlank: true,
            helpItem: <HelpTrigger helpKey="tag" />,
          },
          {
            type: 'radioGroup',
            label: 'Sensitive Assets',
            attribute: 'sensitive_assets',
            value: 'any',
            options: {
              'any': 'Any',
              'true': 'Some',
              'false': 'None',
            },
            helpItem: <HelpTrigger helpKey="sensitive_assets" />,
          },
          {
            type: 'select',
            label: 'Include accepted risk items?',
            attribute: 'accepted_risk',
            value: 'false',
            options: acceptedRiskFilterOptions,
            helpItem: <HelpTrigger helpKey="include_accepted_risk_items" />,
          },
          {
            type: 'inequality',
            label: 'Risk Reduction',
            attribute: 'risk_reduction',
            value: [ 'gt_map', '' ],
            htmlProps: { min: '0', max: '100' },
            subLabel: '%',
            helpItem: <HelpTrigger helpKey="risk_reduction" />,
          },
          // {
          //   type: 'select',
          //   label: 'Exploit Status',
          //   attribute: 'exploit_statuses',
          //   defaultValue: 'private',
          //   options: {
          //     private: 'Private',
          //     // eslint-disable-next-line camelcase
          //     published_details: 'Details published',
          //     poc: 'PoC published',
          //     weaponized: 'Weaponized',
          //   },
          //   helpItem: <HelpTrigger helpKey="exploit_status" />,
          // },
          // {
          //   type: 'checkboxGroup',
          //   label: 'Included Vulnerability Scanner(s)',
          //   attribute: 'third_party_setting_ids',
          //   options: {},
          //   disabled: true,
          //   helpItem: <HelpTrigger helpKey="vulnerability_scanners" />,
          // },
        ],
      },
      advancedCenter: {
        header: 'Wildcards and Search',
        fields: [
          {
            type: 'debouncedText',
            label: 'Host wildcard',
            attribute: 'host_globs',
            placeholder: 'example: OR-*SQL*',
            helpItem: <HelpTrigger helpKey="host_wildcard" />,
          },
          // {
          //   type: 'debouncedText',
          //   label: 'Patch wildcard',
          //   attribute: 'patch_globs',
          //   placeholder: 'example: 447*',
          //   helpItem: <HelpTrigger helpKey="patch_wildcard" />,
          // },
          // {
          //   type: 'debouncedText',
          //   label: 'Vulnerability wildcard',
          //   attribute: 'vulnerability_globs',
          //   placeholder: 'example: CVE-2022-*',
          //   helpItem: <HelpTrigger helpKey="vulnerability_wildcard" />,
          // },
          {
            type: 'searchResults',
            label: 'Specific Host',
            attribute: 'host_ids',
            placeholder: 'Find host by name...',
            recordType: 'host',
            helpItem: <HelpTrigger helpKey="specific_host" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Patch',
            attribute: 'patch_ids',
            placeholder: 'Find patch by name...',
            recordType: 'patch',
            helpItem: <HelpTrigger helpKey="specific_patch" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Vulnerability',
            attribute: 'vulnerability_ids',
            placeholder: 'Find vulnerability by name...',
            recordType: 'vulnerability',
            helpItem: <HelpTrigger helpKey="specific_vulnerability" />,
          },
          // {
          //   type: 'searchResults',
          //   label: 'Specific Scanner Signature',
          //   attribute: 'signature_ids',
          //   placeholder: 'Find scanner signature by name...',
          //   recordType: 'scanner_signature',
          //   helpItem: <HelpTrigger helpKey="specific_scanner_signature" />,
          // },
          // This is not yet supported by the Backend, need to comment out until functional -DMC 2022/12/20
          // {
          //   type: 'debouncedText',
          //   label: 'Vulnerability Description Wildcard',
          //   attribute: 'vulnerability_description',
          //   placeholder: 'example: *CISCO*',
          //   helpItem: <HelpTrigger helpKey="vulnerability_description_wildcard" />,
          // },
        ],
      },
      advancedRight: {
        header: 'Operating System',
        fields: [
          {
            type: 'select',
            label: 'Host OS Name',
            attribute: 'host_os_label',
            options: {},
            allowBlank: true,
            blankDisplayText: 'Any',
            defaultValue: '',
            helpItem: <HelpTrigger helpKey="host_os_name" />,
          },
          // family not quite ready for primetime, commenting out for the time being -DMC 2023-1-4
          // {
          //   type: 'select',
          //   label: 'Host OS Family',
          //   attribute: 'host_os_family',
          //   defaultValue: '',
          //   blankDisplayText: 'Any',
          //   allowBlank: true,
          //   options: {
          //     unix: 'Unix',
          //     windows: 'Windows',
          //     macos: 'macOS',
          //     other: 'Other',
          //     unknown: 'Unknown',
          //   },
          //   helpItem: <HelpTrigger helpKey="host_os_family" />,
          // },
          {
            type: 'select',
            label: 'Host OS Vendor',
            attribute: 'host_vendor',
            options: {},
            allowBlank: true,
            blankDisplayText: 'Any',
            defaultValue: '',
            helpItem: <HelpTrigger helpKey="host_os_vendor" />,
          },
          {
            type: 'select',
            label: 'Host OS Type',
            attribute: 'host_os_type',
            defaultValue: '',
            blankDisplayText: 'Any',
            allowBlank: true,
            options: {},
            helpItem: <HelpTrigger helpKey="host_os_type" />,
          },
          {
            type: 'select',
            label: 'Host OS Architecture',
            attribute: 'host_architecture',
            defaultValue: '',
            blankDisplayText: 'Any',
            allowBlank: true,
            options: {},
            helpItem: <HelpTrigger helpKey="host_os_architecture" />,
          },
        ],
      },
    },
    patch: {
      alwaysVisible: {
        fields: [
          {
            type: 'debouncedText',
            label: 'Filter Patches',
            attribute: 'keywords',
            placeholder: 'Filter Patches by keyword...',
            shouldTriggerRefresh: true,
            helpItem: <HelpTrigger helpKey="keywords" />,
          },
          {
            type: 'select',
            asToggle: true,
            toggleClass: 'large',
            value: 'risk',
            defaultValue: 'risk',
            label: 'Risk Type',
            attribute: 'risk_type',
            shouldTriggerRefresh: true,
            options: {
              risk: 'Cumulative',
              // eslint-disable-next-line camelcase
              direct_risk: 'Direct',
            },
            helpItem: <HelpTrigger helpKey="risk_type" />,
          },
          {
            type: 'hidden',
            attribute: 'item',
          },
          {
            type: 'hidden',
            attribute: 'item_count',
            value: 100,
            shouldTriggerRefresh: true,
          },
        ],
      },
      advancedLeft: {
        fields: [
          {
            type: 'select',
            label: 'Tags',
            attribute: 'asset_tag_ids',
            multiple: true,
            value: [],
            allowBlank: true,
            helpItem: <HelpTrigger helpKey="tag" />,
          },
          {
            type: 'radioGroup',
            label: 'Superseded status',
            attribute: 'superseded',
            value: 'false',
            options: {
              'any': 'Any',
              'fully': 'Fully',
              'partial': 'Partially',
              'unsuperseded': 'Unsuperseded',
            },
            helpItem: <HelpTrigger helpKey="superseded" />,
          },
          {
            type: 'select',
            label: 'Include accepted risk items?',
            attribute: 'accepted_risk',
            value: 'false',
            options: acceptedRiskFilterOptions,
            helpItem: <HelpTrigger helpKey="include_accepted_risk_items" />,
          },
          {
            type: 'inequality',
            label: 'Risk Reduction',
            attribute: 'risk_reduction',
            value: [ 'gt_map', '' ],
            htmlProps: { min: '0', max: '100' },
            subLabel: '%',
            helpItem: <HelpTrigger helpKey="risk_reduction" />,
          },
          {
            type: 'inequality',
            label: 'Patch Age',
            attribute: 'age',
            valuesMap: { gt_map: 'Older than', lt_map: 'Newer than' },
            value: [ 'gt_map', '' ],
            htmlProps: { min: '0', max: '120' },
            subLabel: 'day(s)',
            helpItem: <HelpTrigger helpKey="age" />,
          },
          {
            type: 'inequality',
            label: 'Affected Hosts',
            attribute: 'num_hosts',
            value: [ 'gt_map', '' ],
            htmlProps: { min: '0' },
            subLabel: 'hosts',
            helpItem: <HelpTrigger helpKey="affected_hosts" />,
          },
          // {
          //   type: 'date',
          //   needsUnixTransform: true,
          //   label: 'Published After',
          //   attribute: 'published_after',
          //   htmlProps: { min: '1998-01-01' },
          //   helpItem: <HelpTrigger helpKey="published_date" />,
          // },
          // {
          //   type: 'date',
          //   needsUnixTransform: true,
          //   label: 'Published Before',
          //   attribute: 'published_before',
          //   htmlProps: { min: '1998-01-01' },
          //   helpItem: <HelpTrigger helpKey="published_date" />,
          // },
          // {
          //   type: 'select',
          //   label: 'Exploit Status',
          //   attribute: 'exploit_statuses',
          //   defaultValue: 'private',
          //   options: {
          //     private: 'Private',
          //     // eslint-disable-next-line camelcase
          //     published_details: 'Details published',
          //     poc: 'PoC published',
          //     weaponized: 'Weaponized',
          //   },
          //   helpItem: <HelpTrigger helpKey="exploit_status" />,
          // },
          // {
          //   type: 'checkboxGroup',
          //   label: 'Included Vulnerability Scanner(s)',
          //   attribute: 'third_party_setting_ids',
          //   options: {},
          //   disabled: true,
          //   helpItem: <HelpTrigger helpKey="vulnerability_scanners" />,
          // },
        ],
      },
      advancedCenter: {
        header: 'Wildcards and Search',
        fields: [
          // {
          //   type: 'debouncedText',
          //   label: 'Host wildcard',
          //   attribute: 'host_globs',
          //   placeholder: 'example: OR-*SQL*',
          //   helpItem: <HelpTrigger helpKey="host_wildcard" />,
          // },
          {
            type: 'debouncedText',
            label: 'Patch wildcard',
            attribute: 'patch_globs',
            placeholder: 'example: 447*',
            helpItem: <HelpTrigger helpKey="patch_wildcard" />,
          },
          // {
          //   type: 'debouncedText',
          //   label: 'Vulnerability wildcard',
          //   attribute: 'vulnerability_globs',
          //   placeholder: 'example: CVE-2022-*',
          //   helpItem: <HelpTrigger helpKey="vulnerability_wildcard" />,
          // },
          {
            type: 'searchResults',
            label: 'Specific Host',
            attribute: 'host_ids',
            placeholder: 'Find host by name...',
            recordType: 'host',
            helpItem: <HelpTrigger helpKey="specific_host" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Patch',
            attribute: 'patch_ids',
            placeholder: 'Find patch by name...',
            recordType: 'patch',
            helpItem: <HelpTrigger helpKey="specific_patch" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Vulnerability',
            attribute: 'vulnerability_ids',
            placeholder: 'Find vulnerability by name...',
            recordType: 'vulnerability',
            helpItem: <HelpTrigger helpKey="specific_vulnerability" />,
          },
          // {
          //   type: 'searchResults',
          //   label: 'Specific Scanner Signature',
          //   attribute: 'signature_ids',
          //   placeholder: 'Find scanner signature by name...',
          //   recordType: 'scanner_signature',
          //   helpItem: <HelpTrigger helpKey="specific_scanner_signature" />,
          // },
          // This is not yet supported by the Backend, need to comment out until functional -DMC 2022/12/20
          // {
          //   type: 'debouncedText',
          //   label: 'Vulnerability Description Wildcard',
          //   attribute: 'vulnerability_description',
          //   placeholder: 'example: *CISCO*',
          //   helpItem: <HelpTrigger helpKey="vulnerability_description_wildcard" />,
          // },
        ],
      },
      // advancedRight: {
      //   header: 'Operating System',
      //   fields: [
      //     {
      //       type: 'select',
      //       label: 'Host OS Name',
      //       attribute: 'host_os_label',
      //       options: {},
      //       allowBlank: true,
      //       blankDisplayText: 'Any',
      //       defaultValue: '',
      //       helpItem: <HelpTrigger helpKey="host_os_name" />,
      //     },
      //     // family not quite ready for primetime, commenting out for the time being -DMC 2023-1-4
      //     // {
      //     //   type: 'select',
      //     //   label: 'Host OS Family',
      //     //   attribute: 'host_os_family',
      //     //   defaultValue: '',
      //     //   blankDisplayText: 'Any',
      //     //   allowBlank: true,
      //     //   options: {
      //     //     unix: 'Unix',
      //     //     windows: 'Windows',
      //     //     macos: 'macOS',
      //     //     other: 'Other',
      //     //     unknown: 'Unknown',
      //     //   },
      //     //   helpItem: <HelpTrigger helpKey="host_os_family" />,
      //     // },
      //     {
      //       type: 'select',
      //       label: 'Host OS Vendor',
      //       attribute: 'host_vendor',
      //       options: {},
      //       allowBlank: true,
      //       blankDisplayText: 'Any',
      //       defaultValue: '',
      //       helpItem: <HelpTrigger helpKey="host_os_vendor" />,
      //     },
      //     {
      //       type: 'select',
      //       label: 'Host OS Type',
      //       attribute: 'host_os_type',
      //       defaultValue: '',
      //       blankDisplayText: 'Any',
      //       allowBlank: true,
      //       options: {},
      //       helpItem: <HelpTrigger helpKey="host_os_type" />,
      //     },
      //     {
      //       type: 'select',
      //       label: 'Host OS Architecture',
      //       attribute: 'host_architecture',
      //       defaultValue: '',
      //       blankDisplayText: 'Any',
      //       allowBlank: true,
      //       options: {},
      //       helpItem: <HelpTrigger helpKey="host_os_architecture" />,
      //     },
      //   ],
      // },
    },
    vulnerability: {
      alwaysVisible: {
        fields: [
          {
            type: 'debouncedText',
            label: 'Filter Vulnerabilities',
            attribute: 'keywords',
            placeholder: 'Filter Vulnerabilities by keyword...',
            shouldTriggerRefresh: true,
            helpItem: <HelpTrigger helpKey="keywords" />,
          },
          {
            type: 'select',
            label: 'Patches Available',
            attribute: 'patchable',
            shouldTriggerRefresh: true,
            value: 'any',
            options: {
              'any': 'Any',
              'true': 'Yes',
              'false': 'No',
            },
            helpItem: <HelpTrigger helpKey="patchable" />,
          },
          {
            type: 'hidden',
            attribute: 'item',
          },
          {
            type: 'hidden',
            attribute: 'item_count',
            value: 100,
            shouldTriggerRefresh: true,
          },
        ],
      },
      advancedLeft: {
        fields: [
          {
            type: 'select',
            label: 'Include accepted risk items?',
            attribute: 'accepted_risk',
            value: 'false',
            options: acceptedRiskFilterOptions,
            helpItem: <HelpTrigger helpKey="include_accepted_risk_items" />,
          },
          {
            type: 'checkboxGroup',
            label: 'Exploit Status',
            attribute: 'exploit_statuses',
            defaultValue: [ 'private' ],
            options: {
              private: 'Private',
              // eslint-disable-next-line camelcase
              published_details: 'Details published',
              poc: 'PoC published',
              weaponized: 'Weaponized',
            },
            helpItem: <HelpTrigger helpKey="exploit_status" />,
          },
          {
            type: 'select',
            label: 'Tags',
            attribute: 'asset_tag_ids',
            multiple: true,
            value: [],
            allowBlank: true,
            helpItem: <HelpTrigger helpKey="tag" />,
          },
          {
            type: 'inequality',
            label: 'Risk Reduction',
            attribute: 'risk_reduction',
            value: [ 'gt_map', '' ],
            htmlProps: { min: '0', max: '100' },
            subLabel: '%',
            helpItem: <HelpTrigger helpKey="risk_reduction" />,
          },
          {
            type: 'inequality',
            label: 'Vulnerability Age',
            attribute: 'age',
            valuesMap: { gt_map: 'Older than', lt_map: 'Newer than' },
            value: [ 'gt_map', '' ],
            htmlProps: { min: '0', max: '120' },
            subLabel: 'day(s)',
            helpItem: <HelpTrigger helpKey="age" />,
          },
          {
            type: 'inequality',
            label: 'CVSS Score',
            attribute: 'cvss_base_score',
            valuesMap: { gt_map: 'Higher than', lt_map: 'Lower than' },
            value: [ 'gt_map', '' ],
            htmlProps: { min: '0', max: '10' },
            subLabel: '',
            helpItem: <HelpTrigger helpKey="cvss_base_score" />,
          },
          // {
          //   type: 'date',
          //   needsUnixTransform: true,
          //   label: 'Published After',
          //   attribute: 'published_after',
          //   htmlProps: { min: '1998-01-01' },
          //   helpItem: <HelpTrigger helpKey="published_date" />,
          // },
          // {
          //   type: 'date',
          //   needsUnixTransform: true,
          //   label: 'Published Before',
          //   attribute: 'published_before',
          //   htmlProps: { min: '1998-01-01' },
          //   helpItem: <HelpTrigger helpKey="published_date" />,
          // },
          // {
          //   type: 'checkboxGroup',
          //   label: 'Included Vulnerability Scanner(s)',
          //   attribute: 'third_party_setting_ids',
          //   options: {},
          //   disabled: true,
          //   helpItem: <HelpTrigger helpKey="vulnerability_scanners" />,
          // },
        ],
      },
      advancedCenter: {
        header: 'Wildcards and Search',
        fields: [
          // {
          //   type: 'debouncedText',
          //   label: 'Host wildcard',
          //   attribute: 'host_globs',
          //   placeholder: 'example: OR-*SQL*',
          //   helpItem: <HelpTrigger helpKey="host_wildcard" />,
          // },
          // {
          //   type: 'debouncedText',
          //   label: 'Patch wildcard',
          //   attribute: 'patch_globs',
          //   placeholder: 'example: 447*',
          //   helpItem: <HelpTrigger helpKey="patch_wildcard" />,
          // },
          {
            type: 'debouncedText',
            label: 'Vulnerability wildcard',
            attribute: 'vulnerability_globs',
            placeholder: 'example: CVE-2022-*',
            helpItem: <HelpTrigger helpKey="vulnerability_wildcard" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Host',
            attribute: 'host_ids',
            placeholder: 'Find host by name...',
            recordType: 'host',
            helpItem: <HelpTrigger helpKey="specific_host" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Patch',
            attribute: 'patch_ids',
            placeholder: 'Find patch by name...',
            recordType: 'patch',
            helpItem: <HelpTrigger helpKey="specific_patch" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Vulnerability',
            attribute: 'vulnerability_ids',
            placeholder: 'Find vulnerability by name...',
            recordType: 'vulnerability',
            helpItem: <HelpTrigger helpKey="specific_vulnerability" />,
          },
          // {
          //   type: 'searchResults',
          //   label: 'Specific Scanner Signature',
          //   attribute: 'signature_ids',
          //   placeholder: 'Find scanner signature by name...',
          //   recordType: 'scanner_signature',
          //   helpItem: <HelpTrigger helpKey="specific_scanner_signature" />,
          // },
          // This is not yet supported by the Backend, need to comment out until functional -DMC 2022/12/20
          // {
          //   type: 'debouncedText',
          //   label: 'Vulnerability Description Wildcard',
          //   attribute: 'vulnerability_description',
          //   placeholder: 'example: *CISCO*',
          //   helpItem: <HelpTrigger helpKey="vulnerability_description_wildcard" />,
          // },
        ],
      },
      // advancedRight: {
      //   header: 'Operating System',
      //   fields: [
      //     {
      //       type: 'select',
      //       label: 'Host OS Name',
      //       attribute: 'host_os_label',
      //       options: {},
      //       allowBlank: true,
      //       blankDisplayText: 'Any',
      //       defaultValue: '',
      //       helpItem: <HelpTrigger helpKey="host_os_name" />,
      //     },
      //     // family not quite ready for primetime, commenting out for the time being -DMC 2023-1-4
      //     // {
      //     //   type: 'select',
      //     //   label: 'Host OS Family',
      //     //   attribute: 'host_os_family',
      //     //   defaultValue: '',
      //     //   blankDisplayText: 'Any',
      //     //   allowBlank: true,
      //     //   options: {
      //     //     unix: 'Unix',
      //     //     windows: 'Windows',
      //     //     macos: 'macOS',
      //     //     other: 'Other',
      //     //     unknown: 'Unknown',
      //     //   },
      //     //   helpItem: <HelpTrigger helpKey="host_os_family" />,
      //     // },
      //     {
      //       type: 'select',
      //       label: 'Host OS Vendor',
      //       attribute: 'host_vendor',
      //       options: {},
      //       allowBlank: true,
      //       blankDisplayText: 'Any',
      //       defaultValue: '',
      //       helpItem: <HelpTrigger helpKey="host_os_vendor" />,
      //     },
      //     {
      //       type: 'select',
      //       label: 'Host OS Type',
      //       attribute: 'host_os_type',
      //       defaultValue: '',
      //       blankDisplayText: 'Any',
      //       allowBlank: true,
      //       options: {},
      //       helpItem: <HelpTrigger helpKey="host_os_type" />,
      //     },
      //     {
      //       type: 'select',
      //       label: 'Host OS Architecture',
      //       attribute: 'host_architecture',
      //       defaultValue: '',
      //       blankDisplayText: 'Any',
      //       allowBlank: true,
      //       options: {},
      //       helpItem: <HelpTrigger helpKey="host_os_architecture" />,
      //     },
      //   ],
      // },
    },
    instance: {
      alwaysVisible: {
        fields: [
          {
            type: 'debouncedText',
            label: 'Filter Instances',
            attribute: 'keywords',
            placeholder: 'Filter Instances by keyword...',
            shouldTriggerRefresh: true,
            helpItem: <HelpTrigger helpKey="keywords" />,
          },
          {
            type: 'hidden',
            attribute: 'group_type',
            value: 'host',
          },
          {
            type: 'hidden',
            attribute: 'item_count',
            value: 100,
            shouldTriggerRefresh: true,
          },
        ],
      },
      advancedLeft: {
        fields: [
          {
            type: 'select',
            label: 'Tags',
            attribute: 'asset_tag_ids',
            multiple: true,
            value: [],
            allowBlank: true,
            helpItem: <HelpTrigger helpKey="tag" />,
          },
          {
            type: 'select',
            label: 'Category',
            attribute: 'category',
            value: '',
            allowBlank: true,
            blankDisplayText: 'All',
            shouldTriggerRefresh: true,
            helpItem: <HelpTrigger helpKey="category" />,
            options: {
              /* eslint-disable camelcase */
              deprioritized: 'Deprioritized',
              dos_only: 'Purely denial of service',
              not_exploitable: 'Mitigated',
              overridden: 'Overridden',
              for_review: 'For Review',
              unrecognized: 'Unrecognized',
              unsupported: 'Unsupported',
              missing_host: 'Not scanned by DeepSurface',
              cannot_model: 'Cannot Model',
              insufficient_information: 'Insufficient information',
              missing_capability: 'Missing capability',
              prioritized: 'Prioritized',
              unreachable: 'No attack path',
              carries_risk: 'Carries risk',
              /* eslint-enable camelcase */
            },
          },
          {
            type: 'select',
            label: 'Include accepted risk items?',
            attribute: 'accepted_risk',
            value: 'false',
            options: acceptedRiskFilterOptions,
            helpItem: <HelpTrigger helpKey="include_accepted_risk_items" />,
          },
          {
            type: 'checkboxGroup',
            label: 'Exploit Status',
            attribute: 'exploit_statuses',
            defaultValue: [ 'private' ],
            options: {
              private: 'Private',
              // eslint-disable-next-line camelcase
              published_details: 'Details published',
              poc: 'PoC published',
              weaponized: 'Weaponized',
            },
            helpItem: <HelpTrigger helpKey="exploit_status" />,
          },
          {
            type: 'checkboxGroup',
            label: 'Included Vulnerability Scanner(s)',
            attribute: 'third_party_setting_ids',
            options: {},
            disabled: true,
            helpItem: <HelpTrigger helpKey="vulnerability_scanners" />,
          },
          {
            type: 'inequality',
            label: 'CVSS Score',
            attribute: 'cvss_base_score',
            // eslint-disable-next-line camelcase
            valuesMap: { gt_map: 'Higher than', lt_map: 'Lower than' },
            value: [ 'gt_map', '' ],
            htmlProps: { min: '0', max: '10' },
            subLabel: '',
            helpItem: <HelpTrigger helpKey="cvss_base_score" />,
          },
        ],
      },
      advancedCenter: {
        header: 'Wildcards and Search',
        fields: [
          {
            type: 'debouncedText',
            label: 'Host wildcard',
            attribute: 'host_globs',
            placeholder: 'example: OR-*SQL*',
            helpItem: <HelpTrigger helpKey="host_wildcard" />,
          },
          {
            type: 'debouncedText',
            label: 'Patch wildcard',
            attribute: 'patch_globs',
            placeholder: 'example: 447*',
            helpItem: <HelpTrigger helpKey="patch_wildcard" />,
          },
          {
            type: 'debouncedText',
            label: 'Vulnerability wildcard',
            attribute: 'vulnerability_globs',
            placeholder: 'example: CVE-2022-*',
            helpItem: <HelpTrigger helpKey="vulnerability_wildcard" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Host',
            attribute: 'host_ids',
            placeholder: 'Find host by name...',
            recordType: 'host',
            helpItem: <HelpTrigger helpKey="specific_host" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Patch',
            attribute: 'patch_ids',
            placeholder: 'Find patch by name...',
            recordType: 'patch',
            helpItem: <HelpTrigger helpKey="specific_patch" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Vulnerability',
            attribute: 'vulnerability_ids',
            placeholder: 'Find vulnerability by name...',
            recordType: 'vulnerability',
            helpItem: <HelpTrigger helpKey="specific_vulnerability" />,
          },
          {
            type: 'searchResults',
            label: 'Specific Scanner Signature',
            attribute: 'signature_ids',
            placeholder: 'Find scanner signature by name...',
            recordType: 'scanner_signature',
            helpItem: <HelpTrigger helpKey="specific_scanner_signature" />,
          },
          // This is not yet supported by the Backend, need to comment out until functional -DMC 2022/12/20
          // {
          //   type: 'debouncedText',
          //   label: 'Vulnerability Description Wildcard',
          //   attribute: 'vulnerability_description',
          //   placeholder: 'example: *CISCO*',
          //   helpItem: <HelpTrigger helpKey="vulnerability_description_wildcard" />,
          // },
        ],
      },
      advancedRight: {
        header: 'Operating System',
        fields: [
          {
            type: 'select',
            label: 'Host OS Name',
            attribute: 'host_os_label',
            options: {},
            allowBlank: true,
            blankDisplayText: 'Any',
            defaultValue: '',
            helpItem: <HelpTrigger helpKey="host_os_name" />,
          },
          // family not quite ready for primetime, commenting out for the time being -DMC 2023-1-4
          // {
          //   type: 'select',
          //   label: 'Host OS Family',
          //   attribute: 'host_os_family',
          //   defaultValue: '',
          //   blankDisplayText: 'Any',
          //   allowBlank: true,
          //   options: {
          //     unix: 'Unix',
          //     windows: 'Windows',
          //     macos: 'macOS',
          //     other: 'Other',
          //     unknown: 'Unknown',
          //   },
          //   helpItem: <HelpTrigger helpKey="host_os_family" />,
          // },
          {
            type: 'select',
            label: 'Host OS Vendor',
            attribute: 'host_vendor',
            options: {},
            allowBlank: true,
            blankDisplayText: 'Any',
            defaultValue: '',
            helpItem: <HelpTrigger helpKey="host_os_vendor" />,
          },
          {
            type: 'select',
            label: 'Host OS Type',
            attribute: 'host_os_type',
            defaultValue: '',
            blankDisplayText: 'Any',
            allowBlank: true,
            options: {},
            helpItem: <HelpTrigger helpKey="host_os_type" />,
          },
          {
            type: 'select',
            label: 'Host OS Architecture',
            attribute: 'host_architecture',
            defaultValue: '',
            blankDisplayText: 'Any',
            allowBlank: true,
            options: {},
            helpItem: <HelpTrigger helpKey="host_os_architecture" />,
          },
        ],
      },
    },
    user: {
      alwaysVisible: {
        fields: [
          {
            type: 'debouncedText',
            label: 'Filter Users',
            attribute: 'keywords',
            placeholder: 'Filter Users by keyword...',
            shouldTriggerRefresh: true,
            helpItem: <HelpTrigger helpKey="keywords" />,
          },
          {
            type: 'hidden',
            attribute: 'item',
          },
          {
            type: 'hidden',
            attribute: 'item_count',
            value: 100,
            shouldTriggerRefresh: true,
          },
        ],
      },
    },
    path: {
      alwaysVisible: {
        fields: [
          {
            type: 'hidden',
            attribute: 'item',
          },
          {
            type: 'hidden',
            attribute: 'item_count',
            value: 100,
            shouldTriggerRefresh: true,
          },
        ],
      },
    },
  };
  /* eslint-enable camelcase */

  // fetches osversion info and tag info so that the options can be setup
  const fetchFilterOptions = async ( _fields, _flattenedFields, reportType ) => {

    // for the instance/host/patch/vuln report, we need to grab os version info and third_party_instance
    if (
      reportType === 'instance'
      || reportType === 'host'
      || reportType === 'patch'
      || reportType === 'vulnerability'
    ) {
      const _vulnerabilityScannerOptions = {};
      const _availableScanners = {};
      const osResponse = await makeRequest( 'GROUP', '/analysis/osversion', { project: 'default', model: 'base' } );
      const vulnResponse = await makeRequest( 'SEARCH', '/project/default/third_party_setting', {
        // eslint-disable-next-line camelcase
        extra_columns: [ 'tool', 'settings', 'credential_id', 'category' ],
      } );

      if ( isNotEmpty( vulnResponse ) && isNotEmpty( vulnResponse.results ) ) {
        vulnResponse.results.map( v => {
          if ( v.category === 'vulnerability_scanner' ) {
            _availableScanners[v.id] = v;
          }
        } );

        // if there are scanners configured, grab their associated creds
        if ( isNotEmpty( _availableScanners ) ) {
          const credentialResponse = await makeRequest( 'SEARCH', '/project/default/credential', {
            // eslint-disable-next-line camelcase
            extra_columns: [ 'protocol', 'domain', 'username', 'eval_order', 'options', 'label' ],
            // eslint-disable-next-line camelcase
            order_by: [ [ 'eval_order', 'ASC' ] ],
          } );

          if ( isNotEmpty( credentialResponse ) && isNotEmpty( credentialResponse.results ) ) {
            Object.values( _availableScanners ).map( v => {
              const cred = credentialResponse.results.find( c => c.id === v.settings?.credential_id );

              if ( isNotEmpty( cred ) ) {
                const combined = { ...v, settings: { ...v.settings, ...cred } };
                // eslint-disable-next-line max-len
                _vulnerabilityScannerOptions[v.id] = `${shortenedVulnerabilityScannerNameMap[v.tool]} - ${vulnerabilityScannerInstanceName( combined )}`;
              } else {
                _vulnerabilityScannerOptions[v.id] = `${shortenedVulnerabilityScannerNameMap[v.tool]} - Manual Upload`;
              }
            } );

            if ( isNotEmpty( _vulnerabilityScannerOptions ) ) {
              const scannerFilter = _flattenedFields.find( i => i.attribute === 'third_party_setting_ids' );

              if ( isNotEmpty( scannerFilter ) ) {
                scannerFilter.options = _vulnerabilityScannerOptions;
              }
            }
          }
        }
      }

      // work through all the osVersion data and set filter options
      if ( isNotEmpty( osResponse ) && isNotEmpty( osResponse.results ) ) {
        const osVersionData = osResponse.results;

        if ( isNotEmpty( osVersionData ) && isNotEmpty( _flattenedFields ) ) {
          const vendorFilter = _flattenedFields.find( i => i.attribute === 'host_vendor' );
          const typeFilter = _flattenedFields.find( i => i.attribute === 'host_os_type' );
          const archFilter = _flattenedFields.find( i => i.attribute === 'host_architecture' );
          const labelFilter = _flattenedFields.find( i => i.attribute === 'host_os_label' );

          const vendorOptions = {};
          const typeOptions = {};
          const archOptions = {};
          const labelOptions = {};

          if ( isNotEmpty( osVersionData.host_os_vendor ) && isNotEmpty( vendorFilter ) ) {
            osVersionData.host_os_vendor.map( o => {
              vendorOptions[o] = o;
            } );
            vendorFilter.options = vendorOptions;
          }

          if ( isNotEmpty( osVersionData.host_os_type ) && isNotEmpty( typeFilter ) ) {
            osVersionData.host_os_type.map( o => {
              typeOptions[o] = o;
            } );
            typeFilter.options = typeOptions;
          }

          if ( isNotEmpty( osVersionData.host_architecture ) && isNotEmpty( archFilter ) ) {
            osVersionData.host_architecture.map( o => {
              archOptions[o] = o;
            } );
            archFilter.options = archOptions;
          }

          if ( isNotEmpty( osVersionData.host_os_label ) && isNotEmpty( labelFilter ) ) {
            osVersionData.host_os_label.map( o => {
              labelOptions[o] = o;
            } );
            labelFilter.options = labelOptions;
          }
        }
      }
    }

    // work through all the assetTag data and set filter options
    if ( isNotEmpty( tags ) ) {
      const tagFilter = _flattenedFields.find( i => i.attribute === 'asset_tag_ids' );

      if ( isNotEmpty( tagFilter ) ) {
        tagFilter.options = tags;
      }
    }

    setFields( _fields );
    onRefresh();
  };

  // 1) setup the filters, calls fetchFilterOptions to finish filter options, calls onRefresh at the very end
  React.useEffect( ( ) => {
    if ( isNotEmpty( reportType ) ) {
      const _flattenedFields = [];
      const _fields = EMPTY_FILTERS[reportType];

      if ( isNotEmpty( _fields )  ) {
        Object.values( _fields ).map( group => {
          group.fields.map( field => {
            _flattenedFields.push( field );
          } );
        } );
      }
      fetchFilterOptions( _fields, _flattenedFields, reportType );
    }

    if ( decodeURLHash()['creating_report'] ) {
      const project = 'default';
      const model = 'base';
      const filters = {
        // eslint-disable-next-line camelcase
        extra_columns: [
          'created',
          'email_recipients',
          'format',
          'filters',
          'id',
          'label',
          'last_finished',
          'last_started',
          'schedule',
          'expiration',
          'type',
          'owner',
          'state',
        ],
        // eslint-disable-next-line camelcase
        id_list: [ decodeURLHash()['report_id'] ],
      };

      if ( decodeURLHash()['report_id'] ) {
        makeRequest( 'SEARCH', '/model/base/exported_report', {
          project,
          model,
          filters,
        } ).then( response => {
          if ( isMounted ) {
            if ( response && response.results ) {
              // setExistingReport( response.results[0] );
            } else {
              // setExistingReport( {} );
            }
            // beginReportCreator( );
          }
        } );
      } else {
        // setExistingReport( {} );
        // beginReportCreator( );
      }
    }

    return () => {
      isMounted = false;
    };
  }, [ reportType, tags ] );

  return (
    <div
      id="riskInsightFiltersWrapper"
      // eslint-disable-next-line max-len
      className={ `riskInsightFiltersWrapper ${breakpointClass} ${reportType} ${ tableLoading || visualLoading ? 'disabled' : ''}` }
    >
      {
        isNotEmpty( fields ) &&
        <FilterForm
          advanced
          fields={fields}
          onRefresh={onRefresh}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          reportType={reportType}
          visualCollapsed={visualCollapsed}
          noRefresh
        />
      }
      <button
        className={ `showVisualToggle ${ visualCollapsed ? 'visualDisabled' : ''}` }
        onClick={ () => setVisualCollapsed( !visualCollapsed )}
        title="View/Hide Chart"
      >
        {
          visualCollapsed
            ? <React.Fragment>
              <InlineSVG type="visualDisabled" />
            </React.Fragment>
            : <React.Fragment>
              <InlineSVG type="visualEnabled" />
            </React.Fragment>
        }
      </button>
      {
        ( tableLoading || visualLoading ) &&
        <div className="riskInsightFiltersMask" />
      }
    </div>
  );
};

export default InsightFilters;