/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import { makeRequest } from '../../legacy/io';
import Notification from '../shared/Notification';
import { useLocalStorage } from '../shared/Utilities';

export const OnboardingWizardContext = React.createContext();

export const OnboardingWizardProvider = ( { children } ) => {

  const [ wizardStatus, setWizardStatus ] = React.useState( {} );
  const [ showOnboarding, setShowOnboarding ]= React.useState( false );
  const [ currentWizardStep, setCurrentWizardStep ] = React.useState( {} );
  const [ scanningMode, setScanningMode ] = useLocalStorage( 'onboardingScanningMode', 'user_managed' );

  const stepKeys = [
    'windows-domains',
    'credentials',
    'scan-groups',
    'agent-setup',
    'vuln-scanners',
    'one-auth-scan',
    'one-sensitive-asset',
  ];

  const wizardStepText = {
    'windows-domains': {
      link: '#.=scanning&page=scanning_general_settings',
      text: 'Configure your Windows domain(s)',
      textDetails:  <React.Fragment>
        <p>
          To bootstrap domain scanning, DeepSurface needs to know the fully-qualified domain names
          of Windows domains that you would like to include in credentialed scans.
        </p>
        <p>
          DeepSurface will collect user, group, and privilege information from Active Directory,
          but only if the Windows domain is specified here and at least one domain controller for
          that domain is included in the IP address ranges for authenticated host scanning
          (specified in Step 3).
        </p>
      </React.Fragment>,
      stepNumber: 1,
      label: 'Windows Domains',
    },
    'credentials': {
      link: '#.=scanning&page=credentials',
      text: 'Add scanning credentials',
      textDetails:  <React.Fragment>
        <p>
          In order to build a comprehensive threat model, DeepSurface relies primarily on the
          results of credentialed scans of systems in your environment. Configure DeepSurface with
          credentials that allow commands to be run remotely. In Windows environments, an
          administrative account is typically required to run any command remotely
          (even commands requiring less privileges).
        </p>
        <p>
          On systems with SSH enabled, providing the software with credentials to elevate to root
          (e.g. using sudo), although not necessary, allows DeepSurface to collect more detailed
          information and to build a better model.
        </p>
      </React.Fragment>,
      stepNumber: 2,
      label: 'Credentials',
    },
    'scan-groups': {
      link: '#.=scanning&page=scan_groups',
      text: 'Create a new scan group',
      textDetails:  <React.Fragment>
        <p>
          A scan group is a collection of settings that control all aspects of how an authenticated
          host scan will be executed.  Each scan group specifies which IP addresses/ranges will
          be scanned, the credentials to use for the scan, when the scans should be scheduled,
          and other options. Create a scan group now and then we'll show you how to run it in Step
          5.
        </p>
      </React.Fragment>,
      stepNumber: 3,
      label: 'Scan Groups',
    },
    'agent-setup': {
      link: '#.=scanning&page=agents&onboarding_step=3',
      text: 'Configure the DeepSurface Agent',
      textDetails:  <React.Fragment>
        <p>
          The DeepSurface agent can be installed on remote machines in your environment and set to
          check in on a recurring schedule so that you can get a complete look at your entire
          network.
        </p>
      </React.Fragment>,
      stepNumber: 3,
      label: 'Agent Setup',
    },
    'vuln-scanners': {
      link: '#.=scanning&page=vulnerability_scanners',
      text: 'Add and configure your vulnerability scanner(s)',
      textDetails:  <React.Fragment>
        <p>
          DeepSurface relies primarily on third-party vulnerability scanners to obtain information
          on what software vulnerabilities affect your environment. DeepSurface is typically
          configured with API credentials for these vulnerability scanners, causing it to regularly
          sync this information automatically.
        </p>
        <p>
          With some products, it is also possible to manually upload scan results, but this isn't
          recommended for long-term usage of the product due to the extra effort it requires. If
          your organization's vulnerability scanner does not appear in the list of supported
          scanners, please contact our customer success team and we'll find a way to accommodate
          you.
        </p>
      </React.Fragment>,
      stepNumber: 4,
      label: 'Vuln. Scanners',
    },
    'one-auth-scan': {
      link: '#.=activity&page=tasks',
      text: 'Run your first credentialed scan',
      textDetails:  <React.Fragment>
        <p>
          Note: You are required to both configure and run an credentialed scan before proceeding to
          the next setup steps.
        </p>
        <p>
          This is the primary type of scanning DeepSurface performs, allowing it to collect
          architecture and access rights information from multiple levels of your computing
          stack. This scan will automatically detect which IP addresses are active, attempt
          to log in, and then run a series of scripts on each host to gather this information. The
          scans are generally more "light weight" than a typical vulnerability scan, and usually
          require 2min to 10min for each host, with multiple hosts being scanned in
          parallel. (We also recommend that you schedule DeepSurface credentialed scans to run on a
          weekly basis.)
        </p>
        <Notification options={{
          type: 'warning',
          message: 'Warning: starting this task will wipe all sample data from the application. ',
        }}
        />
      </React.Fragment>,
      stepNumber: 5,
      label: 'Credentialed Scan',
    },
    'one-sensitive-asset': {
      link: '#.=setup&page=sensitive_assets_policies',
      text: 'Configure your sensitive assets',
      textDetails:  <React.Fragment>
        <p>
          Now that we know what hosts, users, groups, and data stores exist in your environment, it
          is your time to inform DeepSurface on which of those hold sensitive assets. This is
          critical information, since this is how DeepSurface relates technical risk to business
          risk.
        </p>
        <p>
          Think about which applications, databases, and network shares are very critical to the
          business and assign a relative impact score for each.  In order to obtain a reasonable
          model during your first scan, it is recommended that you configure about 5 sensitive
          assets to start.
        </p>
      </React.Fragment>,
      stepNumber: 6,
      label: 'Sensitive Assets',
    },
  };

  const refreshWizard = async ( shouldSetCurrent=true ) => {
    // grab the latest status and constructs all the info necessary for each step
    const response = await makeRequest( 'STATUS', '/wizard', {'project_id': 'ffffffff-ffff-ffff-ffff-ffffffffffff'} );

    if ( response && response.results ) {
      const serverStatus = response.results;

      const _agentLessStatus = [];

      stepKeys.map( ( key ) => {
        if ( key !== 'agent-setup' && key !== 'user-managed-setup' ) {
          _agentLessStatus.push( {
            stepName: key,
            stepNumber: wizardStepText[key].stepNumber,
            status: serverStatus[key].status ? serverStatus[key].status : '',
            warning: serverStatus[key].reason ? serverStatus[key].reason : '',
            helperText: wizardStepText[key].text ? wizardStepText[key].text : '',
            helperTextDetails: wizardStepText[key].textDetails
              ? wizardStepText[key].textDetails
              : '',
            link: `${wizardStepText[key].link}&onboarding_step=${wizardStepText[key].stepNumber}`,
            label:  wizardStepText[key].label ? wizardStepText[key].label : '',
          } );
        }
      } );

      const userManagedStatus = stepKeys.map( ( key, index ) => {
        return {
          stepName: key,
          stepNumber: index + 1,
          status: serverStatus[key].status ? serverStatus[key].status : '',
          warning: serverStatus[key].reason ? serverStatus[key].reason : '',
          helperText: wizardStepText[key].text ? wizardStepText[key].text : '',
          helperTextDetails: wizardStepText[key].textDetails
            ? wizardStepText[key].textDetails
            : '',
          link: `${wizardStepText[key].link}&onboarding_step=${index + 1}`,
          label:  wizardStepText[key].label ? wizardStepText[key].label : '',
        };
      } );

      const agentStatus = stepKeys.map( ( key, index ) => {
        return {
          stepName: key,
          stepNumber: index + 1,
          status: serverStatus[key].status ? serverStatus[key].status : '',
          warning: serverStatus[key].reason ? serverStatus[key].reason : '',
          helperText: wizardStepText[key].text ? wizardStepText[key].text : '',
          helperTextDetails: wizardStepText[key].textDetails
            ? wizardStepText[key].textDetails
            : '',
          link: `${wizardStepText[key].link}&onboarding_step=${index + 1}`,
          label:  wizardStepText[key].label ? wizardStepText[key].label : '',
        };
      } );

      let _agentStatus = [];
      let _userManagedStatus = [];

      _agentStatus.push( agentStatus[0] );
      _userManagedStatus.push( userManagedStatus[0] );
      _agentStatus.push( agentStatus[3] );
      _userManagedStatus.push( {
        stepName: 'user-managed-setup',
        stepNumber: 2,
        status: serverStatus['agent-setup'].status || '',
        warning: serverStatus['agent-setup'].reason ? serverStatus['agent-setup'].reason : '',
        helperText: 'Configure the User Managed scripts',
        helperTextDetails: <p>
          The user managed scripts can be installed on remote machines in your environment and managed by your
          platform of choice
        </p>,
        link: '#.=scanning&page=user_managed&onboarding_step=3',
        label: 'User Managed Script Setup',
      } );
      _agentStatus.push( agentStatus[4] );
      _userManagedStatus.push( userManagedStatus[4] );
      _agentStatus.push(
        {
          stepName: 'one-auth-scan',
          stepNumber: 4,
          status: serverStatus['one-auth-scan'].status
            ?  serverStatus['one-auth-scan'].status
            : '',
          warning: serverStatus['one-auth-scan'].warning
            ? serverStatus['one-auth-scan'].warning
            : '',
          helperText: 'Sync Agent Data',
          helperTextDetails:  <React.Fragment>
            <p>
              Your Agents will typicially start reporting results within 24 hours, however, if
              you would like to move through setup quicker, you can manually queue them up now.
            </p>
            <Notification options={{
              type: 'warning',
              message: 'Warning: starting this task will wipe all sample data from the application. ',
            }}
            />
          </React.Fragment>,
          link: `${wizardStepText['one-auth-scan'].link}&onboarding_step=${4}`,
          label:  'Sync Agent Data',
        },
      );
      _userManagedStatus.push(
        {
          stepName: 'one-auth-scan',
          stepNumber: 4,
          status: serverStatus['one-auth-scan'].status
            ?  serverStatus['one-auth-scan'].status
            : '',
          warning: serverStatus['one-auth-scan'].warning
            ? serverStatus['one-auth-scan'].warning
            : '',
          helperText: 'Sync User Managed Data',
          helperTextDetails:  <React.Fragment>
            <p>
              Your user managed scripts will typicially start reporting results within 24 hours, however, if
              you would like to move through setup quicker, you can manually queue them up now.
            </p>
            <Notification options={{
              type: 'warning',
              message: 'Warning: starting this task will wipe all sample data from the application. ',
            }}
            />
          </React.Fragment>,
          link: `${wizardStepText['one-auth-scan'].link}&onboarding_step=${4}`,
          label:  'Sync User Managed Data',
        },
      );
      _agentStatus.push( agentStatus[6] );
      _userManagedStatus.push( userManagedStatus[6] );

      _agentStatus = _agentStatus.map( ( step, index ) => {
        return { ...step, stepNumber: step.stepNumber = index + 1};
      } );

      _userManagedStatus = _userManagedStatus.map( ( step, index ) => {
        return { ...step, stepNumber: step.stepNumber = index + 1};
      } );

      // eslint-disable-next-line camelcase
      setWizardStatus( { agent: _agentStatus, agentless: _agentLessStatus, user_managed: _userManagedStatus } );

      // set current step or last if all finished
      if ( shouldSetCurrent ) {
        const currentAgent = _agentStatus.find( ( step ) => step.status !== 'configured' );
        const currentAgentless = _agentLessStatus.find( ( step ) => step.status !== 'configured' );
        const currentUserManaged = _userManagedStatus.find( ( step ) => step.status !== 'configured' );
        setCurrentWizardStep( {
          agent: currentAgent || _agentStatus[_agentStatus.length - 1],
          agentless: currentAgentless || _agentLessStatus[_agentLessStatus.length - 1],
          // eslint-disable-next-line camelcase
          user_managed: currentUserManaged || _userManagedStatus[_userManagedStatus.length - 1],
        } );
      }
    }
  };

  const data = [
    wizardStatus,
    setWizardStatus,
    refreshWizard,
    showOnboarding,
    setShowOnboarding,
    currentWizardStep,
    setCurrentWizardStep,
    scanningMode,
    setScanningMode,
  ];

  return (
    <OnboardingWizardContext.Provider value={ data }>
      { children }
    </OnboardingWizardContext.Provider>
  );
};
