/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { globalColors, isEmpty, isNotEmpty } from '../Utilities';

import './CenterBar.scss';
import { isCurrent } from './shared';

const CenterBar = ( {
  currentPoint,
  data,
  max,
  upperKey,
  lowerKey,
  upperFill='#EF3535',
  lowerFill='#2ED47A',
  elementClass='',
  yOffset=1,
  withoutWrapper,
  containerWidth,
  containerHeight,
} ) => {

  const [ upperData, setUpperData ] = React.useState( null );
  const [ lowerData, setLowerData ] = React.useState( null );
  const [ upperChartMax, setUpperChartMax ] = React.useState( 1 );
  const [ lowerChartMax, setLowerChartMax ] = React.useState( 1 );

  const svgHeight = containerHeight * 0.125;
  const svgWidth = containerWidth - 10;
  const xGutter = ( containerWidth - svgWidth ) / 2;

  const _yOffset = yOffset === 1 ? ( containerHeight * 0.4 ) - 10 : ( containerHeight * 0.65 ) - 10;
  const xOffset = 0;
  const gap = containerWidth / 40;

  // 1) format the incoming data into a format the barchart can render into an upper and lower chart
  // the axis will be in the middle
  React.useEffect( ( ) => {
    if ( isNotEmpty( data ) && isNotEmpty( data.transformed ) && isNotEmpty( upperKey ) && isNotEmpty( lowerKey ) ) {

      const _widthRatio = svgWidth / ( Object.values( data ).length - 1 );

      const _upper = [];
      let _upperMax = 0;
      const _lower = [];
      let _lowerMax = 0;
      Object.values( data.transformed ).map( ( point, index ) => {
        const upperVal = Math.abs( point[upperKey] );
        const lowerVal = Math.abs( point[lowerKey] );

        let x;

        // points are not necessarily spaced out evenly, need to find the distance from the left based on
        // the xAxis series, if no xAxis is passed in... need to assume even spacing
        if ( isNotEmpty( data.xAxis ) ) {
          const firstPointValue = Object.values( data.transformed )[0][data.xAxis];
          // eslint-disable-next-line max-len
          const lastPointValue = Object.values( data.transformed )[ Object.values( data.transformed ).length - 1][data.xAxis];
          const delta = lastPointValue - firstPointValue;
          const newWidthRatio = svgWidth / delta;
          x = ( newWidthRatio * ( point[data.xAxis] - firstPointValue ) ) + xGutter;

        // evenly spaced out
        } else {
          x = ( _widthRatio * index ) + xGutter;
        }

        if ( isEmpty( max ) ) {
          if ( upperVal >= _upperMax ) {
            _upperMax = upperVal;
          }
        }

        _upper.push( { id: point.id, value: upperVal, original: point, x } );

        if ( isEmpty( max ) ) {
          if ( lowerVal >= _lowerMax ) {
            _lowerMax = lowerVal;
          }
        }

        _lower.push( { id: point.id, value: lowerVal, original: point, x } );
      } );

      setUpperData( _upper );
      setLowerData( _lower );
      if ( isEmpty( max ) ) {
        setUpperChartMax( _upperMax );
      } else {
        setUpperChartMax( max );
      }

      if ( isEmpty( max ) ) {
        setLowerChartMax( _lowerMax );
      } else {
        setLowerChartMax( max );
      }
    }

  }, [ data, upperKey, lowerKey, max, containerHeight, containerWidth ] );

  const svgContent = () => {
    return (
      <g className="centerBarGroup">
        {/* horizontal lines */}
        {/* axis */}
        <line
          x1={ xOffset + xGutter }
          x2={ containerWidth - xGutter }
          y1={ svgHeight + _yOffset }
          y2={ svgHeight + _yOffset }
          stroke={ globalColors['grey--icon'] }
          strokeDasharray="8 5"
          strokeWidth={ 1.5 }
        />
        {/* upperBars */}
        {
          upperData.map( ( point, index ) => {
            let width = ( ( svgWidth - xOffset ) / ( upperData.length - 1 ) ) - gap;
            if ( width <= 8 ) {
              width = 8;
            }
            let height = ( svgHeight ) * ( point.value / upperChartMax );
            let y = ( ( svgHeight ) - height ) + _yOffset;

            if ( height < 2 && height !== 0 ) {
              height = 2;
              y -= height;
            }
            return <rect
              className={ isCurrent( point, currentPoint ) ? 'current' : '' }
              key={index}
              x={ isNotEmpty( point.x ) ? ( point.x ) - ( width / 2 ) : 0 }
              y={ ( isEmpty( y ) || isNaN( y ) ) ? 0 : y }
              width={ width }
              height={ height }
              fill={ upperFill }
              fillOpacity={ 1 }
            />;
          } )
        }
        {/* lowerBars, essentially the same as the upper bars, just need to mirror some math */}
        {
          lowerData.map( ( point, _index ) => {
            let width = ( ( svgWidth - xOffset ) / ( upperData.length - 1 ) ) - gap;
            if ( width <= 8 ) {
              width = 8;
            }
            let height = ( svgHeight ) * ( point.value / lowerChartMax );
            const y = svgHeight + _yOffset;

            // prevents bars from being too short
            if ( height < 2 && height !== 0 ) {
              height = 2;
            }

            return <rect
              key={_index}
              x={ isNotEmpty( point.x ) ? point.x - ( width / 2 ) : 0 }
              y={ ( isEmpty( y ) || isNaN( y ) ) ? 0 : y }
              width={ width }
              height={height}
              fill={ lowerFill }
              fillOpacity={ 1 }
            />;
          } )
        }
      </g>
    );
  };

  return (
    <React.Fragment>
      {
        (
          isNotEmpty( upperData )
          && isNotEmpty( lowerData )
          && isNotEmpty( upperChartMax )
          && isNotEmpty( lowerChartMax )
        ) &&
        <React.Fragment>
          {
            withoutWrapper
              ? svgContent()
              : <svg
                viewBox={ `0 0 ${containerWidth} ${svgHeight * 2}` }
                y={yOffset}
                xmlns="http://www.w3.org/2000/svg"
                className={ `centerBarChart ${elementClass}` }
              >
                { svgContent() }
              </svg>
          }
        </React.Fragment>

      }
    </React.Fragment>
  );
};

export default CenterBar;