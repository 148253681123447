/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  defaultTagColor,
  encodeURLHash,
  formatNumber,
  isNotEmpty,
  tagColors,
  userDisplayName,
} from '../../../shared/Utilities';

import {
  HelpTrigger,
} from '../../../components/HelpDocumentation/ContextualHelp/index.js';

import DataTable from '../../../shared/DataTable';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';
import { getFieldValues } from '../../../shared/Form/Shared';
import Form from '../../../shared/Form';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../legacy/io';
import IndeterminantPagination, { getRowNums, pageIterator } from '../../../shared/Pagination/IndeterminantPagination';
import Loading from '../../../shared/Loading';

import './TagEditor.scss';
import { BreakpointContext } from '../../../Contexts/Breakpoint';

const TagEditor = ( {
  selectedRecord,
  showModal,
  setShowModal,
  onSave,
} ) => {
  /* eslint-disable camelcase */
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ breakpointClass ] = React.useContext( BreakpointContext );

  // current user from the context
  const [ currentUser, , ] = React.useContext( CurrentUserContext );
  const [ users, setUsers ] = React.useState( {} );

  // includes the label and all the possible "filters"
  const [ fields, setFields ] = React.useState( null );

  // once filters are applied, these are the matching results from the db

  const [ isValid, setIsValid ] = React.useState( false );
  const [ updatedForm, setUpdatedForm ] = React.useState( false );
  const [ loading, setLoading ] = React.useState( false );
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState( 1 );
  const [ currentPageResults, setCurrentPageResults ] = React.useState( [] );
  const [ nextPageResults, setNextPageResults ] = React.useState( [] );
  // pagination scroll behavior
  const [ scrollToTableTop, setScrollToTableTop ] = React.useState( false );

  const EMPTY_FIELDS = {
    required: {
      fields: [
        {
          type: 'text',
          label: 'Name',
          attribute: 'label',
          required: true,
          defaultValue: '',
          help: <HelpTrigger helpKey="name" />,
        },
        {
          type: 'select',
          label: 'Owner',
          attribute: 'remediation_manager',
          defaultValue: 'null',
          allowBlank: true,
          blankDisplayValue: 'null',
          help: <HelpTrigger helpKey="owner" />,
        },
        {
          type: 'colorSelect',
          label: 'Color',
          attribute: 'color',
          defaultValue: defaultTagColor,
          required: true,
          allowBlank: false,
          options: tagColors,
          help: <HelpTrigger helpKey="color" />,
        },
      ],
    },
    to_include: {
      asSentence: true,
      header: 'Include the following',
      fields: [
        {
          type: 'textarea',
          attribute: 'included_ranges',
          placeholder: 'No IP ranges included',
          label: 'IP Ranges',
          defaultValue: [],
          needsSplit: true,
          elementClass: 'includeFilter',
          help: <HelpTrigger helpKey="ip_ranges" />,
        },
        {
          type: 'textarea',
          attribute: 'included_host_patterns',
          placeholder: 'No wildcards included',
          label: 'Host Wildcards',
          defaultValue: [],
          needsSplit: true,
          elementClass: 'includeFilter',
          help: <HelpTrigger helpKey="host_wildcards" />,
        },
        {
          type: 'searchList',
          attribute: 'included_host_ids',
          placeholder: 'Search...',
          label: 'Specific Hosts',
          recordType: 'host',
          defaultValue: [],
          elementClass: 'includeFilter',
          inlineVersion: true,
          help: <HelpTrigger helpKey="specific_hosts" />,
        },
      ],
    },
    to_exclude: {
      asSentence: true,
      header: 'And then exclude the following',
      fields: [
        {
          type: 'textarea',
          attribute: 'excluded_ranges',
          placeholder: 'No IP ranges excluded',
          label: 'IP Ranges',
          defaultValue: [],
          needsSplit: true,
          elementClass: 'excludeFilter',
          help: <HelpTrigger helpKey="excluded_ip_ranges" />,
        },
        {
          type: 'textarea',
          attribute: 'excluded_host_patterns',
          placeholder: 'No wildcards excluded',
          label: 'Host Wildcards',
          defaultValue: [],
          needsSplit: true,
          elementClass: 'excludeFilter',
          help: <HelpTrigger helpKey="excluded_host_wildcards" />,
        },
        {
          type: 'searchList',
          attribute: 'excluded_host_ids',
          placeholder: 'Search...',
          label: 'Specific Hosts',
          recordType: 'host',
          defaultValue: [],
          elementClass: 'excludeFilter',
          inlineVersion: true,
          help: <HelpTrigger helpKey="excluded_specific_hosts" />,
        },
      ],
    },
  };

  // on init, get the users and add them as options for the owner dropdown
  React.useEffect( () => {
    if ( isNotEmpty( currentUser ) ) {
      const _allUsers = {};

      makeRequest( 'SEARCH', '/user', {
        // eslint-disable-next-line camelcase
        extra_columns: [
          'authentication_provider_id',
          'username',
          'given_name',
          'family_name',
          'api_key',
          'setup_wizard_disabled',
          'email_address',
        ],
        // eslint-disable-next-line camelcase
        order_by:[ [ 'username', 'ASC' ] ],
      } ).then( response => {
        if ( response ) {
          if ( response['results'] && response['results'].length ) {
            response.results.map( user => {

              _allUsers[user.id] = userDisplayName( user );
            } );

            setUsers( _allUsers );
          }
        }
      } );
    }
  }, [ currentUser ] );

  // when the record comes in, populate the fields
  React.useEffect( () => {
    setCurrentPageNumber( 1 );
    setCurrentPageResults( [] );
    setNextPageResults( [] );

    // once the users all come in, setup the form at the top with the correct defaults and options
    if ( isNotEmpty( currentUser ) && isNotEmpty( users ) ) {
      const ownerField = EMPTY_FIELDS.required.fields.find( f => f.attribute === 'remediation_manager' );
      ownerField.defaultValue = currentUser.id;
      ownerField.options = users;

      setFields( EMPTY_FIELDS );
    }
  }, [ selectedRecord, users, currentUser ] );

  React.useEffect( () => {
    if ( !showModal ) {
      setFields( EMPTY_FIELDS );
    }
  }, [ showModal ] );

  const goToPage = page => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { current_page: parseInt( page ) } );
    setScrollToTableTop( true );
    testFilters( parseInt( page ) );
  };

  const testFilters = async ( page=1 ) => {
    setLoading( true );
    setCurrentPageNumber( page );
    setCurrentPageResults( [] );
    setNextPageResults( [] );

    const filterValues = { current_page: page, item_count: 100 };

    const _values = getFieldValues( updatedForm?.fieldStates, 'tag' );
    const params =  {
      // eslint-disable-next-line camelcase
      order_by: [ [ 'label', 'ASC' ] ],
      rownums: getRowNums( filterValues, 100 ),
    };

    Object.keys( _values ).map( attr => {
      params[attr] = _values[attr];
    } );

    encodeURLHash( { current_page: page } );

    const testFiltersResponse = await makeRequest( 'COMPUTE', '/project/default/asset_tag', params );

    setLoading( false );

    if (
      isNotEmpty( testFiltersResponse )
      && isNotEmpty( testFiltersResponse.results )
      && isNotEmpty( testFiltersResponse.results.target_hosts )
    ) {

      const hostData = [];

      testFiltersResponse.results.target_hosts.map( host => {
        hostData.push(
          {
            name: host.local_name,
            // eslint-disable-next-line camelcase
            ip_address: host.last_scanned_address,
          },
        );
      } );
      const pageResults = pageIterator( hostData, filterValues );

      setCurrentPageResults( pageResults.firstPage );
      setNextPageResults( pageResults.secondPage );

      addFlashMessage( {
        type: 'success',
        body: `Applied filters returned ${ formatNumber( testFiltersResponse.results?.target_hosts?.length ) } host(s)`,
      } );
    } else {
      addFlashMessage( {
        type: 'alert',
        body: 'Applied filters returned no hosts',
      } );
    }
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( fields ) &&
        <div className={ `filterBuilder ${breakpointClass}` } >
          <Form
            fields={fields}
            trackUpdates={false}
            recordType="tag"
            onChangeCallback={ setUpdatedForm }
            existingRecord={selectedRecord}
            setIsValid={setIsValid}
          />
          <button
            className="testTagButton"
            onClick={ () => testFilters( 1 ) }
          >
            Test Filters
          </button>
        </div>
      }
      <IndeterminantPagination
        currentPageNumber={currentPageNumber}
        nextPageResults={nextPageResults}
        goToPage={goToPage}
        elementClass="tagTestPagination"
      />
      <div className="tagTestTableWrapper">
        {
          isNotEmpty( currentPageResults ) &&
          <div className="tableDescription">
            The following results were returned with the provided filters:
          </div>
        }
        <DataTable
          data={currentPageResults}
          elementClass="policyTestDataTable"
          scrollToTableTop={scrollToTableTop}
        />
        { loading && <Loading /> }
      </div>
      <div className="modalActions">
        <button
          disabled={ !isValid }
          className={`${isValid ? '' : 'disabled'} submitButton`}
          onClick={ () => onSave(
            selectedRecord,
            isValid,
            getFieldValues( updatedForm?.fieldStates, 'tag' ),
            () => setShowModal( false ),
          ) }
        >
          Save Changes
        </button>
        <button
          className="cancelButton"
          onClick={ () => setShowModal( false ) }
        >
          Cancel
        </button>
      </div>
    </React.Fragment>
  );
};

export default TagEditor;