/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../../shared/Utilities';
import TaskItem from './TaskItem';

import './Step3.scss';

const Step3 = ( {
  planState,
  setLoadingStep,
  setPlanState,
  getTasks,
  isActive=false,
  users,
  activeIntegrations,
} ) => {

  return (
    <React.Fragment>
      <div className={`panel planDraft ${ isActive && 'isActive' } remediationPanel`}>
        {
          isNotEmpty( planState.tasks.selected ) &&
          <section>
            <h2>{ `Tasks to Include in plan (${planState?.tasks?.selected?.length || 0})` }</h2>
            <ul>
              {
                planState.tasks.selected.map( ( task, index ) => {
                  return  <TaskItem
                    task={task}
                    key={index}
                    planState={planState}
                    setPlanState={setPlanState}
                    setLoadingStep={setLoadingStep}
                    getTasks={getTasks}
                    users={users}
                    isSelected
                    activeIntegrations={activeIntegrations}
                  />;
                } )
              }
            </ul>
          </section>
        }
        <section>
          <h2>{ `Available Tasks (${planState?.tasks?.candidates?.length || 0})` }</h2>
          {
            isNotEmpty( planState.tasks.candidates ) &&
            <ul>
              {
                planState.tasks.candidates.map( ( task, index ) => {
                  return  <TaskItem
                    task={task}
                    key={index}
                    planState={planState}
                    setPlanState={setPlanState}
                    setLoadingStep={setLoadingStep}
                    getTasks={getTasks}
                    users={users}
                    activeIntegrations={activeIntegrations}
                  />;
                } )
              }
            </ul>
          }
        </section>
      </div>
    </React.Fragment>
  );
};

export default Step3;