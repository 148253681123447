/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import InsightDetail from '../../Detail';
import InsightFilters from '../../Filters';
import InsightVisual from '../../Visual';
import InsightTable from '../../Table';

import {
  isNotEmpty,
  decodeURLHash,
  encodeURLHash,
  removeFromURLHash,
  paramsToFilters,
  isInequalityType,
} from '../../../../shared/Utilities';

import './style.scss';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue.js';
import { getRecord, getRecords } from '../../../../shared/RecordCache';
import { getRowNums, pageIterator } from '../../../../shared/Pagination/IndeterminantPagination';
import PageHeader from '../../../../shared/PageHeader';

const Patches = ( ) => {

  const [ selectedRecord, setSelectedRecord ] = React.useState( null );
  const [ hoverRecord, setHoverRecord ] = React.useState( null );
  const [ visualLoading, setVisualLoading ] = React.useState( true );
  const [ tableLoading, setTableLoading ] = React.useState( true );
  const [ riskType, setRiskType ] = React.useState( 'risk' );

  const [ sortBy, setSortBy ] = React.useState( 'risk' );
  const [ sortDirection, setSortDirection ] = React.useState( 'DESC' );

  // pagination related state variables
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState( 1 );
  const [ currentPageResults, setCurrentPageResults ] = React.useState( [] );
  const [ nextPageResults, setNextPageResults ] = React.useState( [] );

  // controls the top overview panel collapsed state, storing here to pass down to multiple components
  const [ visualcollapsed, setVisualCollapsed ] = React.useState( false );
  // controls the filter drawer at the top level
  const [ filtersCollapsed, setFiltersCollapsed ] = React.useState( true );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const isMounted = true;

  // sorting by clicking the column headers in the table
  React.useEffect( () => {
    const currentHash = decodeURLHash();

    if ( sortBy !== currentHash.sort_by || sortDirection !== currentHash.sort_direction ) {
      // eslint-disable-next-line camelcase
      encodeURLHash( { sort_by: sortBy, sort_direction: sortDirection } );
      onRefresh();
    }
  }, [ sortBy, sortDirection ] );

  const fetchExistingRecord = async( recordID ) => {
    const hash = decodeURLHash();
    const tagIDs = hash.asset_tag_ids;
    // eslint-disable-next-line camelcase
    const risk_type = hash.risk_type || 'risk';

    const _record = (
      await getRecord(
        'patch',
        recordID,
        // eslint-disable-next-line camelcase, max-len
        isNotEmpty( tagIDs ) ? { asset_tag_ids: tagIDs, risk_type } : { risk_type },
        true,
      )
    );
    return _record;
  };

  const selectRecord = async ( recordID ) => {
    if ( isNotEmpty( recordID ) ) {

      const fetchedRecord = await fetchExistingRecord( recordID );

      if ( isNotEmpty( fetchedRecord ) ) {
        setSelectedRecord( fetchedRecord );
        encodeURLHash( { item: recordID } );
        setHoverRecord( null );
      } else {
        addFlashMessage( {
          type: 'alert',
          body: 'The record you are looking for is no longer part of the risk model',
        } );
        setSelectedRecord( {} );
        removeFromURLHash( 'item' );
        onRefresh();
        setHoverRecord( null );
      }
    } else {
      setSelectedRecord( {} );
      removeFromURLHash( 'item' );
      onRefresh();
      setHoverRecord( null );
    }
  };

  /* eslint-disable camelcase */
  const onRefresh = async( adjustedFilters=[] ) => {

    const onCorrectPage = decodeURLHash()['report'] === 'patches';

    if ( onCorrectPage ) {
      setVisualLoading( true );
      setTableLoading( true );

      // if the filter that was just changed is not the current page, set it back to page 1
      if ( isNotEmpty( adjustedFilters ) && !adjustedFilters.some( attr => attr === 'current_page ' ) ) {
        // eslint-disable-next-line camelcase
        encodeURLHash( { current_page: 1 } );
      }

      const filterValues = paramsToFilters();

      const params =  {
        model: 'base',
        project: 'default',
        rownums: [ 0, 100 ],
        filters: { 'superseded': 'unsuperseded' },
      };

      const _rowNums = getRowNums( filterValues );

      const gt_map = {};
      const lt_map = {};

      if ( isNotEmpty( filterValues ) ) {
        Object.entries( filterValues ).map( ( [ key, val ] ) => {
          if ( key === 'keywords' ) {
            params.filters['keywords'] = val;
          } else if ( key === 'asset_tag_ids' ) {
            // eslint-disable-next-line camelcase
            params.filters['asset_tag_ids'] = isNotEmpty( val ) ? val : null;
          } else if ( key === 'published_before' && isNotEmpty( val ) ) {
            params.filters[key] = val;
          } else if ( key === 'published_after' && isNotEmpty( val ) ) {
            params.filters[key] = val;
          } else if ( key === 'item_count' ) {
            params.rownums = _rowNums;
          } else if ( isInequalityType.includes( key ) ) {
            const [ inequality, unit ] = val;

            if ( isNotEmpty( unit ) ) {
              if ( inequality === 'gt_map' ) {
                gt_map[key] = parseFloat( unit );
              } else if ( inequality === 'lt_map' ) {
                lt_map[key] = parseFloat( unit );
              }
            }
            if ( isNotEmpty( gt_map ) ) {
              params.filters.gt_map = gt_map;
            }
            if ( isNotEmpty( lt_map ) ) {
              params.filters.lt_map = lt_map;
            }
          } else {
            params.filters[key] = val;
          }
        } );
      }

      let _riskType = decodeURLHash().risk_type;

      // in case the legacy 'direct' val is in the url still from an old report
      if ( _riskType === 'direct' ) {
        _riskType = 'direct_risk';
      }
      setRiskType( _riskType );

      const _sortDirection = filterValues.sort_direction || 'DESC';

      // eslint-disable-next-line camelcase
      params.order_by = [
        [ _riskType, _sortDirection ],
        [ 'vendor', 'ASC' ],
        [ 'identifier', 'DESC' ],
      ];

      if ( _riskType === 'direct_risk' ) {
        // eslint-disable-next-line camelcase
        params.group_type = 'patch_direct';
      } else {
        // eslint-disable-next-line camelcase
        params.group_type = 'patch_cumulative';
      }

      let recordList = [];
      let pagedResults = [];

      if ( isNotEmpty( filterValues.item ) ) {
        if ( isMounted ) {
          selectRecord( filterValues.item );
        }
      } else {
        recordList = await getRecords( 'PATCH', params, true );
        pagedResults = pageIterator( recordList, filterValues );
      }
      if ( isMounted ) {
        setCurrentPageNumber(
          pagedResults.currentPageNumber ? parseInt( pagedResults.currentPageNumber ) : 1,
        );
        setCurrentPageResults( pagedResults.firstPage );
        setNextPageResults( pagedResults.secondPage );
      }
      setVisualLoading( false );
      setTableLoading( false );
    }
  };
  /* eslint-enable camelcase */

  return (
    <React.Fragment>
      {
        isNotEmpty( selectedRecord )
          ? <InsightDetail
            item={selectedRecord}
            selectItem={selectRecord}
            reportType="patch"
            riskType={riskType}
          />
          : <React.Fragment>
            <PageHeader>
              <InsightFilters
                onRefresh={onRefresh}
                collapsed={filtersCollapsed}
                visualCollapsed={visualcollapsed}
                setVisualCollapsed={setVisualCollapsed}
                setCollapsed={setFiltersCollapsed}
                reportType="patch"
                tableLoading={ tableLoading }
                visualLoading={ visualLoading }
              />
              <InsightVisual
                reportType="patch"
                loading={visualLoading}
                collapsed={visualcollapsed}
                setCollapsed={setVisualCollapsed}
                selectRecord={selectRecord}
                setHoverRecord={setHoverRecord}
                onRefresh={onRefresh}
                records={currentPageResults}
                riskType={riskType}
              />
            </PageHeader>
            <InsightTable
              records={currentPageResults}
              nextRecords={nextPageResults}
              currentPageNumber={currentPageNumber}
              loading={tableLoading}
              reportType="patch"
              onRefresh={onRefresh}
              refreshTable={onRefresh}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
              visualCollapsed={visualcollapsed}
              selectedRecord={selectedRecord}
              selectRecord={selectRecord}
              hoverRecord={hoverRecord}
              setHoverRecord={setHoverRecord}
              riskType={riskType}
            />
          </React.Fragment>
      }
    </React.Fragment>
  );
};

export default Patches;