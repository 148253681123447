/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import Form from '../../../shared/Form';

const ScanGroupModal = ( {
  trackUpdates,
  fields,
  setIsValid,
  existingRecord,
  recordType,
  onChangeCallback,
  showModal,
} ) => {

  const [ activeTab, setActiveTab ] = React.useState( 'general' );

  // whenever the modal opens or closes, reset the activeTab
  React.useEffect( ( ) => {
    setActiveTab( 'general' );
  }, [ showModal ] );

  return (
    <React.Fragment>
      <ul className="setupTabs">
        <li
          className={`setupTab ${activeTab === 'general' ? 'active' : ''}`}
          onClick={ () => setActiveTab( 'general' )}
        >
          <span className="tabStep">1</span>
          General
        </li>
        <li
          className={`setupTab ${activeTab === 'settings' ? 'active' : ''}`}
          onClick={ () => setActiveTab( 'settings' )}
        >
          <span className="tabStep">2</span>
          Settings
        </li>
      </ul>
      <div className={`tabWrapper ${activeTab}`}>
        <Form
          trackUpdates={ trackUpdates }
          fields={fields}
          setIsValid={setIsValid}
          existingRecord={existingRecord}
          recordType={recordType}
          onChangeCallback={ onChangeCallback }
        />
      </div>
    </React.Fragment>
  );
};

export default ScanGroupModal;