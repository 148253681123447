/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { capitalize, isNotEmpty, riskToRating } from '../../../../shared/Utilities';
import { getRecord } from '../../../../shared/RecordCache';
import InstancesBreakdown from '../../../../shared/Charts/InstancesBreakdown';
import { makeRequest } from '../../../../../legacy/io';
import RecordStat from './RecordStat';

import './ScopeContent.scss';
import CVSSCategories from '../../../../shared/CVSSCategories';

const ScopeContent = ( { scope, options } ) => {

  const [ hostDetails, setHostDetails ] = React.useState( null );
  const [ instanceData, setInstanceData ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( false );

  const svgWrapperRef = React.useRef( null );

  const scopeTypeMap = {
    // eslint-disable-next-line camelcase
    windows_domain: 'Windows Domain',
    host: 'Host',
    subnet: 'Subnet',
    // eslint-disable-next-line camelcase
    listening_ports: 'Listening Ports',
    // eslint-disable-next-line camelcase
    share_smb: 'Share SMB',
    application: 'Application',
    global: 'Global',
  };

  React.useEffect( () => {
    if ( isNotEmpty( scope ) ) {
      if ( scope.type === 'host' ) {
        // if this is a host, get more information about it
        // eslint-disable-next-line camelcase
        getRecord( 'scope', scope.id,  { field_map: { type: 'host' } },  true ).then( response => {
          if ( isNotEmpty( response ) ) {
            setHostDetails( response );
          }
        } );

        const tallyParams = {
          project: 'default',
          model: 'base',
          filters: {
            // eslint-disable-next-line camelcase
            host_ids: [ scope.id ],
            // eslint-disable-next-line camelcase
            risk_type: scope.risk ? 'risk' : 'filtered_risk',
          },
        };

        setLoading( true );
        // if this is a host, get the necessary instance data for the visual
        makeRequest( 'TALLY', '/analysis/instance', tallyParams ).then( response => {
          setLoading( false );
          if ( isNotEmpty( response ) ) {
            setInstanceData( response );
          }
        } );
      }
    }
  }, [ scope ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( scope ) && scope.type !== 'host' ) &&
        <section>
          <label>Type</label>
          <span>{ scopeTypeMap[scope.type] || capitalize( scope.type ) }</span>
        </section>
      }
      {
        isNotEmpty( options )
          ? <React.Fragment>
            {
              ( 'includeInstances' in options && options.includeInstances === true ) &&
              <React.Fragment>
                {
                  ( isNotEmpty( scope ) && scope.type === 'host' && isNotEmpty( instanceData ) ) &&
                  <InstancesBreakdown
                    reportType="host"
                    itemID={ scope.id }
                    riskRating={ riskToRating( scope.risk ? scope.risk : scope.filtered_risk ) }
                    instanceData={ instanceData }
                    svgRef={ svgWrapperRef }
                    loading={ loading }
                  />
                }
              </React.Fragment>
            }
          </React.Fragment>
          : <React.Fragment>
            {
              ( isNotEmpty( scope ) && scope.type === 'host' && isNotEmpty( instanceData ) ) &&
              <InstancesBreakdown
                reportType="host"
                itemID={ scope.id }
                riskRating={ riskToRating( scope.risk ? scope.risk : scope.filtered_risk ) }
                instanceData={ instanceData }
                svgRef={ svgWrapperRef }
                loading={ loading }
              />
            }
          </React.Fragment>
      }
      {
        (
          isNotEmpty( scope )
          && isNotEmpty( instanceData )
          && isNotEmpty( options )
          && ( options.includeCVSS )
        ) &&
        <CVSSCategories data={ instanceData } />
      }
      {
        ( isNotEmpty( scope ) && scope.type === 'host' && isNotEmpty( hostDetails ) ) &&
        <div className="recordStatistics">
          <RecordStat statKey="num_unsuperseded_patches" statValue={ hostDetails.num_unsuperseded_patches } />
          <RecordStat statKey="num_vulnerabilities" statValue={ hostDetails.num_vulnerabilities } />
        </div>
      }
    </React.Fragment>
  );
};

export default ScopeContent;