/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import { HelpTrigger } from '../../../HelpDocumentation/ContextualHelp/index.js';
import Form from '../../../../shared/Form';
import SetupForm from '../../../../shared/SetupComponents/SetupForm';
import { isEmpty, isNotEmpty } from '../../../../shared/Utilities';
import { isValidPort } from '../../../../shared/Form/Validators';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue';

import './style.scss';
import { makeRequest } from '../../../../../legacy/io';

const OutboundProxy = ( ) => {

  const [ isValid, setIsValid ] = React.useState( true );
  const [ fields, setFields ] = React.useState( null );

  const [ proxyFormState, setProxyFormState ] = React.useState( null );

  // ContextualHelp getters and setters
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  let isMounted = true;

  const [ existingSettings, setExistingSettings ] = React.useState( {} );

  const EMPTY_FIELDS = {
    enableGroup: {
      fields: [
        {
          attribute: 'enabled',
          type: 'checkbox',
          asToggle: true,
          label: 'Enable Outbound Proxy ',
          defaultValue: false,
          help: <HelpTrigger helpKey="enable_outbound_proxy" />,
        },
      ],
    },
    proxyGroup: {
      fields: [
        {
          attribute: 'protocol',
          type: 'select',
          label: 'Protocol',
          options: {
            http: 'HTTP',
            // https: 'HTTPS',
          },
          defaultValue: 'http',
          disableIf: {
            attribute: 'enabled',
            value: false,
          },
          help: <HelpTrigger helpKey="protocol" />,
        },
        {
          attribute: 'server',
          type: 'text',
          label: 'Proxy Server',
          defaultValue: '',
          disableIf: {
            attribute: 'enabled',
            value: false,
          },
          help: <HelpTrigger helpKey="proxy_server" />,
        },
        {
          attribute: 'port',
          type: 'number',
          label: 'Port',
          defaultValue: 8080,
          disableIf: {
            attribute: 'enabled',
            value: false,
          },
          htmlProps: {
            min: 1,
            max: 65535,
          },
          validators: [
            isValidPort,
          ],
          help: <HelpTrigger helpKey="port" />,
        },
        {
          attribute: 'username',
          type: 'text',
          label: 'Proxy Username',
          defaultValue: '',
          disableIf: {
            attribute: 'enabled',
            value: false,
          },
          help: <HelpTrigger helpKey="proxy_username" />,
        },
        {
          attribute: 'password',
          type: 'text',
          label: 'Proxy Password',
          defaultValue: '',
          disableIf: {
            attribute: 'enabled',
            value: false,
          },
          help: <HelpTrigger helpKey="proxy_password" />,
        },
      ],
    },
  };

  const refreshForm = async () => {
    // grab existing settings
    const response = await makeRequest( 'FETCH', '/outbound_proxy' );

    if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
      setExistingSettings( response.results );

      setFields( EMPTY_FIELDS );
    } else if ( isNotEmpty( response.errors ) ) {
      response.errors.map( ( e ) => {
        addFlashMessage( {
          type: 'alert',
          body: e,
        } );
      } );
    } else {
      addFlashMessage( {
        type: 'alert',
        body: 'There was an error retrieving your proxy settings',
      } );
    }
  };

  // register and remove onleave listener
  React.useEffect( () => {
    const parentEl = document.getElementById( 'pageContent' );

    parentEl.onleave = async () => {
      if ( isValid ) {
        onSave( false );
        return true;
      }
    };
    return () => {
      parentEl.onleave = null;
    };
  }, [ proxyFormState, isValid ] );

  // setup the form and existing values on page load
  React.useEffect( () => {
    isMounted = true;
    refreshForm();
    return () => {
      isMounted = false;
    };
  }, [] );

  const onRevert = () => {
    refreshForm();
  };

  const onSave = async ( refresh=true ) => {

    const {fieldStates} = proxyFormState;

    const settings = { ...existingSettings };

    Object.entries( fieldStates ).map( ( [ attr, state ] ) => {
      settings[attr] = state.updatedValue;
    } );


    // the password field can be empty, don't want to overwrite existing
    if ( isEmpty( settings.password ) ) {
      delete settings.password;
    }

    const response = await makeRequest( 'UPDATE', '/outbound_proxy', { settings } );

    if ( isNotEmpty( response.errors ) ) {
      if ( isMounted && refresh ) {
        response.errors.map( ( e ) => {
          addFlashMessage( {
            type: 'alert',
            body: e,
          } );
        } );
      }
    } else if ( isMounted && refresh ) {
      addFlashMessage( {
        type: 'success',
        body: 'Successfully updated outbound proxy settings',
      } );
      refreshForm();
    }
  };

  return (
    <SetupForm elementClass="outboundProxyForm">
      {
        ( isNotEmpty( fields ) && isNotEmpty( existingSettings ) ) &&
        <Form
          fields={fields}
          setIsValid={setIsValid}
          existingRecord={existingSettings}
          recordType="outbound_proxy"
          onChangeCallback={ setProxyFormState }
        />
      }
      <div className="formActions">
        <button
          className="revertButton"
          onClick={ onRevert }
        >
          Revert
        </button>
        <button
          disabled={ !isValid }
          onClick={ onSave }
        >
          Save
        </button>
      </div>
    </SetupForm>
  );
};

export default OutboundProxy;