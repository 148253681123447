/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import { globalColors, isNotEmpty, riskToRating } from '../../../../../shared/Utilities';
import { nameForPlan, percentComplete } from '../../../../Remediation/RemediationPlans/Shared';
import { v2Fetches } from '../../shared';
import EmptyLoading from '../../../../../shared/EmptyLoading';

import './RemediationPlans.scss';
import Legend from '../../../../../shared/Charts/Legend';
import RatingBadge from '../../../../../shared/RatingBadge';

const RemediationPlans = ( { item, prefetchedData, settings } ) => {

  const [ loading, setLoading ] = React.useState( false );
  const [ chartData, setChartData ] = React.useState( null );

  const legendData = {
    risk: {
      label: 'Risk Reduction',
      key: 'risk',
      fill: globalColors['status--yellow'],
    },
    instances: {
      label: 'Instances Reduction',
      key: 'instances',
      fill: globalColors['darkBlue'],
    },
  };

  const setupRemediationPlans = async () => {
    const formatData = plans => {
      setLoading( false );

      if ( isNotEmpty( plans ) && isNotEmpty( plans.results ) ) {
        const _plans = {};
        const _chartData = {};
        const _yLabels = [];

        plans.results.map( plan => {
          if ( plan.status === 'active' || plan.status === 'draft' ) {

            _plans[plan.id] = plan;

            const planRisk = plan.risk;
            const planInstances = plan.num_instances;

            _yLabels.push( nameForPlan( plan ) );

            _chartData[plan.id] = {
              label: nameForPlan( plan ),
              original: plan,
              series: [
                {
                  name: 'Risk',
                  fill: globalColors['status--yellow'],
                  value: percentComplete( plan.original_risk, planRisk ),
                  original: plan,
                },
                {
                  name: 'Risk',
                  fill: globalColors.darkBlue,
                  value: percentComplete( plan.original_num_instances, planInstances ),
                  original: plan,
                },
              ],
            };
          }
        } );
        setChartData( _chartData );
      }
    };

    setLoading( true );

    if (
      isNotEmpty( prefetchedData )
      && isNotEmpty( prefetchedData.remediationPlans )
    ) {
      formatData( prefetchedData.remediationPlans );
    } else {
      const _plans = await v2Fetches.remediationPlans();
      formatData( _plans );
    }

  };

  React.useEffect( ( ) => {
    setupRemediationPlans();
  }, [ prefetchedData, settings ] );

  return (
    <React.Fragment>
      <EmptyLoading
        payload={ chartData }
        loading={ loading }
        loadingMessage="Loading remediation plans"
        emptyMessage="No active/draft remediation plans"
      />
      {
        isNotEmpty( chartData ) &&
        <React.Fragment>
          <div id={ `remediationChartWrapper-${item.i}` } className="remediationChartWrapper" >
            {
              Object.values( chartData ).map( ( planDetails, index ) => {
                return <div className="remediationPlanWrapper" key={index} >
                  <label>
                    <span>{ planDetails.label }</span>
                    <RatingBadge item={planDetails?.original} rating={ riskToRating( planDetails?.original?.risk ) } />
                  </label>
                  <div className="labelAndBarWrapper">
                    <span>{ `${planDetails.series[0].value}%` }</span>
                    <div className="planCompletionWrapper risk">
                      <div
                        className="planCompletionBar"
                        style={{ background: planDetails.series[0].fill, width: `${planDetails.series[0].value}%`} }
                      />
                    </div>
                  </div>
                  <div className="labelAndBarWrapper">
                    <span>{ `${planDetails.series[1].value}%` }</span>
                    <div className="planCompletionWrapper instances">
                      <div
                        className="planCompletionBar"
                        style={{ background: planDetails.series[1].fill, width: `${planDetails.series[1].value}%`} }
                      />
                    </div>
                  </div>
                </div>;
              } )
            }
          </div>
          <Legend horizontal legendData={ legendData } />
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default RemediationPlans;