/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import Area from '../../../../../shared/Charts/Area';
import EmptyLoading from '../../../../../shared/EmptyLoading';
import { debounce, globalColors, isNotEmpty } from '../../../../../shared/Utilities';

import { v4 as uuidv4 } from 'uuid';

import './PeerPercentileOverTime.scss';

import Xaxis from '../../../../../shared/Charts/Axis/Xaxis';
import ChartHoverIndicators from '../../../../../shared/Charts/ChartHoverIndicators';
import { v2Fetches } from '../../shared';
import PeerPercentile from './PeerPercentile';

const PeerPercentileOverTime = ( { item, prefetchedData, settings } ) => {

  const MAX_POINTS = 80;

  const [ chartData, setChartData ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( true );
  // not changing color anymore, may revisit
  // const [ color, setColor ] = React.useState( 'primaryBlue' );
  const [ current, setCurrent ] = React.useState( null );

  const [ svgContainerWidth, setSVGContainerWidth ] = React.useState( null );
  const [ svgContainerHeight, setSVGContainerHeight ] = React.useState( null );

  const svgContainerRef = React.useRef( null );

  const onRefresh = async () => {

    const formatData = overTimeData => {
      // the highest percentile (helps draw the graph)
      const max = 1;

      // if there are too many points, they will not fit or look good in a graph, this will reduce the amount down
      // to the MAX_POINTS allowed
      const maxGroupSize = Math.ceil( overTimeData.results.length / MAX_POINTS );

      // this reduces all the points down into an array of arrays
      const groupedPoints = overTimeData.results.reduce( ( allGroupedPoints, point, index ) => {

        const groupIndex = Math.floor( index / maxGroupSize );

        if( !allGroupedPoints[groupIndex] ) {
          allGroupedPoints[groupIndex] = [];
        }

        allGroupedPoints[groupIndex].push( point );

        return allGroupedPoints;
      }, [] );

      // this is the formatted points available for the chart that will be filled up by the below map
      const _data = {};

      // map over our groups to format the data in just the right way for the chart to interpret
      groupedPoints.map( ( group, groupIndex ) => {

        let highestPercentile = 0;
        let targetPoint = null;

        group.map( point => {
          if ( point.percentile >= highestPercentile ) {
            highestPercentile = point.percentile;
            targetPoint = point;
          }
        } );

        group.map( ( point, pointIndex ) => {
          // the first point of the first group
          if ( groupIndex === 0 && pointIndex === 0 ) {
            _data[point.date + 0.000001] = {
              percentile: point.percentile,
              risk: point.risk,
              originalIndex: groupIndex,
              timestamp: point.date + 0.000001,
              totalPoints: groupedPoints.length + 2,
              original: [ point ],
              id: `${point.date}_${point.risk}_${uuidv4()}`,
            };
          // last point of the last group
          } else if ( groupIndex === groupedPoints.length - 1 && pointIndex === group.length - 1 ) {
            _data[point.date + 0.000001] = {
              percentile: point.percentile,
              risk: point.risk,
              originalIndex: groupIndex + 1,
              timestamp: point.date + 0.000001,
              totalPoints: groupedPoints.length + 2,
              original: [ point ],
              id: `${point.date}_${point.risk}_${uuidv4()}`,
            };
          // regular group
          } else {
            _data[targetPoint.date + 0.000001] = {
              percentile: targetPoint.percentile,
              risk: targetPoint.risk,
              originalIndex: groupIndex + 1,
              timestamp: targetPoint.date + 0.000001,
              totalPoints: groupedPoints.length + 2,
              original: group,
              id: `${point.date}_${point.risk}_${uuidv4()}`,
            };
          }
        } );

      } );

      // overTimeData.results[ overTimeData.results.length - 1 ].percentile = 0;

      if (
        isNotEmpty( overTimeData.results )
        && isNotEmpty( _data )
      ) {
        const _chartData = {
          original: overTimeData.results,
          transformed: _data,
          max,
          yAxis: 'percentile',
          xAxis: 'timestamp',
        };

        if ( isNotEmpty( overTimeData.results[ overTimeData.results.length - 1 ] ) ) {
          _chartData.latestPoint = overTimeData.results[ overTimeData.results.length - 1 ];
          _chartData.currentPercentile = overTimeData.results[ overTimeData.results.length - 1 ].percentile || 0;
          setCurrent( overTimeData.results[ overTimeData.results.length - 1 ] );
        }
        setChartData( _chartData );
      } else {
        setCurrent( null );
        setChartData( null );
      }
      setLoading( false );
    };

    setLoading( true );

    if (
      isNotEmpty( prefetchedData )
      && isNotEmpty( prefetchedData.percentile )
      && isNotEmpty( prefetchedData.percentile.results )
    ) {
      // IMPORTANT! Keep this log -DMC 2023-06-26
      console.log( 'Percentile Results: ', prefetchedData.percentile.results );
      formatData( prefetchedData.percentile );
    } else {
      v2Fetches.percentile().then( response => {
        // IMPORTANT! Keep this log -DMC 2023-06-26
        console.log( 'Percentile Results: ', response.results );
        formatData( response );
      } );
    }
  };

  React.useEffect( ( ) => {
    onRefresh();
  }, [ prefetchedData, item, settings ] );

  // fires on all resize events, throttles to changes greater than 100px
  const adjustSVGAspectRatio = ( ) => {
    const width = window.innerWidth - 400;
    let height = width / 3;
    if ( isNotEmpty( item ) && isNotEmpty( item.h ) ) {
      height = item.h * 60;
    }
    setSVGContainerWidth( width );
    setSVGContainerHeight( height );
  };

  // sets up resize aspect ratio event listener
  React.useEffect( ( ) => {
    if ( isNotEmpty( svgContainerRef ) && isNotEmpty( item ) ) {
      adjustSVGAspectRatio( true );
      window.addEventListener( 'resize', debounce( () => {
        adjustSVGAspectRatio();
      }, 1_000 ) );
      return () => window.removeEventListener( 'resize', debounce );
    }
  }, [ svgContainerRef, item ] );


  const onHoverCallback = ( point, chartData ) => {
    if ( isNotEmpty( point ) ) {
      setCurrent( point.original );
      // setColor( percentileColor( point.original?.percentile ) );
    } else {
      setCurrent( chartData.latestPoint );
      // setColor( percentileColor( chartData.currentPercentile ) );
    }
  };

  return (
    <React.Fragment>
      <EmptyLoading
        payload={ chartData }
        loading={ loading }
        loadingMessage="Loading peer comparison data"
        emptyMessage="Insufficient peer comparison data at this time"
      />
      {
        isNotEmpty( chartData ) &&
        <div className="chartPlusAxis">
          <div className="peerComparisonAreaWrapper" ref={svgContainerRef}>
            <div className="chartAxis yAxis riskOverTimeAxis">
              <div className="tic">100th</div>
              <div className="tic">75th</div>
              <div className="tic">50th</div>
              <div className="tic">25th</div>
            </div>
            {
              ( isNotEmpty( svgContainerHeight ) && isNotEmpty( svgContainerWidth ) ) &&
              <svg
                viewBox={ `0 0 ${svgContainerWidth} ${svgContainerHeight}` }
                xmlns="http://www.w3.org/2000/svg"
                className="areaPlusBarWrapper"
                id="areaPlusBarWrapper"
                preserveAspectRatio="none"
              >
                <Xaxis
                  data={ chartData }
                  dataKey="timestamp"
                  yOffset={ 0 }
                  withoutWrapper
                  currentPoint={current}
                  containerHeight={ svgContainerHeight }
                  containerWidth={ svgContainerWidth }
                />
                <line
                  x1={ ( svgContainerWidth - ( svgContainerWidth - 10 ) ) / 2 }
                  x2={ ( svgContainerWidth - ( svgContainerWidth - 10 ) ) / 2 }
                  y1={ 0 }
                  y2={ svgContainerHeight - 25 - 35 }
                  stroke={ globalColors['grey--divider']}
                  strokeWidth={ 2 }
                />
                <Area
                  data={ chartData }
                  stroke="darkBlue"
                  fill="darkBlue"
                  horizontalLinesCount={4}
                  fullHeight
                  withoutWrapper
                  currentPoint={current}
                  containerHeight={ svgContainerHeight }
                  containerWidth={ svgContainerWidth }
                />
                <ChartHoverIndicators
                  data={ chartData }
                  onHoverCallback={ onHoverCallback }
                  stroke="darkBlue"
                  fill="darkBlue"
                  currentPoint={current}
                  containerHeight={ svgContainerHeight }
                  containerWidth={ svgContainerWidth }
                  fullHeight
                />
              </svg>
            }
          </div>
          <div className="chartLabel">Peer percentile over time</div>
        </div>
      }
      {
        isNotEmpty( current ) &&
        <PeerPercentile
          prefetchedData={
            {
              percentile: {
                results: [
                  current,
                ],
              },
            }
          }
        />
      }
    </React.Fragment>
  );
};

export default PeerPercentileOverTime;