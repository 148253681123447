/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import { isNotEmpty } from '../../../Utilities';

import './File.scss';

const File = ( {
  field,
  formState,
  originalValue,
  onChange,
  existingRecord,
  fieldRef,
} ) => {

  // set the value on init
  React.useEffect( () => {
    if (
      isNotEmpty( fieldRef )
      && isNotEmpty( fieldRef.current )
    ) {
      fieldRef.current.value = originalValue || '';
    }
  }, [ originalValue, fieldRef, existingRecord ] );

  const handleFileChange = e => {
    const reader = new FileReader();

    reader.onload = e => {
      if ( isNotEmpty( fieldRef ) && isNotEmpty( fieldRef.current ) ) {
        fieldRef.current.value = e.target.result;
      }

      onChange( field, e.target.result );
    };

    reader.readAsText( e.target.files[0] );
  };

  return (
    <React.Fragment>
      <input
        accept={field.mime ? field.mime : ''}
        disabled={field.disabled || formState.fieldStates[field.attribute].disabled}
        type={field.type}
        onChange={ handleFileChange }
      />
      {
        field.needsPreview &&
        <textarea
          ref={ fieldRef }
          disabled={field.disabled || formState.fieldStates[field.attribute].disabled}
          onChange={ e => onChange( field, e.target.value ) }
          { ...field.htmlProps }
        />
      }
    </React.Fragment>
  );
};

export default File;
