/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';
import InlineSVG from '../../shared/InlineSVG';

import {
  isEmpty,
  isNotEmpty,
} from '../../shared/Utilities';

import RatingBadge from '../../shared/RatingBadge';
import { NavigationContext } from '../../Contexts/Navigation';
import { isBetaRoute, isNewRoute } from '../App/Routing';

const PageTitle = ( { currentLocation } ) => {

  const [ pageTitle, setPageTitle ] = React.useState( '' );
  const [ pageIcon, setPageIcon ] = React.useState( <React.Fragment></React.Fragment> );
  const [ , , routing, , customReports, , , ] = React.useContext( NavigationContext );

  React.useEffect( () => {
    if (
      isNotEmpty( currentLocation )
      && isNotEmpty( currentLocation.route )
      && isNotEmpty( routing )
      && isNotEmpty( routing[currentLocation?.route] )
    ) {
      setPageTitle( routing[currentLocation?.route].label );
      setPageIcon( <InlineSVG type={ routing[currentLocation?.route].iconKey }/> );
    }
  }, [ currentLocation, customReports, routing ] );

  return (
    <h1 id="mainPageTitle" className={`${currentLocation?.route}PageTitle`}>
      {
        isNotEmpty( currentLocation?.route ) &&
        <React.Fragment>
          { pageIcon }
          <span>{ pageTitle }</span>
          {
            isBetaRoute( currentLocation?.route ) &&
            <RatingBadge rating="beta" />
          }
          {
            isNewRoute( currentLocation?.route ) &&
            <RatingBadge rating="beta" />
          }
        </React.Fragment>
      }
      {
        isEmpty( currentLocation?.route ) &&
        <React.Fragment>
          <InlineSVG type="reporting" />
          <span>Reporting</span>
        </React.Fragment>
      }
    </h1>
  );
};

export default PageTitle;