/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { BreakpointContext } from '../../../../Contexts/Breakpoint';
import { isNotEmpty } from '../../../../shared/Utilities';
import { HelpTrigger } from '../../../HelpDocumentation/ContextualHelp';
import { UneditableWidgets, v2WidgetComponentMap, v2WidgetsWithLegends } from '../shared';

import './WidgetWrapper.scss';
import WidgetDescription from './v2/WidgetDescription';
import InlineSVG from '../../../../shared/InlineSVG';
import { FullScreenVisualContext } from '../../../../Contexts/FullScreenVisual';

const WidgetWrapper = ( {
  item,
  editMode,
  removeItem,
  prefetchedData,
  printing=false,
  editWidget,
  settings,
} ) => {
  const [ , , showVisual, , showFullScreenVisual, , , ] = React.useContext( FullScreenVisualContext );

  const Content = v2WidgetComponentMap[item.key];

  const [ breakpointClass ] = React.useContext( BreakpointContext );

  const [ contentClass, setContentClass ] = React.useState( 'medium' );

  React.useEffect( ( ) => {
    if ( isNotEmpty( item ) && isNotEmpty( item.w ) && isNotEmpty( breakpointClass ) ) {
      let _class = 'medium';
      // width smallest to largest, then within each width, check the screen width to determine how much content
      // needs to be visible
      // 1/3 of the width, for really large sreens, show a medium amount, otherwise show the smallest
      if ( item.w === 2 ) {
        if ( breakpointClass === 'xlarge' ) {
          _class = 'medium';
        } else if ( breakpointClass === 'large' ) {
          _class = 'small';
        } else {
          _class = 'xsmall';
        }
      }
      // 1/2 of the width
      if ( item.w === 3 ) {
        if ( breakpointClass === 'xlarge' ) {
          _class = 'large';
        } else if ( breakpointClass === 'large' ) {
          _class = 'medium';
        } else if ( breakpointClass === 'small' || breakpointClass === 'medium' ) {
          _class = 'small';
        } else {
          _class = 'xsmall';
        }
      }
      // 2/3 of the width
      if ( item.w === 4 ) {
        if ( breakpointClass === 'xxsmall' || breakpointClass === 'xsmall' ) {
          _class = 'xsmall';
        } else if ( breakpointClass === 'small' ) {
          _class = 'small';
        } else if ( breakpointClass === 'medium' ) {
          _class = 'medium';
        } else if ( breakpointClass === 'large' ) {
          _class = 'large';
        } else {
          _class = 'xlarge';
        }
      }
      if ( item.w === 5 ) {
        if ( breakpointClass === 'xxsmall' || breakpointClass === 'xsmall' || breakpointClass === 'small' ) {
          _class = 'small';
        } else if ( breakpointClass === 'medium' ) {
          _class = 'medium';
        } else if ( breakpointClass === 'large' ) {
          _class = 'large';
        } else {
          _class = 'xlarge';
        }
      }
      if ( item.w === 6 ) {
        if ( breakpointClass === 'xxsmall' || breakpointClass === 'xsmall' || breakpointClass === 'small' ) {
          _class = 'medium';
        } else if ( breakpointClass === 'medium' ) {
          _class = 'large';
        } else {
          _class = 'xlarge';
        }
      }
      setContentClass( _class );
    }
  }, [ item, breakpointClass ] );

  // any specific classes for the type of priority ( hosts, patches, etc. )
  const priorityTypeClass = () => {
    if ( item.type === 'priority' && isNotEmpty( item.settings ) ) {
      return item.settings.report_type;
    }
  };

  // any specific classes for widgets depending on settings
  const settingsClass = () => {
    const classes = [];
    if ( isNotEmpty( item ) && isNotEmpty( item.settings ) ) {
      if ( item.settings.include_description ) {
        classes.push( 'withDescription' );
      }
      classes.push( item.settings.version );
    }
    return classes.join( ' ' );
  };

  const getFullLabelForItem = item => {
    const dynamicFullLabels = [
      'hosts_priority',
      'patches_priority',
      'vulnerabilities_priority',
      'users_priority',

      'paths_global',
      'paths_specific',
    ];

    if ( isNotEmpty( item ) && isNotEmpty( item.key ) ) {
      if ( dynamicFullLabels.includes( item.key ) ) {
        switch ( item.key ) {

        case 'hosts_priority':
          return '';
        case 'patches_priority':
          return '';
        case 'vulnerabilities_priority':
          return '';
        case 'users_priority':
          return '';

        case 'paths_specific':
          return `${item.settings?.record_label}: Top ${item.settings?.item_count} Critical Paths`;
        case 'paths_global':
          return `Top ${item.settings?.item_count} Critical Paths`;
        default:
          return '';
        }
      }
    }
    return item.fullLabel || '';
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( Content ) && isNotEmpty( item ) ) &&
        <div
          // eslint-disable-next-line
          className={ `${ ( v2WidgetsWithLegends.includes( item.key ) || item?.settings?.version === 'barchart' ) ? 'withLegend' : '' }  widgetHeight-${item.h} widgetWidth-${item.w} breakPointWidth--${breakpointClass} contentWidth--${contentClass} ${editMode ? 'editMode' : ''} dashboardWidgetWrapper ${item.key} ${settingsClass()} ${priorityTypeClass()}` }
          id={ `dashboardWidget-${item.key}-${item.i}` }
        >
          {
            getFullLabelForItem( item ) !== '' &&
            <div className={'widgetHeader'}>
              <h2>
                { getFullLabelForItem( item ) }
                {
                  item.key === 'exploit_status' &&
                  <HelpTrigger helpKey="exploit_status" />
                }
              </h2>
            </div>
          }
          <div className="widgetContent">
            {
              ( isNotEmpty( item ) && isNotEmpty( item.settings ) && item.settings.include_description ) &&
              <WidgetDescription
                item={ item }
                prefetchedData={prefetchedData}
              />
            }
            {
              <Content
                item={ item }
                settings={ settings }
                contentClass={contentClass}
                prefetchedData={prefetchedData}
                printing={printing}
              />
            }
            <button
              className="fullScreenVisualToggleButton"
              onClick={ () => showFullScreenVisual(
                <Content
                  item={ item }
                  settings={ settings }
                  contentClass={contentClass}
                  prefetchedData={prefetchedData}
                  printing={printing}
                />,
                `dashboardWidget ${item?.key} ${item?.settings?.version}`,
              ) }
            >
              { showVisual ? <InlineSVG type="exitFullscreen" /> : <InlineSVG type="fullscreen" /> }
            </button>
          </div>
          {
            editMode &&
            <div className="editModeFooter">
              <span
                className="editModeButton draggable"
              >
                <InlineSVG elementClass="draggableIcon" type="draggable"/>
              </span>
              {
                isNotEmpty( !UneditableWidgets.includes( item.key ) ) &&
                <button
                  className="editModeButton edit"
                  onClick={ () => editWidget( item ) }
                >
                  <InlineSVG type="edit" />
                </button>
              }
              <button
                className="editModeButton delete"
                onClick={ () => removeItem( item ) }
              >
                <InlineSVG type="delete" version="status--red" />
              </button>
            </div>
          }
        </div>
      }
    </React.Fragment>
  );
};

export default WidgetWrapper;