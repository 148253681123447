/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../Utilities';
import XAxisLabels from '../AxisLabels/XAxisLabels';
import YAxisLabels from '../AxisLabels/YAxisLabels';
import Bar from '../Bar';

import './style.scss';

const CVSSChart = ( {
  cvssData,
  svgRef,
  containerHeight=34,
  containerWidth,
  hoveredSeriesIdentifier=null,
  setHoveredSeriesIdentifier=null,
} ) => {

  const [ yMax, setYMax ] = React.useState( null );
  const [ xLabels, setXLabels ] = React.useState( null );

  React.useEffect( ( ) => {
    if ( isNotEmpty( cvssData ) ) {

      const _xLabels = [];
      let _yMax = 0;

      Object.entries( cvssData ).map( ( [ catKey, catData ] ) => {
        _xLabels.push(
          <React.Fragment>
            <span className="mainLabel">{ catKey }</span>
            <span className="subLabel">{ catData.subLabel }</span>
          </React.Fragment>,
        );
        if ( catData.value > _yMax ) {
          _yMax = catData.value;
        }
      } );
      setXLabels( _xLabels );
      setYMax( _yMax );
    }
  }, [ cvssData ] );
  return (
    <React.Fragment>
      {
        ( isNotEmpty( cvssData ) && isNotEmpty( yMax ) ) &&
        <React.Fragment>
          <div className="cvssBarChartWrapper" ref={svgRef} >
            <YAxisLabels yMax={ yMax } />
            <Bar
              data={cvssData}
              containerWidth={containerWidth}
              containerHeight={containerHeight}
              hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
              setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
            />

          </div>
          <XAxisLabels labels={ xLabels } />
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default CVSSChart;