/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isEmpty, isNotEmpty } from '../../../../shared/Utilities';
import { getEscalationsForEdge } from '../shared';
import SignatureContent from './SignatureContent';
import VulnerabilityContent from './VulnerabilityContent';
import SignatureHeader from '../Header/SignatureHeader';
import VulnerabilityHeader from '../Header/VulnerabilityHeader';
import InlineSVG from '../../../../shared/InlineSVG';
import EmptyState from '../../../../shared/EmptyState';

const CombinedVulnerabilitySignatureContent = ( { vulnerability, edge, context, selectItem } ) => {

  const [ instance, setInstance ] = React.useState( null );
  const [ signature, setSignature ] = React.useState( null );
  const [ escalation, setEscalation ] = React.useState( null );

  const [ showVulnerability, setShowVulnerability ] = React.useState( true );
  const [ showSignature, setShowSignature ] = React.useState( true );

  React.useEffect( () => {
    if ( isNotEmpty( vulnerability ) && isNotEmpty( edge ) ) {
      getEscalationsForEdge( edge ).then( response => {
        if ( isNotEmpty( response ) && isNotEmpty( response.map ) ) {

          const vulnInstance = response.map[ vulnerability.id ];

          if ( isNotEmpty( vulnInstance ) ) {
            setInstance( vulnInstance.instance );
            setSignature( vulnInstance.signature );
            setEscalation( vulnInstance.escalation );
          }
        }
      } );
    }
  }, [ vulnerability, edge ] );

  return (
    <React.Fragment>
      <div
      // eslint-disable-next-line max-len
        className={ `collapsibleSectionWrapper ${showVulnerability ? '' : 'collapsed' }` }
      >
        <div
          className="collapsibleSectionHeader"
          onClick={ () => setShowVulnerability( !showVulnerability ) }
        >
          <div className="headerLeft">
            <div className="recordCardHeader">
              <VulnerabilityHeader
                vulnerability={vulnerability}
                context={ context }
                selectItem={ selectItem }
              />
            </div>
          </div>
          <div className="headerRight">
            <span className="carretWrapper">
              <InlineSVG type="carretUp"/>
            </span>
          </div>
        </div>
        <div className="collapsibleSectionBody">
          <VulnerabilityContent
            vulnerability={ vulnerability }
          />
        </div>
      </div>
      {
        isEmpty( signature )
          ? <EmptyState message="No vulnerability scanner information" />
          : <div
          // eslint-disable-next-line max-len
            className={ `collapsibleSectionWrapper ${showSignature ? '' : 'collapsed' }` }
          >
            <div
              className="collapsibleSectionHeader"
              onClick={ () => setShowSignature( !showSignature ) }
            >
              <div className="headerLeft">
                <div className="recordCardHeader signatureHeader">
                  <SignatureHeader
                    signature={ signature }
                  />
                </div>
              </div>
              <div className="headerRight">
                <span className="carretWrapper">
                  <InlineSVG type="carretUp"/>
                </span>
              </div>
            </div>
            <div className="collapsibleSectionBody">
              <SignatureContent
                signature={ signature }
                instance={ instance }
                escalation={ escalation }
                vulnerability={ vulnerability }
              />
            </div>
          </div>
      }
    </React.Fragment>
  );
};

export default CombinedVulnerabilitySignatureContent;