/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { capitalize, formatNumber, hexToRGB, isNotEmpty, itemIsArray } from '../Utilities';

import './Legend.scss';
import InlineSVG from '../InlineSVG';
import LegendOverflowModal from './LegendOverflowModal';

const Legend = ( {
  label=null,
  horizontal=false,
  canFilter=true,
  legendData,
  hoveredSeriesIdentifier=null,
  setHoveredSeriesIdentifier,
  selectedSeriesIdentifier=null,
  setSelectedSeriesIdentifier,
  onHoverCallback,
  onClickCallback,
  allowMultipleSelections=false,
} ) => {

  const MAX_ITEMS = 9;

  const [ visibleLegendData, setVisibleLegendData ] = React.useState( null );
  const [ overflowCount, setOverflowCount ] = React.useState( 0 );
  const [ showAllItems, setShowAllItems ] = React.useState( false );

  const handleMouseEnter = series => {
    if ( isNotEmpty( setHoveredSeriesIdentifier ) ) {
      setHoveredSeriesIdentifier( series.key );
      if ( isNotEmpty( onHoverCallback ) ) {
        onHoverCallback( series );
      }
    }
  };

  const handleMouseLeave = () => {
    if ( isNotEmpty( setHoveredSeriesIdentifier ) ) {
      setHoveredSeriesIdentifier( null );
      if ( isNotEmpty( onHoverCallback ) ) {
        onHoverCallback( null );
      }
    }
  };

  const alreadySelected = key => {
    if ( canFilter ) {
      if ( itemIsArray( selectedSeriesIdentifier ) ) {
        return selectedSeriesIdentifier?.includes( key );
      }
      return selectedSeriesIdentifier === key;
    }
    return false;
  };

  const handleMouseClick = series => {
    let identifier;

    if ( isNotEmpty( setSelectedSeriesIdentifier ) ) {
      if ( alreadySelected( series?.key ) ) {
        if ( allowMultipleSelections ) {
          identifier = selectedSeriesIdentifier?.filter( i => i !== series.key );
        } else {
          identifier = null;
        }
      } else if ( allowMultipleSelections ) {
        identifier = [ series.key ];
        if ( isNotEmpty( selectedSeriesIdentifier ) ) {
          identifier = [ ...selectedSeriesIdentifier, series.key ];
        }
      } else {
        identifier = series.key;
      }

      setSelectedSeriesIdentifier( identifier );

      if ( isNotEmpty( onClickCallback ) ) {
        onClickCallback( identifier );
      }
    }
  };

  const isFaded = ( series ) => {
    if ( allowMultipleSelections ) {
      return isNotEmpty( selectedSeriesIdentifier ) && !selectedSeriesIdentifier.includes( series.key );
    }
    return isNotEmpty( selectedSeriesIdentifier ) && selectedSeriesIdentifier !== series.key;
  };

  const listItemBackground = series => {
    if ( alreadySelected( series.key ) || hoveredSeriesIdentifier === series.key ) {
      const color = hexToRGB( series.stroke ? series.stroke : series.fill );
      return `rgba(${color.r}, ${color.g}, ${color.b}, 0.2)`;
    }
    return 'none';
  };

  // sometimes there can be a huge amount of data, need to cap it so that it does not break the layout of the page
  React.useEffect( () => {
    if ( isNotEmpty( legendData ) ) {
      if ( Object.keys( legendData ).length > MAX_ITEMS ) {

        const _visibleItems = {};

        setOverflowCount( Object.keys( legendData ).length - MAX_ITEMS );

        Object.entries( legendData ).map( ( [ key, value ], index ) => {
          if ( index < MAX_ITEMS ) {
            _visibleItems[key] = value;
          }
        } );

        setVisibleLegendData( _visibleItems );
      } else {
        setVisibleLegendData( legendData );
      }
    }
  }, [ legendData ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( visibleLegendData ) &&
        <div className={ `chartLegend ${horizontal ? 'horizontal' : '' }`}>
          {
            isNotEmpty( label ) &&
            <h4>{ label }</h4>
          }
          <ul>
            {
              Object.entries( visibleLegendData ).map( ( [ key, series ], index ) => {
                return <li
                  key={index}
                  // eslint-disable-next-line max-len
                  className={ `${ isFaded( series ) ? 'isFaded' : ''} ${ isNotEmpty( setHoveredSeriesIdentifier ) ? 'isHoverable' : '' } ${ isNotEmpty( setSelectedSeriesIdentifier ) ? 'isClickable' : '' } ${ hoveredSeriesIdentifier === series.key ? 'isHovered' : '' } ${ alreadySelected( series.key ) ? 'isSelected' : '' } ${ series.total === 0 ? 'disabled' : '' }` }
                  onMouseEnter={ () => handleMouseEnter( series ) }
                  onMouseLeave={ handleMouseLeave }
                  onClick={ () => handleMouseClick( series ) }
                  style={
                    {
                      background: listItemBackground( series ),
                    }
                  }
                >
                  <div className="labelAndKeyWrapper">
                    <span className="legendKey" style={ { background: series.stroke ? series.stroke : series.fill} } />
                    <span className="legendLabel">
                      { series.label ? series.label : capitalize( key ) }
                    </span>
                    {
                      isNotEmpty( series.total ) &&
                      <strong className="legendCount">
                        ({ formatNumber( series.total ) })
                      </strong>
                    }
                  </div>
                  {
                    ( alreadySelected( series.key ) && !allowMultipleSelections && canFilter ) &&
                    <InlineSVG type="remove" elementClass="removeFilterIcon" />
                  }
                  {
                    ( isNotEmpty( selectedSeriesIdentifier ) && allowMultipleSelections ) &&
                    <React.Fragment>
                      {
                        alreadySelected( series.key )
                          ? <InlineSVG type="checkboxChecked" elementClass="removeFilterIcon" />
                          : <InlineSVG type="checkbox" elementClass="removeFilterIcon" />
                      }
                    </React.Fragment>
                  }
                </li>;
              } )
            }
          </ul>
          {
            overflowCount > 0 &&
            <button
              className="overflowCount"
              onClick={ () => setShowAllItems( true ) }
            >
              + { formatNumber( overflowCount ) } more
            </button>
          }
        </div>
      }
      <LegendOverflowModal
        legendData={ legendData }
        showAllItems={ showAllItems }
        setShowAllItems={ setShowAllItems }
        label={ label }
        isFaded={ isFaded }
        hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
        setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
        selectedSeriesIdentifier={ selectedSeriesIdentifier }
        setSelectedSeriesIdentifier={ setSelectedSeriesIdentifier }
        alreadySelected={ alreadySelected }
        handleMouseClick={ handleMouseClick }
        handleMouseEnter={ handleMouseEnter }
        handleMouseLeave={ handleMouseLeave }
        listItemBackground={ listItemBackground }
        allowMultipleSelections={ allowMultipleSelections }
        canFilter={ canFilter }
      />
    </React.Fragment>
  );
};

export default Legend;