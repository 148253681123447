/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../shared/Utilities';
import { getFieldValues } from '../../../shared/Form/Shared';
import Modal from '../../../shared/Modal';
import Form from '../../../shared/Form';

import './WidgetEditorModal.scss';
import EmptyState from '../../../shared/EmptyState';
import { TagsContext } from '../../../Contexts/Tags';
import { HelpTrigger } from '../../HelpDocumentation/ContextualHelp';
import { v2WidgetMinMaxDimensions } from './shared';
import { makeRequest } from '../../../../legacy/io';

const WidgetSelectorBody = ( {
  selectedWidgetVariant,
  setSelectedWidgetVariant,
  selectedWidgetCategory,
  setRecordLabel,
  currentWidget,
  currentSettings,
  setUpdatedSettings,
} ) => {

  const [ tags ] = React.useContext( TagsContext );

  const [ variantOptions, setVariantOptions ] = React.useState( null );
  const [ variantFields, setVariantFields ] = React.useState( null );

  // for the specific remediation plan select field
  const [ remediationPlanOptions, setRemediationPlanOptions ] = React.useState( null );

  /* eslint-disable camelcase */
  const EMPTY_VARIANT_FIELDS = {
    // risk insight/standard report variants ( hosts, patches, vulnerabilities, users )
    hosts_specific: {
      fields: [
        {
          type: 'searchResults',
          selectCallback: ( result, field, onChange ) => saveRecordLabel( result, field, onChange ),
          attribute: 'record_id_host',
          label: 'Specific Host',
          placeholder: 'Find host by name...',
          recordType: 'scope',
          required: true,
          defaultValue: '',
        },
        {
          type: 'checkbox',
          label: 'Include risk scores and ratings?',
          attribute: 'include_risk',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include Vulnerability Instances Information?',
          attribute: 'include_instances',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include CVSS score(s) and information?',
          attribute: 'include_cvss',
          defaultValue: false,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'host',
        },
      ],
    },
    hosts_top_details: {
      fields: [
        {
          type: 'select',
          label: 'Record Ordering Method',
          attribute: 'order_by',
          defaultValue: 'filtered_risk',
          required: true,
          options: {
            // eslint-disable-next-line camelcase
            filtered_risk: 'Risk',
            // eslint-disable-next-line camelcase
            num_vulnerabilities: 'Amount of Vulnerabilities',
          },
        },
        {
          type: 'select',
          label: 'Number of Results',
          attribute: 'item_count',
          defaultValue: 3,
          required: true,
          options: {
            3: '3',
            5: '5',
          },
        },
        {
          type: 'select',
          label: 'Widget Orientation',
          attribute: 'orientation',
          defaultValue: 'vertical',
          required: true,
          options: {
            vertical: 'Vertical',
            horizontal: 'Horizontal',
          },
        },
        {
          type: 'checkbox',
          label: 'Include risk scores and ratings?',
          attribute: 'include_risk',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include Vulnerability Instances Information?',
          attribute: 'include_instances',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include CVSS score(s) and information?',
          attribute: 'include_cvss',
          defaultValue: false,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'hosts',
        },
      ],
    },
    hosts_priority: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
        {
          type: 'select',
          label: 'Tag(s)',
          attribute: 'asset_tag_ids',
          asMultiple: true,
          value: [],
          allowBlank: true,
          helpItem: <HelpTrigger helpKey="tag" />,
        },
        {
          type: 'select',
          label: 'Widget Version',
          attribute: 'version',
          defaultValue: 'table',
          required: true,
          options: {
            table: 'Table',
            list: 'List',
            barchart: 'Bar Chart',
          },
        },
        {
          includeIf: { attribute: 'version', value: 'table' },
          type: 'checkbox',
          label: 'Include risk scores and ratings?',
          attribute: 'include_risk',
          defaultValue: true,
          required: false,
        },
        {
          includeIf: { attribute: 'version', value: 'table' },
          type: 'checkbox',
          label: 'Include last user?',
          attribute: 'include_user',
          defaultValue: false,
        },
        {
          includeIf: { attribute: 'version', value: 'table' },
          type: 'checkbox',
          label: 'Include OS Type?',
          attribute: 'include_os_type',
          defaultValue: false,
        },
        {
          type: 'select',
          label: 'Record Ordering Method',
          attribute: 'order_by',
          defaultValue: 'filtered_risk',
          required: true,
          options: {
            // eslint-disable-next-line camelcase
            filtered_risk: 'Risk',
            // eslint-disable-next-line camelcase
            num_vulnerabilities: 'Amount of Vulnerabilities',
          },
        },
        {
          type: 'select',
          label: 'Number of Results',
          attribute: 'item_count',
          defaultValue: 25,
          required: true,
          options: {
            10: '10',
            25: '25',
            50: '50',
            100: '100',
          },
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'hosts',
        },
      ],
    },
    hosts_global: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'hosts',
        },
      ],
    },
    hosts_total: {
      fields: [
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'hosts',
        },
      ],
    },

    patches_specific: {
      fields: [
        {
          type: 'searchResults',
          selectCallback: ( result, field, onChange ) => saveRecordLabel( result, field, onChange ),
          attribute: 'record_id_patch',
          label: 'Specific Patch',
          placeholder: 'Find patch by name...',
          recordType: 'patch',
          required: true,
          defaultValue: '',
        },
        {
          type: 'checkbox',
          label: 'Include risk scores and ratings?',
          attribute: 'include_risk',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include Vulnerability Instances Information?',
          attribute: 'include_instances',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include CVSS score(s) and information?',
          attribute: 'include_cvss',
          defaultValue: false,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'patch',
        },
      ],
    },
    patches_priority: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
        {
          type: 'select',
          label: 'Tag(s)',
          attribute: 'asset_tag_ids',
          asMultiple: true,
          value: [],
          allowBlank: true,
          helpItem: <HelpTrigger helpKey="tag" />,
        },
        {
          type: 'select',
          label: 'Widget Version',
          attribute: 'version',
          defaultValue: 'table',
          required: true,
          options: {
            table: 'Table',
            list: 'List',
            barchart: 'Bar Chart',
          },
        },
        {
          includeIf: { attribute: 'version', value: 'table' },
          type: 'checkbox',
          label: 'Include risk scores and ratings?',
          attribute: 'include_risk',
          defaultValue: true,
          required: false,
        },
        {
          includeIf: [
            { attribute: 'version', value: 'table' },
            { attribute: 'version', value: 'list' },
          ],
          type: 'checkbox',
          label: 'Include CVSS score(s) and information?',
          attribute: 'include_cvss',
          defaultValue: false,
          required: false,
        },
        {
          type: 'select',
          label: 'Number of Results',
          attribute: 'item_count',
          defaultValue: 25,
          required: true,
          options: {
            10: '10',
            25: '25',
            50: '50',
            100: '100',
          },
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'patches',
        },
      ],
    },
    patches_top_details: {
      fields: [
        {
          type: 'select',
          label: 'Record Ordering Method',
          attribute: 'order_by',
          defaultValue: 'filtered_risk',
          required: true,
          options: {
            // eslint-disable-next-line camelcase
            filtered_risk: 'Risk',
            // eslint-disable-next-line camelcase
            num_vulnerabilities: 'Amount of Vulnerabilities',
            // eslint-disable-next-line camelcase
            num_hosts: 'Amount of Affected Hosts',
          },
        },
        {
          type: 'select',
          label: 'Number of Results',
          attribute: 'item_count',
          defaultValue: 3,
          required: true,
          options: {
            3: '3',
            5: '5',
          },
        },
        {
          type: 'select',
          label: 'Widget Orientation',
          attribute: 'orientation',
          defaultValue: 'vertical',
          required: true,
          options: {
            vertical: 'Vertical',
            horizontal: 'Horizontal',
          },
        },
        {
          type: 'checkbox',
          label: 'Include risk scores and ratings?',
          attribute: 'include_risk',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include Vulnerability Instances Information?',
          attribute: 'include_instances',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include CVSS score(s) and information?',
          attribute: 'include_cvss',
          defaultValue: false,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'patches',
        },
      ],
    },
    patches_global: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'patches',
        },
      ],
    },
    patches_total: {
      fields: [
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'patches',
        },
      ],
    },

    vulnerabilities_specific: {
      fields: [
        {
          type: 'searchResults',
          selectCallback: ( result, field, onChange ) => saveRecordLabel( result, field, onChange ),
          attribute: 'record_id_vulnerability',
          label: 'Specific Vulnerability',
          placeholder: 'Find vulnerability by name...',
          recordType: 'vulnerability',
          required: true,
          defaultValue: '',
        },
        {
          type: 'checkbox',
          label: 'Include risk scores and ratings?',
          attribute: 'include_risk',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include Vulnerability Instances Information?',
          attribute: 'include_instances',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include CVSS score(s) and information?',
          attribute: 'include_cvss',
          defaultValue: false,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'vulnerability',
        },
      ],
    },
    vulnerabilities_priority: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
        {
          type: 'select',
          label: 'Tag(s)',
          attribute: 'asset_tag_ids',
          asMultiple: true,
          value: [],
          allowBlank: true,
          helpItem: <HelpTrigger helpKey="tag" />,
        },
        {
          type: 'select',
          label: 'Widget Version',
          attribute: 'version',
          defaultValue: 'table',
          required: true,
          options: {
            table: 'Table',
            list: 'List',
            barchart: 'Bar Chart',
          },
        },
        {
          includeIf: { attribute: 'version', value: 'table' },
          type: 'checkbox',
          label: 'Include risk scores and ratings?',
          attribute: 'include_risk',
          defaultValue: true,
          required: false,
        },
        {
          includeIf: { attribute: 'version', value: 'table' },
          type: 'checkbox',
          label: 'Include CVSS score(s) and information?',
          attribute: 'include_cvss',
          defaultValue: false,
          required: false,
        },
        {
          type: 'select',
          label: 'Record Ordering Method',
          attribute: 'order_by',
          defaultValue: 'filtered_risk',
          required: true,
          options: {
            // eslint-disable-next-line camelcase
            filtered_risk: 'Risk',
            // eslint-disable-next-line camelcase
            cvss_base_score: 'CVSS Score',
            // eslint-disable-next-line camelcase
            exploit_status: 'Exploit Status',
            // eslint-disable-next-line camelcase
            num_hosts: 'Affected Hosts',

            // eslint-disable-next-line camelcase
            cvss_exploit: 'CVSS Score followed by Exploit Status',
            // eslint-disable-next-line camelcase
            cvss_hosts: 'CVSS Score followed by Affected Hosts',

            // eslint-disable-next-line camelcase
            exploit_cvss: 'Exploit Status followed by CVSS Score',
            // eslint-disable-next-line camelcase
            exploit_hosts: 'Exploit Status followed by Affected Hosts',

            // eslint-disable-next-line camelcase
            hosts_cvss: 'Affected Hosts followed by CVSS Score',
            // eslint-disable-next-line camelcase
            hosts_exploit: 'Affected Hosts followed by Exploit Status',
          },
        },
        {
          type: 'select',
          label: 'Number of Results',
          attribute: 'item_count',
          defaultValue: 25,
          required: true,
          options: {
            10: '10',
            25: '25',
            50: '50',
            100: '100',
          },
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'vulnerabilities',
        },
      ],
    },
    vulnerabilities_top_details: {
      fields: [
        {
          type: 'select',
          label: 'Record Ordering Method',
          attribute: 'order_by',
          defaultValue: 'filtered_risk',
          required: true,
          options: {
            // eslint-disable-next-line camelcase
            filtered_risk: 'Risk',
            // eslint-disable-next-line camelcase
            cvss_base_score: 'CVSS Score',
            // eslint-disable-next-line camelcase
            exploit_status: 'Exploit Status',
            // eslint-disable-next-line camelcase
            num_hosts: 'Affected Hosts',

            // eslint-disable-next-line camelcase
            cvss_exploit: 'CVSS Score followed by Exploit Status',
            // eslint-disable-next-line camelcase
            cvss_hosts: 'CVSS Score followed by Affected Hosts',

            // eslint-disable-next-line camelcase
            exploit_cvss: 'Exploit Status followed by CVSS Score',
            // eslint-disable-next-line camelcase
            exploit_hosts: 'Exploit Status followed by Affected Hosts',

            // eslint-disable-next-line camelcase
            hosts_cvss: 'Affected Hosts followed by CVSS Score',
            // eslint-disable-next-line camelcase
            hosts_exploit: 'Affected Hosts followed by Exploit Status',
          },
        },
        {
          type: 'select',
          label: 'Number of Results',
          attribute: 'item_count',
          defaultValue: 3,
          required: true,
          options: {
            3: '3',
            5: '5',
          },
        },
        {
          type: 'select',
          label: 'Widget Orientation',
          attribute: 'orientation',
          defaultValue: 'vertical',
          required: true,
          options: {
            vertical: 'Vertical',
            horizontal: 'Horizontal',
          },
        },
        {
          type: 'checkbox',
          label: 'Include risk scores and ratings?',
          attribute: 'include_risk',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include Vulnerability Instances Information?',
          attribute: 'include_instances',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include CVSS score(s) and information?',
          attribute: 'include_cvss',
          defaultValue: false,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'vulnerabilities',
        },
      ],
    },
    vulnerabilities_global: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'vulnerabilities',
        },
      ],
    },
    vulnerabilities_total: {
      fields: [
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'vulnerabilities',
        },
      ],
    },

    users_priority: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
        {
          type: 'select',
          label: 'Widget Version',
          attribute: 'version',
          defaultValue: 'table',
          required: true,
          options: {
            table: 'Table',
            list: 'List',
            barchart: 'Bar Chart',
          },
        },
        {
          includeIf: [
            { attribute: 'version', value: 'table' },
            { attribute: 'version', value: 'list' },
          ],
          type: 'select',
          label: 'Rating Version',
          attribute: 'rating_version',
          defaultValue: 'risk',
          required: true,
          options: {
            risk: 'Risk',
            cvss: 'CVSS Score(s)',
            both: 'Both Risk and CVSS',
          },
        },
        {
          type: 'select',
          label: 'Number of Results',
          attribute: 'item_count',
          defaultValue: 25,
          required: true,
          options: {
            10: '10',
            25: '25',
            50: '50',
            100: '100',
          },
        },
        {
          includeIf: { attribute: 'version', value: 'table' },
          type: 'checkbox',
          label: 'Include rating?',
          attribute: 'include_rating',
          defaultValue: true,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'users',
        },
      ],
    },

    // paths variants
    paths_specific: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
        {
          type: 'select',
          label: 'Specific Record Type',
          attribute: 'report_type',
          required: true,
          defaultValue: 'host',
          options: {
            host: 'Host',
            patch: 'Patch',
            vulnerability: 'Vulnerability',
            domain_user: 'User',
          },
        },
        {
          includeIf: { attribute: 'report_type', value: 'host' },
          type: 'searchResults',
          selectCallback: ( result, field, onChange ) => saveRecordLabel( result, field, onChange ),
          attribute: 'record_id_host',
          label: 'Specific Host',
          placeholder: 'Find host by name...',
          recordType: 'scope',
          required: true,
          defaultValue: '',
        },
        {
          includeIf: { attribute: 'report_type', value: 'patch' },
          type: 'searchResults',
          selectCallback: ( result, field, onChange ) => saveRecordLabel( result, field, onChange ),
          attribute: 'record_id_patch',
          label: 'Specific Patch',
          placeholder: 'Find patch by name...',
          recordType: 'patch',
          required: true,
          defaultValue: '',
        },
        {
          includeIf: { attribute: 'report_type', value: 'vulnerability' },
          type: 'searchResults',
          selectCallback: ( result, field, onChange ) => saveRecordLabel( result, field, onChange ),
          attribute: 'record_id_vulnerability',
          label: 'Specific Vulnerability',
          placeholder: 'Find vulnerability by name...',
          recordType: 'vulnerability',
          required: true,
          defaultValue: '',
        },
        {
          includeIf: { attribute: 'report_type', value: 'domain_user' },
          type: 'searchResults',
          selectCallback: ( result, field, onChange ) => saveRecordLabel( result, field, onChange ),
          attribute: 'record_id_user',
          label: 'Specific User',
          placeholder: 'Find user by name...',
          recordType: 'domain_user',
          required: true,
          defaultValue: '',
        },
        {
          type: 'select',
          label: 'Number of Paths',
          attribute: 'item_count',
          required: true,
          defaultValue: 3,
          options: {
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
          },
        },
      ],
    },
    paths_global: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
        {
          type: 'select',
          label: 'Number of Paths',
          attribute: 'item_count',
          required: true,
          defaultValue: 5,
          options: {
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
          },
        },
      ],
    },

    // vuln. instances variants
    vulnerability_instances_global: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
        {
          type: 'select',
          label: 'Widget Version',
          attribute: 'version',
          defaultValue: 'full',
          required: true,
          options: {
            full: 'Full Version',
            truncated: 'Truncated Version (3 main categories only)',
          },
        },
      ],
    },
    vulnerability_instances_exploit_status_breakdown: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
      ],
    },
    vulnerability_instances_cvss_breakdown: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
        {
          type: 'select',
          label: 'Widget Version',
          attribute: 'version',
          defaultValue: 'barchart',
          required: true,
          options: {
            barchart: 'Bar Chart',
            donut: 'Donut Chart',
          },
        },
      ],
    },
    vulnerability_instances_tag_breakdown: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
      ],
    },
    vulnerability_instances_os_family_breakdown: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
      ],
    },
    vulnerability_instances_vulnerability_age_breakdown: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
      ],
    },
    vulnerability_instances_table: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
        {
          type: 'select',
          label: 'Group Instances by',
          attribute: 'group_by',
          defaultValue: 'host',
          required: true,
          options: {
            host: 'Host',
            patch: 'Patch',
            vulnerability: 'Vulnerability',
            signature: 'Signature',
          },
        },
        {
          type: 'select',
          label: 'Number of Results',
          attribute: 'item_count',
          defaultValue: 25,
          required: true,
          options: {
            10: '10',
            25: '25',
            50: '50',
            100: '100',
          },
        },
      ],
    },
    vulnerability_instances_category: {
      fields: [
        // {
        //   type: 'checkbox',
        //   label: 'Include Description?',
        //   attribute: 'include_description',
        //   defaultValue: false,
        //   required: false,
        // },
        {
          type: 'select',
          label: 'Instances Category',
          attribute: 'category',
          defaultValue: 'carries_risk',
          required: true,
          options: {
            deprioritized: 'Deprioritized',
            dos_only: 'Purely Denial of Service',
            not_exploitable: 'Mitigated',
            overridden: 'Overridden',

            for_review: 'For Review',
            unsupported: 'Unsupported',
            unrecognized: 'Unrecognized',
            missing_host: 'Not Scanned by DeepSurface',
            cannot_model: 'Cannot Model',
            insufficient_information: 'Insufficient Information',
            missing_capability: 'Missing Capability',

            prioritized: 'Prioritized',
            unreachable: 'No Attack Path',
            carries_risk: 'Carries Risk',
          },
        },
      ],
    },

    // risk variants
    risk_score: {
      fields: [
        // {
        //   type: 'checkbox',
        //   label: 'Include Description?',
        //   attribute: 'include_description',
        //   defaultValue: false,
        //   required: false,
        // },
      ],
    },
    risk_peer_percentile: {
      fields: [
        // {
        //   type: 'checkbox',
        //   label: 'Include Description?',
        //   attribute: 'include_description',
        //   defaultValue: false,
        //   required: false,
        // },
      ],
    },
    risk_global: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
        {
          type: 'select',
          label: 'Record Type',
          attribute: 'report_type',
          defaultValue: 'hosts',
          required: true,
          options: {
            hosts: 'Hosts',
            patches: 'Patches',
            vulnerabilities: 'Vulnerabilities',
          },
        },
      ],
    },
    risk_over_time: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include Instance Changes Chart?',
          attribute: 'include_escalations',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include Host Changes Chart?',
          attribute: 'include_hosts',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include Patch Tuesday Indicator?',
          attribute: 'patch_tuesday',
          defaultValue: false,
          required: false,
        },
      ],
    },
    risk_peer_percentile_over_time: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
      ],
    },
    risk_target: {
      fields: [
        // {
        //   type: 'checkbox',
        //   label: 'Include Description?',
        //   attribute: 'include_description',
        //   defaultValue: false,
        //   required: false,
        // },
      ],
    },

    // remediation variants
    remediation_plans: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
      ],
    },
    remediation_specific_plan: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
        {
          type: 'select',
          label: 'Specific Plan',
          attribute: 'plan_id',
          defaultValue: '',
          options: {},
          required: true,
        },
      ],
    },

    scanning_age_breakdown: {
      fields: [
        {
          type: 'select',
          label: 'Scanning Method',
          attribute: 'scanning_type',
          defaultValue: 'agent',
          required: true,
          options: {
            agent: 'User Managed/Agent',
            credentialed: 'Credentialed',
          },
        },
      ],
    },

    scanning_scan_group_breakdown: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
      ],
    },

    scanning_agent_version_breakdown: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: false,
          required: false,
        },
      ],
    },
    scanning_total: {
      fields: [
        {
          type: 'select',
          label: 'Scanning Method',
          attribute: 'scanning_type',
          defaultValue: 'agent',
          required: true,
          options: {
            agent: 'User Managed/Agent',
            credentialed: 'Credentialed',
            both: 'Both',
          },
        },
      ],
    },

    // text variants
    text_free_form: {
      fields: [
        {
          type: 'text',
          label: 'Header',
          attribute: 'heading',
          placeholder: 'Header (optional)',
          defaultValue: '',
        },
        {
          type: 'textarea',
          label: 'Text',
          attribute: 'content',
          defaultValue: '',
        },
      ],
    },
  };
  /* eslint-enable camelcase */

  const saveRecordLabel = ( result, field, onChange ) => {
    if ( isNotEmpty( result ) ) {
      if ( result.identifier ) {
        if ( result.vendor ) {
          setRecordLabel( `${result.vendor} ${result.identifier}` );
        } else {
          setRecordLabel( result.identifier );
        }
        setRecordLabel( result.label );
      }
    }
    onChange( field, result.id );
  };

  React.useEffect( () => {
    if ( isNotEmpty( selectedWidgetCategory ) && isNotEmpty( selectedWidgetCategory.options ) ) {
      setVariantOptions( selectedWidgetCategory.options );
      setSelectedWidgetVariant( Object.values( selectedWidgetCategory.options )[0] );

      const _fields = EMPTY_VARIANT_FIELDS[Object.values( selectedWidgetCategory.options )[0].key];

      if ( isNotEmpty( _fields ) && isNotEmpty( _fields.fields ) ) {

        // work through all the assetTag data and set filter options
        if ( isNotEmpty( tags ) ) {
          const tagFilter = _fields.fields.find( i => i.attribute === 'asset_tag_ids' );

          if ( isNotEmpty( tagFilter ) ) {
            tagFilter.options = tags;
          }
        }
        setVariantFields( _fields.fields );
      } else {
        setVariantFields( null );
      }
    } else {
      setVariantOptions( null );
      setSelectedWidgetVariant( null );
      setVariantFields( null );
    }
  }, [ selectedWidgetCategory, tags ] );

  const handleVariantClick = variant => {
    if ( isNotEmpty( variant ) ) {
      if ( isNotEmpty( selectedWidgetVariant ) && selectedWidgetVariant.key === variant.key ) {
        setSelectedWidgetVariant( null );
        setVariantFields( null );
      } else {
        setSelectedWidgetVariant( variant );
        const _fields = EMPTY_VARIANT_FIELDS[variant.key];

        if ( isNotEmpty( _fields ) && isNotEmpty( _fields.fields ) ) {
          // work through all the assetTag data and set filter options
          if ( isNotEmpty( tags ) ) {
            const tagFilter = _fields.fields.find( i => i.attribute === 'asset_tag_ids' );

            if ( isNotEmpty( tagFilter ) ) {
              tagFilter.options = tags;
            }
          }

          // for the specific remediation plan widget, need to populate the options
          if ( variant.key === 'remediation_specific_plan' ) {
            if ( isNotEmpty( remediationPlanOptions ) ) {
              const planIDField = _fields.fields.find( i => i.attribute === 'plan_id' );

              if ( isNotEmpty( planIDField ) ) {
                planIDField.options = remediationPlanOptions;
              }
            } else {
              const planIDField = _fields.fields.find( i => i.attribute === 'plan_id' );
              if ( isNotEmpty( planIDField ) ) {
                makeRequest( 'LIST', '/project/default/model/base/remediation_plan', {} ).then( response => {
                  if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
                    const options = {};

                    response.results.map( r => {
                      options[r.id] = r.label;
                    } );

                    planIDField.options = options;

                    setRemediationPlanOptions( options );
                  }
                } );
              }
            }
          } else {
            setVariantFields( _fields.fields );
          }
          setVariantFields( _fields.fields );
        } else {
          setVariantFields( null );
        }
      }
    }
  };

  // if a widget is being edited, populate the form accordingly
  React.useEffect( () => {
    if ( isNotEmpty( currentWidget ) ) {
      const _fields = EMPTY_VARIANT_FIELDS[currentWidget.key];


      if ( isNotEmpty( _fields ) && isNotEmpty( _fields.fields ) ) {
        if ( isNotEmpty( tags ) ) {
          const tagFilter = _fields.fields.find( i => i.attribute === 'asset_tag_ids' );

          if ( isNotEmpty( tagFilter ) ) {
            tagFilter.options = tags;
          }
        }

        // for the specific remediation plan widget, need to populate the options
        if ( currentWidget.key === 'remediation_specific_plan' ) {
          if ( isNotEmpty( remediationPlanOptions ) ) {
            const planIDField = _fields.fields.find( i => i.attribute === 'plan_id' );

            if ( isNotEmpty( planIDField ) ) {
              planIDField.options = remediationPlanOptions;
            }
          } else {
            const planIDField = _fields.fields.find( i => i.attribute === 'plan_id' );
            if ( isNotEmpty( planIDField ) ) {
              makeRequest( 'LIST', '/project/default/model/base/remediation_plan', {} ).then( response => {
                if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
                  const options = {};

                  response.results.map( r => {
                    options[r.id] = r.label;
                  } );

                  planIDField.options = options;

                  setRemediationPlanOptions( options );
                }
              } );
            }
          }
        } else {
          setVariantFields( _fields.fields );
        }

      }
    } else {
      setSelectedWidgetVariant( null );
      setVariantFields( null );
    }
  }, [ currentWidget ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( variantOptions ) ) &&
        <div className="widgetVariantSelector">
          <h4>Available Widgets</h4>
          <ul>
            {
              Object.values( variantOptions ).map( ( variant, index ) => {
                return <li
                  key={index}
                  onClick={ () => handleVariantClick( variant ) }
                  // eslint-disable-next-line max-len
                  className={ `widgetVariantItem ${ isNotEmpty( selectedWidgetVariant ) && selectedWidgetVariant.key === variant.key ? 'selected' : ''}`}
                >
                  { variant.label }
                </li>;
              } )
            }
          </ul>
        </div>

      }
      {
        isNotEmpty( selectedWidgetVariant ) &&
        <div className="widgetFormAndPreviewWrapper">
          <h4>{ selectedWidgetVariant.label } Settings</h4>
          {
            isNotEmpty( variantFields )
              ? <Form
                existingRecord={ isNotEmpty( currentWidget ) ? currentSettings[currentWidget.i] : null }
                fields={variantFields}
                recordType={ 'widget' }
                onChangeCallback={ setUpdatedSettings }
              />
              : <EmptyState message="No settings for this widget" />
          }
        </div>
      }
    </React.Fragment>
  );
};

const WidgetSelectorAction = ( {
  addWidget,
  selectedWidgetVariant,
  closeWidgetEditor,
  recordLabel,
  currentWidget,
  updatedSettings,
} ) => {

  const handleAddWidget = () => {

    // two cases, we are updating an existing widget or we are adding a new widget, need to setup the add a bit
    // differently in each case

    // updating an existing widget, just need to pass along the id and update the settings
    if ( isNotEmpty( currentWidget ) && isNotEmpty( updatedSettings ) && isNotEmpty( currentWidget.i ) ) {
      const settings = getFieldValues( updatedSettings.fieldStates, 'widget' );
      if ( isNotEmpty( recordLabel ) ) {
        // eslint-disable-next-line camelcase
        settings.record_label = recordLabel;
      }
      addWidget( currentWidget.i, null, settings );
      closeWidgetEditor();
    // adding a new widget, need to pass over the args a bit differently to the addWidget function
    } else if ( isNotEmpty( selectedWidgetVariant ) ) {
      const widget = { ...selectedWidgetVariant };

      if ( isNotEmpty( updatedSettings ) ) {
        const settingsValues = getFieldValues( updatedSettings.fieldStates, 'widget' );

        const settings = { ...selectedWidgetVariant.settings };

        if ( isNotEmpty( settingsValues ) ) {
          Object.entries( settingsValues ).map( ( [ attr, val ] ) => {
            settings[attr] = val;
          } );
        }

        if ( isNotEmpty( recordLabel ) ) {
          // eslint-disable-next-line camelcase
          settings.record_label = recordLabel;
        }

        addWidget( null, widget, settings );
        closeWidgetEditor();
      } else {
        const minMax = v2WidgetMinMaxDimensions[ currentWidget?.key ];
        let widget = { ...selectedWidgetVariant };

        if ( isNotEmpty( minMax ) ) {
          widget ={ ...widget, ...minMax };
        }
        addWidget( null, widget, {} );
        closeWidgetEditor();
      }
    }
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( selectedWidgetVariant ) &&
        <button
          onClick={ handleAddWidget }
        >
          {
            isNotEmpty( currentWidget )
              ? 'Update Widget'
              : 'Add Widget'
          }
        </button>
      }
    </React.Fragment>
  );
};

const WidgetEditorModal = ( {
  showWidgetEditor,
  setShowWidgetEditor,
  selectedWidgetVariant,
  setSelectedWidgetVariant,
  selectedWidgetOptions,
  setSelectedWidgetOptions,
  selectedWidgetCategory,
  recordLabel,
  setRecordLabel,
  addWidget,
  currentSettings,
  closeWidgetEditor,
  setSelectedWidgetCategory,
  currentWidget,
  setCurrentWidget,
} ) => {

  const [ updatedSettings, setUpdatedSettings ] = React.useState( null );

  React.useEffect( () => {
    if ( showWidgetEditor === false ) {
      setSelectedWidgetCategory( null );
      setShowWidgetEditor( false );
      setCurrentWidget( null );
      setSelectedWidgetVariant( null );
      setRecordLabel( null );
    }
  }, [ showWidgetEditor ] );

  return (
    <Modal
      visible={showWidgetEditor}
      setVisible={setShowWidgetEditor}
      elementClass={ `widgetEditorAndSelctorModal ${ isNotEmpty( currentWidget ) ? 'editMode' : '' }` }
      body={ <WidgetSelectorBody
        selectedWidgetVariant={ selectedWidgetVariant }
        setSelectedWidgetVariant={ setSelectedWidgetVariant }
        selectedWidgetOptions={ selectedWidgetOptions }
        setSelectedWidgetOptions={ setSelectedWidgetOptions }
        selectedWidgetCategory={ selectedWidgetCategory }
        setRecordLabel={ setRecordLabel }
        currentWidget={ currentWidget }
        currentSettings={ currentSettings }
        setUpdatedSettings={ setUpdatedSettings }
      /> }
      action={ <WidgetSelectorAction
        addWidget={ addWidget }
        selectedWidgetVariant={ selectedWidgetVariant }
        setSelectedWidgetVariant={ setSelectedWidgetVariant }
        selectedWidgetOptions={ selectedWidgetOptions }
        setSelectedWidgetOptions={ setSelectedWidgetOptions }
        closeWidgetEditor={ closeWidgetEditor }
        recordLabel={ recordLabel }
        currentWidget={ currentWidget }
        updatedSettings={ updatedSettings }
      /> }
    />
  );
};

export default WidgetEditorModal;