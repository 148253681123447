/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../shared/Utilities';
import RatingBadge from '../../../../shared/RatingBadge';
import { ADVERSARY_NODE } from '../../Graph/shared';
import { getNodeIcon } from '../../../RiskInsight/Detail/shared';
import { getNodeIconDescription } from '../shared';

const NodeHeader = ( { node } ) => {

  return (
    <React.Fragment>
      {
        isNotEmpty( node ) &&
        <React.Fragment>
          <div className="recordCardHeaderRow">
            <h2>
              <svg
                width={ 24 }
                height={ 24 }
                // eslint-disable-next-line max-len
                viewBox="0 0 32 32"
                fill="none"
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                className={ `recordTypeHeaderIcon ${node.id === ADVERSARY_NODE.id ? 'adversaryNode' : '' }` }
              >
                { getNodeIcon( node ) }
              </svg>
              <span className="label">
                { node.label }
              </span>
            </h2>
            <RatingBadge rating={ node.rating } />
          </div>
          <p className="iconDescription">
            {
              getNodeIconDescription( node ).map( ( section, i ) => {
                return <React.Fragment key={i}>{ section }</React.Fragment>;
              } )
            }
          </p>
        </React.Fragment>

      }
    </React.Fragment>
  );
};

export default NodeHeader;