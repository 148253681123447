/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  decodeURLHash,
  isNotEmpty,
  recordSorter,
  getGlobalSettings,
  formatTimeDuration,
} from '../../../shared/Utilities';
import { TasksContext } from '../../../Contexts/Tasks';
import TaskButton from './TaskButton';
import { formatUnixTime } from '../../../../../../login/javascript/LegacyFunctions';
import { format_tz_offset } from '../../../../legacy/dom';
import { HelpTrigger } from '../../../components/HelpDocumentation/ContextualHelp/index.js';
import { OnboardingWizardContext } from '../../../Contexts/OnboardingWizard';
import JobHistoryModal from './JobHistoryModal';
import { makeRequest } from '../../../../legacy/io';
import InlineSVG from '../../../shared/InlineSVG';
import ToolTip from '../../../shared/ToolTip';
import Notification from '../../../shared/Notification';

const TaskStatus = ( { task, warnings } ) => {

  const [ status, setStatus ] = React.useState( '' );
  const [ taskWarnings, setTaskWarnings ] = React.useState( [] );

  React.useEffect( ( ) => {
    if ( isNotEmpty( task?.current?.status ) ) {
      setStatus( task.current?.status );
    }

    if ( isNotEmpty( warnings ) && isNotEmpty( task?.taskIdentifier ) && isNotEmpty( warnings[task.taskIdentifier] ) ) {
      setTaskWarnings( warnings[task.taskIdentifier] );
    }

  }, [ task, warnings ] );

  const needsAlert = () => status === 'error' || isNotEmpty( taskWarnings );
  return (
    <React.Fragment>
      {
        status === 'queued' &&
        <InlineSVG type="queue" elementClass="queued" />
      }
      {
        status === 'completed' &&
        <InlineSVG type="checkmarkCircle" version="status--green" />
      }
      {
        status === 'running' &&
        <InlineSVG type="cycle" version="status--blue" elementClass="running" />
      }
      {
        needsAlert() &&
        <React.Fragment>
          {
            ( status !== 'running' && status !== 'completed' && status !== 'queued' ) &&
            <ToolTip
              triggerOnClick
              draggable
              y="center"
              x="right"
              trigger={ <InlineSVG type="warning" version="status--red" /> }
              content={ <Notification options={ { type: 'alert', message: taskWarnings } }/>}
            />
          }
        </React.Fragment>

      }
    </React.Fragment>
  );
};

const TaskList = ( ) => {

  // eslint-disable-next-line no-unused-vars
  const [ tasks, setTasks ] = React.useContext( TasksContext );

  const [ taskItems, setTaskItems ] = React.useState( {} );
  const [ scanners, setScanners ] = React.useState( {} );
  const [ scanGroups, setScanGroups ] = React.useState( {} );
  const [ currentTime, setCurrentTime ] = React.useState( null );

  const [ serverTimeZone, setServerTimeZone ] = React.useState( '' );

  const [ warnings, setWarnings ] = React.useState( {} );

  // ContextualHelp getters and setters
  const [ , , refreshWizard, , , , , , , ] = React.useContext( OnboardingWizardContext );

  // cannonical order of how the tasks should be displayed on the page
  const availableTasks = [
    'feed',
    'authenticated',
    'process',
    'import',
    'analysis',
    'upgrade',
  ];

  const getTitle = title => {
    if ( title === 'Authenticated Scan' ) {
      return 'Credentialed Scan';
    } else if ( title === 'External Vulnerability Scanner' ) {
      return 'Import Vulnerability Scanner Data';
    }
    return title;

  };

  const getHelpKey = taskIdentifier => {
    if ( taskIdentifier === 'authenticated' ) {
      return 'agentless_scan';
    } else if ( taskIdentifier === 'process' ) {
      return 'process_queue';
    }
    return taskIdentifier;
  };

  // var for the polling that will get cleared on cleanup
  let listPoll = null;

  // on page load, grab all the data needed, set up the polling, clear out on unmount
  React.useEffect( () => {
    let isMounted = true;

    initialize();

    clearInterval( listPoll );

    if ( isMounted ) {
      refreshTasks();
    }

    listPoll = setInterval( function() {
      refreshTasks();
    }, 6000 );

    return () => {
      isMounted = false;
      clearInterval( listPoll );
    };
  }, [] );

  // get all the warnings anytime the tasks change
  React.useEffect( () => {
    if ( isNotEmpty( tasks ) ) {
      const _warnings = {};

      if ( isNotEmpty( tasks.current ) ) {
        Object.entries( tasks.current ).map( ( [ taskKey, task ] ) => {
          const _taskWarnings = task.warnings || [];

          if ( isNotEmpty( task.exception ) ) {
            _taskWarnings.push( task.exception );
          }

          _warnings[taskKey] = _taskWarnings;
        } );
      }
      setWarnings( _warnings );
    }
  }, [ tasks ] );

  React.useEffect( () => {
    getGlobalSettings( 'global' ).then( globalSettings => {
      const tz = globalSettings.server_tz;
      const offset = globalSettings.server_tzoffset;

      setServerTimeZone( `${tz} (UTC${format_tz_offset( offset )})` );
    } );
  }, [  ] );

  const initialize = async () => {

    const _scanGroups = {};
    const _scanners = {};
    const credIDs = {};

    // grab and format the scan groups
    let scanGroupsResponse = ( await ( makeRequest( 'FIND', '/project/default/scan_group', {} ) ) ).results;

    if ( isNotEmpty( scanGroupsResponse ) ) {
      scanGroupsResponse = scanGroupsResponse.sort( ( a, b ) => recordSorter( 'label', true, a, b ) );
    }

    scanGroupsResponse.map( sg => {
      _scanGroups[sg.id] = sg;
    } );

    // grab and format the vuln scanners
    // eslint-disable-next-line max-len
    const vulnerabilityScannersResponse = (
      await ( makeRequest( 'FIND', '/project/default/third_party_setting', { } ) )
    ).results;

    vulnerabilityScannersResponse.map( v => {
      if ( isNotEmpty( v.settings ) && isNotEmpty( v.settings.credential_id ) ) {
        credIDs[v.settings.credential_id] = v.id;
      }
      _scanners[v.id] = v;
    } );

    // grab the creds and add attrs to appropriate scanner
    // eslint-disable-next-line max-len
    const credentials = ( await ( makeRequest( 'FETCH', '/project/default/credential', { ids: Object.keys( credIDs ) } ) ) ).results;

    credentials.map( c => {
      const scanner = _scanners[credIDs[c.id]];

      const currentSettings = scanner.settings;

      scanner.settings = { ...c, currentSettings };

      delete scanner.settings.id;

    } );

    setScanGroups( _scanGroups );
    setScanners( _scanners );
  };

  const refreshTasks = async () => {

    const _tasks = {};

    const taskResponse = ( await ( makeRequest( 'LIST', '/task' ) ) ).results;
    const statusResponse = ( await ( makeRequest( 'STATUS', '/task', { project: 'default' } ) ) ).results;

    setCurrentTime( statusResponse.current_time );

    setTasks( statusResponse );

    availableTasks.map( taskID => {
      let taskKey = taskID;
      if ( taskID === 'process' ) {
        taskKey = 'authenticated';
      }
      _tasks[taskID] = {
        taskIdentifier: taskID,
        current: {},
        scheduled: [],
        ...taskResponse[taskKey],
      };

      if ( taskID === 'process' ) {
        _tasks.process.title = 'Process Scan Queue';
      }
    } );

    availableTasks.map( taskID => {
      let taskKey = taskID;
      if ( taskID === 'process' ) {
        taskKey = 'authenticated';
      }
      _tasks[taskID].current = statusResponse.current[taskKey] || {};
      _tasks[taskID].scheduled = statusResponse.scheduled[taskKey] || [];
    } );

    setTaskItems( _tasks );

    const parsedHash = decodeURLHash();

    if ( parsedHash.onboarding_step ) {
      if ( parsedHash.onboarding_step === '5' ) {
        _tasks.authenticated.focusForOnboarding = true;
      } else if ( parsedHash.onboarding_step === '4' ) {
        _tasks.process.focusForOnboarding = true;
      }
    }
    setTaskItems( _tasks );
    refreshWizard( false );
  };

  const additionalOptionsFor = type => {
    if ( type === 'analysis' ) {
      return (
        { base: { label: 'Base Model' } }
      );
    }
    if ( type === 'authenticated' ) {
      return scanGroups;
    }
    if ( type === 'import' ) {
      return scanners;
    }
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( taskItems ) && isNotEmpty( currentTime ) ) &&
          <ul className="setupRecordsList">
            {
              Object.values( taskItems ).map( ( t, i ) => {
                return  <li
                  // eslint-disable-next-line max-len
                  className={`setupRecordItem task_item alternateLayout    ${ t.focusForOnboarding ? 'onboardingWizardFocus' : '' }`}
                  key={i}
                  id={`${t.taskIdentifier}Task`}
                >
                  <section className="mainDetailsSection">
                    <div className="column">
                      <h2>
                        <TaskStatus task={t} warnings={ warnings } />
                        { getTitle( t.title ) }
                        <HelpTrigger helpKey={ getHelpKey( t.taskIdentifier )} />
                      </h2>
                      <span>
                        <strong>Current/Recent Jobs: </strong>
                        <ul>
                          {
                            isNotEmpty( t.current )
                              ? <ul>
                                {
                                  t.current.ended &&
                                          <li>
                                            <strong>Completed at:</strong>
                                            <span>{ formatUnixTime( t.current.ended ) }</span>
                                          </li>
                                }
                                {
                                  ( t.current.started ) &&
                                          <li>
                                            <strong>Duration:</strong>
                                            <span>
                                              {/* eslint-disable-next-line max-len */}
                                              { formatTimeDuration( ( t.current.ended ? t.current.ended : currentTime ) - t.current.started ) }
                                            </span>
                                          </li>
                                }
                                {
                                  (
                                    t.current.started
                                            && t.id === 'authenticated'
                                            && isNotEmpty( scanGroups )
                                            && isNotEmpty( t.current.work_items
                                            && t.taskIdentifier !== 'process',
                                            ) ) &&
                                          <li>
                                            <strong>Included Scan Groups:</strong>
                                            <span>
                                              {/* eslint-disable-next-line max-len */}
                                              { t.current.work_items.map( item => scanGroups[item] ? scanGroups[item].label : '[Deleted Scan Group]' ).join( ', ' ) }
                                            </span>
                                          </li>
                                }
                              </ul>
                              : <span>N/A</span>
                          }
                        </ul>
                      </span>
                    </div>
                  </section>
                  <section>
                    <strong className="sectionLabel">
                      Scheduled Jobs
                    </strong>
                    <span className="sectionDetails">
                      <ul>
                        {
                          ( isNotEmpty( t.scheduled ) && t.taskIdentifier !== 'process' )
                            ? <ul>
                              {
                                t.scheduled.map( ( task, index ) => {
                                  return  <li
                                    key={index}
                                  >
                                    {
                                      // eslint-disable-next-line max-len
                                      ( t.id === 'authenticated' && isNotEmpty( scanGroups ) && isNotEmpty( scanGroups[task.work_items[0]] ) ) &&
                                                      <strong>
                                                        { scanGroups[task.work_items[0]].label}
                                                      </strong>
                                    }
                                    { `${formatUnixTime( task.next_run_time )} ${serverTimeZone}`}
                                  </li>;
                                } )
                              }
                            </ul>
                            : <span>N/A</span>
                        }
                      </ul>
                    </span>
                  </section>
                  <section>
                    {
                      ( t.taskIdentifier === 'authenticated' || t.taskIdentifier === 'import' ) &&
                      <React.Fragment>
                        <strong className="sectionLabel">
                          Job History
                        </strong>
                        <span className="sectionDetails">
                          <JobHistoryModal
                            task={t}
                            scanGroups={scanGroups}
                            serverTimeZone={serverTimeZone}
                            currentTime={currentTime}
                          />
                        </span>
                      </React.Fragment>
                    }
                  </section>
                  <div className="itemActions withTaskButton">
                    <TaskButton
                      task={t}
                      taskType={ t.taskIdentifier }
                      recordOptions={ additionalOptionsFor( t.taskIdentifier ) }
                      tasks={taskItems}
                      refreshTasks={refreshTasks}
                      warnings={warnings}
                    />
                  </div>
                </li>;
              } )
            }
          </ul>
      }
    </React.Fragment>
  );
};

export default TaskList;