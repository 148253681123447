/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  capitalize,
  debounce,
  formatNumber,
  // formatUnixDate,
  getDimensionsAndOffset,
  getRiskTrend,
  globalColors,
  isEmpty,
  isNotEmpty,
} from '../../../../../shared/Utilities';
import Loading from '../../../../../shared/Loading';
import EmptyState from '../../../../../shared/EmptyState';
import Modal from '../../../../../shared/Modal';
import Area from '../../../../../shared/Charts/Area';
import CenterBar from '../../../../../shared/Charts/CenterBar';

import { v4 as uuidv4 } from 'uuid';
import InlineSVG from '../../../../../shared/InlineSVG';
import Accordion from '../../../../../shared/Accordion';

import './RiskOverTimeV2.scss';
import Xaxis from '../../../../../shared/Charts/Axis/Xaxis';
import { globalRiskColor } from '../../../../../shared/Charts/shared';
import ChartHoverIndicators from '../../../../../shared/Charts/ChartHoverIndicators';
import { HelpTrigger } from '../../../../HelpDocumentation/ContextualHelp/index.js';
import { v2Fetches } from '../../shared';

// vertical lines representing a patch tuesday during the period of time represented on the graph
const PatchTuesdaysOverlay = ( { data, containerHeight, containerWidth } ) => {

  const [ dataPoints, setDataPoints ] = React.useState( null );

  const svgHeight = containerHeight - 30;
  const svgWidth = containerWidth - 10;
  const xGutter = ( containerWidth - svgWidth ) / 2;
  const yGutter = ( containerHeight - svgHeight ) / 2;

  React.useEffect( ( ) => {
    const { startDate, endDate, patchTuesdaysInRange } = data;

    const _dataPoints = [];

    if ( isNotEmpty( startDate ) && isNotEmpty( endDate ) && isNotEmpty( patchTuesdaysInRange ) ) {
      const widthRatio = svgWidth / ( endDate - startDate );
      patchTuesdaysInRange.map( timestamp => {
        const x = ( ( timestamp - startDate ) * widthRatio ) + xGutter;

        _dataPoints.push( { x, timestamp } );
      } );

      setDataPoints( _dataPoints );
    }
  }, [ data, containerHeight, containerWidth ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( dataPoints ) &&
        <React.Fragment>
          {
            dataPoints.map( ( p, i ) => {
              return <g
                className="patchTuesdayGroup"
                key={i}
              >
                <line
                  x1={ p.x || 0 }
                  x2={ p.x || 0 }
                  y1={ 0 }
                  y2={ svgHeight - yGutter }
                  stroke={ globalColors.primaryBlue }
                  strokeDasharray="8 4"
                  strokeWidth={ 2 }
                />
              </g>;
            } )
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

// used elsewhere, needs to export
export const CurrentRisk = ( {
  currentPoint,
  allPoints,
  standAlone=false,
  targetRisk,
} ) => {
  const [ currentRisk, setCurrentRisk ] = React.useState( null );
  const [ delta, setDelta ] = React.useState( null );
  const [ deltaDirection, setDeltaDirection ] = React.useState( 'down' );
  // eslint-disable-next-line
  const [ chartData, setChartData ] = React.useState( null );
  // eslint-disable-next-line
  const [ chartColor, setChartColor ] = React.useState( 'darkBlue' );
  const [ deltaPercent, setDeltaPercent ] = React.useState( '0.00%' );
  // eslint-disable-next-line
  const [ riskDescription, setRiskDescription ] = React.useState( '' );

  React.useEffect( ( ) => {

    if ( isNotEmpty( allPoints ) && isNotEmpty( currentPoint ) && isNotEmpty( targetRisk ) ) {
      const riskTrend = getRiskTrend( currentPoint, allPoints, targetRisk );
      setDelta( riskTrend.delta );
      setDeltaDirection( riskTrend.deltaDirection );
      setDeltaPercent( riskTrend.deltaPercent );
      setRiskDescription( riskTrend.riskDescription );
      setCurrentRisk( riskTrend.currentRisk );
      setChartColor( riskTrend.chartColor );
    } else {
      setDelta( 0 );
      setDeltaDirection( 'flat' );
      setDeltaPercent( '0.0%' );
      setRiskDescription( '' );
    }
  }, [ currentPoint, allPoints, targetRisk ] );

  return (
    <div
      className={ `currentRiskWrapper ${globalRiskColor( currentRisk, targetRisk )} ${standAlone ? '' : 'embedded'}` }
    >
      <div className="riskScoreWrapper">
        <div className="score">
          <h3>
            Risk score
            <HelpTrigger helpKey="risk_score" />
          </h3>
          <h4 className={ globalRiskColor( currentRisk, targetRisk ) }>
            { formatNumber( currentRisk ) }
          </h4>
        </div>
        <div className={`deltaWrapper ${globalRiskColor( currentRisk, targetRisk )}`}>
          <span className="trendIcon">
            {
              deltaDirection !== 'flat'
                ? <InlineSVG
                  type={ `trending${ deltaDirection === 'up' ? 'Up' : 'Down' }` }
                  version={ deltaDirection === 'up' ? 'risk--red' : 'risk--green' }
                />
                : <span>--</span>
            }
          </span>
          <span className="deltaAmount">
            {
              delta > 0
                ? formatNumber( delta )
                : '--'
            }
          </span>
          <span className="deltaPercent">
            ({ deltaPercent })
          </span>
        </div>
      </div>
      <PointSummary point={currentPoint} fullDetails={false} />
    </div>
  );
};

const PointSummary = ( {
  point,
  fullDetails=true,
  sectionCollapse,
  toggleSectionCollapse,
} ) => {

  const [ hostChanges, setHostChanges ] = React.useState( {} );
  const [ domainChanges, setDomainChanges ] = React.useState( {} );
  const [ userChanges, setUserChanges ] = React.useState( {} );
  const [ escalationChanges, setEscalationChanges ] = React.useState( {} );
  // eslint-disable-next-line
  const [ original, setOriginal ] = React.useState( null );

  const userLabelMap = key => {
    if ( key === 'escalation' ) {
      return 'instance';
    }

    if ( key === 'escalation_details' ) {
      return 'instance_details';
    }

    if ( key === 'add' ) {
      return 'added';
    }if ( key === 'update' ) {
      return 'updated';
    }if ( key === 'delete' ) {
      return 'deleted';
    }
    return key;
  };

  const escalationSentence = type => {
    if ( type === 'added' ) {
      /* eslint-disable max-len */
      return `Vulnerability scanners discovered ${escalationChanges.added_escalations} new instances of ${escalationChanges.added_escalations_vulnerabilities} vulnerabilities affecting a total of ${escalationChanges.added_escalations_hosts} hosts.`;
    }
    return `Vulnerability scanner results indicate that ${escalationChanges.removed_escalations} instances of ${escalationChanges.removed_escalations_vulnerabilities} vulnerabilities no longer exist. This appears to improve the security of ${escalationChanges.removed_escalations_hosts} hosts.`;
    /* eslint-enable max-len */
  };

  React.useEffect( () => {
    setHostChanges( {} );
    setDomainChanges( {} );
    setUserChanges( {} );
    setEscalationChanges( {} );
    if ( isNotEmpty( point ) ) {


      let _original;

      if ( point.original ) {
        _original = point.original;
      }

      if ( _original.original ) {
        _original = _original.original;
      }

      setOriginal( _original );

      const hostAdd = [];
      const hostRemove = [];
      const domainAdd = [];
      const domainRemove = [];

      // host and domain details
      if ( isNotEmpty( _original.scan_events ) ) {
        _original.scan_events.map( event => {
          if ( event.event === 'expired' && event.record_type === 'host' ) {
            hostRemove.push( {
              name: event.details.record.local_name,
              id: event.details.record.id,
            } );
          }

          if ( event.event === 'identified' && event.record_type === 'host' ) {
            hostAdd.push( { name: event.details.record.local_name, id: event.details.record.id } );
          }

          if ( event.event === 'expired' && event.record_type === 'windows_domain' ) {
            domainRemove.push( { name: event.details.record.name, id: event.details.record.id } );
          }

          if ( event.event === 'identified' && event.record_type === 'windows_domain' ) {
            domainAdd.push( { name: event.details.record.name, id: event.details.record.id } );
          }
        } );

        setHostChanges( {
          identified: hostAdd,
          expired: hostRemove,
        } );

        setDomainChanges( {
          identified: domainAdd,
          expired: domainRemove,
        } );
      }

      // user Changes
      if ( isNotEmpty( _original.user_changes ) ) {
        setUserChanges( _original.user_changes );
      }

      // escalation changes
      if ( isNotEmpty( _original.import_events ) ) {
        setEscalationChanges( _original.import_events );
      }

    } else {
      setHostChanges( {} );
      setDomainChanges( {} );
      setUserChanges( {} );
      setEscalationChanges( {} );
    }
  }, [ point ] );

  const getBarWidth = ( thisAmount, otherAmount ) => {
    let max = 0;
    if ( isEmpty( thisAmount ) ) {
      return 0;
    }
    if ( isEmpty( otherAmount ) ) {
      max = thisAmount;
      return 100;
    }
    max = Math.max( thisAmount || 0, otherAmount || 0 );
    const percent = ( thisAmount / max ) * 100;
    if ( percent < 5 ) {
      return 5;
    }
    return percent;
  };

  return (
    <React.Fragment>
      {/* the mini version of everything */}
      {
        !fullDetails &&
        <div className="pointChangesContainer" >
          <div className="changesHeader">
            <span>Removed</span>
            <span />
            <span>Added</span>
          </div>
          <div className="pointChangeWrapper instance">
            <strong>Instances</strong>
            <div className="barAndLabelWrapper removed">
              <span className="barWrapper">
                <span
                  className="changeBar"
                  // eslint-disable-next-line max-len
                  style={{ width: `${getBarWidth( escalationChanges?.removed_escalations, escalationChanges?.added_escalations )}%`}}
                />
              </span>
              <span className="changeLabel">
                { formatNumber( escalationChanges?.removed_escalations ) || 0 }
              </span>
            </div>
            <div className="barAndLabelWrapper added">
              <span className="barWrapper">
                <span
                  className="changeBar"
                  // eslint-disable-next-line max-len
                  style={{ width: `${getBarWidth( escalationChanges?.added_escalations, escalationChanges?.removed_escalations )}%`}}
                />
              </span>
              <span className="changeLabel">
                { formatNumber( escalationChanges?.added_escalations ) || 0}
              </span>
            </div>
          </div>
          <div className="pointChangeWrapper host">
            <strong>Hosts</strong>
            <div className="barAndLabelWrapper removed">
              <span className="barWrapper">
                <span
                  className="changeBar"
                  // eslint-disable-next-line max-len
                  style={{ width: `${getBarWidth( hostChanges?.expired?.length, hostChanges?.identified?.length )}%`}}
                />
              </span>
              <span className="changeLabel">
                { formatNumber( hostChanges?.expired?.length || 0 ) || 0}
              </span>
            </div>
            <div className="barAndLabelWrapper added">
              <span className="barWrapper">
                <span
                  className="changeBar"
                  // eslint-disable-next-line max-len
                  style={{ width: `${getBarWidth( hostChanges?.identified?.length, hostChanges?.expired?.length )}%`}}
                />
              </span>
              <span className="changeLabel">
                { formatNumber( hostChanges?.identified?.length || 0 ) || 0}
              </span>
            </div>
          </div>
          <div className="pointChangeWrapper domain">
            <strong>Domains</strong>
            <div className="barAndLabelWrapper removed">
              <span className="barWrapper">
                <span
                  className="changeBar"
                  // eslint-disable-next-line max-len
                  style={{ width: `${getBarWidth( domainChanges?.expired?.length, domainChanges?.identified?.length )}%`}}
                />
              </span>
              <span className="changeLabel">
                { formatNumber( domainChanges?.expired?.length || 0 ) || 0}
              </span>
            </div>
            <div className="barAndLabelWrapper added">
              <span className="barWrapper">
                <span
                  className="changeBar"
                  // eslint-disable-next-line max-len
                  style={{ width: `${getBarWidth( domainChanges?.identified?.length, domainChanges?.expired?.length )}%`}}
                />
              </span>
              <span className="changeLabel">
                { formatNumber( domainChanges?.identified?.length || 0 ) || 0}
              </span>
            </div>
          </div>
        </div>
      }
      {
        ( isNotEmpty( escalationChanges ) &&
        ( escalationChanges.added_escalations !== 0 || escalationChanges.removed_escalations !== 0 ) ) &&
        <React.Fragment>
          { fullDetails &&
            <Accordion>
              <div className={`${sectionCollapse.instance ? 'collapsed' : ''} accordionWrapper`}>
                <div
                  className="accordionHeader"
                  onClick={ () => toggleSectionCollapse( 'instance' ) }
                >
                  <h3>Instance Changes</h3>
                  <button
                    onClick={ () => toggleSectionCollapse( 'instance' ) }
                  >
                    <InlineSVG type="carretUp" />
                  </button>
                </div>
                <div className="accordionBody">
                  <ul>
                    {
                      escalationChanges.added_escalations !== 0 &&
                      <li>{ escalationSentence( 'added' ) }</li>
                    }
                    {
                      escalationChanges.removed_escalations !== 0 &&
                      <li>{ escalationSentence( 'removed' ) }</li>
                    }
                  </ul>
                </div>
              </div>
            </Accordion>
          }
        </React.Fragment>
      }
      {
        isNotEmpty( hostChanges )
        && ( isNotEmpty( hostChanges.identified ) || isNotEmpty( hostChanges.expired ) ) &&
        <React.Fragment>
          {
            fullDetails &&
              <Accordion>
                <div className={`${sectionCollapse.host ? 'collapsed' : ''} accordionWrapper`}>
                  <div
                    className="accordionHeader"
                    onClick={ () => toggleSectionCollapse( 'host' ) }
                  >
                    <h3>Host Changes</h3>
                    <button
                      onClick={ () => toggleSectionCollapse( 'host' ) }
                    >
                      <InlineSVG type="carretUp" />
                    </button>

                  </div>
                  <div className="accordionBody">
                    {
                      isNotEmpty( hostChanges.identified ) &&
                      <React.Fragment>
                        <strong>Hosts Identified</strong>
                        <ul className="twoColumn">
                          {
                            hostChanges.identified.map( ( host, index ) => {
                              if ( index < 30 ) {
                                return  <li key={index} >
                                  <span>{ host.name }</span>
                                </li>;
                              }

                            } )
                          }
                        </ul>
                        {
                          hostChanges.identified.length > 30 &&
                          <span>
                            { `Showing the top 30 of ${hostChanges.identified.length} changes` }
                          </span>
                        }
                      </React.Fragment>
                    }
                    {
                      isNotEmpty( hostChanges.expired ) &&
                      <React.Fragment>
                        <strong>Hosts expired</strong>
                        <ul className="twoColumn">
                          {
                            hostChanges.expired.map( ( host, index ) => {
                              if ( index < 15 ) {
                                return  <li key={index} >
                                  <span>{ host.name }</span>
                                </li>;
                              }
                            } )
                          }
                        </ul>
                        {
                          hostChanges.expired.length > 15 &&
                          <span>{ `Showing the top 15 of ${hostChanges.expired.length} changes` }</span>
                        }
                      </React.Fragment>
                    }

                  </div>
                </div>
              </Accordion>
          }
        </React.Fragment>
      }
      {
        isNotEmpty( domainChanges )
        && ( isNotEmpty( domainChanges.identified ) || isNotEmpty( domainChanges.expired ) ) &&
        <React.Fragment>
          {
            fullDetails &&
              <Accordion>
                <div className={`${sectionCollapse.domain ? 'collapsed' : ''} accordionWrapper`}>
                  <div
                    className="accordionHeader"
                    onClick={ () => toggleSectionCollapse( 'domain' ) }
                  >
                    <h3>Domain Changes</h3>
                    <button
                      onClick={ () => toggleSectionCollapse( 'domain' ) }
                    >
                      <InlineSVG type="carretUp" />
                    </button>
                  </div>
                  <div className="accordionBody">
                    {
                      isNotEmpty( domainChanges.identified ) &&
                      <React.Fragment>
                        <strong>Windows Domains identified</strong>
                        <ul>
                          {
                            domainChanges.identified.map( ( d, index ) => {
                              if ( index < 15 ) {
                                return  <li key={index} >
                                  <span>{ d.name }</span>
                                </li>;
                              }

                            } )
                          }
                        </ul>
                        {
                          domainChanges.identified.length > 15 &&
                          <span>
                            { `Showing the top 15 of ${domainChanges.identified.length} changes` }
                          </span>
                        }
                      </React.Fragment>
                    }
                    {
                      isNotEmpty( domainChanges.expired ) &&
                      <React.Fragment>
                        <strong>Windows Domains expired</strong>
                        <ul>
                          {
                            domainChanges.expired.map( ( d, index ) => {
                              if ( index < 15 ) {
                                return  <li key={index} >
                                  <span>{ d.name }</span>
                                </li>;
                              }
                            } )
                          }
                        </ul>
                        {
                          domainChanges.expired.length > 15 &&
                          <span>{ `Showing the top 15 of ${domainChanges.expired.length} changes` }</span>
                        }
                      </React.Fragment>
                    }
                  </div>
                </div>
              </Accordion>
          }
        </React.Fragment>
      }
      {
        isNotEmpty( userChanges ) &&
        <React.Fragment>
          {
            fullDetails &&
              <Accordion>
                <div className={`${sectionCollapse.user ? 'collapsed' : ''} accordionWrapper`}>
                  <div
                    className="accordionHeader"
                    onClick={ () => toggleSectionCollapse( 'user' ) }
                  >
                    <h3>User Changes</h3>
                    <button
                      onClick={ () => toggleSectionCollapse( 'user' ) }
                    >
                      <InlineSVG type="carretUp" />
                    </button>
                  </div>
                  <div className="accordionBody">
                    <ul>
                      {
                        Object.entries( userChanges ).map( ( [ key, value ], index ) => {
                          return  <li key={index}>
                            <strong>{ capitalize( userLabelMap( key ) )  }</strong>
                            {
                              Object.entries( value ).map( ( [ k, v ] ) => `${v} ${userLabelMap( k )}` ).join( ', ' )
                            }
                          </li>;
                        } )
                      }
                    </ul>
                  </div>
                </div>
              </Accordion>
          }
        </React.Fragment>
      }
      {
        (
          isEmpty( userChanges )
          && escalationChanges.added_escalations === 0
          && escalationChanges.removed_escalations === 0
          && isEmpty( domainChanges )
          && isEmpty( hostChanges )
          && fullDetails
        ) &&
        <EmptyState message="No significant events" />
      }
    </React.Fragment>
  );
};

const RiskHistoryBody = ( {
  currentPoint,
  setCurrentPoint,
  data,
  setShowDetails,
  positionModal,
  setColor,
  targetRisk,
} ) => {

  // section show/hide vars
  const [ sectionCollapse, setSectionCollapse ] = React.useState( {
    host: true,
    user: true,
    instance: true,
    domain: true,
  } );

  const toggleSectionCollapse = section => {
    const _collapse = { ...sectionCollapse };
    _collapse[section] = !_collapse[section];
    setSectionCollapse( _collapse );
  };

  const nextPreviousEvent = ( e, direction ) => {

    const topOffset = 20;

    let newIndex;
    let newPoint;

    let currentIndex;

    if ( isNotEmpty( currentPoint.originalIndex ) ) {
      currentIndex = currentPoint.originalIndex;
    }
    if ( currentPoint.original?.originalIndex ) {
      currentIndex = currentPoint.original.originalIndex;
    }

    if ( isNotEmpty( currentIndex ) ) {
      // left arrow
      if ( direction === 'previous' || e.keyCode === 37 ) {
        if ( currentPoint && currentIndex !== 0 ) {
          newIndex = currentIndex - 1;
          newPoint = Object.values( data )[newIndex];
        } else {
          newIndex = currentIndex;
          newPoint = Object.values( data )[newIndex];
        }
      }
      // right arrow
      if ( direction === 'next' || e.keyCode === 39 ) {
        if ( currentPoint && currentIndex < currentPoint.totalPoints - 1 ) {
          newIndex = currentIndex + 1;
          newPoint = Object.values( data )[newIndex];
        } else {
          newIndex = currentIndex;
          newPoint = Object.values( data )[newIndex];
        }
      }
      // enter key or esc key
      if ( e.keyCode === 13 || e.keyCode === 27 ) {
        newPoint = {};
        setShowDetails( false );
        setCurrentPoint( null );
      }
      if ( isNotEmpty( newPoint ) ) {
        const pointElement = document.getElementById( `areaChartPoint_${newPoint.id}` );
        if ( isNotEmpty( pointElement ) ) {

          const pointOffset = getDimensionsAndOffset( pointElement );

          positionModal( { left: pointOffset.left, top: pointOffset.top + topOffset } );
        }
      }
    }
    setColor( globalRiskColor( newPoint?.risk, targetRisk ) );
    setCurrentPoint( newPoint );
  };

  React.useEffect( () => {
    setSectionCollapse( {
      host: true,
      user: true,
      instance: true,
      domain: true,
    } );
    if ( isNotEmpty( data ) ) {
      window.addEventListener( 'keyup', nextPreviousEvent );
    }

    return () => {
      window.removeEventListener( 'keyup', nextPreviousEvent );
    };
  }, [ currentPoint, data ] );

  return (
    <React.Fragment>
      <div className="arrowControls">
        {
          ( currentPoint && currentPoint.originalIndex !== 0 ) &&
          <button
            onClick={ e => nextPreviousEvent( e, 'previous' ) }
          >
            <InlineSVG type="carretLeft" />
          </button>
        }
        {
          ( currentPoint && currentPoint.originalIndex < currentPoint.totalPoints - 1 ) &&
          <button
            className="next"
            onClick={ e => nextPreviousEvent( e, 'next' ) }
          >
            <InlineSVG type="carretRight" />
          </button>
        }
      </div>
      <div className="modalBodyUpper">
        <CurrentRisk currentPoint={currentPoint} allPoints={data} targetRisk={targetRisk} />
      </div>
      <div className="modalBodyLower">
        <PointSummary
          point={currentPoint}
          sectionCollapse={sectionCollapse}
          toggleSectionCollapse={toggleSectionCollapse}
        />
      </div>
    </React.Fragment>
  );
};

const RiskOverTimeV2 = ( {
  item,
  settings,
  prefetchedData,
} ) => {

  const [ showDetails, setShowDetails ] = React.useState( false );
  const [ modalPosition, setModalPosition ] = React.useState( { top: 0, left: 0 } );
  // eslint-disable-next-line
  const [ color, setColor ] = React.useState( 'darkBlue' );
  const [ currentPoint, setCurrentPoint ] = React.useState( null );

  // max values for the centerBarChart
  const [ addedEscalationsMax, setAddedEscalationsMax ] = React.useState( 1 );
  const [ removedEscalationsMax, setRemovedEscalationsMax ] = React.useState( 1 );
  const [ addedHostsMax, setAddedHostsMax ] = React.useState( 1 );
  const [ removedHostsMax, setRemovedHostsMax ] = React.useState( 1 );

  const [ svgContainerWidth, setSVGContainerWidth ] = React.useState( null );
  const [ svgContainerHeight, setSVGContainerHeight ] = React.useState( null );

  const [ risk, setRisk ] = React.useState( null );
  const [ targetRisk, setTargetRisk ] = React.useState( null );
  const [ loadingRisk, setLoadingRisk ] = React.useState( false );

  const [ patchTuesdayData, setPatchTuesdayData ] = React.useState( null );

  // const svgContainerWidth = 100;
  const wrapperRef = React.useRef( null );
  const svgContainerRef = React.useRef( null );

  // variable for creating a unique wrapper id
  const wrapperUUID = uuidv4();

  const positionModal = ( position, shouldShowModal=true ) => {

    const xOffset = 16;
    let top = 0;

    const wrapper = document.getElementById( 'riskOverTimeAreaWrapper' );
    const wrapperOffset = getDimensionsAndOffset( wrapper );

    if ( isNotEmpty( wrapperOffset ) ) {
      // eslint-disable-next-line
      top = wrapperOffset.top;
    }

    // modal is 40vw
    const modalWidth = window.innerWidth * 0.45;

    let { left } = position;

    left = left + xOffset;

    // too far to the right
    if ( left + xOffset + modalWidth >= window.innerWidth ) {
      left = position.left - modalWidth - xOffset;
    }

    // too far to the left
    if ( left <= 0 ) {
      position.left = + xOffset;
    }

    setModalPosition( { left, top } );
    // setShowHighlight( true );
    if ( shouldShowModal ) {
      setShowDetails( true );
    }
  };

  const onClickCallback = ( e, point, fromBar=false ) => {

    if ( isNotEmpty( point ) ) {

      const topOffset = 20;

      const wrapper = document.getElementById( 'riskOverTimeAreaWrapper' );
      let wrapperOffset = getDimensionsAndOffset( wrapper );
      wrapperOffset = getDimensionsAndOffset( wrapper );

      let top = wrapperOffset.top + topOffset + ( wrapperOffset.height * ( point.y / 10 ) );

      if ( fromBar ) {
        const pointElement = document.getElementById( `areaChartPoint_${point.original.id}` );
        if ( isNotEmpty( pointElement ) ) {

          const pointOffset = getDimensionsAndOffset( pointElement );

          top = pointOffset.top + topOffset;
        } else {
          top = 0;
        }
      }

      if ( isNotEmpty( e ) ) {
        const pointPosition = { left: e.pageX, top };
        setColor( globalRiskColor( point.original.risk, targetRisk ) );
        setCurrentPoint( point );
        positionModal( pointPosition );
      }
    }
  };

  // const hover callback passed into the charts, in this case, it shows the highlight
  const onHoverCallback = point => {
    if ( isNotEmpty( point ) ) {
      setColor( globalRiskColor( point.original.risk, targetRisk ) );
      setCurrentPoint( point );
    }
  };

  const onRefresh = async ( forceRefresh=false, options={} ) => {

    const formatData = ( riskData, projectData ) => {
      setLoadingRisk( false );

      let addedE = 1;
      let removedE = 1;
      let addedH = 1;
      let removedH = 1;

      // loop through the data and set the max of all relevant values
      Object.values( riskData.transformed ).map( ( point, index ) => {
        const aE = Math.abs( point.addedEscalations );
        const rE = Math.abs( point.removedEscalations );
        const aH = Math.abs( point.addedHosts );
        const rH = Math.abs( point.removedHosts );

        if ( aE >= addedE ) {
          addedE = aE;
        }
        if ( rE >= removedE ) {
          removedE = rE;
        }
        if ( aH >= addedH ) {
          addedH = aH;
        }
        if ( rH >= removedH ) {
          removedH = rH;
        }

        setAddedEscalationsMax( addedE );
        setRemovedEscalationsMax( removedE );
        setAddedHostsMax( addedH );
        setRemovedHostsMax( removedH );

        if ( index === Object.values( riskData.transformed ).length - 1 ) {
          const _color = globalRiskColor( point.risk, projectData.settings.risk_target );
          setColor( _color );
        }
      } );
      setRisk( riskData );
      setTargetRisk( projectData?.settings?.risk_target );
    };

    const setupPatchTuesdayData = riskData => {
      if ( isNotEmpty( riskData ) ) {

        const startDate = riskData.original[0].timestamp * 1_000;
        const endDate = riskData.original[riskData.original.length - 1].timestamp * 1_000;

        let currentDate = startDate;
        const patchTuesdaysInRange = [];

        // going through every day between the start and end of the ranges
        while ( currentDate <= endDate ) {
          const date = new Date( currentDate );
          date.setHours( 0, 0, 0, 0 );
          const dow = date.getDay();
          const dom = date.getDate();
          // if the day of the week is tuesday (2) and it is not the first tuesday ( > 7 ) and is
          // the second tuesday ( <= 14 ) then add it is a patch tuesday (second tuesdy of each month)
          if ( dow === 2 && ( dom > 7 && dom <= 14 ) ) {
            patchTuesdaysInRange.push( currentDate );
          }
          currentDate = date.setDate( date.getDate() + 1 );
        }

        setPatchTuesdayData( { startDate, endDate, patchTuesdaysInRange } );
      }
    };

    setLoadingRisk( true );

    if (
      isNotEmpty( prefetchedData )
      && isNotEmpty( prefetchedData.risk )
      && isNotEmpty( prefetchedData.project )
      && forceRefresh === false
    ) {
      formatData( prefetchedData.risk, prefetchedData.project );
      if ( isNotEmpty( settings ) && settings?.patch_tuesday === true ) {
        setupPatchTuesdayData( prefetchedData.risk );
      }
    } else {
      const _riskData = await v2Fetches.risk( options );
      const _projectData = await v2Fetches.project();

      if ( isNotEmpty( _riskData ) && isNotEmpty( _projectData ) ) {
        formatData( _riskData, _projectData );

        if ( isNotEmpty( settings ) && settings?.patch_tuesday === true ) {
          setupPatchTuesdayData( _riskData );
        }
      } else {
        setLoadingRisk( false );
      }
    }
  };

  // find the max values for the different charts when the data comes in
  React.useEffect( ( ) => {
    onRefresh();
  }, [ prefetchedData, settings, item ] );

  // fires on all resize events, throttles to changes greater than 100px
  const adjustSVGAspectRatio = ( ) => {
    const width = window.innerWidth - 200;
    let height = width / 4;
    if ( isNotEmpty( item ) && isNotEmpty( item.h ) ) {
      height = item.h * 60;
    }
    setSVGContainerWidth( width );
    setSVGContainerHeight( height );
  };

  // sets up resize aspect ratio event listener
  React.useEffect( ( ) => {
    if ( isNotEmpty( svgContainerRef ) && isNotEmpty( item ) ) {
      adjustSVGAspectRatio( true );
      window.addEventListener( 'resize', debounce( () => {
        adjustSVGAspectRatio();
      }, 1_000 ) );
      return () => window.removeEventListener( 'resize', debounce );
    }
  }, [ svgContainerRef, item, settings ] );

  // whenever the details close, hide the highlight
  React.useEffect( ( ) => {
    if ( showDetails === false || isEmpty( currentPoint ) ) {
      setCurrentPoint( null );
    }
  }, [ showDetails ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( currentPoint ) &&
        <Modal
          elementID={ `riskOverTimePointModal-${wrapperUUID}` }
          elementClass={ 'riskOverTimePointModal' }
          visible={showDetails}
          modalStyle={ {
            left: modalPosition.left,
            top: modalPosition.top,
          }}
          setVisible={setShowDetails}
          body={
            <RiskHistoryBody
              positionModal={positionModal}
              currentPoint={currentPoint}
              setCurrentPoint={setCurrentPoint}
              data={risk?.transformed}
              targetRisk={targetRisk}
              setShowDetails={setShowDetails}
              wrapperUUID={wrapperUUID}
              wrapperRef={wrapperRef}
              setColor={setColor}
            />
          }
        />
      }
      <label>
        <div className="selectFieldWrapper">
          <select
            onChange={ e => ( onRefresh( true, { days: e.target.value } ) )}
            defaultValue="60"
          >
            <option value="7">Last 7 Days</option>
            <option value="15">Last 15 Days</option>
            <option value="30">Last 30 Days</option>
            <option value="60">Last 60 Days</option>
            <option value="90">Last 90 Days</option>
            <option value="100000000">All Time</option>
          </select>
        </div>
      </label>
      {
        ( isNotEmpty( risk ) && isNotEmpty( risk.transformed ) )
          ? <React.Fragment>
            {
              loadingRisk
                ? <div className="loadingContainerWrapper">
                  <Loading text="Loading risk history..." />
                </div>
                : <div className="riskOverTimeAreaWrapper" id="riskOverTimeAreaWrapper">
                  <div className="yAxisGroupWrapper">
                    <div className="chartAxis yAxis risk">
                      <div className="tic">{ formatNumber( Math.floor( risk?.max ) ) }</div>
                      <div className="tic">
                        { formatNumber( Math.floor( risk?.max * ( 2 / 3 ) ) ) }
                      </div>
                      <div className="tic">
                        { formatNumber( Math.floor( risk?.max * ( 1 / 3 ) ) ) }
                      </div>
                    </div>
                    {
                      isNotEmpty( settings?.include_escalations ) &&
                      <div className="chartAxis yAxis instance">
                        <div className="axisLabel">Instance Changes</div>
                      </div>
                    }
                    {
                      isNotEmpty( settings?.include_hosts ) &&
                      <div className="chartAxis yAxis host">
                        <div className="axisLabel">Host Changes</div>
                      </div>
                    }
                  </div>
                  {/* wrapping the element so that we can resize and change the svg aspect ratio */}
                  <div
                    id={ `riskOverTimeSVGWrapper-${item.i}` }
                    className="riskOverTimeWidgetAreaPlusBarWrapper"
                    ref={svgContainerRef}
                  >
                    {
                      ( isNotEmpty( svgContainerHeight ) && isNotEmpty( svgContainerWidth ) ) &&
                      <svg
                        viewBox={ `0 0 ${svgContainerWidth} ${svgContainerHeight}` }
                        xmlns="http://www.w3.org/2000/svg"
                        className="areaPlusBarWrapper"
                        id="areaPlusBarWrapper"
                        preserveAspectRatio="none"
                      >
                        <Xaxis
                          data={ risk }
                          dataKey="timestamp"
                          withoutWrapper
                          yOffset={ 3 }
                          currentPoint={currentPoint}
                          containerHeight={ svgContainerHeight }
                          containerWidth={ svgContainerWidth }
                        />
                        {/* left vertical line border */}
                        <line
                          x1={ ( svgContainerWidth - ( svgContainerWidth - 10 ) ) / 2 }
                          x2={ ( svgContainerWidth - ( svgContainerWidth - 10 ) ) / 2 }
                          y1={ 0 }
                          y2={ svgContainerHeight - 25 - 35 }
                          stroke={ globalColors['grey--divider']}
                          strokeWidth={ 2 }
                        />
                        <Area
                          data={ risk }
                          elementClass="riskOverTime"
                          fill="darkBlue"
                          stroke="darkBlue"
                          withoutWrapper
                          currentPoint={currentPoint}
                          containerHeight={ svgContainerHeight }
                          containerWidth={ svgContainerWidth }
                        />
                        {
                          isNotEmpty( settings?.include_escalations ) &&
                          <CenterBar
                            data={ risk }
                            max={ Math.max( addedEscalationsMax, removedEscalationsMax ) }
                            upperKey="addedEscalations"
                            lowerKey="removedEscalations"
                            elementClass="escalationsOverTime"
                            yOffset={ 1 }
                            withoutWrapper
                            currentPoint={currentPoint}
                            containerHeight={ svgContainerHeight }
                            containerWidth={ svgContainerWidth }
                          />
                        }
                        {
                          isNotEmpty( settings?.include_hosts ) &&
                          <CenterBar
                            data={ risk }
                            max={ Math.max( addedHostsMax, removedHostsMax ) }
                            upperKey="addedHosts"
                            lowerKey="removedHosts"
                            upperFill="#00AAE9"
                            lowerFill="#FFB800"
                            elementClass="hostsOverTime"
                            yOffset={ isNotEmpty( settings?.include_escalations ) ? 2 : 1 }
                            withoutWrapper
                            currentPoint={currentPoint}
                            containerHeight={ svgContainerHeight }
                            containerWidth={ svgContainerWidth }
                          />
                        }
                        {
                          isNotEmpty( patchTuesdayData ) &&
                          <PatchTuesdaysOverlay
                            data={ patchTuesdayData }
                            containerHeight={ svgContainerHeight }
                            containerWidth={ svgContainerWidth }
                          />
                        }

                        {
                          <ChartHoverIndicators
                            totalHeight={ svgContainerHeight - 40 }
                            data={ risk }
                            onClickCallback={ onClickCallback }
                            onHoverCallback={ onHoverCallback }
                            currentPoint={currentPoint}
                            fill="darkBlue"
                            stroke="darkBlue"
                            containerHeight={ svgContainerHeight }
                            containerWidth={ svgContainerWidth }
                          />
                        }

                      </svg>
                    }
                  </div>
                </div>
            }
          </React.Fragment>
          : <React.Fragment>
            {
              loadingRisk
                ? <div className="loadingContainerWrapper">
                  <Loading text="Loading risk history..." />
                </div>
                : <EmptyState message="No risk history" />
            }
          </React.Fragment>
      }
    </React.Fragment>
  );
};

export default RiskOverTimeV2;