/** **************************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ****************************************************************************/

import React from 'react';

import Draggable from 'react-draggable';

import './style.scss';

const ToolTip = ( {
  content,
  trigger,
  size='medium',
  triggerOnClick=false,
  x='left',
  y='bottom',
  draggable=false,
} ) => {

  const [ position, setPosition ] = React.useState( { top: 0, left: 0 } );
  const [ visible, setVisible ] = React.useState( false );

  const getTop = e => {
    const mousePos = e.clientY;
    let offset = 0;

    if ( y === 'bottom' ) {
      offset = 30;
    } else if ( y === 'center' ) {
      offset = containerRef.current.clientHeight / -2;
    } else {
      offset = ( 30 + containerRef.current.clientHeight ) * -1;
    }
    return mousePos + offset;
  };

  const getLeft = e => {
    const mousePos = e.clientX;
    let offset = 0;

    if ( x === 'left' ) {
      offset = containerRef.current.clientWidth;
    }
    if ( x === 'right' ) {
      offset -= 60;
    }

    if ( y === 'center' ) {
      offset += 30;
    }

    return mousePos - offset;
  };

  const handleToggleVisibility = e => {
    setPosition( { top: getTop( e ), left: getLeft( e ) } );
    setVisible( !visible );
  };

  const containerRef = React.useRef( null );

  return (
    <React.Fragment>
      {
        triggerOnClick &&
        <div
          className={ `toolTipShade ${visible ? 'visible' : ''}`}
          onClick={ () => setVisible( false ) }
        />
      }
      {
        triggerOnClick
          ? <div
            onClick={handleToggleVisibility}
            className={`toolTipTrigger ${triggerOnClick ? 'triggerOnClick' : ''}`}
          >
            { trigger }
          </div>
          : <div
            onMouseOver={handleToggleVisibility}
            onMouseLeave={ () => setVisible( false ) }
            className="toolTipTrigger"
          >
            { trigger }
          </div>
      }
      {
        draggable
          ? <Draggable>
            <div
              ref={containerRef}
              className={`toolTipContainer ${visible ? 'visible' : ''} ${size} draggable`}
              style={{ top: position.top, left: position.left }}
            >
              { content }
            </div>
          </Draggable>
          : <div
            ref={containerRef}
            className={`toolTipContainer ${visible ? 'visible' : ''} ${size}`}
            style={{ top: position.top, left: position.left }}
          >
            { content }
          </div>
      }

    </React.Fragment>
  );
};

export default ToolTip;
