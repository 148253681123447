/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import { getRecord, getRecords } from '../shared/RecordCache';
import { isNotEmpty } from '../shared/Utilities';

export const LabelsContext = React.createContext();

export const LabelsProvider = ( { children } ) => {

  const fullNodeLabel = async ( id, label, scopes=[] ) => {
    let scope;

    if ( isNotEmpty( scopes ) && isNotEmpty( id ) ) {
      scope = scopes.find( s => s.id === id );
    } else if ( isNotEmpty( id ) ) {
      scope = await getRecord( 'scope', id );
    } else {
      return '[Deleted Node]';
    }

    let fullLabel = [];

    if ( scope && isNotEmpty( scope.ancestor_labels ) ) {
      fullLabel = [ ...scope.ancestor_labels ];
    }
    if ( scope && scope.label ) {
      fullLabel.push( scope.label );
    }

    fullLabel.push( label );

    return fullLabel.join( ' / ' );
  };

  const getLabel = async ( item, type, options={} ) => {
    let label = '';

    if ( type === 'scope' || type === 'host' ) {
      return item.label;
    }

    if ( type === 'patch' ) {
      label = `${item.vendor} ${item.identifier}`;
    }

    if ( type === 'vulnerability' ) {
      label = item.identifier;
    }

    if ( type === 'path' ) {

      const last = item.node_labels[item.node_labels.length - 1];
      // eslint-disable-next-line
      const scopeID = last[0];
      // eslint-disable-next-line
      const thisLabel = last[1];

      const _scopeLabel = await fullNodeLabel( scopeID, thisLabel );

      label = `Path to ${_scopeLabel}`;
    }

    if ( type === 'node' ) {

      const scopeID = item.scope_id;
      const thisLabel = item.label;

      const _scopeLabel = await fullNodeLabel( scopeID, thisLabel );

      label = _scopeLabel;
    }

    if ( type === 'edge' || type === 'segment' ) {

      const constructSegmentLabel = async ( item ) => {
        if ( isNotEmpty( item ) ) {
          // eslint-disable-next-line camelcase
          let id_list = [ item.from_node, item.to_node ];
          // eslint-disable-next-line camelcase
          id_list = id_list.filter( id => id !== undefined || id !== 'undefined' );
          // eslint-disable-next-line camelcase
          const enodes = await getRecords( 'node', { id_list } );
          if ( isNotEmpty( enodes ) ) {
            const fn = enodes.find( n => n.id === item.from_node );
            const tn = enodes.find( n => n.id === item.to_node );

            const from = await fullNodeLabel( fn ? fn.scope_id : null, fn ? fn.label : '' );
            const to = await fullNodeLabel( tn ? tn.scope_id : null, tn ? tn.label : '' );

            label = `${from} \u2192 ${to}`;
          } else {
            label = 'N/A \u2192 N/A';
          }
        } else {
          label = 'N/A \u2192 N/A';
        }
      };

      if ( isNotEmpty( options ) ) {
        if ( isNotEmpty( options.nodes ) && isNotEmpty( options.scopes ) ) {
          const fn = options.nodes.find( n => n.id === item.from_node );
          const tn = options.nodes.find( n => n.id === item.to_node );

          const from = await fullNodeLabel( fn && fn.scope_id, fn ? fn.label : '', options.scopes );
          const to = await fullNodeLabel( tn && tn.scope_id, tn ? tn.label : '', options.scopes );

          label = `${from} \u2192 ${to}`;
        } else {
          await constructSegmentLabel( item );
        }
      } else {
        await constructSegmentLabel( item );
      }
    }

    return label;
  };

  const data = [
    getLabel,
  ];

  return (
    <LabelsContext.Provider value={ data }>
      { children }
    </LabelsContext.Provider>
  );
};
