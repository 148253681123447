/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { makeRequest } from '../../../../legacy/io';
import NotificationSubscriptions from '../../../shared/NotificationSubscriptions';
import SetupForm from '../../../shared/SetupComponents/SetupForm';
import { isNotEmpty } from '../../../shared/Utilities';
import './style.scss';
import { getConfigurationAlertsFor } from '../../../shared/ConfigurationAlert';
import Modal from '../../../shared/Modal';
import Notification from '../../../shared/Notification';
import InlineSVG from '../../../shared/InlineSVG';
import PageAlerts from '../../../shared/PageAlerts';

const NotificationSettings = ( ) => {

  const [ availableNotifications, setAvailableNotifications ] = React.useState( null );
  const [ hasNotifications, setHasNotifications ] = React.useState( null );
  const [ showNotificationsModal, setShowNotificationsModal ] = React.useState( null );

  React.useEffect( ( ) => {
    makeRequest( 'TYPES', '/notification_settings', {} ).then( response => {
      if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
        setAvailableNotifications( response.results );
      }
    } );
  }, [] );

  React.useEffect( ( ) => {
    getConfigurationAlertsFor( 'email' ).then( response => {
      if ( isNotEmpty( response ) ) {
        setHasNotifications( true );
      }
    } );
  }, [] );

  return (
    <SetupForm elementClass="notificationSettingsPageContainer">
      {
        hasNotifications &&
        <React.Fragment>
          <Modal
            visible={ showNotificationsModal }
            setVisible={ setShowNotificationsModal }
            elementClass="setupNotificationsModal"
            body={
              <Notification
                options={
                  {
                    type: 'alert',
                    // eslint-disable-next-line max-len
                    message: <span>The most recent attempt to send email resulted in an SMTP failure. Please see the <a href="#.=activity&page=configuration_alerts&source=email"> relevant configuration alerts </a> for more information.</span>,
                  }
                }
              />
            }
          />
          <PageAlerts>
            <button className="showNotificationsButton" onClick={ () => setShowNotificationsModal( true ) }>
              <InlineSVG type="notifications_nav" />
              <span className="notificationsCount">1</span>
            </button>
          </PageAlerts>

        </React.Fragment>
      }
      <NotificationSubscriptions availableNotifications={availableNotifications} />
    </SetupForm>
  );
};

export default NotificationSettings;