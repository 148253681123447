/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { CurrentUserContext } from '../../Contexts/CurrentUser';
import { hasAccess } from '../App/AccessControl';
import { isEmpty, isNotEmpty } from '../../shared/Utilities';
import InlineSVG from '../../shared/InlineSVG';
import RouteSubNavigation from './RouteSubNavigation';
import { OnboardingWizardContext } from '../../Contexts/OnboardingWizard';
import RatingBadge from '../../shared/RatingBadge';

import './MenuItem.scss';
import UserDetails from '../../shared/UserDetails';
import { NavigationContext } from '../../Contexts/Navigation';

const MenuItem = ( {
  item,
  currentRoute,
  currentPage,
} ) => {
  // all related to the user menu special actions
  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );
  // eslint-disable-next-line max-len
  const [ wizardStatus, , , , setShowOnboarding, currentWizardStep, , scanningMode, , ] = React.useContext( OnboardingWizardContext );
  const [ passedInSubNavigationItems, setPassedInSubNavigationItems ] = React.useState( null );

  const [ progressClass, setProgressClass ] = React.useState( '' );
  const [ showWarningBadge, setShowWarningBadge ] = React.useState( false );

  const [ itemLink, setItemLink ] = React.useState( null );

  const [ showMenuFor, setShowMenuFor, routing ] = React.useContext( NavigationContext );

  const pageIsCurrent = item => {

    // early return for somehow empty routs
    if ( isEmpty( currentRoute ) && item.isCurrent === 'reports' ) {
      return true;
    }

    if ( isNotEmpty( currentRoute ) && currentRoute.includes( '_settings' ) ) {
      return currentRoute === `${item.slug}_settings`;
    }

    if ( Array.isArray( item.isCurrent ) ) {
      return item.isCurrent.includes( currentPage ) && currentRoute === item.slug;
    }
    return currentRoute === item.isCurrent && currentRoute === item.slug;
  };

  const onboardingProgressClass = ( _progress ) => {
    let _class = 'minimal';

    const percent = Math.round( ( _progress / wizardStatus[scanningMode].length ) * 100 );

    if ( percent <= 75 ) {
      _class = 'low';
    }
    if ( percent <= 50 ) {
      _class = 'moderate';
    }
    if ( percent <= 25 ) {
      _class = 'high';
    }
    return _class;
  };

  const setupUserAndOptions = async () => {

    // onboarding wizard
    let _progress = 0;

    wizardStatus[scanningMode].map( s => {
      if ( s.status === 'configured' ) {
        _progress += 1;
      }
    } );

    const _progressClass = onboardingProgressClass( _progress );

    setProgressClass( _progressClass );

    const totalSteps = scanningMode === 'agent' || scanningMode === 'user_managed' ? 5 : 6;

    if ( _progress >= totalSteps ) {
      setShowWarningBadge( false );
    } else {
      setShowWarningBadge( true );
    }

    // after everything is set, adjust the options for the menu to reflect the current user and wizard status

    const _subNavigationItems = routing.user_menu;


    if ( isNotEmpty( _subNavigationItems ) ) {
      _subNavigationItems.items.edit_profile.link = `#.=setup&page=users&selected_record=${currentUser.id}`;

      _subNavigationItems.items.onboarding_wizard.action = () => setShowOnboarding( true );
      _subNavigationItems.items.onboarding_wizard.label = <React.Fragment>
        <span className="allowMenuHover" >Onboarding</span>
        <RatingBadge
          alternateText={ `Step ${_progress} of ${wizardStatus[scanningMode].length}` }
          rating={ onboardingProgressClass( _progress ) }
          elementClass="wizardStatusProgress allowMenuHover"
        />
      </React.Fragment>;

      setItemLink( `#.=setup&page=users&selected_record=${currentUser.id}` );

      setPassedInSubNavigationItems( _subNavigationItems.items );
    }

  };

  // once we have the current user and the wizard status, set up the actions, icon, and title for the user menu
  React.useEffect( () => {
    if (
      isNotEmpty( wizardStatus )
      && currentUser
      && isNotEmpty( item )
      && item.slug === 'user_menu'
      && isNotEmpty( scanningMode )
      && isNotEmpty( currentWizardStep )
      && isNotEmpty( currentWizardStep[scanningMode] )
    ) {
      setupUserAndOptions();
    }
  }, [ item, currentUser, wizardStatus, scanningMode, currentWizardStep ] );

  React.useEffect( () => {
    if ( isNotEmpty( item ) && item.slug !== 'user_menu ' ) {
      setItemLink( item.link );
    }
  }, [ item ] );


  const shouldShowMenu = () => item.slug === showMenuFor;

  return (
    <React.Fragment>
      {
        (
          isNotEmpty( licenseInfo )
          && !item.hidden
          && hasAccess( licenseInfo, { route: item.iconKey }, 'route' )
        ) &&
        <li
          // eslint-disable-next-line max-len
          className={`menuItem allowMenuHover ${shouldShowMenu() ? '' : 'negativeZIndex' } ${pageIsCurrent( item ) ? 'current' : ''} ${item.iconKey}`}
        >
          <RouteSubNavigation
            pageIsCurrent={pageIsCurrent}
            item={item}
            currentRoute={currentRoute}
            currentPage={currentPage}
            shouldShowMenu={shouldShowMenu}
            passedInSubNavigationItems={passedInSubNavigationItems}
            renderContext="leftNav"
          />
          {
            pageIsCurrent( item )
              ? <div
                onMouseEnter={ () => setShowMenuFor( item.slug ) }
                onMouseLeave={ () => setShowMenuFor( null ) }
                className={ `allowMenuHover notLink ${shouldShowMenu() ? 'hover' : '' } ` }
              >
                {
                  item.slug === 'user_menu'
                    ? <React.Fragment>
                      {
                        isNotEmpty( currentUser ) &&
                        <React.Fragment>
                          <UserDetails user={currentUser} forLeftNav elementClass="allowMenuHover" />
                          {
                            showWarningBadge &&
                            <span className={ `onboardingWarning ${progressClass}`}>
                              <InlineSVG type="warningAlt" version="light" elementClass="allowMenuHover" />
                            </span>
                          }
                        </React.Fragment>
                      }
                    </React.Fragment>
                    : <InlineSVG type={ item.iconKey } elementClass="allowMenuHover" />
                }
              </div>
              : <span className="linkWrapper allowMenuHover" onClick={ () => setShowMenuFor( null ) }>
                <a
                  href={ itemLink }
                  onMouseEnter={ () => setShowMenuFor( item.slug ) }
                  onMouseLeave={ () => setShowMenuFor( null ) }
                  className={ shouldShowMenu() ? 'hover allowMenuHover' : 'allowMenuHover' }
                >
                  {
                    item.slug === 'user_menu'
                      ? <React.Fragment>
                        {
                          isNotEmpty( currentUser ) &&
                          <React.Fragment>
                            <UserDetails user={currentUser} forLeftNav elementClass="allowMenuHover" />
                            {
                              showWarningBadge &&
                              <span className={ `onboardingWarning allowMenuHover ${progressClass}`}>
                                <InlineSVG type="warningAlt" version="light" elementClass="allowMenuHover" />
                              </span>
                            }
                          </React.Fragment>

                        }
                      </React.Fragment>
                      : <InlineSVG type={ item.iconKey } elementClass="allowMenuHover" />
                  }
                </a>
              </span>
          }
        </li>
      }
    </React.Fragment>
  );
};

export default MenuItem;