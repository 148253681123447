/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../../shared/Utilities';
import { v2Fetches } from '../../shared';
import EmptyLoading from '../../../../../shared/EmptyLoading';
import Legend from '../../../../../shared/Charts/Legend';

import './VulnerabilityAgeBreakdown.scss';
import Bar from '../../../../../shared/Charts/Bar';
import YAxisLabels from '../../../../../shared/Charts/AxisLabels/YAxisLabels';

const VulnerabilityAgeBreakdown = ( { prefetchedData, settings } ) => {

  const [ vulnerabilityAgeTotal, setVulnerabilityAgeTotal ] = React.useState( 1 );
  const [ vulnerabilityAgeData, setVulnerabilityAgeData ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( false );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );
  const [ yMax, setYMax ] = React.useState( 0 );

  const svgRef = React.useRef( null );

  const palette = {
    '0-30d': '#334d6e',
    '30d-60d': '#485f7d',
    '60d-90d': '#5c718b',
    '90d-180d': '#71839a',
    '180d-1y': '#8594a8',
    '1y-3y': '#99a6b7',
    '3y-5y': '#adb8c5',
    '5y-10y': '#c2cad4',
    '10y-inf': '#d6dbe2',
  };

  const ageLabelMap = {
    '0-30d': '0 - 30 Days',
    '30d-60d': '30 - 60 Days',
    '60d-90d': '60 - 90 Days',
    '90d-180d': '90 - 180 Days',
    '180d-1y': '180 Days - 1 Year',
    '1y-3y': '1 - 3 Years',
    '3y-5y': '3 - 5 Years',
    '5y-10y': '5 - 10 Years',
    '10y-inf': 'More than 10 Years',
  };

  const setupData = data => {
    const _ageData = {};
    const total = data._total || 1;
    let _yMax = 0;

    Object.entries( data.vulnerability_age ).map( ( [ ageKey, count ] ) => {
      _ageData[ageKey] = {
        label: ageLabelMap[ageKey],
        stroke: palette[ageKey],
        fill: palette[ageKey],
        value: ( count / total ) * 100,
        originalCount: count,
        total: count,
        key: ageKey,
      };

      if ( count > _yMax ) {
        _yMax = count;
      }
    } );
    setYMax( _yMax );
    setVulnerabilityAgeTotal( total );
    setVulnerabilityAgeData( _ageData );
  };

  const refetchData = async () => {
    setLoading( true );
    const _tallyResults = await v2Fetches.tally_age();
    setLoading( false );

    if ( isNotEmpty( _tallyResults ) ) {
      setupData( _tallyResults.results );
    }
  };

  React.useEffect( () => {
    if (
      isNotEmpty( prefetchedData )
      && isNotEmpty( prefetchedData.tally_age )
      && isNotEmpty( prefetchedData.tally_age.results )
      && isNotEmpty( prefetchedData.tally_age.results.vulnerability_age )
    ) {
      setupData( prefetchedData.tally_age.results );
    } else {
      refetchData();
    }
  }, [ prefetchedData, settings ] );

  return (
    <React.Fragment>
      <EmptyLoading
        payload={ vulnerabilityAgeData }
        loading={ loading }
        loadingMessage="Loading OS Data"
        emptyMessage="No OS Data"
      />
      {
        ( isNotEmpty( vulnerabilityAgeTotal ) && isNotEmpty( vulnerabilityAgeData ) ) &&
        <React.Fragment>
          <div className="cvssBarChartWrapper" ref={svgRef} >
            <YAxisLabels yMax={ yMax } />
            <Bar
              data={vulnerabilityAgeData}
              hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
              setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
            />
            <div className="xLabel">
              <span>Newer</span>
              <span>Older</span>
            </div>
          </div>

          <Legend
            label="Vulnerability Age"
            legendData={ vulnerabilityAgeData }
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default VulnerabilityAgeBreakdown;