/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { capitalize, isNotEmpty } from '../../../shared/Utilities';
import NodeHeader from './Header/NodeHeader';
import EdgeHeader from './Header/EdgeHeader';
import ScopeHeader from './Header/ScopeHeader';
import VulnerabilityHeader from './Header/VulnerabilityHeader';
import PatchHeader from './Header/PatchHeader';
import InlineSVG from '../../../shared/InlineSVG';
import NodeContent from './Content/NodeContent';
import EdgeContent from './Content/EdgeContent';
import ScopeContent from './Content/ScopeContent';
import VulnerabilityContent from './Content/VulnerabilityContent';
import PatchContent from './Content/patchContent';
import NodeOptions from './Options/NodeOptions';
import EdgeOptions from './Options/EdgeOptions';
import ScopeOptions from './Options/ScopeOptions';
import Draggable from 'react-draggable';

import './style.scss';
import PathsFromHere from './Content/PathsFromHere';
import SignatureHeader from './Header/SignatureHeader';
import SignatureContent from './Content/SignatureContent';
import { getEscalationsForSignature } from './shared';

const RecordCard = ( {
  record,
  type,
  context='explore',
  options={},
  show=true,
  setShow=() => {},
  onCloseCallback=() => {},
  // these are all for explore
  editItem=() => {},
  deleteItem=() => {},
  copyItem=() => {},
  selectItem=() => {},
  // addSegment=() => {},
  setSelectingSecondaryItem=() => {},
  pathsFromHere,
  setExternalHoverIDs=() => {},
  displayExploreItemNameFor,
  collapsed=false,
  setCollapsed=() => {},
  selectedPathID=null,
  setSelectedPathID=null,
} ) => {

  // eslint-disable-next-line
  const [ style, setStyle ] = React.useState( {} );

  const [ additionalContent, setAdditionalContent ] = React.useState( null );

  const [ signature, setSignature ] = React.useState( null );
  const [ instance, setInstance ] = React.useState( null );
  const [ escalation, setEscalation ] = React.useState( null );
  const [ loadingVulnerabilities, setLoadingVulnerabilities ] = React.useState( false );
  const [ loadingSignature, setLoadingSignature ] = React.useState( false );
  const [ vulnerabilities, setVulnerabilities ] = React.useState( null );
  const [ highestRisk, setHighestRisk ] = React.useState( null );

  const containerRef = React.useRef( null );

  const typesWithOptionsMenu = [
    'edge',
    'scope',
    'node',
  ];

  React.useEffect( () => {

    // position the modal near to the clicked item
    if (
      isNotEmpty( options )
      && (
        isNotEmpty( options.clickEvent )
        || ( isNotEmpty( record ) && isNotEmpty( record.clickEvent ) )
      )
    ) {

      const width = isNotEmpty( additionalContent ) ? 60 * 16 : 30 * 16;

      let left = 'unset';
      let right = 16;
      let top = 16;
      let bottom = 'unset';

      if ( context !== 'explore' ) {
        const _clickEvent = options.clickEvent || record.clickEvent;

        const { clientX, clientY } = _clickEvent;

        left = clientX + 16;
        right = 'unset';
        top = clientY;
        bottom = 'unset';


        if ( clientY > ( window.innerHeight / 2 ) || type === 'segment' || type === 'edge' ) {
          top = 'unset';
          bottom = 16;

          if ( context === 'risk_insight' ) {
            top = 96;
            bottom = 'unset';
          }
        }

        // it would flow over the right side of the screen, need to put on the left side instead
        if ( ( left + width + ( context === 'explore' ? ( 4 * 16 ) : 0 ) ) > window.innerWidth ) {
          left = clientX - 96 - width;

          // if this would push it too far to the left, just put it as far to the right as it can
          if ( left - 16 ) {
            left = 'unset';
            right = 16;
          }
        }
      }

      setStyle( { top, bottom, left, right, width } );
    }
  }, [ options, type, context, containerRef, additionalContent ] );

  React.useEffect( () => {
    setAdditionalContent( null );
    if ( isNotEmpty( setSelectedPathID ) ) {
      setSelectedPathID( null );
    }
    if ( isNotEmpty( setExternalHoverIDs ) ) {
      setExternalHoverIDs( [] );
    }


  }, [ show ] );


  // for signature "modals" we are just going to write a separate hook as it will behave much more like
  // a traditional modal
  React.useEffect( () => {
    if (
      isNotEmpty( record )
      && type === 'signature'
    ) {

      if ( context === 'risk_insight_signature_instances' ) {
        setSignature( record );
      } else {
        setLoadingVulnerabilities( true );
        setLoadingSignature( true );
        getEscalationsForSignature( record ).then( response => {
          setLoadingVulnerabilities( false );
          setLoadingSignature( false );
          if ( isNotEmpty( response ) ) {
            if ( isNotEmpty( response.vulnerabilities ) ) {
              const _vulnerabilities = Object.values( response.vulnerabilities ).sort( ( a, b ) => b.risk - a.risk );
              const _highestRisk = Math.max( ..._vulnerabilities.map( v => v.risk ) );

              setVulnerabilities( _vulnerabilities );
              setHighestRisk( _highestRisk );
            }

            if ( isNotEmpty( response.instanceAndEscalationData ) ) {
              setInstance( response.instanceAndEscalationData.instance );
              setSignature( response.instanceAndEscalationData.signature?.record );
              setEscalation( response.instanceAndEscalationData.escalation );
            }
          }
        } );
      }

    }
  }, [ record, type, additionalContent, context ] );

  const handleClose = () => {
    setShow( false );
    setAdditionalContent( null );
    onCloseCallback();
  };

  const getUpperHeaderLabel = ( record, type ) => {
    if ( type === 'node' ) {
      return 'Node Information';
    }
    if ( type === 'edge' ) {
      return 'Segment Information';
    }
    if ( type === 'scope' ) {
      if ( isNotEmpty( record ) && isNotEmpty( record.type ) && record.type === 'host' ) {
        return 'Host Information';
      }
      return 'Scope Information';
    }
    if ( type === 'vulnerability' ) {
      return 'Vulnerability Information';
    }
    if ( type === 'patch' ) {
      return 'Patch Information';
    }
    if ( type === 'signature' ) {
      return 'Signature Information';
    }
  };

  const content = <div className={ `contentGridWrapper ${ isNotEmpty( additionalContent ) ? 'twoColumn' : '' }` }>
    <div className="mainContent">
      <div className={ `recordCardHeader ${ options.draggable ? 'draggable' : ''} ${type}` }>
        <div className="recordCardUpperHeader">
          <div className="recordCardUpperHeaderLabel">
            <InlineSVG type="notificationInfo" elementClass="recordCardHeaderIcon"  />
            { getUpperHeaderLabel( record, type ) }
          </div>
          <div className="recordCardUpperHeaderActions">
            {
              ( isNotEmpty( options ) && options.collapsible ) &&
              <button
                onClick={ () => setCollapsed( !collapsed ) }
                className="panelCollapseButton"
              >
                {
                  collapsed
                    ? 'Expand'
                    : 'Collapse'
                }
              </button>
            }
            {
              ( isNotEmpty( options ) && options.draggable ) &&
              <InlineSVG type="draggable" elementClass="draggableIcon" />
            }
            {
              ( isNotEmpty( options ) && options.dismissable ) &&
              <button
                className="recordCardCloseButton"
                onClick={ handleClose }
              >
                <InlineSVG type="close" elementClass="closeIcon" />
              </button>
            }
          </div>
        </div>
        {
          type === 'node' &&
          <NodeHeader node={record} options={options} context={context} />
        }
        {
          type === 'edge' &&
          <EdgeHeader edge={record} options={options} context={context} />
        }
        {
          type === 'scope' &&
          <ScopeHeader scope={record} options={options} context={context} />
        }
        {
          ( type === 'vulnerability' && !options?.includeSignature ) &&
          <VulnerabilityHeader
            vulnerability={record}
            options={options}
            context={context}
            selectItem={selectItem}
          />
        }
        {
          type === 'patch' &&
          <PatchHeader patch={record} options={options} context={context} />
        }
        {
          type === 'signature' &&
          <SignatureHeader signature={ signature } />
        }
      </div>
      <div className="recordCardContent">
        {
          type === 'node' &&
          <React.Fragment>
            <NodeContent
              node={ record }
            />
            {
              isNotEmpty( pathsFromHere ) &&
              <PathsFromHere
                pathsFromHere={ pathsFromHere }
                setExternalHoverIDs={setExternalHoverIDs}
                displayExploreItemNameFor={displayExploreItemNameFor}
                selectedPathID={ selectedPathID }
                setSelectedPathID={ setSelectedPathID }
              />
            }
          </React.Fragment>
        }
        {
          type === 'edge' &&
          <EdgeContent
            edge={record}
            options={options}
            context={context}
            selectItem={selectItem}
            additionalContent={ additionalContent }
            setAdditionalContent={ setAdditionalContent }
            displayExploreItemNameFor={ displayExploreItemNameFor }
          />
        }
        {
          type === 'scope' &&
          <ScopeContent scope={ record } options={options} context={context} />
        }
        {
          type === 'vulnerability' &&
          <React.Fragment>
            <VulnerabilityContent vulnerability={ record } options={options} context={context} />
          </React.Fragment>
        }
        {
          type === 'patch' &&
          <PatchContent patch={ record } options={options} context={context} />
        }
        {
          type === 'signature' &&
          <SignatureContent
            fullVersion
            escalation={ escalation }
            signature={ signature }
            instance={ instance }
            vulnerabilities={ vulnerabilities }
            options={ options }
            highestRisk={ highestRisk }
            context={ context }
            selectItem={ selectItem }
            setAdditionalContent={ setAdditionalContent }
            loadingVulnerabilities={ loadingVulnerabilities }
            loadingSignature={ loadingSignature }
          />
        }
      </div>
      {
        ( context === 'explore' && typesWithOptionsMenu.includes( type ) ) &&
        <div className="recordCardOptions">
          <div className="recordCardOptionsHeader">
            <InlineSVG type="filterAlt" />
            <h3>
              { capitalize( type === 'edge' ? 'segment' : type ) } options
            </h3>
          </div>
          <ul>
            {
              type === 'node' &&
              <NodeOptions
                node={ record }
                selectItem={ selectItem }
                setSelectingSecondaryItem={ setSelectingSecondaryItem }
                editItem={ editItem }
                copyItem={ copyItem }
                deleteItem={deleteItem }
              />
            }
            {
              type === 'edge' &&
              <EdgeOptions
                edge={ record }
                selectItem={ selectItem }
                editItem={ editItem }
                deleteItem={ deleteItem }
              />
            }
            {
              type === 'scope' &&
              <ScopeOptions
                scope={ record }
                editItem={ editItem }
                copyItem={ copyItem }
                selectItem={ selectItem }
              />
            }
          </ul>
        </div>
      }
    </div>
    {
      isNotEmpty( additionalContent ) &&
      <div className="additionalContent">
        <button
          className="closeAdditionalButton"
          onClick={ () => setAdditionalContent( null ) }
        >
          <InlineSVG type="close" />
        </button>
        { additionalContent }
      </div>
    }

  </div>;

  return (
    <React.Fragment>
      {
        ( isNotEmpty( record ) && isNotEmpty( type ) && show ) &&
        <React.Fragment>
          {
            options?.includeShade &&
            <div className="recordCardContainerShade" onClick={ handleClose } />
          }
          {
            ( isNotEmpty( options ) && options.draggable )
              ? <Draggable
                disabled={collapsed}
                handle=".recordCardUpperHeader"
              >
                <div
                  // eslint-disable-next-line max-len
                  className={ `${ collapsed ? 'collapsed' : '' } ${ isNotEmpty( additionalContent ) ? 'withAdditional' : '' } recordCardContainer recordCardType_${type} cardContext_${context} ${ show ? 'visible' : '' }` }
                  style={ style }
                  ref={ containerRef }
                >
                  { content }
                </div>
              </Draggable>
              : <div
                // eslint-disable-next-line max-len
                className={ `${ collapsed ? 'collapsed' : '' } ${ isNotEmpty( additionalContent ) ? 'withAdditional' : '' } recordCardContainer recordCardType_${type} cardContext_${context} ${ show ? 'visible' : '' }` }
                style={ style }
              >
                { content }
              </div>
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default RecordCard;