/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import { pagesMap } from '../components/App/Routing';

export const NavigationContext = React.createContext();

export const NavigationProvider = ( { children } ) => {

  const [ showMenuFor, setShowMenuFor ] = React.useState( null );
  const [ routing, setRouting ] = React.useState( pagesMap );

  const data = [
    showMenuFor,
    setShowMenuFor,
    routing,
    setRouting,
  ];

  return (
    <NavigationContext.Provider value={ data }>
      { children }
    </NavigationContext.Provider>
  );
};
