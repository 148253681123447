/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { RiskContext } from '../../Contexts/Risk';
import {
  formatNumber,
  getCurrentPercentile,
  getGlobalSettings,
  getPercentileClass,
  getRiskClass,
  globalColors,
  isEmpty,
  isNotEmpty,
  numberToOrdinal,
} from '../Utilities';

import './style.scss';
import Gauge from '../Charts/Gauge';

const RiskAndPercentile = ( { externalPreference=null } ) => {

  const [ shouldIncludePercentile, setShouldIncludePercentile ] = React.useState( false );
  const [ shouldIncludeRisk, setShouldIncludeRisk ] = React.useState( false );

  const [ percentile, setPercentile ] = React.useState( null );
  const [ percentileClass, setPercentileClass ] = React.useState( '' );
  const [ riskClass, setRiskClass ] = React.useState( '' );

  const [ , globalRisk, targetRisk, , , , ] = React.useContext( RiskContext );

  const strokes = {
    high: globalColors['high'],
    low: globalColors['low'],
    minimal: globalColors['minimal'],
    primary: globalColors['primaryBlue'],
  };

  // on init, see what we need to show
  React.useEffect( () => {
    if ( isEmpty( externalPreference ) ) {
      // need the global settings for the trigger display ( options, percentile, global risk, both )
      getGlobalSettings( 'global' ).then( response => {
        if ( isNotEmpty( response.risk_score_display_mode ) ) {
          setShouldIncludePercentile(
            response.risk_score_display_mode === 'percentile' || response.risk_score_display_mode === 'both',
          );
          setShouldIncludeRisk(
            response.risk_score_display_mode === 'risk' || response.risk_score_display_mode === 'both',
          );
        } else {
          setShouldIncludePercentile( false );
          setShouldIncludeRisk( true );
        }
      } );
    } else {
      setShouldIncludePercentile( externalPreference === 'percentile' || externalPreference === 'both' );
      setShouldIncludeRisk( externalPreference === 'risk' || externalPreference === 'both' );
    }

  }, [ externalPreference ] );

  // if we need to show percentile, get the percentile and set the class
  React.useEffect( () => {
    if ( shouldIncludePercentile && isEmpty( percentile ) ) {
      getCurrentPercentile().then( _percentile => {
        setPercentile( _percentile );

        if ( isNotEmpty( _percentile ) && isNotEmpty( _percentile.percentile ) ) {
          const _percentileClass = getPercentileClass( _percentile.percentile );
          setPercentileClass( _percentileClass );
        }
      } );
    }
  }, [ shouldIncludePercentile ] );

  // if we need to show the risk, get the risk and set the class
  React.useEffect( () => {
    if ( isNotEmpty( globalRisk ) && isNotEmpty( targetRisk ) && shouldIncludeRisk ) {
      const _riskClass = getRiskClass( globalRisk, targetRisk );

      setRiskClass( _riskClass );
    }
  }, [ globalRisk, targetRisk, shouldIncludeRisk ] );

  const getCombinedClass = () => {
    if ( shouldIncludePercentile && isNotEmpty( percentile ) ) {
      return percentileClass;
    }
    return riskClass;
  };

  return (
    <React.Fragment>
      {
        (
          ( shouldIncludeRisk && isNotEmpty( globalRisk ) )
          || ( shouldIncludePercentile && isNotEmpty( percentile ) && isNotEmpty( percentile.percentile ) )
        ) &&
        <div
          // eslint-disable-next-line max-len
          className={ `riskAndPercentileWrapper ${ ( shouldIncludePercentile && shouldIncludeRisk ) ? 'twoColumn' : ''}` }
        >
          {
            ( shouldIncludeRisk && isNotEmpty( globalRisk ) ) &&
            <div className={ `tile riskTile risk--${getCombinedClass()}` }>
              <div className="outerRiskCircle">
                <div className="innerRiskCircle">
                  <div className="scoreAndLabelWrapper">
                    <strong>{ formatNumber( Math.round( globalRisk ) ) }</strong>
                    <span>Risk score</span>
                  </div>
                </div>
              </div>
            </div>
          }
          {
            ( shouldIncludePercentile && isNotEmpty( percentile ) && isNotEmpty( percentile.percentile ) ) &&
            <div className={ `tile percentileTile risk--${getCombinedClass()}` }>
              <Gauge
                total={1}
                displayLabel
                data={
                  {
                    stroke: strokes[percentileClass],
                    value: ( percentile.percentile / 1 ) * 100,
                    rawValue: numberToOrdinal( percentile.percentile ),
                  }
                }
              />
              <h3>Peer Percentile</h3>
              <p>
                {/* eslint-disable-next-line max-len */}
                The risk score of <strong>{ formatNumber( Math.round( globalRisk ) ) }</strong> is better than <strong>{ Math.round( percentile.percentile * 100 ) }%</strong> of your peers
              </p>
            </div>
          }
        </div>
      }
    </React.Fragment>
  );
};

export default RiskAndPercentile;