/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import SetupForm from '../../../shared/SetupComponents/SetupForm';
import Subnets from './Subnets';
import Cliques from './Cliques';

import './style.scss';

import {
  HelpTrigger,
} from '../../../components/HelpDocumentation/ContextualHelp/index.js';

import Form from '../../../shared/Form';

import {
  getGlobalSettings,
  isNotEmpty,
  updateGlobalSettings,
} from '../../../shared/Utilities';

import { getFieldValues } from '../../../shared/Form/Shared';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../legacy/io';

const EMPTY_FIELDS = {
  settings: {
    header: 'Settings',
    fields: [
      {
        type: 'number',
        label: 'Number of port scan workers',
        attribute: 'connectivity_max_workers',
        htmlProps: { min: 1, max: 500, step: 1},
        help: <HelpTrigger helpKey="connectivity_max_workers" />,
        defaultValue: '',
      },
      {
        type: 'duration',
        expectedUnit: 's',
        allowedUnits: [ 's', 'm' ],
        label: 'Time pause between port scans',
        attribute: 'connectivity_port_delay',
        htmlProps: { min: 0, max: 600, step: 1},
        help: <HelpTrigger helpKey="connectivity_port_delay" />,
        defaultValue: '',
      },
      {
        type: 'number',
        label: 'Scanned service timeout (milliseconds)',
        attribute: 'connectivity_port_timeout',
        htmlProps: { min: 50, max: 10000, step: 1},
        help: <HelpTrigger helpKey="connectivity_port_timeout" />,
        defaultValue: '',
      },
      {
        type: 'number',
        label: 'Maximum ports per client scanner',
        attribute: 'connectivity_ports_per_client',
        htmlProps: { min: 1, max: 1000, step: 1},
        help: <HelpTrigger helpKey="connectivity_ports_per_client" />,
        defaultValue: '',
      },
    ],
  },
};

const NetworkConnectivity = () => {

  let isMounted = true;

  // ContextualHelp getters and setters
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  // validation, fields, values for form
  const [ isValid, setIsValid ] = React.useState( false );
  const [ fields, setFields ] = React.useState( null );
  const [ record, setRecord ] = React.useState( null );
  const [ updatedForm, setUpdatedForm ] = React.useState( null );

  const [ subnets, setSubnets ] = React.useState( {} );
  const [ cliques, setCliques ] = React.useState( [] );

  // On init, grab any settings from the server
  React.useEffect( () => {

    onRefresh( );
    setFields( EMPTY_FIELDS );
  }, [] );

  // register and remove onleave listener
  React.useEffect( () => {
    isMounted = true;
    const parentEl = document.getElementById( 'pageContent' );

    parentEl.onleave = async () => {
      if ( isValid ) {
        onSave( false );
        return true;
      }
      return false;

    };

    return () => {
      parentEl.onleave = null;
      isMounted = false;
    };
  }, [ updatedForm, isValid ] );

  const onRefresh = async() => {
    const _subnets = {
      included: [],
      excluded: [],
    };

    // get the latest project settings
    const _project = await getGlobalSettings( 'project' );
    // get latest subnets
    const subnetRequest = await makeRequest( 'FIND', '/project/default/subnet', {} );

    if ( subnetRequest && subnetRequest.results ) {
      subnetRequest.results.map( subnet => {
        if ( subnet.probe_connectivity ) {
          _subnets.included.push( subnet );
        } else {
          _subnets.excluded.push( subnet );
        }
      } );
    }

    // get latest cliques
    const cliqueRequest = await makeRequest( 'FIND', '/project/default/connectivity_clique', {} );
    if ( cliqueRequest && cliqueRequest.results ) {
      setCliques( cliqueRequest.results );
    } else {
      setCliques( [] );
    }

    setSubnets( _subnets );
    setRecord( {
      /* eslint-disable camelcase */
      connectivity_max_workers: _project?.settings?.connectivity_max_workers,
      connectivity_port_delay: _project?.settings?.connectivity_port_delay,
      connectivity_port_timeout: _project?.settings?.connectivity_port_timeout,
      connectivity_ports_per_client: _project?.settings?.connectivity_ports_per_client,
      /* eslint-enable camelcase */
    } );
  };

  const onSave = async( shouldRefresh=true ) => {

    const _project = { settings: {} };

    if ( isValid && isNotEmpty( updatedForm ) ) {

      const formValues = getFieldValues( updatedForm?.fieldStates, 'connectivity_settings' );

      if ( isNotEmpty( formValues ) ) {
        Object.entries( formValues ).map( ( [ attr, value ] ) => {
          _project.settings[attr] = value;
        } );
      }

      const updated = await updateGlobalSettings( 'project', _project );

      // success
      if ( isNotEmpty( updated.results ) ) {
        // only refresh if needed, ie, not when navigating away
        if (
          shouldRefresh
          && isMounted
        ) {
          onRefresh( );
          addFlashMessage( {
            type: 'success',
            body: 'Successfully updated settings.',
          } );
        }
      // formatted error
      } else if ( isNotEmpty( updated.errors ) ) {
        if ( shouldRefresh && isMounted ) {
          updated?.errors.map( e => {
            addFlashMessage( {
              type: 'alert',
              body: e,
            } );
          } );
        }
      // likely 500
      } else if ( shouldRefresh && isMounted ) {
        addFlashMessage( {
          type: 'alert',
          body: 'There was an error saving settings, please check your connection and try again.',
        } );
      }
    }

  };

  return (
    <div id="react-network-connectivity-container" >
      <SetupForm>
        <section className="networkConnectivitySection" >
          {
            ( isNotEmpty( fields ) && isNotEmpty( record ) ) &&
            <Form
              existingRecord={ record }
              fields={ fields }
              setIsValid={ setIsValid }
              onChangeCallback={ setUpdatedForm }
              recordType="connectivity_settings"
            />
          }
        </section>
        <Subnets
          subnets={subnets}
          setSubnets={setSubnets}
        />
        <Cliques
          subnets={subnets}
          cliques={cliques}
          setCliques={setCliques}
        />
        <div className="formActions">
          <button
            className="revertButton"
            onClick={ onRefresh }
          >
            Revert
          </button>
          <button
            disabled={ !isValid }
            onClick={ onSave }
          >
            Save
          </button>
        </div>
      </SetupForm>
    </div>
  );
};

export default NetworkConnectivity;
