/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, isNotEmpty, riskToRating } from '../../../../shared/Utilities';
import InlineSVG from '../../../../shared/InlineSVG';

import './ItemStatistic.scss';
import RatingBadge from '../../../../shared/RatingBadge';
import ExploitStatus from '../../../../shared/ExploitStatus';
import { getInsightURL, nodeIconMap, reportTypeForStat } from '../shared';
import RiskReduction from '../../../../shared/RiskReduction';

const ItemStatistic = ( { item, stat, instanceData, riskType='risk', reportType='host' } ) => {

  const labelForStat = {
    /* eslint-disable camelcase */
    risk_reduction: 'Risk Reduction',
    vulnerability_instances: 'Vuln. Instances',
    patches: 'All Patches',
    unsuperseded_patches: 'Unsuperseded Patches',
    risk_rating: 'Risk Rating',
    sensitive_nodes: 'Sensitive Assets',
    vulnerabilities: 'Vulnerabilities',
    exploit_status: 'Exploit Status',
    hosts: 'Affected Hosts',
    active_hosts: 'Recently Accessed Hosts',
    domain_groups: 'Domain Groups',
    /* eslint-enable camelcase */
  };

  const iconForStat = {
    /* eslint-disable camelcase */
    risk_reduction: <React.Fragment></React.Fragment>,
    vulnerability_instances: <InlineSVG type="vulnerabilitiesAlt" />,
    patches: <InlineSVG type="patchesAlt" />,
    unsuperseded_patches: <InlineSVG type="patchesAlt" />,
    risk_rating: <React.Fragment></React.Fragment>,
    sensitive_nodes: <InlineSVG type="folderAlt" />,
    vulnerabilities: <InlineSVG type="vulnerabilitiesAlt" />,
    exploit_status: <React.Fragment></React.Fragment>,
    hosts: <InlineSVG type="hostsAlt" />,
    active_hosts: <InlineSVG type="hostsAlt" />,
    domain_groups: <InlineSVG type="groupsAlt" />,
    /* eslint-enable camelcase */
  };

  const needsAlertIcon = () => stat === 'sensitive_nodes' && getAttributeForStat[stat]() !== '0';

  const getAttributeForStat = {
    /* eslint-disable camelcase */
    risk_reduction: () => <RiskReduction item={item} itemType={reportType} riskType={riskType} />,
    vulnerability_instances: () => formatNumber( instanceData?.tally?.results?._total ),
    patches: () => formatNumber( item.num_patches ),
    unsuperseded_patches: () => formatNumber( item.num_unsuperseded_patches ),
    risk_rating: () => <RatingBadge item={item} itemType={reportType} rating={riskToRating( item[riskType] )} />,
    sensitive_nodes: () => formatNumber( item.num_sensitive_nodes ),
    vulnerabilities: () => formatNumber( item.num_vulnerabilities ),
    exploit_status: () => <ExploitStatus status={ item.exploit_status } />,
    active_hosts: () => formatNumber( item.active_hosts?.length ),
    domain_groups: () => formatNumber( item.domain_groups?.length ),
    hosts: () => formatNumber( item.num_hosts ),
    /* eslint-enable camelcase */
  };

  // pulling this out so that I don't have to repeat it down below
  const statContent = ( item, stat, riskType ) => {
    return (
      <React.Fragment>
        <span className="statisticLabel">
          { labelForStat[stat] }
          {
            ( isNotEmpty( getInsightURL( item, reportTypeForStat[stat], reportType, riskType ) ) ) &&
            <InlineSVG type="newTabLink" />
          }
        </span>
        {/* eslint-disable-next-line max-len */}
        <span className={ `statisticValue ${stat} ${riskToRating( item[riskType] )} ${needsAlertIcon() ? 'needsAlert' : ''}` }>
          {
            needsAlertIcon()
              ? <svg
                viewBox="0 0 32 32"
                fill="none"
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                className="svgNodeIcon"
              >
                { nodeIconMap.shared_folder_permission_sensitive }
              </svg>
              : iconForStat[stat]
          }
          { getAttributeForStat[stat]() }
        </span>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( item ) && isNotEmpty( stat ) ) &&
        <React.Fragment>
          {
            isNotEmpty( getInsightURL( item, reportTypeForStat[stat], reportType, riskType ) )
              ? <a
                className={ `itemStatisticWrapper ${stat} asLink` }
                href={getInsightURL( item, reportTypeForStat[stat], reportType, riskType ) }
                target="_blank"
                rel="noreferrer noopener"
              >
                { statContent( item, stat, riskType ) }
              </a>
              : <div className={ `itemStatisticWrapper ${stat}` }>
                { statContent( item, stat, riskType ) }
              </div>
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ItemStatistic;