/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import EmptyState from '../../../../EmptyState';

import SelectedItemWrapper from './SelectedItemWrapper';

import './style.scss';

import {
  isNotEmpty,
} from '../../../../Utilities';

import SearchResults from '../SearchResults';
import Modal from '../../../../Modal';
import InlineSVG from '../../../../InlineSVG';
import { getRecords } from '../../../../RecordCache';

const ModalBody = ( {
  items,
} ) => {
  return (
    <React.Fragment>
      {
        isNotEmpty( items )
          ? <ul>
            {
              Object.entries( items ).map( ( [ key, item ], index ) => {
                return  <li
                  key={index}
                >
                  <a
                    // eslint-disable-next-line max-len
                    href={`#.=risk_insight&report=hosts&item_count=100&sort_by=risk&sort_direction=DESC&sensitive_assets=any&item=${key}&current_page=1`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    { item }
                    <InlineSVG type="link" version="primary" />
                  </a>
                </li>;
              } )
            }
          </ul>
          : <EmptyState message="No Items" />
      }
    </React.Fragment>
  );
};

const InputBody = ( {
  items,
  value,
  formState,
  onChange,
  setItems,
  field,
  fields,
  onDragEnd,
} ) => {

  const [ showAll, setShowAll ] = React.useState( false );

  const fieldRef = React.useRef( null );

  const handleSelect = ( { id, label } ) => {
    const _items = { [id]: label, ...items };

    onChange( field, Object.keys( _items ) );
    setItems( _items );
  };

  return (
    <React.Fragment>
      <Modal
        elementClass="searchListFieldModal"
        header="All Items"
        visible={showAll}
        setVisible={setShowAll}
        body={ <ModalBody items={items} /> }
      />
      {/* only need to show this list in the regular version */}
      {
        !field.inlineVersion &&
        <React.Fragment>
          {
            isNotEmpty( items )
              ? <div className="selectedItems">
                {
                  field.needsDraggable
                    ? <DragDropContext onDragEnd={ result => onDragEnd( result, items )}>
                      <Droppable droppableId="droppable">
                        {
                          ( provided, snapshot ) => (
                            <ul
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className={`${snapshot.isDraggingOver ? 'isDragging' : ''}`}
                            >
                              {
                                Object.keys( items ).map( ( id, index ) => {
                                  if ( index <= 9 ) {
                                    return  <SelectedItemWrapper
                                      key={index}
                                      order={index + 1}
                                      id={id}
                                      items={items}
                                      setItems={setItems}
                                      field={field}
                                      index={index}
                                      onChange={onChange}
                                    />;
                                  }
                                } )
                              }
                              {provided.placeholder}
                            </ul>
                          )
                        }
                      </Droppable>
                    </DragDropContext>
                    : <ul>
                      {
                        Object.keys( items ).map( ( id, index ) => {
                          if ( index <= 9 ) {
                            return  <SelectedItemWrapper
                              key={index}
                              order={index + 1}
                              id={id}
                              items={items}
                              setItems={setItems}
                              field={field}
                              index={index}
                              onChange={onChange}
                            />;
                          }

                        } )
                      }
                    </ul>
                }
                {
                  Object.keys( items ).length > 10 &&
                  <button
                    className="showMoreButton"
                    onClick={ () => setShowAll( true ) }
                  >
                    { `+ ${Object.keys( items ).length -10 } more...` }
                  </button>
                }
              </div>
              : <EmptyState message={ `No ${field.label} Selected` } />
          }
        </React.Fragment>
      }
      <SearchResults
        field={ { ...field, selectCallback: handleSelect, clearOnSelect: true } }
        value={value}
        alreadySelectedItems={ items }
        setItems={setItems}
        formState={formState}
        onChange={onChange}
        fieldRef={fieldRef}
      />
      {
        ( field.removable ) &&
        <button
          className="removeFieldButton"
          onClick={ () => field.onRemove( field, fields ) }
        >
          <InlineSVG type="close" />
        </button>
      }
    </React.Fragment>
  );
};

const SearchList = ( {
  field,
  fields,
  originalValue,
  formState,
  onChange,
  existingRecord,
} ) => {

  const [ items, setItems ] = React.useState( {} );

  React.useEffect( () => {
    if ( isNotEmpty( field ) && isNotEmpty( originalValue ) && isNotEmpty( field.recordType ) ) {
      const params =  {
        // eslint-disable-next-line camelcase
        id_list: originalValue,
        // eslint-disable-next-line camelcase
        extra_columns: [
          'label',
        ],
      };
      getRecords(
        field.recordType === 'host' ? 'scope' : field.recordType,
        params,
      ).then( response => {
        if ( isNotEmpty( response ) ) {
          const _items = {};
          response.map( i => {
            _items[i.id] = i.label;
          } );
          setItems( _items );
        }
      } );
    } else {
      setItems( [] );
    }
  }, [ existingRecord, field, originalValue ] );

  const reorder = ( list, startIndex, endIndex ) => {

    const newList = {};
    const result = Array.from( Object.keys( list ) );
    const [ removed ] = result.splice( startIndex, 1 );
    result.splice( endIndex, 0, removed );

    result.map( id => {
      newList[id] = list[id];
    } );

    setItems( newList );
  };

  const onDragEnd = ( result, items ) => {
    // dropped outside the list
    if ( !result.destination ) {
      return;
    }

    reorder(
      items,
      result.source.index,
      result.destination.index,
    );
  };

  return (
    <div
      className="searchListContainer asVertical"
    >
      <InputBody
        items={items}
        setItems={setItems}
        field={field}
        fields={fields}
        originalValue={originalValue}
        formState={formState}
        onChange={onChange}
        onDragEnd={onDragEnd}
      />
    </div>
  );
};

export default SearchList;
