/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty, riskToRating } from '../../../../shared/Utilities';
import InstancesBreakdown from '../../../../shared/Charts/InstancesBreakdown';
import { makeRequest } from '../../../../../legacy/io';
import RecordStat from './RecordStat';
import CVSSCategories from '../../../../shared/CVSSCategories';

import './PatchContent.scss';

const PatchContent = ( { patch, options } ) => {

  const [ instanceData, setInstanceData ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( false );

  const svgWrapperRef = React.useRef( null );

  React.useEffect( () => {
    if ( isNotEmpty( patch ) ) {
      const tallyParams = {
        project: 'default',
        model: 'base',
        filters: {
          // eslint-disable-next-line camelcase
          patch_ids: [ patch.id ],
          // eslint-disable-next-line camelcase
          risk_type: patch.risk ? 'risk' : 'direct_risk',
        },
      };

      setLoading( true );
      // if this is a host, get the necessary instance data for the visual
      makeRequest( 'TALLY', '/analysis/instance', tallyParams ).then( response => {
        setLoading( false );
        if ( isNotEmpty( response ) ) {
          setInstanceData( response );
        }
      } );
    }
  }, [ patch ] );

  return (
    <React.Fragment>
      <p>
        { patch.description }
      </p>
      {
        isNotEmpty( options )
          ? <React.Fragment>
            {
              ( 'includeInstances' in options && options.includeInstances === true ) &&
              <React.Fragment>
                {
                  ( isNotEmpty( patch ) && isNotEmpty( instanceData ) ) &&
                  <InstancesBreakdown
                    reportType="patch"
                    itemID={ patch.id }
                    riskRating={ riskToRating( patch.risk ? patch.risk : patch.direct_risk ) }
                    instanceData={ instanceData }
                    svgRef={ svgWrapperRef }
                    loading={ loading }
                  />
                }
              </React.Fragment>
            }
          </React.Fragment>
          : <React.Fragment>
            {
              ( isNotEmpty( patch ) && isNotEmpty( instanceData ) ) &&
              <InstancesBreakdown
                reportType="patch"
                itemID={ patch.id }
                riskRating={ riskToRating( patch.risk ? patch.risk : patch.direct_risk ) }
                instanceData={ instanceData }
                svgRef={ svgWrapperRef }
                loading={ loading }
              />
            }
          </React.Fragment>
      }
      {
        (
          isNotEmpty( patch )
          && isNotEmpty( instanceData )
          && isNotEmpty( options )
          && ( options.includeCVSS )
        ) &&
        <CVSSCategories data={ instanceData } />
      }
      {
        isNotEmpty( patch ) &&
        <div className="recordStatistics">
          <RecordStat statKey="hosts" statValue={ patch.hosts?.length } />
          <RecordStat statKey="vulnerabilities" statValue={ patch.vulnerabilities?.length } />
        </div>
      }
    </React.Fragment>
  );
};

export default PatchContent;