/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatUnixTime, isEmpty, isNotEmpty } from '../Utilities';
import InlineSVG from '../InlineSVG';

import './style.scss';

const Content = ( { scanItem, getAgeClass } ) => {
  return <React.Fragment>
    <div className="indicatorAndLabel">
      <span className={ `lastScanAgeIndicator ${getAgeClass()}` } />
      {
        ( isNotEmpty( scanItem.timestamp ) && scanItem.timestamp !== 0 ) &&
        <span className="lastScanTimestamp">{ formatUnixTime( scanItem.timestamp ) }</span>
      }
      {
        ( scanItem.timestamp === 0 || isEmpty( scanItem.timestamp ) ) &&
        <span className="lastScanTimestamp">N/A</span>
      }
      {
        isNotEmpty( scanItem.ip ) &&
        <strong className="lastScanIP">({scanItem.ip})</strong>
      }
    </div>
    {
      isNotEmpty( scanItem.ip ) &&
      <InlineSVG type="newTabLink" />
    }
  </React.Fragment>;
};

const LastScanLink = ( { scanItem } ) => {

  const getAgeClass = () => {
    const sevenDaysAgo = new Date().getTime() - ( 7 * 24 * 60 * 60 * 1000 );
    const thirtyDaysAgo = new Date().getTime() - ( 30 * 24 * 60 * 60 * 1000 );

    const ts = scanItem.timestamp * 1000;

    if ( ts >= sevenDaysAgo ) {
      return 'green';
    }

    if ( ts > thirtyDaysAgo && ts < sevenDaysAgo ) {
      return 'grey';
    }

    if ( isEmpty( ts ) || ts === 0 || ts <= thirtyDaysAgo ) {
      return 'red';
    }


    return 'grey';
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( scanItem ) && isNotEmpty( scanItem.ip ) ) &&
        <a
          className={ `lastScanLink ${getAgeClass()}` }
          href={ `#.=activity&page=scan_logs&item_count=100&item=${scanItem.ip}&current_page=1` }
          target="_blank"
          rel="noopner noreferer"
        >
          <Content scanItem={scanItem} getAgeClass={getAgeClass} />
        </a>
      }
      {
        ( isNotEmpty( scanItem ) && isEmpty( scanItem.ip ) ) &&
        <div
          className={ `lastScanLink ${getAgeClass()} notLink` }
        >
          <Content scanItem={scanItem} getAgeClass={getAgeClass} />
        </div>
      }
    </React.Fragment>
  );
};

export default LastScanLink;