/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { globalColors, isNotEmpty } from '../../../../../shared/Utilities';
import EmptyLoading from '../../../../../shared/EmptyLoading';
import CVSSChart from '../../../../../shared/Charts/CVSSChart';
import { fetchForWidgetsV2, v2Fetches } from '../../shared';

import './CVSSBreakdown.scss';
import Legend from '../../../../../shared/Charts/Legend';
import Donut from '../../../../../shared/Charts/Donut';
import Categories from '../../../../../shared/Charts/Categories';

const CVSSBreakdown = ( { prefetchedData, settings } ) => {

  const order = [
    'Critical',
    'High',
    'Moderate',
    'Low',
    'Minimal',
  ];

  const minMax = {
    critical: {
      maximum: 10,
      minimum: 9,
    },
    high: {
      maximum: 9,
      minimum: 7.5,
    },
    moderate: {
      maximum: 7.5,
      minimum: 6,
    },
    low: {
      maximum: 6,
      minimum: 3,
    },
    minimal: {
      maximum: 3,
      minimum: 0,
    },
  };

  const [ chartData, setChartData ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( true );
  const [ total, setTotal ] = React.useState( null );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );

  const setupData = response => {
    if (
      isNotEmpty( response )
      && isNotEmpty( response.tally )
      && isNotEmpty( response.tally.results )
      && isNotEmpty( response.tally.results.cvss )
    ) {
      const _chartData = {};
      const _counts = {
        critical: response.tally.results.cvss.critical,
        high: response.tally.results.cvss.high,
        moderate: response.tally.results.cvss.moderate,
        low: response.tally.results.cvss.low,
        minimal: response.tally.results.cvss.minimal,
      };

      const _total = response.tally.results.cvss.critical
        + response.tally.results.cvss.high
        + response.tally.results.cvss.moderate
        + response.tally.results.cvss.low
        + response.tally.results.cvss.minimal;

      const zeroed = _total === 0;

      order.map( name => {

        let _value = _counts[name.toLowerCase()];

        if ( settings?.version === 'donut' ) {
          _value = zeroed ? zeroed : ( ( _counts[name.toLowerCase()] / _total ) * 100 );
        }
        _chartData[name.toLowerCase()] = {
          key: name.toLowerCase(),
          label: name,
          // eslint-disable-next-line max-len
          fill: settings?.version === 'horizontal' ? globalColors[`${name.toLowerCase()}--75`] : globalColors[name.toLowerCase()],
          // eslint-disable-next-line max-len
          stroke: settings?.version === 'horizontal' ? globalColors[`${name.toLowerCase()}--75`] : globalColors[name.toLowerCase()],
          total: _counts[name.toLowerCase()],
          value: _value,
          subLabel: `${ minMax[name.toLowerCase()].maximum} - ${ minMax[name.toLowerCase()].minimum}`,
        };
      } );
      setTotal( _total );
      setChartData( _chartData );
    }
    setLoading( false );
  };

  const refetchData = async () => {
    const fetchKeys = fetchForWidgetsV2.vulnerability_instances_cvss_breakdown;

    if ( isNotEmpty( fetchKeys ) ) {
      const fetches = [];
      const _data = {};
      fetchKeys.map( k => {
        fetches.push( v2Fetches[k]() );
      } );

      if ( isNotEmpty( fetches ) ) {
        const resolvedFetches = await Promise.all( fetches );

        resolvedFetches.map( ( data, index ) => {

          _data[fetchKeys[index]] = data;
        } );
        setupData( _data );
      }
    }
  };

  React.useEffect( ( ) => {
    setLoading( true );

    if ( isNotEmpty( prefetchedData ) && isNotEmpty( prefetchedData.tally ) ) {
      setupData( prefetchedData );
    } else {
      refetchData();
    }
  }, [ prefetchedData, settings ] );

  return (
    <React.Fragment>
      <EmptyLoading
        payload={ chartData }
        loading={ loading }
        loadingMessage="Loading CVSS data"
        emptyMessage="No CVSS data"
      />
      {
        ( isNotEmpty( chartData ) && settings?.version === 'horizontal' ) &&
        <Categories
          data={ chartData }
          total={ total }
          hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
          setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
        />
      }
      {
        ( isNotEmpty( chartData ) && settings?.version === 'barchart' ) &&
        <div className="chartWrapper">
          <CVSSChart
            cvssData={chartData}
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
        </div>

      }
      {
        ( isNotEmpty( chartData ) && settings?.version === 'donut' && isNotEmpty( total ) ) &&
        <Donut
          data={chartData}
          total={total}
          hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
          setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
        />
      }
      {
        isNotEmpty( chartData ) &&
        <Legend
          horizontal={ settings?.version === 'horizontal' }
          legendData={ chartData }
          hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
          setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
        />
      }
    </React.Fragment>
  );
};

export default CVSSBreakdown;