/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { globalColors, isEmpty, isNotEmpty } from '../Utilities';

const ChartHoverIndicators = ( {
  data,
  onClickCallback=() => {},
  onHoverCallback=() => {},
  includePoint=true,
  includeLine=true,
  includeRectangle=true,
  stroke='darkBlue',
  fill='darkBlue',
  currentPoint,
  containerWidth,
  containerHeight,
  fullHeight=false,
} ) => {

  const _containerHeight = fullHeight ? containerHeight * 0.85 : containerHeight * 0.4;
  const svgHeight = _containerHeight - 10;
  const svgWidth = containerWidth - 10;
  const xGutter = ( containerWidth - svgWidth ) / 2;
  const yGutter = ( _containerHeight - svgHeight ) / 2;

  const [ points, setPoints ] = React.useState( null );

  // sets the max height for calculating points
  React.useEffect( ( ) => {
    if ( isNotEmpty( data ) && isNotEmpty( data.transformed ) && isNotEmpty( data.max ) && isNotEmpty( data.yAxis ) ) {
      const _points = [];

      Object.values( data.transformed ).map( ( point ) => {
        if ( point[data.yAxis] > data.max ) {
          data.max = point[data.yAxis];
        }
      } );
      const _widthRatio = svgWidth / ( Object.values( data.transformed ).length - 1 );
      const _heightRatio = svgHeight / data.max;

      Object.values( data.transformed ).map( ( point, index ) => {
        let x;

        const y = ( svgHeight - ( _heightRatio * point[data.yAxis] ) ) + yGutter;

        // points are not necessarily spaced out evenly, need to find the distance from the left based on
        // the xAxis series, if no xAxis is passed in... need to assume even spacing
        if ( isNotEmpty( data.xAxis ) ) {
          const firstPointValue = Object.values( data.transformed )[0][data.xAxis];
          // eslint-disable-next-line max-len
          const lastPointValue = Object.values( data.transformed )[ Object.values( data.transformed ).length - 1][data.xAxis];
          const delta = lastPointValue - firstPointValue;
          const newWidthRatio = svgWidth / delta;
          x = ( newWidthRatio * ( point[data.xAxis] - firstPointValue ) ) + xGutter;

        // evenly spaced out
        } else {
          x = ( _widthRatio * index ) + xGutter;
        }

        const _height = svgHeight;
        _points.push( { _height, x, y, id: point.id, original: point } );
      } );

      setPoints( _points );
    }
  }, [ data, containerHeight, containerWidth ] );

  const shouldHighlightPoint = point => {
    if ( point?.id === currentPoint?.id ) {
      return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( data ) && isNotEmpty( containerHeight ) && isNotEmpty( points ) ) &&
        <g className="hoverIndicatorsGroup">
          {/* draw an invisible bar for each point for hovering and clicking, etc.  */}
          {/* This will make highlight and selection much easier */}
          {
            points.map( ( point, index ) => {
              return <g
                key={index}
                className={ `hoverIndicatorWrapper ${ shouldHighlightPoint( point ) ? 'highlight' : '' }` }
              >
                {
                  includeLine &&
                  <line
                    x1={ ( isEmpty( point.x ) || isNaN( point.x ) ) ? 0 : point.x }
                    x2={ ( isEmpty( point.x ) || isNaN( point.x ) ) ? 0 : point.x }
                    y1={ 0 }
                    y2={ containerHeight - 25 - 25 }
                    stroke={ globalColors[stroke] }
                    id={ `areaChartHighlightLine_${point.id}` }
                    className={ `areaChartHighlightLine ${ shouldHighlightPoint( point ) ? 'highlight' : '' }` }
                    strokeWidth={ 1.5 }
                  />
                }
                {
                  includePoint &&
                  <circle
                    key={index}
                    cx={ ( isEmpty( point.x ) || isNaN( point.x ) ) ? 0 : point.x }
                    cy={ ( isEmpty( point.y ) || isNaN( point.y ) ) ? 0 : point.y }
                    r={ 4 }
                    className={ `areaChartPoint ${ shouldHighlightPoint( point ) ? 'highlight' : '' }` }
                    id={ `areaChartPoint_${point.id}` }
                    // eslint-disable-next-line max-len
                    fill={ globalColors[fill] }
                  />
                }
                {
                  includeRectangle &&
                  <rect
                    // eslint-disable-next-line max-len
                    x={ ( isEmpty( point.x ) || isNaN( point.x ) ) ? 0 : point.x - 7.5 }
                    y={0}
                    width={ 15 }
                    height={ containerHeight - 25 - 25 }
                    fill={ globalColors[fill] }
                    fillOpacity="0.03"
                    id={ `areaChartHoverRect_${point.id}` }
                    onMouseEnter={ () => onHoverCallback( point, data ) }
                    onMouseLeave={ () => onHoverCallback( null, data ) }
                    onClick={ e => onClickCallback( e, point, true ) }
                    className={ `areaChartHoverRect ${ shouldHighlightPoint( point ) ? 'highlight' : '' }` }
                  />
                }
              </g>;
            } )
          }
        </g>
      }
    </React.Fragment>
  );
};

export default ChartHoverIndicators;