/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  capitalize,
  decodeURLHash,
  encodeURLHash,
  isEmpty,
  isNotEmpty,
  paramsToFilters,
  pluralizeType,
  userDisplayName,
} from '../../../shared/Utilities';
import Dropdown from '../../../shared/Dropdown';
import AddToPlanMenu from '../../Remediation/AddToPlanMenu/index.js';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';
import { makeRequest } from '../../../../legacy/io';
import { defaultRemdiationItems, planOptionsForReportType, remediationItemsKey, reportTypes } from './shared';
import { ReportCreatorContext } from '../../../Contexts/ReportCreator';
import InlineSVG from '../../../shared/InlineSVG';
import IndeterminantPagination from '../../../shared/Pagination/IndeterminantPagination';

const ReportSpecificFields = ( {
  reportType,
  onRefresh,
} ) => {

  // adjusts the groupType and resets to the first page
  const handleGroupChange = type => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { group_type: type, current_page: 1, sort_by: 'filtered_risk', sort_direction: 'DESC' } );
    onRefresh();
  };

  return (
    <React.Fragment>
      {
        reportType === 'instance' &&
        <div className="groupTypeSelector selectFieldWrapper">

          <label>
            <span className="labelWrapper">Instances grouped by: </span>
            <select
              value={ decodeURLHash().group_type }
              onChange={ e => handleGroupChange( e.target.value ) }
            >
              <option value="host" >Host</option>
              <option value="patch_cumulative" >Patch (Cumulative)</option>
              <option value="patch" >Patch (Non-cumulative)</option>
              <option value="vulnerability" >Vulnerability</option>
              <option value="signature" >Scanner Signature</option>
            </select>
          </label>
        </div>
      }
    </React.Fragment>
  );
};

const InsightTableActions = ( {
  reportType,
  records,
  refreshTable,
  remediationItems,
  setRemediationItems,
  currentPageNumber,
  nextRecords,
  onRefresh,
  setScrollToTableTop,
} ) => {

  const [ currentUser, , ] = React.useContext( CurrentUserContext );

  const [ , , openReportCreator, , , ] = React.useContext( ReportCreatorContext );

  const exportReport = format => {
    const today = new Date();
    // eslint-disable-next-line max-len
    const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}-${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}`;

    const hash = decodeURLHash();

    const reportType = isNotEmpty( hash.report ) ? hash.report : 'vulnerability_data';
    const newReport = {
      owner: currentUser.id,
      // eslint-disable-next-line max-len
      label: `${userDisplayName( currentUser, true )}_${capitalize( reportTypes[reportType] )}_Report---${date}`,
      format,
      type: reportTypes[reportType],
    };

    const filters = paramsToFilters();
    delete filters['creating_report'];

    const _filters = { ...filters };

    // eslint-disable-next-line camelcase
    const gt_map = {};
    // eslint-disable-next-line camelcase
    const lt_map = {};

    const isInequalityType = [
      'age',
      'risk_reduction',
      'num_hosts',
      'cvss_base_score',
    ];

    if ( isNotEmpty( _filters ) ) {
      Object.entries( _filters ).map( ( [ key, val ] ) => {
        if ( isInequalityType.includes( key ) ) {
          delete _filters[key];
          const [ inequality, unit ] = val;

          if ( isNotEmpty( unit ) ) {
            if ( inequality === 'gt_map' ) {
              // eslint-disable-next-line camelcase
              gt_map[key] = parseFloat( unit );
            } else if ( inequality === 'lt_map' ) {
              // eslint-disable-next-line camelcase
              lt_map[key] = parseFloat( unit );
            }
          }
          if ( isNotEmpty( gt_map ) ) {
            // eslint-disable-next-line camelcase
            _filters.gt_map = gt_map;
          }
          if ( isNotEmpty( lt_map ) ) {
            // eslint-disable-next-line camelcase
            _filters.lt_map = lt_map;
          }
        } else {
          _filters[key] = val;
        }
      } );
    }

    if ( format === 'xlsx' ) {
      // eslint-disable-next-line camelcase
      _filters.full_xlsx_results = true;
    }

    newReport.filters = _filters;

    makeRequest( 'ADD', '/model/base/exported_report', { record: newReport } ).then( response => {
      if ( response && response.results ) {
        makeRequest( 'START', '/model/base/exported_report', { id: response.results.id } ).then( () => {
          window.location.href = `#.=reporting&page=exports&report_id=${response.results.id}`;
        } );
      }
    } );
  };

  // for the table actions (currently only has export options)
  const getExportMenuItems = () => {
    const items = [
      <div
        onClick={ () => exportReport( 'xlsx' ) }
        title={`Export ${reportType} report`}
      >
        <InlineSVG type="xlsx" size="xlarge" version="special" />
        <span>Quick Export as .xlsx</span>
      </div>,
    ];

    if ( reportType !== 'vulnerability_data' ) {
      items.push(
        <div
          onClick={ () => exportReport( 'pdf' ) }
          title={`Export ${reportType} report`}
        >
          <InlineSVG type="pdf" size="xlarge" version="special" />
          <span>Quick Export as .pdf</span>
        </div>,
      );
    }

    items.push(
      <div onClick={ () => openReportCreator() }>
        <InlineSVG type="setup" />
        Configure Export
      </div>,
    );

    return items;
  };

  const goToPage = page => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { current_page: parseInt( page ) } );
    setScrollToTableTop( true );
    onRefresh();
  };

  const reportTableHeader = () => {
    const hash = decodeURLHash();

    const pageNumber = hash.current_page || 1;
    const itemsPerPage = hash.item_count || 100;
    const recordCount = records?.length || 0;

    const beginning = itemsPerPage * ( pageNumber - 1 );
    const end = beginning + recordCount;

    let title = '';

    if ( reportType !== 'instance' ) {
      if ( isEmpty( records ) ) {
        title = `${ capitalize( pluralizeType( reportType ) ) } (0)`;
      } else {
        title = `${ capitalize( pluralizeType( reportType ) ) } (${beginning + 1} - ${end})`;
      }
    } else {
      title = `(${beginning + 1} - ${end})`;
    }
    return title;
  };

  const addToPlanMenuCallback = ( isAcceptedRisk=false ) => {
    setRemediationItems( defaultRemdiationItems );
    if ( isAcceptedRisk ) {
      onRefresh();
    }
  };

  const handleItemCountChange = count => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { item_count: count, current_page: 1 } );
    onRefresh();
  };

  return (
    <div className="insightTableHeaderActions">
      {
        isNotEmpty( remediationItems[remediationItemsKey( reportType )] ) &&
        <AddToPlanMenu
          items={ remediationItems[ remediationItemsKey( reportType ) ] }
          itemsKey={ remediationItemsKey( reportType ) }
          callback={ addToPlanMenuCallback }
          planOptions={ planOptionsForReportType[remediationItemsKey( reportType )] }
        />
      }
      {
        <ReportSpecificFields reportType={reportType} onRefresh={refreshTable} />
      }
      <h2>
        { reportTableHeader( ) }
      </h2>
      <div className="itemCountSelector asButton selectFieldWrapper">
        <label>
          <select
            value={ decodeURLHash().item_count || 100 }
            onChange={ e => handleItemCountChange( e.target.value ) }
          >
            <option value={ 10 } >View 10</option>
            <option value={ 25 } >View 25</option>
            <option value={ 50 } >View 50</option>
            <option value={ 100 } >View 100</option>
          </select>
        </label>
      </div>
      <IndeterminantPagination
        currentPageNumber={currentPageNumber}
        nextPageResults={nextRecords}
        goToPage={goToPage}
        elementClass="riskInsightPagination"
      />
      {
        ( reportType !== 'path' ) &&
        <Dropdown
          trigger={
            <button className="exportMenuTrigger">
              <InlineSVG type="exportFile" version="primary" />
              <span>Export</span>
            </button>
          }
          menuItems={ getExportMenuItems() }
        />
      }
    </div>
  );
};

export default InsightTableActions;