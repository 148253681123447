/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty, reportTypeDisplayName, riskToRating } from '../../../../shared/Utilities';
import InlineSVG from '../../../../shared/InlineSVG';
import RatingBadge from '../../../../shared/RatingBadge';
import RiskReduction from '../../../../shared/RiskReduction';

const ScopeHeader = ( { scope, options } ) => {
  return (
    <React.Fragment>
      {
        isNotEmpty( scope ) &&
        <React.Fragment>
          <div className="recordCardHeaderRow">
            <h2>
              <InlineSVG type="hostsAlt" elementClass="recordTypeHeaderIcon" />
              <span className="label">{ reportTypeDisplayName( scope, 'scope' ) }</span>
            </h2>
            {
              ( isNotEmpty( options ) && 'includeRisk' in options )
                ? <React.Fragment>
                  {
                    options.includeRisk === true &&
                    <RatingBadge rating={ riskToRating( scope.risk ? scope.risk : scope.filtered_risk ) } />
                  }
                </React.Fragment>
                : <RatingBadge rating={ riskToRating( scope.risk ? scope.risk : scope.filtered_risk ) } />
            }
          </div>
          <div className="recordCardHeaderRow">
            <div className="left">
              {
                scope.type === 'host' &&
                <a
                  // eslint-disable-next-line max-len
                  href={ `#.=risk_insight&report=hosts&item_count=100&sensitive_assets=any&sort_by=risk&sort_direction=DESC&item=${scope.id}&current_page=1` }
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Learn more
                  <InlineSVG type="newTabLink" />
                </a>
              }
            </div>
            {
              ( isNotEmpty( options ) && 'includeRisk' in options )
                ? <React.Fragment>
                  {
                    options.includeRisk === true &&
                    <RiskReduction item={scope} riskType={ scope.risk ? 'risk' : 'filtered_risk' } />
                  }
                </React.Fragment>
                : <RiskReduction item={scope} riskType={ scope.risk ? 'risk' : 'filtered_risk' } />
            }
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ScopeHeader;