/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { makeRequest } from '../../../../../legacy/io';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue';
import Loading from '../../../../shared/Loading';
import { decodeURLHash, isEmpty, isNotEmpty, getExternalUsers } from '../../../../shared/Utilities';
import IntegrationForm from './IntegrationForm';
import UserMapping from './UserMapping';

const IntegrationModal = ( {
  selectedRecord,
  setSelectedRecord,
  showModal,
  setShowModal,
  onSave,
} ) => {

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  // this is set in the form onChange callback
  const [ updatedForm, setUpdatedForm ] = React.useState( null );
  const [ formIsValid, setFormISValid ] = React.useState( true );
  const [ mappedUsersValid, setMappedUsersValid ] = React.useState( true );

  const [ isMappingUsers, setIsMappingUsers ] = React.useState( false );

  const [ userMappings, setUserMappings ] = React.useState( null );
  const [ externalUsers, setExternalUsers ] = React.useState( null );

  const [ loading, setLoading ] = React.useState( false );
  const [ cachedProjects, setCachedProjects ] = React.useState( null );
  // const [ cachedIntegrationID, setCachedIntegrationID ] = React.useState( null );

  // whenever the mapped users changes, check to make sure they all have values, if any are empty, disable submit
  React.useEffect( ( ) => {
    setMappedUsersValid( true );
  }, [ userMappings ] );

  // whenever the modal closes, reset
  React.useEffect( ( ) => {
    if ( showModal === false ) {
      setIsMappingUsers( false );
      setSelectedRecord( null );
      setUserMappings( null );
    }
  }, [ showModal ] );

  // the user may have been redirected here from remediation, in that case, send them back there
  const handleRedirects = () => {
    const hash = decodeURLHash();
    if ( isNotEmpty( hash.redirect_url ) ) {
      window.location.href = decodeURIComponent( hash.redirect_url );
    }
  };

  // callback for when externalUsers are returned, sets up the user mapping screen
  const externalUsersCallback = ( externalUsers ) => {
    loadUserMapping( externalUsers );
  };

  // for the first step, saves without mapping over any users
  const saveAndExit = async () => {

    const updatedRecord = await onSave( selectedRecord, formIsValid, updatedForm?.fieldStates, cachedProjects );

    if ( isNotEmpty( updatedRecord ) ) {
      // setCachedIntegrationID( updatedRecord.id );
      setShowModal( false );
      setIsMappingUsers( false );

      handleRedirects();
    }
  };

  // If we have everything ready to map users, this sets up all the necessary vars
  const loadUserMapping = async ( _externalUsers ) => {

    const usersRequest = await makeRequest( 'SEARCH', '/user', {
      // eslint-disable-next-line camelcase
      extra_columns: [
        'authentication_provider_id',
        'username',
        'given_name',
        'family_name',
        'api_key',
        'setup_wizard_disabled',
        'email_address',
      ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'username', 'ASC' ] ],
    } );

    // we have everything we need
    if (
      isNotEmpty( usersRequest )
      && isNotEmpty( usersRequest.results )
      && isNotEmpty( _externalUsers )
    ) {

      const _userMappings = {};

      usersRequest.results.map( u => {

        const externalUser = Object.values( _externalUsers ).find( eu => eu.web_user_id === u.id );
        const thisUser = { ...u };

        if ( isNotEmpty( externalUser ) ) {
          thisUser.externalUserID = externalUser.id;
        } else {
          thisUser.externalUserID = null;
        }
        _userMappings[u.id] = thisUser;
      } );

      setUserMappings( _userMappings );
      setExternalUsers( _externalUsers );
      setIsMappingUsers( true );
      setLoading( false );
    } else {
      setExternalUsers( null );
      setUserMappings( null );
      setIsMappingUsers( false );
      // eslint-disable-next-line max-len
      addFlashMessage( { type: 'alert', body: 'There was an error fetching the users. Please ensure you have the correct credentials and try again.' } );
      setLoading( false );
    }
  };

  // for the first step, saves and then sets the state to map all users, this will potentially take a while
  const saveAndMapUsers = async ( ) => {
    const updatedRecord = await onSave( selectedRecord, formIsValid, updatedForm?.fieldStates, cachedProjects );

    if ( isNotEmpty( updatedRecord ) ) {

      setSelectedRecord( updatedRecord );

      // first see if there are cached users already
      const externalUsersResponse = await makeRequest(
        'SEARCH',
        '/model/base/external_users',
        // eslint-disable-next-line camelcase
        { third_party_setting_id: updatedRecord.id },
      );

      if ( isEmpty( externalUsersResponse.results ) ) {
        if (
          // eslint-disable-next-line max-len
          confirm( 'The external users have not yet been cached into our system. The users can be fetched, but it might take several seconds.' )
        ) {
          getExternalUsers( updatedRecord.id, externalUsersCallback );
        }
      } else {
        getExternalUsers( updatedRecord.id, externalUsersCallback );
      }
    }
  };

  // for the second step, saves all the mapped users
  const saveMappedUsers = async () => {
    const externalRecords = [];
    const userRecords = [];

    if ( isNotEmpty( userMappings ) && isNotEmpty( externalUsers ) ) {

      Object.values( userMappings ).map( user => {
        if ( isNotEmpty( user.externalUserID ) ) {
          // eslint-disable-next-line camelcase
          userRecords.push( { id: user.id, remediation_export_setting: selectedRecord.id || null } );
        }
      } );

      Object.keys( externalUsers ).map( eID => {
        const mappedToDSUser = Object.values( userMappings ).find( dsu => dsu.externalUserID === eID );

        // eslint-disable-next-line camelcase
        externalRecords.push( { id: eID, web_user_id: mappedToDSUser ? mappedToDSUser.id : null } );
      } );
    }

    if ( isNotEmpty( userRecords ) ) {
      const userResponse = await makeRequest( 'UPSERT', '/user', { records: userRecords } );

      if ( isNotEmpty( userResponse ) && isNotEmpty( userResponse.results ) ) {
        addFlashMessage( {
          type: 'success',
          body: 'Successfully saved user remediation preference',
        } );
        setIsMappingUsers( false );
        setShowModal( false );
        handleRedirects();
      } else {
        addFlashMessage( {
          type: 'alert',
          body: 'There was an error saving the user remediation preference',
        } );
      }
    }

    if ( isNotEmpty( externalRecords ) ) {
      const externalUserResponse = await makeRequest(
        'UPDATE',
        '/model/base/external_users',
        { records: externalRecords },
      );

      if ( isNotEmpty( externalUserResponse ) && isNotEmpty( externalUserResponse.results ) ) {
        addFlashMessage( {
          type: 'success',
          body: 'Successfully mapped users',
        } );
        setIsMappingUsers( false );
        setShowModal( false );
        handleRedirects();
      } else {
        addFlashMessage( {
          type: 'alert',
          body: 'There was an error mapping users, please make sure you have entered all of the details correctly',
        } );
      }
    } else {
      addFlashMessage( {
        type: 'success',
        body: 'Integration saved without mapping any users',
      } );
      setIsMappingUsers( false );
      setShowModal( false );
      handleRedirects();
    }
  };

  const showSaveAndExit = () => {
    return updatedForm?.fieldStates?.tool?.updatedValue !== 'email' && !isMappingUsers;
  };

  const saveButtonText = () => {
    if ( isMappingUsers ) {
      return 'Save User Mappings';
    } else if ( updatedForm?.fieldStates?.tool?.updatedValue === 'email' ) {
      return 'Save';
    }
    return 'Save and Map Users';
  };

  const saveButtonAction = () => {
    if ( isMappingUsers ) {
      return saveMappedUsers();
    } else if ( updatedForm?.fieldStates?.tool?.updatedValue === 'email' ) {
      return saveAndExit();
    }
    return saveAndMapUsers();
  };

  // extra validation check for project field if it is a new record
  const integrationFormIsFullyValid = ( ) => {
    if ( isNotEmpty( selectedRecord ) ) {
      return formIsValid;
    }

    if ( updatedForm?.fieldStates?.tool?.updatedValue === 'email' ) {
      return formIsValid;
    }
    return formIsValid && isNotEmpty( updatedForm?.fieldStates?.jira_project?.updatedValue );
  };

  return (
    <React.Fragment>
      { loading && <Loading /> }
      {
        isMappingUsers
          ? <UserMapping
            selectedRecord={selectedRecord}
            userMappings={userMappings}
            setUserMappings={setUserMappings}
            externalUsers={externalUsers}
          />
          : <IntegrationForm
            selectedRecord={selectedRecord}
            updatedForm={updatedForm}
            setUpdatedForm={setUpdatedForm}
            setIsValid={setFormISValid}
            isValid={formIsValid}
            setCachedProjects={setCachedProjects}
          />
      }

      <div className="modalActions">
        <div className="buttonGroup">
          <div className="cancelButton" onClick={ () => setShowModal( false ) }>
            Cancel
          </div>
          {
            isMappingUsers
              ? <button
                className={ `${!mappedUsersValid ? 'disabled' : ''} primaryButton` }
                disabled={ !mappedUsersValid }
                onClick={ saveButtonAction }
              >
                { saveButtonText() }
              </button>
              : <button
                className={ `${!integrationFormIsFullyValid() ? 'disabled' : ''} primaryButton` }
                disabled={ !integrationFormIsFullyValid() }
                onClick={ saveButtonAction }
              >
                { saveButtonText() }
              </button>
          }

        </div>
        {
          showSaveAndExit() &&
          <button
            className={ `${!integrationFormIsFullyValid() ? 'disabled' : ''} secondaryButton` }
            disabled={ !integrationFormIsFullyValid() }
            onClick={ saveAndExit }
          >
            Save and Exit
          </button>
        }
      </div>
    </React.Fragment>
  );
};

export default IntegrationModal;