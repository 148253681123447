/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import Accordion from '../../../shared/Accordion';
import Form from '../../../shared/Form';
import { getFieldValues } from '../../../shared/Form/Shared';
import InlineSVG from '../../../shared/InlineSVG';
import {
  capitalize,
  formatUnixDate,
  isEmpty,
  isNotEmpty,
  pluralizeType,
  userDisplayName,
} from '../../../shared/Utilities';
import { makeRequest } from '../../../../legacy/io';
import Loading from '../../../shared/Loading';

const AcceptedRiskMenu = ( {
  tasks,
  tasksKey,
  callback=() => {},
  show,
  setShow,
  menuRef,
  menuPosition,
  resetMenu,
  collapsed,
  setCollapsed,
} ) => {

  const [ currentUser, , ] = React.useContext( CurrentUserContext );
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ fields, setFields ] = React.useState( null );
  const [ updatedForm, setUpdatedForm ] = React.useState( false );

  const [ formHeader, setFormHeader ] = React.useState( '' );
  const [ itemsHeader, setItemsHeader ] = React.useState( '' );
  const [ items, setItems ] = React.useState( [] );
  const [ itemsValues, setItemsValues ] = React.useState( {} );
  const [ isValid, setIsValid ] = React.useState( false );
  const [ loading, setLoading ] = React.useState( false );

  const getLabelForTaskType = ( type, item ) => {
    if ( type === 'host' ) {
      return item.local_name;
    }
    // if ( type === 'patch' ) {
    //   return `${item.vendor} ${item.identifier}`;
    // }
    if ( type === 'vulnerability' ) {
      return item.identifier;
    }
  };

  const cancelAndClose = () => {
    setShow( false );
    resetMenu();
  };
  /* eslint-disable camelcase */
  // Need to update PLAN_TYPES everytime this dict is changed.
  const REASON_OPTIONS = [
    {
      label: 'Not Exploitable',
      options: {
        false_positive: 'False positive',
        mitigated: 'Mitigated',
      },
    },
    {
      label: 'Accepting Risk',
      options: {
        decommissioning: 'Decommissioning',
        disrupts_functionality: 'Disrupts functionality',
      },
    },
  ];

  const PLAN_TYPES = {
    false_positive: 'not_exploitable',
    mitigated: 'not_exploitable',
    decommissioning: 'accepted_risk',
    disrupts_functionality: 'accepted_risk',
  };

  const EMPTY_FIELDS = [
    {
      type: 'select',
      attribute: 'reason',
      label: 'Reason:',
      required: true,
      defaultValue: 'false_positive',
      options: REASON_OPTIONS,
    },
    {
      type: 'date',
      needsUnixTransform: true,
      label: 'Expiration:',
      attribute: 'expiration',
      required: true,
      // default date is 3 months out ( fun. expects unix timestamp, need to divide by 1000 to make it work )
      defaultValue: ( Date.now() + 7_884_000_000 ) / 1000,
      // cannot be before today or greater than 9 months out.
      // ( fun. expects unix timestamp, need to divide by 1000 to make it work )
      htmlProps: {
        min: formatUnixDate( Date.now() / 1000 ),
        max: formatUnixDate( ( Date.now() + ( 7_884_000_000 * 3 ) ) / 1000 ),
      },
    },
    {
      type: 'text',
      label: 'Approved by:',
      attribute: 'owner',
      required: true,
      defaultValue: '',
    },
    {
      type: 'textarea',
      label: 'Notes:',
      attribute: 'description',
      defaultValue: '',
    },
  ];

  // gets the items (either vulns. or hosts depending on the type)
  const fetchItemsFor = async ( type, tasks ) => {

    if ( isNotEmpty( tasks ) ) {
      const search_ids = Object.keys( tasks );

      const itemsResponse = await makeRequest(
        'ITEMS',
        'model/base/accepted_risk',
        { type: type === 'host' ? 'vulnerabilities' : 'hosts', search_ids },
      );

      if ( isNotEmpty( itemsResponse ) && isNotEmpty( itemsResponse.results ) ) {
        const options = {};

        itemsResponse.results.map( result => {
          // eslint-disable-next-line max-len
          options[type === 'host' ? result.vulnerability_id : result.id] = type === 'host' ? result.identifier : result.local_name;
        } );
        setCollapsed( false );
        setItems( options );
      }
    }
  };
  /* eslint-enable camelcase */

  // 1) when we know what type of record (and how many) we are dealing with, we can setup the form, different fields
  // depending on if it is one of the following 6 scenarios:
  // 1 Host -> Many Vulnerabilities ( checkboxes )
  // 1 Patch -> Many Hosts ( checkboxes ) DISABLED UNTIL FURTHER NOTICE
  // 1 Vulnerability -> Many Hosts ( checkboxes )
  // Many Hosts -> 1 Vulnerability ( radios )
  // Many Patches -> 1 host ( radios ) DISABLED UNTIL FURTHER NOTICE
  // Many Vulnerabilities -> 1 host ( radios )
  React.useEffect( ( ) => {
    if ( isNotEmpty( tasks ) && isNotEmpty( tasksKey ) && isNotEmpty( currentUser ) ) {
      if ( isEmpty( fields ) ) {
        // set the default value of the owner field to the current user, this is not a select, unlike in other sections
        // this is a free-form text field, meaning it does not need to be a DS User
        const _fields = EMPTY_FIELDS;
        const ownerField = _fields.find( f => f.attribute === 'owner' );
        ownerField.defaultValue = userDisplayName( currentUser );

        // sets the fields
        setFields( _fields );
      }

      if ( isEmpty( formHeader ) ) {
        if ( Object.values( tasks ).length > 1 ) {
          // eslint-disable-next-line max-len
          setFormHeader( <span><strong>{ capitalize( pluralizeType( tasksKey ) ) }: </strong> {Object.values( tasks ).length} Selected</span> );
        } else {
          const [ item ] = Object.values( tasks );
          // eslint-disable-next-line max-len
          setFormHeader( <span><strong>{ capitalize( tasksKey ) }: </strong> { getLabelForTaskType( tasksKey, item ) }</span> );
        }
      }
      if ( isEmpty( itemsHeader ) ) {
        let header = '';

        if ( tasksKey === 'host' ) {
          if ( Object.values( tasks ).length > 1  ) {
            header = 'Limit to the following vulnerability';
          } else {
            header = 'Include the following vulnerabilities';
          }
        }
        // if ( tasksKey === 'patch' ) {
        //   if ( Object.values( tasks ).length > 1  ) {
        //     header = 'Limit to the following host';
        //   } else {
        //     header = 'Include the following hosts';
        //   }
        // }
        if ( tasksKey === 'vulnerability' ) {
          if ( Object.values( tasks ).length > 1  ) {
            header = 'Limit to the following host';
          } else {
            header = 'Include the following hosts';
          }
        }
        setItemsHeader( header );
      }
      if ( isEmpty( items ) ) {
        fetchItemsFor( tasksKey, tasks );
      }
    }
  }, [ tasksKey, tasks ] );

  // callback for checkbox of an additionalItem
  const handleItemValueCheck = item => {
    const _itemsValues = { ...itemsValues };

    const [ id ] = Object.keys( item );
    const [ value ] = Object.values( item );

    // already present, need to remove
    if ( isNotEmpty( _itemsValues[id] ) ) {
      delete _itemsValues[id];
    // not present, need to add
    } else {
      _itemsValues[id] = value;
    }
    setItemsValues( _itemsValues );
  };

  // callback for radio of an additionalItem
  const handleItemValueRadio = item => {
    const [ id ] = Object.keys( item );
    const [ value ] = Object.values( item );
    setItemsValues( { [id]: value } );
  };

  // checks to see if an item should be checked or toggled
  const itemValueIncluded = item => {
    const [ id ] = Object.keys( item );
    return isNotEmpty( itemsValues[id] );
  };

  const isFullyValid = () => isValid && isNotEmpty( itemsValues );

  const getPlanType = ( tasksKey, hasManyTasks ) => {
    if ( tasksKey === 'host' ) {
      if ( hasManyTasks ) {
        return 'vulnerability_hosts';
      }
      return 'host_vulnerabilities';
    }

    // if ( tasksKey === 'patch' ) {
    //   if ( hasManyTasks ) {
    //     return 'host_patches';
    //   }
    //   return 'patch_hosts';
    // }

    if ( tasksKey === 'vulnerability' ) {
      if ( hasManyTasks ) {
        return 'host_vulnerabilities';
      }
      return 'vulnerability_hosts';
    }
  };

  const getPlanTask = ( tasksKey, hasManyTasks, tasks, itemsValues ) => {
    if ( hasManyTasks ) {
      const [ id ] = Object.keys( itemsValues );
      const [ value ] = Object.values( itemsValues );
      return { id: id, label: value };
    }
    return {
      id: Object.values( tasks )[0].id,
      label: getLabelForTaskType( tasksKey, Object.values( tasks )[0] ),
    };
  };

  const getPlanItems = ( hasManyTasks, tasks, itemsValues ) => {
    return hasManyTasks
      ? Object.values( tasks ).map( i => i.id )
      : Object.keys( itemsValues );
  };

  // handler for checking all of the items (only shows up for checkboxes, not radios)
  const handleSelectAllItems = () => {
    // already all selected, need to deselect all
    if ( allSelected() ) {
      setItemsValues( {} );
    // there is at least one not selected, select all
    } else {
      setItemsValues( items );
    }
  };

  // checker to see if all items are selected or not
  const allSelected = () => {
    const _itemsValues = { ...itemsValues };
    if ( isNotEmpty( _itemsValues ) && isNotEmpty( items ) ) {
      return Object.keys( _itemsValues ).length === Object.keys( items ).length;
    }
    return false;
  };

  const onSave = async () => {
    const hasManyTasks = isNotEmpty( tasks ) && Object.values( tasks ).length > 1;
    const record = {};

    setLoading( true );

    // need to make sure everything is ready
    if (
      isNotEmpty( updatedForm )
      && isNotEmpty( updatedForm.fieldStates )
      && isNotEmpty( tasks )
      && isNotEmpty( tasksKey )
      && isNotEmpty( itemsValues )
    ) {
      const values = getFieldValues( updatedForm.fieldStates, 'accepted_risk' );

      // add in all the form values
      if ( isNotEmpty( values ) ) {
        Object.entries( values ).map( ( [ attr, val ] ) => {

          if ( attr === 'expiration' ) {
            record[attr] = Math.floor( new Date( val ).getTime() / 1000 );
          } else {
            record[attr] = val;
          }

        } );
      }

      // the type, task, and tasks are all derived based on wether this is a single or multiple relationship
      // the naming conventions match the conventions of a remediation plan task.
      record.type = getPlanType( tasksKey, hasManyTasks );
      const task = getPlanTask( tasksKey, hasManyTasks, tasks, itemsValues );
      // eslint-disable-next-line camelcase
      record.object_id = task.id;
      // convenience label so another lookup is not needed to retrieve this for display later
      // eslint-disable-next-line camelcase
      record.object_label = task.label;
      // eslint-disable-next-line camelcase
      record.item_ids = getPlanItems( hasManyTasks, tasks, itemsValues );
      // eslint-disable-next-line camelcase
      record.plan_type = PLAN_TYPES[record.reason];
    }

    if ( isNotEmpty( record ) ) {

      const response = await makeRequest( 'UPSERT', 'model/base/accepted_risk', { records: [ record ] } );

      if ( isNotEmpty( response ) ) {
        // success
        if ( isNotEmpty( response.results ) ) {
          setLoading( false );
          addFlashMessage( {
            type: 'success',
            body: <React.Fragment>
              Successfully created accepted risk plan. Some items in the plan may need to have a full analysis run
              in order to properly display in the application. To run a full analysis go to
              the <a target="_blank" href="#.=activity&page=tasks">{ 'Activity > Tasks' }</a> page and run
              the "Risk Analysis and Prioritization" task.
            </React.Fragment>,
          } );
          cancelAndClose();
          // fire the callback, let it know to refresh the table
          callback( true );
        // errors
        } else if ( isNotEmpty( response.errors ) ) {
          setLoading( false );
          response.errors.map( e => {
            addFlashMessage( {
              type: 'alert',
              body: e,
            } );
          } );
        // unkown
        } else {
          setLoading( false );
          addFlashMessage( {
            type: 'alert',
            body: 'There was an error creating the accepted risk plan.',
          } );
        }
      // likely 500
      } else {
        setLoading( false );
        addFlashMessage( {
          type: 'alert',
          body: 'There was an error creating the accepted risk plan.',
        } );
      }
    }
  };

  return (
    <React.Fragment>
      { show && <div className="acceptedRiskShade addToPlanShade" onClick={ cancelAndClose } /> }
      <div
        ref={ menuRef }
        className={ `acceptedRiskMenu ${show ? 'visible' : ''} addToPlanMenu`}
        style={ menuPosition }
      >
        { loading && <Loading text="" /> }
        <div className="remediationWorkflowHeader">
          <div className="titleWrapper">
            <InlineSVG type="remediationCart" />
            <h2>
              { formHeader }
            </h2>
          </div>
          <button
            className="closeButton"
            onClick={ cancelAndClose }
          >
            <InlineSVG type="close" />
          </button>
        </div>
        {
          isNotEmpty( fields ) &&
          <Form
            fields={ fields }
            recordType="accepted_risk_plan"
            trackUpdates={ false }
            onChangeCallback={ setUpdatedForm }
            setIsValid={ setIsValid }
          />
        }
        <div className="itemsContainer">
          {
            isNotEmpty( items ) &&
            <Accordion>
              <div
                className={`${collapsed ? 'collapsed' : ''} accordionWrapper alternate`}
              >
                <div
                  className="accordionHeader"
                  onClick={ () => setCollapsed( !collapsed ) }
                >
                  <h3>
                    <span>{ itemsHeader }</span>
                    <span className="itemsCount">({ Object.values( itemsValues ).length } Selected)</span>
                  </h3>
                  <button
                    onClick={ () => setCollapsed( !collapsed ) }
                  >
                    <InlineSVG type="carretUp" />
                  </button>
                </div>
                <div className="accordionBody">
                  {
                    isNotEmpty( tasks ) &&
                    <React.Fragment>
                      {
                        Object.values( tasks ).length > 1
                          ? <React.Fragment>
                            {
                              Object.entries( items ).map( ( [ id, label ], index ) => {
                                return <label key={index} >
                                  <div
                                    // eslint-disable-next-line max-len
                                    className={ `radioFieldWrapper ${ itemValueIncluded( { [id]: label } ) ? 'checked' : ''}` }
                                  >
                                    <input
                                      type="radio"
                                      onChange={ () => handleItemValueRadio( { [id]: label } ) }
                                      checked={ itemValueIncluded( { [id]: label } ) }
                                    />
                                  </div>
                                  <span className="labelWrapper">{ label }</span>
                                </label>;
                              } )
                            }
                          </React.Fragment>
                          : <React.Fragment>
                            <button
                              className="selectAllButton"
                              onClick={ handleSelectAllItems }
                            >
                              {
                                allSelected()
                                  ? <InlineSVG type="remove" />
                                  : <InlineSVG type="checkbox" />
                              }
                              Select all
                            </button>
                            {
                              Object.entries( items ).map( ( [ id, label ], index ) => {
                                return <label key={index} >
                                  <div
                                    // eslint-disable-next-line max-len
                                    className={ `checkboxFieldWrapper ${ itemValueIncluded( { [id]: label } ) ? 'checked' : ''}` }
                                  >
                                    <input
                                      type="checkbox"
                                      onChange={ () => handleItemValueCheck( { [id]: label } ) }
                                      checked={ itemValueIncluded( { [id]: label } ) }
                                    />
                                  </div>
                                  <span className="labelWrapper">{ label }</span>
                                </label>;
                              } )
                            }
                          </React.Fragment>
                      }
                    </React.Fragment>
                  }
                </div>
              </div>
            </Accordion>
          }
        </div>
        <div className="formButtonsWrapper">
          <button
            className="cancelButton"
            onClick={ cancelAndClose }
          >
            Cancel
          </button>
          <button
            className={ `submitButton ${ isFullyValid() ? '' : 'disabled' }`}
            disabled={ !isFullyValid()}
            onClick={ onSave }
          >
            Accept Risk
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AcceptedRiskMenu;