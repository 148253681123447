// returns the defaults for advanced filter,
// the current values are compared to these and if they do not match
// a little pill is shown to the user so that they know what filters are applied
// NOTE: only filters that matter are ones outside of the alwaysVisible group
// because the user will be able to see those

import { getRecordForType } from '../Form/Fields/Advanced/SearchResults/SearchData';
import { exploitStatusLabelMap, formatUnixDate, isNotEmpty } from '../Utilities';

/* eslint-disable camelcase */
export const defaultFilterValues = {
  instance: {
    asset_tag_ids: [],
    host_ids: [],
    patch_ids: [],
    vulnerability_ids: [],
    signature_ids: [],
    host_globs: '',
    patch_globs: '',
    vulnerability_globs: '',
    exploit_status: 'null',
    accepted_risk: 'false',
  },
  host: {
    sensitive_assets: 'any',
    asset_tag_ids: [],
    accepted_risk: 'false',
    risk_reduction: [ 'gt_map', '' ],
  },
  vulnerability: {
    asset_tag_ids: [],
    patchable: 'any',
    published_before: '',
    published_after: '',
    exploit_status: 'null',
    accepted_risk: 'false',
    age: [ 'gt_map', '' ],
    risk_reduction: [ 'gt_map', '' ],
    cvss_base_score: [ 'gt_map', '' ],
  },
  patch: {
    asset_tag_ids: [],
    superseded: 'any',
    published_before: '',
    published_after: '',
    accepted_risk: 'false',
    age: [ 'gt_map', '' ],
    risk_reduction: [ 'gt_map', '' ],
    num_hosts: [ 'gt_map', '' ],
  },
  path: {},
  user: {},
  scans: {
    asset_tag_ids: [],
    host_ids: [],
    patch_ids: [],
    vulnerability_ids: [],
    signature_ids: [],
    host_globs: '',
    patch_globs: '',
    vulnerability_globs: '',
    exploit_statuses: [ 'private' ],
  },
};

export const acceptedRiskFilterOptions = {
  false: 'Hide accepted risk items',
  true: 'Show accepted risk items only',
  any: 'Show all items',
};

export const supersededFilterOptions = {
  fully: 'Fully superseded',
  unsuperseded: 'Not superseded',
  any: 'Any',
  partial: 'Partially superseded',
};

export const appliedFiltersTransformMap = {
  published_before: { formatter: date => formatUnixDate( date ) },
  published_after: { formatter: date => formatUnixDate( date ) },
  // eslint-disable-next-line max-len
  exploit_status: { formatter: statuses => isNotEmpty( statuses ) ? statuses.map( s => exploitStatusLabelMap[s] ).join( ', ' ) : '' },
  // eslint-disable-next-line max-len
  exploit_statuses: { formatter: statuses => isNotEmpty( statuses ) ? statuses.map( s => exploitStatusLabelMap[s] ).join( ', ' ) : '' },
  host_ids: { isPromise: true, formatter: id => getRecordForType( 'scope', id ) },
  patch_ids: { isPromise: true, formatter: id => getRecordForType( 'patch', id ) },
  vulnerability_ids: { isPromise: true, formatter: id => getRecordForType( 'vulnerability', id ) },
  signature_ids: { isPromise: true, formatter: id => getRecordForType( 'scanner_signature', id ) },
  // eslint-disable-next-line max-len
  asset_tag_ids: { formatter: ids => `${ids.length} selected` },
  third_party_setting_ids: { formatter: settings => settings.length },
  accepted_risk: { formatter: value => acceptedRiskFilterOptions[value] },
  superseded: { formatter: value => supersededFilterOptions[value] },

  age: { formatter: value => `${ value[0] === 'gt_map' ? 'Older than' : 'Newer than' } ${value[1]} day(s)` },
  risk_reduction: { formatter: value => `${ value[0] === 'gt_map' ? 'More than' : 'Less than' } ${value[1]}%` },
  num_hosts: { formatter: value => `${ value[0] === 'gt_map' ? 'More than' : 'Less than' } ${value[1]} hosts` },
  cvss_base_score: { formatter: value => `${ value[0] === 'gt_map' ? 'Higher than' : 'Lower than' } ${value[1]}` },
};
/* eslint-enable camelcase */