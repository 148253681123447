/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  capitalize,
  cvssScoreToRating,
  formatNumber,
  formatRiskReduction,
  globalColors,
  isEmpty,
  isNotEmpty,
  reportTypeDisplayName,
  riskToRating,
} from '../../../../../shared/Utilities';
import DataTable from '../../../../../shared/DataTable';
import RatingBadge from '../../../../../shared/RatingBadge';
import RiskReduction from '../../../../../shared/RiskReduction';
import ExploitStatus from '../../../../../shared/ExploitStatus';
import InlineSVG from '../../../../../shared/InlineSVG';

import './PriorityRecords.scss';
// import Legend from '../../../../../shared/Charts/Legend';
import Bar from '../../../../../shared/Charts/Bar';
import YAxisLabels from '../../../../../shared/Charts/AxisLabels/YAxisLabels';
import RecordList from '../../../../../shared/RecordList';
import { RiskContext } from '../../../../../Contexts/Risk';
import { fetchForWidgetsV2, multipleOrderByOptions, paramsMap, v2Fetches } from '../../shared';
import { getRecords } from '../../../../../shared/RecordCache';
import { TagsContext } from '../../../../../Contexts/Tags';
import TagList from '../../../../RiskInsight/Tags/List';

const PriorityRecords = ( { item, settings, prefetchedData } ) => {

  const [ , globalRisk, , , , , ] = React.useContext( RiskContext );
  const [ tags ] = React.useContext( TagsContext );

  const [ records, setRecords ] = React.useState( null );
  const [ tableData, setTableData ] = React.useState( null );
  const [ listData, setListData ] = React.useState( null );
  const [ chartData, setChartData ] = React.useState( null );
  // const [ chartLegendData, setChartLegendData ] = React.useState( null );
  const [ yMax, setYMax ] = React.useState( null );

  const [ noRisk, setNoRisk ] = React.useState( false );
  const [ hasCVSS, setHasCVSS ] = React.useState( false );
  const [ hasUser, setHasUser ] = React.useState( false );
  const [ hasHostType, setHasHostType ] = React.useState( false );

  const [ primaryOrderBy, setPrimaryOrderBy ] = React.useState( 'risk' );

  const [ tagMemberships, setTagMemberships ] = React.useState( null );

  const keyToAttrMap = {
    hosts: 'num_hosts',
    vulnerabilities: 'num_vulnerabilities',
    cvss: 'cvss_base_score',
    exploit: 'exploit_status',
  };

  // find out what tags this host is a part of
  React.useEffect( () => {
    if ( isNotEmpty( tags ) && isNotEmpty( settings ) && isNotEmpty( settings.asset_tag_ids ) ) {
      const _tags = {};

      settings.asset_tag_ids.map( id => {
        _tags[id] = tags[id];
      } );

      setTagMemberships( _tags );
    }
  }, [ tags, settings ] );

  const nameLink = ( item, type ) => {

    let href = `#.=risk_insight&report=${type}&item=${item.id}&sort_by=risk&sort_direction=DESC&current_page=1`;

    if ( type === 'patches' ) {
      // eslint-disable-next-line max-len
      href = `#.=risk_insight&report=${type}&item=${item.id}&risk_type=risk&sort_by=risk&sort_direction=DESC&current_page=1`;
    }
    return (
      <a
        target="_blank"
        rel="noreferrer noopener"
        href={ href }
      >
        { reportTypeDisplayName( item, type ) }
        <InlineSVG type="newTabLink" version="primary" />
      </a>
    );
  };

  const refetchRecords = async ( additionalParams={} ) => {

    // initial check to see if we need to refetch for vuln. table
    if (
      isNotEmpty( additionalParams )
      && isNotEmpty( additionalParams.order_by )
      && isNotEmpty( item )
      && item.key === 'vulnerabilities_priority'
    ) {
      const _records = await getRecords(
        'VULNERABILITY',
        {
          ...paramsMap.table.vulnerabilities,
          ...additionalParams,
        },
        true,
      );
      setRecords( _records );

    } else if (
      isNotEmpty( additionalParams )
      && isNotEmpty( additionalParams.order_by )
      && isNotEmpty( item )
      && item.key === 'hosts_priority'
    ) {

      const _records = await getRecords(
        'HOST',
        {
          ...paramsMap.table.hosts,
          ...additionalParams,
        },
        true,
      );
      setRecords( _records );
    } else if ( isNotEmpty( additionalParams ) && isNotEmpty( additionalParams.asset_tag_ids ) ) {

      let key = 'HOST';
      const [ type ] = item.key.split( '_' );

      if ( item.key === 'vulnerabilities_priority' ) {
        key = 'VULNERABILITY';
      }
      if ( item.key === 'patches_priority' ) {
        key = 'PATCH';
      }

      const _records = await getRecords(
        key,
        {
          ...paramsMap.table[type],
          filters: {
            ...paramsMap.table[type].filters,
            // eslint-disable-next-line camelcase
            asset_tag_ids: additionalParams.asset_tag_ids,
          },
        },
        true,
      );
      setRecords( _records );
    } else if ( isNotEmpty( item ) && isNotEmpty( item.key )  ) {

      const fetchKeys = fetchForWidgetsV2[item.key];

      const fetches = [];
      const _data = {};
      if ( isNotEmpty( fetchKeys ) ) {
        fetchKeys.map( k => {
          fetches.push( v2Fetches[k]() );
        } );

        if ( isNotEmpty( fetches ) ) {
          const resolvedFetches = await Promise.all( fetches );

          resolvedFetches.map( ( data, index ) => {

            _data[fetchKeys[index]] = data;
          } );
          const dataKey = `${item.key.split( '_' )[0]}_list`;

          let _records = _data[dataKey];

          if ( _records.results ) {
            _records = _records.results;
          }
          setRecords( _records );
        }
      }
    }
  };

  React.useEffect( () => {
    // special refetch case needed to sort by cvss for vulns table
    if (
      isNotEmpty( settings )
      && 'order_by' in settings
      && ( settings.report_type === 'vulnerabilities' || settings.report_type === 'hosts' )
    ) {
      // eslint-disable-next-line camelcase
      let order_by = [
        [ 'filtered_risk', 'DESC' ],
        [ 'id', 'DESC' ],
      ];

      if ( multipleOrderByOptions.includes( settings.order_by ) ) {

        const [ primaryKey, secondaryKey ] = settings.order_by.split( '_' );

        setPrimaryOrderBy( keyToAttrMap[primaryKey] );

        // eslint-disable-next-line camelcase
        order_by = [
          [ keyToAttrMap[primaryKey], 'DESC' ],
          [ keyToAttrMap[secondaryKey], 'DESC' ],
          [ 'id', 'DESC' ],
        ];
      } else {
        // eslint-disable-next-line camelcase
        order_by = [
          [ settings.order_by, 'DESC' ],
          [ 'id', 'DESC' ],
        ];
      }
      // eslint-disable-next-line camelcase
      refetchRecords( { order_by } );
    }

    if ( isNotEmpty( settings ) && isNotEmpty( prefetchedData ) ) {
      const dataKey = settings.report_type;

      if ( isNotEmpty( dataKey ) && isNotEmpty( prefetchedData[`${dataKey}_list`] ) ) {

        // if these results should be filtered down by tags, need to refetch
        if ( isNotEmpty( settings ) && isNotEmpty( settings.asset_tag_ids ) ) {
          // eslint-disable-next-line camelcase
          refetchRecords( { asset_tag_ids: settings?.asset_tag_ids || [] } );
        }
        let _records = prefetchedData[`${dataKey}_list`];
        if ( prefetchedData[`${dataKey}_list`].results ) {
          _records = prefetchedData[`${dataKey}_list`].results;
        }
        setRecords( _records );
      } else {
        // eslint-disable-next-line camelcase
        refetchRecords( { asset_tag_ids: settings?.asset_tag_ids || [] } );
      }
    } else {
      // eslint-disable-next-line camelcase
      refetchRecords( { asset_tag_ids: settings?.asset_tag_ids || [] } );
    }
  }, [ settings, prefetchedData, globalRisk ] );

  React.useEffect( () => {
    if (
      isNotEmpty( records )
      && isNotEmpty( settings )
      && isNotEmpty( settings.report_type )
    ) {
      if ( settings.version === 'table' ) {

        let _showRisk = true;
        let _showCVSS = false;
        let _showUser = false;
        let _showHostType = false;

        if ( 'include_risk' in settings && settings.include_risk === false ) {
          _showRisk = false;
        }
        if ( settings?.include_cvss === true ) {
          _showCVSS = true;
        }
        if ( settings?.include_user === true ) {
          _showUser = true;
        }
        if ( settings?.include_os_type === true ) {
          _showHostType = true;
        }

        const _tableData = [];

        records.map( ( item, index ) => {
          if ( index < settings.item_count ) {
            if ( settings.report_type === 'hosts' ) {
              const _row = {};

              if ( settings?.include_risk ) {
                _row.risk = <React.Fragment>
                  <RatingBadge altVersion rating={ riskToRating( item.filtered_risk ) }/>
                  <RiskReduction item={item} riskType="filtered_risk" />
                </React.Fragment>;
              }
              _row.name = nameLink( item, settings.report_type );
              if ( settings?.include_user ) {
                // eslint-disable-next-line camelcase
                _row.last_logged_in_user = isEmpty( item.last_logged_user ) ? 'N/A' : item.last_logged_user;
              }
              if ( settings?.include_os_type ) {
                // eslint-disable-next-line camelcase
                _row.host_type = item.host_os_type;
              }
              _row.vulnerabilities = formatNumber( item.num_vulnerabilities );

              _tableData.push( _row );
            }
            if ( settings.report_type === 'patches' ) {
              const _row = {};

              if ( settings?.include_risk ) {
                _row.risk = <React.Fragment>
                  <RatingBadge altVersion rating={ riskToRating( item.risk ) }/>
                  <RiskReduction item={item} riskType="risk" />
                </React.Fragment>;
              }
              _row.name = nameLink( item, settings.report_type );
              _row.hosts = formatNumber( item.num_hosts );
              _row.vulnerabilities = formatNumber( item.num_vulnerabilities );
              _row.description = item.description;

              _tableData.push( _row );
            }
            if ( settings.report_type === 'vulnerabilities' ) {
              const _row = {};

              if ( settings?.include_risk ) {
                _row.risk = <React.Fragment>
                  <RatingBadge altVersion rating={ riskToRating( item.filtered_risk ) }/>
                  <RiskReduction item={item} riskType="filtered_risk" />
                </React.Fragment>;
              }
              if ( settings?.include_cvss ) {
                _row['CVSS'] = <RatingBadge
                  rating={ cvssScoreToRating( item.cvss_base_score ) }
                  // eslint-disable-next-line max-len
                  alternateText={ `CVSS Score: ${item.cvss_base_score}`}
                  elementClass="cvssRatingBadge"
                />;
              }
              _row.name = nameLink( item, settings.report_type );
              // eslint-disable-next-line camelcase
              _row.exploit_status = <ExploitStatus status={item.exploit_status} fullVersion={false} />;
              _row.hosts = formatNumber( item.num_hosts );
              _row.description = item.description;

              _tableData.push( _row );
            }
            if ( settings.report_type === 'users' ) {
              _tableData.push(
                {
                  risk: <React.Fragment>
                    <RatingBadge altVersion rating={ riskToRating( item.risk ) }/>
                    <RiskReduction item={item} riskType="risk" />
                  </React.Fragment>,
                  name: nameLink( item, settings.report_type ),
                  // eslint-disable-next-line camelcase
                  recently_accessed_hosts: formatNumber( item?.active_hosts?.length || 0 ),
                  // eslint-disable-next-line camelcase
                  domain_groups: formatNumber( item?.domain_groups?.length || 0 ),
                },
              );
            }
          }
        } );

        setTableData( _tableData );

        setNoRisk( !_showRisk );
        setHasCVSS( _showCVSS );
        setHasUser( _showUser );
        setHasHostType( _showHostType );
      }
      if ( settings.version === 'list' ) {
        const _listData = [];
        records.map( ( r, i ) => {
          if ( i < settings.item_count ) {
            _listData.push( r );
          }
        } );

        let _riskType = 'risk';

        const [ first ] = records;

        if ( settings.report_type === 'patches' ) {
          if ( first.filtered_risk ) {
            _riskType = 'filtered_risk';
          } else if ( first.risk ) {
            _riskType = 'risk';
          } else {
            _riskType = 'direct_risk';
          }
        } else if ( first.filtered_risk ) {
          _riskType = 'filtered_risk';
        } else {
          _riskType = 'risk';
        }

        // eslint-disable-next-line camelcase
        let order_by = _riskType;

        if ( isNotEmpty( settings ) && 'order_by' in settings ) {
          ( { order_by } = settings );
        }

        if ( multipleOrderByOptions.includes( order_by ) ) {
          // eslint-disable-next-line camelcase
          const [ primaryKey ] = order_by.split( '_' );
          // eslint-disable-next-line camelcase
          order_by = keyToAttrMap[primaryKey];
        }
        setPrimaryOrderBy( order_by );
        setListData( _listData );
      }
      // we want the y axis to be whatever we are ordering by, this is often risk, but not always, need to figure it
      // out and setup the chart accordingly
      if ( settings.version === 'barchart' ) {

        let _riskType = 'risk';

        // eslint-disable-next-line camelcase
        let order_by = _riskType;

        const _chartData = {};

        if ( isNotEmpty( settings ) && 'order_by' in settings ) {
          ( { order_by } = settings );
        }

        if ( multipleOrderByOptions.includes( order_by ) ) {
          // eslint-disable-next-line camelcase
          const [ primaryKey ] = order_by.split( '_' );
          // eslint-disable-next-line camelcase
          order_by = keyToAttrMap[primaryKey];
        }
        setPrimaryOrderBy( order_by );

        let _yMax = 0;

        const [ first ] = records;

        if ( settings.report_type === 'patches' ) {
          if ( first.filtered_risk ) {
            _riskType = 'filtered_risk';
          } else if ( first.risk ) {
            _riskType = 'risk';
          } else {
            _riskType = 'direct_risk';
          }
        } else if ( first.filtered_risk ) {
          _riskType = 'filtered_risk';
        } else {
          _riskType = 'risk';
        }

        const _legendData = {
          critical: { key: 'critical', label: 'Critical', total: 0, fill: globalColors.critical },
          high: { key: 'high', label: 'High', total: 0, fill: globalColors.high },
          moderate: { key: 'moderate', label: 'Moderate', total: 0, fill: globalColors.moderate },
          low: { key: 'low', label: 'Low', total: 0, fill: globalColors.low },
          minimal: { key: 'minimal', label: 'Minimal', total: 0, fill: globalColors.minimal },
        };

        records.map( ( record, index ) => {
          if ( isNotEmpty( record ) && index < settings.item_count ) {
            const rating = riskToRating( record[_riskType] );
            const reduction = record[_riskType];
            if ( reduction > _yMax ) {
              _yMax = reduction;
            }
            _chartData[record.id] = {
              key: record.id,
              original: record,
              label: reportTypeDisplayName( record, settings.report_type ),
              value: record[_riskType],
              fill: globalColors[rating],
            };

            _legendData[rating].total += 1;
          }
        } );
        setYMax( _yMax );
        // setChartLegendData( _legendData );
        setChartData( _chartData );
      }
    }
  }, [ records, settings ] );

  const orderByToWords = orderBy => {
    switch ( orderBy ) {
    case 'filtered_risk':
      return 'risk score';
    case 'cvss_base_score':
      return 'CVSS score';
    case 'exploit_status':
      return 'exploit status';
    case 'num_hosts':
      return 'number of affected hosts';
    case 'num_vulnerabilities':
      return 'amount of vulnerabilities';
    case 'cvss_exploit':
      return 'CVSS score and then exploit status';
    case 'cvss_hosts':
      return 'CVSS score and then number of affected hosts';
    case 'exploit_cvss':
      return 'exploit status and then CVSS score';
    case 'exploit_hosts':
      return 'exploit status and then number of hosts';
    case 'hosts_cvss':
      return 'number of hosts and then CVSS score';
    case 'hosts_exploit':
      return 'number of hosts and then exploit status';
    default:
      return 'risk score';
    }
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( settings ) &&
        <span className="recordsCountHeader">
          {/* eslint-disable-next-line max-len */}
          <strong>Top { records?.length > settings?.item_count ? settings.item_count : records?.length } { capitalize( settings?.report_type ) }: </strong>
          <span>sorted by { orderByToWords( settings?.order_by ) }</span>
        </span>
      }
      {
        isNotEmpty( tagMemberships ) &&
        <TagList tags={tagMemberships} />
      }
      {
        ( isNotEmpty( records ) && isNotEmpty( settings ) && isNotEmpty( settings.version ) ) &&
        <React.Fragment>
          {
            ( settings.version === 'table' && isNotEmpty( tableData ) ) &&
            <DataTable
              data={tableData}
              // eslint-disable-next-line max-len
              elementClass={ `tableWidgetWrapper ${settings?.report_type} riskClass--${ noRisk ? 'no_risk' : 'has_risk' } cvssClass--${ hasCVSS ? 'has_cvss' : 'no_cvss' } hostTypeClass--${ hasHostType ? 'has_host_type' : 'no_host_type' } userClass--${ hasUser ? 'has_user' : 'no_user' }` }
            />
          }
          {
            ( settings.version === 'list' && isNotEmpty( listData ) ) &&
            <RecordList
              orderBy={primaryOrderBy}
              records={listData}
              reportType={settings?.report_type}
            />
          }
          {
            ( settings.version === 'barchart' && isNotEmpty( chartData ) ) &&
            <React.Fragment>
              <div className="priorityChartAndAxisWrapper">
                <YAxisLabels yMax={ yMax } ticFormatter={ ticValue => formatRiskReduction( ticValue, globalRisk ) } />
                <Bar data={chartData} elementClass={ `chartWidgetWrapper ${settings?.report_type}` } />
              </div>
              {/* <Legend legendData={chartLegendData} /> */}
            </React.Fragment>

          }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default PriorityRecords;