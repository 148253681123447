/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isEmpty, isNotEmpty } from '../../../../../shared/Utilities';

import PathsGraph from '../../../../RiskInsight/Detail/Sections/PathsGraph';
import { getRecords } from '../../../../../shared/RecordCache';
import { makeRequest } from '../../../../../../legacy/io';

import './Paths.scss';

const Paths = ( { prefetchedData, settings } ) => {

  const [ relatedPaths, setRelatedPaths ] = React.useState( null );

  const getPathsForRecord = async () => {

    const recordID = settings[`record_id_${settings.report_type}`];

    // params for initial fetch of the related paths
    const params = {
      model: 'base',
      [`${settings.report_type}_id`]: recordID,
      rownums: [ 0, settings?.item_count ],
    };

    // fetches the paths for this item
    const relatedPathsResponse = await makeRequest( 'FETCH', '/analysis/related_paths', params );
    setRelatedPaths( relatedPathsResponse );
  };

  const getRelatedPaths = async ( paths ) => {
    const params = {
      // eslint-disable-next-line camelcase
      extra_columns: [
        'keywords',
        'risk',
        'modified',
        'edges',
        'node_labels',
      ],
      // eslint-disable-next-line camelcase
      order_by: [
        [ 'risk', 'DESC' ],
      ],
      rownums: [ 0, settings?.item_count || 3 ],
    };

    let pathsResponse;
    if ( isEmpty( paths ) ) {
      pathsResponse = await getRecords( 'path', params );
    } else {
      pathsResponse = [ ...paths ];
    }

    if (  isNotEmpty( pathsResponse ) ) {
      // eslint-disable-next-line camelcase
      const path_ids = pathsResponse.map( p => p.id );

      // params for initial fetch of the related paths
      const relatedParams = {
        model: 'base',
        // eslint-disable-next-line camelcase
        path_ids,
        rownums: [ 0, settings?.item_count || 3 ],
      };

      const relatedPathsResponse = await makeRequest( 'FETCH', '/analysis/related_paths', relatedParams );

      setRelatedPaths( relatedPathsResponse );
    }
  };

  React.useEffect( () => {
    if (
      isNotEmpty( settings )
      && isNotEmpty( settings.record_id )
      && isNotEmpty( settings.report_type )
      && (
        isNotEmpty( settings.record_id_host )
        || isNotEmpty( settings.record_id_patch )
        || isNotEmpty( settings.record_id_vulnerability )
      )
    ) {
      getPathsForRecord();
    } else if ( isNotEmpty( prefetchedData ) ) {
      getRelatedPaths( prefetchedData?.paths );
    }
  }, [ prefetchedData, settings ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( relatedPaths ) &&
        <div className="pathsWidgetWrapper">
          <PathsGraph
            relatedPaths={ relatedPaths }
          />
        </div>
      }
    </React.Fragment>
  );
};

export default Paths;