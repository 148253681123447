/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import { Responsive } from 'react-grid-layout';

import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import './style.scss';
import {
  decodeURLHash,
  getDimensionsAndOffset,
  isEmpty,
  isNotEmpty,
  promiseAllSequential,
  useLocalStorage,
} from '../../../shared/Utilities';
import WidgetWrapper from './Widgets/WidgetWrapper';
import {
  legacyToV2Widget,
  fetchForWidgetsV2,
  v2Fetches,
  getLatestWidgetVersion,
  v2WidgetMinMaxDimensions,
} from './shared';
import DashboardEditorV2 from './DashboardEditorV2';
import { getFieldValues } from '../../../shared/Form/Shared';

import InlineSVG from '../../../shared/InlineSVG';
import DashboardSelector from './DashboardSelector';
import { makeRequest } from '../../../../legacy/io';
import EmptyLoading from '../../../shared/EmptyLoading';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import { ReportCreatorContext } from '../../../Contexts/ReportCreator';
import ExportModal from './ExportModal';
import PageHeader from '../../../shared/PageHeader';
import PageCreateButton from '../../../shared/PageCreateButton';
import WidgetEditorModal from './WidgetEditorModal';

const Grid = Responsive;

const Dashboards = ( ) => {

  // contexts
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ , setCreatorActive, openReportCreator, , , ] = React.useContext( ReportCreatorContext );

  // all layouts and currentlayout vars
  // settings are separate from layout so that widgets only refetch data when absolutely necessary
  const [ layouts, setLayouts ] = React.useState( null );
  const [ currentLayoutID, setCurrentLayoutID ] = useLocalStorage( 'DSdashboardCurrentLayoutID', '' );
  const [ currentLayout, setCurrentLayout ] = React.useState( null );
  const [ currentSettings, setCurrentSettings ] = React.useState( null );
  const [ currentWidget, setCurrentWidget ] = React.useState( null );

  // vars for loading and grid
  const [ gridLoading, setGridLoading ] = React.useState( true );
  const [ dataFetchComplete, setDataFetchComplete ] = React.useState( false );
  const [ gridWidth, setGridWidth ] = React.useState( 1280 );

  // toggles for editing and printing
  const [ editMode, setEditMode ] = React.useState( false );
  const [ showWidgetEditor, setShowWidgetEditor ] = React.useState( false );
  const [ printing, setPrinting ] = React.useState( false );
  const [ showPageBreaks, setShowPageBreaks ] = useLocalStorage( 'DSdashboardShowPageBreaks', false );
  const [ pageCount, setPageCount ] = React.useState( 1 );

  // data that powers all the widgets, anything that can be fetched ahead of time and deduped is contained her
  const [ prefetchedData, setPrefetchedData ] = React.useState( null );

  // form for the layout itself, only has one attr, the label
  const [ updatedLayoutForm, setUpdatedLayoutForm ] = React.useState( null );

  // exports vars
  const [ existingReport, setExistingReport ] = React.useState( null );
  const [ showReportCreatorModal, setShowReportCreatorModal ] = React.useState( false );

  // widget and variant related vars, need to be here so they can pass down to all children
  const [ selectedWidgetVariant, setSelectedWidgetVariant ] = React.useState( null );
  const [ selectedWidgetOptions, setSelectedWidgetOptions ] = React.useState( null );
  const [ selectedWidgetCategory, setSelectedWidgetCategory ] = React.useState( null );
  const [ recordLabel, setRecordLabel ] = React.useState( null );

  // refs
  const dashboardWrapperRef = React.useRef( null );
  const editorRef = React.useRef( null );

  /* eslint-disable camelcase */
  const builtInDashboards = [
    {
      id: '00000000-0000-0000-0000-000000000000',
      label: 'Summary',
      widgets: [
        {
          'version': 2,
          'label': 'Highest Priority Hosts',
          'fullLabel': 'Hosts: Highest Priority (100)',
          'key': 'hosts_priority',
          'minH': 4,
          'maxH': 48,
          'minW': 2,
          'maxW': 6,
          'h': 18,
          'w': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'settings': {
            'version': 'table',
            'item_count': 25,
            'report_type': 'hosts',
            'include_rating': true,
            'include_user': true,
            'include_os_type': true,
            'include_description': false,
            'include_risk': true,
          },
          'i': 'd1fe682e-471a-455a-b6cb-2d1bc32c60ec',
          'x': 0,
          'y': 0,
        },
        {
          'version': 2,
          'label': 'Highest Priority Patches',
          'fullLabel': 'Patches: Highest Priority (100)',
          'key': 'patches_priority',
          'minH': 4,
          'maxH': 48,
          'minW': 2,
          'maxW': 6,
          'h': 17,
          'w': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'settings': {
            'version': 'table',
            'item_count': 25,
            'report_type': 'patches',
            'include_rating': true,
            'include_description': false,
            'include_risk': true,
          },
          'i': 'a82c85a1-0cb9-4218-b705-6b4354d8b9fa',
          'x': 0,
          'y': 18,
        },
        {
          'version': 2,
          'label': 'Highest Priority Vulnerabilities',
          'fullLabel': 'Vulnerabilities: Highest Priority (100)',
          'key': 'vulnerabilities_priority',
          'minH': 4,
          'maxH': 48,
          'minW': 2,
          'maxW': 6,
          'h': 17,
          'w': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'settings': {
            'version': 'table',
            'item_count': 25,
            'report_type': 'vulnerabilities',
            'include_rating': true,
            'include_description': false,
            'include_risk': true,
            'include_cvss': true,
          },
          'i': '6d36be4a-5517-4b92-8ecb-2f9fff9d2543',
          'x': 0,
          'y': 35,
        },
        {
          'version': 2,
          'label': 'Risk Over Time',
          'fullLabel': 'Risk: Over Time',
          'key': 'risk_over_time',
          'minH': 6,
          'maxH': 12,
          'minW': 6,
          'maxW': 6,
          'h': 7,
          'w': 6,
          'settings': {
            'include_escalations': true,
            'include_hosts': true,
            'patch_tuesday': true,
            'include_description': false,
          },
          'resizeHandles': [
            's',
          ],
          'i': '382e90c9-37f1-4ce2-86cf-2dac966b76cc',
          'x': 0,
          'y': 52,
        },
        {
          'version': 2,
          'label': 'Peer Percentile Over Time',
          'fullLabel': 'Risk: Peer Percentile Over Time',
          'key': 'risk_peer_percentile_over_time',
          'minH': 6,
          'maxH': 12,
          'minW': 6,
          'maxW': 6,
          'h': 6,
          'w': 6,
          'settings': {
            'include_description': false,
          },
          'resizeHandles': [
            's',
          ],
          'i': '08616ac1-2ca7-4432-8a37-d3707ebef7ca',
          'x': 0,
          'y': 65,
        },
        {
          'version': 2,
          'label': 'Vulnerability Instances',
          'fullLabel': '',
          'key': 'vulnerability_instances_global',
          'minH': 6,
          'maxH': 12,
          'minW': 6,
          'maxW': 6,
          'h': 6,
          'w': 6,
          'settings': {
            'version': 'full',
            'include_description': false,
          },
          'resizeHandles': [
            's',
          ],
          'i': '4c3a0446-35c6-4685-9404-48e6c9649476',
          'x': 0,
          'y': 59,
        },
      ],
    },
    {
      id: '00000000-0000-0000-0000-000000000001',
      label: 'Risk Report',
      widgets: [
        {
          'version': 2,
          'label': 'Highest Priority Patches',
          'fullLabel': 'Patches: Highest Priority (100)',
          'key': 'patches_priority',
          'minH': 4,
          'maxH': 48,
          'minW': 2,
          'maxW': 6,
          'h': 6,
          'w': 2,
          'resizeHandles': [
            's',
            'e',
          ],
          'settings': {
            'include_description': false,
            'version': 'list',
            'item_count': '10',
            'report_type': 'patches',
          },
          'i': 'abf6e79b-4622-42ec-a6e8-332fa3f560a6',
          'x': 4,
          'y': 81,
        },
        {
          'version': 2,
          'label': 'Highest Priority Vulnerabilities',
          'fullLabel': 'Vulnerabilities: Highest Priority (100)',
          'key': 'vulnerabilities_priority',
          'minH': 4,
          'maxH': 48,
          'minW': 2,
          'maxW': 6,
          'h': 6,
          'w': 2,
          'resizeHandles': [
            's',
            'e',
          ],
          'settings': {
            'include_description': false,
            'version': 'list',
            'include_risk': true,
            'include_cvss': true,
            'item_count': '10',
            'report_type': 'vulnerabilities',
          },
          'i': '97d53bce-d955-4f70-b196-52dcfc9b61ba',
          'x': 4,
          'y': 45,
        },
        {
          'version': 2,
          'label': 'Exploit Status Breakdown',
          'fullLabel': 'Vulnerability Instances: Exploit Status Breakdown',
          'key': 'vulnerability_instances_exploit_status_breakdown',
          'settings': {
            'include_description': false,
          },
          'minH': 4,
          'maxH': 8,
          'minW': 2,
          'maxW': 3,
          'h': 6,
          'w': 2,
          'resizeHandles': [
            's',
            'e',
          ],
          'i': 'e788e232-147b-4d01-94e5-b7505ebdfd29',
          'x': 4,
          'y': 10,
        },
        {
          'version': 2,
          'label': 'CVSS Breakdown',
          'fullLabel': 'Vulnerability Instances: CVSS Breakdown',
          'key': 'vulnerability_instances_cvss_breakdown',
          'settings': {
            'include_description': false,
            'version': 'barchart',
          },
          'minH': 4,
          'maxH': 8,
          'minW': 2,
          'maxW': 4,
          'h': 5,
          'w': 2,
          'resizeHandles': [
            's',
            'e',
          ],
          'i': 'b2a5f945-994d-404d-b6fa-fe63b0a41af0',
          'x': 4,
          'y': 0,
        },
        {
          'version': 2,
          'label': 'Risk Over Time',
          'fullLabel': 'Risk: Over Time',
          'key': 'risk_over_time',
          'minH': 6,
          'maxH': 12,
          'minW': 6,
          'maxW': 6,
          'h': 7,
          'w': 6,
          'settings': {
            'include_escalations': true,
            'include_hosts': true,
            'patch_tuesday': true,
            'include_description': false,
          },
          'resizeHandles': [
            's',
          ],
          'i': '7735d84a-644c-458e-9b9d-22a6511cae83',
          'x': 0,
          'y': 38,
        },
        {
          'version': 2,
          'label': 'Vulnerability Instances',
          'fullLabel': '',
          'key': 'vulnerability_instances_global',
          'minH': 6,
          'maxH': 12,
          'minW': 6,
          'maxW': 6,
          'h': 6,
          'w': 6,
          'settings': {
            'version': 'full',
            'include_description': false,
          },
          'resizeHandles': [
            's',
          ],
          'i': '18628600-b65e-4779-b75f-9b44afe9b767',
          'x': 0,
          'y': 16,
        },
        {
          'version': 2,
          'label': 'Top Paths (system wide)',
          'fullLabel': 'Top 5 Critical Paths',
          'key': 'paths_global',
          'minH': 4,
          'maxH': 12,
          'minW': 4,
          'maxW': 6,
          'h': 11,
          'w': 4,
          'settings': {
            'item_count': 5,
            'include_description': true,
          },
          'resizeHandles': [
            's',
            'e',
          ],
          'i': '344f30d6-4701-4846-bbfc-dfe7514b248e',
          'x': 0,
          'y': 5,
        },
        {
          'version': 2,
          'label': 'Host Risk Breakdown',
          'fullLabel': 'Hosts: Risk Breakdown',
          'key': 'hosts_global',
          'settings': {
            'report_type': 'hosts',
            'include_description': false,
          },
          'minH': 4,
          'maxH': 12,
          'minW': 2,
          'maxW': 4,
          'h': 6,
          'w': 2,
          'resizeHandles': [
            's',
            'e',
          ],
          'i': 'e3423fc4-e5b1-42a1-a334-8acd0fcbc4f0',
          'x': 0,
          'y': 65,
        },
        {
          'version': 2,
          'label': 'Patch Risk Breakdown',
          'fullLabel': 'Patches: Risk Breakdown',
          'key': 'patches_global',
          'settings': {
            'report_type': 'patches',
            'include_description': false,
          },
          'minH': 4,
          'maxH': 12,
          'minW': 2,
          'maxW': 4,
          'h': 6,
          'w': 2,
          'resizeHandles': [
            's',
            'e',
          ],
          'i': '0657603b-68f9-412e-ab78-ed28c058a72a',
          'x': 0,
          'y': 81,
        },
        {
          'version': 2,
          'label': 'Vulnerability Risk Breakdown',
          'fullLabel': 'Vulnerabilities: Risk Breakdown',
          'key': 'vulnerabilities_global',
          'settings': {
            'report_type': 'vulnerabilities',
            'include_description': false,
          },
          'minH': 4,
          'maxH': 12,
          'minW': 2,
          'maxW': 4,
          'h': 6,
          'w': 2,
          'resizeHandles': [
            's',
            'e',
          ],
          'i': 'eb52039e-77c2-4c2f-9ad6-280a08534209',
          'x': 0,
          'y': 45,
        },
        {
          'version': 2,
          'label': 'Peer Percentile',
          'fullLabel': '',
          'key': 'risk_peer_percentile',
          'settings': {},
          'minH': 4,
          'maxH': 12,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 5,
          'w': 2,
          'i': '2342bbd8-7e38-417e-b5a4-91f05c00a67a',
          'x': 2,
          'y': 0,
        },
        {
          'version': 2,
          'label': 'Total',
          'fullLabel': '',
          'key': 'hosts_total',
          'minH': 4,
          'maxH': 12,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 6,
          'w': 2,
          'settings': {
            'report_type': 'hosts',
          },
          'i': '935a1fb4-6e41-446f-a33e-710657cba21e',
          'x': 2,
          'y': 65,
        },
        {
          'version': 2,
          'label': 'Total',
          'fullLabel': '',
          'key': 'patches_total',
          'minH': 4,
          'maxH': 12,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 6,
          'w': 2,
          'settings': {
            'report_type': 'patches',
          },
          'i': 'cec2e767-b026-4f31-b2f2-2171ca156781',
          'x': 2,
          'y': 81,
        },
        {
          'version': 2,
          'label': 'Total',
          'fullLabel': '',
          'key': 'vulnerabilities_total',
          'minH': 4,
          'maxH': 12,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 6,
          'w': 2,
          'settings': {
            'report_type': 'vulnerabilities',
          },
          'i': '7c2e1bc2-b966-4640-bb11-fec8f3319901',
          'x': 2,
          'y': 45,
        },
        {
          'version': 2,
          'label': 'Top Vulnerabilities w/ Details',
          'fullLabel': '',
          'key': 'vulnerabilities_top_details',
          'minH': 4,
          'maxH': 48,
          'minW': 2,
          'maxW': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 14,
          'w': 6,
          'settings': {
            'order_by': 'filtered_risk',
            'item_count': 3,
            'orientation': 'horizontal',
            'include_risk': true,
            'include_instances': true,
            'include_cvss': true,
            'report_type': 'vulnerabilities',
          },
          'i': '560e7d8f-d3c6-4313-8a7d-c6ebd773d836',
          'x': 0,
          'y': 51,
        },
        {
          'version': 2,
          'label': 'Vulnerability Age Breakdown',
          'fullLabel': 'Vulnerability Instances: Vulnerability Age Breakdown',
          'key': 'vulnerability_instances_vulnerability_age_breakdown',
          'settings': {
            'include_description': true,
          },
          'minH': 4,
          'maxH': 8,
          'minW': 2,
          'maxW': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 6,
          'w': 4,
          'i': '8b4393f5-2be7-425c-baec-af9058ab86e8',
          'x': 2,
          'y': 22,
        },
        {
          'version': 2,
          'label': 'Total',
          'fullLabel': '',
          'key': 'vulnerability_instances_total',
          'minH': 4,
          'maxH': 12,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 5,
          'w': 2,
          'i': 'bc3febc8-0fd8-4d40-b7f5-b9f071afedd7',
          'x': 4,
          'y': 5,
          'settings': {
            'include_description': false,
            'version': 'full',
          },
        },
        {
          'version': 2,
          'label': 'Total',
          'fullLabel': '',
          'key': 'scanning_total',
          'minH': 4,
          'maxH': 12,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 6,
          'w': 2,
          'i': '51590b8a-56a0-4285-bf3a-8e4cab4dd7dd',
          'x': 0,
          'y': 22,
          'settings': {
            'scanning_type': 'both',
          },
        },
        {
          'version': 2,
          'label': 'Top Hosts w/ Details',
          'fullLabel': '',
          'key': 'hosts_top_details',
          'minH': 4,
          'maxH': 48,
          'minW': 2,
          'maxW': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 10,
          'w': 6,
          'settings': {
            'order_by': 'filtered_risk',
            'item_count': 3,
            'orientation': 'horizontal',
            'include_risk': true,
            'include_instances': true,
            'include_cvss': true,
            'report_type': 'hosts',
          },
          'i': '4f49b689-4483-4d0f-b1d0-7c87df3799bc',
          'x': 0,
          'y': 71,
        },
        {
          'version': 2,
          'label': 'Top Patches w/ Details',
          'fullLabel': '',
          'key': 'patches_top_details',
          'minH': 4,
          'maxH': 48,
          'minW': 2,
          'maxW': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 11,
          'w': 6,
          'settings': {
            'order_by': 'filtered_risk',
            'item_count': 3,
            'orientation': 'horizontal',
            'include_risk': true,
            'include_instances': true,
            'include_cvss': true,
            'report_type': 'patches',
          },
          'i': 'd984ff5b-bbd0-4b84-9079-6a3708597a8c',
          'x': 0,
          'y': 87,
        },
        {
          'version': 2,
          'label': 'Highest Priority Hosts',
          'fullLabel': 'Hosts: Highest Priority (100)',
          'key': 'hosts_priority',
          'minH': 4,
          'maxH': 48,
          'minW': 2,
          'maxW': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 6,
          'w': 2,
          'settings': {
            'include_description': false,
            'version': 'list',
            'order_by': 'filtered_risk',
            'item_count': '10',
            'report_type': 'hosts',
          },
          'i': 'eb005c2f-4884-469e-a907-13bee22f3e37',
          'x': 4,
          'y': 65,
        },
        {
          'version': 2,
          'label': 'Risk Score',
          'fullLabel': '',
          'key': 'risk_score',
          'settings': {},
          'minH': 4,
          'maxH': 12,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 5,
          'w': 2,
          'i': 'cbade6b7-e2aa-4151-ac7d-1e4483f5d591',
          'x': 0,
          'y': 0,
        },
        {
          'version': 2,
          'label': 'Specific Plan',
          'fullLabel': '',
          'key': 'remediation_specific_plan',
          'settings': {
            'include_description': false,
            'plan_id': '3ec5aedd-f54c-4d42-8848-a6118e1a0c4b',
          },
          'minH': 4,
          'maxH': 24,
          'minW': 2,
          'maxW': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 10,
          'w': 3,
          'i': '90c6ba43-f3e2-4b8c-8cb8-6d47ba7cc6a5',
          'x': 3,
          'y': 28,
        },
        {
          'version': 2,
          'label': 'Specific Plan',
          'fullLabel': '',
          'key': 'remediation_specific_plan',
          'settings': {
            'include_description': false,
            'plan_id': '0e2c14ad-28e4-4610-ac40-da1007a20feb',
          },
          'minH': 4,
          'maxH': 24,
          'minW': 2,
          'maxW': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 10,
          'w': 3,
          'i': '5bb0c06d-6614-4d0f-9cf3-0f65fa265cb0',
          'x': 0,
          'y': 28,
        },
      ],
    },
    {
      id: '00000000-0000-0000-0000-000000000002',
      label: 'Scanning and Vulnerabilities',
      widgets: [
        {
          'version': 2,
          'label': 'OS Family Breakdown',
          'fullLabel': 'Vulnerability Instances: OS Family Breakdown',
          'key': 'vulnerability_instances_os_family_breakdown',
          'settings': {
            'include_description': true,
          },
          'minH': 4,
          'maxH': 8,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 8,
          'w': 2,
          'i': '56db2cf5-616c-4043-b713-fc67ba0c3e2e',
          'x': 2,
          'y': 8,
        },
        {
          'version': 2,
          'label': 'Tag Breakdown',
          'fullLabel': 'Vulnerability Instances: Tag Breakdown',
          'key': 'vulnerability_instances_tag_breakdown',
          'settings': {
            'include_description': true,
          },
          'minH': 4,
          'maxH': 8,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 8,
          'w': 2,
          'i': '78f67f29-0082-4fec-b002-c7e6b70cb024',
          'x': 0,
          'y': 8,
        },
        {
          'version': 2,
          'label': 'Vulnerability Age Breakdown',
          'fullLabel': 'Vulnerability Instances: Vulnerability Age Breakdown',
          'key': 'vulnerability_instances_vulnerability_age_breakdown',
          'settings': {
            'include_description': true,
          },
          'minH': 4,
          'maxH': 8,
          'minW': 2,
          'maxW': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 6,
          'w': 4,
          'i': '2c489ad7-ef48-43d6-9054-3f386738f8c7',
          'x': 2,
          'y': 16,
        },
        {
          'version': 2,
          'label': 'Exploit Status Breakdown',
          'fullLabel': 'Vulnerability Instances: Exploit Status Breakdown',
          'key': 'vulnerability_instances_exploit_status_breakdown',
          'settings': {
            'include_description': true,
          },
          'minH': 4,
          'maxH': 8,
          'minW': 2,
          'maxW': 3,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 8,
          'w': 2,
          'i': '660908b0-48c9-4c09-be7c-1b41c3dd2aa7',
          'x': 4,
          'y': 0,
        },
        {
          'version': 2,
          'label': 'CVSS Breakdown',
          'fullLabel': 'Vulnerability Instances: CVSS Breakdown',
          'key': 'vulnerability_instances_cvss_breakdown',
          'settings': {
            'include_description': false,
            'version': 'barchart',
          },
          'minH': 4,
          'maxH': 8,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 8,
          'w': 2,
          'i': '5acc0dae-d59e-4cc3-b317-d3376ea7b0d2',
          'x': 0,
          'y': 0,
        },
        {
          'version': 2,
          'label': 'Total',
          'fullLabel': '',
          'key': 'scanning_total',
          'minH': 4,
          'maxH': 12,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 4,
          'w': 2,
          'i': 'e8487196-fa2a-4850-afde-12d3fb4ef7b3',
          'x': 2,
          'y': 0,
          'settings': {
            'scanning_type': 'both',
          },
        },
        {
          'version': 2,
          'label': 'Agent Version Breakdown',
          'fullLabel': 'Scanning: Agent Version Breakdown',
          'key': 'scanning_agent_version_breakdown',
          'settings': {
            'include_description': true,
          },
          'minH': 4,
          'maxH': 8,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 8,
          'w': 2,
          'i': 'da6cb7ec-9e55-4de8-88c7-ac243d1ee491',
          'x': 4,
          'y': 8,
        },
        {
          'version': 2,
          'label': 'Highest Priority Vulnerabilities',
          'fullLabel': 'Vulnerabilities: Highest Priority (100)',
          'key': 'vulnerabilities_priority',
          'minH': 4,
          'maxH': 48,
          'minW': 2,
          'maxW': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 33,
          'w': 4,
          'settings': {
            'include_description': false,
            'version': 'table',
            'include_risk': false,
            'include_cvss': true,
            'order_by': 'cvss_exploit',
            'item_count': '50',
            'report_type': 'vulnerabilities',
          },
          'i': '7f948544-04a7-4c1e-912c-6094c5d84548',
          'x': 2,
          'y': 28,
        },
        {
          'version': 2,
          'label': 'Highest Priority Hosts',
          'fullLabel': 'Hosts: Highest Priority (100)',
          'key': 'hosts_priority',
          'minH': 4,
          'maxH': 48,
          'minW': 2,
          'maxW': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 32,
          'w': 4,
          'settings': {
            'include_description': false,
            'version': 'table',
            'include_risk': false,
            'include_user': false,
            'include_os_type': true,
            'order_by': 'num_vulnerabilities',
            'item_count': '50',
            'report_type': 'hosts',
          },
          'i': 'e7f666bf-c6aa-4877-bbdd-d19031a7fff2',
          'x': 2,
          'y': 61,
        },
        {
          'version': 2,
          'label': 'Total',
          'fullLabel': '',
          'key': 'vulnerability_instances_total',
          'minH': 4,
          'maxH': 12,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 4,
          'w': 2,
          'i': 'a9076b31-a640-4786-8034-1ed47d6ab060',
          'x': 2,
          'y': 4,
          'settings': {
            'include_description': false,
            'version': 'full',
          },
        },
        {
          'version': 2,
          'label': 'Top Vulnerabilities w/ Details',
          'fullLabel': '',
          'key': 'vulnerabilities_top_details',
          'minH': 4,
          'maxH': 48,
          'minW': 2,
          'maxW': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 33,
          'w': 2,
          'settings': {
            'order_by': 'cvss_exploit',
            'item_count': 3,
            'orientation': 'vertical',
            'include_risk': false,
            'include_instances': true,
            'include_cvss': true,
            'report_type': 'vulnerabilities',
          },
          'i': 'bea79062-c457-4e55-9af2-4d62a03ba7d4',
          'x': 0,
          'y': 28,
        },
        {
          'version': 2,
          'label': 'Top Hosts w/ Details',
          'fullLabel': '',
          'key': 'hosts_top_details',
          'minH': 4,
          'maxH': 48,
          'minW': 2,
          'maxW': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 32,
          'w': 2,
          'settings': {
            'order_by': 'num_vulnerabilities',
            'item_count': 3,
            'orientation': 'vertical',
            'include_risk': false,
            'include_instances': true,
            'include_cvss': true,
            'report_type': 'hosts',
          },
          'i': '6b17c869-3fc1-4e4d-9094-79c0917a3221',
          'x': 0,
          'y': 61,
        },
        {
          'version': 2,
          'label': 'Categories',
          'fullLabel': '',
          'key': 'vulnerability_instances_global',
          'minH': 6,
          'maxH': 12,
          'minW': 6,
          'maxW': 6,
          'resizeHandles': [
            's',
          ],
          'h': 6,
          'w': 6,
          'settings': {
            'version': 'full',
            'include_description': false,
          },
          'i': '7f7c1819-5b1f-44de-9348-06f600602353',
          'x': 0,
          'y': 22,
        },
        {
          'version': 2,
          'label': 'Total',
          'fullLabel': '',
          'key': 'vulnerabilities_total',
          'minH': 4,
          'maxH': 12,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 6,
          'w': 2,
          'settings': {
            'report_type': 'vulnerabilities',
          },
          'i': '552f703d-fd65-4609-b655-eeeae8706830',
          'x': 0,
          'y': 16,
        },
      ],
    },
    {
      id: '00000000-0000-0000-0000-000000000003',
      label: 'Vulnerabilities and Instances',
      widgets: [
        {
          'version': 2,
          'label': 'OS Family Breakdown',
          'fullLabel': 'Vulnerability Instances: OS Family Breakdown',
          'key': 'vulnerability_instances_os_family_breakdown',
          'settings': {
            'include_description': true,
          },
          'minH': 4,
          'maxH': 8,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 8,
          'w': 2,
          'i': '56db2cf5-616c-4043-b713-fc67ba0c3e2e',
          'x': 4,
          'y': 8,
        },
        {
          'version': 2,
          'label': 'Tag Breakdown',
          'fullLabel': 'Vulnerability Instances: Tag Breakdown',
          'key': 'vulnerability_instances_tag_breakdown',
          'settings': {
            'include_description': true,
          },
          'minH': 4,
          'maxH': 8,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 8,
          'w': 2,
          'i': '78f67f29-0082-4fec-b002-c7e6b70cb024',
          'x': 0,
          'y': 8,
        },
        {
          'version': 2,
          'label': 'Vulnerability Age Breakdown',
          'fullLabel': 'Vulnerability Instances: Vulnerability Age Breakdown',
          'key': 'vulnerability_instances_vulnerability_age_breakdown',
          'settings': {
            'include_description': true,
          },
          'minH': 4,
          'maxH': 8,
          'minW': 2,
          'maxW': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 6,
          'w': 6,
          'i': '2c489ad7-ef48-43d6-9054-3f386738f8c7',
          'x': 0,
          'y': 16,
        },
        {
          'version': 2,
          'label': 'Exploit Status Breakdown',
          'fullLabel': 'Vulnerability Instances: Exploit Status Breakdown',
          'key': 'vulnerability_instances_exploit_status_breakdown',
          'settings': {
            'include_description': true,
          },
          'minH': 4,
          'maxH': 8,
          'minW': 2,
          'maxW': 3,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 8,
          'w': 2,
          'i': '660908b0-48c9-4c09-be7c-1b41c3dd2aa7',
          'x': 4,
          'y': 0,
        },
        {
          'version': 2,
          'label': 'CVSS Breakdown',
          'fullLabel': 'Vulnerability Instances: CVSS Breakdown',
          'key': 'vulnerability_instances_cvss_breakdown',
          'settings': {
            'include_description': false,
            'version': 'barchart',
          },
          'minH': 4,
          'maxH': 8,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 8,
          'w': 2,
          'i': '5acc0dae-d59e-4cc3-b317-d3376ea7b0d2',
          'x': 0,
          'y': 0,
        },
        {
          'version': 2,
          'label': 'Highest Priority Vulnerabilities',
          'fullLabel': 'Vulnerabilities: Highest Priority (100)',
          'key': 'vulnerabilities_priority',
          'minH': 4,
          'maxH': 48,
          'minW': 2,
          'maxW': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 17,
          'w': 4,
          'settings': {
            'include_description': false,
            'version': 'table',
            'include_risk': false,
            'include_cvss': true,
            'order_by': 'exploit_cvss',
            'item_count': '25',
            'report_type': 'vulnerabilities',
          },
          'i': '7f948544-04a7-4c1e-912c-6094c5d84548',
          'x': 0,
          'y': 34,
        },
        {
          'version': 2,
          'label': 'Highest Priority Hosts',
          'fullLabel': 'Hosts: Highest Priority (100)',
          'key': 'hosts_priority',
          'minH': 4,
          'maxH': 48,
          'minW': 2,
          'maxW': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 17,
          'w': 4,
          'settings': {
            'include_description': false,
            'version': 'table',
            'include_risk': false,
            'include_user': false,
            'include_os_type': true,
            'order_by': 'num_vulnerabilities',
            'item_count': '25',
            'report_type': 'hosts',
          },
          'i': 'e7f666bf-c6aa-4877-bbdd-d19031a7fff2',
          'x': 0,
          'y': 61,
        },
        {
          'version': 2,
          'label': 'Total',
          'fullLabel': '',
          'key': 'vulnerability_instances_total',
          'minH': 4,
          'maxH': 12,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 6,
          'w': 2,
          'i': 'a9076b31-a640-4786-8034-1ed47d6ab060',
          'x': 2,
          'y': 10,
          'settings': {
            'include_description': false,
            'version': 'full',
          },
        },
        {
          'version': 2,
          'label': 'Top Vulnerabilities w/ Details',
          'fullLabel': '',
          'key': 'vulnerabilities_top_details',
          'minH': 4,
          'maxH': 48,
          'minW': 2,
          'maxW': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 12,
          'w': 6,
          'settings': {
            'order_by': 'exploit_cvss',
            'item_count': 3,
            'orientation': 'horizontal',
            'include_risk': false,
            'include_instances': true,
            'include_cvss': true,
            'report_type': 'vulnerabilities',
          },
          'i': 'bea79062-c457-4e55-9af2-4d62a03ba7d4',
          'x': 0,
          'y': 22,
        },
        {
          'version': 2,
          'label': 'Top Hosts w/ Details',
          'fullLabel': '',
          'key': 'hosts_top_details',
          'minH': 4,
          'maxH': 48,
          'minW': 2,
          'maxW': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 10,
          'w': 6,
          'settings': {
            'order_by': 'num_vulnerabilities',
            'item_count': 3,
            'orientation': 'horizontal',
            'include_risk': false,
            'include_instances': true,
            'include_cvss': true,
            'report_type': 'hosts',
          },
          'i': '6b17c869-3fc1-4e4d-9094-79c0917a3221',
          'x': 0,
          'y': 51,
        },
        {
          'version': 2,
          'label': 'Total',
          'fullLabel': '',
          'key': 'vulnerabilities_total',
          'minH': 4,
          'maxH': 12,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 5,
          'w': 2,
          'settings': {
            'report_type': 'vulnerabilities',
          },
          'i': '552f703d-fd65-4609-b655-eeeae8706830',
          'x': 2,
          'y': 0,
        },
        {
          'version': 2,
          'label': 'Total',
          'fullLabel': '',
          'key': 'hosts_total',
          'minH': 4,
          'maxH': 12,
          'minW': 2,
          'maxW': 4,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 5,
          'w': 2,
          'settings': {
            'report_type': 'hosts',
          },
          'i': 'c4c67a87-d89f-48f8-9436-3c58f0a8802b',
          'x': 2,
          'y': 5,
        },
        {
          'version': 2,
          'label': 'Highest Priority Vulnerabilities',
          'fullLabel': 'Vulnerabilities: Highest Priority (100)',
          'key': 'vulnerabilities_priority',
          'minH': 4,
          'maxH': 48,
          'minW': 2,
          'maxW': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 17,
          'w': 2,
          'settings': {
            'version': 'list',
            'item_count': 25,
            'report_type': 'vulnerabilities',
            'include_rating': true,
            'include_description': false,
            'order_by': 'num_hosts',
          },
          'i': 'f1dd6d21-9f7e-4daa-928f-ae3a6f6de5a3',
          'x': 4,
          'y': 34,
        },
        {
          'version': 2,
          'label': 'Highest Priority Hosts',
          'fullLabel': 'Hosts: Highest Priority (100)',
          'key': 'hosts_priority',
          'minH': 4,
          'maxH': 48,
          'minW': 2,
          'maxW': 6,
          'resizeHandles': [
            's',
            'e',
          ],
          'h': 17,
          'w': 2,
          'settings': {
            'version': 'list',
            'item_count': 25,
            'report_type': 'hosts',
            'include_rating': true,
            'include_user': false,
            'include_os_type': false,
            'include_description': false,
            'order_by': 'num_vulnerabilities',
          },
          'i': 'b31d6b74-677b-4795-98f3-8dfc76692188',
          'x': 4,
          'y': 61,
        },
      ],
    },
  ];
  /* eslint-enable camelcase */

  const builtInIDS = builtInDashboards.map( dash => dash.id );

  const isBuiltIn = _layout => builtInIDS.includes( _layout.id );

  const refreshLayouts = async ( currentID=null ) => {

    const records = builtInDashboards;

    // for now, always going to update the backend with the latest builtin dashboards no matter what
    await makeRequest( 'UPSERT', '/dashboard', { records } );
    const dashResponse = await makeRequest( 'SEARCH', '/dashboard' );

    if ( isNotEmpty( dashResponse ) && isNotEmpty( dashResponse.results ) ) {

      const _layouts = [];
      const _currentSettings = {};
      const _currentWidgets = [];

      if ( isNotEmpty( dashResponse.results ) ) {
        dashResponse.results.map( dash => {
          const _dash = {
            ...dash,
            widgets: dash.widgets.map( w => {
              if ( w.version === 2 ) {
                return w;
              }
              const _latest = getLatestWidgetVersion( w );
              return _latest;
            } ),
          };

          _layouts.push( _dash );
        } );
      }

      let _currentID = isNotEmpty( currentLayoutID ) ? currentLayoutID : '00000000-0000-0000-0000-000000000001';

      if ( isNotEmpty( currentID ) ) {
        _currentID = currentID;
      }
      let _currentLayout = _layouts.find( l => l.id === _currentID );

      if ( isEmpty( _currentLayout ) ) {
        [ _currentLayout ] = _layouts;
        _currentID = _currentLayout.id;
      }

      if ( isNotEmpty( _currentLayout ) && isNotEmpty( _currentLayout.widgets ) ) {
        _currentLayout.widgets.map( w => {
          _currentSettings[w.i] = w.settings;

          const minMax = v2WidgetMinMaxDimensions[w.key];

          if ( isNotEmpty( minMax ) ) {
            const _w = {
              ...w,
              ...minMax,
            };

            _currentWidgets.push( _w );
          } else {
            _currentWidgets.push( w );
          }
        } );
      }

      _currentLayout.widgets = _currentWidgets;

      // stored in local storage so we know what the current layout should be
      setCurrentLayoutID( _currentID );
      // all the layout and setting vars
      setLayouts( _layouts );
      setCurrentSettings( _currentSettings );
      setCurrentLayout( _currentLayout );

      // kick off the data fetch
      prefetchDashboardData( _currentLayout );
    } else {
      addFlashMessage( {
        body: 'There was an error trying to get dashboards',
        type: 'alert',
      } );
    }

    if ( isNotEmpty( dashboardWrapperRef ) && isNotEmpty( dashboardWrapperRef.current ) ) {
      const { height } = getDimensionsAndOffset( dashboardWrapperRef.current );

      const _pageCount = Math.ceil( height / 720 );

      if ( isNotEmpty( _pageCount ) && _pageCount > 1 ) {
        setPageCount( _pageCount );
      } else {
        setPageCount( 1 );
      }
    }
  };

  // called on page load, detects if there are legacy layouts that need converting, or previously stored layouts in
  // localStorage and initializes the dashboard accordingly. If nothing exists, it creates the default dashboard and
  // saves it to localStorage
  const setupLayouts = async ( ) => {

    // one time check to see if any left over layouts have been stored in localStorage. If so, grab them and stash them
    // into the db, then wipe localStorage
    const localStorageDBs = localStorage.getItem( 'DSdashboardLayouts' );

    if ( isNotEmpty( localStorageDBs ) ) {
      let records = JSON.parse( localStorageDBs );

      records = records.filter( l => !isBuiltIn( l ) );

      if ( isNotEmpty( records ) ) {
        await makeRequest( 'UPSERT', '/dashboard', { records } );
      }
      localStorage.removeItem( 'DSdashboardLayouts' );
      localStorage.removeItem( 'DSdashboardCurrentLayout' );
    }
    refreshLayouts();
  };

  // because there can be multiple instances of the same widget, or different widgets that use the same data, instead
  // of having each widget grab its own data, it is important to prefetch as much as possible to not slam the server
  // with multiple, sometimes repeated, async requests
  const prefetchDashboardData = async( layout ) => {
    setGridLoading( true );
    if ( isNotEmpty( layout ) && isNotEmpty( layout.widgets ) ) {

      const fetchesNeeded = [];

      // maps through all of the widgets and adds the fetchKey if it has not already been added so that the correct
      // data can be fetched
      layout.widgets.forEach( widget => {

        let _widget;
        if ( widget.version === 2 ) {
          _widget = { ...widget };
        } else {
          _widget = legacyToV2Widget( widget );
        }

        const { key } = _widget;

        if ( isNotEmpty( key ) && isNotEmpty( fetchForWidgetsV2[key] ) ) {
          fetchForWidgetsV2[key].map( f => {
            if ( !fetchesNeeded.includes( f ) ) {
              fetchesNeeded.push( f );
            }
          } );
        }
      } );

      // if there are any fetches needed, the server is hit sequentially and then all the data is stored
      // in one object that is passed down to all of the widgets
      if ( isNotEmpty( fetchesNeeded ) ) {
        const promises = fetchesNeeded.map( f => v2Fetches[f] );

        const resolvedResponses = await promiseAllSequential( promises, { chunkSize: 4 } );

        const _prefetchedData = {};

        fetchesNeeded.map( ( key, index ) => {
          _prefetchedData[key] = resolvedResponses[index];
        } );

        setPrefetchedData( _prefetchedData );
      }
    }
    setGridLoading( false );
    setDataFetchComplete( true );
  };

  React.useEffect( ( ) => {
    setupLayouts();

    const hash = decodeURLHash();

    if ( hash.creating_report ) {
      const project = 'default';
      const model = 'base';
      const filters = {
        // eslint-disable-next-line camelcase
        extra_columns: [
          'created',
          'email_recipients',
          'format',
          'filters',
          'id',
          'label',
          'last_finished',
          'last_started',
          'schedule',
          'expiration',
          'type',
          'owner',
          'state',
        ],
        // eslint-disable-next-line camelcase
        id_list: [ hash.report_id ],
      };

      if ( isNotEmpty( hash.report_id ) ) {
        makeRequest( 'SEARCH', '/model/base/exported_report', {
          project,
          model,
          filters,
        } ).then( response => {
          if ( response && response.results ) {
            setExistingReport( response.results[0] );
          } else {
            setExistingReport( null );
          }
          openReportCreator( openCallback );
        } );
      } else {
        setExistingReport( null );
        openReportCreator( openCallback );
      }
    }

    if ( isNotEmpty( dashboardWrapperRef ) && isNotEmpty( dashboardWrapperRef.current ) ) {
      const { height } = getDimensionsAndOffset( dashboardWrapperRef.current );

      const _pageCount = Math.ceil( height / 720 );

      if ( isNotEmpty( _pageCount ) && _pageCount > 1 ) {
        setPageCount( _pageCount );
      } else {
        setPageCount( 1 );
      }
    }
  }, [] );

  // called from the widget editor modal
  const addWidget = ( widgetID, widget, settings ) => {
    const getNewWidgetY = () => {
      let _otherItems = [];
      if ( isNotEmpty( currentLayout?.widgets ) ) {
        _otherItems = [ ...currentLayout.widgets ];
        _otherItems = _otherItems.sort( ( a, b ) => b.y - a.y );
        return _otherItems[0].y + _otherItems[0].h;
      }
      return 0;
    };
    if ( isNotEmpty( settings ) ) {
      const _currentSettings = { ...currentSettings };
      // updating existing widget, just updated the settings
      if ( isNotEmpty( widgetID ) ) {

        _currentSettings[widgetID] = settings;
        setCurrentSettings( _currentSettings );
      // adding a new widget, need to add the widget to the layout and settings
      } else if ( isNotEmpty( widget ) ) {

        const i = uuidv4();

        const _widget = {
          ...widget,
          i,
          x: 0,
          y: getNewWidgetY(),
          settings,
        };

        _currentSettings[i] = settings;
        setCurrentSettings( _currentSettings );
        updateLayout( _widget );
      }
    // some widgets have no settings, risk, peer percentile,
    } else if ( isNotEmpty( widget ) ) {

      const i = uuidv4();

      const _widget = {
        ...widget,
        i,
        x: 0,
        y: getNewWidgetY(),
        settings,
      };

      updateLayout( _widget );
    }
    // scroll container to newly added widget for convenience
    const scrollingContainer = document.getElementById( 'reportingContainer' );
    if ( isNotEmpty( scrollingContainer ) ) {
      window.setTimeout( () => {
        scrollingContainer.scrollTop = scrollingContainer.scrollHeight;
      }, 100 );
    }
  };

  const openWidgetEditorFor = widgetCategory => {
    setSelectedWidgetCategory( widgetCategory );
    setShowWidgetEditor( true );
  };

  const editWidget = existing => {
    if ( isNotEmpty( existing ) ) {
      setCurrentWidget( existing );
      setShowWidgetEditor( true );
      setSelectedWidgetVariant( existing.key );
    }
  };

  const closeWidgetEditor = () => {
    setSelectedWidgetCategory( null );
    setShowWidgetEditor( false );
    setCurrentWidget( null );
    setSelectedWidgetVariant( null );
    setRecordLabel( null );
  };

  const removeItem = removedItem => {
    updateLayout( null, removedItem );
  };

  const getLayoutWidget = layoutItem => {
    const _existingWidget = currentLayout.widgets?.find( i => i.i === layoutItem.i );

    const cleansedWidget = {};

    Object.entries( layoutItem ).map( ( [ key, value ] ) => {
      if ( isNotEmpty( value ) ) {
        cleansedWidget[key] = value;
      }
    } );

    const layoutWidget = { ..._existingWidget, ...cleansedWidget };
    return layoutWidget;
  };

  // called when widgets get added, removed, resized, moved
  const updateLayout = ( addedItem=null, removedItem=null, widgets=null ) => {
    let _currentLayout = { ...currentLayout };
    let values = {};
    // the overall form for the dashboard itself, not the individual fields for widget settings
    if ( isNotEmpty( updatedLayoutForm ) && isNotEmpty( updatedLayoutForm.fieldStates ) ) {
      values = getFieldValues( updatedLayoutForm.fieldStates, 'reporting_dashboard' );
    }
    _currentLayout = { ...currentLayout };

    if ( isNotEmpty( values ) && values?.label !== '' ) {
      _currentLayout.label = values.label;
    }
    // the layout changed, only callback, all the widgets have already been merged and updated, just need to set
    if ( isNotEmpty( widgets ) ) {
      _currentLayout = { ..._currentLayout, widgets };
    // added an item
    } else if ( isNotEmpty( addedItem ) ) {
      if ( isNotEmpty( _currentLayout.widgets ) ) {
        _currentLayout = { ..._currentLayout, widgets: _currentLayout.widgets.filter( w => w.i !== addedItem.i ) };
        _currentLayout = { ..._currentLayout, widgets: [ ..._currentLayout.widgets, addedItem ] };
      } else {
        _currentLayout = { ..._currentLayout, widgets: [ addedItem ] };
      }
    // removed an item
    } else if ( isNotEmpty( removedItem ) ) {
      if ( isNotEmpty( _currentLayout.widgets ) ) {
        _currentLayout = { ..._currentLayout, widgets: _currentLayout.widgets.filter( i => i.i !== removedItem.i ) };
      } else {
        _currentLayout = { ..._currentLayout, widgets: [] };
      }
    }
    setCurrentLayout( _currentLayout );

    if ( isNotEmpty( dashboardWrapperRef ) && isNotEmpty( dashboardWrapperRef.current ) ) {
      const { height } = getDimensionsAndOffset( dashboardWrapperRef.current );

      const _pageCount = Math.ceil( height / 720 );

      if ( isNotEmpty( _pageCount ) && _pageCount > 1 ) {
        setPageCount( _pageCount );
      } else {
        setPageCount( 1 );
      }
    }
  };

  // called on drag/drop/etc. from the grid libray callback
  const handleLayoutChange = layout => {
    const layoutWidgets = layout.map( getLayoutWidget );
    updateLayout( null, null, layoutWidgets );
  };

  const editAndConfigure = () => {
    setEditMode( true );
    setTimeout( () => {
      adjustGridWidthAndPadding();
    }, 100 );
  };

  const adjustGridWidthAndPadding = () => {
    if ( !printing ) {
      // full width - the sidebar
      setGridWidth( window.innerWidth - 114 );
    }
  };

  const handleBeforePrint = () => {
    setPrinting( true );
  };

  const handleAfterPrint = () => {
    setPrinting( false );
  };

  React.useEffect( ( ) => {
    if ( printing ) {
      setGridWidth( 960 );
    } else {
      setGridWidth( window.innerWidth - 114 );
    }
  }, [ printing ] );

  // sets up the resize listener so that the padding can correctly be applied to the top of the grid to account
  // for the size of the editor
  React.useEffect( ( ) => {
    adjustGridWidthAndPadding();
    window.addEventListener( 'resize', adjustGridWidthAndPadding );
    window.addEventListener( 'beforeprint', handleBeforePrint );
    window.addEventListener( 'afterprint', handleAfterPrint );
    return () => {
      window.removeEventListener( 'resize', adjustGridWidthAndPadding );
      window.removeEventListener( 'beforeprint', handleBeforePrint );
      window.removeEventListener( 'afterprint', handleAfterPrint );
    };
  }, [ editMode ] );

  const openCallback = () => {
    setCreatorActive( true );
    setShowReportCreatorModal( true );
  };

  const closeCallback = () => {
    setShowReportCreatorModal( false );
    setCreatorActive( false );
  };

  const handleExportButtonClick = () => {
    if ( isBuiltIn( currentLayout ) && currentLayout.id === '00000000-0000-0000-0000-000000000000' ) {
      openReportCreator( openCallback );
    } else {
      setGridWidth( 960 );
      setPrinting( true );
      setTimeout( () => {
        window.print();
      }, 100 );
    }
  };

  const handleShowPageBreakClick = () => {
    if ( isNotEmpty( dashboardWrapperRef ) && isNotEmpty( dashboardWrapperRef.current ) ) {
      const { height } = getDimensionsAndOffset( dashboardWrapperRef.current );

      const _pageCount = Math.ceil( height / 720 );

      if ( isNotEmpty( _pageCount ) && _pageCount > 1 ) {
        setPageCount( _pageCount );
      } else {
        setPageCount( 1 );
      }
    }
    setShowPageBreaks( !showPageBreaks );
  };

  const copyDashboard = async () => {
    if ( isNotEmpty( currentLayout ) ) {
      const copiedDashboard = { ...currentLayout };

      delete copiedDashboard.id;
      delete copiedDashboard.created;
      delete copiedDashboard.modified;

      copiedDashboard.label = `${copiedDashboard.label} (copy)`;

      const upsertRequest = await makeRequest( 'UPSERT', '/dashboard', { records: [ copiedDashboard ] } );

      // success
      if ( isNotEmpty( upsertRequest ) && isNotEmpty( upsertRequest.results ) ) {

        setCurrentLayoutID( upsertRequest.results[0].id );

        addFlashMessage( {
          body: 'Successfully copied dashboard',
          type: 'success',
        } );
        window.location.reload();
      } else {
        addFlashMessage( {
          body: 'There was an error copying your dashboard',
          type: 'alert',
        } );
      }

      setEditMode( false );
      refreshLayouts( upsertRequest.results[0].id );
    }
  };

  return (
    <React.Fragment>
      <WidgetEditorModal
        showWidgetEditor={ showWidgetEditor}
        setShowWidgetEditor={ setShowWidgetEditor}
        selectedWidgetVariant={ selectedWidgetVariant }
        setSelectedWidgetVariant={ setSelectedWidgetVariant }
        selectedWidgetOptions={ selectedWidgetOptions }
        setSelectedWidgetOptions={ setSelectedWidgetOptions }
        selectedWidgetCategory={ selectedWidgetCategory }
        recordLabel={ recordLabel }
        setRecordLabel={ setRecordLabel }
        addWidget={ addWidget }
        currentSettings={ currentSettings }
        closeWidgetEditor={ closeWidgetEditor }
        setSelectedWidgetCategory={ setSelectedWidgetCategory }
        currentWidget={ currentWidget }
        setCurrentWidget={ setCurrentWidget }
      />
      <ExportModal
        existingReport={existingReport}
        setExistingReport={setExistingReport}
        show={showReportCreatorModal}
        setShow={setShowReportCreatorModal}
        closeCallback={closeCallback}
      />
      <PageHeader elementClass="reportingDashboardPageHeader">
        {
          editMode
            ? <React.Fragment>
              <DashboardEditorV2
                currentSettings={ currentSettings }
                addWidget={addWidget}
                setEditMode={setEditMode}
                layouts={layouts}
                currentLayoutID={ currentLayoutID }
                setCurrentLayoutID={setCurrentLayoutID}
                currentLayout={currentLayout}
                setCurrentLayout={setCurrentLayout}
                updatedLayoutForm={ updatedLayoutForm }
                setUpdatedLayoutForm={ setUpdatedLayoutForm }
                editorRef={editorRef}
                refreshLayouts={refreshLayouts}
                openWidgetEditorFor={ openWidgetEditorFor }
                closeWidgetEditor={ closeWidgetEditor }
              />
            </React.Fragment>

            : <React.Fragment>
              {
                isNotEmpty( currentLayout ) &&
                <React.Fragment>
                  <h2>
                    {
                      isBuiltIn( currentLayout ) &&
                      <InlineSVG type="primaryLogoBug" version="bug" size="logoBug" elementClass="dsLogo" />
                    }
                    {
                      isNotEmpty( currentLayout?.label )
                        ? currentLayout.label
                        : 'Default Dashboard'
                    }
                    {
                      !isBuiltIn( currentLayout ) &&
                      <button
                        onClick={ editAndConfigure }
                        className="editModeButton"
                      >
                        <InlineSVG type="setup" />
                        Configure
                      </button>
                    }
                    {
                      <button
                        className="copyDashboardButton"
                        onClick={ copyDashboard }
                        title="Duplicate Current Report?"
                      >
                        <InlineSVG type="copy"/>
                      </button>
                    }

                  </h2>
                  <div className="exportActions">
                    <div className="checkboxWrapper" onClick={ handleShowPageBreakClick }>
                      {
                        showPageBreaks
                          ? <InlineSVG type="checkboxChecked" />
                          : <InlineSVG type="checkbox" />
                      }
                      <label>Show page breaks?</label>
                    </div>
                    <button
                      className="exportMenuTrigger"
                      onClick={ handleExportButtonClick }
                    >
                      {
                        ( isBuiltIn( currentLayout ) && currentLayout.id === '00000000-0000-0000-0000-000000000000' )
                          ? <InlineSVG type="exportFile" />
                          : <InlineSVG type="print" />
                      }
                      {
                        ( isBuiltIn( currentLayout ) && currentLayout.id === '00000000-0000-0000-0000-000000000000' )
                          ? <span>Export</span>
                          : <span>Print/Save PDF</span>
                      }
                    </button>
                  </div>

                </React.Fragment>
              }
            </React.Fragment>
        }
      </PageHeader>
      <PageCreateButton>
        {
          isNotEmpty( layouts ) &&
          <DashboardSelector
            setEditMode={setEditMode}
            layouts={layouts}
            setLayouts={setLayouts}
            currentLayoutID={currentLayoutID}
            setCurrentLayoutID={setCurrentLayoutID}
            currentLayout={currentLayout}
            setCurrentLayout={setCurrentLayout}
            isBuiltIn={isBuiltIn}
          />
        }
      </PageCreateButton>
      <div ref={dashboardWrapperRef} className="dashboardsGridWrapper" >
        <EmptyLoading
          loading={ gridLoading }
          loadingMessage="Loading dashboard data"
          payload={ dataFetchComplete }
          emptyMessage="This dashboard is empty"
        />
        {
          ( isNotEmpty( pageCount ) && showPageBreaks ) &&
          <div className="pagesContainer">
            {
              Array( pageCount ).fill().map( ( p, i ) => <div key={i} className="pageWrapper" /> )
            }
          </div>
        }
        {
          (
            isNotEmpty( currentLayout )
            && isNotEmpty( currentLayout.widgets )
            && isNotEmpty( Grid )
            && isNotEmpty( gridWidth )
            && dataFetchComplete
          ) &&
          <Grid
            className="layout"
            width={ gridWidth }
            layouts={ { default: currentLayout.widgets } }
            breakpoints={{ default: 0 }}
            cols={ { default: 6 } }
            rowHeight={ 62 }
            margin={ [ 0, 0 ] }
            isDraggable={ editMode }
            isResizable={ editMode }
            isDroppable={ editMode }
            isBounded
            onLayoutChange={ handleLayoutChange }
          >
            {
              currentLayout.widgets.map( item => {
                return <div key={item.i} >
                  <WidgetWrapper
                    item={ item }
                    editMode={editMode}
                    removeItem={removeItem}
                    prefetchedData={prefetchedData}
                    printing={printing}
                    editWidget={ editWidget }
                    settings={ currentSettings[item.i] }
                  />
                </div>;
              } )
            }
          </Grid>
        }
      </div>
    </React.Fragment>
  );
};

export default Dashboards;